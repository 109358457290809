import { GetRooms_Room as ApiGetRoomsRoom } from 'core/dna/types/generated/GetRooms';
import { GetSchedule_Schedule_lessonGroups_lessons_rooms as ApiGetSchedulesRoom } from 'core/dna/types/generated/GetSchedule';
import { GetLessons_Lesson_rooms as ApiGetLessonsRoom } from 'core/dna/types/generated/GetLessons';
import { mapNullableListToList } from 'core/dna/functions';

import { Room } from 'modules/planner/dna/types/room';
import { mapApiRoomTermToTerm } from '../map-ApiRoomTerm-to-Term';
import { mapApiAvailabilityPreferenceListToAvailabilityPreferenceList } from '../map-ApiAvailabilityPreference-to-AvailabilityPreference';

export const mapApiRoomListToRoomList = (
  apiRoomList:
    | (ApiGetRoomsRoom | ApiGetSchedulesRoom | ApiGetLessonsRoom | null)[]
    | null,
): Room[] | undefined => {
  return mapNullableListToList<
    ApiGetRoomsRoom | ApiGetSchedulesRoom | ApiGetLessonsRoom,
    Room
  >(apiRoomList, mapApiRoomToRoom);
};

export const mapApiRoomToRoom = (
  apiRoom: ApiGetRoomsRoom | ApiGetSchedulesRoom | ApiGetLessonsRoom | null,
): Room | undefined => {
  if (!apiRoom) {
    return;
  }

  const {
    id,
    name,
    alias,
    note,
    deleted,
    term,
    availabilityPreferences,
  } = apiRoom;

  return {
    id,
    name,
    alias: alias ?? undefined,
    note: note ?? undefined,
    deleted: deleted ?? undefined,
    term: mapApiRoomTermToTerm(term),
    availabilityPreferences: mapApiAvailabilityPreferenceListToAvailabilityPreferenceList(
      availabilityPreferences,
    ),
  };
};
