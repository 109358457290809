import { useEffect } from 'react';
import queryString from 'query-string';

import { isString } from 'core/atoms/functions';
import { logger, LogType } from 'core/atoms/housekeeping';

import {
  useLocale,
  useModuleRoutes,
  getActiveRoute,
  useRouteMatch,
  URLS,
  useHistory,
} from 'core/dna/routing';
import { useTranslations } from 'core/dna/translations';
import { useApolloClient } from 'core/dna/types/apollo';
import { Modules } from 'core/dna/types/modules';

import { useAccessRolesUpdater } from 'core/dna/access-roles';

import { getInitialPageStorage } from 'core/memory/browser';

import { useOrganization } from 'core/metabolism/organizations';

import { getSessionTokenStorage } from 'auth/memory/browser';

import { useAuthContext } from 'auth/metabolism/use-auth-context';
import { useUpdateToken } from 'auth/metabolism/use-update-token';
import { useGetTokenByTransient } from 'auth/metabolism/use-get-token-by-transient';

import AuthPaths from 'auth/paths';
import AuthUrls from 'auth/urls';

import PlannerPaths from 'modules/planner/routing/paths';

import { PATHS as SubstitutePaths } from 'modules/substitute/paths';
import { useLoadMyAccessRoles } from 'app/memory/apollo/my-access-roles/remote';
import { getDataRoutes } from 'app/routes';
import { useAccessTokenExpirationIntervalCheck } from './use-access-token-expiration-interval-check';
import { authStore, useAuthState } from './auth-store';

const FILE_NAME = 'use-auth-handler';

const isStringQueryParam = (param: string | string[]): param is string => {
  return isString(param);
};

export const useAuthHandler = () => {
  const { replace } = useHistory();
  const locale = useLocale();
  const { texts } = useTranslations();
  const client = useApolloClient();
  const { loadMyAccessRoles } = useLoadMyAccessRoles({ client });
  const setMyAccessRoles = useAccessRolesUpdater();
  const { getTokenByTransient } = useGetTokenByTransient();

  const {
    getIdentity,
    getHasPinCode,
    refresh,
    doOnboard,
    // reload,
  } = useAuthContext();

  const { organization } = useOrganization();
  const routes = useModuleRoutes();
  // const { selected: organization } = useOrganizationsState();

  // const [authStoreState, setAuthStoreState] = useState(authStore.initialState);
  // useLayoutEffect(() => {
  //   const subscription = authStore.subscribe(setAuthStoreState);
  //   return () => {
  //     subscription.unsubscribe();
  //   };
  // }, []);
  const { authState } = useAuthState();
  const { updateToken } = useUpdateToken();

  const { transient } = queryString.parse(location?.search ?? '');

  useEffect(() => {
    if (transient) {
      if (isStringQueryParam(transient)) {
        getTokenByTransient(transient).then((tokens) => {
          logger.debug({
            title: 'getTokenByTransient: SUCCESS',
            logger: FILE_NAME,
            type: LogType.Info,
            value: [
              {
                accessToken: tokens?.accessToken,
                sessionToken: tokens?.sessionToken,
              },
            ],
          });
          doOnboard(tokens?.accessToken ?? '').then(() => {
            loadMyAccessRoles().then((data) => {
              setMyAccessRoles(data ?? null);
              replace(
                getInitialPageStorage().getInitialPage()?.path ??
                  URLS.home(locale),
              );
            });
          });
        });
      }
    }
  }, [
    replace,
    transient,
    getTokenByTransient,
    loadMyAccessRoles,
    locale,
    setMyAccessRoles,
    doOnboard,
  ]);

  useEffect(() => {
    if (transient) {
      return;
    }
    const identity = getIdentity();
    const { getSessionToken } = getSessionTokenStorage();

    refresh(
      {
        accessToken: identity?.token ?? null,
        sessionToken: getSessionToken() ?? null,
      },
      true,
    );
  }, [getHasPinCode, getIdentity, refresh, transient]);

  const plannerMatch = useRouteMatch(PlannerPaths.path);
  const substituteMatch = useRouteMatch(SubstitutePaths.path);
  const loginMatch = useRouteMatch(AuthPaths.path);
  const lockMatch = useRouteMatch(AuthPaths.Lock.path);

  useEffect(() => {
    if (transient) {
      return;
    }

    const { mode, modeChanged } = authState;
    const module = plannerMatch
      ? Modules.planner
      : substituteMatch
      ? Modules.substitute
      : '';

    const routes = getDataRoutes({ module, locale, texts, organization });
    const route = getActiveRoute(routes);
    if (route.id && !route.private) {
      return;
    }

    switch (mode) {
      case 'identity-refreshed':
        // reload();
        loadMyAccessRoles().then((data) => {
          setMyAccessRoles(data ?? null);
        });
        break;
      case 'identity':
        if (modeChanged) {
          loadMyAccessRoles().then((data) => {
            setMyAccessRoles(data ?? null);
            if (lockMatch || loginMatch) {
              // reload();
              replace(
                getInitialPageStorage().getInitialPage()?.path ??
                  URLS.home(locale),
              );
            }
          });
        }
        break;
      case 'lock':
        if (modeChanged && !lockMatch) {
          const { transient } = queryString.parse(location?.search ?? '');
          // reload();
          setMyAccessRoles(null);
          if (transient) {
            getInitialPageStorage().setInitialPage({
              path: location?.pathname ?? '',
              search: location?.search,
            });
            // const url = `${AuthUrls.lock(locale)}${location?.search}`;
            updateToken().then(() => {
              authStore.updateIdentityIfLocked();
              const url = `${AuthUrls.home(locale)}${location?.search}`;
              replace(url);
            });
          } else {
            modeChanged && replace(AuthUrls.lock(locale));
          }
        }
        break;
      case 'logged-out':
        if (!loginMatch) {
          const { transient } = queryString.parse(location?.search ?? '');
          // reload();
          setMyAccessRoles(null);
          if (transient) {
            getInitialPageStorage().setInitialPage({
              path: location?.pathname ?? '',
              search: location?.search,
            });
            const url = `${AuthUrls.home(locale)}${location?.search}`;
            replace(url);
          } else {
            modeChanged && replace(AuthUrls.home(locale));
          }
        }
        break;
      default:
        break;
    }
  }, [
    authState,
    loadMyAccessRoles,
    locale,
    lockMatch,
    loginMatch,
    organization,
    plannerMatch,
    replace,
    routes,
    setMyAccessRoles,
    substituteMatch,
    texts,
    transient,
    updateToken,
  ]);

  useAccessTokenExpirationIntervalCheck();
};
