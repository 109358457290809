import { JournalType } from 'core/dna/types/generated/_globalTypes';

export enum RestockTypes {
  diapers = 'diapers',
  indoor = 'indoor',
  outdoor = 'outdoor',
  other = 'other',
}

export interface JournalEntryDataType {
  [JournalType.restock]?: {
    comment: string;
    restockType: RestockTypes;
  };
  [JournalType.diaper]?: string;
  [JournalType.sleep]?: string;
  [JournalType.note]?: string;
}

export interface JournalEntryData {
  data: JournalEntryDataType;
}
