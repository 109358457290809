import { OrganizationLevel, RouteConfig } from 'core/dna/types/local';
import { OasLanguage } from 'core/dna/routing';
import { OasLocales } from 'core/dna/translations/types';

import { PATHS } from './paths';
import { Dashboard } from './pages/dashboard';
import { AccessDenied } from './pages/access-denied';

export const getRoutes = (
  locale: OasLanguage,
  texts: OasLocales,
): RouteConfig[] => [
  {
    id: `dashboard-${PATHS.path}`,
    name: 'Dashboard',
    mini: 'DB',
    private: true,
    path: PATHS.path,
    component: Dashboard,
    sidebar: false,
    level: OrganizationLevel.Unset,
    exact: true,
  },
  {
    id: `access-denied-${PATHS.AccessDenied.path}`,
    name: 'Access denied',
    mini: 'AD',
    private: true,
    path: PATHS.AccessDenied.path,
    component: AccessDenied,
    sidebar: false,
    level: OrganizationLevel.Unset,
    exact: true,
  },
];
