import { OasLanguage, URLS } from 'core/dna/routing';

import { PATHS } from 'modules/student/paths';

const { getOasUrlWithoutParams, getOasUrl } = URLS;

const getDefaultUrl = (locale?: OasLanguage) => {
  return getOasUrlWithoutParams({
    pathname: PATHS.Cards.path,
    locale,
  });
};

const getStudentCardUrl = (input: { id?: string; locale?: OasLanguage }) => {
  return getOasUrl({
    pathname: PATHS.Cards.Card.path,
    params: [
      {
        key: PATHS.Cards.params.cardId.pathKey,
        value: input.id,
      },
    ],
    locale: input.locale,
  });
};

export default {
  home: getDefaultUrl,
  studentCard: getStudentCardUrl,
};
