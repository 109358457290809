import {
  ViewApi,
  EventApi as FcEventApi,
  DurationInput as FcDurationInput,
  Duration,
  EventInput as FcEventInput,
  EventContentArg as FcEventContentArg,
  CalendarOptions as FcCalendarOptions,
  ComponentChildren as FcComponentChildren,
} from '@fullcalendar/core';
import {
  CustomContentGenerator,
  DayCellContentArg as FcDayCellContentArg,
} from '@fullcalendar/common';

export type EventApi = FcEventApi;
export type DurationInput = FcDurationInput;
export type EventInput = FcEventInput;
export type EventContentArg = FcEventContentArg;
export type CalendarOptions = FcCalendarOptions;
export type ComponentChildren = FcComponentChildren;
export type DayCellContentArg = FcDayCellContentArg;
export type DayCellContentType = CustomContentGenerator<DayCellContentArg>;

export type OverlapFunc = (
  stillEvent: EventApi,
  movingEvent: EventApi | null,
) => boolean;

export enum CalendarAction {
  AddEvent = 'add-event',
  DeleteEvent = 'delete-event',
}

export interface WeekEvent {
  id: string;
  title: string;
  weekDay?: number;
  startTime?: string;
  endTime?: string;
}

// https://fullcalendar.io/docs/resource-object
export interface Resource {
  id: string;
  title: string;
  extendedProps?: any;
  eventConstraint?: any;
  eventOverlap?: any;
  eventAllow?: any;
  eventBackgroundColor?: any;
  eventBorderColor?: any;
  eventTextColor?: any;
  eventClassNames?: any;
}

// https://fullcalendar.io/docs/select-callback
export interface SelectionInfo {
  start: Date;
  end: Date;
  startStr: string;
  endStr: string;
  allDay: boolean;
  jsEvent?: any;
  view?: any;
  resource?: Resource;
}

// https://fullcalendar.io/docs/eventRender
export interface EventRenderInfo {
  event: EventApi;
  el: HTMLElement;
  isMirror: boolean;
  isStart: boolean;
  isEnd: boolean;
  view: ViewApi;
}

// https://fullcalendar.io/docs/eventResize
export interface EventResizeInfo {
  event: EventApi;
  relatedEvents?: EventApi[];
  oldEvent: EventApi;
  endDelta: Duration;
  startDelta: Duration;
  revert: () => void;
  view: ViewApi;
  el: HTMLElement;
  jsEvent: Event;
}

// https://fullcalendar.io/docs/eventDrop
export interface EventDropInfo {
  delta: Duration;
  el: HTMLElement;
  event: EventApi;
  jsEvent: Event;
  oldEvent: EventApi;
  revert: () => void;
  view: ViewApi;
  newResource?: Resource;
  oldResource?: Resource;
}

// https://fullcalendar.io/docs/drop
export interface DropInfo {
  allDay: boolean;
  date: Date;
  dateStr: string;
  draggedEl: HTMLElement;
  jsEvent: MouseEvent;
  view: ViewApi;
  resource?: Resource;
}
