import { useContext } from 'react';

import { ModuleRoutesContext } from './module-routes-context';

export const useModuleRoutes = () => {
  const moduleRoutes = useContext(ModuleRoutesContext);
  if (typeof moduleRoutes === 'undefined') {
    throw new Error(
      'useModuleRoutes must be used within a ModuleRoutesProvider',
    );
  }
  return moduleRoutes;
};
