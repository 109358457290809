import React from 'react';
import { isAndroid, isIOS, isMobile } from 'react-device-detect';

import appStoreDownloadImage from 'core/atoms/pictures/app-store-download.png';
import googlePlayDownloadImage from 'core/atoms/pictures/google-play-download.png';

import { useParams } from 'core/dna/routing';

import { ShareDesktop } from './components/share-desktop';
import { ShareMobile } from './components/share-mobile';
import { ShareNotSupportedMobile } from './components/share-not-supported-mobile';

export const Share = () => {
  const { shareId } = useParams<any>();

  if (!isMobile) {
    return <ShareDesktop />;
  }

  if (isAndroid) {
    return (
      <ShareMobile
        shareId={shareId}
        href="https://play.google.com/store/apps/details?id=no.transponder.schedule"
        image={googlePlayDownloadImage}
        alt="google-play-download"
      />
    );
  }

  if (isIOS) {
    return (
      <ShareMobile
        shareId={shareId}
        href="https://apps.apple.com/no/app/transponder-timeplan/id1523564897?l=nb"
        image={appStoreDownloadImage}
        alt="app-store-download"
      />
    );
  }

  return <ShareNotSupportedMobile />;
};
