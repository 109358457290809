import { Fragment } from 'react';
import { Check, List as ListIcon } from '@material-ui/icons';

import { createStyles, makeStyles, Theme } from 'core/atoms/styles';
import { formatDateString } from 'core/atoms/functions/string';

import { Grid } from 'core/cells/grid';
import { Skeleton } from 'core/cells/skeleton';
import { ListItem } from 'core/cells/list-item';
import { ListItemText } from 'core/cells/list-item-text';
import { Divider } from 'core/cells/divider';
import { List } from 'core/cells/list';

import { ModuleState } from 'core/dna/types/local';
import { ModuleModeEnum, Modules } from 'core/dna/types/modules';
import { useTranslations } from 'core/dna/translations';
import { isOrganizationHasAccessRole } from 'core/dna/functions';
import { AccessRoleEnum } from 'core/dna/types/access-roles';
import { useAccessRolesState } from 'core/dna/access-roles';
import { useSelectedOrganizationState } from 'core/dna/organizations';
import { useModulesState } from 'core/dna/modules';
import { ModuleSelector } from 'core/dna/translations/types';

import {
  Card,
  CardBody,
  CardHeader,
  CardIcon,
} from 'templates/mdp/components/card';
import { useStyles as useTableStyles } from 'templates/mdp/views/tables/react-tables.styles';

import { useModuleSelector } from 'app/organisms/module-selector/use-module-selector';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    item: {
      cursor: 'pointer',
      color: theme.palette.primary.main,
      '&:hover': {
        backgroundColor: theme.palette.action.hover,
      },
    },
    itemText: {
      margin: '6px 0',
      flex: 'inherit',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      width: '100%',
    },
  }),
);

export const Dashboard = () => {
  const tableClasses = useTableStyles();
  const classes = useStyles();

  const { texts } = useTranslations();

  const selectedOrgState = useSelectedOrganizationState();
  const { onChange, data } = useModuleSelector();
  const modules = useModulesState();
  const myAccessRoles = useAccessRolesState();

  const filteredModules = modules.filter((m) => m.id !== Modules.unset);
  const availableModules = filteredModules.filter(
    (m) => m.mode === ModuleModeEnum.active || m.mode === ModuleModeEnum.demo,
  );
  const notAvailableModules = filteredModules.filter(
    (m) => m.mode === ModuleModeEnum.expired || m.mode === ModuleModeEnum.off,
  );

  const isAdmin = isOrganizationHasAccessRole(
    myAccessRoles,
    selectedOrgState?.id,
    AccessRoleEnum.admin,
  );

  const modeContent = (module: ModuleState) => {
    const expiresAt = module.expiresAt
      ? formatDateString(module.expiresAt)
      : '';

    return module.mode === ModuleModeEnum.demo
      ? `${texts.oasCommon.featureMode[module.mode]} (${expiresAt})`
      : texts.oasCommon.featureMode[module.mode];
  };

  return !data.length ? (
    <Skeleton variant="rect" width="100%" height="80vh" />
  ) : (
    <Grid container spacing={3}>
      <Grid item xs={12} lg={6}>
        <Card>
          <CardHeader color="primary" icon>
            <CardIcon color="primary">
              <Check />
            </CardIcon>
            <h4 className={tableClasses.cardIconTitle}>
              {texts.dashboard.myServices}
            </h4>
          </CardHeader>
          <CardBody>
            <List>
              {availableModules.map((module) => (
                <Fragment key={module.id}>
                  <ListItem
                    className={classes.item}
                    onClick={() => onChange(module.id)}
                  >
                    <ListItemText
                      className={classes.itemText}
                      primary={
                        module.text ??
                        texts.oasCommon.moduleSelector[
                          module.id as keyof ModuleSelector
                        ]
                      }
                    />
                  </ListItem>
                  <Divider component="li" />
                </Fragment>
              ))}
            </List>
          </CardBody>
        </Card>
      </Grid>
      {isAdmin && (
        <Grid item xs={12} lg={6}>
          <Card>
            <CardHeader color="primary" icon>
              <CardIcon color="primary">
                <ListIcon />
              </CardIcon>
              <h4 className={tableClasses.cardIconTitle}>
                {texts.dashboard.otherServices}
              </h4>
            </CardHeader>
            <CardBody>
              <List>
                {notAvailableModules.map((module) => (
                  <Fragment key={`not-available--${module.id}`}>
                    <ListItem>
                      <ListItemText
                        className={classes.itemText}
                        primary={module.text}
                        secondary={modeContent(module)}
                      />
                    </ListItem>
                    <Divider component="li" />
                  </Fragment>
                ))}
              </List>
            </CardBody>
          </Card>
        </Grid>
      )}
    </Grid>
  );
};
