// import queryString from 'query-string';

import { AuthorizationCallback } from 'auth/dna/types';

import { URLS } from 'core/dna/routing';

import { getInitialPageStorage } from 'core/memory/browser';

export const defaultAuthCallback: AuthorizationCallback = (
  identity,
  options,
) => {
  const { getInitialPage: getBrowserInitialPage } = getInitialPageStorage();

  const initialPage = getBrowserInitialPage();

  let url: string;
  if (initialPage) {
    url = initialPage.path; // + qs;
  } else {
    url = URLS.orgWelcome(options?.locale ?? URLS.defaultLocale); // + qs;
  }
  options?.navigateTo?.(url, {
    from: options?.from ?? 'defaultAuthCallback',
  });
};
