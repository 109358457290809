import { useCallback, useMemo } from 'react';

import { TextValue } from 'core/atoms/types';

import { SelectChangeEvent } from 'core/cells/select5';

import { useGetClasses } from 'modules/planner/memory/apollo/classes/remote';
import {
  useGetSelectedClassesState,
  useSetSelectedClassesState,
} from 'modules/planner/memory/apollo/classes/local';

export const useClassSelector = () => {
  const { classes: classData, classesLoading, classesError } = useGetClasses();
  const { setSelectedClassesState } = useSetSelectedClassesState();
  const { selectedClassesState } = useGetSelectedClassesState();

  const classes: TextValue[] = useMemo(() => {
    return (classData ?? []).map((cls) => ({
      text: cls.name || 'noname',
      value: cls.id,
    }));
  }, [classData]);

  const onChange = useCallback(
    (event: SelectChangeEvent<string[]>) => {
      setSelectedClassesState(event.target.value as string[]);
    },
    [setSelectedClassesState],
  );

  return {
    classes,
    selectedClasses: selectedClassesState ?? [],
    onChange,
    setSelectedClassesState,
    classesLoading,
    classesError,
  };
};
