import { useCallback } from 'react';

import { Link } from 'core/cells/link';
import { Typography } from 'core/cells/typography';

import { useOasHistory } from 'core/metabolism/use-oas-history';

import { ScheduleTableItem } from 'modules/planner/dna/types/schedule';

import { useSetSelectedSchedules } from 'modules/planner/metabolism/schedules';

export interface ColPageLinkProps {
  item: ScheduleTableItem;
  text: string;
  pageUrl: string;
}

export const ColPageLink = (props: ColPageLinkProps) => {
  const { push } = useOasHistory();
  const { setSelectedSchedules } = useSetSelectedSchedules();

  const { item, text, pageUrl } = props;

  const handleClick = useCallback(() => {
    setSelectedSchedules([item.id]);
    // setSchedulesSelectedState({
    //   schedules: [mapScheduleToScheduleStateInput(item)],
    // });
    push(pageUrl);
    return false;
  }, [item.id, pageUrl, push, setSelectedSchedules]);

  return item ? (
    <Link onClick={handleClick}>
      <Typography>{text}</Typography>
    </Link>
  ) : (
    <span> </span>
  );
};
