import { makeStyles, createStyles, CSSProperties } from 'core/atoms/styles';

export interface TypographyPageStyles {
  typo: CSSProperties;
  note: CSSProperties;
}

export const typographyPageStyles: TypographyPageStyles = {
  typo: {
    paddingLeft: '25%',
    marginBottom: '40px',
    position: 'relative',
  },
  note: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    bottom: '10px',
    color: '#c0c1c2',
    display: 'block',
    fontWeight: 400,
    fontSize: '13px',
    lineHeight: '13px',
    left: '0',
    marginLeft: '20px',
    position: 'absolute',
    width: '260px',
  },
};

export const useStyles = makeStyles(() =>
  createStyles({ ...typographyPageStyles }),
);
