import { useCallback, useState } from 'react';
import Check from '@material-ui/icons/Check';

import { makeStyles, createStyles, Theme } from 'core/atoms/styles';
import { Identity } from 'core/atoms/types';

import { Grid } from 'core/cells/grid';
import { List } from 'core/cells/list';
import { ListItem } from 'core/cells/list-item';
import { ListItemIcon } from 'core/cells/list-item-icon';
import { ListItemText } from 'core/cells/list-item-text';
import { Checkbox } from 'core/cells/checkbox';
import { TextFilterField } from 'core/cells/text-filter-field';
import { Button } from 'core/cells/button';

import { useTranslations } from 'core/dna/translations';
import { useStyles } from 'templates/mdp/views/forms/regular-forms.styles';

export interface ImportListProps {
  checked: string[];
  onCheck: (checked: string[]) => void;
  onClear: () => void;
  data: Identity[];
  getText: (item: any) => string;
  filterPredicate?: (item: any, filterText: string) => boolean;
  onImport?: () => void;
  width?: number;
}

const useLocalStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: (props: ImportListProps) => props.width ?? 260,
      padding: theme.spacing(2),
    },
  }),
);

export const ImportList = (props: ImportListProps) => {
  const {
    checked,
    onCheck,
    onClear,
    data,
    filterPredicate,
    getText,
    onImport,
  } = props;

  const classes = useLocalStyles(props);
  const regularFormsClasses = useStyles();

  const { lang } = useTranslations();

  const [filter, setFilter] = useState<string>('');

  const handleToggle = useCallback(
    (value: string) => () => {
      const currentIndex = checked.indexOf(value);
      const newChecked = [...checked];

      if (currentIndex === -1) {
        newChecked.push(value);
      } else {
        newChecked.splice(currentIndex, 1);
      }
      onCheck(newChecked);
    },
    [checked, onCheck],
  );

  const handleClearSelection = useCallback(() => {
    onClear();
  }, [onClear]);

  return (
    <div className={classes.root}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <TextFilterField
            label={lang.common.filter}
            value={filter}
            onChange={(e) => {
              setFilter(e.target.value);
            }}
            onFilterClick={() => {
              setFilter('');
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Button color="secondary" fullWidth onClick={handleClearSelection}>
            {lang.common.clear}
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Button
            variant="outlined"
            color="primary"
            fullWidth
            onClick={onImport}
          >
            {lang.common.import}
            {checked.length ? ` (${checked.length})` : ''}
          </Button>
        </Grid>
        <Grid item xs={12}>
          <List>
            {data
              .filter((item) =>
                filter && filterPredicate
                  ? filterPredicate(item, filter)
                  : true,
              )
              .map((item) => {
                const { id } = item;
                const labelId = `list-label-${id}`;

                return (
                  <ListItem
                    key={id}
                    role={undefined}
                    dense
                    button
                    onClick={handleToggle(id)}
                  >
                    <ListItemIcon>
                      <Checkbox
                        edge="start"
                        checked={checked.indexOf(id) !== -1}
                        tabIndex={-1}
                        disableRipple
                        inputProps={{ 'aria-labelledby': labelId }}
                        checkedIcon={
                          <Check className={regularFormsClasses.checkedIcon} />
                        }
                        icon={
                          <Check
                            className={regularFormsClasses.uncheckedIcon}
                          />
                        }
                        classes={{
                          checked: regularFormsClasses.checked,
                          root: regularFormsClasses.checkRoot,
                        }}
                      />
                    </ListItemIcon>
                    <ListItemText id={labelId} primary={getText(item)} />
                  </ListItem>
                );
              })}
          </List>
        </Grid>
      </Grid>
    </div>
  );
};
