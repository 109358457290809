import { memo } from 'react';
import { Skeleton } from '@material-ui/lab';

import { makeStyles, createStyles } from 'core/atoms/styles';

import { Select } from 'core/cells/select';

import { useTranslations } from 'core/dna/translations';

import { useTermSelector } from './use-term-selector';

const useStyles = makeStyles(
  createStyles({
    root: {
      padding: 10,
    },
    formControl: {
      minWidth: 130,
    },
  }),
);

const TermSelectorComponent = () => {
  const classes = useStyles();

  const {
    onChange,
    termsLoading,
    termsError,
    termsData,
    termsDisabled,
    value,
    termCreating,
  } = useTermSelector();

  const { lang } = useTranslations();

  if (termsError) return <p>Terms Error!</p>;

  return (
    <div className={classes.root}>
      {termsLoading ? (
        <Skeleton variant="rect" width={180} height={40} />
      ) : !termsData ? (
        <>No termsData</>
      ) : (
        <Select
          // palette="light"
          name="term"
          label={lang.common.term}
          className={classes.formControl}
          data={termsData}
          value={value}
          onChange={onChange}
          disabled={termsDisabled || termCreating}
          notFoundText={'...'}
        />
      )}
    </div>
  );
};

TermSelectorComponent.displayName = 'Term Selector';

export const TermSelector = memo(TermSelectorComponent);
