import { useCallback } from 'react';

import { useHistory } from 'core/dna/routing';
import { useTranslations } from 'core/dna/translations';
import { CreateLessonGroupLessonsInput } from 'core/dna/types/generated/_globalTypes';

import {
  useCreateLessonGroup,
  useEditLessonGroup,
} from 'modules/planner/memory/apollo/lesson-groups/remote';

import { LessonItem, LessonProperty } from './types';
import {
  mapSubjectToSubjectInput,
  mapTeacherToTeacherInput,
  mapRoomToRoomInput,
  mapChapterToChapterInput,
} from './components/lesson-group-creator/functions';
import { useLgcState, lgcStore } from './store';

export interface UseLessonGroupConstructorDialogProps {
  scheduleId: string;
  lessonGroupId?: string;
}

export const useLessonGroupConstructor = (
  props: UseLessonGroupConstructorDialogProps,
) => {
  const { scheduleId, lessonGroupId } = props;

  const { texts } = useTranslations();
  const { goBack } = useHistory();

  const {
    lgcState: {
      lessons,
      subjects,
      teachers,
      rooms,
      chapters,
      plannedMinutesPerTimeSlot,
      canBeSplit,
      // mustBeNonstop,
    },
  } = useLgcState();

  const {
    createLessonGroup,
    loading: createLessonGroupLoading,
    error: createLessonGroupError,
  } = useCreateLessonGroup();

  const {
    loading: editLessonGroupLoading,
    error: editLessonGroupError,
    editLessonGroup,
  } = useEditLessonGroup();

  const saveLessonGroupFunc = useCallback(() => {
    return new Promise<any>((resolve, reject) => {
      try {
        if (!subjects?.length) {
          lgcStore.setErrorSubjects(
            texts.planner.plannerLessons.subjectRequired,
          );
          // eslint-disable-next-line prefer-promise-reject-errors
          reject(texts.planner.plannerLessons.subjectRequired);
        } else if (!plannedMinutesPerTimeSlot.length) {
          lgcStore.setErrorPlannedMinutesPerTimeSlot(
            texts.planner.common.fieldIsRequired,
          );
          // eslint-disable-next-line prefer-promise-reject-errors
          reject(texts.planner.common.fieldIsRequired);
        } else {
          const lessonsInput: CreateLessonGroupLessonsInput[] = lessons
            ? lessons.map((l: LessonItem) => {
                const byLesson = (lp: LessonProperty) => lp.lessonId === l.id;
                return {
                  subjects: subjects
                    .filter(byLesson)
                    .map(mapSubjectToSubjectInput),
                  teachers: teachers
                    ? teachers.filter(byLesson).map(mapTeacherToTeacherInput)
                    : [],
                  rooms: rooms
                    ? rooms.filter(byLesson).map(mapRoomToRoomInput)
                    : [],
                  chapters: chapters
                    ? chapters.filter(byLesson).map(mapChapterToChapterInput)
                    : [],
                };
              })
            : [];

          if (lessonGroupId) {
            editLessonGroup({
              lessonGroupId,
              lessons: lessonsInput,
              plannedMinutesPerTimeSlot,
              mustBeNonstop: !canBeSplit,
            })
              .then((result) => {
                resolve({ action: 'edit', result });
              })
              // eslint-disable-next-line prefer-promise-reject-errors
              .catch(() => reject('Error to update a lesson group'));
          } else {
            return createLessonGroup({
              scheduleId,
              lessons: lessonsInput,
              plannedMinutesPerTimeSlot,
              mustBeNonstop: !canBeSplit,
            })
              .then((result) => {
                resolve({ action: 'create', result });
              })
              .catch(() => {
                // eslint-disable-next-line prefer-promise-reject-errors
                reject({ message: texts.oasCommon.error });
              });
          }
        }
      } catch (e: any) {
        // eslint-disable-next-line prefer-promise-reject-errors
        reject({
          title: texts.planner.common.error500.title,
          message: `${texts.planner.common.error500.title}: ${texts.planner.common.error500.description}`,
        });
      }
    });
  }, [
    chapters,
    createLessonGroup,
    editLessonGroup,
    texts,
    lessonGroupId,
    lessons,
    canBeSplit,
    plannedMinutesPerTimeSlot,
    rooms,
    scheduleId,
    subjects,
    teachers,
  ]);

  const clearData = () => lgcStore.reset();

  const close = () => {
    clearData();
    goBack();
  };

  return {
    saveLessonGroup: saveLessonGroupFunc,
    saveLessonGroupLoading: lessonGroupId
      ? editLessonGroupLoading
      : createLessonGroupLoading,
    saveLessonGroupError: lessonGroupId
      ? editLessonGroupError
      : createLessonGroupError,
    clearData,
    close,
  };
};
