import { ReactNode, useMemo } from 'react';

import { NavPills } from 'core/cells/nav-pills';

import { Class } from 'modules/planner/dna/types/class';

import {
  useGetSelectedClassState,
  useSetSelectedClassState,
} from 'modules/planner/memory/apollo/classes/local';

interface ClassesTabsProps {
  classesList: Class[];
  tabContent: () => ReactNode;
}

export const ClassesTabs = (props: ClassesTabsProps) => {
  const { classesList, tabContent } = props;

  const { selectedClassState } = useGetSelectedClassState();
  const { setSelectedClassesState } = useSetSelectedClassState();

  const tabs = useMemo(
    () =>
      classesList.map((cls, tabIndex) => {
        return {
          id: cls.id,
          tabIndex,
          tabButton: cls.name,
          tabContent: tabContent(),
        };
      }),
    [tabContent, classesList],
  );

  return (
    <NavPills
      alignCenter
      notRounded
      tabs={tabs}
      value={selectedClassState}
      onChange={setSelectedClassesState}
    />
  );
};
