import { useMemo } from 'react';

import { useTranslations } from 'core/dna/translations';
import { Carer } from 'modules/student/dna/types/carer';
import { longNorPhoneRegex, shortNorPhoneRegex } from 'core/atoms/reg-ex';

export const useCarerTableRead = (carers: Carer[] | undefined) => {
  const { texts } = useTranslations();

  const columns = useMemo(
    () => [
      {
        Header: texts.planner.common.firstName,
        accessor: 'firstName',
        disableFilters: true,
      },
      {
        Header: texts.planner.common.firstName,
        accessor: 'lastName',
        disableFilters: true,
      },
      {
        Header: texts.oasCommon.email,
        accessor: 'mail',
        disableFilters: true,
      },
      {
        Header: texts.oasCommon.phone,
        accessor: 'mobile',
        disableFilters: true,
      },
    ],
    [texts],
  );

  const toShortFormat = (phone: string) => {
    if (longNorPhoneRegex.test(phone)) {
      return phone.slice(2);
    }

    if (shortNorPhoneRegex.test(phone)) {
      return phone;
    }

    return '';
  };

  const tableData = useMemo(
    () =>
      carers?.map((carer) => ({
        ...carer,
        mobile: carer.mobile && toShortFormat(carer.mobile),
      })) ?? [],
    [carers],
  );

  return { columns, tableData };
};
