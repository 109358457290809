import { makeStyles, Theme, createStyles } from 'core/atoms/styles';
import { grey } from 'core/atoms/colors';

import { Backdrop } from 'core/cells/backdrop';
// import { WaitingIndicator } from 'Cells/waiting-indicator';
import { Typography } from 'core/cells/typography';

import { useTranslations } from 'core/dna/translations';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#8e8e8e',
      position: 'absolute',
    },
    backdropContainer: {
      position: 'relative',
      width: '100%',
      height: '100%',
    },
    text: {
      position: 'absolute',
      bottom: theme.spacing(6),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      width: '100%',
      zIndex: theme.zIndex.drawer + 1,
      color: grey['50'],
    },
  }),
);

export interface WaitingBackdropProps {
  show: boolean;
  showText: boolean;
}

export const WaitingBackdrop = (props: WaitingBackdropProps) => {
  const classes = useStyles();
  const { show, showText } = props;

  const { texts } = useTranslations();

  return (
    <Backdrop className={classes.root} open={show} invisible={!show}>
      <div className={classes.backdropContainer}>
        {/* <WaitingIndicator /> */}
        {showText && (
          <div className={classes.text}>
            <Typography variant="h5">
              {
                texts.planner.preparationSpace.schedulesModule
                  .timePlanBackdropTitle
              }
            </Typography>
            <Typography variant="subtitle1">
              {
                texts.planner.preparationSpace.schedulesModule
                  .timePlanBackdropSubtitle
              }
            </Typography>
          </div>
        )}
      </div>
    </Backdrop>
  );
};
