import { OasLanguage, URLS } from 'core/dna/routing';

import { PATHS } from 'modules/grades/paths';

const { getOasUrlWithoutParams, getOasUrl } = URLS;

const getDefaultUrl = (locale?: OasLanguage) => {
  return getOasUrlWithoutParams({
    pathname: PATHS.Assessment.path,
    locale,
  });
};

const getBatchSubjectsUrl = (input: {
  batchId: string;
  locale?: OasLanguage;
}) => {
  return getOasUrl({
    pathname: PATHS.Preparation.Subjects.path,
    params: [
      {
        key: PATHS.Preparation.Subjects.params.batchId.pathKey,
        value: input.batchId,
      },
    ],
    locale: input.locale,
  });
};

const getPrepareEvaluationGroupUrl = (input: {
  evaluationGroupId: string;
  locale?: OasLanguage;
}) => {
  return getOasUrl({
    pathname: PATHS.Preparation.EvaluationGroup.path,
    params: [
      {
        key: PATHS.Preparation.EvaluationGroup.params.evaluationGroupId.pathKey,
        value: input.evaluationGroupId,
      },
    ],
    locale: input.locale,
  });
};

const getAssessmentUrl = (locale?: OasLanguage) => {
  return getOasUrlWithoutParams({
    pathname: PATHS.Assessment.path,
    locale,
  });
};

const getOpenRequestsForGradesUrl = (input: {
  batchId: string;
  subjectCode: string;
  locale?: OasLanguage;
}) => {
  return getOasUrl({
    pathname: PATHS.Assessment.OpenRequestsForGrades.path,
    params: [
      {
        key: PATHS.Assessment.OpenRequestsForGrades.params.batchId.pathKey,
        value: input.batchId,
      },
      {
        key: PATHS.Assessment.OpenRequestsForGrades.params.subjectCode.pathKey,
        value: input.subjectCode,
      },
    ],
    locale: input.locale,
  });
};

export default {
  home: getDefaultUrl,
  batchSubjects: getBatchSubjectsUrl,
  prepareEvaluationGroup: getPrepareEvaluationGroupUrl,
  assessment: getAssessmentUrl,
  openRequestsForGradesUrl: getOpenRequestsForGradesUrl,
};
