import { useParams } from 'core/dna/routing';

import { useGetSelectedScheduleState } from 'modules/planner/memory/apollo/schedules/local';
import { useGetScheduleDescriptions } from 'modules/planner/memory/apollo/schedules/remote';

import { LessonGroupConstructor } from '../../components/lesson-group-constructor';

/**  /plan/schedules/:scheduleId?/lesson-groups/:lessonGroupId/edit  */
export const LessonGroupsEdit = () => {
  const { lessonGroupId } = useParams<{ lessonGroupId: string }>();

  // this is needed for getting the title text
  useGetScheduleDescriptions();

  const { selectedScheduleState } = useGetSelectedScheduleState();
  return (
    <LessonGroupConstructor
      scheduleId={selectedScheduleState ?? ''}
      lessonGroupId={lessonGroupId}
    />
  );
};
