import { useState, useLayoutEffect } from 'react';

import { lgcStore } from './lgc-store';

export const useLgcState = () => {
  const [state, setState] = useState(lgcStore.initialState);

  useLayoutEffect(() => {
    const subscription = lgcStore.subscribe(setState);
    return () => {
      subscription.unsubscribe();
    };
  }, []);

  return {
    lgcState: state,
  };
};
