import React, { useState, useCallback } from 'react';
import clsx from 'clsx';
import { NavLink } from 'react-router-dom';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Hidden from '@material-ui/core/Hidden';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

import Menu from '@material-ui/icons/Menu';
import Fingerprint from '@material-ui/icons/Fingerprint';
// import LockOpen from '@material-ui/icons/LockOpen';

import { useTranslations } from 'core/dna/translations';
import { useRouteMatch, useLocale, matchPath } from 'core/dna/routing';

import { useOasHistory } from 'core/metabolism/use-oas-history';

import { useAuthContext } from 'auth/metabolism/use-auth-context';

import AuthPaths from 'auth/paths';
import AuthUrls from 'auth/urls';

import { Button } from 'templates/mdp/components/custom-buttons';

import { useStyles } from './auth-navbar.styles';

export interface AuthNavbarProps {
  color?: 'primary' | 'info' | 'success' | 'warning' | 'danger'; // PropTypes.oneOf([]),
  brandText?: string; // PropTypes.string,
}

export const AuthNavbar = (props: AuthNavbarProps) => {
  const [open, setOpen] = useState(false);
  const { texts } = useTranslations();
  const locale = useLocale();
  const handleDrawerToggle = () => {
    setOpen(!open);
  };

  const { replace } = useOasHistory();
  const { logout } = useAuthContext();

  // verifies if routeName is the one active (in browser input)
  const activeRoute = (routeName: string) => {
    return matchPath(window.location.pathname, {
      path: routeName,
      exact: true,
    });
  };

  const handleLogout = useCallback(() => {
    logout?.();
    replace(AuthUrls.home(locale));
  }, [locale, logout, replace]);

  const lockMatch = useRouteMatch(AuthPaths.Lock.path);
  const logoutMatch = useRouteMatch(AuthPaths.LoggedOut.path);
  const classes = useStyles();
  const { color, brandText } = props;
  const appBarClasses = clsx(color ? classes[color] : '');
  const list = (
    <List className={classes.list}>
      {/* <ListItem className={classes.listItem}> */}
      {/*  <NavLink to={'/admin/dashboard'} className={classes.navLink}> */}
      {/*    <Dashboard className={classes.listItemIcon} /> */}
      {/*    <ListItemText */}
      {/*      primary={'Dashboard'} */}
      {/*      disableTypography={true} */}
      {/*      className={classes.listItemText} */}
      {/*    /> */}
      {/*  </NavLink> */}
      {/* </ListItem> */}
      {/* <ListItem className={classes.listItem}> */}
      {/*  <NavLink */}
      {/*    to={'/auth/pricing-page'} */}
      {/*    className={clsx(classes.navLink, { */}
      {/*      [classes.navLinkActive]: activeRoute('/auth/pricing-page'), */}
      {/*    })} */}
      {/*  > */}
      {/*    <MonetizationOn className={classes.listItemIcon} /> */}
      {/*    <ListItemText */}
      {/*      primary={'Pricing'} */}
      {/*      disableTypography={true} */}
      {/*      className={classes.listItemText} */}
      {/*    /> */}
      {/*  </NavLink> */}
      {/* </ListItem> */}
      {/* <ListItem className={classes.listItem}> */}
      {/*  <NavLink */}
      {/*    to={'/auth/register-page'} */}
      {/*    className={clsx(classes.navLink, { */}
      {/*      [classes.navLinkActive]: activeRoute('/auth/register-page'), */}
      {/*    })} */}
      {/*  > */}
      {/*    <PersonAdd className={classes.listItemIcon} /> */}
      {/*    <ListItemText */}
      {/*      primary={'Register'} */}
      {/*      disableTypography={true} */}
      {/*      className={classes.listItemText} */}
      {/*    /> */}
      {/*  </NavLink> */}
      {/* </ListItem> */}
      {!!logoutMatch && (
        <ListItem className={classes.listItem}>
          <NavLink
            to={AuthUrls.home(locale)}
            className={clsx(classes.navLink, {
              [classes.navLinkActive]: activeRoute(AuthPaths.path),
            })}
          >
            <Fingerprint className={classes.listItemIcon} />
            <ListItemText
              primary={texts.planner.login.primaryButton}
              disableTypography={true}
              className={classes.listItemText}
            />
          </NavLink>
        </ListItem>
      )}
      <ListItem className={classes.listItem}>
        {!!lockMatch && (
          <Button color="secondary" block onClick={handleLogout}>
            {texts.planner.common.signOut}
          </Button>
        )}
        {/* <NavLink */}
        {/*  to={AuthUrls.home(locale)} */}
        {/*  className={clsx(classes.navLink, { */}
        {/*    [classes.navLinkActive]: activeRoute(AuthPaths.path), */}
        {/*  })} */}
        {/* > */}
        {/*  <Fingerprint className={classes.listItemIcon} /> */}
        {/*  <ListItemText */}
        {/*    primary={lang.login.primaryButton} */}
        {/*    disableTypography={true} */}
        {/*    className={classes.listItemText} */}
        {/*  /> */}
        {/* </NavLink> */}
      </ListItem>
      {/* <ListItem className={classes.listItem}> */}
      {/*  <NavLink */}
      {/*    to={AuthUrls.lock(locale)} */}
      {/*    className={clsx(classes.navLink, { */}
      {/*      [classes.navLinkActive]: activeRoute(AuthPaths.Lock.path), */}
      {/*    })} */}
      {/*  > */}
      {/*    <LockOpen className={classes.listItemIcon} /> */}
      {/*    <ListItemText */}
      {/*      primary={texts.auth.lockScreen} */}
      {/*      disableTypography={true} */}
      {/*      className={classes.listItemText} */}
      {/*    /> */}
      {/*  </NavLink> */}
      {/* </ListItem> */}
    </List>
  );
  return (
    <AppBar position="static" className={clsx(classes.appBar, appBarClasses)}>
      <Toolbar className={classes.container}>
        <Hidden smDown>
          <div className={classes.flex}>
            <Button href="#" className={classes.title} color="transparent">
              {brandText}
            </Button>
          </div>
        </Hidden>
        <Hidden mdUp>
          <div className={classes.flex}>
            <Button href="#" className={classes.title} color="transparent">
              {brandText}
            </Button>
          </div>
        </Hidden>
        <Hidden smDown>{list}</Hidden>
        <Hidden mdUp>
          <Button
            className={classes.sidebarButton}
            color="transparent"
            justIcon
            aria-label="open drawer"
            onClick={handleDrawerToggle}
          >
            <Menu />
          </Button>
        </Hidden>
        <Hidden mdUp>
          <Hidden mdUp>
            <Drawer
              variant="temporary"
              anchor="right"
              open={open}
              classes={{
                paper: classes.drawerPaper,
              }}
              onClose={handleDrawerToggle}
              ModalProps={{
                keepMounted: true, // Better open performance on mobile.
              }}
            >
              {list}
            </Drawer>
          </Hidden>
        </Hidden>
      </Toolbar>
    </AppBar>
  );
};
