import { SubstituteJobState } from 'core/dna/types/generated/_globalTypes';

import { SubstituteTask } from './substitute-task';
import { SubstituteInvitation } from './substitute-invitation';

export interface JobPublic {
  description: string;
  state?: SubstituteJobState;
  tasks?: SubstituteTask[];
  deadline: string | null;
}

export interface Job extends JobPublic {
  id: string;
  internalTitle: string;
  deadline: string;
  invitations?: SubstituteInvitation[];
}

export interface JobTableItem extends Job {
  responses?: string;
}

export const emptyJobItem: JobTableItem = {
  id: '',
  description: '',
  internalTitle: '',
  deadline: '',
  responses: '0/0',
};
