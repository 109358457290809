import {
  makeStyles,
  createStyles,
  CSSProperties,
  Theme,
} from 'core/atoms/styles';

import {
  containerFluid,
  defaultFont,
  primaryColor,
  defaultBoxShadow,
  infoColor,
  successColor,
  warningColor,
  dangerColor,
  whiteColor,
  grayColor,
  drawerWidth,
} from 'templates/mdp/material-dashboard-pro-react';

export interface DashboardNavbarStyle {
  appResponsive: CSSProperties;
  appBar: CSSProperties;
  container: CSSProperties;
  flex: CSSProperties;
  title: CSSProperties;
  primary: CSSProperties;
  info: CSSProperties;
  success: CSSProperties;
  warning: CSSProperties;
  danger: CSSProperties;
  sidebarMinimize: CSSProperties;
  sidebarMinimizeRTL: CSSProperties;
  sidebarMiniIcon: CSSProperties;
  firstLeftMargin: CSSProperties;
  intercomAfterBreadcrumbs: CSSProperties;
}

export const dashboardNavbarStyle = (theme: Theme): DashboardNavbarStyle => ({
  appResponsive: {
    top: '8px',
  },
  appBar: {
    backgroundColor: 'transparent',
    boxShadow: 'none',
    borderBottom: '0',
    marginBottom: '0',
    position: 'relative',
    // position: 'absolute',
    // [theme-light.breakpoints.up('md')]: {
    //   width: `calc(100% - ${drawerWidth}px)`,
    // },
    width: '100%',
    paddingTop: '10px',
    // zIndex: 1029,
    color: grayColor[6],
    border: '0',
    borderRadius: 0, // '3px',
    padding: '10px 0',
    transition: 'all 150ms ease 0s',
    minHeight: '50px',
    display: 'block',
  },
  container: {
    ...containerFluid,
    minHeight: '50px',
  },
  flex: {
    flex: 1,
  },
  title: {
    ...defaultFont,
    lineHeight: '30px',
    fontSize: '18px',
    borderRadius: '3px',
    textTransform: 'none',
    color: 'inherit',
    paddingTop: '0.625rem',
    paddingBottom: '0.625rem',
    margin: '0 !important',
    letterSpacing: 'unset',
    '&:hover,&:focus': {
      background: 'transparent',
    },
  },
  primary: {
    backgroundColor: primaryColor[0],
    color: whiteColor,
    ...defaultBoxShadow,
  },
  info: {
    backgroundColor: infoColor[0],
    color: whiteColor,
    ...defaultBoxShadow,
  },
  success: {
    backgroundColor: successColor[0],
    color: whiteColor,
    ...defaultBoxShadow,
  },
  warning: {
    backgroundColor: warningColor[0],
    color: whiteColor,
    ...defaultBoxShadow,
  },
  danger: {
    backgroundColor: dangerColor[0],
    color: whiteColor,
    ...defaultBoxShadow,
  },
  sidebarMinimize: {
    float: 'left',
    padding: '0 0 0 15px',
    display: 'block',
    color: grayColor[6],
  },
  sidebarMinimizeRTL: {
    padding: '0 15px 0 0 !important',
  },
  sidebarMiniIcon: {
    width: '20px',
    height: '17px',
  },
  firstLeftMargin: {
    marginLeft: -28,
  },
  intercomAfterBreadcrumbs: {
    marginLeft: 6,
    paddingTop: 6,
  },
});

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({ ...dashboardNavbarStyle(theme) }),
);
