import { useMemo } from 'react';
import clsx from 'clsx';

import MuiBox, { BoxProps as MuiBoxProps } from '@material-ui/core/Box';

import { makeStyles, createStyles } from 'core/atoms/styles';

export interface BoxProps extends MuiBoxProps {
  /** Aligns content centered both horizontally and vertically inside the Box */
  centeredContent?: boolean;
  /** Sets the component sizes as in outer container with zero margin and padding */
  fullSize?: boolean;
  /** Sets the component margin and padding to zero */
  noSpacing?: boolean;
}

const useStyles = makeStyles(() =>
  createStyles({
    centeredContent: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    centeredHorizontally: {
      display: 'flex',
      justifyContent: 'center',
    },
    centeredVertically: {
      display: 'flex',
      alignItems: 'center',
    },
    fullSize: {
      width: '100%',
      height: '100%',
    },
    noSpacing: {
      margin: 0,
      padding: 0,
    },
  }),
);

export const Box = (props: BoxProps) => {
  const classes = useStyles(props);

  const {
    centeredContent,
    fullSize,
    noSpacing,
    className: propsClassName,
    ...other
  } = props;

  const className = useMemo(() => {
    return clsx(
      centeredContent ? classes.centeredContent : '',
      fullSize ? classes.fullSize : '',
      noSpacing ? classes.noSpacing : '',
    );
  }, [
    centeredContent,
    fullSize,
    noSpacing,
    classes.centeredContent,
    classes.fullSize,
    classes.noSpacing,
  ]);

  return <MuiBox {...other} className={clsx(propsClassName, className)} />;
};

Box.defaultProps = {
  centeredContent: false,
  fullSize: false,
  noSpacing: false,
};
