import { useState, ReactNode } from 'react';

import { DraggableType, DropFn } from 'core/atoms/types';

import { DroppableOutlinedArea } from 'core/cells/droppable-outlined-area';

export interface LessonDroppableSectionProps {
  label: string;
  type: DraggableType;
  onDrop?: DropFn;
  minWidth?: number | string;
  minHeight?: number | string;
  children?: ReactNode;
}

const defaultProps: Partial<LessonDroppableSectionProps> = {
  minWidth: '100%',
  // TODO: Need to think how to use theme-light spacing to set default props
  minHeight: 64,
};

export const LessonDroppableSection = (props: LessonDroppableSectionProps) => {
  const {
    label,
    type,
    onDrop,
    minWidth = defaultProps.minWidth,
    minHeight = defaultProps.minHeight,
    children,
  } = props;

  const [isOver, setIsOver] = useState<boolean>(false);

  return (
    <DroppableOutlinedArea
      minWidth={minWidth}
      minHeight={minHeight}
      label={label}
      type={type}
      isOver={isOver}
      onOverChanged={setIsOver}
      onDrop={onDrop}
    >
      {children}
    </DroppableOutlinedArea>
  );
};

LessonDroppableSection.defaultProps = defaultProps;
