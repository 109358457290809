/**
=========================================================
* Material Dashboard 2 PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Material Dashboard 2 PRO React base styles
import colors from '../../base/colors';
import borders from '../../base/borders';
import typography from '../../base/typography';

// Material Dashboard 2 PRO React helper functions
import pxToRem from '../../functions/pxToRem';

const { light, text, dark } = colors;
const { borderRadius } = borders;
const { size } = typography;

export default {
  styleOverrides: {
    root: {
      '&.MuiButtonBase-root': {
        display: 'flex',
        minWidth: pxToRem(160),
        minHeight: 'unset',
        padding: `${pxToRem(4.8)} ${pxToRem(16)}`,
        borderRadius: borderRadius.md,
        fontSize: size.sm,
        color: text.main,
        transition: 'background-color 100ms ease, color 100ms ease',

        '&:hover, &:focus, &.Mui-selected, &.Mui-selected:hover, &.Mui-selected:focus': {
          backgroundColor: light.main,
          color: dark.main,
        },
      },
    },
  },
};
