import { useCallback } from 'react';
import clsx from 'clsx';

import { makeStyles, createStyles } from 'core/atoms/styles';
import { hexToRgbA } from 'core/atoms/functions/hex-to-rgba';
import { parseTemplate } from 'core/atoms/functions/string';

import { Form, Field, FormController } from 'core/cells/forms';
import { FormTextField } from 'core/cells/form-text-field';
import { FlexBox } from 'core/cells/box';
import { FormLabel } from 'core/cells/form-label';
import { ColorPicker } from 'core/cells/color-picker';

import { useTranslations } from 'core/dna/translations';
import { WriteModeEnum } from 'core/dna/types/write-mode';

import { WriteTableModal } from 'core/organisms/write-modal';

import { Button } from 'templates/mdp/components/custom-buttons';
import { customCheckboxRadioSwitch } from 'templates/mdp/custom-checkbox-radio-switch';
import { GridContainer, GridItem } from 'templates/mdp/components/grid';
import { DeepWarning } from 'templates/mdp/components/typography/deep-warning';

import { useSubjectsTableWrite } from './use-subjects-table-write';

interface Values {
  name: string;
  alias?: string;
  code?: string;
  bgColor?: string;
  annualHours?: string;
}

interface SubjectFormProps {
  defaultName: string;
  defaultAlias?: string;
  defaultCode?: string;
  defaultBgColor?: string;
  defaultAnnualHours?: number;
  id?: string;
  open?: boolean;
  setOpen?: (open: boolean) => void;
}

export const useStyles = makeStyles(() =>
  createStyles({
    ...customCheckboxRadioSwitch,
    colorsLabel: { paddingTop: 0, float: 'left' },
    colorRow: { marginTop: 12, marginBottom: 12 },
    flexVCenter: { display: 'block', paddingTop: 6 },
  }),
);

export const SubjectEditor = (props: SubjectFormProps) => {
  const {
    open,
    setOpen,
    id,
    defaultName,
    defaultAlias,
    defaultCode,
    defaultBgColor = '#cacaca',
    defaultAnnualHours,
  } = props;

  const classes = useStyles();
  const { onRowAdd, onRowUpdate } = useSubjectsTableWrite();
  const { lang, texts } = useTranslations();

  const onClose = useCallback(() => {
    setOpen?.(false);
  }, [setOpen]);

  return (
    <WriteTableModal
      isShow={open ?? false}
      onClose={onClose}
      writeMode={id ? WriteModeEnum.update : WriteModeEnum.create}
      title={
        id
          ? lang.sourceDataSpace.subjectsModule.editSubject
          : lang.sourceDataSpace.subjectsModule.addSubject
      }
    >
      {!id && (
        <DeepWarning>
          {texts.planner.sourceDataSpace.subjectsModule.addSubjectWarning}
        </DeepWarning>
      )}
      <FormController
        initialValues={{
          name: defaultName,
          alias: defaultAlias,
          code: defaultCode,
          bgColor: defaultBgColor,
          annualHours: defaultAnnualHours,
        }}
        validate={(values) => {
          const errors: Partial<Values> = {};
          if (!values.name) {
            errors.name = texts.oasCommon.validation.required;
          }
          if (values.annualHours && values.annualHours < 0) {
            errors.annualHours = texts.oasCommon.validation.zeroOrGreater;
          } else if (!values.annualHours && values.annualHours !== 0) {
            errors.annualHours = texts.oasCommon.validation.required;
          } else if (!Number.isInteger(values.annualHours)) {
            errors.annualHours = texts.oasCommon.validation.integer;
          }
          if (values.alias && values.alias.length > 10) {
            errors.alias = parseTemplate(
              texts.oasCommon.validation.stringLength,
              { length: 10 },
            );
          }
          return errors;
        }}
        onSubmit={async (values) => {
          setOpen?.(false);
          id
            ? await onRowUpdate({
                id,
                name: values.name,
                alias: values.alias,
                code: values.code,
                bgColor: values.bgColor,
                annualHours: Number(values.annualHours),
              })
            : await onRowAdd({
                name: values.name,
                alias: values.alias,
                code: values.code,
                bgColor: values.bgColor,
                annualHours: Number(values.annualHours),
              });
        }}
      >
        {({ submitForm, isSubmitting, setFieldValue, values }) => (
          <Form>
            <Field
              component={FormTextField}
              name="name"
              type="text"
              label={lang.common.names.one}
              disabled={isSubmitting}
              fullWidth
            />
            <Field
              component={FormTextField}
              name="annualHours"
              type="number"
              label={texts.planner.annualHours60m}
              disabled={isSubmitting}
              fullWidth
            />
            <Field
              component={FormTextField}
              name="alias"
              type="text"
              label={lang.common.shortNames.one}
              disabled={isSubmitting}
              fullWidth
            />
            <Field
              component={FormTextField}
              name="code"
              type="text"
              label={lang.common.codes.one}
              disabled={isSubmitting}
              fullWidth
            />

            <GridContainer className={classes.colorRow}>
              <GridItem xs={12} sm={12} md={3} className={classes.flexVCenter}>
                <FormLabel
                  className={clsx(classes.labelHorizontal, classes.colorsLabel)}
                >
                  {lang.common.colors.one}
                </FormLabel>
              </GridItem>
              <GridItem xs={12} sm={12} md={9}>
                <ColorPicker
                  color={hexToRgbA(values.bgColor ?? defaultBgColor)}
                  onChange={(color) => {
                    setFieldValue('bgColor', color.hex);
                  }}
                />
              </GridItem>
            </GridContainer>

            <FlexBox justifyContent="flex-end">
              <Button
                color="secondary"
                disabled={isSubmitting}
                onClick={submitForm}
              >
                {id ? lang.common.save : lang.common.add}
              </Button>
              <Button onClick={onClose} color="secondary" simple>
                {lang.common.close}
              </Button>
            </FlexBox>
          </Form>
        )}
      </FormController>
    </WriteTableModal>
  );
};
