import {
  makeStyles,
  createStyles,
  Theme,
  CSSProperties,
} from 'core/atoms/styles';

import {
  drawerWidth,
  drawerMiniWidth,
  transition,
  containerFluid,
} from '../material-dashboard-pro-react';

export interface AppStyle {
  wrapper: CSSProperties;
  mainPanel: CSSProperties;
  content: CSSProperties;
  container: CSSProperties;
  map: CSSProperties;
  mainPanelSidebarMini: CSSProperties;
  mainPanelWithPerfectScrollbar: CSSProperties;
}

export const appStyle = (theme: Theme): AppStyle => ({
  wrapper: {
    position: 'relative',
    top: '0',
    height: '100vh',
    '&:after': {
      display: 'table',
      clear: 'both',
      content: '" "',
    },
  },
  mainPanel: {
    transitionProperty: 'top, bottom, width',
    transitionDuration: '.2s, .2s, .35s',
    transitionTimingFunction: 'linear, linear, ease',
    [theme.breakpoints.up('md')]: {
      width: `calc(100% - ${drawerWidth}px)`,
    },
    overflow: 'auto',
    position: 'relative',
    float: 'right',
    ...transition,
    maxHeight: '100%',
    width: '100%',
    overflowScrolling: 'touch',
  },
  content: {
    marginTop: '70px',
    padding: '30px 15px',
    minHeight: 'calc(100vh - 123px)',
  },
  container: { ...containerFluid },
  map: {
    marginTop: '70px',
  },
  mainPanelSidebarMini: {
    [theme.breakpoints.up('md')]: {
      width: `calc(100% - ${drawerMiniWidth}px)`,
    },
  },
  mainPanelWithPerfectScrollbar: {
    overflow: 'hidden !important',
  },
});

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({ ...appStyle(theme) }),
);
