import moment from 'moment';
import { useEffect, useMemo } from 'react';
import { useLazyQuery } from 'core/dna/types/apollo';
import { mapApiPersonT2ShortDataToPersonT2ShortData } from 'modules/student/dna/functions/map-api-person-t2-short-data-to-person-t2-short-data';
import { mapApiRemarkToRemarkList } from 'modules/student/dna/functions/map-api-remark-to-remark';
import { mapApiCarerToCarerList } from 'modules/student/dna/functions/map-api-carer-to-carer';
import { mapApiAbsenceToAbsenceList } from 'modules/student/dna/functions/map-api-absence-to-absence';
import {
  mapApiConfigurationListToConfigurationObject,
  mapApiJournalToJournalList,
} from 'modules/student/dna/functions';
import { PersonT2 } from 'modules/student/dna/types';
import {
  GetStudentDetails,
  GetStudentDetailsVariables,
} from 'core/dna/types/generated/GetStudentDetails';
import { useTerm } from 'modules/planner/metabolism/terms';
import { GET_STUDENT_DETAILS_QUERY } from './gql';

export const useGetStudentDetails = (props: GetStudentDetailsVariables) => {
  const { tuid, first, order } = props;

  const [getStudentDetails, { data, error, loading }] = useLazyQuery<
    GetStudentDetails,
    GetStudentDetailsVariables
  >(GET_STUDENT_DETAILS_QUERY, {
    errorPolicy: 'all',
    fetchPolicy: 'no-cache',
  });

  const { term, termId } = useTerm();

  const dateFrom = moment(term?.startDate).startOf('day').utc().toISOString();
  const dateTo = moment(term?.endDate).endOf('day').utc().toISOString();

  useEffect(() => {
    if (term && termId) {
      getStudentDetails({
        variables: {
          tuid,
          first,
          order,
          input: {
            from: dateFrom,
            to: dateTo,
          },
        },
      });
    }
  }, [dateFrom, dateTo, first, getStudentDetails, order, term, termId, tuid]);

  const studentDetails = useMemo<PersonT2 | undefined>(() => {
    if (loading || !data) {
      return;
    }

    if (!data.PersonT2 || !data.PersonT2.length || !data.PersonT2[0]) {
      return;
    }

    const shortData = mapApiPersonT2ShortDataToPersonT2ShortData(
      data.PersonT2[0],
    );

    // (absences/remarks/carers === null) means absences/remarks/carers has error
    return {
      ...shortData,
      absence:
        data.PersonT2[0].absences === null
          ? null
          : mapApiAbsenceToAbsenceList(data.PersonT2[0].absences),
      remarks:
        data.PersonT2[0].remarks === null
          ? null
          : mapApiRemarkToRemarkList(data.PersonT2[0].remarks),
      carers:
        data.PersonT2[0].carers === null
          ? null
          : mapApiCarerToCarerList(data.PersonT2[0].carers),
      configuration:
        data.PersonT2[0]?.configurations === null
          ? null
          : mapApiConfigurationListToConfigurationObject(
              data.PersonT2[0].configurations,
            ),
      journals:
        data.PersonT2[0]?.journals === null
          ? null
          : mapApiJournalToJournalList(data.PersonT2[0]?.journals),
    };
  }, [loading, data]);

  return {
    loading,
    error,
    data: studentDetails,
  };
};
