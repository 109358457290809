import { useEffect, useMemo, useState } from 'react';
import { Column } from 'react-table';

import { useTranslations } from 'core/dna/translations';
import { AbsenceState } from 'core/dna/types/generated/_globalTypes';

import { PersonDetailsT2Group } from 'modules/student/dna/types';
import { StudentWithTotalAbsences } from 'modules/reports/dna/types';

export interface StudentsAbsencesFullTableItem {
  firstName: string | null;
  lastName: string | null;
  groups: string;
  hoursMedical: number;
  hoursLeave: number;
  hoursUndocumented: number;
  hoursOpen: number;
  daysMedical: number;
  daysLeave: number;
  daysUndocumented: number;
  daysOpen: number;
  daysTotal: number;
  hoursTotal: number;
  daysExempt: number;
  hoursExempt: number;
}

export const useStudentsAbsenceFullTableRead = (
  data: StudentWithTotalAbsences[],
) => {
  const { texts } = useTranslations();

  const mapGroupsToString = (groups?: PersonDetailsT2Group[]) => {
    if (!groups?.length) {
      return '';
    }

    const modifiedGroups: string[] = [];

    groups.forEach((group) => {
      if (group.name) {
        modifiedGroups.push(group.name);
      }
    });

    return modifiedGroups.join(', ');
  };

  const preparedData = useMemo<StudentsAbsencesFullTableItem[]>(() => {
    if (!data) {
      return [];
    }

    return data.map((student) => {
      const { details, total } = student;

      return {
        firstName: details.firstName,
        lastName: details.lastName,
        groups: mapGroupsToString(details.groups),
        daysMedical: total.daysByState[AbsenceState.medical],
        daysLeave: total.daysByState[AbsenceState.leave],
        daysUndocumented: total.daysByState[AbsenceState.undocumented],
        daysOpen: total.daysByState[AbsenceState.open],
        hoursMedical: total.hoursByState[AbsenceState.medical],
        hoursLeave: total.hoursByState[AbsenceState.leave],
        hoursUndocumented: total.hoursByState[AbsenceState.undocumented],
        hoursOpen: total.hoursByState[AbsenceState.open],
        daysTotal: total.days,
        hoursTotal: total.hours,
        daysExempt: total.daysByState.exempt,
        hoursExempt: total.hoursByState.exempt,
      };
    });
  }, [data]);

  const [tableData, setTableData] = useState<StudentsAbsencesFullTableItem[]>(
    preparedData,
  );

  useEffect(() => {
    setTableData(preparedData);
  }, [preparedData]);

  const columns = useMemo<Column<StudentsAbsencesFullTableItem>[]>(
    () => [
      {
        Header: texts.planner.common.firstName,
        accessor: 'firstName',
      },
      {
        Header: texts.planner.common.lastName,
        accessor: 'lastName',
      },
      {
        Header: texts.oasCommon.group.one,
        accessor: 'groups',
      },
      {
        Header: texts.reports.absenceTable.daysMedical,
        accessor: 'daysMedical',
      },
      {
        Header: texts.reports.absenceTable.daysLeave,
        accessor: 'daysLeave',
      },
      {
        Header: texts.reports.absenceTable.daysUndocumented,
        accessor: 'daysUndocumented',
      },
      {
        Header: texts.reports.absenceTable.daysOpen,
        accessor: 'daysOpen',
      },
      {
        Header: texts.reports.absenceTable.daysTotal,
        accessor: 'daysTotal',
      },
      {
        Header: texts.reports.absenceTable.daysExcludedFromDiploma,
        accessor: 'daysExempt',
      },
      {
        Header: texts.reports.absenceTable.hoursMedical,
        accessor: 'hoursMedical',
      },
      {
        Header: texts.reports.absenceTable.hoursLeave,
        accessor: 'hoursLeave',
      },
      {
        Header: texts.reports.absenceTable.hoursUndocumented,
        accessor: 'hoursUndocumented',
      },
      {
        Header: texts.reports.absenceTable.hoursOpen,
        accessor: 'hoursOpen',
      },
      {
        Header: texts.reports.absenceTable.hoursTotal,
        accessor: 'hoursTotal',
      },
      {
        Header: texts.reports.absenceTable.hoursExcludedFromDiploma,
        accessor: 'hoursExempt',
      },
      // {
      //   Header: texts.studentModule.absence.stateValues![AbsenceState.medical],
      //   accessor: 'hoursMedical',
      // },
    ],
    [texts],
  );

  return {
    title: texts.studentModule.studentList,
    columns,
    tableData,
  };
};
