// enum Modules are different from Features !!! ("student" module === "absence" & "remarks" features)

export enum Modules {
  sms = 'sms',
  messageBook = 'messageBook',
  substitute = 'substitute',
  student = 'student',
  planner = 'planner',
  directory = 'directory',
  unset = 'unset',
  reports = 'reports',
  grades = 'grades',
}

export enum ModuleModeEnum {
  active = 'active',
  demo = 'demo',
  expired = 'expired',
  off = 'off',
}
