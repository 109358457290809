import { useMemo } from 'react';
import { useQuery, gql } from 'core/dna/types/apollo';
import { GetEvaluationBatchList } from 'core/dna/types/generated/GetEvaluationBatchList';

import { mapApiEvaluationBatchToEvaluationBatchList } from 'modules/grades/dna/functions';
import { EvaluationBatch } from 'modules/grades/dna/types';

import { EVALUATION_BATCH_FRAGMENT } from 'modules/grades/memory/apollo/_fragments';

export const GET_EVALUATION_BATCH_LIST_QUERY = gql`
  query GetEvaluationBatchList {
    EvaluationBatch {
      ...EvaluationBatch
    }
  }
  ${EVALUATION_BATCH_FRAGMENT}
`;

export const useGetEvaluationBatchList = () => {
  const { data, error, loading } = useQuery<GetEvaluationBatchList>(
    GET_EVALUATION_BATCH_LIST_QUERY,
  );

  const evaluationBatch = useMemo<EvaluationBatch[] | undefined>(() => {
    if (loading || error || !data) {
      return;
    }

    if (!data.EvaluationBatch) {
      return;
    }

    return mapApiEvaluationBatchToEvaluationBatchList(data.EvaluationBatch);
  }, [loading, error, data]);

  return {
    loading,
    error,
    data: evaluationBatch,
  };
};
