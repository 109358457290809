import { makeStyles, createStyles, CSSProperties } from 'core/atoms/styles';

import { grayColor } from '../../material-dashboard-pro-react';

export interface GridSystemStyle {
  title: CSSProperties;
}

export const gridSystemStyle: GridSystemStyle = {
  title: {
    color: grayColor[2],
    textDecoration: 'none',
  },
};

export const useStyles = makeStyles(() => createStyles({ ...gridSystemStyle }));
