import React from 'react';
import 'typeface-roboto';

import {
  ThemeProvider,
  createMuiTheme,
  withStyles,
  responsiveFontSizes,
} from 'core/atoms/styles';

import { NoSsr } from 'core/cells/no-ssr';
import { CssBaseline } from 'core/cells/css-baseline';

import { oasThemeOptions } from 'core/dna/theme';

const GlobalCss = withStyles({
  // @global is handled by jss-plugin-global.
  '@global': {
    html: {
      height: '100%',
      '& body': {
        height: '100%',
        '& div#oas-web-root': {
          height: '100%',
        },
      },
    },
  },
})(() => null);

let theme = createMuiTheme(oasThemeOptions);
theme = responsiveFontSizes(theme);

export const StylesProvider = ({ children }: any) => {
  return (
    <NoSsr>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <GlobalCss />
        {children}
      </ThemeProvider>
    </NoSsr>
  );
};
