import { useMemo } from 'react';
import { useQuery, gql } from 'core/dna/types/apollo';
import {
  GetEvaluationBatch,
  GetEvaluationBatchVariables,
} from 'core/dna/types/generated/GetEvaluationBatch';

import { mapApiEvaluationBatchToEvaluationBatchList } from 'modules/grades/dna/functions';
import { EvaluationBatch } from 'modules/grades/dna/types';

import { EVALUATION_BATCH_FRAGMENT } from 'modules/grades/memory/apollo/_fragments';

export const GET_EVALUATION_BATCH_QUERY = gql`
  query GetEvaluationBatch($id: ValidateString!) {
    EvaluationBatch(filter: { teacherTuid: $id }) {
      ...EvaluationBatch
    }
  }
  ${EVALUATION_BATCH_FRAGMENT}
`;

export const useGetEvaluationBatch = (teacherTuid: string) => {
  const { data, error, loading } = useQuery<
    GetEvaluationBatch,
    GetEvaluationBatchVariables
  >(GET_EVALUATION_BATCH_QUERY, { variables: { id: teacherTuid } });

  const evaluationBatch = useMemo<EvaluationBatch | undefined>(() => {
    if (loading || error || !data) {
      return;
    }

    if (!data.EvaluationBatch) {
      return;
    }

    const evaluationBatchList = mapApiEvaluationBatchToEvaluationBatchList(
      data.EvaluationBatch,
    );

    return evaluationBatchList ? evaluationBatchList[0] : undefined;
  }, [loading, error, data]);

  return {
    loading,
    error,
    data: evaluationBatch,
  };
};
