import { BehaviorSubject } from 'rxjs';
import { produce } from 'immer';

import { logger, LogType } from 'core/atoms/housekeeping';
import { IdentityMap } from 'core/atoms/types';
import { OasError, OasErrorInterface, OasPageError } from 'core/atoms/errors';

import { SubjectInfo } from 'modules/planner/dna/types/subject';

export type ImportSubjectsResponseType = 'success' | 'duplicate' | 'error';

export interface ImportSubjectsResponse {
  type: ImportSubjectsResponseType;
  name?: string;
  message?: string;
}

export type ImportSource = 'sync' | 'default';

export interface SubjectsStoreState {
  checkedForImport: IdentityMap<SubjectInfo>;
  importListVisible: boolean;
  importSource: ImportSource | null;
  importing: boolean;
  importingError: OasErrorInterface | null;
}

const defaultIdentityMap: IdentityMap<any> = { allIds: [], byId: {} };

const initialState: SubjectsStoreState = {
  checkedForImport: defaultIdentityMap,
  importListVisible: false,
  importSource: null,
  importing: false,
  importingError: null,
};

const subject = new BehaviorSubject(initialState);

let state = initialState;

const emit = (state: SubjectsStoreState) => {
  subject.next(state);
};

const FILE_NAME = 'planner/source-data/classes/classes-store';

const getShowImportListState = (from: ImportSource) =>
  produce(state, (draft) => {
    draft.importSource = from;
    draft.importListVisible = true;
  });

export const subjectsStore = {
  subscribe: (setState: (value: SubjectsStoreState) => void) =>
    subject.subscribe(setState),
  checkIdsForImport: (ids: string[], map: IdentityMap<SubjectInfo>) => {
    state = produce(state, (draft) => {
      const result = { ...defaultIdentityMap };
      result.allIds = ids;
      result.byId = result.allIds.reduce((prev, cur) => {
        return { ...prev, [cur]: map.byId[cur] };
      }, {});
      draft.checkedForImport = result;
    });

    logger.debug({
      title: 'checkIdsForImport',
      logger: FILE_NAME,
      type: LogType.Info,
    });

    emit(state);
  },
  clearIdsForImport: () => {
    state = produce(state, (draft) => {
      draft.checkedForImport = defaultIdentityMap;
    });

    logger.debug({
      title: 'clearIdsForImport',
      logger: FILE_NAME,
      type: LogType.Info,
    });

    emit(state);
  },
  showImportListFromSync: () => {
    state = getShowImportListState('sync');

    logger.debug({
      title: 'showImportListFromSync',
      logger: FILE_NAME,
      type: LogType.Info,
    });

    emit(state);
  },
  showImportListFromDefault: () => {
    state = getShowImportListState('default');

    logger.debug({
      title: 'showImportListFromDefault',
      logger: FILE_NAME,
      type: LogType.Info,
    });

    emit(state);
  },
  hideImportList: () => {
    state = produce(state, (draft) => {
      draft.importListVisible = false;
      draft.importSource = null;
    });

    logger.debug({
      title: 'hideImportList',
      logger: FILE_NAME,
      type: LogType.Info,
    });

    emit(state);
  },
  importSubjects: async (
    func: () => Promise<PromiseSettledResult<ImportSubjectsResponse>[]>,
  ): Promise<ImportSubjectsResponse[]> => {
    let result: ImportSubjectsResponse[];
    state = produce(state, (draft) => {
      draft.importListVisible = false;
      draft.importing = true;
      draft.importingError = null;
    });
    emit(state);
    try {
      const response = await func();
      state = produce(state, (draft) => {
        draft.checkedForImport = defaultIdentityMap;
      });
      result = response.map((ri) => {
        if (ri.status === 'rejected') {
          return {
            name: ri.reason.name,
            type: ri.reason.type,
            message: ri.reason.message,
          } as ImportSubjectsResponse;
        }
        return {
          name: ri.value.name,
          type: ri.value.type,
          message: ri.value.message,
        } as ImportSubjectsResponse;
      });
    } catch (error) {
      let e: OasErrorInterface;
      if (error instanceof OasError) {
        e = error;
      } else {
        e = OasPageError.fromError(error, { title: FILE_NAME });
      }
      if ((e.originalMessage?.indexOf('DUPLICATE') ?? -2) > -1) {
        state = produce(state, (draft) => {
          draft.checkedForImport = defaultIdentityMap;
        });
        result = [{ type: 'duplicate', message: e.data.name }];
      }
      state = produce(state, (draft) => {
        draft.importingError = e;
      });
      result = [{ type: 'error' }];
    } finally {
      state = produce(state, (draft) => {
        draft.importing = false;
      });
    }
    emit(state);
    return result;
  },
  initialState,
};
