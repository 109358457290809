import { useCallback } from 'react';

import { SchedulesTabbedPage } from 'modules/planner/pages/plan/schedules/components/schedules-tabbed-page';

import { LessonGroupsTable } from './components/lesson-groups-table';

/**  /plan/schedules/:scheduleId?/lesson-groups  */
export const LessonGroups = () => {
  const getContent = useCallback(() => <LessonGroupsTable />, []);
  return <SchedulesTabbedPage tabContent={getContent} />;
};
