export interface IssueAccessTokenPinValid {
  accessToken?: string;
}

export interface IssueAccessTokenPinInvalid {
  error: string;
}

export type IssueAccessTokenResult =
  | IssueAccessTokenPinValid
  | IssueAccessTokenPinInvalid;

export function isIssueAccessTokenPinValid(
  input: IssueAccessTokenResult,
): input is IssueAccessTokenPinValid {
  return input.hasOwnProperty('accessToken');
}
