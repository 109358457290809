import { useCallback } from 'react';

import { parseTemplate } from 'core/atoms/functions/string';
import { makeStyles, createStyles } from 'core/atoms/styles';

import { FlexBox } from 'core/cells/box';
import { Form, Field, FormController } from 'core/cells/forms';
import { FormTextField } from 'core/cells/form-text-field';
import { FormSelect } from 'core/cells/form-select';

import { RoleType } from 'core/dna/types/generated/_globalTypes';
import { WriteModeEnum } from 'core/dna/types/write-mode';
import { useTranslations } from 'core/dna/translations';

import { WriteTableModal } from 'core/organisms/write-modal';

import { Button } from 'templates/mdp/components/custom-buttons';

import { useTeachersTableWrite } from './use-teachers-table-write';

interface Values {
  firstName: string;
  lastName: string;
  type: string;
  nin?: string;
  reservedEmploymentPercentage?: number | string;
  employmentPercentage?: number | string;
}

interface TeacherFormProps {
  defaultFirstName: string;
  defaultLastName: string;
  defaultType: string;
  defaultNin?: string;
  defaultReservedEmploymentPercentage?: number;
  defaultEmploymentPercentage?: number;
  id?: string;
  open?: boolean;
  setOpen?: (open: boolean) => void;
}

const getTypeFromString = (stringType: string) => {
  if (RoleType.assistant === stringType) {
    return RoleType.assistant;
  }
  if (RoleType.carer === stringType) {
    return RoleType.carer;
  }
  if (RoleType.staff === stringType) {
    return RoleType.staff;
  }
  if (RoleType.student === stringType) {
    return RoleType.student;
  }
  if (RoleType.teacher === stringType) {
    return RoleType.teacher;
  }
};

const useStyles = makeStyles(() =>
  createStyles({
    hidden: {
      display: 'none',
    },
  }),
);

export const TeacherEditor = (props: TeacherFormProps) => {
  const {
    open,
    setOpen,
    id,
    defaultFirstName,
    defaultLastName,
    defaultType,
    defaultNin,
    defaultReservedEmploymentPercentage = 0,
    defaultEmploymentPercentage = 100,
  } = props;

  const classes = useStyles();

  const { onRowAdd, onRowUpdate } = useTeachersTableWrite();

  const { texts } = useTranslations();

  const onClose = useCallback(() => {
    setOpen?.(false);
  }, [setOpen]);

  return (
    <WriteTableModal
      isShow={open ?? false}
      onClose={onClose}
      writeMode={id ? WriteModeEnum.update : WriteModeEnum.create}
      title={
        id
          ? texts.planner.plannerTeachers.editTeacher
          : texts.planner.plannerTeachers.addTeacher
      }
    >
      <FormController
        initialValues={{
          firstName: defaultFirstName,
          lastName: defaultLastName,
          type: defaultType,
          nin: defaultNin,
          reservedEmploymentPercentage: defaultReservedEmploymentPercentage,
          employmentPercentage: defaultEmploymentPercentage,
        }}
        validate={(values) => {
          const errors: Partial<Values> = {};
          if (values.type === RoleType.teacher) {
            if (
              values.reservedEmploymentPercentage < 0 ||
              values.reservedEmploymentPercentage > 100
            ) {
              errors.reservedEmploymentPercentage = parseTemplate(
                texts.oasCommon.validation.range,
                { v1: '0', v2: '100' },
              );
            }
          }
          if (
            values.employmentPercentage < 0 ||
            values.employmentPercentage > 100
          ) {
            errors.employmentPercentage = parseTemplate(
              texts.oasCommon.validation.range,
              { v1: '0', v2: '100' },
            );
          }
          if (!id) {
            if (!values.firstName) {
              errors.firstName = texts.oasCommon.validation.required;
            }
            if (!values.lastName) {
              errors.lastName = texts.oasCommon.validation.required;
            }
            if (!values.nin) {
              errors.nin = texts.oasCommon.validation.required;
            } else {
              const ninRegex = /^([0-9]{11})$/;
              if (!ninRegex.test(values.nin)) {
                errors.nin = texts.oasCommon.validation.nin;
              }
            }
          }
          return errors;
        }}
        onSubmit={async (values) => {
          setOpen?.(false);
          id
            ? await onRowUpdate({
                roleId: id,
                type: getTypeFromString(values.type) ?? RoleType.teacher,
                reservedEmploymentPercentage:
                  values.type === RoleType.teacher
                    ? Number(values.reservedEmploymentPercentage) ?? null
                    : 0,
                employmentPercentage:
                  Number(values.employmentPercentage) ?? null,
              })
            : await onRowAdd({
                firstName: values.firstName,
                lastName: values.lastName,
                type: getTypeFromString(values.type) ?? RoleType.teacher,
                nin: values.nin ?? '',
                staffDetails: {
                  reservedEmploymentPercentage:
                    values.type === RoleType.teacher
                      ? Number(values.reservedEmploymentPercentage) ?? null
                      : 0,
                  employmentPercentage:
                    Number(values.employmentPercentage) ?? null,
                },
              });
        }}
      >
        {({ submitForm, isSubmitting, values }) => (
          <Form>
            <Field
              component={FormTextField}
              name="firstName"
              type="text"
              label={texts.planner.common.firstName}
              disabled={isSubmitting || id}
              fullWidth
            />
            <Field
              component={FormTextField}
              name="lastName"
              type="text"
              label={texts.planner.common.lastName}
              disabled={isSubmitting || id}
              fullWidth
            />
            <Field
              component={FormSelect}
              name="type"
              label={texts.oasCommon.type}
              data={[
                { text: texts.oasCommon.teachers.one, value: RoleType.teacher },
                {
                  text: texts.oasCommon.assistants.one,
                  value: RoleType.assistant,
                },
                { text: texts.oasCommon.staff, value: RoleType.staff },
              ]}
              disabled={isSubmitting}
              fullWidth
            />
            <Field
              component={FormTextField}
              name="reservedEmploymentPercentage"
              type="number"
              label={texts.planner.otherAssignments}
              className={values.type !== RoleType.teacher ? classes.hidden : ''}
              disabled={isSubmitting}
              fullWidth
            />
            <Field
              component={FormTextField}
              name="employmentPercentage"
              type="number"
              label={texts.planner.employmentPercentage}
              disabled={isSubmitting}
              fullWidth
            />
            {!id && (
              <Field
                component={FormTextField}
                name="nin"
                type="text"
                label={texts.planner.common.nin}
                disabled={isSubmitting}
                fullWidth
              />
            )}
            <FlexBox justifyContent="flex-end">
              <Button
                color="secondary"
                disabled={isSubmitting}
                onClick={submitForm}
              >
                {id ? texts.planner.common.save : texts.planner.common.add}
              </Button>
              <Button onClick={onClose} color="secondary" simple>
                {texts.planner.common.close}
              </Button>
            </FlexBox>
          </Form>
        )}
      </FormController>
    </WriteTableModal>
  );
};
