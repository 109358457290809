import { compile, pathToRegexp } from 'path-to-regexp';

import { PATHS } from './paths';
import { OasLanguage } from './types';
import { PARAMS } from './params';
import { DEFAULT_LOCALE } from './constants';

interface GetOasUrlWithoutParamsInput {
  pathname: string;
  locale?: OasLanguage;
}

interface GetOasUrlInput {
  pathname: string;
  params: { key: string; value?: string }[];
  locale?: OasLanguage;
}

const generateLocaleUrl = (
  locale: string,
  location: { pathname: string },
  localeIsPath?: boolean,
) => {
  const ROUTE = `/${PARAMS.Locale.path}/:path*`;
  // const ROUTE = `/:locale(no|en|uk)/:path*`;
  const definePath = compile(ROUTE);

  const urlArray = location.pathname.split('?');

  const url = localeIsPath
    ? urlArray[0].replace(PARAMS.Locale.regexp, 'no')
    : urlArray[0];
  const routeComponents = pathToRegexp(ROUTE).exec(url);

  let subPaths = null;
  if (routeComponents && routeComponents[2]) {
    subPaths = routeComponents[2].split('/');
  }

  const pathWithoutQueryString = definePath({
    locale,
    path: subPaths,
  });

  return urlArray.length > 1
    ? `${pathWithoutQueryString}?${urlArray[1]}`
    : pathWithoutQueryString;
};

const getOasUrlWithoutParams = (input: GetOasUrlWithoutParamsInput) => {
  const { locale, pathname } = input;
  if (!locale) {
    return pathname;
  }
  return generateLocaleUrl(locale, { pathname }, true);
};

const getOasUrl = (input: GetOasUrlInput) => {
  const { pathname, params, locale } = input;
  const paramsUrl = params.reduce((total: string, current) => {
    if (!current.value) {
      return total.replace(`/${current.key}`, '');
    }
    return total.replace(current.key, current.value);
  }, pathname);

  if (!locale) {
    return paramsUrl;
  }
  return generateLocaleUrl(locale, { pathname: paramsUrl }, true);
};

const getHomeUrl = (locale?: OasLanguage) => {
  return getOasUrlWithoutParams({
    pathname: PATHS.Home.path,
    locale,
  });
};

const getOrganizationWelcomeUrl = (locale?: OasLanguage) => {
  return getOasUrlWithoutParams({
    pathname: PATHS.Home.path,
    locale,
  });
};

const getDirectoryPeopleUrl = (input: {
  locale?: OasLanguage;
  federationId: string;
}) => {
  return getOasUrlWithoutParams({
    pathname: PATHS.Directory.People.path,
    locale: input.locale,
  });
};

export const URLS = {
  defaultLocale: DEFAULT_LOCALE,
  generateLocaleUrl,
  getOasUrlWithoutParams,
  getOasUrl,
  home: getHomeUrl,
  orgWelcome: getOrganizationWelcomeUrl,
  directoryPeople: getDirectoryPeopleUrl,
};
