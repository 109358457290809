import { useMemo } from 'react';

import { useTranslations } from 'core/dna/translations';

import { LessonGroupTableItem } from 'modules/planner/dna/types/lesson-group';
import { mapLessonGroupToLessonGroupTableItem } from 'modules/planner/dna/functions';

import { useGetSchedule } from 'modules/planner/memory/apollo/schedules/remote';
import { useGetSelectedScheduleState } from 'modules/planner/memory/apollo/schedules/local';

export const useLessonGroupsTableRead = () => {
  const { texts } = useTranslations();

  const { selectedScheduleState } = useGetSelectedScheduleState();
  const { data: schedule, loading } = useGetSchedule({
    scheduleId: selectedScheduleState,
  });

  const columns = useMemo(
    () => [
      {
        Header: texts.planner.common.subjects,
        accessor: 'subjects',
      },
      {
        Header: texts.oasCommon.employees.one,
        accessor: 'teachers',
      },
      {
        Header: texts.planner.common.rooms,
        accessor: 'rooms',
      },
      {
        Header: texts.planner.common.groups.many,
        accessor: 'chapters',
      },
      {
        Header: texts.planner.preparationSpace.schedulesModule.numberOfMinutes,
        accessor: 'plannedMinutesPerTimeSlot',
      },
      {
        Header: texts.planner.preparationSpace.schedulesModule.canBeSplit,
        accessor: 'canBeSplit',
      },
      {
        Header: texts.planner.common.actions.many,
        accessor: 'actions',
      },
    ],
    [texts],
  );

  const data = useMemo<LessonGroupTableItem[] | undefined>(
    () =>
      schedule?.lessonGroups?.map((lg) =>
        mapLessonGroupToLessonGroupTableItem(lg, texts),
      ),
    [schedule, texts],
  );

  return {
    title: texts.planner.preparationSpace.schedulesModule.lessonGroups.many,
    columns,
    data,
    loading,
  };
};
