import React, { ReactNode } from 'react';
import clsx from 'clsx';

import { useStyles } from './typography.styles';

export interface MutedProps {
  children?: ReactNode; // PropTypes.node,
}

export const Muted = (props: MutedProps) => {
  const classes = useStyles();
  const { children } = props;
  return (
    <div className={clsx(classes.defaultFontStyle, classes.mutedText)}>
      {children}
    </div>
  );
};
