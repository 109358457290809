/* eslint-disable */
import React, { ReactNode } from 'react';
import clsx from 'clsx';

import MuiButton, { ButtonProps } from '@material-ui/core/Button';

import { useStyles } from './button.styles';

type RegularButtonColor =
  | 'primary'
  | 'secondary'
  | 'info'
  | 'success'
  | 'warning'
  | 'danger'
  | 'rose'
  | 'white'
  | 'twitter'
  | 'facebook'
  | 'google'
  | 'linkedin'
  | 'pinterest'
  | 'youtube'
  | 'tumblr'
  | 'github'
  | 'behance'
  | 'dribbble'
  | 'reddit'
  | 'transparent';

interface OmittedProps {
  color?: any;
  size?: any;
}

export interface RegularButtonProps
  extends Omit<ButtonProps, keyof OmittedProps> {
  color?: RegularButtonColor; // PropTypes.oneOf([])
  size?: 'sm' | 'lg'; // PropTypes.oneOf(['sm', 'lg']),
  simple?: boolean; // PropTypes.bool;
  round?: boolean; // PropTypes.bool;
  fullWidth?: boolean; // PropTypes.bool;
  disabled?: boolean; // PropTypes.bool;
  block?: boolean; // PropTypes.bool;
  link?: boolean; // PropTypes.bool;
  justIcon?: boolean; // PropTypes.bool;
  className?: string; // PropTypes.string;
  muiClasses?: Record<string, unknown>; // PropTypes.object;
  children?: ReactNode; // PropTypes.node;
  target?: string;
}

const RegularButton = React.forwardRef(
  (props: RegularButtonProps, ref: any) => {
    const classes = useStyles();
    const {
      color,
      size,
      simple,
      round,
      fullWidth,
      disabled,
      block,
      link,
      justIcon,
      className,
      muiClasses,
      children,
      ...rest
    } = props;

    const btnClasses = clsx(
      classes.button,
      size ? classes[size] : '',
      color ? classes[color] : '',
      round ? classes.round : '',
      fullWidth ? classes.fullWidth : '',
      disabled ? classes.disabled : '',
      simple ? classes.simple : '',
      block ? classes.block : '',
      link ? classes.link : '',
      justIcon ? classes.justIcon : '',
      className ?? '',
    );

    return (
      <MuiButton
        {...rest}
        ref={ref}
        classes={muiClasses}
        className={btnClasses}
      >
        {children}
      </MuiButton>
    );
  },
);

RegularButton.displayName = 'RegularButton';

export const Button = RegularButton;
