import clsx from 'clsx';

import { makeStyles, Theme, createStyles } from 'core/atoms/styles';

export interface IconTextContentProps {
  children: React.ReactElement;
  text: string;
  className?: string;
}

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      // height: '100%',
      display: 'inline-flex',
      flexWrap: 'nowrap',
      alignItems: 'center',
    },
  }),
);

export const IconTextContent = (props: IconTextContentProps) => {
  const classes = useStyles();

  return (
    <span className={clsx(classes.root, props.className)}>
      {props.children} {props.text}
    </span>
  );
};
