import { useCallback } from 'react';

import { OasPageError, OasError } from 'core/atoms/errors';
import { parseTemplate } from 'core/atoms/functions/string';

import { useSnackbar } from 'core/cells/notistack';

import { useTranslations } from 'core/dna/translations';

import { ClassTableItem } from 'modules/planner/dna/types/class';

import {
  useCreateClass,
  useEditClass,
  useDeleteClass,
} from 'modules/planner/memory/apollo/classes/remote';

const TITLE = 'use-classes-table-write';

export const useClassesTableWrite = () => {
  const { texts } = useTranslations();
  const { createClass } = useCreateClass();
  const { editClass } = useEditClass();
  const { deleteClass } = useDeleteClass();
  const { enqueueSnackbar } = useSnackbar();

  const onRowAdd = (newData: { name: string }) =>
    new Promise<any>((resolve, reject) => {
      createClass({
        name: newData.name,
      })
        .then((result) => {
          enqueueSnackbar(
            parseTemplate(texts.oasCommon.entityCreated, {
              entityType: texts.oasCommon.classes.one,
            }),
            {
              variant: 'success',
            },
          );
          resolve(result);
        })
        .catch((e) => {
          enqueueSnackbar(e.message, { variant: 'error' });
          reject(e);
        });
    });

  const onRowUpdate = (newData: { id: string; name: string }) =>
    new Promise<any>((resolve, reject) => {
      editClass({
        classId: newData.id,
        name: newData.name,
      })
        .then((result) => {
          enqueueSnackbar(
            texts.planner.plannerClasses.classUpdatedSuccessfully,
            { variant: 'success' },
          );
          resolve(result);
        })
        .catch((e) => {
          enqueueSnackbar(e.message, { variant: 'error' });
          reject(e);
        });
    });

  const onRowDelete = useCallback(
    async (oldData: ClassTableItem) => {
      try {
        const result = await deleteClass({ classId: oldData.id });

        if (result?.statusCode !== 'OK') {
          throw new OasPageError(
            result?.description ??
              parseTemplate(texts.oasCommon.deleteEntitiesOfTypeError.one, {
                entityType: texts.oasCommon.classes.one,
                entity: oldData.name,
              }),
            { title: TITLE },
          );
        }
        enqueueSnackbar(
          parseTemplate(texts.oasCommon.entitiesOfTypeWereDeleted.one, {
            entityType: texts.oasCommon.classes.one,
            entity: oldData.name,
          }),
          {
            variant: 'success',
          },
        );
        return 0;
      } catch (e: any) {
        const error =
          e instanceof OasError
            ? e
            : OasPageError.fromError(e, { title: TITLE });
        enqueueSnackbar(
          error.originalMessage ??
            parseTemplate(texts.oasCommon.deleteEntitiesOfTypeError.one, {
              entityType: texts.oasCommon.classes.one,
              entity: oldData.name,
            }),
          {
            variant: 'error',
          },
        );
        return 1;
      }
    },
    [
      deleteClass,
      enqueueSnackbar,
      texts.oasCommon.classes.one,
      texts.oasCommon.deleteEntitiesOfTypeError.one,
      texts.oasCommon.entitiesOfTypeWereDeleted.one,
    ],
  );

  return {
    onRowAdd,
    onRowUpdate,
    onRowDelete,
  };
};
