/* eslint-disable */
import React from 'react';
import clsx from 'clsx';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';

import { Card } from '../card';
import { Button } from '../custom-buttons';

import { WizardStyleClasses, wizardStyle } from './wizard.styles';

interface WizardStep {
  stepName: string; // PropTypes.string.isRequired,
  stepComponent: any; //PropTypes.object.isRequired,
  stepId: string; //PropTypes.string.isRequired,
}

type WizardColor =
  | 'primary'
  | 'warning'
  | 'danger'
  | 'success'
  | 'info'
  | 'rose';

export interface WizardProps {
  classes: WizardStyleClasses; // PropTypes.object.isRequired,
  steps: WizardStep[]; // PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  color?: WizardColor; // PropTypes.oneOf([]),
  title?: string; // PropTypes.string,
  subtitle?: string; // PropTypes.string,
  previousButtonClasses?: string; // PropTypes.string,
  previousButtonText?: string; // PropTypes.string,
  nextButtonClasses?: string; // PropTypes.string,
  nextButtonText?: string; // PropTypes.string,
  finishButtonClasses?: string; // PropTypes.string,
  finishButtonText?: string; // PropTypes.string,
  finishButtonClick?: (allStates: any) => void; // PropTypes.func,
  validate?: boolean; // PropTypes.bool,
}

class WizardComponent extends React.Component<WizardProps, any> {
  static defaultProps = {
    color: 'rose' as WizardColor,
    title: 'Here should go your title',
    subtitle: 'And this would be your subtitle',
    previousButtonText: 'Previous',
    previousButtonClasses: '',
    nextButtonClasses: '',
    nextButtonText: 'Next',
    finishButtonClasses: '',
    finishButtonText: 'Finish',
  };

  constructor(props: WizardProps) {
    super(props);
    let width;
    if (this.props.steps.length === 1) {
      width = '100%';
    } else {
      if (window.innerWidth < 600) {
        if (this.props.steps.length !== 3) {
          width = '50%';
        } else {
          width = 100 / 3 + '%';
        }
      } else {
        if (this.props.steps.length === 2) {
          width = '50%';
        } else {
          width = 100 / 3 + '%';
        }
      }
    }
    this.state = {
      currentStep: 0,
      color: this.props.color,
      nextButton: this.props.steps.length > 1,
      previousButton: false,
      finishButton: this.props.steps.length === 1,
      width: width,
      movingTabStyle: {
        transition: 'transform 0s',
      },
      allStates: {},
    };
    this.navigationStepChange = this.navigationStepChange.bind(this);
    this.refreshAnimation = this.refreshAnimation.bind(this);
    this.previousButtonClick = this.previousButtonClick.bind(this);
    this.previousButtonClick = this.previousButtonClick.bind(this);
    this.finishButtonClick = this.finishButtonClick.bind(this);
    this.updateWidth = this.updateWidth.bind(this);
  }
  wizard = React.createRef<any>();
  componentDidMount() {
    this.refreshAnimation(0);
    window.addEventListener('resize', this.updateWidth);
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWidth);
  }
  updateWidth() {
    this.refreshAnimation(this.state.currentStep);
  }
  navigationStepChange(key: number) {
    if (this.props.steps) {
      let validationState = true;
      if (key > this.state.currentStep) {
        for (let i = this.state.currentStep; i < key; i++) {
          const obj = (this as any)[this.props.steps[i].stepId];
          if (obj.sendState !== undefined) {
            this.setState({
              allStates: {
                ...this.state.allStates,
                [this.props.steps[i].stepId]: obj.sendState(),
              },
            });
          }
          if (obj.isValidated !== undefined && obj.isValidated() === false) {
            validationState = false;
            break;
          }
        }
      }
      if (validationState) {
        this.setState({
          currentStep: key,
          nextButton: this.props.steps.length > key + 1,
          previousButton: key > 0,
          finishButton: this.props.steps.length === key + 1,
        });
        this.refreshAnimation(key);
      }
    }
  }
  nextButtonClick() {
    const obj = (this as any)[this.props.steps[this.state.currentStep].stepId];
    if (
      (this.props.validate &&
        ((obj.isValidated !== undefined && obj.isValidated()) ||
          obj.isValidated === undefined)) ||
      this.props.validate === undefined
    ) {
      if (obj.sendState !== undefined) {
        this.setState({
          allStates: {
            ...this.state.allStates,
            [this.props.steps[this.state.currentStep].stepId]: obj.sendState(),
          },
        });
      }
      const key = this.state.currentStep + 1;
      this.setState({
        currentStep: key,
        nextButton: this.props.steps.length > key + 1,
        previousButton: key > 0,
        finishButton: this.props.steps.length === key + 1,
      });
      this.refreshAnimation(key);
    }
  }
  previousButtonClick() {
    const obj = (this as any)[this.props.steps[this.state.currentStep].stepId];
    if (obj.sendState !== undefined) {
      this.setState({
        allStates: {
          ...this.state.allStates,
          [this.props.steps[this.state.currentStep].stepId]: obj.sendState(),
        },
      });
    }
    const key = this.state.currentStep - 1;
    if (key >= 0) {
      this.setState({
        currentStep: key,
        nextButton: this.props.steps.length > key + 1,
        previousButton: key > 0,
        finishButton: this.props.steps.length === key + 1,
      });
      this.refreshAnimation(key);
    }
  }
  finishButtonClick() {
    const obj = (this as any)[this.props.steps[this.state.currentStep].stepId];
    if (
      (this.props.validate === false &&
        this.props.finishButtonClick !== undefined) ||
      (this.props.validate &&
        ((obj.isValidated !== undefined && obj.isValidated()) ||
          obj.isValidated === undefined) &&
        this.props.finishButtonClick !== undefined)
    ) {
      this.setState(
        {
          allStates: {
            ...this.state.allStates,
            [this.props.steps[this.state.currentStep].stepId]: obj.sendState(),
          },
        },
        () => {
          this.props.finishButtonClick?.(this.state.allStates);
        },
      );
    }
  }
  refreshAnimation(index: any) {
    const total = this.props.steps.length;
    let liWidth = 100 / total;
    const totalSteps = this.props.steps.length;
    let moveDistance = this.wizard.current.children[0].offsetWidth / totalSteps;
    let indexTemp = index;
    let verticalLevel = 0;

    const mobileDevice = window.innerWidth < 600 && total > 3;

    if (mobileDevice) {
      moveDistance = this.wizard.current.children[0].offsetWidth / 2;
      indexTemp = index % 2;
      liWidth = 50;
    }

    this.setState({ width: liWidth + '%' });

    const stepWidth = moveDistance;
    moveDistance = moveDistance * indexTemp;

    const current = index + 1;

    if (current === 1 || (mobileDevice && index % 2 === 0)) {
      moveDistance -= 8;
    } else if (current === totalSteps || (mobileDevice && index % 2 === 1)) {
      moveDistance += 8;
    }

    if (mobileDevice) {
      verticalLevel = parseInt(String(index / 2), 10);
      verticalLevel = verticalLevel * 38;
    }
    const movingTabStyle = {
      width: stepWidth,
      transform:
        'translate3d(' + moveDistance + 'px, ' + verticalLevel + 'px, 0)',
      transition: 'all 0.5s cubic-bezier(0.29, 1.42, 0.79, 1)',
    };
    this.setState({ movingTabStyle: movingTabStyle });
  }
  render() {
    const { classes, title, subtitle, color, steps } = this.props;
    return (
      <div className={classes.wizardContainer} ref={this.wizard}>
        <Card className={classes.card}>
          <div className={classes.wizardHeader}>
            <h3 className={classes.title}>{title}</h3>
            <h5 className={classes.subtitle}>{subtitle}</h5>
          </div>
          <div className={classes.wizardNavigation}>
            <ul className={classes.nav}>
              {steps.map((prop, key) => {
                return (
                  <li
                    className={classes.steps}
                    key={key}
                    style={{ width: this.state.width }}
                  >
                    <a
                      href="#pablo"
                      className={classes.stepsAnchor}
                      onClick={(e) => {
                        e.preventDefault();
                        this.navigationStepChange(key);
                      }}
                    >
                      {prop.stepName}
                    </a>
                  </li>
                );
              })}
            </ul>
            <div
              className={clsx(classes.movingTab, color ? classes[color] : '')}
              style={this.state.movingTabStyle}
            >
              {steps[this.state.currentStep].stepName}
            </div>
          </div>
          <div className={classes.content}>
            {steps.map((prop, key) => {
              const stepContentClasses = clsx({
                [classes.stepContentActive]: this.state.currentStep === key,
                [classes.stepContent]: this.state.currentStep !== key,
              });
              return (
                <div className={stepContentClasses} key={key}>
                  <prop.stepComponent
                    innerRef={(node: any) =>
                      ((this as any)[prop.stepId] = node)
                    }
                    allStates={this.state.allStates}
                  />
                </div>
              );
            })}
          </div>
          <div className={classes.footer}>
            <div className={classes.left}>
              {this.state.previousButton ? (
                <Button
                  className={this.props.previousButtonClasses}
                  onClick={() => this.previousButtonClick()}
                >
                  {this.props.previousButtonText}
                </Button>
              ) : null}
            </div>
            <div className={classes.right}>
              {this.state.nextButton ? (
                <Button
                  color="rose"
                  className={this.props.nextButtonClasses}
                  onClick={() => this.nextButtonClick()}
                >
                  {this.props.nextButtonText}
                </Button>
              ) : null}
              {this.state.finishButton ? (
                <Button color="rose" onClick={() => this.finishButtonClick()}>
                  {this.props.finishButtonText}
                </Button>
              ) : null}
            </div>
            <div className={classes.clearfix} />
          </div>
        </Card>
      </div>
    );
  }
}

export const Wizard = withStyles(wizardStyle)(WizardComponent);
