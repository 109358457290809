import { useCallback } from 'react';

import { OasMemoryError, OasError } from 'core/atoms/errors';

import { gql, useMutation } from 'core/dna/types/apollo';
import { UpdateSubstituteTaskInput } from 'core/dna/types/generated/_globalTypes';
import {
  UpdateSubstituteTask,
  UpdateSubstituteTaskVariables,
} from 'core/dna/types/generated/UpdateSubstituteTask';

import { SUBSTITUTE_TASK_DATA_FRAGMENT } from 'modules/substitute/memory/apollo/_fragments';
import { GET_SUBSTITUTE_JOBS_QUERY } from 'modules/substitute/memory/apollo/substitute-jobs/remote';

export const UPDATE_SUBSTITUTE_TASK_MUTATION = gql`
  mutation UpdateSubstituteTask($input: UpdateSubstituteTaskInput!) {
    updateSubstituteTask(input: $input) {
      ...SubstituteTaskData
    }
  }
  ${SUBSTITUTE_TASK_DATA_FRAGMENT}
`;

const TITLE = 'use-update-substitute-task';

export const useUpdateSubstituteTask = () => {
  const [updateSubstituteTask] = useMutation<
    UpdateSubstituteTask,
    UpdateSubstituteTaskVariables
  >(UPDATE_SUBSTITUTE_TASK_MUTATION);

  const func = useCallback(
    async (input: UpdateSubstituteTaskInput) => {
      try {
        const result = await updateSubstituteTask({
          variables: {
            input,
          },
          refetchQueries: [
            {
              query: GET_SUBSTITUTE_JOBS_QUERY,
            },
          ],
        });

        if (result.errors) {
          throw new OasMemoryError(JSON.stringify(result.errors), {
            title: TITLE,
          });
        }
        return result.data?.updateSubstituteTask ?? undefined;
      } catch (e) {
        if (e instanceof OasError) {
          throw e;
        } else {
          throw OasMemoryError.fromError(e, {
            title: TITLE,
          });
        }
      }
    },
    [updateSubstituteTask],
  );

  return {
    updateSubstituteTask: func,
  };
};
