/* eslint-disable */
import React, { ReactNode } from 'react';
import clsx from 'clsx';

import { useStyles } from './card-footer.styles';

export interface CardFooterProps {
  className?: string; // PropTypes.string,
  plain?: boolean; // PropTypes.bool,
  profile?: boolean; // PropTypes.bool,
  pricing?: boolean; // PropTypes.bool,
  testimonial?: boolean; // PropTypes.bool,
  stats?: boolean; // PropTypes.bool,
  chart?: boolean; // PropTypes.bool,
  product?: boolean; // PropTypes.bool,
  children?: ReactNode; // PropTypes.node,
}

export const CardFooter = (props: CardFooterProps) => {
  const classes = useStyles();

  const {
    className,
    children,
    plain,
    profile,
    pricing,
    testimonial,
    stats,
    chart,
    product,
    ...rest
  } = props;

  const cardFooterClasses = clsx(
    classes.cardFooter,
    plain ? classes.cardFooterPlain : '',
    profile || testimonial ? classes.cardFooterProfile : '',
    pricing ? classes.cardFooterPricing : '',
    testimonial ? classes.cardFooterTestimonial : '',
    stats ? classes.cardFooterStats : '',
    chart || product ? classes.cardFooterChart : '',
    className ?? '',
  );

  return (
    <div className={cardFooterClasses} {...rest}>
      {children}
    </div>
  );
};
