import {
  OasError,
  OasErrorConstructor,
  getOasErrorFromError,
  OasErrorOptions,
  OasErrorInterface,
} from './oas-error';

export const OasPageError: OasErrorConstructor = class OasPageError extends OasError {
  static fromError(e: unknown, options?: OasErrorOptions): OasErrorInterface {
    return getOasErrorFromError(OasPageError, e, options);
  }

  constructor(message?: string, options?: OasErrorOptions) {
    super(message, { ...options, type: 'OasPageError' });
  }
};
