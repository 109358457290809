import { useState, useCallback, useMemo, useEffect } from 'react';
import queryString from 'query-string';

import { useTraceUpdate, logger, LogType } from 'core/atoms/housekeeping';
import { OasError } from 'core/atoms/errors';
import { Identity } from 'core/atoms/types';
import { cloneDeep } from 'core/atoms/functions';

import { useAccessRolesState } from 'core/dna/access-roles';
import {
  OrganizationsStateContext,
  OrganizationsUpdaterContext,
  OrganizationsStateContextValue,
  OrganizationsUpdaterContextValue,
  SelectedOrganizationStateContext,
  SelectedOrganizationUpdaterContext,
} from 'core/dna/organizations';
import { AccessRoleEnum } from 'core/dna/types/access-roles';
import { OrganizationState, OrganizationLevel } from 'core/dna/types/local';

import { getOrganizationStorage } from 'core/memory/browser';

const FILE_NAME = 'organizations-provider';

export const OrganizationsProvider = (props: any) => {
  const { children } = props;

  // TODO: remove after performance testing
  useTraceUpdate('OrganizationsStateProvider', props);

  const {
    getOrganization: getBrowserOrganization,
    setOrganization: setBrowserOrganization,
  } = useMemo(() => getOrganizationStorage(), []);

  const [organizations, setOrganizations] = useState<
    OrganizationState[] | null
  >(null);
  const [
    selectedOrganization,
    setSelectedOrganization,
  ] = useState<OrganizationState | null>(null);

  const differentTo = useCallback(
    (values: Identity[]) => {
      if (!organizations?.length && !values.length) {
        return false;
      }
      if (
        (!organizations?.length && values.length) ||
        (organizations?.length && !values.length)
      ) {
        return true;
      }
      if (organizations!.length !== values.length) {
        return true;
      }

      let result = false;
      // eslint-disable-next-line no-restricted-syntax
      for (const org of organizations!) {
        if (!values.find((v) => v.id === org.id)) {
          result = true;
          break;
        }
      }
      return result;
    },
    [organizations],
  );

  const reset = useCallback(
    (values: OrganizationState[]) => {
      if (!values.length) {
        return;
      }

      const newOrganizations = cloneDeep(values);
      let selected = newOrganizations.find((o) => o.accessible);

      const qOrg = queryString.parse(window.location.search).o;
      const bOrg = getBrowserOrganization();

      if (qOrg) {
        const newOrg = newOrganizations.find(
          (o) => o.id === qOrg && o.accessible,
        );
        if (newOrg) {
          selected = newOrg;
        }
      } else if (bOrg) {
        const newOrg = newOrganizations.find(
          (o) => o.id === bOrg && o.accessible,
        );
        if (newOrg) {
          selected = newOrg;
        }
      }

      if (!selected) {
        throw new OasError('Accessible organization was not found!', {
          title: FILE_NAME,
          type: 'OasError',
        });
      }
      setBrowserOrganization(selected.id);
      setOrganizations(newOrganizations);
      setSelectedOrganization(selected);
      return selected;
    },
    [getBrowserOrganization, setBrowserOrganization],
  );

  const select = useCallback(
    (id: string) => {
      const newOrg =
        organizations?.find((o) => o.id === id && o.accessible) ??
        organizations?.find((o) => o.accessible) ??
        null;

      setBrowserOrganization(newOrg?.id ?? null);
      setSelectedOrganization(newOrg);

      return newOrg;
    },
    [organizations, setBrowserOrganization],
  );

  const organizationsState = useMemo<OrganizationsStateContextValue>(
    () => ({
      organizations,
      differentTo,
    }),
    [differentTo, organizations],
  );

  const organizationsUpdater = useMemo<OrganizationsUpdaterContextValue>(
    () => ({
      reset,
    }),
    [reset],
  );

  const myAccessRoles = useAccessRolesState();

  useEffect(() => {
    if (myAccessRoles) {
      logger.debug({
        title: 'useEffect([differentTo, myAccessRoles, reset])',
        logger: FILE_NAME,
        type: LogType.Secondary,
        value: 'if (myAccessRoles)',
      });
      const values: OrganizationState[] = [];
      myAccessRoles.forEach((ro) => {
        if (ro) {
          values.push({
            id: ro.id!,
            name: ro.name!,
            accessible: false,
            // (ro.accessRoles?.indexOf(AccessRoleEnum.rootAdmin) ?? -1) > -1,
            permissions: ro.permissions!,
            level: OrganizationLevel.Federation,
            category: ro.category,
          });
          ro.organizations?.forEach((o) => {
            if (o) {
              values.push({
                id: o.id!,
                name: o.name!,
                accessible:
                  (o.accessRoles?.indexOf(AccessRoleEnum.admin) ?? -1) > -1 ||
                  (o.accessRoles?.indexOf(AccessRoleEnum.staff) ?? -1) > -1,
                rootId: ro?.id!,
                rootName: ro?.name!,
                rootAccessible:
                  (ro?.accessRoles?.indexOf(AccessRoleEnum.rootAdmin) ?? -1) >
                  -1,
                permissions: o.permissions ?? [],
                level: OrganizationLevel.Organization,
                category: o.category,
              });
            }
          });
        }
      });
      if (differentTo(values)) {
        logger.debug({
          title: 'useEffect([differentTo, myAccessRoles, reset])',
          logger: FILE_NAME,
          type: LogType.Success,
          value: 'differentTo(values)',
        });
        reset(values);
      }
    }
  }, [differentTo, myAccessRoles, reset]);

  return (
    <OrganizationsStateContext.Provider value={organizationsState}>
      <SelectedOrganizationStateContext.Provider value={selectedOrganization}>
        <OrganizationsUpdaterContext.Provider value={organizationsUpdater}>
          <SelectedOrganizationUpdaterContext.Provider value={select}>
            {children}
          </SelectedOrganizationUpdaterContext.Provider>
        </OrganizationsUpdaterContext.Provider>
      </SelectedOrganizationStateContext.Provider>
    </OrganizationsStateContext.Provider>
  );
};
