import { AccessRoleEnum, RootAccessRoles } from 'core/dna/types/access-roles';

export const isOrganizationHasAccessRole = (
  myAccessRoles: RootAccessRoles[] | null,
  orgId: string | undefined,
  role: AccessRoleEnum,
): boolean => {
  if (!myAccessRoles) {
    return false;
  }

  const organizations = myAccessRoles
    .map((federation) => federation.organizations)
    .flat();

  const org = organizations.find((o) => o!.id === orgId);

  if (!org || !org.accessRoles) {
    return false;
  }

  return org.accessRoles.includes(role);
};
