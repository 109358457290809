import { useCallback } from 'react';
import moment from 'moment';

import { FlexBox } from 'core/cells/box';
import { Form, Field, FormController } from 'core/cells/forms';
import { FormTextField } from 'core/cells/form-text-field';

import { useTranslations } from 'core/dna/translations';

import { Button } from 'templates/mdp/components/custom-buttons';

import { WriteModeEnum } from 'core/dna/types/write-mode';
import { dateTimeInputFormat } from 'core/atoms/date-time/formats';

import { WriteTableModal } from 'core/organisms/write-modal';

import { selectJob } from 'modules/substitute/memory/rx/jobs';

import { useJobsTableWrite } from 'modules/substitute/metabolism/use-jobs-table-write';

interface Values {
  description: string;
  internalTitle: string;
  deadline: string;
}

interface JobCreatorProps {
  open?: boolean;
  setOpen?: (open: boolean) => void;
  onSubmit?: (id: string) => void;
}

export const JobCreator = (props: JobCreatorProps) => {
  const { open, setOpen, onSubmit } = props;
  const { onRowAdd } = useJobsTableWrite();
  const { texts } = useTranslations();

  const onClose = useCallback(() => {
    setOpen?.(false);
  }, [setOpen]);

  return (
    <WriteTableModal
      width={420}
      isShow={open ?? false}
      onClose={onClose}
      writeMode={WriteModeEnum.create}
      title={texts.substitute.addJob}
    >
      <FormController
        initialValues={{
          description: '',
          internalTitle: '',
          deadline: moment().add(1, 'year').format(dateTimeInputFormat),
        }}
        validate={(values) => {
          const errors: Partial<Values> = {};
          if (!values.internalTitle) {
            errors.internalTitle = texts.oasCommon.validation.required;
          }
          if (!values.description) {
            errors.description = texts.oasCommon.validation.required;
          }
          return errors;
        }}
        onSubmit={async (values) => {
          const job = await onRowAdd({
            description: values.description,
            internalTitle: values.internalTitle,
            deadline: values.deadline,
          });
          if (job) {
            selectJob({
              id: job.id,
              description: job.description,
              internalTitle: job.internalTitle,
              deadline: job.deadline.formatted ?? '',
            });
            onSubmit?.(job.id);
          }
        }}
      >
        {({ submitForm, isSubmitting }) => (
          <Form>
            <Field
              component={FormTextField}
              name="internalTitle"
              type="text"
              label={texts.substitute.jobTitle}
              disabled={isSubmitting}
              fullWidth
            />
            <Field
              component={FormTextField}
              name="description"
              type="text"
              label={texts.planner.common.notes.one}
              disabled={isSubmitting}
              fullWidth
            />
            <FlexBox justifyContent="flex-end">
              <Button
                color="secondary"
                disabled={isSubmitting}
                onClick={submitForm}
              >
                {texts.planner.common.add}
              </Button>
              <Button onClick={onClose} color="secondary" simple>
                {texts.planner.common.close}
              </Button>
            </FlexBox>
          </Form>
        )}
      </FormController>
    </WriteTableModal>
  );
};
