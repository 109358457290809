import { GetSchedule_Schedule_lessonGroups_timeSlots as ApiTimeSlot } from 'core/dna/types/generated/GetSchedule';
import { TimeSlot } from 'modules/planner/dna/types/time-slot';

import {
  mapNullableListToList,
  mapNullableNumberToNumber,
  mapNullableStringToString,
  mapNullableBooleanToBoolean,
} from 'core/dna/functions';

export const mapApiTimeSlotListToTimeSlotList = (
  apiTimeSlotList: (ApiTimeSlot | null)[] | null,
): TimeSlot[] | undefined => {
  return mapNullableListToList<ApiTimeSlot, TimeSlot>(
    apiTimeSlotList,
    mapApiTimeSlotToTimeSlot,
  );
};

export const mapApiTimeSlotToTimeSlot = (
  apiTimeSlot: ApiTimeSlot | null,
): TimeSlot | undefined => {
  if (!apiTimeSlot) {
    return;
  }

  const {
    id,
    startTime,
    endTime,
    weekday,
    pinned,
    splitId,
    aiHardConflicts,
    aiSoftConflicts,
  } = apiTimeSlot;

  return {
    id,
    startTime: mapNullableStringToString(startTime.formatted),
    endTime: mapNullableStringToString(endTime.formatted),
    weekday: mapNullableNumberToNumber(weekday),
    pinned: mapNullableBooleanToBoolean(pinned),
    splitId: mapNullableStringToString(splitId),
    aiHardConflicts: mapNullableListToList<string, string>(
      aiHardConflicts,
      mapNullableStringToString,
    ),
    aiSoftConflicts: mapNullableListToList<string, string>(
      aiSoftConflicts,
      mapNullableStringToString,
    ),
  };
};
