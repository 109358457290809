/* eslint-disable */
import React, { ReactNode, ComponentType, useState } from 'react';
import clsx from 'clsx';

// @material-ui/core components
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Paper from '@material-ui/core/Paper';
import Grow from '@material-ui/core/Grow';
import Divider from '@material-ui/core/Divider';
import Popper from '@material-ui/core/Popper';

import { TextItem } from 'core/atoms/types';

import { Button } from '../custom-buttons';

import { useStyles } from './custom-dropdown.styles';

type CustomDropdownHoverColor =
  | 'dark'
  | 'primary'
  | 'info'
  | 'success'
  | 'warning'
  | 'danger'
  | 'rose';

type CustomDropdownDropPlacement =
  | 'bottom'
  | 'top'
  | 'right'
  | 'left'
  | 'bottom-start'
  | 'bottom-end'
  | 'top-start'
  | 'top-end'
  | 'right-start'
  | 'right-end'
  | 'left-start'
  | 'left-end';

export interface CustomDropdownProps {
  hoverColor?: CustomDropdownHoverColor; // PropTypes.oneOf([]),
  buttonText?: ReactNode; // PropTypes.node,
  buttonIcon?: ComponentType<any>; // PropTypes.object,
  dropdownList?: any[]; // PropTypes.array,
  buttonProps?: Record<string, unknown>; // PropTypes.object,
  dropup?: boolean; // PropTypes.bool,
  dropdownHeader?: ReactNode; // PropTypes.node,
  rtlActive?: boolean; // PropTypes.bool,
  caret?: boolean; // PropTypes.bool,
  dropPlacement?: CustomDropdownDropPlacement; // PropTypes.oneOf([]),
  noLiPadding?: boolean; // PropTypes.bool,
  innerDropDown?: boolean; // PropTypes.bool,
  navDropdown?: boolean; // PropTypes.bool,
  // This is a function that returns the clicked menu item
  onClick?: (param: any) => void; // PropTypes.func,
}

function isTextItem(item: any): item is TextItem {
  return 'text' in item && 'id' in item;
}

export const CustomDropdown = (props: CustomDropdownProps) => {
  const [anchorEl, setAnchorEl] = useState<any>(null);
  const classes = useStyles();

  const handleClick = (event: any) => {
    if (anchorEl?.contains?.(event.target)) {
      setAnchorEl(null);
    } else {
      setAnchorEl(event.currentTarget);
    }
  };

  const handleClose = (event: any) => {
    if (anchorEl?.contains?.(event.target)) {
      return;
    }
    setAnchorEl(null);
  };

  const handleCloseMenu = (param: any) => {
    setAnchorEl(null);
    props?.onClick?.(param);
  };

  const {
    buttonText,
    dropdownList,
    buttonProps,
    dropup,
    dropdownHeader,
    caret,
    hoverColor,
    dropPlacement,
    rtlActive,
    noLiPadding,
    innerDropDown,
    navDropdown,
  } = props;

  const ButtonIcon = props.buttonIcon;

  const caretClasses = clsx(
    classes.caret,
    dropup && !anchorEl ? classes.caretDropup : '',
    Boolean(anchorEl) && !dropup ? classes.caretActive : '',
    rtlActive ? classes.caretRTL : '',
  );

  const dropdownItem = clsx(
    classes.dropdownItem,
    (classes as any)[hoverColor + 'Hover'],
    noLiPadding ? classes.noLiPadding : '',
    rtlActive ? classes.dropdownItemRTL : '',
  );

  const dropDownMenu = (
    <MenuList role="menu" className={classes.menuList}>
      {dropdownHeader !== undefined ? (
        <MenuItem
          onClick={() => handleCloseMenu(dropdownHeader)}
          className={classes.dropdownHeader}
        >
          {dropdownHeader}
        </MenuItem>
      ) : null}
      {dropdownList?.map((prop, key) => {
        if (prop.divider) {
          return (
            <Divider
              key={key}
              onClick={() => handleCloseMenu('divider')}
              className={classes.dropdownDividerItem}
            />
          );
        } else if (
          prop.props !== undefined &&
          prop.props['data-ref'] === 'multi'
        ) {
          return (
            <MenuItem
              key={key}
              className={dropdownItem}
              style={{ overflow: 'visible', padding: 0 }}
            >
              {prop}
            </MenuItem>
          );
        } else if (isTextItem(prop)) {
          return (
            <MenuItem
              key={key}
              onClick={() => handleCloseMenu(prop)}
              className={dropdownItem}
            >
              {prop.text}
            </MenuItem>
          );
        }
        return (
          <MenuItem
            key={key}
            onClick={() => handleCloseMenu(prop)}
            className={dropdownItem}
          >
            {prop}
          </MenuItem>
        );
      })}
    </MenuList>
  );

  return (
    <div className={innerDropDown ? classes.innerManager : classes.manager}>
      <div className={buttonText !== undefined ? '' : classes.target}>
        <Button
          aria-label="Notifications"
          aria-owns={anchorEl ? 'menu-list' : undefined}
          aria-haspopup="true"
          {...buttonProps}
          onClick={handleClick}
        >
          {ButtonIcon !== undefined ? (
            <ButtonIcon className={classes.buttonIcon} />
          ) : null}
          {buttonText !== undefined ? buttonText : null}
          {caret ? <b className={caretClasses} /> : null}
        </Button>
      </div>
      <Popper
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        transition
        disablePortal
        placement={dropPlacement}
        className={clsx(
          !anchorEl ? classes.popperClose : '',
          classes.popperResponsive,
          Boolean(anchorEl) && navDropdown ? classes.popperNav : '',
        )}
      >
        {() => (
          <Grow
            in={Boolean(anchorEl)}
            style={
              dropup
                ? { transformOrigin: '0 100% 0' }
                : { transformOrigin: '0 0 0' }
            }
          >
            <Paper className={classes.dropdown}>
              {innerDropDown ? (
                dropDownMenu
              ) : (
                <ClickAwayListener onClickAway={handleClose}>
                  {dropDownMenu}
                </ClickAwayListener>
              )}
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  );
};

CustomDropdown.defaultProps = {
  caret: true,
  dropup: false,
  hoverColor: 'primary',
};
