import { useGetSelectedScheduleState } from 'modules/planner/memory/apollo/schedules/local';
import { useGetScheduleDescriptions } from 'modules/planner/memory/apollo/schedules/remote';

import { LessonGroupConstructor } from '../../components/lesson-group-constructor';

/**  /plan/schedules/:scheduleId?/lesson-groups/create  */
export const LessonGroupsCreate = () => {
  // this is needed for getting the title text
  useGetScheduleDescriptions();
  const { selectedScheduleState } = useGetSelectedScheduleState();
  return <LessonGroupConstructor scheduleId={selectedScheduleState ?? ''} />;
};
