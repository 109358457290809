import React from 'react';

import { Chip } from 'core/cells/chip';

import { useTeacherTableWrite } from 'modules/grades/metabolism';

interface DisplayCellDataProps {
  value?: any[];
  row: any;
  data: any[];
}

export const DisplayCellData = (props: DisplayCellDataProps) => {
  const { row, value, data } = props;

  const { onDeleteAccess, writing } = useTeacherTableWrite();

  const handleClick = async (access: any, evaluationGroupId: string) => {
    await onDeleteAccess({
      evaluationGroupId,
      tuid: access.teacher.personDetails.tuid,
    });
  };

  if (!data[row.index]?.accessList?.length) {
    return '';
  }

  return data[row.index].accessList.map((access: any) => (
    <Chip
      key={access.teacher?.personDetails?.tuid}
      style={{ marginBottom: 4, marginRight: 4 }}
      size="small"
      color="secondary"
      variant="outlined"
      disabled={writing}
      label={`${access.teacher?.personDetails?.firstName} ${access.teacher?.personDetails?.lastName}`}
      onDelete={() => handleClick(access, data[row.index].id)}
    />
  ));
};
