import React from 'react';

import { makeStyles, createStyles } from 'core/atoms/styles';

import { Select } from 'core/cells/select';

import { useTranslations } from 'core/dna/translations';

import { useTeacherSelector } from './use-teacher-selector';

const useStyles = makeStyles(() =>
  createStyles({
    select: {
      width: 200,
    },
  }),
);

export const TeacherSelector = () => {
  const classes = useStyles();
  const { lang } = useTranslations();

  const {
    teachers,
    selectedTeachers,
    setSelectedTeachers,
    teachersLoading,
    teachersError,
  } = useTeacherSelector();

  if (teachersLoading) return <p>Loading Teachers...</p>;
  if (teachersError) return <p>Teachers Error!</p>;

  return (
    <Select
      multiple
      name="teachers"
      label={lang.common.teachers}
      data={teachers}
      value={selectedTeachers}
      className={classes.select}
      minWidth={200}
      onChange={setSelectedTeachers}
    />
  );
};
