import React from 'react';
import { ApolloError } from '@apollo/client/errors';
import { Typography } from '@material-ui/core';
import { Grid } from 'core/cells/grid';
import { Skeleton } from 'core/cells/skeleton';
import { useTranslations } from 'core/dna/translations';
import { JournalEntry } from 'core/dna/types/generated/JournalEntry';

import { JournalsTable } from './components/journals-table';

interface StudentJournalsProps {
  data: JournalEntry[] | null | undefined;
  error: ApolloError | undefined;
}

export const StudentJournals = ({ data, error }: StudentJournalsProps) => {
  const { texts } = useTranslations();

  if (
    error &&
    data === null &&
    error.graphQLErrors.some(
      (e) => e.path && e.path[e.path.length - 1] === 'journals',
    )
  ) {
    return (
      <Typography variant="h5" color="textSecondary" align="center">
        {texts.oasCommon.notAuthForResource}
      </Typography>
    );
  }

  return !data ? (
    <Skeleton variant="rect" width="100%" height="80vh" />
  ) : (
    <Grid container spacing={3} justify="center">
      <Grid item xs={12} xl={8}>
        <JournalsTable data={data} loading={false} />
      </Grid>
    </Grid>
  );
};
