import { GetOrganizations_Organization as ApiOrganization } from 'core/dna/types/generated/GetOrganizations';
import { Organization } from 'core/dna/types/organization';
import { mapNullableListToList } from 'core/dna/functions';

import { mapApiTermListToTermList } from '../map-ApiTerm-to-Term';
import { mapApiOpeningHoursListToOpeningHoursList } from '../map-ApiOpeningHours-to-OpeningHours';
import { mapApiFeatureListToFeatureList } from '../map-ApiFeature-to-Feature';

export const mapApiOrganizationListToOrganizationList = (
  apiOrganizationList: (ApiOrganization | null)[] | null,
): Organization[] | undefined => {
  return mapNullableListToList<ApiOrganization, Organization>(
    apiOrganizationList,
    mapApiOrganizationToOrganization,
  );
};

export const mapApiOrganizationToOrganization = (
  apiOrganization: ApiOrganization | null,
): Organization | undefined => {
  if (!apiOrganization) {
    return;
  }
  const {
    id,
    name,
    type,
    terms,
    openingHours,
    federation,
    features,
    category,
  } = apiOrganization;

  return {
    id,
    name: name ?? 'ORG',
    type,
    terms: mapApiTermListToTermList(terms),
    openingHours: mapApiOpeningHoursListToOpeningHoursList(openingHours),
    federation: federation
      ? {
          id: federation.id,
          name: federation.name ?? '',
        }
      : undefined,
    features: mapApiFeatureListToFeatureList(features),
    category,
  };
};
