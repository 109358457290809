import { GetSchedule_Schedule_lessonGroups_lessons_chapters as ApiChapter } from 'core/dna/types/generated/GetSchedule';
import { mapNullableListToList } from 'core/dna/functions/map-nullable-list-to-list';

import { LessonChapter } from 'modules/planner/dna/types/lesson-chapter';

export const mapApiChapterListToLessonChapterList = (
  apiChapterList: (ApiChapter | null)[] | null,
): LessonChapter[] | undefined => {
  return mapNullableListToList<ApiChapter, LessonChapter>(
    apiChapterList,
    mapApiChapterToLessonChapter,
  );
};

export const mapApiChapterToLessonChapter = (
  apiChapter: ApiChapter | null,
): LessonChapter | undefined => {
  if (!apiChapter) {
    return;
  }
  const { id, name, union } = apiChapter;

  return {
    id,
    name,
    class: union!.class!.name!,
    classId: union!.class!.id,
  };
};
