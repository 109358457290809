import { useCallback } from 'react';
import { useMutation } from 'core/dna/types/apollo';

import { OasMemoryError } from 'core/atoms/errors';
import { OasError } from 'core/atoms/errors/oas-error';

import {
  CreateSubject,
  CreateSubjectVariables,
} from 'core/dna/types/generated/CreateSubject';

import { useLazyGetTermsState } from 'core/memory/apollo/terms/local/get-terms-state';

import { GET_SUBJECTS_QUERY } from '../graphql';

import { CREATE_SUBJECT_MUTATION } from './create-subject-mutation';

const TITLE = 'use-create-subject';

export const useCreateSubject = () => {
  const { getTermState } = useLazyGetTermsState();

  const [createSubject, { loading, error }] = useMutation<
    CreateSubject,
    CreateSubjectVariables
  >(CREATE_SUBJECT_MUTATION);

  const func = useCallback(
    async (
      name: string,
      alias?: string,
      code?: string,
      bgColor?: string,
      annualHours?: number,
    ) => {
      try {
        const termsState = await getTermState(true);

        const result = await createSubject({
          variables: {
            input: {
              name,
              alias,
              code,
              bgColor,
              termId: termsState!.selected!,
              annualHours,
            },
          },
          refetchQueries: [
            {
              query: GET_SUBJECTS_QUERY,
              variables: { termId: termsState!.selected! },
            },
          ],
        });

        if (result.errors) {
          throw new OasMemoryError(JSON.stringify(result.errors), {
            title: TITLE,
          });
        }
        return result.data?.createSubject ?? undefined;
      } catch (e: any) {
        if (e instanceof OasError) {
          throw e;
        } else {
          throw OasMemoryError.fromError(e, {
            title: TITLE,
          });
        }
      }
    },
    [createSubject, getTermState],
  );

  return {
    createSubject: func,
    loading,
    error,
  };
};
