import { ReactNode, useCallback, useState } from 'react';
import SwipeableViews from 'react-swipeable-views';
import clsx from 'clsx';

import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';

import { GridContainer, GridItem } from 'templates/mdp/components/grid';

import { useStyles } from './nav-pills.styles';

interface NavPillsTab {
  id: string;
  tabIndex?: number;
  tabButton?: string;
  tabIcon?: any;
  tabContent?: ReactNode;
}

type NavPillsColor =
  | 'primary'
  | 'warning'
  | 'danger'
  | 'success'
  | 'info'
  | 'rose';

interface Horizontal {
  tabsGrid?: any;
  contentGrid?: any;
}

export interface NavPillsProps {
  tabs: NavPillsTab[];
  active?: number;
  color?: NavPillsColor;
  direction?: string;
  horizontal?: Horizontal;
  alignCenter?: boolean;
  notRounded?: boolean;
  value?: string;
  onChange?: (newValue: string) => void;
}

const defaultProps: Partial<NavPillsProps> = {
  color: 'primary',
};

export const NavPills = (props: NavPillsProps) => {
  const {
    value,
    onChange,
    tabs,
    direction,
    color = defaultProps.color,
    horizontal,
    alignCenter,
    notRounded,
  } = props;

  const getIndexByValue = useCallback(
    (value: string | undefined) => tabs.find((t) => t.id === value)?.tabIndex,
    [tabs],
  );

  const [active, setActive] = useState(getIndexByValue(value));

  const handleChange = (event: any, value: string) => {
    onChange?.(value);
    setActive(getIndexByValue(value));
  };

  const classes = useStyles();

  const flexContainerClasses = clsx({
    [classes.flexContainer]: true,
    [classes.horizontalDisplay]: horizontal !== undefined,
  });
  const tabButtons = (
    <Tabs
      classes={{
        root: classes.root,
        fixed: classes.fixed,
        flexContainer: flexContainerClasses,
        indicator: classes.displayNone,
      }}
      value={value}
      onChange={handleChange}
      centered={alignCenter}
    >
      {tabs.map((prop, key) => {
        const icon: any = {};
        if (prop.tabIcon !== undefined) {
          icon['icon'] = <prop.tabIcon className={classes.tabIcon} />;
        }
        const pillsClasses = clsx({
          [classes.pills]: true,
          [classes.horizontalPills]: horizontal !== undefined,
          [classes.pillsWithIcons]: prop.tabIcon !== undefined,
          [classes.notRounded]: notRounded,
        });

        return (
          <Tab
            label={prop.tabButton}
            value={prop.id}
            tabIndex={key}
            key={prop.id}
            {...icon}
            classes={{
              root: pillsClasses,
              selected: classes[color ?? 'primary'],
            }}
          />
        );
      })}
    </Tabs>
  );
  const tabContent = (
    <div className={classes.contentWrapper}>
      <SwipeableViews
        axis={direction === 'rtl' ? 'x-reverse' : 'x'}
        value={value}
        index={active}
        style={{ overflowY: 'hidden' }}
      >
        {tabs.map((prop) => {
          return (
            <div className={classes.tabContent} key={prop.id}>
              {prop.tabContent}
            </div>
          );
        })}
      </SwipeableViews>
    </div>
  );
  return horizontal !== undefined ? (
    <GridContainer>
      <GridItem {...horizontal.tabsGrid}>{tabButtons}</GridItem>
      <GridItem {...horizontal.contentGrid}>{tabContent}</GridItem>
    </GridContainer>
  ) : (
    <div>
      {tabButtons}
      {tabContent}
    </div>
  );
};

NavPills.defaultProps = defaultProps;
