/* eslint-disable */
import React, { ReactNode } from 'react';

import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import { Card, CardHeader, CardBody } from '../card';

import { useStyles } from './custom-tabs.styles';
import clsx from 'clsx';

interface CustomTabsTab {
  tabName: string; // PropTypes.string.isRequired,
  tabIcon?: any; // PropTypes.object,
  tabContent: ReactNode; // PropTypes.node.isRequired,
}

type CustomTabsColor =
  | 'warning'
  | 'success'
  | 'danger'
  | 'info'
  | 'primary'
  | 'rose';

export interface CustomTabsProps {
  /** the default opened tab - index starts at 0 */
  value?: number; // PropTypes.number,
  /** function for changing the value
   * note, if you pass this function,
   * the default function that changes the tabs will no longer work,
   * so you need to create the changing functionality as well
   */
  changeValue?: () => void; // PropTypes.func,
  headerColor?: CustomTabsColor; // PropTypes.oneOf([]),
  title?: string; // PropTypes.string,
  tabs?: CustomTabsTab[]; // PropTypes.arrayOf(PropTypes.shape({})),
  rtlActive?: boolean; // PropTypes.bool;
  plainTabs?: boolean; // PropTypes.bool;
}

const defaultProps: Partial<CustomTabsProps> = {
  value: 0,
};

export const CustomTabs = (props: CustomTabsProps) => {
  const [value, setValue] = React.useState(props.value ?? defaultProps.value!);
  const handleChange = (event: any, value: any) => {
    setValue(value);
  };
  const classes = useStyles();
  const { headerColor, plainTabs, tabs, title, rtlActive } = props;
  const cardTitle = clsx({
    [classes.cardTitle]: true,
    [classes.cardTitleRTL]: rtlActive,
  });
  return (
    <Card plain={plainTabs}>
      <CardHeader color={headerColor} plain={plainTabs}>
        {title !== undefined ? <div className={cardTitle}>{title}</div> : null}
        <Tabs
          value={value}
          onChange={props.changeValue ? props.changeValue : handleChange}
          classes={{
            root: classes.tabsRoot,
            indicator: classes.displayNone,
          }}
          variant="scrollable"
          scrollButtons="auto"
        >
          {tabs ? (
            tabs.map((prop: any, key: any) => {
              let icon = {};
              if (prop.tabIcon) {
                icon = {
                  icon: <prop.tabIcon />,
                };
              }
              return (
                <Tab
                  classes={{
                    root: classes.tabRootButton,
                    selected: classes.tabSelected,
                    wrapper: classes.tabWrapper,
                  }}
                  key={key}
                  label={prop.tabName}
                  {...icon}
                />
              );
            })
          ) : (
            <></>
          )}
        </Tabs>
      </CardHeader>
      <CardBody>
        {tabs ? (
          tabs.map((prop: any, key: any) => {
            if (key === value) {
              return <div key={key}>{prop.tabContent}</div>;
            }
            return null;
          })
        ) : (
          <></>
        )}
      </CardBody>
    </Card>
  );
};

CustomTabs.defaultProps = defaultProps;
