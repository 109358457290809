import { cloneDeep } from 'core/atoms/functions';

import { Class, ClassTableItem } from 'modules/planner/dna/types/class';

export const mapClassToClassTableItem = (cls: Class): ClassTableItem => {
  const { id, name, unions, availabilityPreferences, students } = cls;
  const unionsClone = cloneDeep(unions) ?? [];

  const chaptersCount = unionsClone
    .map((u) => u.chapters)
    .reduce((prev: any, curr: any) => [...prev, ...curr], [])?.length;

  return {
    id,
    name,
    unions: unionsClone,
    chaptersCount,
    availabilityDefined:
      !!availabilityPreferences && !!availabilityPreferences.length,
    studentsCount: students?.length ?? 0,
    studentsAssignmentState: 'unassigned',
    students,
  };
};
