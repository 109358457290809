import { useMemo } from 'react';

import { useQuery } from 'core/dna/types/apollo';
import { GetSubjectInfosFromDefault } from 'core/dna/types/generated/GetSubjectInfosFromDefault';
import { mapNullableListToIdentityMap } from 'core/dna/functions';

import { SubjectInfo } from 'modules/planner/dna/types/subject';
import { mapApiSubjectInfoListToSubjectInfoList } from 'modules/planner/dna/functions';

import { GET_SUBJECT_INFOS_FROM_DEFAULT_QUERY } from './gql';

export const useGetSubjectInfosFromDefault = (runCondition = true) => {
  const {
    data: apiData,
    error,
    loading,
  } = useQuery<GetSubjectInfosFromDefault>(
    GET_SUBJECT_INFOS_FROM_DEFAULT_QUERY,
    { skip: !runCondition },
  );

  const list = useMemo(() => {
    if (!loading && !error && apiData) {
      return mapApiSubjectInfoListToSubjectInfoList(apiData.DefaultSubject);
    }
    return [];
  }, [apiData, error, loading]);

  const map = useMemo(() => {
    return list?.length
      ? mapNullableListToIdentityMap<SubjectInfo, SubjectInfo>(
          list,
          (si) => si ?? undefined,
        )
      : undefined;
  }, [list]);

  return {
    list,
    map,
    loading,
    error,
  };
};
