import { GetClasses_Class_unions as ApiUnion } from 'core/dna/types/generated/GetClasses';

import { Union } from 'modules/planner/dna/types/union';
import { mapGetClassesClassUnionsChaptersListToUnionList } from 'modules/planner/dna/functions/map-GetClasses_Class_unions_chapters-to-Chapter';

export const mapGetClassesClassUnionsListToUnionList = (
  apiUnionList: (ApiUnion | null)[] | null,
): Union[] | undefined => {
  if (!apiUnionList) {
    return [];
  }
  const result: Union[] = [];
  apiUnionList.forEach((apiUnion) => {
    const u = mapGetClassesClassUnionsToUnion(apiUnion);
    if (u) {
      result.push(u);
    }
  });
  return result;
};

export const mapGetClassesClassUnionsToUnion = (
  apiUnion: ApiUnion | null,
): Union | undefined => {
  if (!apiUnion) {
    return;
  }
  const { id, chapters } = apiUnion;
  return {
    id,
    chapters: mapGetClassesClassUnionsChaptersListToUnionList(chapters),
  };
};
