import { mapNullableListToList } from 'core/dna/functions/map-nullable-list-to-list';
import { GetEvaluationBatch_EvaluationBatch_evaluationGroups_evaluations as ApiStudentEvaluation } from 'core/dna/types/generated/GetEvaluationBatch';
import { StudentEvaluation } from 'modules/grades/dna/types';

export const mapApiStudentEvaluationToStudentEvaluation = (
  apiStudentEvaluation: ApiStudentEvaluation | null,
): StudentEvaluation => {
  if (apiStudentEvaluation === null) {
    throw new TypeError('"apiStudentEvaluation" should not be null!');
  }

  const {
    createdAt,
    updatedAt,
    code,
    note,
    evaluation,
    evaluatedBy,
    student,
  } = apiStudentEvaluation;

  return {
    code,
    note,
    evaluation,
    createdAt: createdAt?.formatted ?? null,
    updatedAt: updatedAt?.formatted ?? null,
    evaluatedBy,
    student,
  };
};

export const mapApiStudentEvaluationToStudentEvaluationList = (
  apiStudentEvaluationList: (ApiStudentEvaluation | null)[] | null,
): StudentEvaluation[] | undefined => {
  return mapNullableListToList<ApiStudentEvaluation, StudentEvaluation>(
    apiStudentEvaluationList,
    mapApiStudentEvaluationToStudentEvaluation,
  );
};
