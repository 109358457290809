import { OasLanguage, URLS } from 'core/dna/routing';

import PATHS from './paths';

const { getOasUrlWithoutParams, getOasUrl } = URLS;

const getWelcomeUrl = (locale?: OasLanguage) => {
  return getOasUrlWithoutParams({
    pathname: PATHS.Welcome.path,
    locale,
  });
};

const getErrorUrl = (locale?: OasLanguage) => {
  return getOasUrlWithoutParams({
    pathname: PATHS.Error.path,
    locale,
  });
};

const getOrganizationUrl = (locale?: OasLanguage) => {
  return getOasUrlWithoutParams({
    pathname: PATHS.SourceData.Organization.path,
    locale,
  });
};

const getTermUrl = (locale?: OasLanguage) => {
  return getOasUrlWithoutParams({
    pathname: PATHS.SourceData.Term.path,
    locale,
  });
};

const getClassesUrl = (locale?: OasLanguage) => {
  return getOasUrlWithoutParams({
    pathname: PATHS.SourceData.Classes.path,
    locale,
  });
};

const getClassesAvailabilityUrl = (locale?: OasLanguage) => {
  return getOasUrlWithoutParams({
    pathname: PATHS.SourceData.Classes.Availability.path,
    locale,
  });
};

const getClassesGroupsUrl = (locale?: OasLanguage) => {
  return getOasUrlWithoutParams({
    pathname: PATHS.SourceData.Classes.Groups.path,
    locale,
  });
};

const getClassesStudentsUrl = (locale?: OasLanguage) => {
  return getOasUrlWithoutParams({
    pathname: PATHS.SourceData.Classes.Students.path,
    locale,
  });
};

const getClassesStudentsGroupsUrl = (locale?: OasLanguage) => {
  return getOasUrlWithoutParams({
    pathname: PATHS.SourceData.Classes.StudentsGroups.path,
    locale,
  });
};

const getRoomsUrl = (locale?: OasLanguage) => {
  return getOasUrlWithoutParams({
    pathname: PATHS.SourceData.Rooms.path,
    locale,
  });
};

const getRoomsAvailabilityUrl = (locale?: OasLanguage) => {
  return getOasUrlWithoutParams({
    pathname: PATHS.SourceData.Rooms.Availability.path,
    locale,
  });
};

const getSubjectsUrl = (locale?: OasLanguage) => {
  return getOasUrlWithoutParams({
    pathname: PATHS.SourceData.Subjects.path,
    locale,
  });
};

const getTeachersUrl = (locale?: OasLanguage) => {
  return getOasUrlWithoutParams({
    pathname: PATHS.SourceData.Teachers.path,
    locale,
  });
};

const getTeachersAvailabilityUrl = (locale?: OasLanguage) => {
  return getOasUrlWithoutParams({
    pathname: PATHS.SourceData.Teachers.Availability.path,
    locale,
  });
};

const getSchedulesUrl = (locale?: OasLanguage) => {
  return getOasUrlWithoutParams({
    pathname: PATHS.Plan.Schedules.path,
    locale,
  });
};

const getLessonGroupsUrl = (input: {
  scheduleId?: string;
  locale?: OasLanguage;
}) => {
  return getOasUrl({
    pathname: PATHS.Plan.Schedules.LessonGroups.path,
    params: [
      {
        key: PATHS.Plan.Schedules.params.scheduleId.pathKey,
        value: input.scheduleId,
      },
    ],
    locale: input.locale,
  });
};

const getCreateLessonGroupUrl = (input: {
  scheduleId?: string;
  locale?: OasLanguage;
}) => {
  return getOasUrl({
    pathname: PATHS.Plan.Schedules.LessonGroups.Create.path,
    params: [
      {
        key: PATHS.Plan.Schedules.params.scheduleId.pathKey,
        value: input.scheduleId,
      },
    ],
    locale: input.locale,
  });
};

const getEditLessonGroupUrl = (input: {
  lessonGroupId: string;
  scheduleId?: string;
  locale?: OasLanguage;
}) => {
  return getOasUrl({
    pathname: PATHS.Plan.Schedules.LessonGroups.Edit.path,
    params: [
      {
        key: PATHS.Plan.Schedules.params.scheduleId.pathKey,
        value: input.scheduleId,
      },
      {
        key: PATHS.Plan.Schedules.LessonGroups.params.lessonGroupId.pathKey,
        value: input.lessonGroupId,
      },
    ],
    locale: input.locale,
  });
};

const getTimePlanUrl = (input: {
  scheduleId?: string;
  locale?: OasLanguage;
}) => {
  return getOasUrl({
    pathname: PATHS.Plan.Schedules.TimePlan.path,
    params: [
      {
        key: PATHS.Plan.Schedules.params.scheduleId.pathKey,
        value: input.scheduleId,
      },
    ],
    locale: input.locale,
  });
};

const getTeachingTimeReportUrl = (input: {
  scheduleId?: string;
  locale?: OasLanguage;
}) => {
  return getOasUrl({
    pathname: PATHS.Plan.Schedules.TeachingTimeReport.path,
    params: [
      {
        key: PATHS.Plan.Schedules.params.scheduleId.pathKey,
        value: input.scheduleId,
      },
    ],
    locale: input.locale,
  });
};

const getShareUrl = (input: { shareCode: string; locale?: OasLanguage }) => {
  return getOasUrl({
    pathname: PATHS.Share.path,
    params: [
      {
        key: PATHS.Share.params.shareId.pathKey,
        value: input.shareCode,
      },
    ],
    locale: input.locale,
  });
};

const getWeeksUrl = (locale?: OasLanguage) => {
  return getOasUrlWithoutParams({
    pathname: PATHS.Allocation.Weeks.path,
    locale,
  });
};

const getYearPlanUrl = (locale?: OasLanguage) => {
  return getOasUrlWithoutParams({
    pathname: PATHS.Allocation.YearPlan.path,
    locale,
  });
};

export default {
  home: getSchedulesUrl,
  welcome: getWelcomeUrl,
  error: getErrorUrl,
  organization: getOrganizationUrl,
  term: getTermUrl,
  classes: getClassesUrl,
  classesAvailability: getClassesAvailabilityUrl,
  classesGroups: getClassesGroupsUrl,
  classesStudents: getClassesStudentsUrl,
  classesStudentsGroups: getClassesStudentsGroupsUrl,
  rooms: getRoomsUrl,
  roomsAvailability: getRoomsAvailabilityUrl,
  subjects: getSubjectsUrl,
  teachers: getTeachersUrl,
  teachersAvailability: getTeachersAvailabilityUrl,
  schedules: getSchedulesUrl,
  lessonGroups: getLessonGroupsUrl,
  createLessonGroup: getCreateLessonGroupUrl,
  editLessonGroup: getEditLessonGroupUrl,
  timePlan: getTimePlanUrl,
  teachingTimeReport: getTeachingTimeReportUrl,
  share: getShareUrl,
  weeks: getWeeksUrl,
  yearPlan: getYearPlanUrl,
};
