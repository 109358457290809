import { useCallback } from 'react';

import { RouterSwitch } from 'core/cells/router-switch';
import { RouterRoute } from 'core/cells/router-route';
import { RouterRedirect } from 'core/cells/router-redirect';

import { URLS, OasPath, useLocale } from 'core/dna/routing';

import { AuthLogin, AuthCallback } from 'auth/pages';

import AuthPaths from 'auth/paths';

import 'templates/mdp/assets/scss/material-dashboard-pro-react.scss';

import { useAuthHandler } from 'app/auth';
import { useErrorsHandler } from 'app/errors/use-errors-handler';

import { ModuleRoutesProvider } from 'app/providers/module-routes-provider';

import { DashboardLayout } from 'app/layouts/dashboard-layout';
import { AuthLayout } from 'app/layouts/auth-layout';

import { useGetAuthRoutes } from 'app/routes';

const DEFAULT_PATH = AuthPaths.path;

export const App = () => {
  const locale = useLocale();

  const getDefaultUrl = useCallback(
    () =>
      URLS.getOasUrlWithoutParams({
        pathname: DEFAULT_PATH as OasPath,
        locale,
      }),
    [locale],
  );

  useErrorsHandler();
  useAuthHandler();

  const authRoutes = useGetAuthRoutes();

  return (
    <RouterSwitch>
      <RouterRoute exact path={AuthPaths.Login.path}>
        <AuthLogin />
      </RouterRoute>
      <RouterRoute exact path={AuthPaths.Callback.path}>
        <AuthCallback />
      </RouterRoute>
      <RouterRoute path={AuthPaths.path}>
        <AuthLayout routes={authRoutes} />
      </RouterRoute>
      <RouterRoute
        path={[
          '/:locale(no|en|uk)/',
          '/:locale(no|en|uk)/planner',
          '/:locale(no|en|uk)/directory',
          '/:locale(no|en|uk)/student',
          '/:locale(no|en|uk)/substitute',
          '/:locale(no|en|uk)/grades',
        ]}
      >
        <ModuleRoutesProvider>
          <DashboardLayout />
        </ModuleRoutesProvider>
      </RouterRoute>
      <RouterRoute path="*">
        <RouterRedirect to={getDefaultUrl()} />
      </RouterRoute>
    </RouterSwitch>
  );
};
