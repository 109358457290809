import { v4 as uuidv4 } from 'uuid';

import { defaultColors } from 'core/cells/color-picker';

import { GetSubjectInfosFromSync_SyncSubject as ApiSubjectInfoSync } from 'core/dna/types/generated/GetSubjectInfosFromSync';
import { GetSubjectInfosFromDefault_DefaultSubject as ApiSubjectInfoDefault } from 'core/dna/types/generated/GetSubjectInfosFromDefault';
import { mapNullableListToList } from 'core/dna/functions';

import { SubjectInfo } from 'modules/planner/dna/types/subject';

export const mapApiSubjectInfoListToSubjectInfoList = (
  apiSubjectInfoList:
    | (ApiSubjectInfoDefault | ApiSubjectInfoSync | null)[]
    | null,
): SubjectInfo[] | undefined => {
  return mapNullableListToList<
    ApiSubjectInfoDefault | ApiSubjectInfoSync,
    SubjectInfo
  >(apiSubjectInfoList, mapApiSubjectInfoToSubjectInfo);
};

export const mapApiSubjectInfoToSubjectInfo = (
  apiSubjectInfo: ApiSubjectInfoDefault | ApiSubjectInfoSync | null,
): SubjectInfo | undefined => {
  if (!apiSubjectInfo) {
    return;
  }

  const { name, short } = apiSubjectInfo;

  const randomIndex = Math.floor(Math.random() * 24); // returns a random integer between 0 and 23 (both included)
  return {
    id: uuidv4(),
    name: name!,
    alias: short!,
    bgColor: defaultColors[randomIndex],
  } as SubjectInfo;
};
