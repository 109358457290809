import { gql } from 'core/dna/types/apollo';

export const UPDATE_OPENING_HOURS_MUTATION = gql`
  mutation UpdateOpeningHours($input: UpdateOpeningHoursInput!) {
    updateOpeningsHours(input: $input) {
      id
      startTime {
        formatted
      }
      endTime {
        formatted
      }
      weekday
    }
  }
`;
