import React, { ReactNode } from 'react';
import clsx from 'clsx';

import Snack from '@material-ui/core/SnackbarContent';
import IconButton from '@material-ui/core/IconButton';

// @material-ui/icons
import Close from '@material-ui/icons/Close';

import { useStyles } from './snackbar-content.styles';

type SnackbarContentColor =
  | 'info'
  | 'success'
  | 'warning'
  | 'danger'
  | 'primary'
  | 'rose';

export interface SnackbarContentProps {
  message: ReactNode; // PropTypes.node.isRequired,
  color?: SnackbarContentColor; // PropTypes.oneOf([]),
  close?: boolean; // PropTypes.bool,
  icon?: any; // PropTypes.object,
}

export const SnackbarContent = (props: SnackbarContentProps) => {
  const classes = useStyles();
  const { message, color, close, icon } = props;
  let action: any[] = [];
  const messageClasses = clsx({
    [classes.iconMessage]: icon !== undefined,
  });
  if (close !== undefined) {
    action = [
      <IconButton
        className={classes.iconButton}
        key="close"
        aria-label="Close"
        color="inherit"
      >
        <Close className={classes.close} />
      </IconButton>,
    ];
  }
  const iconClasses = clsx({
    [classes.icon]: classes.icon,
    [classes.infoIcon]: color === 'info',
    [classes.successIcon]: color === 'success',
    [classes.warningIcon]: color === 'warning',
    [classes.dangerIcon]: color === 'danger',
    [classes.primaryIcon]: color === 'primary',
    [classes.roseIcon]: color === 'rose',
  });
  return (
    <Snack
      message={
        <div>
          {icon !== undefined ? <props.icon className={iconClasses} /> : null}
          <span className={messageClasses}>{message}</span>
        </div>
      }
      classes={{
        root: clsx(classes.root, color ? classes[color] : ''),
        message: classes.message,
      }}
      action={action}
    />
  );
};

SnackbarContent.defaultProps = {
  color: 'info',
};
