import moment from 'moment';
import { date } from 'core/atoms/date-time/formats';
import { OasError } from '../../errors';

export const mapTimeStringToDateTime = (time: string) => {
  const today = moment().format(date);

  if (!today || !time) {
    throw new OasError(
      'Time string should be in either "HH:mm:ss" or "HH:mm" format',
      { title: 'map-time-string-to-date-time', type: 'OasError' },
    );
  }

  return `${today}T${time}`;
};
