import { CSSProperties, makeStyles, createStyles } from 'core/atoms/styles';

import {
  customSelectStyle,
  CustomSelectStyle,
} from '../../custom-select-style';

export interface ReactTableStyle extends CustomSelectStyle {
  formControlMargins: CSSProperties;
  gridContainer: CSSProperties;
  filterContainer: CSSProperties;
  columnHeader: CSSProperties;
  rt: CSSProperties;
  tableBody: CSSProperties;
}

export const reactTableStyle: ReactTableStyle = {
  ...customSelectStyle,
  formControlMargins: {
    margin: '3px 0 !important',
    minWidth: '130px',
  },
  gridContainer: {
    justifyContent: 'center',
  },
  filterContainer: {
    '& > *': {
      paddingTop: '15px',
      marginBottom: '15px',
    },
  },
  columnHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  rt: {
    '&.ReactTable': {
      '& .rt-tbody': {
        overflow: 'none !important',
      },
    },
  },
  tableBody: {
    '&.rt-tbody': {
      overflow: 'none !important',
    },
  },
};

export const useStyles = makeStyles(() => createStyles({ ...reactTableStyle }));
