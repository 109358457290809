import { useCallback, useEffect, useMemo, useState } from 'react';
import { Close, Edit, People } from '@material-ui/icons';
import SweetAlert from 'react-bootstrap-sweetalert';
import clsx from 'clsx';

import { uniq } from 'core/atoms/functions/array';
// import { uniqBy } from 'core/atoms/functions/array/uniq-by';

import { Skeleton } from 'core/cells/skeleton';
import { PeopleGroupIcon } from 'core/cells/oas-icons';

import { useTranslations } from 'core/dna/translations';
import { useLocale, useHistory } from 'core/dna/routing';

import { useGetTermsState } from 'core/memory/apollo/terms/local';
import { createReactTableStore } from 'core/memory/pure/react-table-store';

import { useReactTableState } from 'core/metabolism/table-state';

import { GridContainer, GridItem } from 'templates/mdp/components/grid';
import {
  Card,
  CardBody,
  CardHeader,
  CardIcon,
} from 'templates/mdp/components/card';
import { Table } from 'templates/mdp/components/react-table';
import { Button } from 'templates/mdp/components/custom-buttons';

import { useStyles as useSweetAlertStyles } from 'templates/mdp/views/components/sweet-alert.styles';

import {
  useGetSelectedClassState,
  useSetSelectedClassState,
} from 'modules/planner/memory/apollo/classes/local';
import {
  useGetStudentsGroupsState,
  useSetStudentsGroupsState,
} from 'modules/planner/memory/apollo/classes/local/students-groups';

import { useClasses } from 'modules/planner/metabolism/use-classes';

import PlannerUrls from 'modules/planner/routing/urls';

import { ColGroups } from './components/col-groups';
import { ColBreaks } from './components/col-breaks';
import { ColStudents } from './components/col-students';

import { ClassEditor } from './class-editor';
import { useClassesTableRead } from './use-classes-table-read';
import { useClassesTableWrite } from './use-classes-table-write';

export interface ClassesTableProps {
  onGenerateClassesClick?: () => void;
}

export const classesTableStore = createReactTableStore({}, {});

export const ClassesTable = (props: ClassesTableProps) => {
  const swAlClasses = useSweetAlertStyles();

  const { onGenerateClassesClick } = props;
  const { texts } = useTranslations();
  const locale = useLocale();
  const { push } = useHistory();
  const { data: termsState } = useGetTermsState();
  const { setSelectedClasses, selectedClasses } = useClasses();
  const {
    setSelectedClassesState: setSelectedClass,
  } = useSetSelectedClassState();
  const { selectedClassState: selectedClass } = useGetSelectedClassState();

  const { columns, data, loading } = useClassesTableRead();
  const { onRowDelete } = useClassesTableWrite();

  const { getStudentsGroupsForClass } = useGetStudentsGroupsState();
  const { init: initClassStudentsGroups } = useSetStudentsGroupsState();

  useEffect(() => {
    data.forEach((item) => {
      initClassStudentsGroups({
        classId: item.id,
        unions: item.unions ?? [],
        students: item.students ?? [],
      });
    });
  }, [data, initClassStudentsGroups]);

  const {
    initialPage,
    pageSize,
    sort,
    filters,
    setSort,
    setFilters,
    setPage,
    setPageSize,
  } = useReactTableState(classesTableStore, termsState?.selected ?? undefined);

  const [showModal, setShowModal] = useState(false);

  const [alert, setAlert] = useState<any>(null);

  const hideAlert = useCallback(() => {
    setAlert(null);
  }, []);

  const hideAlertAndGoToClassGroups = useCallback(() => {
    hideAlert();
    push(PlannerUrls.classesGroups(locale));
  }, [hideAlert, locale, push]);

  const getClassTableItem = useCallback(
    (id?: string) => data.find((item) => id && item.id === id),
    [data],
  );

  const showAlertNoGroupsDefined = useCallback(
    (title: string) => {
      setAlert(
        <SweetAlert
          title={title}
          style={{ display: 'block', marginTop: '-100px' }}
          onConfirm={hideAlertAndGoToClassGroups}
          onCancel={hideAlert}
          confirmBtnCssClass={clsx(swAlClasses.button, swAlClasses.success)}
          cancelBtnCssClass={clsx(swAlClasses.button, swAlClasses.danger)}
          confirmBtnText={texts.oasCommon.continue}
          cancelBtnText={texts.oasCommon.cancel}
          showCancel
        >
          {
            texts.planner.sourceDataSpace.classesModule
              .needToDefineSubgroupsBeforeOrganizeStudents
          }
        </SweetAlert>,
      );
    },
    [hideAlert, hideAlertAndGoToClassGroups, swAlClasses, texts],
  );

  const handleAdd = () => {
    setSelectedClass('');
    setShowModal(true);
  };

  const handleStudentsGroupsClick = useCallback(
    async (classTableItemId: string, chaptersCount: number) => {
      setSelectedClass(classTableItemId);
      const newSelectedClasses = uniq([...selectedClasses, classTableItemId]);
      if (newSelectedClasses.length !== selectedClasses.length) {
        setSelectedClasses(newSelectedClasses);
      }
      if (chaptersCount > 1) {
        push(PlannerUrls.classesStudentsGroups(locale));
      } else {
        showAlertNoGroupsDefined(
          getClassTableItem(classTableItemId)?.name ?? '',
        );
      }
    },
    [
      getClassTableItem,
      locale,
      push,
      selectedClasses,
      setSelectedClass,
      setSelectedClasses,
      showAlertNoGroupsDefined,
    ],
  );

  const handleUpdate = useCallback(
    async (classTableItemId: string) => {
      setSelectedClass(classTableItemId);
      setShowModal(true);
    },
    [setSelectedClass],
  );

  const handleDelete = useCallback(
    async (classId: string) => {
      await onRowDelete(getClassTableItem(classId)!);
    },
    [getClassTableItem, onRowDelete],
  );

  const names = useMemo(() => uniq(data.map((item) => item.name)), [data]);

  const preparedData = useMemo<any[]>(() => {
    return data.map((prop) => {
      const studentsGroupsState = getStudentsGroupsForClass(prop.id);
      function getStudentsAssignmentsColor() {
        switch (studentsGroupsState.assignmentsStatus) {
          case 'all set':
            return 'success';
          case 'in progress':
            return 'warning';
          case 'unassigned':
          default:
            return 'info';
        }
      }
      function isSimpleButton() {
        switch (studentsGroupsState.assignmentsStatus) {
          case 'all set':
          case 'in progress':
          case 'unassigned':
          default:
            return true;
        }
      }
      return {
        ...prop,
        // TODO (Ihor): on delete table item exits error
        availabilityDefined: <ColBreaks item={prop} />,
        chaptersCount: <ColGroups item={prop} />,
        studentsCount: <ColStudents item={prop} />,
        actions: (
          <div className="actions-right">
            {prop.studentsCount ? (
              <Button
                justIcon
                round
                simple={isSimpleButton()}
                onClick={() =>
                  handleStudentsGroupsClick(prop.id, prop.chaptersCount ?? 0)
                }
                color={getStudentsAssignmentsColor()}
                className="edit"
              >
                <PeopleGroupIcon />
              </Button>
            ) : (
              <> </>
            )}{' '}
            <Button
              justIcon
              round
              simple
              onClick={() => handleUpdate(prop.id)}
              color="info"
              className="edit"
            >
              <Edit />
            </Button>{' '}
            <Button
              justIcon
              round
              simple
              onClick={() => handleDelete(prop.id)}
              color="danger"
              className="remove"
            >
              <Close />
            </Button>{' '}
          </div>
        ),
      };
    });
  }, [
    data,
    getStudentsGroupsForClass,
    handleDelete,
    handleStudentsGroupsClick,
    handleUpdate,
  ]);

  return loading || !termsState?.selected ? (
    <Skeleton variant="rect" width="100%" height="80vh" />
  ) : (
    <GridContainer>
      <GridItem xs={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button color="secondary" onClick={handleAdd}>
          {texts.planner.sourceDataSpace.classesModule.addClass}
        </Button>
        <Button color="secondary" onClick={onGenerateClassesClick}>
          {texts.planner.sourceDataSpace.classesModule.generateClasses}
        </Button>
      </GridItem>
      <GridItem xs={12}>
        <Card>
          <CardHeader icon>
            <CardIcon color="primary">
              <People />
            </CardIcon>
            {/* <h4 className={classes.cardIconTitle}>{title}</h4> */}
          </CardHeader>
          <CardBody>
            {alert}
            <Table
              columns={columns}
              data={preparedData}
              key={termsState?.selected ?? 'unknown_term'}
              onChangeRowsPerPage={setPageSize}
              onChangePage={setPage}
              onFilterChange={setFilters}
              onOrderChange={setSort}
              options={{
                initialPage,
                pageSize,
                sort,
                filters,
              }}
            />
          </CardBody>
        </Card>
      </GridItem>
      <ClassEditor
        id={selectedClass}
        defaultName={getClassTableItem(selectedClass)?.name ?? ''}
        open={showModal}
        setOpen={setShowModal}
        names={names}
      />
    </GridContainer>
  );
};
