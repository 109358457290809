import { Grid } from 'core/cells/grid';

import { StudentsTable } from './components/students-table';

export const StudentsMainContent = () => {
  return (
    <Grid container>
      <Grid item xs={12}>
        <StudentsTable />
      </Grid>
    </Grid>
  );
};
