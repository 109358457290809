import { useState, useLayoutEffect } from 'react';

import { classesStore } from './classes-store';

export const useClassesState = () => {
  const [state, setState] = useState(classesStore.initialState);

  useLayoutEffect(() => {
    const subscription = classesStore.subscribe(setState);
    return () => {
      subscription.unsubscribe();
    };
  }, []);

  return {
    classesState: state,
  };
};
