import { useCallback } from 'react';

import { makeStyles, createStyles } from 'core/atoms/styles';

import { EventContentArg } from 'core/cells/full-calendar';
import { IconButton } from 'core/cells/icon-button';
import { DeleteIcon } from 'core/cells/oas-icons';

import { DefaultEvent } from '../default-event';

export interface RemovableEventProps extends EventContentArg {
  onDelete?: (eventId: string) => void;
}

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      '& .fc-delete': {
        display: 'none',
      },
      '&:hover': {
        '& .fc-delete': {
          display: 'block',
          position: 'absolute',
          top: 0,
          right: 0,
        },
      },
    },
    deleteButton: {
      padding: 3,
      '&:hover': {
        backgroundColor: 'rgba(255,255,255,0.2)',
      },
    },
    deleteIcon: {
      fontSize: '0.8rem',
      color: theme.palette.secondary.contrastText,
    },
  }),
);

export const RemovableEvent = (props: RemovableEventProps) => {
  const classes = useStyles();
  const { onDelete, event, ...rest } = props;

  const handleEventDelete = useCallback(() => {
    onDelete?.(event.id);
  }, [event.id, onDelete]);

  return (
    <DefaultEvent className={classes.root} event={event} {...rest}>
      <div className="fc-delete">
        <IconButton
          className={classes.deleteButton}
          aria-label="Delete"
          onClick={handleEventDelete}
        >
          <DeleteIcon className={classes.deleteIcon} />
        </IconButton>
      </div>
    </DefaultEvent>
  );
};
