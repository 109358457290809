import { tick } from 'core/atoms/date-time';
import { isObject, isArray, isString } from 'core/atoms/functions/type-check';

import { LogType } from './log-type';
import { logStyles } from './log-styles';
import { formatGroupMessage } from './format-group-message';

const withTime = (message: string) =>
  `${tick().format('YYYY-MM-DD HH:mm:ss')} ${message}`;

const withDebugTime = (message: string) =>
  `${tick().format('YYYY-MM-DD HH:mm:ss.SSS')} ${message}`;

export const rainbow = (message: string) => {
  console.log(`%c${message}`, logStyles[LogType.Rainbow]);
};

export const trace = (message: string, type?: LogType) => {
  const styles: string = type ? logStyles[type] : logStyles[LogType.Default];
  console.log(`%c${withTime(message)}`, styles);
};

// export const debug = (message: string, type?: LogType) => {
//   const styles: string = type ? logStyles[type] : logStyles[LogType.Default];
//   console.log(`%c${withDebugTime(message)}`, styles);
// };

export const info = (message: string, type?: LogType) => {
  const styles: string = type ? logStyles[type] : logStyles[LogType.Default];
  console.info(`%c${message}`, styles);
};

export const warn = (message: string) => {
  console.warn(message);
};

const debugString = (
  value: string,
  options?: {
    title?: string;
    logger?: string;
    type?: LogType;
  },
) => {
  const { title = 'TITLE', logger = 'logger', type = LogType.Default } =
    options ?? {};
  const styles = logStyles[type];
  console.debug(...formatGroupMessage(value, title, styles, logger));
};

const debugObject = (
  value: Record<string, any>,
  options: {
    title?: string;
    logger?: string;
    type?: LogType;
  },
) => {
  const { title = 'TITLE', logger = 'logger', type = LogType.Default } =
    options ?? {};
  const styles = logStyles[type];
  const group = formatGroupMessage(value, title, styles, logger);
  if (!value || !Object.keys(value).length) {
    console.debug(...group);
    return;
  }
  console.groupCollapsed(...group);
  for (let i = 0; i < Object.keys(value).length; i++) {
    const obj = value[i];
    if (isObject(obj)) {
      console.groupCollapsed(
        `%c Object:  ${obj.id ?? `[${i}]`}`,
        'color: gray; font-weight: lighter;',
      );
      Object.keys(obj).forEach((propKey) => {
        if (isArray(obj[propKey])) {
          debugArray(obj[propKey], { title: propKey, type, logger });
        } else {
          console.debug(
            `%c "${propKey}": ${JSON.stringify(obj[propKey])}`,
            styles,
          );
        }
      });
      console.groupEnd();
    } else {
      console.debug(`%c${JSON.stringify(obj)}`, styles);
    }
  }
  console.groupEnd();
};

const debugArray = (
  array: any[],
  options: {
    title?: string;
    logger?: string;
    type?: LogType;
  },
) => {
  const { title = 'TITLE', logger = 'logger', type = LogType.Default } =
    options ?? {};
  const styles = logStyles[type];
  const group = formatGroupMessage(array, title, styles, logger);
  if (!array.length) {
    console.debug(...group);
    return;
  }
  console.groupCollapsed(...group);
  for (let i = 0; i < array.length; i++) {
    const obj = array[i];
    if (isObject(obj)) {
      console.groupCollapsed(
        `%c Object:  ${obj.id ?? `[${i}]`}`,
        'color: gray; font-weight: lighter;',
      );
      // eslint-disable-next-line no-restricted-syntax
      for (const prop in obj) {
        if (isArray(obj[prop])) {
          debugArray(obj[prop], { title: prop, type, logger });
        } else {
          console.debug(`%c "${prop}": ${JSON.stringify(obj[prop])}`, styles);
        }
      }
      console.groupEnd();
    } else {
      console.debug(`%c${JSON.stringify(obj)}`, styles);
    }
  }
  console.groupEnd();
};

export const debug = (options: {
  title?: string;
  logger?: string;
  type?: LogType;
  value?: any[] | Record<string, any> | string;
}) => {
  if (!options.value) {
    debugString('', {
      title: options.title,
      logger: options.logger,
      type: options.type,
    });
  } else if (isObject(options.value)) {
    debugObject(options.value as Record<string, any>, {
      title: options.title,
      logger: options.logger,
      type: options.type,
    });
  } else if (isArray(options.value)) {
    debugArray(options.value as any[], {
      title: options.title,
      logger: options.logger,
      type: options.type,
    });
  } else if (isString(options.value)) {
    debugString(String(options.value), {
      title: options.title,
      logger: options.logger,
      type: options.type,
    });
  }
};
