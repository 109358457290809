export const mapStringListToString = (list?: string[]): string | undefined => {
  if (!list?.length) {
    return;
  }
  // const countStr = list.length > 1 ? ` (+${list.length - 1})` : '';
  // return `${list[0]}${countStr}`;

  return list.reduce((prev, cur) => {
    return prev ? `${prev}, ${cur}` : cur;
  }, '');
};
