import { useMemo } from 'react';

import { useTranslations } from 'core/dna/translations';
import { formatDateTimeString } from 'core/atoms/functions/string';
import { dateTimeShortForTableSorting } from 'core/atoms/date-time/formats';

import { EvaluationBatch } from 'modules/grades/dna/types';
import { useGetEvaluationBatchList } from 'modules/grades/memory/apollo/evaluation/remote';

export interface BatchTableItem extends EvaluationBatch {
  deadlineAt: string;
}

export const useBatchTableRead = () => {
  const { texts } = useTranslations();
  const { loading, data } = useGetEvaluationBatchList();

  const columns = useMemo(() => {
    return [
      {
        Header: texts.oasCommon.type,
        accessor: 'type',
      },
      {
        Header: texts.grades.gradesCommon.subTerm,
        accessor: 'term',
      },
      {
        Header: texts.oasCommon.deadline,
        accessor: 'deadlineAt',
      },
      {
        Header: texts.oasCommon.state,
        accessor: 'state',
      },
      {
        Header: '',
        accessor: 'actions',
        disableFilters: true,
      },
    ];
  }, [texts]);

  const preparedData = useMemo<BatchTableItem[]>(() => {
    if (!data) {
      return [];
    }

    return data.map((batch) => {
      return {
        ...batch,
        deadlineAt: !batch.deadlineAt
          ? ''
          : formatDateTimeString(
              batch.deadlineAt,
              dateTimeShortForTableSorting,
            ),
      };
    });
  }, [data]);

  return {
    title: texts.grades.prepareBatch.title,
    columns,
    data: preparedData,
    loading,
  };
};
