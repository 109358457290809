import { useMemo, useState } from 'react';
import clsx from 'clsx';

import { makeStyles, createStyles, Theme } from 'core/atoms/styles';
import { DraggableItem } from 'core/atoms/types';
import { orderBy } from 'core/atoms/functions/array';

import { Paper } from 'core/cells/paper';
import { TextFilterField } from 'core/cells/text-filter-field';

import { useLocale } from 'core/dna/routing';
import { useTranslations } from 'core/dna/translations';

import { useClasses } from 'modules/planner/metabolism/use-classes';

import PlannerUrls from 'modules/planner/routing/urls';

import { useLgcState } from '../../../../store';

import { NoBlockData } from '../no-block-data';

import { ClassWithChapters } from './class-with-chapters';

export interface ClassesBlockProps {
  className?: string;
  onChapterDoubleClick?: (item: DraggableItem) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    list: {
      display: 'flex',
      flexWrap: 'wrap',
      marginTop: theme.spacing(2),
    },
  }),
);

export const ClassesBlock = (props: ClassesBlockProps) => {
  const { className, onChapterDoubleClick } = props;
  const classes = useStyles();
  const { texts } = useTranslations();
  const { classData, classesError, classesLoading } = useClasses();
  const [filter, setFilter] = useState<string>('');
  const locale = useLocale();
  const { lgcState } = useLgcState();

  const assignedChapters = useMemo(
    () =>
      lgcState.chapters?.map((c) => {
        return { chapterId: c.id, classId: c.data?.class?.id };
      }) ?? [],
    [lgcState.chapters],
  );

  if (classesError) return <p>Groups Error!</p>;
  if (classesLoading) return <p>Loading Groups...</p>;

  return classData.length ? (
    <Paper className={clsx(classes.root, className)}>
      <TextFilterField
        label={texts.planner.sourceDataSpace.classesModule.filterClasses}
        value={filter}
        onChange={(e) => {
          setFilter && setFilter(e.target.value);
        }}
        onFilterClick={() => {
          setFilter && setFilter('');
        }}
      />
      <div className={classes.list}>
        {orderBy(
          classData
            .filter(
              (c) =>
                !filter ||
                (c.name || '').toLowerCase().indexOf(filter.toLowerCase()) > -1,
            )
            .filter((c) => {
              const allClassChapters = c.unions
                ?.map((u) => u.chapters?.map((c) => c.id))
                .reduce((prev, cur) => {
                  return [...(prev ?? []), ...(cur ?? [])];
                }, []);

              return !allClassChapters?.every((cc) =>
                assignedChapters.map((ac) => ac.chapterId).includes(cc),
              );
            }),
          ['name'],
        ).map((c) => (
          <ClassWithChapters
            key={c.id}
            cls={{ ...c }}
            assignedChapters={assignedChapters}
            onChapterDoubleClick={onChapterDoubleClick}
          />
        ))}
      </div>
    </Paper>
  ) : (
    <NoBlockData
      message={texts.planner.sourceDataSpace.classesModule.noClasses}
      link={PlannerUrls.classes(locale)}
      linkText={texts.planner.sourceDataSpace.classesModule.addClass}
      className={classes.root}
    />
  );
};
