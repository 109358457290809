export const apiFormat = 'YYYY-MM-DDTHH:mm:ss';
export const dateTimeInputFormat = 'yyyy-MM-DD[T]HH:mm';

export const timeShort = 'HH:mm';
export const timeLong = 'HH:mm:ss';
export const date = 'YYYY-MM-DD';
export const dateDot = 'YYYY.MM.DD';
export const dateNo = 'DD.MM.YYYY';
export const dayLongWithDateNo = 'dddd DD.MM.YYYY';
export const dayShort = 'ddd';
export const monthShort = 'MMM';
export const dateTimeShortNo = `${dateNo}, ${timeShort}`;
export const dateTimeShortForTableSorting = `${dateDot}, ${timeShort}`;
