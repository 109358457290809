import React from 'react';
import { ApolloError } from '@apollo/client/errors';
import { Typography } from '@material-ui/core';
import { Grid } from 'core/cells/grid';
import { Skeleton } from 'core/cells/skeleton';
import { Remark } from 'modules/student/dna/types';
import { useTranslations } from 'core/dna/translations';
import { RemarkTable } from './components/remark-table';
import { RemarkTotalByCategory } from './components/remark-total-by-category';

interface StudentRemarksProps {
  data: Remark[] | null | undefined;
  error: ApolloError | undefined;
}

export const StudentRemarks = ({ data, error }: StudentRemarksProps) => {
  const { texts } = useTranslations();

  if (
    error &&
    data === null &&
    error.graphQLErrors.some(
      (e) => e.path && e.path[e.path.length - 1] === 'remarks',
    )
  ) {
    return (
      <Typography variant="h5" color="textSecondary" align="center">
        {texts.oasCommon.notAuthForResource}
      </Typography>
    );
  }

  return !data ? (
    <Skeleton variant="rect" width="100%" height="80vh" />
  ) : (
    <Grid container spacing={3} justify="center">
      <Grid item xs={12} lg={3}>
        {data && <RemarkTotalByCategory data={data} />}
      </Grid>
      <Grid item xs={12} lg={9}>
        <RemarkTable data={data} loading={false} />
      </Grid>
    </Grid>
  );
};
