import clsx from 'clsx';

import { makeStyles, createStyles } from 'core/atoms/styles';

import { EventContentArg, ComponentChildren } from 'core/cells/full-calendar';

export type DefaultEventProps = EventContentArg & {
  children?: ComponentChildren;
  className?: string;
};

const useStyles = makeStyles(() =>
  createStyles({
    title: {
      fontWeight: 'bold',
    },
  }),
);

export const DefaultEvent = (props: DefaultEventProps) => {
  const classes = useStyles();
  const { timeText, event, children, className } = props;

  return (
    <div className={clsx('fc-event-main-frame', className)}>
      <div className="fc-event-time">{timeText}</div>
      <div className="fc-event-title-container">
        <div className={clsx('fc-event-title fc-sticky', classes.title)}>
          {event.title ?? 'NO TITLE'}
        </div>
      </div>
      {children}
    </div>
  );
};
