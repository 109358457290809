import { useState, useEffect } from 'react';
// import { v4 as uuidv4 } from 'uuid';

import { ConfirmationAutoFocus } from 'core/atoms/types';

// import { ConfirmationDialog } from 'core/cells/confirmation-dialog';
import { RouterPrompt } from 'core/cells/router-prompt';

import { useTranslations } from 'core/dna/translations';

import { useOasHistory } from 'core/metabolism/use-oas-history';

import { useConfirmationAlert } from 'modules/planner/organisms/alert';

export interface RouteLeavingGuardProps {
  when?: boolean;
  shouldBlockNavigation: (nextLocation: any) => boolean;
}

export const RouteLeavingGuard = (props: RouteLeavingGuardProps) => {
  const [lastLocation, setLastLocation] = useState(null);
  const [confirmedNavigation, setConfirmedNavigation] = useState(false);
  // const [modalVisible, setModalVisible] = useState(false);
  const history = useOasHistory();
  const { texts } = useTranslations();

  const { when, shouldBlockNavigation } = props;

  useEffect(() => {
    if (confirmedNavigation) {
      // Navigate to the previous blocked location with your navigate function
      history.push((lastLocation || ({} as any)).pathname);
    }
  }, [confirmedNavigation, history, lastLocation]);

  const handleBlockedNavigation = (nextLocation: any) => {
    if (!confirmedNavigation && shouldBlockNavigation(nextLocation)) {
      setLastLocation(nextLocation);
      // setModalVisible(true);
      showAlert();
      return false;
    }
    return true;
  };

  // const handleConfirmNavigationClose = (confirmed: boolean) => {
  //   setModalVisible(false);
  //   if (!confirmed) {
  //     return;
  //   }
  //   if (lastLocation) {
  //     setConfirmedNavigation(true);
  //   }
  // };

  const { alert, showAlert, hideAlert } = useConfirmationAlert({
    onConfirm: () => {
      if (lastLocation) {
        setConfirmedNavigation(true);
      }
      hideAlert();
    },
    message: texts.oasCommon.continueWithUnsavedChangesConfirmation,
    confirmText: texts.planner.common.yes,
    cancelText: texts.planner.common.no,
    autoFocus: ConfirmationAutoFocus.Cancel,
  });

  return (
    <>
      <RouterPrompt when={when} message={handleBlockedNavigation} />
      {alert}
    </>
  );
};

/*
{alert ?? (
        <ConfirmationDialog
          id={`${uuidv4()}-leaving-guard-confirmation`}
          onClose={handleConfirmNavigationClose}
          open={modalVisible}
          content={lang.common.routeLeavingConfirmation}
          okText={lang.common.yes}
          cancelText={lang.common.no}
          autoFocus={ConfirmationAutoFocus.Cancel}
        />
      )}
 */
