import { useCallback } from 'react';

import { makeStyles, Theme, createStyles } from 'core/atoms/styles';

import { InputAdornment } from 'core/cells/input-adornment';
import { IconButton } from 'core/cells/icon-button';
import { TextField, TextFieldProps } from 'core/cells/text-field';
import { FilterIcon, ClearCircleIcon } from 'core/cells/oas-icons';

interface OmittedProps {
  variant: 'outlined';
}

export interface TextFilterFieldProps
  extends Omit<TextFieldProps, keyof OmittedProps> {
  onFilterClick?: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    labelFormControl: {
      // transform: 'translate(0, 40px) scale(1)',
    },
  }),
);

export const TextFilterField = (props: TextFilterFieldProps) => {
  const { InputProps, value, onChange, onFilterClick, ...rest } = props;

  const classes = useStyles();

  const onClick = useCallback(() => {
    onFilterClick?.();
  }, [onFilterClick]);

  const mixedInputProps = {
    endAdornment: (
      <InputAdornment position="end">
        {value ? (
          <IconButton edge="end" onClick={onClick}>
            <ClearCircleIcon />
          </IconButton>
        ) : (
          <FilterIcon />
        )}
      </InputAdornment>
    ),
    ...InputProps,
  };

  return (
    <TextField
      InputProps={mixedInputProps}
      value={value}
      onChange={onChange}
      {...rest}
      InputLabelProps={{ classes: { formControl: classes.labelFormControl } }}
    />
  );
};
