import { BehaviorSubject } from 'rxjs';
import { produce } from 'immer';

import { logger, LogType } from 'core/atoms/housekeeping';
// import { IdentityMap } from 'core/atoms/types';
import { OasError, OasErrorInterface, OasPageError } from 'core/atoms/errors';

export interface ImportTeachersResponse {
  type: 'success' | 'error';
  error?: OasErrorInterface;
}

export interface TeachersStoreState {
  checkedForImport: string[];
  importListVisible: boolean;
  importing: boolean;
  importingError: OasErrorInterface | null;
}

// const defaultIdentityMap: IdentityMap<any> = { allIds: [], byId: {} };

const initialState: TeachersStoreState = {
  checkedForImport: [],
  importListVisible: false,
  importing: false,
  importingError: null,
};

const subject = new BehaviorSubject(initialState);

let state = initialState;

const emit = (state: TeachersStoreState) => {
  subject.next(state);
};

const FILE_NAME = 'planner/source-data/teachers/teachers-store';

export const teachersStore = {
  subscribe: (setState: (value: TeachersStoreState) => void) =>
    subject.subscribe(setState),
  checkIdsForImport: (ids: string[]) => {
    state = produce(state, (draft) => {
      draft.checkedForImport = ids;
    });

    logger.debug({
      title: 'checkIdsForImport',
      logger: FILE_NAME,
      type: LogType.Info,
    });

    emit(state);
  },
  clearIdsForImport: () => {
    state = produce(state, (draft) => {
      draft.checkedForImport = [];
    });

    logger.debug({
      title: 'clearIdsForImport',
      logger: FILE_NAME,
      type: LogType.Info,
    });

    emit(state);
  },
  showImportList: () => {
    state = produce(state, (draft) => {
      draft.importListVisible = true;
    });

    logger.debug({
      title: 'showImportList',
      logger: FILE_NAME,
      type: LogType.Info,
    });

    emit(state);
  },
  hideImportList: () => {
    state = produce(state, (draft) => {
      draft.importListVisible = false;
    });

    logger.debug({
      title: 'hideImportList',
      logger: FILE_NAME,
      type: LogType.Info,
    });

    emit(state);
  },
  importTeachers: async (
    func: () => Promise<boolean[]>,
  ): Promise<ImportTeachersResponse> => {
    let result: ImportTeachersResponse;
    state = produce(state, (draft) => {
      draft.importListVisible = false;
      draft.importing = true;
      draft.importingError = null;
    });
    emit(state);
    try {
      await func();
      state = produce(state, (draft) => {
        draft.checkedForImport = [];
      });
      result = { type: 'success' };
    } catch (error) {
      let e: OasErrorInterface;
      if (error instanceof OasError) {
        e = error;
      } else {
        e = OasPageError.fromError(error, { title: FILE_NAME });
      }
      state = produce(state, (draft) => {
        draft.importingError = e;
      });
      result = { type: 'error', error: e };
    } finally {
      state = produce(state, (draft) => {
        draft.importing = false;
      });
    }
    emit(state);
    return result;
  },
  initialState,
};
