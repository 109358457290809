import { forwardRef, Ref } from 'react';
import FullCalendarReact from '@fullcalendar/react';

import { runtimeEnv } from 'core/atoms/env';
import { staticMonday } from 'core/atoms/date-time';

import {
  timeGridPlugin,
  interactionPlugin,
  momentPlugin,
  adaptivePlugin,
} from './plugins';
import { workingHours, localeNo } from './constants';
import { CalendarOptions } from './types';

export const licenseKey: string = runtimeEnv()
  .REACT_APP_FC_SCHEDULER_LICENSE_KEY;

export type FullCalendarProps = CalendarOptions;

const defaultProps: Partial<FullCalendarProps> = {
  plugins: [timeGridPlugin, interactionPlugin, momentPlugin, adaptivePlugin],
  locale: localeNo,
  initialView: 'timeGridWeek',
  weekNumbers: false,
  allDaySlot: false,
  headerToolbar: false,
  selectMirror: false,
  selectable: true,
  editable: true,
  droppable: true,
  eventOverlap: false,
  slotEventOverlap: false,
  weekends: false,
  slotLabelFormat: 'H:mm',
  slotLabelInterval: '01:00',
  slotDuration: '00:30:00',
  snapDuration: '00:45:00',
  slotMinTime: workingHours.start,
  slotMaxTime: workingHours.end,
  dayHeaderFormat: { weekday: 'short' },
  eventTimeFormat: 'H:mm',
  height: 'parent',
  now: staticMonday,
};

export const FullCalendar = forwardRef(
  (props: FullCalendarProps, ref: Ref<FullCalendarReact>) => {
    const { schedulerLicenseKey = licenseKey, ...rest } = props;
    return (
      <FullCalendarReact
        ref={ref}
        schedulerLicenseKey={schedulerLicenseKey}
        {...rest}
      />
    );
  },
);

export { FullCalendarReact as FullCalendarType };

FullCalendar.displayName = 'Full Calendar';
FullCalendar.defaultProps = defaultProps;
