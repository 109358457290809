import { RoleType } from 'core/dna/types/generated/_globalTypes';
import { OasLocales } from 'core/dna/translations/types';

import { Teacher, TeacherTableItem } from 'modules/planner/dna/types/teacher';

export const mapTeacherToTeacherTableItem = (
  teacher: Teacher,
  texts: OasLocales,
): TeacherTableItem => {
  const {
    id,
    title,
    availabilityPreferences,
    type,
    reservedEmploymentPercentage,
    employmentPercentage,
  } = teacher;

  return {
    id,
    title,
    firstName: teacher.person?.firstName,
    lastName: teacher.person?.lastName,
    availabilityDefined: !!availabilityPreferences?.length,
    type,
    typeText:
      type === RoleType.teacher
        ? texts.oasCommon.teachers.one
        : type === RoleType.assistant
        ? texts.oasCommon.assistants.one
        : texts.oasCommon.staff,
    reservedEmploymentPercentage,
    employmentPercentage,
  };
};
