import { useMemo } from 'react';

import { useTranslations } from 'core/dna/translations';

import { mapStudentToStudentsTableItem } from 'modules/planner/dna/functions/map-student-to-students-table-item';
import { Student } from 'modules/planner/dna/types/student';

import { useSelectedClass } from 'modules/planner/metabolism/use-selected-class';

export const useStudentsTableRead = () => {
  const { texts } = useTranslations();
  const { selectedClass } = useSelectedClass();

  const columns = useMemo(
    () => [
      {
        Header: texts.planner.common.firstName,
        accessor: 'firstName',
      },
      {
        Header: texts.planner.common.lastName,
        accessor: 'lastName',
      },
      // {
      //   Header: texts.planner.common.groups.many,
      //   accessor: 'chaptersCount',
      //   disableFilters: true,
      // },
      {
        Header: texts.planner.common.actions.many,
        accessor: 'actions',
      },
    ],
    [texts],
  );

  const hasStudents = useMemo(() => selectedClass?.students?.length, [
    selectedClass?.students?.length,
  ]);

  const hasGroups = useMemo(() => (selectedClass?.unions?.length ?? 0) > 1, [
    selectedClass?.unions?.length,
  ]);

  const data = useMemo(
    () =>
      (selectedClass?.students ?? [])
        .reduce((p, c) => [...p, c], [] as Student[])
        .map(mapStudentToStudentsTableItem)
        .sort((sti1, sti2) =>
          `${sti1.firstName ?? ''} ${sti1.lastName ?? ''}` >
          `${sti2.firstName ?? ''} ${sti2.lastName ?? ''}`
            ? -1
            : 1,
        ) ?? [],
    [selectedClass?.students],
  );

  return {
    title: texts.oasCommon.students.many,
    columns,
    data,
    hasStudents,
    hasGroups,
  };
};
