import { useCallback } from 'react';

import { OasMemoryError, OasError } from 'core/atoms/errors';

import { gql, useMutation } from 'core/dna/types/apollo';
import {
  ShareSchedule,
  ShareScheduleVariables,
} from 'core/dna/types/generated/ShareSchedule';

import { useLazyGetTermsState } from 'core/memory/apollo/terms/local';

import { GET_SCHEDULE_DESCRIPTIONS_QUERY } from './_gql';

const SHARE_SCHEDULE_MUTATION = gql`
  mutation ShareSchedule($input: ShareScheduleInput!) {
    shareSchedule(input: $input) {
      shareCode
    }
  }
`;

const TITLE = 'UseShareSchedule';

export const useShareSchedule = () => {
  const { getTermState } = useLazyGetTermsState();

  const [shareSchedule] = useMutation<ShareSchedule, ShareScheduleVariables>(
    SHARE_SCHEDULE_MUTATION,
  );

  const func = useCallback(
    async (scheduleId: string) => {
      try {
        const termsState = await getTermState(true);

        const result = await shareSchedule({
          variables: { input: { scheduleId } },
          refetchQueries: [
            {
              query: GET_SCHEDULE_DESCRIPTIONS_QUERY,
              variables: { termId: termsState!.selected! },
            },
          ],
        });

        if (result.errors) {
          throw new OasMemoryError(JSON.stringify(result.errors), {
            title: TITLE,
          });
        }
        return result.data?.shareSchedule ?? undefined;
      } catch (e: any) {
        if (e instanceof OasError) {
          throw e;
        } else {
          throw OasMemoryError.fromError(e, {
            title: TITLE,
          });
        }
      }
    },
    [shareSchedule, getTermState],
  );

  return {
    shareSchedule: func,
  };
};
