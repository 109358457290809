import SweetAlert from 'react-bootstrap-sweetalert';

import { makeStyles, createStyles } from 'core/atoms/styles';

import { Button } from 'templates/mdp/components/custom-buttons';
import { buttonStyle } from 'templates/mdp/components/custom-buttons/button.styles';
import { useMediaQuery } from '@material-ui/core';

const useStyles = makeStyles(() =>
  createStyles({
    printButton: {
      marginTop: 15,
      marginBottom: 0,
    },
    ...buttonStyle,
  }),
);

export const PrintButton = (props: { setAlert: (value: any) => void }) => {
  const { setAlert } = props;
  const classes = useStyles();

  const hidePrintAlert = () => {
    setAlert(null);
  };

  const printAlert = () => {
    setAlert(
      <SweetAlert
        style={{ display: 'block', marginTop: '-100px' }}
        title="Print"
        onCancel={() => hidePrintAlert()}
        onConfirm={() => hidePrintAlert()}
        confirmBtnCssClass={`${classes.button} ${classes.success}`}
      >
        <p>
          PC trykk: <b>ctrl+p</b>
        </p>
        <p>
          Mac trykk: <b>command+p</b>
        </p>
      </SweetAlert>,
    );
  };

  return (
    <Button
      size="sm"
      color="secondary"
      className={classes.printButton}
      onClick={printAlert}
    >
      Print
    </Button>
  );
};
