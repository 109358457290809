import {
  initialPage,
  pageSize,
} from 'modules/planner/dna/constants/table-state-constants';
import {
  ReactTableFilter,
  ReactTableSort,
  ReactTableState,
} from 'modules/planner/dna/types/react-table-state';

export interface ReactTableStore {
  getSort: (id: string) => ReactTableSort | undefined;
  getFilters: (id: string) => ReactTableFilter;
  getPage: (id: string) => number;
  getPageSize: (id: string) => number;
  setSort: (id: string, sort: ReactTableSort) => void;
  setFilters: (id: string, filters: ReactTableFilter) => void;
  setPage: (id: string, page: number) => void;
  setPageSize: (id: string, pageSize: number) => void;
  reset: () => void;
  initialPage: number;
  initialPageSize: number;
}

export interface TableStateMap {
  [id: string]: ReactTableState;
}

export const createReactTableStore = (
  state: TableStateMap,
  initialState: TableStateMap,
): ReactTableStore => {
  return {
    getSort: (id: string) => {
      return state?.[id]?.sort ?? [];
    },
    getFilters: (id: string) => {
      return { id: '', value: '' } as ReactTableFilter; // state?.[id]?.filters ?? [];
    },
    getPage: (id: string) => {
      return state?.[id]?.page ?? initialPage;
    },
    getPageSize: (id: string) => {
      return state?.[id]?.pageSize ?? pageSize;
    },
    setSort: (id: string, sort: ReactTableSort) => {
      state = {
        ...state,
        [id]: {
          ...state[id],
          id,
          sort,
        },
      };
    },
    setFilters: (id: string, filters: ReactTableFilter) => {
      state = {
        ...state,
        [id]: {
          ...state[id],
          id,
          filters,
        },
      };
    },
    setPage: (id: string, page: number) => {
      if (state?.[id]?.page === page) {
        return;
      }
      state = {
        ...state,
        [id]: {
          ...state[id],
          id,
          page,
        },
      };
    },
    setPageSize: (id: string, pageSize: number) => {
      if (state?.[id]?.pageSize === pageSize) {
        return;
      }
      state = {
        ...state,
        [id]: {
          ...state[id],
          id,
          pageSize,
        },
      };
    },
    reset: () => {
      state = initialState;
    },
    initialPage,
    initialPageSize: pageSize,
  };
};
