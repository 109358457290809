import { useMemo } from 'react';
import { useQuery, gql } from 'core/dna/types/apollo';
import { GetEvaluationGroupAccess } from 'core/dna/types/generated/GetEvaluationGroupAccess';

import { mapApiEvaluationGroupAccessToEvaluationGroupAccessList } from 'modules/grades/dna/functions';
import { EvaluationGroupAccess } from 'modules/grades/dna/types';

import { EVALUATION_GROUP_ACCESS_FRAGMENT } from 'modules/grades/memory/apollo/_fragments';

export const GET_EVALUATION_GROUP_ACCESS_QUERY = gql`
  query GetEvaluationGroupAccess {
    EvaluationGroupAccess {
      ...EvaluationGroupAccess
    }
  }
  ${EVALUATION_GROUP_ACCESS_FRAGMENT}
`;

export const useGetEvaluationGroupAccess = () => {
  const { data, error, loading } = useQuery<GetEvaluationGroupAccess>(
    GET_EVALUATION_GROUP_ACCESS_QUERY,
  );

  const evaluationGroupAccess = useMemo<
    EvaluationGroupAccess[] | undefined
  >(() => {
    if (loading || error || !data) {
      return;
    }

    if (!data.EvaluationGroupAccess) {
      return;
    }

    return mapApiEvaluationGroupAccessToEvaluationGroupAccessList(
      data.EvaluationGroupAccess,
    );
  }, [loading, error, data]);

  return {
    loading,
    error,
    data: evaluationGroupAccess,
  };
};
