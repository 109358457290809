import React, { ChangeEvent, useEffect, useState } from 'react';
import moment from 'moment';

import { Select } from 'core/cells/select';
import { useTranslations } from 'core/dna/translations';
import { TextValue } from 'core/atoms/types';
import { PersonT2_DateInputFilter } from 'core/dna/types/generated/_globalTypes';
import { FormControl } from 'core/cells/form-control';
import { FormLabel } from 'core/cells/form-label';

import { useTerm } from 'modules/planner/metabolism/terms';

interface QuickPeriodSelectorProps {
  period?: PersonT2_DateInputFilter;
  onChange: (input: PersonT2_DateInputFilter) => void;
}

export const QuickPeriodSelector = ({
  onChange,
  period,
}: QuickPeriodSelectorProps) => {
  const { texts } = useTranslations();
  const { term, termId } = useTerm();

  const timePeriods: TextValue[] = [
    {
      text: texts.oasCommon.timeDuration.yestreday,
      value: 1,
    },
    {
      text: texts.oasCommon.timeDuration.lastSevenDays,
      value: 7,
    },
    {
      text: texts.oasCommon.timeDuration.lastThirtyDays,
      value: 30,
    },
    {
      text: texts.planner.common.term,
      value: 'term',
    },
  ];
  const defaultPeriod = timePeriods[timePeriods.length - 1];

  const [value, setValue] = useState(defaultPeriod.value);

  // for reset state if the term was changed
  useEffect(() => {
    setValue(defaultPeriod.value);
  }, [defaultPeriod.value, termId]);

  const handleChange = (val: ChangeEvent<{ value: string | number }>) => {
    setValue(val);

    if (typeof val === 'number') {
      onChange({
        from: moment().subtract(val, 'days').startOf('day').utc().toISOString(),
        to: moment().utc().toISOString(),
      });
      return;
    }

    onChange({
      from: moment(term?.startDate).startOf('day').utc().toISOString(),
      to: moment(term?.endDate).endOf('day').utc().toISOString(),
    });
  };

  return (
    <FormControl component="fieldset" variant="filled">
      <FormLabel component="legend">
        {texts.reports.reportsCommon.quickChoices}
      </FormLabel>
      <Select
        name="period-selector"
        label={texts.oasCommon.duration}
        data={timePeriods}
        value={value}
        minWidth={180}
        onChange={handleChange}
      />
    </FormControl>
  );
};
