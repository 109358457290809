import { useOrganizationCalendar } from 'modules/planner/metabolism/use-organization-calendar';
import { useGetStudents } from 'modules/student/memory/apollo/students/remote/get-students';
import { mapStudentListToWeeklyEventsList } from 'modules/student/dna/functions';

export const useWeeklyEvents = () => {
  const { data } = useGetStudents();
  const { startTime, endTime } = useOrganizationCalendar();

  return {
    startTime,
    endTime,
    events: !data ? [] : mapStudentListToWeeklyEventsList(data),
  };
};
