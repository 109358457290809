import clsx from 'clsx';
import { useMemo } from 'react';

import { makeStyles, createStyles, Theme } from 'core/atoms/styles';
import { DraggableTypes, TextItem, DraggableItem } from 'core/atoms/types';
import { orderBy } from 'core/atoms/functions/array';

import { FilteredChips } from 'core/cells/filtered-chips';

import { useLocale } from 'core/dna/routing';
import { useTranslations } from 'core/dna/translations';

import { useGetRooms } from 'modules/planner/memory/apollo/rooms/remote';

import PlannerUrls from 'modules/planner/routing/urls';

import { NoBlockData } from '../no-block-data';

export interface RoomsBlockProps {
  className?: string;
  onDoubleClick?: (item: DraggableItem) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginBottom: theme.spacing(2),
    },
  }),
);

export const RoomsBlock = (props: RoomsBlockProps) => {
  const classes = useStyles();

  const { texts } = useTranslations();
  const locale = useLocale();

  const { rooms: roomsData, roomsError, roomsLoading } = useGetRooms();

  const rooms: TextItem[] = useMemo(
    () =>
      orderBy(
        roomsData.map((r) => ({
          id: r.id,
          text: r.name || DraggableTypes.Room,
        })),
        ['text'],
      ),
    [roomsData],
  );

  if (roomsError) return <p>Rooms Error!</p>;
  if (roomsLoading) return <p>Loading Rooms...</p>;

  return rooms.length ? (
    <FilteredChips
      label={texts.planner.plannerRooms.filterRooms}
      data={rooms}
      draggableType={DraggableTypes.Room}
      className={clsx(classes.root, props.className)}
      color="primary"
      onDoubleClick={props.onDoubleClick}
    />
  ) : (
    <NoBlockData
      message={texts.planner.plannerRooms.noRooms}
      link={PlannerUrls.rooms(locale)}
      linkText={texts.planner.plannerRooms.addRoom}
      className={classes.root}
    />
  );
};
