import { useMemo } from 'react';
import clsx from 'clsx';

import { makeStyles, createStyles, Theme } from 'core/atoms/styles';
import { DraggableTypes, DraggableItem, DataItem } from 'core/atoms/types';
import { orderBy } from 'core/atoms/functions/array';

import { FilteredChips } from 'core/cells/filtered-chips';

import { useLocale } from 'core/dna/routing';
import { useTranslations } from 'core/dna/translations';
import { RoleType } from 'core/dna/types/generated/_globalTypes';

import { getEmployeeTypeString } from 'modules/planner/dna/functions/get-employee-type-string';

import { useTeachers } from 'modules/planner/metabolism/use-teachers';

import PlannerUrls from 'modules/planner/routing/urls';

import { NoBlockData } from '../no-block-data';

export interface TeachersBlockProps {
  className?: string;
  onDoubleClick?: (item: DraggableItem) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginBottom: theme.spacing(2),
    },
  }),
);

export const TeachersBlock = (props: TeachersBlockProps) => {
  const classes = useStyles();
  const { texts } = useTranslations();
  const locale = useLocale();

  const {
    teachers: teachersData,
    teachersError,
    teachersLoading,
  } = useTeachers();

  const teachers: DataItem<string>[] | undefined = useMemo(
    () =>
      orderBy(
        teachersData?.map((t) => ({
          id: t.id,
          text: (t.person || {}).name || DraggableTypes.Teacher,
          data: getEmployeeTypeString({
            type: t.type ?? RoleType.teacher,
            texts,
          }),
        })),
        ['text'],
      ),
    [teachersData, texts],
  );

  if (teachersError) return <p>Teachers Error!</p>;
  if (teachersLoading) return <p>Loading Teachers...</p>;

  return teachers?.length ? (
    <FilteredChips
      withAvatars
      label={texts.planner.plannerTeachers.filterTeachers}
      data={teachers}
      draggableType={DraggableTypes.Teacher}
      color="secondary"
      className={clsx(classes.root, props.className)}
      onDoubleClick={props.onDoubleClick}
    />
  ) : (
    <NoBlockData
      message={texts.planner.plannerTeachers.noTeachers}
      link={PlannerUrls.teachers(locale)}
      linkText={texts.planner.plannerTeachers.addTeacher}
      className={classes.root}
    />
  );
};
