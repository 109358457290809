import { ReactNode } from 'react';

import { useTranslations } from 'core/dna/translations';

import { useClasses } from 'modules/planner/metabolism/use-classes';
import { useSelectedClass } from 'modules/planner/metabolism/use-selected-class';

import { ClassesTabs } from '../classes-tabs';

interface ClassesTabbedPageProps {
  tabContent: () => ReactNode;
}

export const ClassesTabbedPage = (props: ClassesTabbedPageProps) => {
  const { tabContent } = props;

  const { texts } = useTranslations();
  const {
    selectedClasses,
    classesLoading,
    classesError,
    classData,
  } = useClasses();

  const selectedClassesFullData = classData
    .filter((c) => selectedClasses.includes(c.id))
    .sort((a, b) => (a.name > b.name ? 1 : -1));

  const { selectedClass } = useSelectedClass();

  if (classesLoading) return <p>Loading Classes...</p>;
  if (classesError) return <p>Classes Error!</p>;

  if (selectedClasses.length === 0) {
    return (
      <p>
        {texts.planner.sourceDataSpace.classesModule.selectClassNotification}
      </p>
    );
  }

  if (!selectedClass) return <p>Class is not found!</p>;

  if (selectedClasses.length === 1) {
    return <>{tabContent()}</>;
  }
  if (selectedClasses.length > 1 && selectedClasses.length <= 8) {
    return (
      <ClassesTabs
        classesList={selectedClassesFullData}
        tabContent={tabContent}
      />
    );
  }
  if (selectedClasses.length > 8) {
    return (
      <p>
        {texts.planner.sourceDataSpace.classesModule.tooManyClassesSelected}
      </p>
    );
  }

  return <p>Something wrong with selected classes!</p>;
};
