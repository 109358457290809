import XLSX from 'xlsx';
import { Column } from 'react-table';

export const useReactTableExport = <T extends Record<string, any>>(
  columns: Column<T>[],
  data: T[] | undefined,
) => {
  const mapColumnHeaders = () => {
    const headers: string[] = [];
    columns.forEach((col) => {
      headers.push(col.Header as string);
    });
    return headers;
  };

  const findValueByKey = (tableRow: T, searchableKey: keyof T) => {
    // eslint-disable-next-line no-restricted-syntax
    for (const [key, value] of Object.entries(tableRow)) {
      if (key === searchableKey) {
        return value;
      }
    }
  };

  const mapRowColumns = (tableRow: T) => {
    const cols: unknown[] = [];

    columns.forEach((col) => {
      const val = findValueByKey(tableRow, col.accessor as keyof T);
      if (val !== undefined) {
        cols.push(val);
      }
    });

    return cols;
  };

  const getRows = () => {
    const rows: any[] = [];

    rows.push(mapColumnHeaders());

    data?.forEach((tableRow) => {
      rows.push(mapRowColumns(tableRow));
    });

    return rows;
  };

  const exportFile = (fileName: string) => {
    const data = getRows();
    const ws = XLSX.utils.aoa_to_sheet(data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet 1');
    XLSX.writeFile(wb, `${fileName}.xlsx`);
  };

  return {
    exportToExcel: exportFile,
  };
};
