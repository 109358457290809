import { useCallback, useMemo } from 'react';

import { OasError, OasOrganismError } from 'core/atoms/errors';

import { ModuleModeEnum } from 'core/dna/types/modules';
import { useSelectedOrganizationState } from 'core/dna/organizations';
import {
  useModulesState,
  useSelectedModuleState,
  useSelectedModuleUpdater,
} from 'core/dna/modules';
import { useModuleGoHome } from 'core/dna/routing/module-routes';
import { useTranslations } from 'core/dna/translations';
import { ModuleSelector } from 'core/dna/translations/types';

export const useModuleSelector = () => {
  const selectedOrgState = useSelectedOrganizationState();
  const selectedModule = useSelectedModuleState();
  const selectModule = useSelectedModuleUpdater();
  const modules = useModulesState();
  const { texts } = useTranslations();

  const goHome = useModuleGoHome();

  const onChange = useCallback(
    (newValue: string) => {
      try {
        selectModule(newValue);
        goHome(newValue, selectedOrgState?.level);
      } catch (e: any) {
        if (e instanceof OasError) {
          throw e;
        } else {
          throw OasOrganismError.fromError(e, { title: 'UseModuleSelector' });
        }
      }
    },
    [goHome, selectModule, selectedOrgState?.level],
  );

  const data = useMemo(() => {
    const result = modules
      .filter(
        (m) =>
          m.mode === ModuleModeEnum.active || m.mode === ModuleModeEnum.demo,
      )
      .map((ms) => {
        const m = {
          text:
            ms.text ??
            texts.oasCommon.moduleSelector[ms.id as keyof ModuleSelector],
          value: ms.id,
          data: { accessible: !ms.disabled ?? true },
        };
        return m;
      });
    return result;
  }, [modules, texts]);

  return {
    data,
    value: selectedModule?.id ?? '',
    onChange,
  };
};
