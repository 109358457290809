import { useMemo } from 'react';

import { AbsenceState } from 'core/dna/types/generated/_globalTypes';
import { Absence } from 'modules/student/dna/types/absence';
import { Card, CardBody } from 'templates/mdp/components/card';
import { useTranslations } from 'core/dna/translations';
import { ColDef, DataGrid } from 'core/cells/data-grid';
import { makeStyles, createStyles } from 'core/atoms/styles';

import {
  absenceCalculateTotalDays,
  absenceCalculateTotalHours,
} from 'modules/student/dna/functions';
import {
  calculateTotalDaysByAbsenceState,
  calculateTotalHoursByAbsenceState,
} from 'modules/reports/dna/functions';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      border: 'none',
      '& .MuiDataGrid-columnSeparator': {
        display: 'none',
        color: 'transparent !important',
      },
    },
  }),
);

interface AbsenceTotalByProps {
  data: Absence[];
}

export const AbsenceTotalByCategory = (props: AbsenceTotalByProps) => {
  const { data } = props;
  const { texts } = useTranslations();

  const classes = useStyles();

  const columns: ColDef[] = useMemo(
    () => [
      {
        headerName: texts.oasCommon.category.one,
        field: 'category',
        flex: 3,
      },
      {
        headerName: texts.planner.common.days.many,
        field: 'days',
        flex: 2,
        headerAlign: 'center',
        align: 'center',
      },
      {
        headerName: texts.planner.common.hours.many,
        field: 'hours',
        flex: 2,
        headerAlign: 'center',
        align: 'center',
      },
    ],
    [texts],
  );

  const rows = useMemo(
    () => [
      {
        id: 0,
        category: texts.studentModule.absence.stateValues![
          AbsenceState.medical
        ],
        days: calculateTotalDaysByAbsenceState(data, AbsenceState.medical),
        hours: calculateTotalHoursByAbsenceState(data, AbsenceState.medical),
      },
      {
        id: 1,
        category: texts.studentModule.absence.stateValues![AbsenceState.leave],
        days: calculateTotalDaysByAbsenceState(data, AbsenceState.leave),
        hours: calculateTotalHoursByAbsenceState(data, AbsenceState.leave),
      },
      {
        id: 2,
        category: texts.studentModule.absence.stateValues![
          AbsenceState.undocumented
        ],
        days: calculateTotalDaysByAbsenceState(data, AbsenceState.undocumented),
        hours: calculateTotalHoursByAbsenceState(
          data,
          AbsenceState.undocumented,
        ),
      },
      {
        id: 3,
        category: texts.studentModule.absence.stateValues![AbsenceState.open],
        days: calculateTotalDaysByAbsenceState(data, AbsenceState.open),
        hours: calculateTotalHoursByAbsenceState(data, AbsenceState.open),
      },
      {
        id: 4,
        category: texts.oasCommon.total,
        days: absenceCalculateTotalDays(data),
        hours: absenceCalculateTotalHours(data),
      },
    ],
    [texts, data],
  );

  return (
    <Card>
      <CardBody>
        <div style={{ height: 300 }}>
          <DataGrid
            hideFooter
            autoHeight
            // density="compact"
            columns={columns}
            rows={rows}
            disableColumnMenu
            className={classes.root}
          />
        </div>
      </CardBody>
    </Card>
  );
};
