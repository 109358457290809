import { useCallback, useMemo, useState } from 'react';
import MeetingRoomIcon from '@mui/icons-material/MeetingRoom';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';

import { uniq } from 'core/atoms/functions/array';

import { Skeleton } from 'core/cells/skeleton';

import { useTranslations } from 'core/dna/translations';

import { useGetTermsState } from 'core/memory/apollo/terms/local';
import { createReactTableStore } from 'core/memory/pure/react-table-store';

import { useReactTableState } from 'core/metabolism/table-state';

import {
  Card,
  CardBody,
  CardHeader,
  CardIcon,
} from 'templates/mdp/components/card';
import { GridContainer, GridItem } from 'templates/mdp/components/grid';
import { Button } from 'templates/mdp/components/custom-buttons';
import { Table } from 'templates/mdp/components/react-table';

import { emptyRoomItem } from 'modules/planner/dna/types/room';

import { ColAvailability } from './components/col-availability';
import { RoomEditor } from './room-editor';
import { useRoomsTableRead } from './use-rooms-table-read';
import { useRoomsTableWrite } from './use-rooms-table-write';

export const roomsTableStore = createReactTableStore({}, {});

export const RoomsTable = () => {
  const { texts } = useTranslations();
  const { data: termsState } = useGetTermsState();

  const { columns, data, loading } = useRoomsTableRead();
  const { onRowDelete } = useRoomsTableWrite();

  const {
    initialPage,
    pageSize,
    sort,
    filters,
    setSort,
    setFilters,
    setPage,
    setPageSize,
  } = useReactTableState(roomsTableStore, termsState?.selected ?? undefined);

  const [showModal, setShowModal] = useState(false);

  const [selectedRoomTableItem, setSelectedRoomTableItem] = useState(
    emptyRoomItem,
  );

  const handleAdd = () => {
    setSelectedRoomTableItem(emptyRoomItem);
    setShowModal(true);
  };

  const handleUpdate = useCallback(
    async (roomTableItemId: string) => {
      const room = data.find((room) => room.id === roomTableItemId);
      setSelectedRoomTableItem(room!);
      setShowModal(true);
    },
    [data],
  );

  const handleDelete = useCallback(
    async (classId: string) => {
      const tableItem = data.find((o) => o.id === classId);
      await onRowDelete(tableItem!);
    },
    [data, onRowDelete],
  );

  const names = useMemo(() => uniq(data.map((item) => item.name)), [data]);

  const preparedData = useMemo<any[]>(() => {
    return data.map((room) => {
      return {
        ...room,
        availabilityDefined: <ColAvailability item={room} />,
        actions: (
          <div className="actions-right">
            <Button
              justIcon
              round
              simple
              onClick={() => handleUpdate(room.id)}
              color="info"
              className="edit"
            >
              <EditIcon />
            </Button>{' '}
            <Button
              justIcon
              round
              simple
              onClick={() => handleDelete(room.id)}
              color="danger"
              className="remove"
            >
              <CloseIcon />
            </Button>{' '}
          </div>
        ),
      };
    });
  }, [data, handleDelete, handleUpdate]);

  return loading || !termsState?.selected ? (
    <Skeleton variant="rect" width="100%" height="80vh" />
  ) : (
    <GridContainer>
      <GridItem xs={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button color="secondary" onClick={handleAdd}>
          {texts.planner.plannerRooms.addRoom}
        </Button>
      </GridItem>
      <GridItem xs={12}>
        <Card>
          <CardHeader color="primary" icon>
            <CardIcon color="primary">
              <MeetingRoomIcon />
            </CardIcon>
            {/* <h4 className={classes.cardIconTitle}>{title}</h4> */}
          </CardHeader>
          <CardBody>
            <Table
              columns={columns}
              data={preparedData}
              key={termsState?.selected ?? 'unknown_term'}
              onChangeRowsPerPage={setPageSize}
              onChangePage={setPage}
              onFilterChange={setFilters}
              onOrderChange={setSort}
              options={{
                initialPage,
                pageSize,
                sort,
                filters,
              }}
            />
          </CardBody>
        </Card>
      </GridItem>
      <RoomEditor
        id={selectedRoomTableItem.id}
        defaultName={selectedRoomTableItem.name ?? ''}
        defaultAlias={selectedRoomTableItem.alias ?? ''}
        defaultNote={selectedRoomTableItem.note}
        open={showModal}
        setOpen={setShowModal}
        names={names}
      />
    </GridContainer>
  );
};
