import { Box } from 'core/cells/box';

import { WeekWorkingHours } from './components/week-working-hours';

export const Organization = () => {
  return (
    <Box centeredContent>
      <WeekWorkingHours />
    </Box>
  );
};
