/* eslint-disable */
import React, { ReactNode } from 'react';
import clsx from 'clsx';

import { useStyles } from './card-text.styles';

type CardTextColor =
  | 'warning'
  | 'success'
  | 'danger'
  | 'info'
  | 'primary'
  | 'rose';

export interface CardTextProps {
  className?: string; // PropTypes.string,
  color?: CardTextColor; // PropTypes.oneOf([]),
  children?: ReactNode; // PropTypes.node,
}

export const CardText = (props: CardTextProps) => {
  const classes = useStyles();
  const { className, children, color, ...rest } = props;

  const cardTextClasses = clsx(
    classes.cardText,
    color ? (classes as any)[color + 'CardHeader'] : '',
    className !== undefined ? className : '',
  );

  return (
    <div className={cardTextClasses} {...rest}>
      {children}
    </div>
  );
};
