import React from 'react';

import { NoData } from 'core/organisms/no-data';

import { AbsenceEntity } from 'modules/reports/dna/types';
import { ShortDataWithAbsences } from 'modules/student/dna/types';

import { StudentsReport } from './student/students-report';
import { GroupsReport } from './group/groups-report';

interface ReportManagerProps {
  data: ShortDataWithAbsences[] | undefined;
  loading: boolean;
  entity: AbsenceEntity;
}

export const ReportManager = ({
  data,
  loading,
  entity,
}: ReportManagerProps) => {
  if ((!data || !data.length) && !loading) {
    return <NoData />;
  }

  switch (entity) {
    case AbsenceEntity.student:
      return <StudentsReport data={data} loading={loading} />;
    case AbsenceEntity.class:
      return <GroupsReport data={data} loading={loading} />;
    default:
      return <StudentsReport data={data} loading={loading} />;
  }
};
