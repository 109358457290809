import { makeStyles, createStyles, CSSProperties } from 'core/atoms/styles';

import { grayColor } from '../../material-dashboard-pro-react';
import { buttonGroupStyle, ButtonGroupStyle } from '../../button-group-style';

export interface ButtonsStyle extends ButtonGroupStyle {
  cardTitle: CSSProperties;
  cardHeader: CSSProperties;
  cardContentLeft: CSSProperties;
  cardContentRight: CSSProperties;
  cardContentBottom: CSSProperties;
  marginRight: CSSProperties;
  icons: CSSProperties;
  socialButtonsIcons: CSSProperties;
}

export const buttonsStyle: ButtonsStyle = {
  cardTitle: {
    marginTop: 0,
    marginBottom: '3px',
    color: grayColor[2],
    fontSize: '18px',
  },
  cardHeader: {
    zIndex: 3,
  },
  cardContentLeft: {
    padding: '15px 20px 15px 0px',
    position: 'relative',
  },
  cardContentRight: {
    padding: '15px 20px 15px 0px',
    position: 'relative',
  },
  cardContentBottom: {
    padding: '15px 0px 0px 0px',
    position: 'relative',
  },
  marginRight: {
    marginRight: '5px',
  },
  icons: {
    width: '17px',
    height: '17px',
  },
  ...buttonGroupStyle,
  socialButtonsIcons: {
    fontSize: '18px',
    marginTop: '-2px',
    position: 'relative',
  },
};

export const useStyles = makeStyles(() => createStyles({ ...buttonsStyle }));
