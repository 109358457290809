import { useCallback } from 'react';

import { OasMemoryError, OasError } from 'core/atoms/errors';

import { gql, useMutation } from 'core/dna/types/apollo';
import { CreateSubstituteJobInvites } from 'core/dna/types/generated/_globalTypes';
import {
  CreateSubstituteInvites,
  CreateSubstituteInvitesVariables,
} from 'core/dna/types/generated/CreateSubstituteInvites';

import { GET_SUBSTITUTE_JOBS_QUERY } from 'modules/substitute/memory/apollo/substitute-jobs/remote';

export const CREATE_SUBSTITUTE_INVITES_MUTATION = gql`
  mutation CreateSubstituteInvites($input: CreateSubstituteJobInvites!) {
    createSubstituteJobInvites(input: $input) {
      id
      invitations {
        inviteId
        teacher {
          firstName
          lastName
        }
      }
    }
  }
`;

const TITLE = 'use-create-substitute-invites';

export const useCreateSubstituteInvites = () => {
  const [createSubstituteInvites] = useMutation<
    CreateSubstituteInvites,
    CreateSubstituteInvitesVariables
  >(CREATE_SUBSTITUTE_INVITES_MUTATION);

  const func = useCallback(
    async (input: CreateSubstituteJobInvites) => {
      try {
        const result = await createSubstituteInvites({
          variables: {
            input,
          },
          refetchQueries: [
            {
              query: GET_SUBSTITUTE_JOBS_QUERY,
            },
          ],
        });

        if (result.errors) {
          throw new OasMemoryError(JSON.stringify(result.errors), {
            title: TITLE,
          });
        }
        return result.data?.createSubstituteJobInvites ?? undefined;
      } catch (e) {
        if (e instanceof OasError) {
          throw e;
        } else {
          throw OasMemoryError.fromError(e, {
            title: TITLE,
          });
        }
      }
    },
    [createSubstituteInvites],
  );

  return {
    createSubstituteInvites: func,
  };
};
