import { useCallback } from 'react';

import { OasMemoryError, OasError } from 'core/atoms/errors';

import { gql, useMutation } from 'core/dna/types/apollo';
import { ClassRole } from 'core/dna/types/generated/_globalTypes';
import {
  UpdateClassMembers,
  UpdateClassMembersVariables,
} from 'core/dna/types/generated/UpdateClassMembers';
import { mapNullableListToList } from 'core/dna/functions';

import { useLazyGetTermsState } from 'core/memory/apollo/terms/local';

import { GET_CLASSES_QUERY } from './_gql';

export const UPDATE_CLASS_MEMBERS_MUTATION = gql`
  mutation UpdateClassMembers($input: UpdateClassMembersInput!) {
    updateClassMembers(input: $input) {
      id
      title
    }
  }
`;

const FILE_NAME = 'use-update-class-members';

export const useUpdateClassMembers = () => {
  const { getTermState } = useLazyGetTermsState();

  const [update, { loading, error }] = useMutation<
    UpdateClassMembers,
    UpdateClassMembersVariables
  >(UPDATE_CLASS_MEMBERS_MUTATION);

  const func = useCallback(
    async (input: {
      classId: string;
      members?: { roleId: string; classRole: ClassRole }[];
    }) => {
      try {
        const termsState = await getTermState(true);

        const result = await update({
          variables: { input },
          refetchQueries: [
            {
              query: GET_CLASSES_QUERY,
              variables: { termId: termsState!.selected },
            },
          ],
        });

        if (result.errors) {
          throw new OasMemoryError(JSON.stringify(result.errors), {
            title: FILE_NAME,
          });
        }
        return mapNullableListToList(
          result.data?.updateClassMembers ?? null,
          (item) =>
            item ? { roleId: item.id, roleTitle: item.title ?? '' } : undefined,
        );
      } catch (e: any) {
        if (e instanceof OasError) {
          throw e;
        } else {
          throw OasMemoryError.fromError(e, {
            title: FILE_NAME,
          });
        }
      }
    },
    [getTermState, update],
  );

  return {
    updateClassMembers: func,
    loading,
    error,
  };
};
