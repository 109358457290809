import { BehaviorSubject } from 'rxjs';

import { logger, LogType } from 'core/atoms/housekeeping';

import { JobTableItem } from 'modules/substitute/dna/types/job';

const initialState: { selected: JobTableItem | null } = {
  selected: null,
};

const subject = new BehaviorSubject(initialState);

let state = initialState;

export const jobsStore = {
  subscribe: (setState: any) => subject.subscribe(setState),
  select: (item: JobTableItem | null) => {
    if (item?.id !== state.selected?.id) {
      state = {
        ...state,
        selected: item,
      };
      subject.next(state);
      logger.debug({
        title: 'select',
        logger: 'jobs-store',
        type: LogType.Accent,
        value: `New ID was sent: "${item?.id}"`,
      });
    }
  },
  initialState,
};
