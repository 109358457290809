import { gql } from 'core/dna/types/apollo';

import { ROLE_DATA_FRAGMENT } from 'modules/planner/memory/apollo/_fragments';

export const GET_ROLE_QUERY = gql`
  query GetRole($id: String!) {
    Role(id: $id) {
      ...RoleData
    }
  }
  ${ROLE_DATA_FRAGMENT}
`;
