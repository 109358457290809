import { useContext } from 'react';

import { SelectedModuleStateContext } from './selected-module-state-context';

export const useSelectedModuleState = () => {
  const selectedModuleState = useContext(SelectedModuleStateContext);
  if (typeof selectedModuleState === 'undefined') {
    throw new Error(
      'useSelectedModuleState must be used within a ModulesProvider',
    );
  }
  return selectedModuleState;
};
