import MuiOutlinedInput, {
  OutlinedInputProps as MuiOutlinedInputProps,
} from '@material-ui/core/OutlinedInput';

import {
  makeStyles,
  createStyles,
  Theme,
  FormPalette,
  getFormPalette,
} from 'core/atoms/styles';

import { focused, disabled, error } from 'core/atoms/styles/global-css';

export interface OutlinedInputProps extends MuiOutlinedInputProps {
  palette?: FormPalette;
}

const defaultProps = {};

const notchedOutline = '.MuiOutlinedInput-notchedOutline';
const input = '.MuiOutlinedInput-input';

const useStyles = (props: OutlinedInputProps) =>
  makeStyles((theme: Theme) => {
    const palette = getFormPalette(theme, props.palette || 'default');

    return createStyles({
      root: {
        '&:hover ': {
          [`& ${notchedOutline}`]: {
            borderColor: palette.layout.hover, // || palette.layout.normal,
          },
          [`& ${input}`]: {
            color: palette.text.hover, // || palette.text.normal,
          },
        },
        // Reset on touch devices, it doesn't add specificity
        '@media (hover: none)': {
          [`&:hover ${notchedOutline}`]: {
            borderColor: palette.layout.normal,
          },
        },
        [`&${focused} ${notchedOutline}`]: {
          borderColor: palette.layout.focused, // || palette.layout.normal,
        },
        [`&${error} ${notchedOutline}`]: {
          borderColor: palette.layout.error, // || theme-light.palette.error.main,
        },
        [`&${disabled} ${notchedOutline}`]: {
          borderColor: palette.layout.disabled, // || theme-light.palette.action.disabled,
        },
      },
      input: {
        color: palette.text.normal,
      },
      notchedOutline: {
        borderColor: palette.layout.normal,
      },
    });
  });

export const OutlinedInput = (props: OutlinedInputProps) => {
  const classes = useStyles(props)();

  const { classes: extClasses, ...other } = props;

  return (
    <MuiOutlinedInput classes={{ ...classes, ...extClasses }} {...other} />
  );
};

OutlinedInput.defaultProps = defaultProps;
