import moment from 'moment';
import { useMemo } from 'react';

import { Term, TermTense } from 'core/dna/types/term';

import { useTerms } from './use-terms';

export const useTerm = () => {
  const { loading, error, terms, selectedTermId } = useTerms();

  const term = useMemo<Term | undefined>(() => {
    if (terms && selectedTermId) {
      return terms.find((t: any) => t.id === selectedTermId);
    }
  }, [terms, selectedTermId]);

  const termStart = useMemo(() => moment(term?.startDate), [term?.startDate]);
  const termEnd = useMemo(() => moment(term?.endDate), [term?.endDate]);

  const tenseIdentificator = (term: Term | undefined) => {
    if (!term?.startDate || !term?.endDate) {
      return;
    }

    const date = new Date();
    const now = date.getTime();

    const startDate = term.startDate.getTime();
    const endDate = term.endDate.getTime();

    if (now > startDate && now < endDate) {
      return TermTense.current;
    }

    if (now > endDate) {
      return TermTense.past;
    }

    if (now < startDate && now < endDate) {
      return TermTense.future;
    }
  };

  return {
    loading,
    error,
    term,
    termId: selectedTermId,
    termStart,
    termEnd,
    tense: tenseIdentificator(term),
  };
};
