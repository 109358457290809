import { useMemo, useEffect } from 'react';

import { logger, LogType } from 'core/atoms/housekeeping';

import { useTranslations } from 'core/dna/translations';

import { StudentBasicData } from 'modules/student/dna/types';
import { useGetStudents } from 'modules/student/memory/apollo/students/remote/get-students';

type OmitedShortData = Omit<StudentBasicData, 'configuration'>;
interface AllergiesTableItem extends OmitedShortData {
  configuration: string[] | string;
}

export const useAllergiesTableRead = () => {
  const { texts } = useTranslations();
  const { data, loading } = useGetStudents();

  useEffect(() => {
    logger.debug({
      title: 'did mount',
      type: LogType.Info,
      logger: 'use-allergies-table-read',
    });
  }, []);

  useEffect(() => {
    logger.debug({
      title: 'on data',
      type: LogType.Info,
      logger: 'use-allergies-table-read',
      value: data?.length ? data : data?.length === 0 ? 'NO DATA' : 'UNDEFINED',
    });
  }, [data]);

  const columns = useMemo(
    () => [
      {
        Header: texts.planner.common.firstName,
        accessor: 'details.firstName',
      },
      {
        Header: texts.planner.common.lastName,
        accessor: 'details.lastName',
      },
      {
        Header: texts.studentModule.allergies,
        accessor: 'configuration',
      },
    ],
    [texts],
  );

  const tableData = useMemo<AllergiesTableItem[]>(() => {
    if (!data) {
      return [];
    }

    const withAllergies = data.filter(
      (s) => s.configuration?.allergies?.length,
    );

    return withAllergies.map((student) => {
      return {
        ...student,
        configuration: !student.configuration?.allergies?.length
          ? ''
          : student.configuration.allergies.map((a) => `${a}, `),
      };
    });
  }, [data]);

  return {
    title: texts.studentModule.allergiesList,
    columns,
    data: tableData,
    loading,
  };
};
