import { gql } from 'core/dna/types/apollo';

export const TERMS_STATE_DATA_FRAGMENT = gql`
  fragment TermsStateData on TermsState {
    selected @client
  }
`;

export const TERMS_SELECTED_STATE_DATA_FRAGMENT = gql`
  fragment TermsSelectedStateData on TermsState {
    selected @client
  }
`;
