import { useMemo, useCallback, useEffect, useState } from 'react';

import { orderBy } from 'core/atoms/functions/array';

import { Resource, EventInput } from 'core/cells/full-calendar';

import { AvailabilityPreferencesEntityType } from 'core/dna/types';
import { UpdateAvailabilityPreferencesForEntityInput } from 'core/dna/types/generated/_globalTypes';
import { CalendarEvent } from 'core/dna/types/calendar-event';

import { useRemovableEvent } from 'core/organisms/week-calendar/components';

import {
  mapResourceListToAvailabilityPreferenceList,
  mapAvailabilityPreferenceListToEventInputList,
  mapCalendarEventListToApiAvailabilityPreferenceList,
} from 'modules/planner/dna/functions';

import { useClasses } from 'modules/planner/metabolism/use-classes';
import { useOrganizationCalendar } from 'modules/planner/metabolism/use-organization-calendar';

export const useClassesAvailability = () => {
  const { startTime, endTime, getBackgroundEvents } = useOrganizationCalendar();

  const {
    classData,
    selectedClasses: selectedClassIds,
    updateAvailabilityPreferencesForClasses,
    classesLoading,
    classesError,
  } = useClasses();

  const [events, setEvents] = useState<EventInput[]>([] as EventInput[]);
  const [dirty, setDirty] = useState(false);
  const [saving, setSaving] = useState(false);

  const selectedClasses = useMemo(
    () => classData.filter((c) => selectedClassIds.indexOf(c.id) >= 0),
    [classData, selectedClassIds],
  );

  const selectedClassesCount = selectedClasses?.length;

  const resources = useMemo<Resource[]>(() => {
    const result = selectedClasses.map((c) => ({
      id: c.id,
      title: c.name || 'noname',
    }));

    return result && result.length
      ? orderBy(result, ['title'])
      : [{ id: '', title: '' }];
  }, [selectedClasses]);

  useEffect(() => {
    if (!classesLoading && !classesError && selectedClassesCount) {
      const newEvents = mapAvailabilityPreferenceListToEventInputList(
        mapResourceListToAvailabilityPreferenceList(selectedClasses),
        'Break',
      );
      setEvents(newEvents);
    }
  }, [classesError, classesLoading, selectedClasses, selectedClassesCount]);

  const updateEvents = useCallback((newEvents: EventInput[]) => {
    setDirty(true);
    setEvents(newEvents);
  }, []);

  const addPreference = useCallback(
    (event: EventInput) => {
      if (!events) {
        throw new Error("It's not possible to add new event undefined!");
      }
      updateEvents([...events, event]);
    },
    [events, updateEvents],
  );

  const editPreference = useCallback(
    (event: EventInput) => {
      if (!events) {
        throw new Error("It's not possible to edit not existing events!");
      }
      const existingEvent = events.find((e) => e.id === event.id);
      if (!existingEvent) {
        throw new Error('Event to edit have to match data');
      }

      existingEvent.start = event.start;
      existingEvent.end = event.end;

      updateEvents([...events]);
    },
    [events, updateEvents],
  );

  const deletePreference = useCallback(
    (eventId: string) => {
      if (!events) {
        throw new Error("It's not possible to edit not existing events!");
      }
      const updatedEvents = events.filter((e) => e.id !== eventId);
      if (updatedEvents.length === events.length) {
        throw new Error('Item to delete is not found in the data source!');
      }
      updateEvents(updatedEvents);
    },
    [events, updateEvents],
  );

  const { eventContent } = useRemovableEvent({ onDelete: deletePreference });

  const save = useCallback(() => {
    setSaving(true);
    const entityType: AvailabilityPreferencesEntityType = 'class';
    const classPreferences = resources.map((r) => {
      return {
        entityId: r.id,
        entityType,
        availabilityPreferences: mapCalendarEventListToApiAvailabilityPreferenceList(
          events as CalendarEvent[],
          r.id,
          -2,
        ),
      } as UpdateAvailabilityPreferencesForEntityInput;
    });
    updateAvailabilityPreferencesForClasses({
      preferences: classPreferences,
    }).then(() => {
      setDirty(false);
      setSaving(false);
    });
  }, [events, resources, updateAvailabilityPreferencesForClasses]);

  return {
    startTime,
    endTime,
    resources,
    bgEvents: getBackgroundEvents(resources?.map((r) => r.id)),
    events,
    add: addPreference,
    edit: editPreference,
    save,
    dirty,
    saving,
    loading: classesLoading,
    error: classesError,
    eventContent,
  };
};
