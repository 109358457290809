import { OasLanguage, URLS } from 'core/dna/routing';

import { PATHS } from './paths';

const { getOasUrlWithoutParams, getOasUrl } = URLS;

const getJobsUrl = (locale?: OasLanguage) => {
  return getOasUrlWithoutParams({
    pathname: PATHS.Jobs.path,
    locale,
  });
};

const getJobDetailsUrl = (input: { jobId: string; locale?: OasLanguage }) => {
  return getOasUrl({
    pathname: PATHS.Jobs.Details.path,
    params: [
      {
        key: PATHS.Jobs.Details.params.jobId.pathKey,
        value: input.jobId,
      },
    ],
    locale: input.locale,
  });
};

const getEditJobUrl = (input: { jobId: string; locale?: OasLanguage }) => {
  return getOasUrl({
    pathname: PATHS.Jobs.Edit.path,
    params: [
      {
        key: PATHS.Jobs.Edit.params.jobId.pathKey,
        value: input.jobId,
      },
    ],
    locale: input.locale,
  });
};

export default {
  home: getJobsUrl,
  jobs: getJobsUrl,
  jobDetails: getJobDetailsUrl,
  editJob: getEditJobUrl,
};
