import { RoleType } from 'core/dna/types/generated/_globalTypes';
import { OasLocales } from 'core/dna/translations/types';

export const getEmployeeTypeString = (input: {
  type: RoleType;
  texts: OasLocales;
}) => {
  const { type, texts } = input;
  let typeString = '';
  switch (type) {
    case RoleType.teacher:
      typeString = texts.oasCommon.teachers.one.slice(0, 1);
      break;
    case RoleType.assistant:
      typeString = 'A';
      break;
    default:
      typeString = 'O';
      break;
  }
  return typeString;
};
