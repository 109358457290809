import { TermData } from 'core/dna/types/term';
import { AvailabilityPreference } from 'modules/planner/dna/types/availability-preference';

export interface RoomData {
  id: string;
  name: string;
  alias?: string;
  note?: string;
}

export interface Room extends RoomData {
  deleted?: boolean;
  term?: TermData;
  availabilityPreferences?: AvailabilityPreference[];
}

export interface RoomTableItem extends RoomData {
  availabilityDefined: boolean;
}

export const emptyRoomItem: Partial<RoomTableItem> = {
  name: '',
};
