import { useMemo, useCallback, Fragment } from 'react';
import clsx from 'clsx';

import { makeStyles, createStyles } from 'core/atoms/styles';
import { DraggableTypes } from 'core/atoms/types';

import { Paper } from 'core/cells/paper';
import { Box } from 'core/cells/box';
import { DraggableChip } from 'core/cells/draggable-chip';

import { Union as UnionType } from 'modules/planner/dna/types/union';
import { StudentItem } from 'modules/planner/dna/types/local';

import {
  useGetStudentsGroupsState,
  useSetStudentsGroupsState,
} from 'modules/planner/memory/apollo/classes/local/students-groups';

import { GridContainer, GridItem } from 'templates/mdp/components/grid';

import { Chapter } from './chapter';
import { ChapterContent } from './chapter-content';

interface UnionProps {
  classId: string;
  union: UnionType;
  students: StudentItem[];
  classes?: { root?: string };
}

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      minWidth: 400,
      border: '1px solid #cacaca',
      padding: theme.spacing(2),
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: 'yellow',
    },
    flex: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    chip: {
      margin: theme.spacing(0.5),
    },
    chapter: {
      display: 'flex',
      flexGrow: 1,
      margin: theme.spacing(0, 1, 1, 0),
    },
    lastChapter: {
      margin: theme.spacing(0, 0, 1, 0),
    },
  }),
);

export const Union = (props: UnionProps) => {
  const { classId, union, students, classes: propsClasses } = props;

  const classes = useStyles();

  const { studentsGroupsState } = useGetStudentsGroupsState();
  const {
    updateStudent: updateStudentState,
    selectChapter,
  } = useSetStudentsGroupsState();

  const selectedChapter = useMemo(
    () => studentsGroupsState?.unions.byId[union.id]?.selectedChapter,
    [studentsGroupsState, union.id],
  );

  const dragDropType = useMemo(() => `${DraggableTypes.Student}-${union.id}`, [
    union,
  ]);

  const updateStudent = useCallback(
    (item: any, chapterId?: string) => {
      updateStudentState({
        classId,
        unionId: union.id,
        studentId: item.id,
        chapterId,
      });
    },
    [classId, union.id, updateStudentState],
  );

  const handleChapterSelect = (chapterId: string) => {
    selectChapter({ classId, unionId: union.id, chapterId });
  };

  return (
    <Paper className={clsx(classes.root, propsClasses?.root)}>
      <GridContainer spacing={2}>
        <GridItem flex xs={12} aria-label="chapters-area">
          {union.chapters?.map((c, index) => {
            const chStudents = students.filter((s) => s.chapter === c.id);
            return (
              <Fragment key={`chapter-${c.id}`}>
                <Chapter
                  isEmpty={!chStudents.length}
                  dropType={dragDropType}
                  title={c.name ?? String(index)}
                  classes={{
                    root: clsx(
                      classes.chapter,
                      index === (union.chapters?.length ?? 0) - 1
                        ? classes.lastChapter
                        : '',
                    ),
                  }}
                  selected={selectedChapter === c.id}
                  onSelect={() => handleChapterSelect(c.id)}
                  onDrop={(item) => updateStudent(item, c.id)}
                >
                  <ChapterContent
                    students={chStudents}
                    dragDropType={dragDropType}
                    onDelete={updateStudent}
                  />
                </Chapter>
              </Fragment>
            );
          })}
        </GridItem>
        <GridItem xs={12} className="lesson-footer">
          <Box className={classes.flex}>
            {students
              .filter((s) => !s.chapter)
              .map((item) => {
                const chipProps: any = {
                  key: item.id,
                };
                return (
                  <DraggableChip
                    {...chipProps}
                    type={dragDropType}
                    id={item.id}
                    text={item.text}
                    data={item.chapter}
                    color="default"
                    onDoubleClick={(item) =>
                      updateStudent(item, selectedChapter)
                    }
                  />
                );
              })}
          </Box>
        </GridItem>
      </GridContainer>
    </Paper>
  );
};
