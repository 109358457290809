import { useCallback } from 'react';

import { logger, LogType } from 'core/atoms/housekeeping';

import {
  getAccessTokenStorage,
  getSessionTokenStorage,
  getStatusStorage,
} from 'auth/memory/browser';
import { useReissueAccessToken } from 'auth/memory/apollo/access-token/remote';

import { useAuthContext } from './use-auth-context';
import { mapTokensToAuthIdentity } from '../dna/functions';

const FILE_NAME = 'use-update-token';

export const useUpdateToken = () => {
  const { getStatus, setStatus } = getStatusStorage();
  const { getAccessToken } = getAccessTokenStorage();
  const { getSessionToken } = getSessionTokenStorage();
  const { setTokens, logout } = useAuthContext();
  const { reissueAccessToken: reissueAT } = useReissueAccessToken();

  const func = useCallback(async () => {
    const accessToken = getAccessToken();
    const sessionToken = getSessionToken();

    if (!accessToken || !sessionToken) {
      logger.debug({
        title: 'reissueAccessToken: NO TOKEN',
        logger: FILE_NAME,
        type: LogType.Info,
        value: [
          {
            accessToken,
            sessionToken,
          },
        ],
      });
      return;
    }

    const identity = mapTokensToAuthIdentity(accessToken, sessionToken);
    if (!identity) {
      logger.debug({
        title: 'reissueAccessToken: NO IDENTITY',
        logger: FILE_NAME,
        type: LogType.Info,
        value: [
          {
            accessToken,
            sessionToken,
          },
        ],
      });
      return;
    }

    try {
      const reissueResult = await reissueAT();

      if (getStatus() !== 'identity') {
        setStatus('identity');
      }
      setTokens({
        accessToken: reissueResult.accessToken as string,
        sessionToken: sessionToken,
      });
    } catch (e: any) {
      logger.debug({
        title: 'Error to reissue access token',
        logger: FILE_NAME,
        type: LogType.Error,
        value: [
          {
            error: e,
            iat: identity.issuedAt ?? 'UNDEFINED',
            exp: identity.expiredAt ?? 'UNDEFINED',
          },
        ],
      });

      await logout?.();
    }
  }, [
    logout,
    getAccessToken,
    getSessionToken,
    getStatus,
    reissueAT,
    setStatus,
    setTokens,
  ]);

  return {
    updateToken: func,
  };
};
