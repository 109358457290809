import React, { useCallback } from 'react';

import { makeStyles, Theme, createStyles } from 'core/atoms/styles';

import { Typography } from 'core/cells/typography';
import { Box, FlexBox } from 'core/cells/box';
import { Chip } from 'core/cells/chip';

import { useTranslations } from 'core/dna/translations';

import { createReactTableStore } from 'core/memory/pure/react-table-store';
import { TeacherTableItem } from 'modules/substitute/dna/types/teacher';

import { SubstituteInvitation } from 'modules/substitute/dna/types/substitute-invitation';

import {
  useCreateSubstituteInvites,
  useDeleteSubstituteInvites,
} from 'modules/substitute/memory/apollo/substitute-jobs/remote';
import { TeachersTable } from '../teachers-table';

export interface InvitationsProps {
  substituteJobId: string;
  data?: SubstituteInvitation[];
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    addAppointmentOutline: {
      padding: theme.spacing(1),
    },
    occupied: {
      borderStyle: 'solid',
    },
    chips: {
      display: 'flex',
      justifyContent: 'flex-start',
      flexWrap: 'wrap',
      listStyle: 'none',
      padding: theme.spacing(0.5),
      margin: 0,
    },
    chip: {
      margin: theme.spacing(0.5),
    },
  }),
);

const teachersTableStore = createReactTableStore({}, {});

export const Invitations = (props: InvitationsProps) => {
  const { substituteJobId, data } = props;
  const classes = useStyles();
  const { texts } = useTranslations();

  const { createSubstituteInvites } = useCreateSubstituteInvites();
  const { deleteSubstituteInvites } = useDeleteSubstituteInvites();

  const handleSelect = useCallback(
    async (item: TeacherTableItem) => {
      if (
        !data?.length ||
        (data?.length && !data.find((inv) => inv.teacher.id === item.id))
      ) {
        await createSubstituteInvites({
          substituteJobId,
          substituteTeacherIds: [item.id],
        });
      }
    },
    [createSubstituteInvites, data, substituteJobId],
  );

  const handleDelete = useCallback(
    (itemToDelete: SubstituteInvitation) => async () => {
      await deleteSubstituteInvites({
        substituteJobId,
        substituteTeacherIds: [itemToDelete.teacher.id],
      });
    },
    [deleteSubstituteInvites, substituteJobId],
  );

  return (
    <>
      <FlexBox>
        {!data?.length ? (
          <Typography color="error">
            {texts.substitute.invintationsListDesc}
          </Typography>
        ) : (
          <Typography>{texts.oasCommon.invitations.one}</Typography>
        )}
        <Box component="ul" className={classes.chips}>
          {!!data?.length &&
            data.map((item) => {
              return (
                <li key={item.teacher.id}>
                  <Chip
                    color="primary"
                    size="small"
                    label={`${item.teacher.firstName} ${item.teacher.lastName}`}
                    onDelete={handleDelete(item)}
                    className={classes.chip}
                  />
                </li>
              );
            })}
        </Box>
      </FlexBox>
      <TeachersTable
        tableStore={teachersTableStore}
        editable={false}
        selectable
        onSelect={handleSelect}
        selected={data}
      />
    </>
  );
};
