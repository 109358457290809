import { useCallback } from 'react';

import {
  selectedScheduleStateVar,
  defaults,
} from 'modules/planner/memory/apollo/cache';

export const useSetSelectedScheduleState = () => {
  const setSelectedScheduleState = useCallback((value: string | null) => {
    try {
      if (selectedScheduleStateVar() === value) {
        return;
      }
      selectedScheduleStateVar(value ?? defaults.selectedScheduleState);
    } catch (e: any) {
      throw new Error(e);
    }
  }, []);

  return {
    setSelectedScheduleState,
  };
};
