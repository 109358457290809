import { mapStringToDate, mapNullableListToList } from 'core/dna/functions';

export const mapGetTermsTermHolidaysListToDateList = (
  apiHolidayList: (any | null)[] | null,
): Date[] | undefined => {
  return mapNullableListToList<any, Date>(
    apiHolidayList,
    mapGetTermsTermHolidaysToDate,
  );
};

export const mapGetTermsTermHolidaysToDate = (
  apiHoliday: any | null,
): Date | undefined => {
  return mapStringToDate(apiHoliday?.formatted);
};
