import {
  Schedule,
  ScheduleTableItem,
} from 'modules/planner/dna/types/schedule';

export const mapScheduleToScheduleTableItem = (
  schedule: Schedule,
): ScheduleTableItem => {
  return {
    ...schedule,
    create: '',
    edit: '',
    share: '',
  };
};
