import { useMemo } from 'react';
import { useQuery } from 'core/dna/types/apollo';
import { GetStudents } from 'core/dna/types/generated/GetStudents';
import { mapApiPersonT2BasicDataToPersonT2BasicDataList } from 'modules/student/dna/functions';
import { StudentBasicData } from 'modules/student/dna/types';
import { GET_STUDENTS_QUERY } from './gql';

export const useGetStudents = () => {
  const { data, error, loading } = useQuery<GetStudents>(GET_STUDENTS_QUERY, {
    fetchPolicy: 'cache-and-network',
  });

  const students = useMemo<StudentBasicData[] | undefined>(() => {
    if (loading || error || !data) {
      return;
    }

    return mapApiPersonT2BasicDataToPersonT2BasicDataList(data.PersonT2);
  }, [loading, error, data]);

  return {
    loading,
    error,
    data: students,
  };
};
