import { OasMappingError } from 'core/atoms/errors';

import { Term } from 'core/dna/types/term';
import { mapStringToDate, mapNullableListToList } from 'core/dna/functions';

import { mapGetTermsTermHolidaysListToDateList } from '../map-GetTerms_Term_holidays-to-Date';

export const mapApiTermListToTermList = (
  apiTermList: (any | null)[] | null,
): Term[] | undefined => {
  return mapNullableListToList<any, Term>(apiTermList, mapApiTermToTerm);
};

export const mapApiTermToTerm = (apiTerm: any | null): Term => {
  if (apiTerm === null) {
    throw new OasMappingError('apiTerm" should not be null!', {
      title: 'Map ApiTerm to Term',
    });
  }

  const { id, name, startDate, endDate, holidays, allocationPlan } = apiTerm;

  return {
    id,
    name: name ?? 'TERM',
    startDate: mapStringToDate(startDate?.formatted),
    endDate: mapStringToDate(endDate?.formatted),
    holidays: mapGetTermsTermHolidaysListToDateList(holidays),
    allocationPlan: {
      allocations: allocationPlan
        ? mapNullableListToList(allocationPlan.allocations, (item) => {
            return {
              weekNumber: item?.weekNumber ?? -1,
              published: !!item?.published,
            };
          }) ?? []
        : [],
    },
  };
};
