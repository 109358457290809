import { useEffect } from 'react';

import { logger, LogType } from 'core/atoms/housekeeping';

import { Grid } from 'core/cells/grid';

import { CountingList } from './components/counting-list';

export const CountingMainContent = () => {
  useEffect(() => {
    logger.debug({
      title: 'did mount',
      type: LogType.Info,
      logger: 'counting-main-content',
    });
  }, []);

  return (
    <Grid container>
      <Grid item xs={12}>
        <CountingList />
      </Grid>
    </Grid>
  );
};
