import { useCallback } from 'react';

import { withStyles, makeStyles, createStyles } from 'core/atoms/styles';
import { yellow, brown } from 'core/atoms/colors';

import { Tooltip } from 'core/cells/tooltip';
import { LinkButton } from 'core/cells/link-button';

import { Union } from 'modules/planner/dna/types/union';

import { Chapters } from '../chapters';

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.primary.dark,
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 200,
    fontSize: theme.typography.pxToRem(12),
    border: `1px solid ${theme.palette.primary.dark}`,
  },
  arrow: {
    color: theme.palette.primary.dark,
  },
}))(Tooltip);

export interface ChaptersTooltipButtonProps {
  itemId: string;
  text: string;
  onClick?: (itemId: string) => void;
  unions?: Union[];
  selectedChapters?: string[];
}

const useStyles = makeStyles((theme) =>
  createStyles({
    chapter: {
      backgroundColor: theme.palette.secondary.contrastText,
      borderColor: theme.palette.secondary.contrastText,
      color: theme.palette.primary.main,
    },
    selectedChapter: {
      backgroundColor: yellow.A200,
      border: '1px solid',
      borderColor: brown['900'],
      color: brown['900'],
    },
  }),
);

export const ChaptersTooltipButton = (props: ChaptersTooltipButtonProps) => {
  const { itemId, text, unions, onClick, selectedChapters } = props;

  const classes = useStyles();

  const handleClick = useCallback(() => {
    onClick?.(itemId);
  }, [itemId, onClick]);

  return (
    <HtmlTooltip
      id={`chapters-tooltip-button_${itemId}`}
      title={
        <Chapters
          unions={unions}
          selectedChapters={selectedChapters}
          classes={{
            chapter: classes.chapter,
            selectedChapter: classes.selectedChapter,
          }}
        />
      }
      arrow
    >
      <LinkButton onClick={handleClick}>{text}</LinkButton>
    </HtmlTooltip>
  );
};
