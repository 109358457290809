import { useCallback } from 'react';

import { OasMemoryError, OasError } from 'core/atoms/errors';

import { gql, useMutation } from 'core/dna/types/apollo';
import {
  AddMembersToChapter,
  AddMembersToChapterVariables,
} from 'core/dna/types/generated/AddMembersToChapter';
import { mapNullableListToList } from 'core/dna/functions';

import { useLazyGetTermsState } from 'core/memory/apollo/terms/local';

import { GET_CLASSES_QUERY } from './_gql';

export const ADD_MEMBERS_TO_CHAPTER_MUTATION = gql`
  mutation AddMembersToChapter($input: AddMembersToChapterInput!) {
    addMembersToChapter(input: $input) {
      id
      title
    }
  }
`;

const FILE_NAME = 'use-add-members-to-chapters';

export const useAddMembersToChapter = () => {
  const { getTermState } = useLazyGetTermsState();

  const [addMembers, { loading, error }] = useMutation<
    AddMembersToChapter,
    AddMembersToChapterVariables
  >(ADD_MEMBERS_TO_CHAPTER_MUTATION);

  const func = useCallback(
    async (chapterId: string, roleIds: string[]) => {
      try {
        const termsState = await getTermState(true);

        const result = await addMembers({
          variables: { input: { chapterId, roleIds } },
          refetchQueries: [
            {
              query: GET_CLASSES_QUERY,
              variables: { termId: termsState!.selected },
            },
          ],
        });

        if (result.errors) {
          throw new OasMemoryError(JSON.stringify(result.errors), {
            title: FILE_NAME,
          });
        }
        return mapNullableListToList(
          result.data?.addMembersToChapter ?? null,
          (item) =>
            item ? { roleId: item.id, roleTitle: item.title ?? '' } : undefined,
        );
      } catch (e: any) {
        if (e instanceof OasError) {
          throw e;
        } else {
          throw OasMemoryError.fromError(e, {
            title: FILE_NAME,
          });
        }
      }
    },
    [addMembers, getTermState],
  );

  return {
    addMembersToChapter: func,
    loading,
    error,
  };
};
