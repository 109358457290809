import { useMemo } from 'react';

import { gql, useQuery } from 'core/dna/types/apollo';
import { SchedulesState } from 'modules/planner/dna/types/local';
import { GetSchedulesState } from 'core/dna/types/generated/GetSchedulesState';
import { mapLocalSchedulesDataStateToSchedulesDataState } from 'modules/planner/dna/functions/map-LocalSchedulesDataState-to-SchedulesDataState';

export const GET_SCHEDULES_STATE_QUERY = gql`
  query GetSchedulesState {
    schedulesState @client {
      selected
      subscribed
      byId
    }
  }
`;

export const useGetSchedulesState = () => {
  const { data: localApiData, loading, error } = useQuery<GetSchedulesState>(
    GET_SCHEDULES_STATE_QUERY,
  );

  const data = useMemo<SchedulesState | undefined>(() => {
    if (!loading && !error && localApiData) {
      return {
        selected: localApiData.schedulesState?.selected ?? [],
        subscribed: localApiData.schedulesState?.subscribed ?? [],
        byId:
          mapLocalSchedulesDataStateToSchedulesDataState(
            localApiData?.schedulesState?.byId,
          ) ?? {},
      };
    }
  }, [loading, error, localApiData]);

  return {
    loading,
    error,
    data,
  };
};
