import { OasLanguage, URLS } from 'core/dna/routing';

import PATHS from 'auth/paths';

const { getOasUrlWithoutParams } = URLS;

const getAuthUrl = (locale?: OasLanguage) => {
  return getOasUrlWithoutParams({
    pathname: PATHS.path,
    locale,
  });
};

const getLoginUrl = () => {
  return PATHS.Login.path;
};

const getLoggedOutUrl = (locale?: OasLanguage) => {
  return getOasUrlWithoutParams({
    pathname: PATHS.LoggedOut.path,
    locale,
  });
};

const getLockUrl = (locale?: OasLanguage) => {
  return getOasUrlWithoutParams({
    pathname: PATHS.Lock.path,
    locale,
  });
};

export default {
  home: getAuthUrl,
  login: getLoginUrl,
  loggedOut: getLoggedOutUrl,
  lock: getLockUrl,
};
