import { produce } from 'immer';

import { logger, LogType } from 'core/atoms/housekeeping';
import { DraggingStore, SplitDraggingState } from './types';
import { getSplitDraggingStorage } from './split-dragging-storage';

const FILE_NAME = 'split-dragging-store';

const storage = getSplitDraggingStorage();

export const createSplitDraggingStore = (): DraggingStore => {
  return {
    startDragging: (input: SplitDraggingState) => {
      storage.setSplitDraggingState(input);
      logger.debug({
        title: 'startDragging',
        logger: FILE_NAME,
        type: LogType.Info,
      });
    },
    getState: () => {
      return storage.getSplitDraggingState() ?? undefined;
    },
    clear: () => {
      storage.removeSplitDraggingState();
    },
  };
};
