/* eslint-disable */
import React, { ReactNode } from 'react';
import clsx from 'clsx';

import { makeStyles } from '@material-ui/core/styles';
import Grid, { GridProps } from '@material-ui/core/Grid';

import { createStyles } from 'core/atoms/styles';

export interface GridItemProps
  extends Omit<GridProps, 'justify' | 'alignItems'> {
  className?: string; // PropTypes.string,
  children?: ReactNode; // PropTypes.node,
  flex?: boolean;
}

const useStyles = makeStyles(() =>
  createStyles({
    grid: {
      padding: '0 15px !important',
    },
    flex: {
      display: 'flex',
    },
  }),
);

export const GridItem = (props: GridItemProps) => {
  const classes = useStyles();
  const { children, flex, className, ...rest } = props;
  return (
    <Grid
      item
      {...rest}
      className={clsx(classes.grid, flex ? classes.flex : '', className ?? '')}
    >
      {children}
    </Grid>
  );
};
