import { RECENT_MESSAGE_TIME } from './constants';

const hasRecentMessageTime = () => {
  return !!localStorage.getItem(RECENT_MESSAGE_TIME);
};

const getRecentMessageTime = (): number | null => {
  return hasRecentMessageTime()
    ? Number(localStorage.getItem(RECENT_MESSAGE_TIME))
    : null;
};

const removeRecentMessageTime = () => {
  hasRecentMessageTime() && localStorage.removeItem(RECENT_MESSAGE_TIME);
};

const setRecentMessageTime = (time: number | null) => {
  if (time) {
    localStorage.setItem(RECENT_MESSAGE_TIME, String(time));
  } else {
    removeRecentMessageTime();
  }
};

export const getRecentMessageTimeStorage = () => {
  return {
    key: RECENT_MESSAGE_TIME,
    hasRecentMessageTime,
    getRecentMessageTime,
    removeRecentMessageTime,
    setRecentMessageTime,
  };
};
