import { useCallback, useMemo, useState } from 'react';
import { Close, Edit, LibraryBooksTwoTone } from '@material-ui/icons';

import { hexToRgbA } from 'core/atoms/functions/hex-to-rgba';

import { Skeleton } from 'core/cells/skeleton';
import { ColorPicker } from 'core/cells/color-picker';

import { useTranslations } from 'core/dna/translations';

import { useGetTermsState } from 'core/memory/apollo/terms/local';

import { Table } from 'templates/mdp/components/react-table';
import { Button } from 'templates/mdp/components/custom-buttons';
import { GridContainer, GridItem } from 'templates/mdp/components/grid';
import {
  Card,
  CardBody,
  CardHeader,
  CardIcon,
} from 'templates/mdp/components/card';

// import { useStyles } from 'templates/mdp/views/tables/react-tables.styles';

import {
  emptySubjectItem,
  SubjectTableItem,
} from 'modules/planner/dna/types/subject';

import { createReactTableStore } from 'core/memory/pure/react-table-store';

import { useReactTableState } from 'core/metabolism/table-state';

import { SubjectEditor } from './subject-editor';
import { useSubjectsTableRead } from './use-subjects-table-read';
import { useSubjectsTableWrite } from './use-subjects-table-write';

export interface SubjectsTableProps {
  onImportSubjectsFromOasClick?: () => void;
  onImportSubjectsFromDefaultClick?: () => void;
}

export const subjectsTableStore = createReactTableStore({}, {});

export const SubjectsTable = (props: SubjectsTableProps) => {
  const {
    onImportSubjectsFromOasClick,
    onImportSubjectsFromDefaultClick,
  } = props;
  // const classes = useStyles();
  const { lang } = useTranslations();
  const { data: termsState } = useGetTermsState();

  const { columns, data, loading } = useSubjectsTableRead();
  const { onRowDelete } = useSubjectsTableWrite();

  const {
    initialPage,
    pageSize,
    sort,
    filters,
    setSort,
    setFilters,
    setPage,
    setPageSize,
  } = useReactTableState(subjectsTableStore, termsState?.selected ?? undefined);

  const [showModal, setShowModal] = useState(false);
  const [selectedSubjectTableItem, setSelectedSubjectTableItem] = useState<
    Partial<SubjectTableItem>
  >(emptySubjectItem);

  const handleAdd = () => {
    setSelectedSubjectTableItem(emptySubjectItem);
    setShowModal(true);
  };

  const handleUpdate = useCallback(
    async (subjectTableItemId: string) => {
      const subject = data.find((subject) => subject.id === subjectTableItemId);
      if (subject) {
        setSelectedSubjectTableItem(subject);
        setShowModal(true);
      }
    },
    [data],
  );

  const handleDelete = useCallback(
    async (classId: string) => {
      const tableItem = data.find((o) => o.id === classId);
      if (tableItem) {
        await onRowDelete(tableItem);
      }
    },
    [data, onRowDelete],
  );

  const preparedData = useMemo<any[]>(() => {
    return data.map((subject) => {
      return {
        ...subject,
        bgColor: (
          <ColorPicker
            readonly
            color={hexToRgbA(subject.bgColor ?? '#cacaca')}
          />
        ),
        actions: (
          <div className="actions-right">
            <Button
              justIcon
              round
              simple
              onClick={() => handleUpdate(subject.id)}
              color="info"
              className="edit"
            >
              <Edit />
            </Button>{' '}
            <Button
              justIcon
              round
              simple
              onClick={() => handleDelete(subject.id)}
              color="danger"
              className="remove"
            >
              <Close />
            </Button>{' '}
          </div>
        ),
      };
    });
  }, [data, handleDelete, handleUpdate]);

  return loading || !termsState?.selected ? (
    <Skeleton variant="rect" width="100%" height="80vh" />
  ) : (
    <GridContainer>
      <GridItem xs={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button color="secondary" onClick={onImportSubjectsFromOasClick}>
          {lang.sourceDataSpace.subjectsModule.importSubjectsFromOas}
        </Button>
        <Button color="secondary" onClick={onImportSubjectsFromDefaultClick}>
          {lang.sourceDataSpace.subjectsModule.importSubjectsFromDefault}
        </Button>
        <Button color="secondary" onClick={handleAdd}>
          {lang.sourceDataSpace.subjectsModule.addSubject}
        </Button>
      </GridItem>
      <GridItem xs={12}>
        <Card>
          <CardHeader color="primary" icon>
            <CardIcon color="primary">
              <LibraryBooksTwoTone />
            </CardIcon>
            {/* <h4 className={classes.cardIconTitle}>{title}</h4> */}
          </CardHeader>
          <CardBody>
            <Table
              columns={columns}
              data={preparedData}
              key={termsState?.selected ?? 'unknown_term'}
              onChangeRowsPerPage={setPageSize}
              onChangePage={setPage}
              onFilterChange={setFilters}
              onOrderChange={setSort}
              options={{
                initialPage,
                pageSize,
                sort,
                filters,
              }}
            />
          </CardBody>
        </Card>
      </GridItem>
      <SubjectEditor
        defaultName={selectedSubjectTableItem.name ?? ''}
        defaultAlias={selectedSubjectTableItem.alias ?? ''}
        defaultCode={selectedSubjectTableItem.code}
        defaultBgColor={selectedSubjectTableItem.bgColor}
        defaultAnnualHours={selectedSubjectTableItem.annualHours}
        id={selectedSubjectTableItem.id}
        open={showModal}
        setOpen={setShowModal}
      />
    </GridContainer>
  );
};
