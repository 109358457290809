import { useCallback } from 'react';

import { OasMemoryError, OasError } from 'core/atoms/errors';

import { gql, useMutation } from 'core/dna/types/apollo';
import { DeleteScheduleInput } from 'core/dna/types/generated/_globalTypes';
import {
  DeleteSchedule,
  DeleteScheduleVariables,
} from 'core/dna/types/generated/DeleteSchedule';

import { useLazyGetTermsState } from 'core/memory/apollo/terms/local';

import { GET_SCHEDULE_DESCRIPTIONS_QUERY } from './_gql';

const DELETE_SCHEDULE_MUTATION = gql`
  mutation DeleteSchedule($input: DeleteScheduleInput!) {
    deleteSchedule(input: $input) {
      description
      status
      statusCode
    }
  }
`;

const TITLE = 'useDeleteSchedule';

export const useDeleteSchedule = () => {
  const { getTermState } = useLazyGetTermsState();

  const [deleteSchedule] = useMutation<DeleteSchedule, DeleteScheduleVariables>(
    DELETE_SCHEDULE_MUTATION,
  );

  const func = useCallback(
    async (input: DeleteScheduleInput) => {
      try {
        const termsState = await getTermState(true);

        const result = await deleteSchedule({
          variables: { input },
          refetchQueries: [
            {
              query: GET_SCHEDULE_DESCRIPTIONS_QUERY,
              variables: { termId: termsState!.selected! },
            },
          ],
        });

        if (result.errors) {
          throw new OasMemoryError(JSON.stringify(result.errors), {
            title: TITLE,
          });
        }
        return result.data?.deleteSchedule ?? undefined;
      } catch (e: any) {
        if (e instanceof OasError) {
          throw e;
        } else {
          throw OasMemoryError.fromError(e, {
            title: TITLE,
          });
        }
      }
    },
    [deleteSchedule, getTermState],
  );

  return {
    deleteSchedule: func,
  };
};
