import { useCallback } from 'react';
import moment, { Moment } from 'moment';

import { useYear } from 'modules/planner/metabolism/use-year';

import { range } from '../utils';

import { Day } from './day';
import { WeekNumber } from './week-number';

export interface MonthProps {
  yearStartDate: Date;
  yearEndDate: Date;
  year: number;
  month: number;
  forceFullWeeks?: boolean;
  showWeekSeparators?: boolean;
  showWeekNumbers?: boolean;
  selectedDays: Date[];
  firstDayOfWeek?: number;
  customClasses?: any;
  onClick: (day: Moment, classes: string) => void;
  dayHovered: (day: any, classes: string) => void;
}

const defaultProps: Partial<MonthProps> = {
  customClasses: undefined,
};

export const MonthRow = (props: MonthProps) => {
  const {
    yearStartDate,
    yearEndDate,
    year,
    month,
    dayHovered,
    forceFullWeeks,
    showWeekSeparators,
    showWeekNumbers,
    selectedDays,
    firstDayOfWeek = 0,
    customClasses,
  } = props;

  const { getMonth } = useYear();

  const isDateOutsideOfTerm = useCallback(
    (date: Moment) => date.isBefore(yearStartDate) || date.isAfter(yearEndDate),
    [yearEndDate, yearStartDate],
  );

  const onDayClick = useCallback(
    (day: Moment, classes: any) => {
      !isDateOutsideOfTerm(day) && props.onClick(day, classes);
    },
    [isDateOutsideOfTerm, props],
  );

  const renderMonthDays = () => {
    const monthStart = moment([year, month, 1]); // current day

    // number of days to insert before the first of the month to correctly align the weekdays
    let prevMonthDaysCount = monthStart.weekday();
    while (prevMonthDaysCount < firstDayOfWeek) {
      prevMonthDaysCount += 7;
    }
    // days in month
    const numberOfDays = monthStart.daysInMonth();
    // insert days at the end to match up 37 (max number of days in a month + 6)
    // or 42 (if user prefers seeing the week closing with Sunday)
    const totalDays = forceFullWeeks ? 42 : 37;

    // day-generating loop
    const days: any[] = [];
    range(firstDayOfWeek + 1, totalDays + firstDayOfWeek + 1, null).forEach(
      (i) => {
        const day = moment([year, month, i - prevMonthDaysCount]);

        // pick appropriate classes
        const classes: any[] = [];
        if (i <= prevMonthDaysCount) {
          classes.push('prev-month');
        } else if (i > numberOfDays + prevMonthDaysCount) {
          classes.push('next-month');
        } else {
          if (
            selectedDays.some((selectedDay) => day.isSame(selectedDay, 'day'))
          ) {
            classes.push('selected');
          }
          if (isDateOutsideOfTerm(day)) {
            classes.push('outside-of-term');
          }
          // call here customClasses function to avoid giving improper classes to prev/next month
          if (customClasses instanceof Function) {
            classes.push(customClasses(day));
          }
        }

        if (customClasses) {
          Object.keys(customClasses).forEach((k) => {
            const obj = (customClasses as any)[k];
            if (typeof obj === 'string') {
              if (obj.indexOf(day.format('ddd')) > -1) {
                classes.push(k);
              }
            } else if (obj instanceof Array) {
              obj.forEach((d) => {
                if (day.format('YYYY-MM-DD') === d) classes.push(k);
              });
            } else if (obj instanceof Function) {
              if (obj(day)) {
                classes.push(k);
              }
            } else if (obj.start && obj.end) {
              const startDate = moment(obj.start, 'YYYY-MM-DD').add(-1, 'days');
              const endDate = moment(obj.end, 'YYYY-MM-DD').add(1, 'days');
              if (day.isBetween(startDate, endDate)) {
                classes.push(k);
              }
            }
          });
        }

        if ((i - 1) % 7 === firstDayOfWeek && days.length) {
          if (showWeekSeparators) {
            // push week separator
            days.push(
              <td aria-label="Week Separator" key={`separator-${i}`} />,
            );
          }
          if (showWeekNumbers) {
            days.push(<WeekNumber date={day} />);
          }
        }
        days.push(
          <Day
            key={`day-${i}`}
            day={day.isValid() ? day : null}
            classes={classes.join(' ')}
            onClick={onDayClick}
            onHover={dayHovered}
          />,
        );
      },
    );
    return days;
  };

  return (
    <tr>
      <td className="month-name">{getMonth(month)?.short ?? 'undefined'}</td>
      {showWeekNumbers && <WeekNumber date={moment([year, month, 1])} />}
      {renderMonthDays()}
    </tr>
  );
};

MonthRow.defaultProps = defaultProps;
