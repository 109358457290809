import { useCallback } from 'react';

import { useGetSelectedScheduleState } from 'modules/planner/memory/apollo/schedules/local';

import { SchedulesTabbedPage } from 'modules/planner/pages/plan/schedules/components/schedules-tabbed-page';

import { SchedulePlanner } from './schedule-planner';

/**  /plan/schedules/:scheduleId?/time-plan  */
export const TimePlan = () => {
  const { selectedScheduleState } = useGetSelectedScheduleState();
  const getContent = useCallback(
    () => <SchedulePlanner scheduleId={selectedScheduleState ?? ''} />,
    [selectedScheduleState],
  );
  return <SchedulesTabbedPage tabContent={getContent} />;
};
