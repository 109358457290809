import { GetOrganizations_Organization_openingHours as ApiOpeningHours } from 'core/dna/types/generated/GetOrganizations';
import { OpeningHours } from 'core/dna/types/opening-hours';
import { mapNullableListToList } from 'core/dna/functions';

export const mapApiOpeningHoursListToOpeningHoursList = (
  apiOpeningHoursList: (ApiOpeningHours | null)[] | null,
): OpeningHours[] | undefined => {
  return mapNullableListToList<ApiOpeningHours, OpeningHours>(
    apiOpeningHoursList,
    mapApiOpeningHoursToOpeningHours,
  );
};

export const mapApiOpeningHoursToOpeningHours = (
  apiOpeningHours: ApiOpeningHours | null,
): OpeningHours | undefined => {
  if (!apiOpeningHours) {
    return;
  }
  const { id, startTime, endTime, weekday } = apiOpeningHours;

  return {
    id,
    startTime: startTime?.formatted ?? '',
    endTime: endTime?.formatted ?? '',
    weekDay: weekday ?? undefined,
  };
};
