import { useMemo } from 'react';

import { gql, useQuery } from 'core/dna/types/apollo';
import { GetSelectedScheduleState } from 'core/dna/types/generated/GetSelectedScheduleState';

const GET_SELECTED_SCHEDULE_STATE_QUERY = gql`
  query GetSelectedScheduleState {
    selectedScheduleState @client
  }
`;

export const useGetSelectedScheduleState = () => {
  const { data, loading, error } = useQuery<GetSelectedScheduleState>(
    GET_SELECTED_SCHEDULE_STATE_QUERY,
  );

  const selectedScheduleState = useMemo(() => {
    if (!loading && !error && data) {
      return data.selectedScheduleState ?? undefined;
    }
  }, [loading, error, data]);

  return {
    selectedScheduleState,
    loading,
    error,
  };
};
