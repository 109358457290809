import React, { useState } from 'react';
import { ApolloError } from '@apollo/client/errors';

import { Grid } from 'core/cells/grid';
import { useSnackbar, VariantType } from 'core/cells/notistack';

import { ConfigurationType } from 'core/dna/types/generated/_globalTypes';
import { OasError, OasMemoryError } from 'core/atoms/errors';

import { ConfigurationDataType, WeeklyEvent } from 'modules/student/dna/types';
import { useUpsertConfiguration } from 'modules/student/memory/apollo/students/remote/upsert-configuration';
import { Allergies, SleepingHours, WeeklyEvents } from './components/profile';

interface StudentProfileProps {
  tuid?: OasValidateString | null;
  data?: ConfigurationDataType | null;
  error?: ApolloError;
}

interface OverridedConfiguration
  extends Omit<ConfigurationDataType, 'sleep' | 'allergies' | 'weeklyEvents'> {
  sleep: Array<string[]>;
  allergies: string[];
  weeklyEvents: WeeklyEvent[];
}

const TITLE = 'Student profile';

export const StudentProfile = ({ tuid, data }: StudentProfileProps) => {
  const { enqueueSnackbar } = useSnackbar();

  const { upsertConfiguration, loading } = useUpsertConfiguration();

  const [profile, setProfile] = useState<OverridedConfiguration>({
    sleep: !data?.sleep ? [] : data.sleep,
    allergies: !data?.allergies ? [] : data.allergies,
    weeklyEvents: !data?.weeklyEvents ? [] : data.weeklyEvents,
  });

  const upsertConfigurationFunc = async (
    configurationType: ConfigurationType,
    title = 'Created successfully!',
  ) => {
    try {
      await upsertConfiguration(tuid!, configurationType, {
        [configurationType]: profile[configurationType],
      });
      enqueueSnackbar(title, {
        variant: VariantType.Success,
      });
    } catch (e: any) {
      if (e instanceof OasError) {
        throw e;
      } else {
        throw OasMemoryError.fromError(e, {
          title: TITLE,
        });
      }
    }
  };

  const addSleepingTimeToProfile = async (sleep: string[]) => {
    profile.sleep.push(sleep);

    setProfile({
      ...profile,
      allergies: profile.allergies,
    });

    await upsertConfigurationFunc(ConfigurationType.sleep);
  };

  const removeSleepingTimeFromProfile = async (index: number) => {
    profile.sleep.splice(index, 1);
    setProfile({ ...profile, sleep: profile.sleep });

    await upsertConfigurationFunc(
      ConfigurationType.sleep,
      'Deleted successfully!',
    );
  };

  const addAllergyToProfile = async (allergy: string) => {
    profile.allergies.push(allergy);

    setProfile({
      ...profile,
      allergies: profile.allergies,
    });

    await upsertConfigurationFunc(ConfigurationType.allergies);
  };

  const removeAllergyFromProfile = async (index: number) => {
    profile.allergies.splice(index, 1);
    setProfile({ ...profile, allergies: profile.allergies });

    await upsertConfigurationFunc(
      ConfigurationType.allergies,
      'Deleted successfully!',
    );
  };

  const addWeeklyEventToProfile = async (weeklyEvent: WeeklyEvent) => {
    profile.weeklyEvents.push(weeklyEvent);
    setProfile({
      ...profile,
      weeklyEvents: profile.weeklyEvents,
    });

    await upsertConfigurationFunc(ConfigurationType.weeklyEvents);
  };

  const removeWeeklyEventFromProfile = async (index: number) => {
    profile.weeklyEvents.splice(index, 1);
    setProfile({ ...profile, weeklyEvents: profile.weeklyEvents });

    await upsertConfigurationFunc(
      ConfigurationType.weeklyEvents,
      'Deleted successfully!',
    );
  };

  return (
    <Grid container spacing={3} justify="center">
      <Grid item xs={12} lg={4}>
        <SleepingHours
          sleep={profile.sleep}
          onAdd={addSleepingTimeToProfile}
          onRemove={removeSleepingTimeFromProfile}
          loading={loading}
        />
      </Grid>

      <Grid item xs={12} lg={4}>
        <Allergies
          allergies={profile.allergies}
          onAdd={addAllergyToProfile}
          onRemove={removeAllergyFromProfile}
          loading={loading}
        />
      </Grid>

      <Grid item xs={12} lg={4}>
        <WeeklyEvents
          weeklyEvents={profile.weeklyEvents}
          onAdd={addWeeklyEventToProfile}
          onRemove={removeWeeklyEventFromProfile}
          loading={loading}
        />
      </Grid>
    </Grid>
  );
};
