import moment from 'moment';
import { dateTimeShortNo, apiFormat } from 'core/atoms/date-time/formats';
import { OasError } from '../../errors';

export const formatDateTimeString = (time: string, format?: string) => {
  const m = moment(time, apiFormat);

  if (format && m) {
    return m.format(format);
  }

  if (m) {
    return m.format(dateTimeShortNo);
  }

  throw new OasError('Time string should be "YYYY-MM-DDTHH:mm:ss" format', {
    title: 'format-time-string',
    type: 'OasError',
  });
};
