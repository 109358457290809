import { useMemo } from 'react';
import moment from 'moment';

import { dateTimeShortNo } from 'core/atoms/date-time/formats';

import { useTranslations } from 'core/dna/translations';
import { JournalEntry } from 'core/dna/types/generated/JournalEntry';
import { JournalType } from 'core/dna/types/generated/_globalTypes';

import { JournalEntryDataType } from 'modules/student/dna/types';
import { DisplayCellData } from './display-cell-data';

type OmitedJournals = Omit<JournalEntry, 'signedBy' | 'id' | 'from' | 'to'>;
interface JournalsTableItem extends OmitedJournals {
  signedBy: string;
  date: string;
}

export const useJournalsTableRead = (journals: JournalEntry[] | undefined) => {
  const { texts } = useTranslations();

  const columns = useMemo(
    () => [
      {
        Header: texts.oasCommon.type,
        accessor: 'type',
      },
      {
        Header: texts.studentModule.absence.signedBy,
        accessor: 'signedBy',
      },
      {
        Header: texts.planner.common.notes.one,
        accessor: 'data',
        Cell: (row: any) => <DisplayCellData {...row} />,
      },
      {
        Header: texts.oasCommon.date,
        accessor: 'date',
      },
      {
        Header: texts.planner.common.from,
        accessor: 'from',
      },
      {
        Header: texts.planner.common.to,
        accessor: 'to',
      },
    ],
    [texts],
  );

  const mapData = (data: JournalEntryDataType) => {
    const keys = Object.keys(data);

    if (!keys.length) {
      return '';
    }

    switch (keys[0]) {
      case JournalType.note:
        return data[JournalType.note];
      case JournalType.sleep:
        return data[JournalType.sleep];
      case JournalType.diaper:
        return data[JournalType.diaper];
      case JournalType.restock:
        return !data[JournalType.restock]?.comment
          ? data[JournalType.restock]?.restockType
          : `Type: ${data[JournalType.restock]?.restockType};\n Comment: ${
              data[JournalType.restock]?.comment
            }`;
      default:
        return 'JournalType isn`t correct';
    }
  };

  const tableData = useMemo<Partial<JournalsTableItem[]>>(
    () =>
      journals?.map((journal) => ({
        ...journal,
        from: !journal.from?.formatted
          ? ''
          : moment(journal.from.formatted).utc().format(dateTimeShortNo),
        to: !journal.to?.formatted
          ? ''
          : moment(journal.to.formatted).utc().format(dateTimeShortNo),
        data: mapData(journal.data),
        signedBy: `${journal.signedBy.firstName} ${journal.signedBy.lastName}`,
        date: !journal.signedBy.date?.formatted
          ? ''
          : moment(journal.signedBy.date.formatted)
              .utc()
              .format(dateTimeShortNo),
      })) ?? [],
    [journals],
  );

  return { columns, tableData };
};
