import { BehaviorSubject } from 'rxjs';
import { produce } from 'immer';

import { logger, LogType } from 'core/atoms/housekeeping';

export type AuthMode =
  | 'logged-out'
  | 'lock'
  | 'identity'
  | 'identity-refreshed';

export interface AuthStoreState {
  mode: AuthMode | null;
  modeChanged: boolean;
}

interface InternalState {
  accessToken: string | null;
}

const initialState: AuthStoreState = {
  mode: null,
  modeChanged: false,
};

const internalState: InternalState = {
  accessToken: null,
};

const subject = new BehaviorSubject(initialState);

let state = initialState;

const emit = (state: AuthStoreState) => {
  subject.next(state);
};

const FILE_NAME = 'auth-store';

export const authStore = {
  subscribe: (setState: (value: AuthStoreState) => void) =>
    subject.subscribe(setState),
  setAuth: (input: {
    accessToken: string | null;
    sessionToken: string | null;
    hasPinCode: boolean | null;
  }) => {
    let mode: AuthMode = 'logged-out';

    const { accessToken, sessionToken, hasPinCode } = input;

    if (sessionToken) {
      if (accessToken) {
        if (
          internalState.accessToken &&
          accessToken !== internalState.accessToken
        ) {
          mode = 'identity-refreshed';
        } else {
          mode = 'identity';
        }
      } else if (hasPinCode) {
        mode = 'lock';
      }
    }
    internalState.accessToken = accessToken;

    state = {
      mode,
      modeChanged: state.mode !== mode,
    };

    logger.debug({
      title: 'setAuth() -> mode',
      logger: FILE_NAME,
      type: LogType.Info,
      value: mode,
    });

    emit(state);
  },
  updateIdentityIfLocked: () => {
    if (state.mode !== 'lock') {
      return;
    }
    state = produce(state, (draft) => {
      draft.mode = 'identity';
    });

    logger.debug({
      title: 'updateIdentityIfLocked()',
      logger: FILE_NAME,
      type: LogType.Info,
    });

    emit(state);
  },
  initialState,
};
