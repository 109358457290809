import React, { useMemo, useState } from 'react';

import { Table } from 'templates/mdp/components/react-table';
import { Delete, Edit, PersonAdd, Group } from '@material-ui/icons';
import { Skeleton } from 'core/cells/skeleton';
import { WriteModeEnum } from 'core/dna/types/write-mode';

import { GridContainer, GridItem } from 'templates/mdp/components/grid';
import {
  Card,
  CardBody,
  CardHeader,
  CardIcon,
} from 'templates/mdp/components/card';
import { useStyles } from 'templates/mdp/views/tables/react-tables.styles';
import { Button } from 'templates/mdp/components/custom-buttons';

import { useTranslations } from 'core/dna/translations';
import { createReactTableStore } from 'core/memory/pure/react-table-store';
import { useGetTermsState } from 'core/memory/apollo/terms/local/get-terms-state';
import { useReactTableState } from 'core/metabolism/table-state';
import { useHistory } from 'core/dna/routing';

import {
  TeachersTableItem,
  useTeachersTableRead,
} from './use-teachers-table-read';
import {
  EvaluationGroupCreator,
  InitialEvaluationGroup,
  initialEvaluationGroup,
} from './evaluation-group-creator';
import {
  EvaluationGroupAccessCreator,
  EvaluationGroupAccessCreatorData,
} from './evaluation-group-access-creator';
import { StudentEvaluationsCreator } from './student-evaluations-creator';

export const subjectsTableStore = createReactTableStore({}, {});

export const TeachersTable = () => {
  const classes = useStyles();
  const { texts } = useTranslations();
  const { goBack } = useHistory();

  const { data: termsState } = useGetTermsState();

  const { columns, data, loading, title, batchId } = useTeachersTableRead();

  const {
    initialPage,
    pageSize,
    sort,
    filters,
    setSort,
    setFilters,
    setPage,
    setPageSize,
  } = useReactTableState(subjectsTableStore, termsState?.selected ?? undefined);

  const [showModal, setShowModal] = useState(false);
  const [showAccessCreator, setShowAccessCreator] = useState(false);
  const [
    showStudentEvaluationsCreator,
    setShowStudentEvaluationsCreator,
  ] = useState(false);
  const [writeMode, setWriteMode] = useState<WriteModeEnum>(
    WriteModeEnum.create,
  );
  const [
    selectedEvaluationGroup,
    setSelectedEvaluationGroup,
  ] = useState<InitialEvaluationGroup>(initialEvaluationGroup);
  const [
    selectedEvaluationGroupAccess,
    setSelectedEvaluationGroupAccess,
  ] = useState<EvaluationGroupAccessCreatorData>({
    evaluationGroupId: '',
    evaluator: true,
    tuid: '',
    selectedTeachersIds: [],
  });

  const preparedData = useMemo(
    () =>
      data?.map((evaluationGroup) => ({
        ...evaluationGroup,
        actions: (
          <div className="actions-right">
            <Button
              justIcon
              round
              simple
              size="sm"
              color="info"
              title="add responsible teacher"
              aria-label={texts.planner.common.edit}
              onClick={() => {
                handleAddAccess(evaluationGroup);
              }}
            >
              <PersonAdd />
            </Button>
            <Button
              justIcon
              round
              simple
              size="sm"
              color="info"
              title={texts.planner.common.edit}
              aria-label={texts.planner.common.edit}
              onClick={() => {
                handleEdit(evaluationGroup);
              }}
            >
              <Edit />
            </Button>
            <Button
              justIcon
              round
              simple
              size="sm"
              color="danger"
              title={texts.planner.common.delete}
              aria-label={texts.planner.common.delete}
              disabled={true}
              onClick={() => {
                console.log('remove is not implemented');
              }}
            >
              <Delete />
            </Button>
          </div>
        ),
      })),
    [data, texts],
  );

  const handleAdd = () => {
    setWriteMode(WriteModeEnum.create);
    setSelectedEvaluationGroup({
      ...initialEvaluationGroup,
      id: batchId,
    });
    setShowModal(true);
  };

  const handleEdit = (evaluationGroup: TeachersTableItem) => {
    setWriteMode(WriteModeEnum.update);
    setSelectedEvaluationGroup({
      id: evaluationGroup.id!,
      subjectCode: evaluationGroup.subjectCode || '',
      name: evaluationGroup.name || '',
    });
    setShowModal(true);
  };

  const handleAddAccess = (evaluationGroup: TeachersTableItem) => {
    if (!evaluationGroup.id) return;

    setSelectedEvaluationGroupAccess({
      evaluationGroupId: evaluationGroup.id,
      evaluator: true,
      tuid: null,
      selectedTeachersIds: evaluationGroup.accessList.map(
        (access) => access.teacher?.personDetails?.tuid || '',
      ),
    });
    setShowAccessCreator(true);
  };

  const handleGenerateGroups = () => {
    setShowStudentEvaluationsCreator(true);
  };

  const handleBack = () => {
    goBack();
  };

  return loading || !termsState?.selected ? (
    <Skeleton variant="rect" width="100%" height="80vh" />
  ) : (
    <GridContainer spacing={3} justifyContent="center">
      <GridItem
        xs={12}
        style={{ display: 'flex', justifyContent: 'space-between' }}
      >
        <Button onClick={handleBack}>{texts.oasCommon.back}</Button>
        <div>
          <Button color="secondary" onClick={handleGenerateGroups}>
            {texts.grades.evaluationGroups.generateGroups}
          </Button>
          <Button color="secondary" onClick={handleAdd}>
            {texts.grades.evaluationGroups.addEvaluationGroup}
          </Button>
        </div>
      </GridItem>
      <GridItem xs={12}>
        <Card>
          <CardHeader color="primary" icon>
            <CardIcon color="primary">
              <Group />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>{title}</h4>
          </CardHeader>
          <CardBody>
            <Table
              columns={columns}
              data={preparedData ?? []}
              key={termsState.selected}
              onChangeRowsPerPage={setPageSize}
              onChangePage={setPage}
              onFilterChange={setFilters}
              onOrderChange={setSort}
              options={{
                initialPage,
                pageSize,
                sort,
                filters,
              }}
            />
          </CardBody>
        </Card>
      </GridItem>
      <EvaluationGroupCreator
        writeMode={writeMode}
        data={selectedEvaluationGroup}
        open={showModal}
        setOpen={setShowModal}
      />
      <EvaluationGroupAccessCreator
        data={selectedEvaluationGroupAccess}
        open={showAccessCreator}
        setOpen={setShowAccessCreator}
      />
      <>
        {showStudentEvaluationsCreator && (
          <StudentEvaluationsCreator
            data={{ batchId }}
            open={showStudentEvaluationsCreator}
            setOpen={setShowStudentEvaluationsCreator}
          />
        )}
      </>
    </GridContainer>
  );
};
