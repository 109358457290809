import { useState } from 'react';
import { NotificationsActive } from '@material-ui/icons';

import { Features } from 'core/dna/types/organization';
import { Modules } from 'core/dna/types/modules';
import { useSelectedModuleState } from 'core/dna/modules';

import { useOrganization } from 'core/metabolism/organizations';

import { Snackbar } from 'templates/mdp/components/snackbar';

import { BannerContent } from './banner-content';

export const DemoBanner = () => {
  const selectedModule = useSelectedModuleState();
  const { organization } = useOrganization();

  const [demoBannerOpen, setDemoBannerOpen] = useState(true);

  const currentFeature = (): Features | undefined => {
    const modules = [Modules.planner, Modules.substitute];

    if (!selectedModule || !organization) {
      return;
    }

    if (!modules.includes(selectedModule.id as Modules)) {
      return;
    }

    return organization.features?.find((f) => f.code === selectedModule.id);
  };

  const isTrialModule = () => {
    const feature = currentFeature();

    return !!feature?.expiresAt;
  };

  return (
    <Snackbar
      place="bc"
      color="warning"
      icon={NotificationsActive}
      message={<BannerContent feature={currentFeature()} />}
      open={demoBannerOpen && isTrialModule()}
      closeNotification={() => setDemoBannerOpen(false)}
      close
    />
  );
};
