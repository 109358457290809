import { useMemo } from 'react';

import { gql, useQuery } from 'core/dna/types/apollo';
import { GetSubstituteTeachers } from 'core/dna/types/generated/GetSubstituteTeachers';

import { Teacher } from 'modules/substitute/dna/types/teacher';

export const GET_SUBSTITUTE_TEACHERS_QUERY = gql`
  query GetSubstituteTeachers {
    SubstituteTeacher {
      id
      firstName
      lastName
      msisdn
      mail
      tags
      state
    }
  }
`;

export const useGetSubstituteTeachers = () => {
  const { data: apiData, error, loading } = useQuery<GetSubstituteTeachers>(
    GET_SUBSTITUTE_TEACHERS_QUERY,
  );

  const data = useMemo<Teacher[] | undefined>(() => {
    if (!loading && !error && apiData?.SubstituteTeacher) {
      return apiData.SubstituteTeacher.map((t) => ({
        id: t?.id ?? '',
        firstName: t?.firstName ?? '',
        lastName: t?.lastName ?? '',
        phone: t?.msisdn ?? undefined,
        email: t?.mail ?? undefined,
        tags: t?.tags.join(', '),
        state: t?.state,
      }));
    }
  }, [apiData, error, loading]);

  return {
    data,
    error,
    loading,
  };
};
