import React, { useCallback } from 'react';

import { EventContentArg } from 'core/cells/full-calendar';

import { CalendarEvent } from 'core/dna/types';

import { TimeplanEvent } from './timeplan-event';
import { TimeplanEventVisibilityProps } from './timeplan-event.types';

export type UseTimeplanEventProps = TimeplanEventVisibilityProps & {
  onPin?: (event: CalendarEvent) => void;
  onDelete?: (eventId: string) => void;
  readonly?: boolean;
};

export const useTimeplanEvent = (props: UseTimeplanEventProps) => {
  const eventContent = useCallback(
    (args: EventContentArg) => <TimeplanEvent {...args} {...props} />,
    [props],
  );
  return { eventContent };
};
