import { Grid } from 'core/cells/grid';

import { BatchTable } from './components/batch-table';

export const BatchListMainContent = () => {
  return (
    <Grid container>
      <Grid item xs={12}>
        <BatchTable />
      </Grid>
    </Grid>
  );
};
