import { Typography } from 'core/cells/typography';
import { useTranslations } from 'core/dna/translations';

interface NoDataProps {
  text?: string;
}

export const NoData = ({ text }: NoDataProps) => {
  const { texts } = useTranslations();

  return (
    <Typography variant="body1" color="textSecondary" align="center">
      {text || texts.oasCommon.noData}
    </Typography>
  );
};
