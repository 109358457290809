import { useTranslations } from 'core/dna/translations';
import { SubstituteJobState } from 'core/dna/types/generated/_globalTypes';
// import { JobTableItem } from '../dna/types/job';

interface DisplayCellStateProps {
  value: string;
  // row: any;
  // data: JobTableItem[];
}

export const DisplayCellState = (props: DisplayCellStateProps) => {
  const { texts } = useTranslations();
  const { value } = props;

  return <>{texts.substitute.jobState[value as SubstituteJobState]}</>;
};
