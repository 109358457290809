import { GetClasses_Class_availabilityPreferences as ApiClassAvailabilityPreference } from 'core/dna/types/generated/GetClasses';
import { GetRooms_Room_availabilityPreferences as ApiRoomAvailabilityPreference } from 'core/dna/types/generated/GetRooms';
import { mapNullableListToList } from 'core/dna/functions/map-nullable-list-to-list';

import { AvailabilityPreference } from 'modules/planner/dna/types/availability-preference';
import { AvailabilityLevel } from 'modules/planner/dna/types/availability-level';

export const mapApiAvailabilityPreferenceListToAvailabilityPreferenceList = (
  apiAvailabilityPreferenceList:
    | (ApiClassAvailabilityPreference | ApiRoomAvailabilityPreference | null)[]
    | null,
): AvailabilityPreference[] | undefined => {
  return mapNullableListToList<
    ApiClassAvailabilityPreference | ApiRoomAvailabilityPreference,
    AvailabilityPreference
  >(
    apiAvailabilityPreferenceList,
    mapApiAvailabilityPreferenceToAvailabilityPreference,
  );
};

export const mapApiAvailabilityPreferenceToAvailabilityPreference = (
  apiAvailabilityPreference:
    | ApiClassAvailabilityPreference
    | ApiRoomAvailabilityPreference
    | null,
): AvailabilityPreference | undefined => {
  if (!apiAvailabilityPreference) {
    return;
  }
  const {
    id,
    startTime,
    endTime,
    availabilityLevel,
    weekday,
  } = apiAvailabilityPreference;

  return {
    id,
    startTime: startTime.formatted!,
    endTime: endTime.formatted!,
    availabilityLevel: (availabilityLevel as AvailabilityLevel) ?? undefined,
    weekDay: weekday ?? undefined,
  };
};
