import { useMemo } from 'react';
import queryString from 'query-string';

import { RouterRouteProps, RouterRoute } from 'core/cells/router-route';
import { RouterRedirect } from 'core/cells/router-redirect';

import { getInitialPageStorage } from 'core/memory/browser';

import { useAuthContext } from 'auth/metabolism/use-auth-context';

export type PrivateRouteProps = RouterRouteProps;

export const PrivateRoute = (props: PrivateRouteProps) => {
  const { getIdentity } = useAuthContext();
  const identity = getIdentity();
  const { setInitialPage: setInitialPageToBrowserStorage } = useMemo(
    () => getInitialPageStorage(),
    [],
  );

  if (!identity) {
    return <></>;
  }

  const { transient } = queryString.parse(props.location?.search ?? '');
  if (transient) {
    return <RouterRedirect to={props.location?.pathname ?? ''} />;
  }

  setInitialPageToBrowserStorage({
    path: props.location?.pathname ?? '',
    search: props.location?.search,
  });

  return <RouterRoute {...props} />;
};
