import { OasLocales } from 'core/dna/translations/types';
import { RouteConfig } from 'core/dna/types/local';

import { LoginPage, AuthLoggedOut, LockPage } from 'auth/pages';

import PATHS from 'auth/paths';

export const getRoutes = (texts: OasLocales): RouteConfig[] => [
  {
    id: `auth-${PATHS.path}`,
    exact: true,
    path: PATHS.path,
    component: LoginPage,
  },
  {
    id: `auth-${PATHS.LoggedOut.path}`,
    name: texts.planner.authSpace.loggedOutTitle,
    exact: true,
    path: PATHS.LoggedOut.path,
    component: AuthLoggedOut,
  },
  {
    id: `auth-${PATHS.Lock.path}`,
    name: texts.auth.lockScreen,
    exact: true,
    path: PATHS.Lock.path,
    component: LockPage,
  },
];
