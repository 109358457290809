import { v4 as uuidv4 } from 'uuid';

import { OasMappingError } from 'core/atoms/errors';

import {
  GetMyAccessRoles_myAccessRoles as ApiMyAccessRoles,
  GetMyAccessRoles_myAccessRoles_organizations as ApiAccessRoles,
} from 'core/dna/types/generated/GetMyAccessRoles';
import { AccessRoles } from 'core/dna/types/access-roles';

import { mapNullableListToList } from '../map-nullable-list-to-list';
import { mapNullableStringToString } from '../map-nullable-string-to-string';

export const mapApiAccessRolesListToAccessRolesList = (
  apiAccessRolesList: (ApiMyAccessRoles | ApiAccessRoles | null)[] | null,
): AccessRoles[] | undefined => {
  return mapNullableListToList<ApiMyAccessRoles | ApiAccessRoles, AccessRoles>(
    apiAccessRolesList,
    mapApiAccessRolesToAccessRoles,
  );
};

export const mapApiAccessRolesToAccessRoles = (
  apiAccessRoles: ApiMyAccessRoles | ApiAccessRoles | null,
): AccessRoles => {
  if (apiAccessRoles === null) {
    throw new OasMappingError('apiAccessRoles" should not be null!', {
      title: 'Map ApiAccessRoles to AccessRoles',
    });
  }

  const { id, name, accessRoles, permissions } = apiAccessRoles;

  return {
    id: id ?? `no-id-${uuidv4()}`,
    name: name ?? undefined,
    accessRoles: mapNullableListToList(accessRoles, mapNullableStringToString),
    permissions: mapNullableListToList(permissions, mapNullableStringToString),
  };
};
