import { useCallback, useState } from 'react';

import { logger, LogType } from 'core/atoms/housekeeping';
import {
  OasPageError,
  OasError,
  OasErrorInterface,
  OasMemoryError,
} from 'core/atoms/errors';
import { parseTemplate } from 'core/atoms/functions/string';
import { useSnackbar } from 'core/cells/notistack';
import { useTranslations } from 'core/dna/translations';
import {
  CreateStudentEvaluationInput,
  UpdateEvaluationBatchInput,
  UpdateStudentEvaluationInput,
} from 'core/dna/types/generated/_globalTypes';

import {
  useCreateStudentEvaluation,
  useUpdateStudentEvaluation,
} from '../memory/apollo/evaluation/remote';

const TITLE = 'use-evaluation-table-write';

export const useEvaluationTableWrite = () => {
  const { texts } = useTranslations();
  const { enqueueSnackbar } = useSnackbar();
  const { createStudentEvaluation } = useCreateStudentEvaluation();
  const { updateStudentEvaluation } = useUpdateStudentEvaluation();

  const [writing, setWriting] = useState(false);

  const onRowAdd = useCallback(
    async (item: CreateStudentEvaluationInput) => {
      try {
        setWriting(true);
        const result = await createStudentEvaluation(item);
        setWriting(false);
        enqueueSnackbar(
          parseTemplate(texts.oasCommon.entityCreated, {
            entityType: texts.grades.gradesCommon.evaluation,
          }),
          {
            variant: 'success',
          },
        );
        return result;
      } catch (e: any) {
        setWriting(false);
        let error: OasErrorInterface;
        if (e instanceof OasError) {
          error = e;
        } else {
          error = OasMemoryError.fromError(e, {
            title: TITLE,
          });
        }
        enqueueSnackbar(error.message, { variant: 'error' });
        throw error;
      }
    },
    [createStudentEvaluation, enqueueSnackbar, texts],
  );

  const onRowUpdate = useCallback(
    async (item: UpdateStudentEvaluationInput, teacherTuid: string) => {
      try {
        setWriting(true);
        const result = await updateStudentEvaluation(item, teacherTuid);
        setWriting(false);
        enqueueSnackbar(
          parseTemplate(texts.oasCommon.entityUpdated, {
            entityType: texts.grades.gradesCommon.evaluation,
          }),
          {
            variant: 'success',
          },
        );
        return result;
      } catch (e: any) {
        setWriting(false);
        let error: OasErrorInterface;
        if (e instanceof OasError) {
          error = e;
        } else {
          error = OasMemoryError.fromError(e, {
            title: TITLE,
          });
        }
        enqueueSnackbar(error.message, { variant: 'error' });
        throw error;
      }
    },
    [enqueueSnackbar, texts, updateStudentEvaluation],
  );

  const onRowDelete = useCallback(
    async (oldData: UpdateEvaluationBatchInput) => {
      try {
        setWriting(true);
        enqueueSnackbar('Not implemented!', { variant: 'warning' });
        // await deleteSubstituteJob(oldData.id);
        setWriting(false);
      } catch (e: any) {
        setWriting(false);
        const error =
          e instanceof OasError
            ? e
            : OasPageError.fromError(e, { title: TITLE });
        if (error.originalMessage) {
          logger.info(error.originalMessage, LogType.Error);
        }
        enqueueSnackbar(
          parseTemplate(texts.oasCommon.deleteEntitiesOfTypeError.one, {
            entityType: texts.oasCommon.job.one,
            entity: oldData.term.slice(0, 10),
          }),
          {
            variant: 'error',
          },
        );
        return 1;
      }
    },
    [enqueueSnackbar, texts],
  );

  return {
    onRowAdd,
    onRowUpdate,
    onRowDelete,
    writing,
  };
};
