import { GetSyncGroups_SyncGroup as ApiSyncGroup } from 'core/dna/types/generated/GetSyncGroups';
import { mapNullableListToList } from 'core/dna/functions';

import { SyncGroup } from 'modules/planner/dna/types/sync-group';

export const mapApiSyncGroupListToSyncGroupList = (
  apiSyncGroup: (ApiSyncGroup | null)[] | null,
): SyncGroup[] | undefined => {
  return mapNullableListToList<ApiSyncGroup, SyncGroup>(
    apiSyncGroup,
    mapApiSyncGroupToSyncGroup,
  );
};

export const mapApiSyncGroupToSyncGroup = (
  apiSyncGroup: ApiSyncGroup | null,
): SyncGroup | undefined => {
  if (!apiSyncGroup) {
    return;
  }

  const { id, name, short, description, parentId, type } = apiSyncGroup;

  return {
    id: id ?? '',
    name: name ?? 'NAME',
    short: short ?? '',
    description: description ?? '',
    parentId: parentId ?? '',
    type: type ?? '',
  };
};
