import { useMemo } from 'react';

import { runtimeEnv } from 'core/atoms/env';

import { OasPath, matchPath, PARAMS, useLocation } from 'core/dna/routing';

const env = runtimeEnv();

interface IntercomProductTour {
  pathname: OasPath;
  tourId: number;
}

const getIntercomProductTours = (): IntercomProductTour[] => {
  const toursString = String(env.REACT_APP_INTERCOM_PRODUCT_TOURS);
  return toursString
    .split(',')
    .map((tourString) => {
      const tourArray = tourString.split(':');
      if (tourArray.length !== 2) {
        return null;
      }
      return {
        pathname: `/${PARAMS.Locale.path + tourArray[0]}`,
        tourId: Number(tourArray[1]),
      } as IntercomProductTour;
    })
    .filter((tour) => tour !== null) as IntercomProductTour[];
};

const tours = getIntercomProductTours();

export interface UseIntercomResult {
  productTourId: number | null;
}

export const useIntercom = (): UseIntercomResult => {
  const location = useLocation();

  const tour = useMemo(() => {
    const t = tours.find((tour) => {
      const match = matchPath(location.pathname, {
        path: tour.pathname,
        exact: true,
        strict: true,
      });
      return !!match;
    });
    return t ?? null;
  }, [location.pathname]);

  return {
    productTourId: tour?.tourId ?? null,
  };
};
