import { useCallback, useMemo, useState } from 'react';
import clsx from 'clsx';

import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Paper from '@material-ui/core/Paper';
import Grow from '@material-ui/core/Grow';
import Hidden from '@material-ui/core/Hidden';
import Popper from '@material-ui/core/Popper';
import Person from '@material-ui/icons/Person';

import { useTraceUpdate, useRenderCounter } from 'core/atoms/housekeeping';

import { useSelectedOrganizationState } from 'core/dna/organizations';
import { useLocale, useRouteMatch } from 'core/dna/routing';
import { useTranslations } from 'core/dna/translations';
import { OrganizationLevel } from 'core/dna/types/local';

import { useGetOrganization } from 'core/memory/apollo/organizations/remote';

import { useOasHistory } from 'core/metabolism/use-oas-history';

import {
  useLazyLogout,
  defaultLogoutCallback,
  defaultAuthErrorCallback,
  useLock,
} from 'auth/metabolism';
import { defaultLockCallback } from 'auth/metabolism/default-lock-callback';
import { useAuthContext } from 'auth/metabolism/use-auth-context';

import { Button } from 'templates/mdp/components/custom-buttons';

import { PATHS as SubstitutePaths } from 'modules/substitute/paths';

import { OrganizationSelector } from 'app/organisms/organization-selector';
import { TermSelector } from 'app/organisms/term-selector';

import { useStyles } from './dashboard-navbar-links.styles';

export interface DashboardNavbarLinksProps {
  rtlActive?: boolean;
}

export const DashboardNavbarLinks = (props: DashboardNavbarLinksProps) => {
  // TODO: remove after performance testing
  useTraceUpdate('DashboardNavbarLinks', props);
  const renderCount = useRenderCounter({ name: 'DashboardNavbarLinks' });

  const [openProfile, setOpenProfile] = useState<any>(null);
  const handleClickProfile = (event: any) => {
    if (openProfile?.contains(event.target)) {
      setOpenProfile(null);
    } else {
      setOpenProfile(event.currentTarget);
    }
  };
  const handleCloseProfile = useCallback(() => {
    setOpenProfile(null);
  }, []);

  const { replace } = useOasHistory();
  const locale = useLocale();
  const { texts } = useTranslations();
  const selected = useSelectedOrganizationState();
  const { data: organization } = useGetOrganization();
  const matchSubstitute = useRouteMatch(SubstitutePaths.path);
  const { getHasPinCode } = useAuthContext();

  const hasPinCode = getHasPinCode() ?? false;
  const showTermSelector = useMemo(
    () =>
      !matchSubstitute &&
      selected?.level === OrganizationLevel.Organization &&
      !!organization,

    [matchSubstitute, organization, selected?.level],
  );

  const { logout } = useLazyLogout(
    () => defaultLogoutCallback({ navigateTo: replace, locale }),
    (error) => defaultAuthErrorCallback(error, { navigateTo: replace, locale }),
  );

  const { lock } = useLock(
    () => defaultLockCallback({ navigateTo: replace, locale }),
    (error) => defaultAuthErrorCallback(error, { navigateTo: replace, locale }),
  );

  const handleLockClick = () => {
    lock();
    handleCloseProfile();
  };

  const handleLogOutClick = () => {
    logout();
    handleCloseProfile();
  };

  const classes = useStyles();
  const { rtlActive } = props;

  const dropdownItem = clsx(
    classes.dropdownItem,
    classes.primaryHover,
    rtlActive ? classes.dropdownItemRTL : '',
  );

  const wrapper = clsx(rtlActive ? classes.wrapperRTL : '');
  const { managerClasses } = classes;

  return (
    <div className={wrapper}>
      {renderCount}
      <div className={managerClasses}>
        <OrganizationSelector />
      </div>
      <div className={managerClasses}>
        {showTermSelector && <TermSelector />}
      </div>
      <div className={managerClasses}>
        <Button
          color="transparent"
          aria-label="Person"
          justIcon
          aria-owns={openProfile ? 'profile-menu-list' : undefined}
          aria-haspopup="true"
          onClick={handleClickProfile}
          className={rtlActive ? classes.buttonLinkRTL : classes.buttonLink}
          muiClasses={{
            label: rtlActive ? classes.labelRTL : '',
          }}
          style={{ paddingTop: 28 }}
        >
          <Person
            className={clsx(
              classes.headerLinksSvg,
              rtlActive
                ? `${classes.links} ${classes.linksRTL}`
                : classes.links,
            )}
          />
          <Hidden mdUp implementation="css">
            <span onClick={handleClickProfile} className={classes.linkText}>
              {rtlActive ? 'الملف الشخصي' : 'Profile'}
            </span>
          </Hidden>
        </Button>
        <Popper
          open={Boolean(openProfile)}
          anchorEl={openProfile}
          transition
          disablePortal
          placement="bottom"
          className={clsx({
            [classes.popperClose]: !openProfile,
            [classes.popperResponsive]: true,
            [classes.popperNav]: true,
          })}
        >
          {({ TransitionProps }) => (
            <Grow {...TransitionProps} style={{ transformOrigin: '0 0 0' }}>
              <Paper className={classes.dropdown}>
                <ClickAwayListener onClickAway={handleCloseProfile}>
                  <MenuList role="menu">
                    {hasPinCode && (
                      <MenuItem
                        onClick={handleLockClick}
                        className={dropdownItem}
                      >
                        {texts.auth.lockScreen}
                      </MenuItem>
                    )}
                    <MenuItem
                      onClick={handleLogOutClick}
                      className={dropdownItem}
                    >
                      {rtlActive ? 'الخروج' : texts.planner.common.signOut}
                    </MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
    </div>
  );
};
