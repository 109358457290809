import { useMemo } from 'react';
import clsx from 'clsx';

import { makeStyles, createStyles, Theme } from 'core/atoms/styles';
import { DraggableTypes, TextItem, DraggableItem } from 'core/atoms/types';
import { orderBy } from 'core/atoms/functions/array';

import { FilteredChips } from 'core/cells/filtered-chips';

import { useLocale } from 'core/dna/routing';
import { useTranslations } from 'core/dna/translations';

import { useGetSubjects } from 'modules/planner/memory/apollo/subjects/remote';

import PlannerUrls from 'modules/planner/routing/urls';

import { NoBlockData } from '../no-block-data';

export interface SubjectsBlockProps {
  className?: string;
  onDoubleClick?: (item: DraggableItem) => void;
}
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginBottom: theme.spacing(2),
    },
  }),
);

export const SubjectsBlock = (props: SubjectsBlockProps) => {
  const classes = useStyles();

  const { texts } = useTranslations();
  const locale = useLocale();

  const {
    subjects: subjectsData,
    subjectsError,
    subjectsLoading,
  } = useGetSubjects();

  const subjects: TextItem[] | undefined = useMemo(
    () =>
      orderBy(
        subjectsData?.map((s) => ({
          id: s.id,
          text: s.name || DraggableTypes.Subject,
        })),
        ['text'],
      ),
    [subjectsData],
  );

  if (subjectsError) return <p>Subjects Error!</p>;
  if (subjectsLoading) return <p>Loading Subjects...</p>;

  return subjects?.length ? (
    <FilteredChips
      label={texts.planner.sourceDataSpace.subjectsModule.filterSubjects}
      data={subjects}
      draggableType={DraggableTypes.Subject}
      className={clsx(classes.root, props.className)}
      onDoubleClick={props.onDoubleClick}
    />
  ) : (
    <NoBlockData
      message={texts.planner.sourceDataSpace.subjectsModule.noSubjects}
      link={PlannerUrls.subjects(locale)}
      linkText={texts.planner.sourceDataSpace.subjectsModule.addSubject}
      className={classes.root}
    />
  );
};
