import { GetStudentDetails_PersonT2_configurations as ApiConfiguration } from 'core/dna/types/generated/GetStudentDetails';
import { mapNullableListToList } from 'core/dna/functions/map-nullable-list-to-list';
import { ConfigurationType } from 'core/dna/types/generated/_globalTypes';

import { ConfigurationDataType } from 'modules/student/dna/types';

const mapApiConfigurationToConfiguration = (
  apiConfiguration: ApiConfiguration | null,
): ConfigurationDataType => {
  if (apiConfiguration === null) {
    throw new TypeError('"apiConfiguration" should not be null!');
  }

  switch (apiConfiguration.type) {
    case ConfigurationType.allergies:
      return {
        [ConfigurationType.allergies]:
          apiConfiguration.data[ConfigurationType.allergies],
      };
    case ConfigurationType.weeklyEvents:
      return {
        [ConfigurationType.weeklyEvents]:
          apiConfiguration.data[ConfigurationType.weeklyEvents],
      };
    case ConfigurationType.sleep:
      return {
        [ConfigurationType.sleep]:
          apiConfiguration.data[ConfigurationType.sleep],
      };
    default:
      throw new TypeError(
        '"apiConfiguration" should not be as type of "ConfigurationType"!',
      );
  }
};

const mapApiConfigurationToConfigurationList = (
  apiConfigurationList: (ApiConfiguration | null)[] | null,
): ConfigurationDataType[] | undefined => {
  return mapNullableListToList<ApiConfiguration, ConfigurationDataType>(
    apiConfigurationList,
    mapApiConfigurationToConfiguration,
  );
};

const mapConfigurationsArrayOfObjectsToObject = (
  intermediateConfigurations: ConfigurationDataType[] | null | undefined,
): ConfigurationDataType | null => {
  const obj = {};

  intermediateConfigurations?.forEach((conf) => {
    const keys = Object.keys(conf);

    if (keys.length) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      // eslint-disable-next-line prefer-destructuring
      obj[Object.keys(conf)[0]] = Object.values(conf)[0];
    }
  });

  return !Object.keys(obj).length ? null : (obj as ConfigurationDataType);
};

export const mapApiConfigurationListToConfigurationObject = (
  apiConfigurationList: (ApiConfiguration | null)[] | null,
): ConfigurationDataType | null => {
  const configurationList = mapApiConfigurationToConfigurationList(
    apiConfigurationList,
  );

  return mapConfigurationsArrayOfObjectsToObject(configurationList);
};
