import { useCallback } from 'react';

import { OasMemoryError, OasError } from 'core/atoms/errors';

import { gql, useMutation } from 'core/dna/types/apollo';
import {
  AllocateSchedule,
  AllocateScheduleVariables,
} from 'core/dna/types/generated/AllocateSchedule';

import { useLazyGetTermsState } from 'core/memory/apollo/terms/local';

import { mapWeekToApiWeek } from 'modules/planner/dna/functions';

import { GET_ALLOCATION_PLANS_QUERY } from './_gql';

const ALLOCATE_SCHEDULE_MUTATION = gql`
  mutation AllocateSchedule($input: AllocateScheduleInput!) {
    allocateSchedule(input: $input) {
      statusCode
      status
      description
    }
  }
`;

const FILE_NAME = 'use-allocate-schedule';

export const useAllocateSchedule = () => {
  const { getTermState } = useLazyGetTermsState();

  const [allocateSchedule] = useMutation<
    AllocateSchedule,
    AllocateScheduleVariables
  >(ALLOCATE_SCHEDULE_MUTATION);

  const func = useCallback(
    async (input: { scheduleId: string; week: number; year: number }) => {
      const { scheduleId, week, year } = input;
      try {
        const termsState = await getTermState(true);

        const weekNumber = mapWeekToApiWeek({ year, week });
        const result = await allocateSchedule({
          variables: {
            input: {
              allocationData: [
                {
                  published: true,
                  weekNumber,
                },
              ],
              scheduleId,
              termId: termsState!.selected!,
            },
          },
          refetchQueries: [
            {
              query: GET_ALLOCATION_PLANS_QUERY,
              variables: { termId: termsState!.selected! },
            },
          ],
        });

        if (result.errors) {
          throw new OasMemoryError(JSON.stringify(result.errors), {
            title: FILE_NAME,
          });
        }
        return result.data?.allocateSchedule ?? undefined;
      } catch (e: any) {
        if (e instanceof OasError) {
          throw e;
        } else {
          throw OasMemoryError.fromError(e, {
            title: FILE_NAME,
          });
        }
      }
    },
    [allocateSchedule, getTermState],
  );

  return {
    allocateSchedule: func,
  };
};
