import React from 'react';

import { NsSnackbarProvider } from 'core/cells/notistack';

export const SnackbarProvider = ({ children }: any) => {
  return (
    <NsSnackbarProvider maxSnack={3} autoHideDuration={4000}>
      {children}
    </NsSnackbarProvider>
  );
};
