import { useCallback } from 'react';

import { isString } from 'core/atoms/functions';

import { useGetSchedulesSelectedState } from 'modules/planner/memory/apollo/schedules/local';
import { useGetScheduleDescriptions } from 'modules/planner/memory/apollo/schedules/remote';

import { useSetSelectedSchedules } from 'modules/planner/metabolism/schedules';

// const TITLE = 'use-schedule-selector';

export const useScheduleSelector = () => {
  const {
    loading: schedulesLoading,
    error: schedulesError,
    // map: scheduleMap,
    list: schedules,
  } = useGetScheduleDescriptions();

  const {
    data: selectedIds,
    loading: selectedIdsLoading,
    error: errorIdsLoading,
  } = useGetSchedulesSelectedState();

  const { setSelectedSchedules } = useSetSelectedSchedules();

  // const mapValueToScheduleStateInput = useCallback(
  //   (value: string) => {
  //     const s = scheduleMap?.byId[value];
  //     if (!s) {
  //       throw new OasOrganismError(
  //         `handleChange => No schedule with ID: ${value}`,
  //         {
  //           title: TITLE,
  //         },
  //       );
  //     }
  //     return mapScheduleToScheduleStateInput(s);
  //   },
  //   [scheduleMap?.byId],
  // );

  const valueIsString = (value: string | string[]): value is string =>
    isString(value);

  const handleChange = useCallback(
    (value: string | string[]) => {
      setSelectedSchedules(valueIsString(value) ? [value] : value);

      // const schedulesInput: ScheduleStateInput[] = [];
      // if (isString(value)) {
      //   schedulesInput.push(mapValueToScheduleStateInput(value as string));
      // } else {
      //   (value as string[]).forEach((v) => {
      //     schedulesInput.push(mapValueToScheduleStateInput(v));
      //   });
      // }
      // setSchedulesSelectedState({ schedules: schedulesInput });
    },
    [setSelectedSchedules],
  );

  return {
    loading: schedulesLoading || selectedIdsLoading,
    error: schedulesError || errorIdsLoading,
    schedulesData:
      schedules?.map((s) => ({
        text: s.name || 'noname',
        value: s.id,
      })) ?? [],
    selectedIds,
    handleChange,
  };
};
