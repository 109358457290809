import { useRef, memo, useCallback, useState, useMemo } from 'react';
import clsx from 'clsx';
// import PerfectScrollbar from 'perfect-scrollbar';
import { NavLink } from 'react-router-dom';

import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Hidden from '@material-ui/core/Hidden';
import Collapse from '@material-ui/core/Collapse';
import Icon from '@material-ui/core/Icon';

import {
  PARAMS,
  URLS,
  getActiveSidebarRoute,
  filterRoutes,
  OasLanguage,
  useLocation,
  useModuleGoHome,
} from 'core/dna/routing';
import { RouteConfig } from 'core/dna/types/local';
import { useSelectedModuleUpdater } from 'core/dna/modules';
import { Modules } from 'core/dna/types/modules';

import UnsetUrls from 'modules/unset/urls';

import { DashboardNavbarLinks } from 'app/organisms/dashboard-navbar-links';
import { ModuleSelector } from 'app/organisms/module-selector';

import { useStyles } from './sidebar.styles';

let ps: any;

export interface SidebarWrapperProps {
  className?: string;
  headerLinks?: any;
  links?: any;
}

// We've created this component so we can have a ref to the wrapper of the links that appears in our sidebar.
// This was necessary so that we could initialize PerfectScrollbar on the links.
// There might be something with the Hidden component from material-ui, and we didn't have access to
// the links, and couldn't initialize the plugin.
const SidebarWrapper = (props: SidebarWrapperProps) => {
  const { className, headerLinks, links } = props;
  const sidebarWrapper = useRef<any>();

  // TODO: discover does it lead to rerendering
  // useEffect(() => {
  //   if (navigator.platform.indexOf('Win') > -1) {
  //     ps = new PerfectScrollbar(sidebarWrapper.current, {
  //       suppressScrollX: true,
  //       suppressScrollY: false,
  //     });
  //   }
  //   return () => {
  //     if (navigator.platform.indexOf('Win') > -1) {
  //       ps.destroy();
  //     }
  //   };
  // }, []);

  return (
    <div className={className} ref={sidebarWrapper}>
      <ModuleSelector />
      {headerLinks}
      {links}
    </div>
  );
};

type SidebarColor =
  | 'white'
  | 'red'
  | 'orange'
  | 'green'
  | 'blue'
  | 'purple'
  | 'rose';

export interface SidebarProps {
  routes: RouteConfig[]; // PropTypes.arrayOf(PropTypes.object),
  locale: OasLanguage;
  bgColor?: 'white' | 'black' | 'blue'; // PropTypes.oneOf([]),
  rtlActive?: boolean; // PropTypes.bool,
  color?: SidebarColor; // PropTypes.oneOf([]),
  logo?: string; // PropTypes.string,
  logoText?: string; // PropTypes.string,
  image?: string; // PropTypes.string,
  miniActive?: boolean; // PropTypes.bool,
  setMiniActive?: (value: boolean) => void;
  open?: boolean; // PropTypes.bool,
  handleDrawerToggle?: () => void; // PropTypes.func,
}

const defaultProps: Partial<SidebarProps> = {
  bgColor: 'blue',
};

/**
 * this verifies if any of the collapses should be default opened on a rerender of this component
 * for example, on the refresh of the page,
 * while on the src/views/forms/RegularForms.jsx - route /admin/regular-forms
 */
const getCollapseInitialState = (propsRoutes: any[], routes: any[]) => {
  let result = false;
  // eslint-disable-next-line no-restricted-syntax
  for (const route of routes) {
    if (route.collapse && getCollapseInitialState(propsRoutes, route.views)) {
      result = true;
      break;
    } else if (getActiveSidebarRoute(propsRoutes)?.path === route.path) {
      result = true;
      break;
    }
  }
  return result;
};

/**
 * this creates the initial state of this component based on the collapse routes
 * that it gets through this.props.routes
 */
const getCollapseStates = (propsRoutes: any[], routes: any[]) => {
  let initialState = {};
  routes.map((route) => {
    if (route.collapse) {
      initialState = {
        [route.state]: getCollapseInitialState(propsRoutes, route.views),
        ...getCollapseStates(propsRoutes, route.views),
        ...initialState,
      };
    }
    return null;
  });
  return initialState;
};

const SidebarComponent = (props: SidebarProps) => {
  const {
    routes: propsRoutes,
    color,
    rtlActive,
    miniActive,
    setMiniActive,
    logo,
    image,
    logoText,
    bgColor,
    locale,
    open,
    handleDrawerToggle,
  } = props;

  const classes = useStyles();
  const location = useLocation();

  const selectModule = useSelectedModuleUpdater();
  const goHome = useModuleGoHome();

  const mainPanel = useRef<any>(null);

  const sidebarRoutes = useMemo(
    () => filterRoutes(propsRoutes, { fieldName: 'sidebar', fieldValue: true }),
    [propsRoutes],
  );

  const [state, setState] = useState<any>(() => ({
    openAvatar: false,
    // sidebarRoutes: filterRoutes(props.routes, 'sidebar', true),
    ...getCollapseStates(propsRoutes, props.routes ?? []),
  }));

  /** verifies if routeName is the one active (in browser input) */
  const activeRoute = useCallback(
    (pathname: string) => {
      const activeRoute = getActiveSidebarRoute(propsRoutes);
      return activeRoute?.path === pathname ? 'active' : '';
    },
    [propsRoutes],
  );

  // const openCollapse = useCallback(
  //   (collapse: string) => {
  //     const st: any = {};
  //     st[collapse] = !state[collapse];
  //     setState(st);
  //   },
  //   [state],
  // );

  /** this function creates the links and collapses that appear in the sidebar (left menu) */
  const createLinks = useCallback(
    (routes: any[]) => {
      // logger.debugArray(routes, { title: 'createLinks', logger: 'sidebar' });
      return routes.map((route: any, key) => {
        if (route.redirect) {
          return null;
        }
        if (route.collapse) {
          const st: any = {};
          st[route['state']] = !state[route.state];

          const navLinkClasses = clsx(
            classes.itemLink,
            getCollapseInitialState(propsRoutes, route.views)
              ? classes.collapseActive
              : '',
          );

          const itemText = clsx(
            classes.itemText,
            miniActive ? classes.itemTextMini : '',
            rtlActive && miniActive ? classes.itemTextMiniRTL : '',
            rtlActive ? classes.itemTextRTL : '',
          );

          const collapseItemText = clsx(
            classes.collapseItemText,
            miniActive ? classes.collapseItemTextMini : '',
            rtlActive && miniActive ? classes.collapseItemTextMiniRTL : '',
            rtlActive ? classes.collapseItemTextRTL : '',
          );

          const itemIcon = clsx(
            classes.itemIcon,
            rtlActive ? classes.itemIconRTL : '',
          );

          const caret = clsx(classes.caret, rtlActive ? classes.caretRTL : '');

          const collapseItemMini = clsx(
            classes.collapseItemMini,
            rtlActive ? classes.collapseItemMiniRTL : '',
          );

          return (
            <ListItem
              key={`${route.path}-${key}`}
              className={clsx(
                route.icon !== undefined ? classes.item : '',
                route.icon === undefined ? classes.collapseItem : '',
              )}
            >
              <NavLink
                to="#"
                className={navLinkClasses}
                onClick={(e) => {
                  e.preventDefault();
                  setState(st);
                }}
              >
                {route.icon !== undefined ? (
                  typeof route.icon === 'string' ? (
                    <Icon className={itemIcon}>{route.icon}</Icon>
                  ) : (
                    <route.icon className={itemIcon} />
                  )
                ) : (
                  <span className={collapseItemMini}>
                    {rtlActive ? route.rtlMini : route.mini}
                  </span>
                )}
                <ListItemText
                  primary={rtlActive ? route.rtlName : route.name}
                  secondary={
                    <b
                      className={clsx(
                        caret,
                        state[route.state] ? classes.caretActive : '',
                      )}
                    />
                  }
                  disableTypography={true}
                  className={clsx(
                    { [itemText]: route.icon !== undefined },
                    { [collapseItemText]: route.icon === undefined },
                  )}
                />
              </NavLink>
              <Collapse in={state[route.state]} unmountOnExit>
                <List className={clsx(classes.list, classes.collapseList)}>
                  {createLinks(route.views)}
                </List>
              </Collapse>
            </ListItem>
          );
        }

        const innerNavLinkClasses = clsx(
          classes.collapseItemLink,
          activeRoute(route.path) ? (color ? classes[color] : '') : '',
        );

        const collapseItemMini = clsx(
          classes.collapseItemMini,
          rtlActive ? classes.collapseItemMiniRTL : '',
        );

        const navLinkClasses = clsx(
          classes.itemLink,
          activeRoute(route.path) ? (color ? classes[color] : '') : '',
        );

        const itemText = clsx(
          classes.itemText,
          miniActive ? classes.itemTextMini : '',
          rtlActive && miniActive ? classes.itemTextMiniRTL : '',
          rtlActive ? classes.itemTextRTL : '',
        );

        const collapseItemText = clsx(
          classes.collapseItemText,
          miniActive ? classes.collapseItemTextMini : '',
          rtlActive && miniActive ? classes.collapseItemTextMiniRTL : '',
          rtlActive ? classes.collapseItemTextRTL : '',
        );

        const itemIcon = clsx(
          classes.itemIcon,
          rtlActive ? classes.itemIconRTL : '',
        );

        return (
          <ListItem
            key={`${route.path}-${key}`}
            className={clsx(
              route.icon !== undefined ? classes.item : '',
              route.icon === undefined ? classes.collapseItem : '',
            )}
          >
            <NavLink
              to={
                route.url ??
                route.path?.replace?.(PARAMS.Locale.path, URLS.defaultLocale) ??
                '#'
              }
              className={clsx(
                { [navLinkClasses]: route.icon !== undefined },
                { [innerNavLinkClasses]: route.icon === undefined },
              )}
            >
              {route.icon !== undefined ? (
                typeof route.icon === 'string' ? (
                  <Icon className={itemIcon}>{route.icon}</Icon>
                ) : (
                  <route.icon className={itemIcon} />
                )
              ) : (
                <span className={collapseItemMini}>
                  {rtlActive ? route.rtlMini : route.mini}
                </span>
              )}
              <ListItemText
                primary={rtlActive ? route.rtlName : route.name}
                disableTypography={true}
                className={clsx(
                  { [itemText]: route.icon !== undefined },
                  { [collapseItemText]: route.icon === undefined },
                )}
              />
            </NavLink>
          </ListItem>
        );
      });
    },
    [activeRoute, classes, color, miniActive, propsRoutes, rtlActive, state],
  );

  // const onMouseOverDrawer = useCallback(() => setMiniActive?.(false), [
  //   setMiniActive,
  // ]);
  //
  // const onMouseOutDrawer = useCallback(() => setMiniActive?.(true), [
  //   setMiniActive,
  // ]);

  const Links = useCallback(
    () => <List className={classes.list}>{createLinks(sidebarRoutes)}</List>,
    [classes.list, createLinks, sidebarRoutes],
  );

  const logoNormal = clsx(
    classes.logoNormal,
    miniActive ? classes.logoNormalSidebarMini : '',
    rtlActive && miniActive ? classes.logoNormalSidebarMiniRTL : '',
    rtlActive ? classes.logoNormalRTL : '',
  );

  const logoMini = clsx(classes.logoMini, rtlActive ? classes.logoMiniRTL : '');

  const logoClasses = clsx(
    classes.logo,
    bgColor === 'white' ? classes.whiteAfter : '',
  );

  const Brand = useCallback(
    () => (
      <div className={logoClasses}>
        <NavLink to={URLS.orgWelcome(locale)} className={logoMini}>
          <img src={logo} alt="logo" className={classes.img} />
        </NavLink>
        <NavLink to={URLS.orgWelcome(locale)} className={logoNormal}>
          {logoText}
        </NavLink>
      </div>
    ),
    [classes.img, locale, logo, logoClasses, logoMini, logoNormal, logoText],
  );

  const dashboardClasses = clsx(
    classes.singleLink,
    bgColor === 'white' ? classes.whiteAfter : '',
  );

  const DashboardLink = useCallback(
    () => (
      <List className={clsx(dashboardClasses)} style={{ marginBottom: 15 }}>
        <NavLink
          onClick={(event) => {
            event.preventDefault();
            selectModule(Modules.unset);
            goHome(Modules.unset);
          }}
          to=""
          className={clsx(
            classes.collapseItemLink,
            location.pathname === UnsetUrls.home(locale)
              ? color
                ? classes[color]
                : ''
              : '',
          )}
        >
          <span className={classes.collapseItemMini}>DB</span>
          <ListItemText
            primary="Dashboard"
            disableTypography
            className={clsx(
              { [classes.collapseItemText]: true },
              { [classes.collapseItemTextMini]: miniActive },
            )}
          />
        </NavLink>
      </List>
    ),
    [
      dashboardClasses,
      classes,
      location.pathname,
      locale,
      color,
      miniActive,
      selectModule,
      goHome,
    ],
  );

  const drawerPaper = clsx(
    classes.drawerPaper,
    miniActive ? classes.drawerPaperMini : '',
    rtlActive ? classes.drawerPaperRTL : '',
  );

  const sidebarWrapper = clsx(
    classes.sidebarWrapper,
    miniActive ? classes.drawerPaperMini : '',
    navigator.platform.indexOf('Win') > -1
      ? classes.sidebarWrapperWithPerfectScrollbar
      : '',
  );

  return (
    <div ref={mainPanel}>
      <Hidden mdUp implementation="css">
        <Drawer
          variant="temporary"
          anchor={rtlActive ? 'left' : 'right'}
          open={open}
          classes={{
            paper: clsx(drawerPaper, (classes as any)[`${bgColor}Background`]),
          }}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
        >
          <Brand />
          <SidebarWrapper
            className={sidebarWrapper}
            headerLinks={<DashboardNavbarLinks rtlActive={rtlActive} />}
            links={<Links />}
          />
          {image !== undefined ? (
            <div
              className={classes.background}
              style={{ backgroundImage: `url(${image})` }}
            />
          ) : null}
        </Drawer>
      </Hidden>
      <Hidden smDown implementation="css">
        <Drawer
          // onMouseOver={onMouseOverDrawer}
          // onMouseOut={onMouseOutDrawer}
          anchor={rtlActive ? 'right' : 'left'}
          variant="permanent"
          open
          classes={{
            paper: clsx(drawerPaper, (classes as any)[`${bgColor}Background`]),
          }}
        >
          <Brand />
          <DashboardLink />
          <SidebarWrapper className={sidebarWrapper} links={<Links />} />
          {image !== undefined ? (
            <div
              className={classes.background}
              style={{ backgroundImage: `url(${image})` }}
            />
          ) : null}
        </Drawer>
      </Hidden>
    </div>
  );
};

SidebarComponent.defaultProps = defaultProps;

export const Sidebar = memo(SidebarComponent);

Sidebar.displayName = 'Sidebar Component';
