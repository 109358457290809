import { AnyMap, TextItem } from 'core/atoms/types';
import { CalendarEvent, ScheduleWorklistItem } from 'core/dna/types';

export enum ScheduleStateStatus {
  Idle = 'Idle',
  NotStarted = 'NotStarted',
  NotStopped = 'NotStopped',
  Running = 'Running',
  Starting = 'Starting',
  Stopping = 'Stopping',
}

export interface ScheduleDataState {
  id: string;
  name: string | null;
  status: ScheduleStateStatus | null;
}

export interface ScheduleState {
  id: string;
  name: string;
  status: ScheduleStateStatus;
}

export interface ScheduleDataStateMap {
  [id: string]: ScheduleDataState;
}

export interface SchedulesState {
  selected: string[];
  subscribed: string[];
  byId: ScheduleDataStateMap;
}

export interface ScheduleStateInput {
  id: string;
  name?: string | null;
  status?: ScheduleStateStatus | null;
}

export interface SetSchedulesStateInput {
  selected?: string[] | null;
  subscribed?: string[] | null;
  values?: ScheduleStateInput[] | null;
}

export interface AddSchedulesSubscribedStateInput {
  schedule: ScheduleStateInput;
}

export interface TimePlanState {
  scheduleId: string;
  calendarEvents: CalendarEvent[];
  worklist: ScheduleWorklistItem[];
}

export type AssignmentState = 'unassigned' | 'in progress' | 'all set';

export interface StudentItem extends TextItem {
  chapter?: string;
}
export interface UnionStoreItem {
  id: string;
  chapters: TextItem[];
  students: StudentItem[];
  selectedChapter?: string;
  state: AssignmentState;
}

export interface ChapterAssignments {
  id: string;
  roleIds: string[];
}

export interface StudentsGroupsState {
  unions: AnyMap<UnionStoreItem>;
  status: 'empty' | 'initialized';
  assigning: boolean;
  changedFromLastAssignment: boolean;
  assignSuccess: boolean | undefined;
  assignError: boolean | undefined;
  prevChapterAssignments?: ChapterAssignments[];
  assignmentsStatus: AssignmentState;
}
