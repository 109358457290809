import { useMemo } from 'react';

import { gql, useQuery } from 'core/dna/types/apollo';
import {
  GetSubstituteInvitationDetails,
  GetSubstituteInvitationDetailsVariables,
} from 'core/dna/types/generated/GetSubstituteInvitationDetails';

import { mapApiSubstituteJobInvitationDetailsToSubstituteInvitationDetails } from 'modules/substitute/dna/functions';

export const GET_SUBSTITUTE_INVITATION_DETAILS_QUERY = gql`
  query GetSubstituteInvitationDetails($input: SubstituteJobInvitationInput!) {
    SubstituteJobInvitation(input: $input) {
      id
      to {
        id
        firstName
        lastName
      }
      job {
        deadline {
          formatted
        }
        description
        state
        tasks {
          id
          subject
          comment
          myResponse {
            response
          }
          assignedTo {
            id
            firstName
            lastName
          }
          state
          date {
            formatted
          }
          timeFrom {
            formatted
          }
          timeTo {
            formatted
          }
        }
      }
    }
  }
`;

export const useGetSubstituteInvitationDetails = (inviteId?: string) => {
  const { data: apiData, error, loading } = useQuery<
    GetSubstituteInvitationDetails,
    GetSubstituteInvitationDetailsVariables
  >(GET_SUBSTITUTE_INVITATION_DETAILS_QUERY, {
    skip: !inviteId,
    variables: { input: { inviteId: inviteId ?? '' } },
  });

  const data = useMemo(() => {
    if (!loading && !error && apiData?.SubstituteJobInvitation) {
      return mapApiSubstituteJobInvitationDetailsToSubstituteInvitationDetails(
        apiData.SubstituteJobInvitation,
      );
    }
  }, [apiData, error, loading]);

  return { data, error, loading };
};
