import { useMemo } from 'react';

import { useTranslations } from 'core/dna/translations';

import { ClassTableItem } from 'modules/planner/dna/types/class';
import { mapClassToClassTableItem } from 'modules/planner/dna/functions';

import { useClasses } from 'modules/planner/metabolism/use-classes';

export const useClassesTableRead = () => {
  const { texts } = useTranslations();
  const { classData, classesLoading } = useClasses();

  const columns = useMemo(
    () => [
      {
        Header: texts.planner.common.names.one,
        accessor: 'name',
      },
      {
        Header: texts.planner.common.breaks.many,
        accessor: 'availabilityDefined',
        disableFilters: true,
      },
      {
        Header: texts.planner.common.groups.many,
        accessor: 'chaptersCount',
        disableFilters: true,
      },
      {
        Header: texts.oasCommon.students.many,
        accessor: 'studentsCount',
        disableFilters: true,
      },
      {
        Header: texts.planner.common.actions.many,
        accessor: 'actions',
      },
    ],
    [texts],
  );

  const data = useMemo<ClassTableItem[]>(
    () =>
      classData
        .map(mapClassToClassTableItem)
        .sort((a, b) => (a.name < b.name ? 1 : -1)),
    [classData],
  );

  return {
    title: texts.planner.common.classes,
    columns,
    loading: classesLoading,
    data,
  };
};
