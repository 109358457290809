import React, { Fragment, useState } from 'react';
import { List, Typography } from '@material-ui/core';
import { Delete } from '@material-ui/icons';

import { ListItem } from 'core/cells/list-item';
import { ListItemText } from 'core/cells/list-item-text';
import { ListItemSecondaryAction } from 'core/cells/list-item-secondary-action';
import { IconButton } from 'core/cells/icon-button';
import { Divider } from 'core/cells/divider';
import { TextField } from 'core/cells/text-field';
import { useTranslations } from 'core/dna/translations';
import { makeStyles } from 'core/atoms/styles';

import { Button } from 'templates/mdp/components/custom-buttons';

import { studentProfileStyles } from './student-profile.styles';

const useStyles = makeStyles(studentProfileStyles);

interface SleepingHoursProps {
  sleep?: Array<string[]>;
  onAdd: (sleep: string[]) => void;
  onRemove: (index: number) => void;
  loading: boolean;
}

export const SleepingHours = ({
  sleep,
  onAdd,
  onRemove,
  loading,
}: SleepingHoursProps) => {
  const classes = useStyles();
  const { texts } = useTranslations();

  const [sleepingFrom, setSleepingFrom] = useState('');
  const [sleepingTo, setSleepingTo] = useState('');

  const handleAdd = () => {
    if (!sleepingFrom || !sleepingTo) return;
    onAdd([sleepingFrom, sleepingTo]);
    setSleepingFrom('');
    setSleepingTo('');
  };

  return (
    <div className={classes.box}>
      <div className={classes.groupTitle}>
        <Typography variant="h5">{texts.studentModule.sleepHours}:</Typography>
      </div>
      <div className={classes.groupInput}>
        {sleep && !!sleep.length && (
          <List className={classes.list}>
            {sleep.map((hours, index) => (
              <Fragment key={`sleep-${index}`}>
                <ListItem>
                  <ListItemText secondary={`${hours[0]} - ${hours[1]}`} />
                  <ListItemSecondaryAction>
                    <IconButton
                      edge="end"
                      aria-label="delete"
                      onClick={() => onRemove(index)}
                    >
                      <Delete />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
                <Divider component="li" />
              </Fragment>
            ))}
          </List>
        )}
        <div className={classes.pickerBox}>
          <TextField
            id="time-from"
            label={texts.planner.common.from}
            type="time"
            margin="dense"
            className={classes.picker}
            InputLabelProps={{ shrink: true }}
            value={sleepingFrom}
            onChange={(e) => {
              if (!e.target.value) return;
              setSleepingFrom(e.target.value);
            }}
          />
          <TextField
            id="time-to"
            label={texts.planner.common.to}
            type="time"
            margin="dense"
            className={classes.picker}
            InputLabelProps={{ shrink: true }}
            value={sleepingTo}
            disabled={!sleepingFrom}
            onChange={(e) => {
              if (!e.target.value) return;
              setSleepingTo(e.target.value);
            }}
          />
        </div>
        <Button
          className={classes.addBtn}
          size="sm"
          color="success"
          disabled={!sleepingFrom || !sleepingTo || loading}
          onClick={() => handleAdd()}
        >
          {texts.planner.common.add}
        </Button>
      </div>
    </div>
  );
};
