import { useCallback } from 'react';

import { OasMemoryError, OasError } from 'core/atoms/errors';

import { useMutation } from 'core/dna/types/apollo';
import {
  CreateTerm,
  CreateTermVariables,
} from 'core/dna/types/generated/CreateTerm';
import { CreateTermInput } from 'core/dna/types/generated/_globalTypes';
import { useSelectedOrganizationState } from 'core/dna/organizations';

import { GET_ORGANIZATION_QUERY } from 'core/memory/apollo/organizations/remote/get-organization';

import { CREATE_TERM_MUTATION } from './_gql';

const FILE_NAME = 'use-create-term';

export const useCreateTerm = () => {
  const selected = useSelectedOrganizationState();

  const [createTerm, { loading, error }] = useMutation<
    CreateTerm,
    CreateTermVariables
  >(CREATE_TERM_MUTATION);

  const func = useCallback(
    async (input: CreateTermInput) => {
      try {
        const result = await createTerm({
          variables: {
            input: {
              ...input,
            },
          },
          refetchQueries: [
            {
              query: GET_ORGANIZATION_QUERY,
              variables: { organizationId: selected!.id },
            },
          ],
        });

        if (result.errors) {
          throw new OasMemoryError(JSON.stringify(result.errors), {
            title: FILE_NAME,
          });
        }
        return result.data?.createTerm ?? undefined;
      } catch (e: any) {
        if (e instanceof OasError) {
          throw e;
        } else {
          throw OasMemoryError.fromError(e, {
            title: FILE_NAME,
          });
        }
      }
    },
    [createTerm, selected],
  );

  return {
    createTerm: func,
    loading,
    error,
  };
};
