import { GetSyncStudents_SyncStudent_groups as ApiSyncStudentGroupData } from 'core/dna/types/generated/GetSyncStudents';
import { GetSyncGroups_SyncGroup as ApiSyncGroupsGroupData } from 'core/dna/types/generated/GetSyncGroups';
import { mapNullableListToList } from 'core/dna/functions';

import { SyncGroupData } from 'modules/planner/dna/types/sync-group-data';

export const mapApiSyncGroupDataListToSyncGroupDataList = (
  apiSyncGroupData: (ApiSyncStudentGroupData | null)[] | null,
): SyncGroupData[] | undefined => {
  return mapNullableListToList<ApiSyncStudentGroupData, SyncGroupData>(
    apiSyncGroupData,
    mapApiSyncGroupDataToSyncGroupData,
  );
};

export const mapApiSyncGroupDataToSyncGroupData = (
  apiSyncGroupData: ApiSyncStudentGroupData | ApiSyncGroupsGroupData | null,
): SyncGroupData | undefined => {
  if (!apiSyncGroupData) {
    return;
  }

  const { id, name, short, description } = apiSyncGroupData;

  return {
    id: id ?? '',
    name: name ?? 'NAME',
    short: short ?? '',
    description: description ?? '',
  };
};
