import { useCallback } from 'react';
import { Moment } from 'moment';

export interface DayProps {
  day: Moment | null;
  classes: string;
  onHover: (day: any, classes: any) => void;
  onClick?: (day: Moment, classes: any) => void;
}

const defaultProps: Partial<DayProps> = {
  classes: '',
};

export const Day = (props: DayProps) => {
  const { classes, onClick, onHover, day } = props;

  const handleClick = useCallback(() => {
    if (!day || !onClick) {
      return;
    }
    onClick(day, classes);
  }, [classes, day, onClick]);

  const handleHover = useCallback(() => {
    onHover(day, '');
  }, [day, onHover]);

  return (
    <td onClick={handleClick} onMouseEnter={handleHover} className={classes}>
      <span className="day-number">{day ? day.date() : ''}</span>
    </td>
  );
};

Day.defaultProps = defaultProps;
