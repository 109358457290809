import { useCallback } from 'react';

import { OasMemoryError } from 'core/atoms/errors';
import { OasError } from 'core/atoms/errors/oas-error';

import { useMutation, ApolloError } from 'core/dna/types/apollo';

import { EDIT_ROOM_MUTATION } from './edit-room.mutation';

export interface EditRoomInput {
  roomId: string;
  alias?: string;
  name?: string;
  note?: string;
}

export interface EditRoomResult {
  editRoom: (input: EditRoomInput) => Promise<any>;
  loading: boolean;
  error?: ApolloError;
}

const TITLE = 'Edit Room';

export const useEditRoom = (): EditRoomResult => {
  const [editRoom, { loading, error }] = useMutation(EDIT_ROOM_MUTATION);

  const func = useCallback(
    (input: EditRoomInput) => {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise<any>(async (resolve, reject) => {
        try {
          const editRoomResult = await editRoom({
            variables: { input },
          });

          if (editRoomResult.data && !editRoomResult.errors) {
            resolve(editRoomResult);
          } else {
            throw new OasMemoryError('Edit room error!', { title: TITLE });
          }
        } catch (e: any) {
          if (e instanceof OasError) {
            reject(e);
          } else {
            reject(OasMemoryError.fromError(e, { title: TITLE }));
          }
        }
      });
    },
    [editRoom],
  );

  return {
    editRoom: func,
    loading,
    error,
  };
};
