import {
  default as MuiChip,
  ChipProps as MuiChipProps,
} from '@mui/material/Chip';

import { mdStyled } from 'core/atoms/themes';

export type ChipProps = MuiChipProps;

export const Chip = mdStyled(MuiChip)<MuiChipProps>(({ theme, ...props }) => ({
  '&.MuiButtonBase-root': {
    borderRadius: theme.spacing(2),

    ...((!props.color || props.color === 'default') && {
      backgroundColor: theme.palette.action.selected,
      color: theme.palette.text.primary,
    }),

    ...(props.color === 'primary' && {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    }),

    ...(props.color === 'secondary' && {
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.secondary.contrastText,
    }),

    ...(props.color === 'info' && {
      backgroundColor: theme.palette.info.main,
      color: theme.palette.info.contrastText,
    }),

    ...(props.color === 'success' && {
      backgroundColor: theme.palette.success.main,
      color: theme.palette.success.contrastText,
    }),

    ...(props.color === 'warning' && {
      backgroundColor: theme.palette.warning.main,
      color: theme.palette.warning.contrastText,
    }),

    ...(props.color === 'error' && {
      backgroundColor: theme.palette.error.main,
      color: theme.palette.error.contrastText,
    }),
  },
}));
