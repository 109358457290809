import { GetSyncStudents_SyncStudent as ApiSyncStudent } from 'core/dna/types/generated/GetSyncStudents';
import { mapNullableListToList } from 'core/dna/functions';

import { SyncStudent } from 'modules/planner/dna/types/sync-student';
import { mapApiSyncGroupDataListToSyncGroupDataList } from 'modules/planner/dna/functions/map-ApiSyncGroupData-to-SyncGroupData';

export const mapApiSyncStudentListToSyncStudentList = (
  apiSyncStudents: (ApiSyncStudent | null)[] | null,
): SyncStudent[] | undefined => {
  return mapNullableListToList<ApiSyncStudent, SyncStudent>(
    apiSyncStudents,
    mapApiSyncStudentToSyncStudent,
  );
};

export const mapApiSyncStudentToSyncStudent = (
  apiSyncStudent: ApiSyncStudent | null,
): SyncStudent | undefined => {
  if (!apiSyncStudent) {
    return;
  }

  const { id, tuid, firstName, lastName, groups } = apiSyncStudent;

  return {
    id,
    tuid: tuid ?? '',
    firstName: firstName ?? 'NAME',
    lastName: lastName ?? 'LAST-NAME',
    groups: mapApiSyncGroupDataListToSyncGroupDataList(groups) ?? [],
  };
};
