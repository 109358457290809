import { useCallback } from 'react';

import { Form, Field, FormController } from 'core/cells/forms';
import { FormTextField } from 'core/cells/form-text-field';
import { FlexBox } from 'core/cells/box';

import { useTranslations } from 'core/dna/translations';

import { Button } from 'templates/mdp/components/custom-buttons';

import { WriteModeEnum } from 'core/dna/types/write-mode';
import { WriteTableModal } from 'core/organisms/write-modal';
import { emailRegex, shortNorPhoneRegex } from 'core/atoms/reg-ex';

import { useTeachersTableWrite } from './use-teachers-table-write';

interface Values {
  firstName: string;
  lastName: string;
  phone?: string;
  email?: string;
  tags?: string;
}

interface TeacherFormProps {
  initial?: Partial<Values> & { id?: string };
  open?: boolean;
  setOpen?: (open: boolean) => void;
}

export const TeacherEditor = (props: TeacherFormProps) => {
  const { open, setOpen, initial } = props;

  const { onRowAdd, onRowUpdate } = useTeachersTableWrite();

  const { lang, texts } = useTranslations();

  const onClose = useCallback(() => {
    setOpen?.(false);
  }, [setOpen]);

  return (
    <WriteTableModal
      isShow={open ?? false}
      onClose={onClose}
      writeMode={initial ? WriteModeEnum.update : WriteModeEnum.create}
      title={
        initial?.id ? texts.substitute.editTeacher : texts.substitute.addTeacher
      }
    >
      <FormController
        initialValues={{
          firstName: initial?.firstName,
          lastName: initial?.lastName,
          phone: initial?.phone,
          email: initial?.email,
          tags: initial?.tags,
        }}
        validate={(values) => {
          const errors: Partial<Values> = {};
          if (!values.firstName) {
            errors.firstName = texts.oasCommon.validation.required;
          }
          if (!values.lastName) {
            errors.lastName = texts.oasCommon.validation.required;
          }
          if (!values.phone) {
            errors.phone = texts.oasCommon.validation.required;
          } else if (!shortNorPhoneRegex.test(values.phone)) {
            errors.phone = texts.oasCommon.validation.norwegianPhone;
          }

          if (!values.email) {
            errors.email = texts.oasCommon.validation.required;
          } else if (!emailRegex.test(values.email)) {
            errors.email = texts.oasCommon.validation.email;
          }

          return errors;
        }}
        onSubmit={async (values) => {
          setOpen?.(false);
          initial?.id
            ? await onRowUpdate({
                id: initial.id,
                firstName: values.firstName ?? '',
                lastName: values.lastName ?? '',
                phone: values.phone ?? '',
                email: values.email,
                tags: values.tags,
              })
            : await onRowAdd({
                firstName: values.firstName ?? '',
                lastName: values.lastName ?? '',
                phone: values.phone,
                email: values.email,
                tags: values.tags,
              });
        }}
      >
        {({ submitForm, isSubmitting }) => (
          <Form>
            <Field
              component={FormTextField}
              name="firstName"
              type="text"
              label={lang.common.firstName}
              disabled={isSubmitting}
              fullWidth
            />
            <Field
              component={FormTextField}
              name="lastName"
              type="text"
              label={lang.common.lastName}
              disabled={isSubmitting}
              fullWidth
            />
            <Field
              component={FormTextField}
              name="phone"
              type="text"
              label={texts.oasCommon.phone}
              disabled={isSubmitting}
              fullWidth
            />
            <Field
              component={FormTextField}
              name="email"
              type="text"
              label={texts.oasCommon.email}
              disabled={isSubmitting}
              fullWidth
            />
            <Field
              component={FormTextField}
              name="tags"
              type="text"
              label={texts.oasCommon.tags}
              disabled={isSubmitting}
              fullWidth
            />
            <FlexBox justifyContent="flex-end">
              <Button
                color="secondary"
                disabled={isSubmitting}
                onClick={submitForm}
              >
                {initial?.id ? lang.common.save : lang.common.add}
              </Button>
              <Button onClick={onClose} color="secondary" simple>
                {lang.common.close}
              </Button>
            </FlexBox>
          </Form>
        )}
      </FormController>
    </WriteTableModal>
  );
};
