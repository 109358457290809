import { useMemo } from 'react';

import { Absence, ShortDataWithAbsences } from 'modules/student/dna/types';
import {
  mapAbsenceListToAbsenceTotal,
  mapStudentListToGroupList,
} from 'modules/reports/dna/functions';
import { GroupWithTotalAbsences } from 'modules/reports/dna/types';

export const useGroupsAbsencesTotal = (
  data: ShortDataWithAbsences[] | undefined,
) => {
  const total = useMemo<GroupWithTotalAbsences[]>(() => {
    if (!data) {
      return [];
    }

    const groups = mapStudentListToGroupList(data);
    const groupsWithStudents = groups.map((group) => ({
      ...group,
      students: data.filter((s) =>
        s.details.groups?.some((g) => group.id === g.id),
      ),
    }));

    const mapStudentsWithAbsencesToAbsences = (
      students: ShortDataWithAbsences[],
    ): Absence[] => {
      const studentsWithAbsences = students.filter((s) => !!s.absences?.length);

      return studentsWithAbsences.map((s) => s.absences!).flat();
    };

    const groupsWithAbsences = groupsWithStudents.map((group) => ({
      ...group,
      absences: mapStudentsWithAbsencesToAbsences(group.students),
    }));

    return groupsWithAbsences.map((group) => ({
      id: group.id,
      name: group.name,
      total: mapAbsenceListToAbsenceTotal(group.absences),
    }));
  }, [data]);

  return {
    total,
  };
};
