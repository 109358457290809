import { useCallback } from 'react';

import { OasMemoryError, OasError } from 'core/atoms/errors';

import { gql, useMutation } from 'core/dna/types/apollo';
import { SubstituteTeacherState } from 'core/dna/types/generated/_globalTypes';
import {
  InactivateSubstituteTeacher,
  InactivateSubstituteTeacherVariables,
} from 'core/dna/types/generated/InactivateSubstituteTeacher';

import { GET_SUBSTITUTE_TEACHERS_QUERY } from '../get-substitute-teachers';

export const INACTIVATE_SUBSTITUTE_TEACHER_MUTATION = gql`
  mutation InactivateSubstituteTeacher($input: UpdateSubstituteTeacherInput!) {
    updateSubstituteTeacher(input: $input) {
      id
    }
  }
`;

const TITLE = 'use-delete-substitute-teacher';

export const useInactivateSubstituteTeacher = () => {
  const [inactivateSubstituteTeacher] = useMutation<
    InactivateSubstituteTeacher,
    InactivateSubstituteTeacherVariables
  >(INACTIVATE_SUBSTITUTE_TEACHER_MUTATION);

  const func = useCallback(
    async (
      id: string,
      firstName: string,
      lastName: string,
      phone?: string,
      email?: string,
      tags?: string,
    ) => {
      try {
        const result = await inactivateSubstituteTeacher({
          variables: {
            input: {
              substituteTeacherId: id,
              firstName,
              lastName,
              mail: email,
              msisdn: phone,
              tags: tags?.split(','),
              state: SubstituteTeacherState.inactive,
            },
          },
          refetchQueries: [
            {
              query: GET_SUBSTITUTE_TEACHERS_QUERY,
            },
          ],
        });

        if (result.errors) {
          throw new OasMemoryError(JSON.stringify(result.errors), {
            title: TITLE,
          });
        }
        return result.data?.updateSubstituteTeacher ?? undefined;
      } catch (e) {
        if (e instanceof OasError) {
          throw e;
        } else {
          throw OasMemoryError.fromError(e, {
            title: TITLE,
          });
        }
      }
    },
    [inactivateSubstituteTeacher],
  );

  return {
    inactivateSubstituteTeacher: func,
  };
};
