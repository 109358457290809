import React from 'react';

import { Typography } from 'core/cells/typography';

export interface PageTitleProps {
  children: any;
  className?: string;
}

export const PageTitle = (props: PageTitleProps) => {
  return (
    <Typography variant="h5" className={props.className}>
      {props.children}
    </Typography>
  );
};
