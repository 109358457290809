import { createContext } from 'react';

import { AuthStatus } from 'core/atoms/errors';

import { AuthIdentity } from './types';

export interface AuthContextProps {
  setTokens: (input: { accessToken: string; sessionToken: string }) => void;
  isAccessTokenExpired: () => boolean;
  isSessionTokenExpired: () => boolean;
  removeAccessToken: () => void;
  removeTokens: () => void;
  lock: () => Promise<AuthStatus>;
  getIdentity: () => AuthIdentity | null;
  getHasPinCode: () => boolean | null;
  login?: (transient?: string) => Promise<any>;
  authorize?: (tgt: string) => Promise<any>;
  logout?: () => Promise<any>;
  refresh: (
    input: {
      accessToken: string | null;
      sessionToken: string | null;
    },
    startup?: boolean,
  ) => void;
  ping: string;
  reload: () => void;
  doOnboard: (accessToken: string) => Promise<void>;
}

export const AuthContext = createContext<AuthContextProps>({
  ping: '',
  getIdentity: () => {
    throw 'not implemented';
  },
  getHasPinCode: () => {
    throw 'not implemented';
  },
  setTokens: () => {
    throw 'not implemented';
  },
  isAccessTokenExpired: () => {
    throw 'not implemented';
  },
  isSessionTokenExpired: () => {
    throw 'not implemented';
  },
  removeAccessToken: () => {
    throw 'not implemented';
  },
  removeTokens: () => {
    throw 'not implemented';
  },
  lock: () => {
    throw 'not implemented';
  },
  refresh: () => {
    throw 'not implemented';
  },
  reload: () => {
    throw 'not implemented';
  },
  doOnboard: () => {
    throw 'not implemented';
  },
});

AuthContext.displayName = 'Auth Context';
