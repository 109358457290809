import { useMemo } from 'react';

import { gql, useQuery } from 'core/dna/types/apollo';
import { GetSelectedClassState } from 'core/dna/types/generated/GetSelectedClassState';

const GET_SELECTED_CLASS_STATE_QUERY = gql`
  query GetSelectedClassState {
    selectedClassState @client
  }
`;

export const useGetSelectedClassState = () => {
  const { data, loading, error } = useQuery<GetSelectedClassState>(
    GET_SELECTED_CLASS_STATE_QUERY,
  );

  const selectedClassState = useMemo<string | undefined>(() => {
    if (!loading && !error && data) {
      return data.selectedClassState ?? undefined;
    }
  }, [loading, error, data]);

  return {
    selectedClassState,
    loading,
    error,
  };
};
