import { useMemo, useCallback } from 'react';

import { makeStyles, createStyles, Theme } from 'core/atoms/styles';
import { tick } from 'core/atoms/date-time';

import { FlexBox } from 'core/cells/box';
import { Tooltip } from 'core/cells/tooltip';
import { IconButton } from 'core/cells/icon-button';
import { Link } from 'core/cells/link';
import { Typography } from 'core/cells/typography';
import { SyncIcon } from 'core/cells/oas-icons';

import { useLocale } from 'core/dna/routing';
import { useTranslations } from 'core/dna/translations';

import { ScheduleTableItem } from 'modules/planner/dna/types/schedule';

import { useShareSchedule } from 'modules/planner/memory/apollo/schedules/remote';

import PlannerUrls from 'modules/planner/routing/urls';

import { ColPageLink } from './col-page-link';

export interface ColSharedScheduleProps {
  item: ScheduleTableItem;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    ml: {
      marginLeft: theme.spacing(0.5),
    },
  }),
);

export const ColSharedSchedule = (props: ColSharedScheduleProps) => {
  const classes = useStyles();
  const { item } = props;

  const { lang } = useTranslations();
  const locale = useLocale();

  const { shareSchedule } = useShareSchedule();

  const handleShareSchedule = useCallback(() => {
    shareSchedule(item.id).then();
  }, [item.id, shareSchedule]);

  return useMemo(() => {
    const { shareCode, shareCodeUpdatedAt, timeSlotsUpdatedAt } = item;
    if (timeSlotsUpdatedAt) {
      if (shareCode && shareCodeUpdatedAt) {
        const shareIsOutdated = tick(shareCodeUpdatedAt).isBefore(
          timeSlotsUpdatedAt,
        );

        const text = shareIsOutdated
          ? `${
              lang.preparationSpace.schedulesModule.sharedSchedule
            } (${lang.common.outdated.toLowerCase()})`
          : lang.preparationSpace.schedulesModule.sharedSchedule;

        return (
          <FlexBox>
            <ColPageLink
              item={item}
              pageUrl={PlannerUrls.share({ shareCode, locale })}
              text={text}
            />
            {shareIsOutdated ? (
              <Tooltip
                title={lang.preparationSpace.schedulesModule.updateShareLink}
              >
                <IconButton
                  className={classes.ml}
                  onClick={handleShareSchedule}
                  size="small"
                >
                  <SyncIcon fontSize="small" />
                </IconButton>
              </Tooltip>
            ) : (
              <></>
            )}
          </FlexBox>
        );
      }
      return (
        <Link onClick={handleShareSchedule}>
          <Typography>
            {lang.preparationSpace.schedulesModule.createSharedSchedule}
          </Typography>
        </Link>
      );
    }
    return <></>;
  }, [item, lang, locale, handleShareSchedule, classes]);
};
