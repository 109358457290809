import { gql } from 'core/dna/types/apollo';

export const DELETE_LESSON_GROUP_MUTATION = gql`
  mutation deleteLessonGroup($input: DeleteLessonGroupInput!) {
    deleteLessonGroup(input: $input) {
      status
      description
    }
  }
`;
