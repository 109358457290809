import { useEffect, useState } from 'react';

import { EventInput } from 'core/cells/full-calendar';

import { Class } from 'modules/planner/dna/types/class';
import { mapResourceListToAvailabilityPreferenceList } from 'modules/planner/dna/functions/map-Resources-to-AvailabilityPreferences';
import { mapAvailabilityPreferenceListToEventInputList } from 'modules/planner/dna/functions/map-AvailabilityPreference-to-EventInput';

import { useClasses } from 'modules/planner/metabolism/use-classes';

const getBreakCalendarEvents = (classData: Class[], ids?: string[]) => {
  const selectedClasses = ids
    ? classData.filter((c) => ids.indexOf(c.id) >= 0)
    : null;
  if (!selectedClasses?.length) {
    return null;
  }
  return mapAvailabilityPreferenceListToEventInputList(
    mapResourceListToAvailabilityPreferenceList(selectedClasses),
    '',
    'background',
  );
};

export const useClassesCalendarEvents = (props: { ids?: string[] }) => {
  const { ids } = props;
  const { classData, classesLoading, classesError } = useClasses();
  const [breaks, setBreaks] = useState<EventInput[] | null>(null);

  useEffect(() => {
    if (!classesLoading && !classesError && ids?.length) {
      setBreaks(getBreakCalendarEvents(classData, ids) as EventInput[]);
    } else {
      setBreaks(null);
    }
  }, [classData, classesError, classesLoading, ids]);

  return {
    breaks,
  };
};
