import { Box, BoxProps } from './box';

export type FlexBoxProps = BoxProps;

export const FlexBox = (props: FlexBoxProps) => {
  const {
    display,
    alignItems = 'center',
    fullSize = true,
    noSpacing = true,
    ...other
  } = props;
  return (
    <Box
      display="flex"
      alignItems={alignItems}
      fullSize={fullSize}
      noSpacing={noSpacing}
      {...other}
    />
  );
};
