import { useEffect } from 'react';

import { useOasHistory } from 'core/metabolism/use-oas-history';
import { useErrorsState } from 'core/metabolism/errors';

import PlannerUrls from 'modules/planner/routing/urls';

export const useErrorsHandler = () => {
  const {
    errorsState: { current },
  } = useErrorsState();

  const { replace } = useOasHistory();

  useEffect(() => {
    if (current) {
      replace(PlannerUrls.error());
    }
  }, [current, replace]);
};
