import { Fragment, useState, useEffect, useCallback } from 'react';
import Check from '@material-ui/icons/Check';
import { AccessTime } from '@material-ui/icons';

import { makeStyles, Theme, createStyles } from 'core/atoms/styles';
import { workingDays } from 'core/atoms/date-time/static-week';
import { Weekday } from 'core/atoms/date-time/types';
import { FlexBox } from 'core/cells/box';
import { Divider } from 'core/cells/divider';
import { Checkbox } from 'core/cells/checkbox';
import { useSnackbar, VariantType } from 'core/cells/notistack';

import { UpdateOpeningHoursInput } from 'core/dna/types/generated/_globalTypes';
import { useTranslations } from 'core/dna/translations';

import { useOrganization } from 'core/metabolism/organizations/use-organization';

import { Button } from 'templates/mdp/components/custom-buttons';
import {
  Card,
  CardBody,
  CardHeader,
  CardIcon,
} from 'templates/mdp/components/card';

import { useStyles } from 'templates/mdp/views/forms/regular-forms.styles';

import { useWeek } from 'modules/planner/metabolism/use-week';

import { DayWorkingHours, WorkTimePart } from '../day-working-hours';

const useLocalStyles = makeStyles((theme: Theme) =>
  createStyles({
    card: {
      width: 'fit-content',
    },
    divider: {
      margin: theme.spacing(1, 0),
    },
  }),
);

interface WorkDayTime {
  enabled?: boolean;
  opening?: string;
  closing?: string;
  weekday?: number;
}

interface WorkWeekTime {
  [key: string]: WorkDayTime;
}

export const WeekWorkingHours = () => {
  const regularFormsClasses = useStyles();
  const localClasses = useLocalStyles();

  const { lang } = useTranslations();

  const { organization, updateOpeningHours } = useOrganization();

  const [openingDefault] = useState<string>('08:00');
  const [closingDefault] = useState<string>('16:00');
  const { enqueueSnackbar } = useSnackbar();

  const [workTime, setWorkTime] = useState<WorkWeekTime>({});

  const { week } = useWeek();

  useEffect(() => {
    if (organization) {
      const initialWorkWeek: WorkWeekTime = {};
      workingDays.forEach((day) => {
        const openingHours = organization.openingHours?.find(
          (oh) => oh.weekDay === day,
        );
        initialWorkWeek[day] = {
          enabled: !!openingHours,
          opening: openingHours?.startTime,
          closing: openingHours?.endTime,
          weekday: day,
        };
      });
      setWorkTime(initialWorkWeek);
    }
  }, [organization, setWorkTime]);

  const changeWorkTimePart = useCallback(
    (value: string, part: WorkTimePart, day: Weekday) => {
      const d = workTime[day] ?? {};
      d[part] = value;
      setWorkTime({
        ...workTime,
        [day]: d,
      });
    },
    [workTime],
  );

  const changeWorkTimeEnabled = useCallback(
    (value: boolean, day: Weekday) => {
      const d: WorkDayTime = workTime[day] ?? {};
      d.enabled = value;
      setWorkTime({
        ...workTime,
        [day]: d,
      });
    },
    [workTime],
  );

  const getAugmentedWorkTime = useCallback(() => {
    const wt: WorkWeekTime = {};
    workingDays.forEach((day) => {
      if (workTime[day]?.enabled) {
        wt[day] = {
          opening: workTime[day]?.opening ?? openingDefault,
          closing: workTime[day]?.closing ?? closingDefault,
          weekday: workTime[day]?.weekday,
        };
      }
    });
    return wt;
  }, [closingDefault, openingDefault, workTime]);

  const saveWorkTime = useCallback(() => {
    const wt = getAugmentedWorkTime();
    const input: UpdateOpeningHoursInput = {
      openingHours: Object.keys(wt).map((day) => ({
        weekday: wt[day]!.weekday!,
        startTime: wt[day]!.opening!,
        endTime: wt[day]!.closing!,
      })),
    };
    updateOpeningHours(input)
      .then(() => {
        enqueueSnackbar('Opening hours updated successfully!', {
          variant: VariantType.Success,
        });
      })
      .catch((e) => {
        enqueueSnackbar(`Error: ${e.message}`, {
          variant: VariantType.Error,
        });
      });
  }, [enqueueSnackbar, getAugmentedWorkTime, updateOpeningHours]);

  return organization ? (
    <Card pricing className={localClasses.card}>
      <CardHeader color="primary" icon>
        <CardIcon color="primary">
          <AccessTime />
        </CardIcon>
      </CardHeader>
      <CardBody pricing>
        {[1, 2, 3, 4, 5].map((day) => (
          <Fragment key={day}>
            <FlexBox>
              <Checkbox
                checked={workTime[day]?.enabled ?? false}
                onChange={(event) =>
                  changeWorkTimeEnabled(event.target.checked, day)
                }
                tabIndex={-1}
                checkedIcon={
                  <Check className={regularFormsClasses.checkedIcon} />
                }
                icon={<Check className={regularFormsClasses.uncheckedIcon} />}
                classes={{
                  checked: regularFormsClasses.checked,
                  root: regularFormsClasses.checkRoot,
                }}
              />
              <DayWorkingHours
                disabled={!workTime[day]?.enabled}
                title={week[day].title}
                opening={workTime[day]?.opening ?? openingDefault}
                closing={workTime[day]?.closing ?? closingDefault}
                onChange={(value, part) => changeWorkTimePart(value, part, day)}
              />
            </FlexBox>
            <Divider className={localClasses.divider} />
          </Fragment>
        ))}

        <FlexBox justifyContent="flex-end">
          <Button variant="contained" color="secondary" onClick={saveWorkTime}>
            {lang.common.save}
          </Button>
        </FlexBox>
      </CardBody>
    </Card>
  ) : (
    <p>Waiting for organization...</p>
  );
};
