import { CSSProperties } from './types';

export { default as createMuiTheme } from '@material-ui/core/styles/createTheme';

export { default as responsiveFontSizes } from '@material-ui/core/styles/responsiveFontSizes';

export { alpha } from '@material-ui/core/styles';

export { lighten } from '@material-ui/core/styles/colorManipulator';

export { ThemeProvider, styled } from '@material-ui/styles';

export {
  makeStyles,
  createStyles,
  useTheme,
  withStyles,
} from '@material-ui/core';

export * from './types';

export * from './utils';

export const defaultFont: CSSProperties = {
  fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  fontWeight: 300,
  lineHeight: '1.5em',
};
