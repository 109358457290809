export const mapNullableListToList = <TI = any, TO = TI>(
  list: (TI | null)[] | null,
  itemMapper: (item: TI | null) => TO | undefined,
): TO[] | undefined => {
  let listOut: TO[] | undefined;
  if (list) {
    listOut = [];
    list.forEach((item) => {
      const itemOut = itemMapper(item);
      itemOut && listOut && listOut.push(itemOut);
    });
  }
  return listOut;
};
