import { AuthProvider } from 'app/auth';

import { StylesProvider } from 'app/providers/styles-provider';
import { LocaleProvider } from 'app/providers/locale-provider';
import { TranslationsProvider } from 'app/providers/translations-provider';
import { DndProvider } from 'app/providers/dnd-provider';
import { RoutingProvider } from 'app/providers/routing-provider';
import { DataProvider } from 'app/providers/data-provider';
import { SnackbarProvider } from 'app/providers/snackbar-provider';
import { IntercomProvider } from 'app/providers/intercom-provider';
import { AccessRolesProvider } from 'app/providers/access-roles-provider';
import { OrganizationsProvider } from 'app/providers/organizations-provider';
import { ModulesProvider } from 'app/providers/modules-provider';

import { App } from 'app/app';
import { Mui5Provider } from './providers/mui5-provider';

export const Main = () => {
  return (
    <StylesProvider>
      <Mui5Provider>
        <LocaleProvider>
          <TranslationsProvider>
            <DndProvider>
              <AuthProvider>
                <AccessRolesProvider>
                  <OrganizationsProvider>
                    <RoutingProvider>
                      <ModulesProvider>
                        <DataProvider>
                          <SnackbarProvider>
                            <IntercomProvider>
                              <App />
                            </IntercomProvider>
                          </SnackbarProvider>
                        </DataProvider>
                      </ModulesProvider>
                    </RoutingProvider>
                  </OrganizationsProvider>
                </AccessRolesProvider>
              </AuthProvider>
            </DndProvider>
          </TranslationsProvider>
        </LocaleProvider>
      </Mui5Provider>
    </StylesProvider>
  );
};
