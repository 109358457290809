import { ReactElement, useCallback, useMemo } from 'react';

import { makeStyles, createStyles, PropTypeColor } from 'core/atoms/styles';
import { DraggableItem } from 'core/atoms/types';

import { Chip, ChipProps } from 'core/cells/chip';

import { useDrag } from 'modules/planner/dna/dnd/use-drag';

export interface DraggableChipProps extends DraggableItem {
  color?: Exclude<PropTypeColor, 'inherit'>;
  onDoubleClick?: (item: DraggableItem) => void;
  onDelete?: (item: DraggableItem) => void;
  avatar?: ReactElement;
}

const useStyles = makeStyles((theme) =>
  createStyles({
    chip: {
      margin: theme.spacing(0.5),
    },
  }),
);

export const DraggableChip = (props: DraggableChipProps) => {
  const classes = useStyles();

  const { onDoubleClick, onDelete, color, avatar, ...item } = props;

  const { isDragging, dragRef } = useDrag({
    type: item.type,
    id: item.id,
    text: item.text,
    data: item.data,
  });

  const handleDoubleClick = useCallback(() => {
    onDoubleClick?.(item);
  }, [item, onDoubleClick]);

  const handleDelete = useCallback(() => {
    onDelete?.(item);
  }, [item, onDelete]);

  const chipProps = useMemo(() => {
    const cp: Partial<ChipProps> = {};
    if (onDoubleClick) {
      cp.onDoubleClick = handleDoubleClick;
    }
    if (onDelete) {
      cp.onDelete = handleDelete;
    }
    return cp;
  }, [handleDelete, handleDoubleClick, onDelete, onDoubleClick]);

  return (
    <Chip
      ref={dragRef}
      avatar={avatar}
      size="small"
      label={item.text}
      className={classes.chip}
      color={color}
      style={{
        opacity: isDragging ? 0.8 : 1,
        cursor: 'move',
        zIndex: 1,
      }}
      {...chipProps}
    />
  );
};
