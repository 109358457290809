import { useContext } from 'react';

import { SelectedOrganizationStateContext } from './selected-organization-state-context';

export const useSelectedOrganizationState = () => {
  const selectedOrganizationState = useContext(
    SelectedOrganizationStateContext,
  );
  if (typeof selectedOrganizationState === 'undefined') {
    throw new Error(
      'useSelectedOrganizationState must be used within a OrganizationsProvider',
    );
  }
  return selectedOrganizationState;
};
