import { GetSchedule_Schedule_lessonGroups_lessons_chapters as ApiChapter } from 'core/dna/types/generated/GetSchedule';
import { mapNullableListToList } from 'core/dna/functions/map-nullable-list-to-list';

import { ChapterData } from 'modules/planner/dna/types/chapter-data';

export const mapApiChapterListToChapterDataList = (
  apiChapterList: (ApiChapter | null)[] | null,
): ChapterData[] | undefined => {
  return mapNullableListToList<ApiChapter, ChapterData>(
    apiChapterList,
    mapApiChapterToChapterData,
  );
};

export const mapApiChapterToChapterData = (
  apiChapter: ApiChapter | null,
): ChapterData | undefined => {
  if (!apiChapter) {
    return;
  }
  const { id, name } = apiChapter;

  return {
    id,
    name,
  };
};
