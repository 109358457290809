import React, { useCallback } from 'react';

import { FlexBox } from 'core/cells/box';
import { FormController, Form, Field } from 'core/cells/forms';
import { FormTextField } from 'core/cells/form-text-field';

import { useTranslations } from 'core/dna/translations';

import { Button } from 'templates/mdp/components/custom-buttons';

import { WriteModeEnum } from 'core/dna/types/write-mode';

import { WriteTableModal } from 'core/organisms/write-modal';

import { useClassesTableWrite } from './use-classes-table-write';

interface Values {
  name: string;
}

export interface ClassEditorProps {
  open?: boolean;
  setOpen?: (open: boolean) => void;
  defaultName: string;
  id?: string;
  names?: string[];
}

export const ClassEditor = (props: ClassEditorProps) => {
  const { open, setOpen, id, defaultName } = props;

  const { onRowUpdate, onRowAdd } = useClassesTableWrite();
  const { texts } = useTranslations();

  const onClose = useCallback(() => {
    setOpen?.(false);
  }, [setOpen]);

  return (
    <WriteTableModal
      isShow={open ?? false}
      onClose={onClose}
      writeMode={id ? WriteModeEnum.update : WriteModeEnum.create}
      title={
        id
          ? texts.planner.sourceDataSpace.classesModule.editClass
          : texts.planner.sourceDataSpace.classesModule.addClass
      }
    >
      <FormController
        initialValues={{
          name: defaultName,
        }}
        validate={(values) => {
          const errors: Partial<Values> = {};
          if (!values.name) {
            errors.name = texts.oasCommon.validation.required;
          }
          if (
            props.names
              ?.map((n) => n.toLowerCase())
              ?.includes(values.name.toLowerCase())
          ) {
            errors.name = texts.planner.plannerClasses.duplicateClassError;
          }
          return errors;
        }}
        onSubmit={async (values) => {
          setOpen?.(false);
          id
            ? await onRowUpdate({ id, name: values.name ?? '' })
            : await onRowAdd({ name: values.name ?? '' });
        }}
      >
        {({ submitForm, isSubmitting }) => (
          <Form>
            <Field
              component={FormTextField}
              name="name"
              type="text"
              label={texts.planner.sourceDataSpace.classesModule.className}
              disabled={isSubmitting}
              fullWidth
            />
            <FlexBox justifyContent="flex-end">
              <Button
                color="secondary"
                disabled={isSubmitting}
                onClick={submitForm}
              >
                {id ? texts.planner.common.save : texts.planner.common.add}
              </Button>
              <Button onClick={onClose} color="secondary" simple>
                {texts.planner.common.close}
              </Button>
            </FlexBox>
          </Form>
        )}
      </FormController>
    </WriteTableModal>
  );
};
