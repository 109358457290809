import React, { FC } from 'react';
import clsx from 'clsx';
import { makeStyles, createStyles } from 'core/atoms/styles';
import { blue } from '@material-ui/core/colors';
import {
  InputBaseComponentProps,
  TextField as MuiTextField,
} from '@material-ui/core';

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    textField: {
      width: 'auto',
      '& label.Mui-focused': {
        color: `${blue['500']}`,
      },
      '& .MuiInput-underline:after': {
        borderBottomColor: `${blue['500']}`,
      },
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderColor: `${blue['500']}`,
        },
        '&:hover fieldset': {
          borderColor: `${blue['500']}`,
        },
        '&.Mui-focused fieldset': {
          borderColor: `${blue['500']}`,
        },
      },
    },
  }),
);

export interface TimeFieldProps {
  label?: string;
  inputProps?: InputBaseComponentProps;
  defaultValue?: string;
  value?: string;
  onChange?: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>;
  disabled?: boolean;
  id?: string;
  className?: string;
}

export const DateField: FC<TimeFieldProps> = (props) => {
  const classes = useStyles();

  const {
    label,
    inputProps,
    defaultValue = undefined,
    value,
    onChange,
    disabled,
    id = 'date',
    className,
  } = props;

  return (
    <form className={clsx(classes.container, className || null)} noValidate>
      <MuiTextField
        id={id}
        label={label}
        type="date"
        margin="dense"
        variant="outlined"
        defaultValue={defaultValue}
        className={classes.textField}
        InputLabelProps={{
          shrink: true,
        }}
        inputProps={inputProps}
        value={value}
        onChange={onChange}
        disabled={disabled}
      />
    </form>
  );
};
