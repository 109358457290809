import { gql } from 'core/dna/types/apollo';
import {
  JOURNAL_ENTRY_FRAGMENT,
  PERSON_T2_DETAILS_FRAGMENT,
} from 'modules/student/memory/apollo/_fragments';

export const GET_JOURNALS_QUERY = gql`
  query GetJournals {
    PersonT2 {
      tuid
      details {
        ...PersonDetailsT2
      }
      journals {
        ...JournalEntry
      }
    }
  }
  ${JOURNAL_ENTRY_FRAGMENT}
  ${PERSON_T2_DETAILS_FRAGMENT}
`;
