import { useMemo } from 'react';

import { RoomTableItem } from 'modules/planner/dna/types/room';

import { useGetRooms } from 'modules/planner/memory/apollo/rooms/remote';

import { useTranslations } from 'core/dna/translations';

export const useRoomsTableRead = () => {
  const { lang } = useTranslations();
  const { rooms, roomsLoading } = useGetRooms();

  // const columns: Column<RoomTableItem>[] = useMemo(
  const columns = useMemo(
    () => [
      {
        Header: lang.common.names.one,
        accessor: 'name',
      },
      {
        Header: lang.common.shortNames.one,
        accessor: 'alias',
      },
      {
        Header: lang.common.notes.one,
        accessor: 'note',
      },
      {
        Header: lang.common.availabilityCalendar,
        accessor: 'availabilityDefined',
        disableFilters: true,
      },
      {
        Header: lang.common.actions.many,
        accessor: 'actions',
      },
    ],
    [lang],
  );

  const data: RoomTableItem[] = useMemo(
    () =>
      rooms.map((r) => {
        const { id, name, alias, note, availabilityPreferences } = r;
        return {
          id,
          name,
          alias,
          note,
          availabilityDefined:
            !!availabilityPreferences && !!availabilityPreferences.length,
        };
      }),
    [rooms],
  );

  return {
    title: lang.common.rooms,
    columns,
    data,
    loading: roomsLoading,
  };
};
