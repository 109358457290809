import invert from 'invert-color';

import { grey } from 'core/atoms/colors';
import { tick } from 'core/atoms/date-time';

import { ScheduleEvent } from 'core/dna/types';
import { LessonGroup } from 'modules/planner/dna/types/lesson-group';
import { ExtractTimePlanOptions } from 'modules/planner/dna/types/extract-time-plan-options';
import { mapStringListToString } from 'core/dna/functions';

import { getEventDuration } from './get-event-duration';

export const getCalendarEventsFromLessonGroup = (
  lessonGroup: LessonGroup,
  options: ExtractTimePlanOptions,
) => {
  const { id: lgId, timeSlots, lessons } = lessonGroup;
  const { withClasses, withGroups, withTeachers, withRooms } = options;

  const calendarEvents: ScheduleEvent[] = [];

  let start: Date;
  let end: Date;
  let invertedColor: string;
  let duration: number;
  timeSlots?.forEach((ts) => {
    start = tick(ts.weekday)
      .setTime(ts.startTime ?? '')
      .toDate();
    end = tick(ts.weekday)
      .setTime(ts.endTime ?? '')
      .toDate();
    duration = getEventDuration({ start, end });
    lessons?.forEach((l) => {
      const { title, chapters, bgColor, classes, groups, teachers, rooms } = l;

      invertedColor = invert(bgColor ?? '', {
        black: '#3a3a3a',
        white: '#fafafa',
      });

      calendarEvents.push({
        id: `${lgId}_${duration}_${ts.id}`,
        lessonGroupId: lessonGroup.id,
        timeSlotId: ts.id,
        splitId: ts.splitId,
        title: title ?? 'E',
        resourceIds: chapters?.map((ch) => ch.classId),
        backgroundColor: bgColor,
        borderColor: grey['600'],
        textColor: invertedColor,
        start,
        end,
        pinned: ts.pinned,
        durationEditable: false,
        locked: false, // !!ts.splitId,
        startEditable: true, // !ts.splitId,
        classes: withClasses
          ? mapStringListToString(classes?.map((t) => t.text))
          : undefined,
        groups: withGroups
          ? mapStringListToString(groups?.map((t) => t.text))
          : undefined,
        teachers: withTeachers
          ? mapStringListToString(teachers?.map((t) => t.text))
          : undefined,
        rooms: withRooms
          ? mapStringListToString(rooms?.map((r) => r.text))
          : undefined,
        aiHardConflicts: ts.aiHardConflicts,
        aiSoftConflicts: ts.aiSoftConflicts,
      });
    });
  });

  return calendarEvents;
};
