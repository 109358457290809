import React from 'react';
import ReactDOM from 'react-dom';
import { enableMapSet } from 'immer';

import { Main } from 'app';

import reportWebVitals from './reportWebVitals';

enableMapSet();

ReactDOM.render(<Main />, document.getElementById('oas-web-root'));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
