import { RouteConfig } from 'core/dna/types/local';
import { findRoute } from 'core/dna/routing/functions/find-route';

import { filterRoutes, getRoutes } from '../functions';

import { getActiveRoute } from './get-active-route';

const findClosestSidebarRoute = (
  routes: RouteConfig[],
  route: RouteConfig,
): RouteConfig | undefined => {
  if (!route.parentId) {
    return;
  }

  const parent = findRoute(routes, 'id', route.parentId);

  if (parent) {
    return findClosestSidebarRoute(routes, parent);
  }
};

export const getActiveSidebarRoute = (
  routes: RouteConfig[],
): RouteConfig | undefined => {
  const flatRoutes = getRoutes(routes);
  const route = getActiveRoute(flatRoutes);
  // if (!Object.keys(route).length) {
  //   throw new OasRoutingError('Active route should be defined', {
  //     title: 'getActiveSidebarRoute',
  //   });
  // }
  if (route.sidebar) {
    return route;
  }
  if (!route.parentId) {
    return;
  }

  const parentRoutes = filterRoutes(flatRoutes, {
    fieldName: 'id',
    fieldValue: route.parentId,
  });
  return parentRoutes.length ? parentRoutes[0] : undefined;
};
