import { useCallback } from 'react';

import { getErrorMessage } from 'core/atoms/errors';

import {
  selectedClassesStateVar,
  selectedClassStateVar,
  defaults,
} from 'modules/planner/memory/apollo/cache';

export const useSetSelectedClassesState = () => {
  const func = useCallback((values: string[]) => {
    try {
      selectedClassesStateVar([...values]);
      const existingClass = selectedClassStateVar();
      if (!values.includes(existingClass)) {
        selectedClassStateVar(
          values.length ? values[0] : defaults.selectedClassState,
        );
      }
    } catch (e) {
      throw new Error(getErrorMessage(e));
    }
  }, []);

  return {
    setSelectedClassesState: func,
  };
};
