import { useCallback } from 'react';

import { useGetSelectedScheduleState } from 'modules/planner/memory/apollo/schedules/local';

import { SchedulesTabbedPage } from 'modules/planner/pages/plan/schedules/components/schedules-tabbed-page';

import { TeachingTimeTable } from './components/teaching-time-table';

/**  /plan/schedules/:scheduleId?/teaching-time-report  */
export const TeachingTimeReport = () => {
  const { selectedScheduleState } = useGetSelectedScheduleState();
  const getContent = useCallback(
    () => <TeachingTimeTable scheduleId={selectedScheduleState ?? ''} />,
    [selectedScheduleState],
  );
  return <SchedulesTabbedPage tabContent={getContent} />;
};
