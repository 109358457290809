import { useMemo, useCallback, memo } from 'react';

import { Link } from 'core/cells/link';
import { Typography } from 'core/cells/typography';

import { useTranslations } from 'core/dna/translations';
import { useHistory, useLocale } from 'core/dna/routing';

import { ClassTableItem } from 'modules/planner/dna/types/class';

import { useClasses } from 'modules/planner/metabolism/use-classes';

import PlannerUrls from 'modules/planner/routing/urls';

export interface ColBreaksProps {
  item?: ClassTableItem;
  children?: React.ReactElement;
}

const ColBreaksComponent = (props: ColBreaksProps) => {
  const { item } = props;

  const { texts } = useTranslations();
  const history = useHistory();
  const locale = useLocale();

  const { setSelectedClasses } = useClasses();

  const text = useMemo(() => {
    if (!item?.availabilityDefined) {
      return texts.planner.common.create;
    }
    return texts.planner.common.definedCountChange.one;
  }, [item, texts.planner]);

  const handleClick = useCallback(() => {
    if (item) {
      setSelectedClasses([item.id]);
      history.push(PlannerUrls.classesAvailability(locale));
    }
    return false;
  }, [history, item, locale, setSelectedClasses]);

  return item ? (
    <Link onClick={handleClick}>
      <Typography>{text}</Typography>
    </Link>
  ) : (
    <span> </span>
  );
};

ColBreaksComponent.displayName = 'Col Breaks';

export const ColBreaks = memo(ColBreaksComponent);
