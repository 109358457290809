import { forwardRef } from 'react';

import MuiButton, {
  ButtonProps as MuiButtonProps,
} from '@material-ui/core/Button';

export interface ButtonProps extends MuiButtonProps {
  autoFocus?: boolean;
}

export const Button = forwardRef((props: ButtonProps, ref: any) => {
  const { autoFocus, action, ...other } = props;

  return (
    <MuiButton
      action={(actions) => autoFocus && actions && actions.focusVisible()}
      {...other}
      ref={ref}
    >
      {props.children}
    </MuiButton>
  );
});
