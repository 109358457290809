import { makeStyles, Theme, createStyles } from 'core/atoms/styles';

import { Grid } from 'core/cells/grid';
import { Button } from 'core/cells/button';

import { useTranslations } from 'core/dna/translations';

import { GradeClassesSelector } from './components/grade-classes-selector';
import { useClassesGenerator } from './use-classes-generator';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: 260,
      margin: theme.spacing(2),
    },
  }),
);

export const ClassesGenerator = () => {
  const classes = useStyles();
  const { lang } = useTranslations();
  const {
    grades,
    handleGenerate,
    clearGeneratingOptions,
  } = useClassesGenerator();

  return (
    <div className={classes.root}>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Button
            color="secondary"
            variant="outlined"
            fullWidth
            onClick={clearGeneratingOptions}
          >
            {lang.common.clear}
          </Button>
        </Grid>
        <Grid item xs={6}>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            onClick={handleGenerate}
          >
            {lang.common.generate}
          </Button>
        </Grid>
        {grades.map((grade, index) => {
          return (
            <Grid key={`${grade}-${index}`} item xs={12}>
              <GradeClassesSelector grade={grade} />
            </Grid>
          );
        })}
      </Grid>
    </div>
  );
};
