import moment, { Moment, MomentInput } from 'moment';

import { isWeekday } from 'core/atoms/functions';

import { getWeekdayDate } from './static-week';
import { timeLong } from './formats';

export type TickInput = Tick | MomentInput;

class Tick {
  private m: Moment;

  get weekDay(): number {
    return this.m.weekday();
  }

  get time(): string {
    return this.m.format(timeLong);
  }

  set time(value: string) {
    const mt = moment(value, timeLong);
    this.m.set({
      hour: mt.hour(),
      minute: mt.minute(),
      second: mt.second(),
      millisecond: 0,
    });
  }

  setTime(time: string) {
    this.time = time;
    return this;
  }

  toDate() {
    return this.m.toDate();
  }

  format(format?: string) {
    return this.m.format(format);
  }

  isBefore(inp?: TickInput) {
    if (inp instanceof Tick) {
      return this.m.isBefore(inp.m);
    }
    return this.m.isBefore(inp);
  }

  constructor(input?: any, format?: any) {
    this.m = moment(input, format);
  }
}

export function tick(input?: any, format?: any) {
  if (isWeekday(input)) {
    return new Tick(getWeekdayDate(input), format);
  }
  return new Tick(input, format);
}
