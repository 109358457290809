import { mapStringListToString } from 'core/dna/functions/map-string-list-to-string';

import { LessonGroup } from 'modules/planner/dna/types/lesson-group';

export const extractChaptersStringFromLessonGroup = (
  lessonGroup: LessonGroup,
) =>
  mapStringListToString(
    lessonGroup.lessons
      ?.map((l) => l.chapters || [])
      .reduce((prev, cur) => {
        return [...prev, ...cur];
      }, [])
      .map((ch) => `${ch.name}(${ch.class})`),
  );
