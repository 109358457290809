import moment from 'moment';

// '2021-09-27T14:55:00' - example date
export const sortByDateTime = (a: string, b: string) => {
  const dateA = a.split('T')[0];
  const dateB = b.split('T')[0];

  if (moment(dateA).isSame(dateB)) {
    if (moment(a).isSame(b)) {
      return 0;
    }
    return moment(a).isBefore(b) ? -1 : 1;
  }

  return moment(dateA).isBefore(dateB) ? -1 : 1;
};
