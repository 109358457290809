import { mapNullableListToList } from 'core/dna/functions/map-nullable-list-to-list';
import { GetStudentDetails_PersonT2_absences as ApiAbsences } from 'core/dna/types/generated/GetStudentDetails';
import { Absence } from 'modules/student/dna/types/absence';
import { mapPersonDetailsToFullName } from '../map-person-details-to-full-name';

export const mapApiAbsenceToAbsence = (
  apiAbsences: ApiAbsences | null,
): Absence => {
  if (apiAbsences === null) {
    throw new TypeError('"apiAbsences" should not be null!');
  }

  const {
    id,
    regarding,
    signedBy,
    wholeDay,
    exempt,
    from,
    to,
    locked,
    state,
    origin,
  } = apiAbsences;

  return {
    id,
    wholeDay,
    locked,
    exempt,
    state,
    regarding: regarding?.details
      ? mapPersonDetailsToFullName(regarding.details)
      : null,
    signedBy: signedBy?.details
      ? mapPersonDetailsToFullName(signedBy.details)
      : null,
    from: from?.formatted || null,
    to: to?.formatted || null,
    origin,
  };
};

export const mapApiAbsenceToAbsenceList = (
  apiAbsenceList: (ApiAbsences | null)[] | null,
): Absence[] | undefined => {
  return mapNullableListToList<ApiAbsences, Absence>(
    apiAbsenceList,
    mapApiAbsenceToAbsence,
  );
};
