import { useContext } from 'react';

import { useLocale } from 'core/dna/routing';

import { TranslationsContext } from './translations-context';

export const useTranslations = () => {
  const locale = useLocale();
  const data = useContext(TranslationsContext);

  return {
    texts: data[locale],
    lang: data[locale].planner,
    ...data,
  };
};
