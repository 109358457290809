import { useCallback } from 'react';

import { tick } from 'core/atoms/date-time';
import { timeShort } from 'core/atoms/date-time/formats';

import { EventInput } from 'core/cells/full-calendar';

import { useOrganization } from 'core/metabolism/organizations/use-organization';

export const useOrganizationCalendar = () => {
  const { startTime, endTime, organization } = useOrganization();

  const getBackgroundEvents = useCallback(
    (resourceIds?: string[]): EventInput[] | undefined => {
      if (organization && startTime && endTime) {
        const events: EventInput[] = [];
        organization.openingHours?.forEach((oh) => {
          if (
            tick(startTime, timeShort).isBefore(tick(oh.startTime, timeShort))
          ) {
            events.push({
              id: `${oh.id}-before`,
              title: '',
              resourceIds,
              start: tick(oh.weekDay).setTime(startTime).toDate(),
              end: tick(oh.weekDay).setTime(oh.startTime).toDate(),
              display: 'background',
            });
          }

          if (tick(oh.endTime, timeShort).isBefore(tick(endTime, timeShort))) {
            events.push({
              id: `${oh.id}-after`,
              title: '',
              resourceIds,
              start: tick(oh.weekDay).setTime(oh.endTime).toDate(),
              end: tick(oh.weekDay).setTime(endTime).toDate(),
              display: 'background',
            });
          }
        });
        return events.length ? events : undefined;
      }
    },
    [endTime, organization, startTime],
  );

  return {
    startTime,
    endTime,
    getBackgroundEvents,
  };
};
