import { useCallback } from 'react';

import { OasError, OasMetabolismError } from 'core/atoms/errors';
import { DataItem } from 'core/atoms/types';

import { ClassRole } from 'core/dna/types/generated/_globalTypes';
import { mapNullableListToList } from 'core/dna/functions';

import { useCreateStudentFromSyncPerson } from 'modules/planner/memory/apollo/sync-students/remote';
import {
  useUpdateClassMembers,
  useAddMembersToChapter,
  useLazyGetClass,
} from 'modules/planner/memory/apollo/classes/remote';

import { useClasses } from './use-classes';

const FILE_NAME = 'use-import-students';

export const useImportStudents = () => {
  const { createStudentFromSyncPerson } = useCreateStudentFromSyncPerson();
  const { updateClassMembers } = useUpdateClassMembers();
  const { addMembersToChapter } = useAddMembersToChapter();

  const { getClass } = useLazyGetClass();
  const { classMap } = useClasses();

  const importStudents = useCallback(
    async (classId: string, students: DataItem<{ tuid: string }>[]) => {
      try {
        const existingClass = classMap.byId[classId];
        if (!existingClass) {
          return;
        }

        const filteredStudents = students.filter(
          (s) =>
            (existingClass.students?.map((es) => es.tuid) ?? []).indexOf(
              s.data?.tuid ?? '',
            ) < 0,
        );

        const studentResults = await Promise.all(
          filteredStudents.map((item) => createStudentFromSyncPerson(item)),
        );

        const members = mapNullableListToList(
          studentResults.map((sr) => {
            if (sr?.role) {
              return {
                roleId: sr.role.id,
                classRole: ClassRole.student,
              };
            }
            return null;
          }),
          (item) => item ?? undefined,
        );

        const ucmResult = await updateClassMembers({ classId, members });

        const union = existingClass.unions?.find(
          (u) => u.chapters?.length === 1,
        );

        if (ucmResult?.length && union?.chapters?.length) {
          await addMembersToChapter(
            union.chapters[0].id,
            ucmResult.map((r) => r.roleId),
          );
          await getClass(classId);
        }
      } catch (e: any) {
        if (e instanceof OasError) {
          throw e;
        } else {
          throw OasMetabolismError.fromError(e, {
            title: FILE_NAME,
          });
        }
      }
    },
    [
      addMembersToChapter,
      classMap.byId,
      createStudentFromSyncPerson,
      getClass,
      updateClassMembers,
    ],
  );

  return {
    importStudents,
  };
};
