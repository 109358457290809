import { makeStyles, createStyles, CSSProperties } from 'core/atoms/styles';

import { cardTitle } from '../../material-dashboard-pro-react';

export interface PanelsStyle {
  cardTitle: CSSProperties;
  pageSubcategoriesTitle: CSSProperties;
  cardCategory: CSSProperties;
}

export const panelsStyle: PanelsStyle = {
  cardTitle,
  pageSubcategoriesTitle: {
    color: '#3C4858',
    textDecoration: 'none',
    textAlign: 'center',
  },
  cardCategory: {
    margin: 0,
    color: '#999999',
  },
};

export const useStyles = makeStyles(() => createStyles({ ...panelsStyle }));
