import React, { useEffect, useState } from 'react';
import moment from 'moment';

import { Box } from 'core/cells/box';
import { DateField } from 'core/cells/date-field';
import { date } from 'core/atoms/date-time/formats';
import { useTranslations } from 'core/dna/translations';
import { createStyles, makeStyles } from 'core/atoms/styles';
import { PersonT2_DateInputFilter } from 'core/dna/types/generated/_globalTypes';
import { FormControl } from 'core/cells/form-control';
import { FormLabel } from 'core/cells/form-label';

import { useTerm } from 'modules/planner/metabolism/terms';

const useStyles = makeStyles(
  createStyles({
    from: {
      marginRight: 15,
    },
  }),
);

interface PeriodSelectionProps {
  idFromInput?: string;
  idToInput?: string;
  period: PersonT2_DateInputFilter;
  onChange: (input: PersonT2_DateInputFilter) => void;
}

export const PeriodSelection = (props: PeriodSelectionProps) => {
  const {
    idFromInput = 'date-from',
    idToInput = 'date-to',
    period,
    onChange,
  } = props;

  const classes = useStyles();
  const { texts } = useTranslations();
  const { term } = useTerm();

  const today = moment().format(date);

  const [dateFrom, setDateFrom] = useState('');
  const [dateTo, setDateTo] = useState('');

  useEffect(() => {
    setDateFrom(moment(period.from).endOf('day').format(date));
    setDateTo(moment(period.to).endOf('day').format(date));
  }, [period.from, period.to]);

  return (
    <FormControl component="fieldset">
      <FormLabel component="legend">
        {texts.reports.reportsCommon.periodSelection}
      </FormLabel>
      <Box display="flex" justifyContent="space-between" flexWrap="wrap">
        <DateField
          className={classes.from}
          id={idFromInput}
          label={texts.planner.common.dateFrom}
          value={dateFrom}
          inputProps={{
            min: moment(term?.startDate).format(date),
            max: today,
          }}
          onChange={(e) => {
            setDateFrom(e.target.value);
            onChange({
              ...period,
              from: moment(e.target.value).startOf('day').utc().toISOString(),
            });
          }}
        />
        <DateField
          id={idToInput}
          label={texts.planner.common.dateTo}
          value={dateTo}
          inputProps={{
            min: dateFrom,
            max: today,
          }}
          onChange={(e) => {
            setDateTo(e.target.value);
            onChange({
              ...period,
              to: moment(e.target.value).endOf('day').utc().toISOString(),
            });
          }}
        />
      </Box>
    </FormControl>
  );
};
