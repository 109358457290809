import { Moment } from 'moment';

import { OasOrganismError } from 'core/atoms/errors';

import {
  EventContentArg,
  Resource,
  DurationInput,
  OverlapFunc,
  EventInput,
  DayCellContentType,
  EventApi,
} from 'core/cells/full-calendar';

import { CalendarEvent } from 'core/dna/types';

export type CalendarType = 'resource-week' | 'week' | 'day';

export interface WeekCalendarProps {
  id?: string;
  freeze?: boolean;
  type?: CalendarType;
  slotMinTime?: string;
  slotMaxTime?: string;
  events?: CalendarEvent[];
  eventTitle?: string;
  eventClass?: string;
  extEvents?: EventInput[]; // CalendarEvent[];
  bgEvents?: EventInput[]; // CalendarEvent[];
  onAddExternal?: (event: EventInput) => void;
  onAdd?: (event: EventInput) => void;
  onEdit?: (event: EventInput) => void;
  onSplitDragStart?: (
    event: EventInput,
    relatives: EventApi[],
    duration: number,
    relativesTimeSlotIds: string[],
  ) => void;
  eventContent?: (props: EventContentArg) => any;
  onPin?: (event: EventInput) => void;
  resources?: Resource[];
  datesAboveResources?: boolean;
  slotLabelInterval?: DurationInput;
  slotDuration?: DurationInput;
  snapDuration?: DurationInput;
  withConfirmation?: boolean;
  withPinning?: boolean;
  pinned?: boolean;
  resizable?: boolean;
  selectable?: boolean;
  eventOverlap?: boolean | OverlapFunc;
  contentHeight?: string | number;
  height?: string | number;
  nowIndicator?: boolean;
  now?: Date | string | Moment;
  dayCellContent?: DayCellContentType;
}

export const defaultProps: Partial<WeekCalendarProps> = {
  type: 'week',
  eventTitle: 'EVENT',
  resizable: true,
  eventOverlap: false,
};

export function assertProperUsageOfResources<T extends string, K>(
  type: T,
  resources: K,
  title: string,
): asserts resources is NonNullable<K> {
  if (type === 'resource-week' && !resources) {
    throw new OasOrganismError(
      'Expected "resources" to be defined for resource calendar (type="resource-week")',
      { title },
    );
  }
}

export function assertHasNowWhenPassedNowIndicator<T, K>(
  now: T,
  nowIndicator: K,
  title: string,
): asserts now is T {
  if (nowIndicator && !now) {
    throw new OasOrganismError(
      'Expected "now" to be defined if "nowIndicator" is "true"',
      { title },
    );
  }
}
