import clsx from 'clsx';

import { makeStyles, createStyles, Theme } from 'core/atoms/styles';

import { Paper } from 'core/cells/paper';
import { Typography } from 'core/cells/typography';
import { Link } from 'core/cells/link';
import { NavLink } from 'core/cells/nav-link';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(2),
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    message: {
      margin: 0,
    },
  }),
);

export interface NoBlockDataProps {
  message: string;
  link: string;
  linkText: string;
  className?: string;
}

export const NoBlockData = (props: NoBlockDataProps) => {
  const classes = useStyles();
  const { message, link, linkText, className } = props;

  return (
    <Paper className={clsx(classes.root, className)}>
      <Typography className={classes.message}>{message}</Typography>
      <Link
        sx={{ marginLeft: (theme) => theme.spacing(0.5) }}
        component={NavLink}
        to={link}
      >
        <Typography>{linkText}</Typography>
      </Link>
    </Paper>
  );
};
