import { useCallback } from 'react';

import { OasMemoryError, OasError } from 'core/atoms/errors';

import { gql, useMutation } from 'core/dna/types/apollo';

import { JournalType } from 'core/dna/types/generated/_globalTypes';
import {
  CreateJournalEntry,
  CreateJournalEntryVariables,
} from 'core/dna/types/generated/CreateJournalEntry';
import { EXECUTION_STATUS } from 'modules/substitute/memory/apollo/_fragments/remote';
import { JournalEntryData } from 'modules/student/dna/types';
// import { GET_STUDENT_DETAILS_QUERY } from '../get-student-details/gql';

export const CREATE_JOURNAL_ENTRY_MUTATION = gql`
  mutation CreateJournalEntry($input: createJournalEntryInput!) {
    createJournalEntry(input: $input) {
      ...ExecutionStatus
    }
  }
  ${EXECUTION_STATUS}
`;

const TITLE = 'use-create-journal-entry';

export const useCreateJournalEntry = () => {
  const [createJournalEntry, { loading, error }] = useMutation<
    CreateJournalEntry,
    CreateJournalEntryVariables
  >(CREATE_JOURNAL_ENTRY_MUTATION);

  const func = useCallback(
    async (
      regardingTuid: OasValidateString,
      type: JournalType,
      data: JournalEntryData,
      from?: OasValidateString | null,
      to?: OasValidateString | null,
    ) => {
      try {
        const result = await createJournalEntry({
          variables: {
            input: {
              regardingTuid,
              type,
              data,
              from,
              to,
            },
          },
          // refetchQueries: [
          //   {
          //     query: GET_STUDENT_DETAILS_QUERY,
          //     variables: { tuid: regardingTuid },
          //   },
          // ],
        });

        if (result.errors) {
          throw new OasMemoryError(JSON.stringify(result.errors), {
            title: TITLE,
          });
        }
        return result.data?.createJournalEntry ?? undefined;
      } catch (e: any) {
        if (e instanceof OasError) {
          throw e;
        } else {
          throw OasMemoryError.fromError(e, {
            title: TITLE,
          });
        }
      }
    },
    [createJournalEntry],
  );

  return {
    createJournalEntry: func,
    loading,
    error,
  };
};
