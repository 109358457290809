import { useCallback } from 'react';

import { OasMemoryError, OasError } from 'core/atoms/errors';

import { IssueAccessTokenResult } from 'auth/dna/types';
import axios from 'axios';
import { runtimeEnv } from '../../../../../../core/atoms/env';
import { getSessionTokenStorage } from '../../../../browser';
import { parseJwt } from '../../../../../dna/functions';

const env = runtimeEnv();

const TITLE = 'use-issue-access-token';

export const useIssueAccessToken = () => {
  const { getSessionToken } = getSessionTokenStorage();

  const func = useCallback(
    async (pincode: string): Promise<IssueAccessTokenResult> => {
      const sessionToken = getSessionToken();

      if (!sessionToken) {
        throw OasMemoryError.fromError('No sessionToken', {
          title: TITLE,
        });
      }

      const sessionTokenDecoded = parseJwt(sessionToken!);
      const jti = sessionTokenDecoded['jti'] as string;

      try {
        const accessTokensUri = `${env.REACT_APP_CORE_URI}/v1/sessions/${jti}/accessTokens`;
        const result = await axios.put(`${accessTokensUri}`, {
          pin: pincode,
          sessionToken: sessionToken,
        });

        if (!result || !result.data?.accessToken) {
          throw new OasMemoryError('Not Found', {
            title: TITLE,
          });
        }
        return result.data;
      } catch (e: any) {
        if (e instanceof OasError) {
          throw e;
        } else {
          return {
            error: e.error?.text || 'Pin code invalid!',
          };
          // throw OasMemoryError.fromError(e, {
          //   title: TITLE,
          // });
        }
      }
    },
    [getSessionToken],
  );

  return {
    issueAccessToken: func,
  };
};
