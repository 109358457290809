import { useOrganization } from 'core/metabolism/organizations';

import { useGetTermsState } from 'core/memory/apollo/terms/local';

export const useTerms = () => {
  const { organization, loading, error } = useOrganization();
  const { data: termsState } = useGetTermsState();

  return {
    loading,
    error,
    terms: organization?.terms,
    selectedTermId: termsState?.selected ?? undefined,
  };
};
