import { Dispatch, SetStateAction } from 'react';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import Typography from '@mui/material/Typography';

import { TreeItem, TreeView } from '@mui/lab';
import { TreeNode } from './types';

type Node = TreeNode<{ groupId: string; studentId?: string; tuid?: string }>;
export const bfsSearch = (graph: Node[], targetId: string) => {
  const queue = [...graph];

  while (queue.length > 0) {
    const currNode = queue.shift()!;
    if (currNode.id === targetId) {
      return currNode;
    }
    if (currNode.children) {
      queue.push(...currNode.children);
    }
  }
  return; // Target node not found
};

export const Tree = ({
  data,
  selected = [],
  setSelected,
}: {
  data: Node[];
  selected: string[];
  setSelected: Dispatch<SetStateAction<string[]>>;
}) => {
  // Retrieve all ids from node to his children's
  function getAllIds(node: Node, idList: string[] = []) {
    idList.push(node.id);
    if (node.children) {
      node.children.forEach((child) => getAllIds(child, idList));
    }
    return idList;
  }

  // Get IDs of all children from specific node
  const getAllChild = (id: string) => {
    return getAllIds(bfsSearch(data, id)!);
  };

  // Get all father IDs from specific node
  const getAllFathers = (id: string, list: string[] = []): string[] => {
    const node = bfsSearch(data, id);
    if (node?.parent) {
      list.push(node.parent);

      return getAllFathers(node.parent, list);
    }

    return list;
  };

  function isAllChildrenChecked(node: Node, list: string[]) {
    const allChild = getAllChild(node.id);
    const nodeIdIndex = allChild.indexOf(node.id);
    allChild.splice(nodeIdIndex, 1);

    return allChild.every((nodeId) => selected.concat(list).includes(nodeId));
  }

  const handleNodeSelect = (event: any, nodeId: string) => {
    event.stopPropagation();
    const allChild = getAllChild(nodeId);
    const fathers = getAllFathers(nodeId);

    if (selected.includes(nodeId)) {
      // Need to de-check
      setSelected((prevSelectedNodes) =>
        prevSelectedNodes.filter(
          (id) => !allChild.concat(fathers).includes(id),
        ),
      );
    } else {
      // Need to check
      const ToBeChecked = allChild;
      for (let i = 0; i < fathers.length; ++i) {
        if (isAllChildrenChecked(bfsSearch(data, fathers[i])!, ToBeChecked)) {
          ToBeChecked.push(fathers[i]);
        }
      }
      setSelected((prevSelectedNodes) =>
        [...prevSelectedNodes].concat(ToBeChecked),
      );
    }
  };

  const handleExpandClick = (event: any) => {
    // prevent the click event from propagating to the checkbox
    // event.stopPropagation();
  };

  const renderTree = (node: Node) => (
    <TreeItem
      key={node.id}
      nodeId={node.id}
      onClick={handleExpandClick}
      label={
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Checkbox
            sx={{ marginRight: '8px !important' }}
            checked={selected.indexOf(node.id) !== -1}
            tabIndex={-1}
            disableRipple
            onClick={(event) => handleNodeSelect(event, node.id)}
          />
          <Typography variant="body1">{node.text}</Typography>
        </Box>
      }
    >
      {Array.isArray(node.children)
        ? node.children.map((node) => renderTree(node))
        : null}
    </TreeItem>
  );

  return (
    <TreeView
      multiSelect
      defaultCollapseIcon={<ExpandMoreIcon />}
      defaultExpandIcon={<ChevronRightIcon />}
      selected={selected}
    >
      {data.map((node) => renderTree(node))}
    </TreeView>
  );
};
