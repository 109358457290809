import { useTranslations } from 'core/dna/translations';
import { useOrganization } from 'core/metabolism/organizations';
import {
  JournalType,
  OrganizationCategoryType,
} from 'core/dna/types/generated/_globalTypes';
import { useMemo } from 'react';

export const useGetJournalActions = () => {
  const { texts } = useTranslations();
  const { organization } = useOrganization();

  const actions = useMemo(() => {
    return {
      note: {
        text: texts.studentModule.journal.addNote as string,
        value: JournalType.note,
      },
      sleep: {
        text: texts.studentModule.journal.addSleep as string,
        value: JournalType.sleep,
      },
      diaper: {
        text: texts.studentModule.journal.addDiaper as string,
        value: JournalType.diaper,
      },
      restock: {
        text: texts.studentModule.journal.addRestock as string,
        value: JournalType.restock,
      },
    };
  }, [texts]);

  const func = useMemo(() => {
    switch (organization?.category) {
      case OrganizationCategoryType.barnehage:
        return [actions.note, actions.sleep, actions.diaper, actions.restock];

      default:
        return [actions.note, actions.restock];
    }
  }, [actions, organization?.category]);

  return { journalActionsByOrgCategory: func };
};
