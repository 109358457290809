import { useMemo } from 'react';

import { tick } from 'core/atoms/date-time';
import { timeShort } from 'core/atoms/date-time/formats';
import { WorkingHours } from 'modules/planner/dna/types/working-hours';

import {
  useUpdateOpeningHours,
  useGetOrganization,
} from 'core/memory/apollo/organizations/remote';

export const useOrganization = () => {
  const { loading, error, data: organization } = useGetOrganization();

  const { updateOpeningHours } = useUpdateOpeningHours();

  const workingHours = useMemo<WorkingHours | undefined>(() => {
    if (organization) {
      return organization.openingHours?.reduce<WorkingHours>((prev, cur) => {
        const curMin = tick(cur.startTime, timeShort);
        const prevMin = tick(prev.minTime, timeShort);
        const curMax = tick(cur.endTime, timeShort);
        const prevMax = tick(prev.maxTime, timeShort);
        return {
          minTime:
            !prev.minTime || curMin.isBefore(prevMin)
              ? cur.startTime
              : prev.minTime,
          maxTime:
            prev.maxTime && curMax.isBefore(prevMax)
              ? prev.maxTime
              : cur.endTime,
        };
      }, {});
    }
  }, [organization]);

  return {
    loading,
    error,
    organization,
    updateOpeningHours,
    startTime: workingHours?.minTime,
    endTime: workingHours?.maxTime,
  };
};
