import { useCallback, useState, forwardRef, Fragment } from 'react';
import clsx from 'clsx';
import Slide from '@material-ui/core/Slide';
import DateRangeIcon from '@material-ui/icons/DateRange';

import { makeStyles, createStyles } from 'core/atoms/styles';
import { parseTemplate } from 'core/atoms/functions/string';

import { Box } from 'core/cells/box';
import { Dialog } from 'core/cells/dialog';
import { DialogTitle } from 'core/cells/dialog-title';
import { DialogContent } from 'core/cells/dialog-content';
import { DialogContentText } from 'core/cells/dialog-content-text';
import { DialogActions } from 'core/cells/dialog-actions';

import { useTranslations } from 'core/dna/translations';

import { Button } from 'templates/mdp/components/custom-buttons';
import {
  CardHeader,
  CardIcon,
  CardBody,
  Card,
} from 'templates/mdp/components/card';
import { Danger } from 'templates/mdp/components/typography';
import { buttonStyle } from 'templates/mdp/components/custom-buttons/button.styles';

import { useStyles as useNotificationsStyles } from 'templates/mdp/views/components/notifications.styles';

import { AssignedWeek } from 'modules/planner/dna/types/assigned-week';

import { useGetSchedulesSelectedState } from 'modules/planner/memory/apollo/schedules/local';
import {
  useAllocateSchedule,
  useDeleteAllocation,
  useSynchronizeAllocationSchedules,
} from 'modules/planner/memory/apollo/allocation/remote';

import { useTerm } from 'modules/planner/metabolism/terms';
import { useTermWeeks } from 'modules/planner/metabolism/use-term-weeks';

import { ScheduleSelector } from 'modules/planner/organisms/schedule-selector';

import { Week } from './components';

const Transition: any = forwardRef(function Transition(props: any, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const useStyles = makeStyles(() =>
  createStyles({
    ...buttonStyle,
    marginRight: {
      marginRight: 5,
    },
  }),
);

export const Weeks = () => {
  const notificationsClasses = useNotificationsStyles();
  const classes = useStyles();
  const { texts } = useTranslations();
  const { termStart, termEnd } = useTerm();
  const { data: selectedSchedules } = useGetSchedulesSelectedState();
  const { termWeeks, findAllocationForWeek, loading, error } = useTermWeeks();
  const { deleteAllocation } = useDeleteAllocation();
  const { allocateSchedule } = useAllocateSchedule();
  const { synchronizeAllocateSchedules } = useSynchronizeAllocationSchedules();

  const [selectedWeek, setSelectedWeek] = useState<{
    week: number;
    year: number;
  } | null>(null);

  const [dangerText, setDangerText] = useState('');

  const onWeekClick = useCallback(
    (year: number, week: number) => {
      const allocation = findAllocationForWeek({ week, year });
      if (allocation) {
        deleteAllocation({
          scheduleId: allocation.schedule.id,
          week,
          year,
        }).then(() => {
          synchronizeAllocateSchedules().then();
        });
      } else {
        setSelectedWeek({ week, year });
      }
    },
    [deleteAllocation, findAllocationForWeek, synchronizeAllocateSchedules],
  );

  const handleDialogClose = useCallback(() => {
    setSelectedWeek(null);
  }, []);

  const handleDialogAssign = useCallback(() => {
    if (selectedSchedules?.length !== 1) {
      setDangerText('Only one schedule should be selected');
      return;
    }
    if (selectedWeek) {
      allocateSchedule({
        scheduleId: selectedSchedules[0],
        week: selectedWeek.week,
        year: selectedWeek.year,
      }).then(() => {
        synchronizeAllocateSchedules().then();
      });
      // editSchedule({
      //   scheduleId: selectedSchedules[0],
      //   weeks: [
      //     ...mapAssignedWeeksToWeeksInput(
      //       data?.filter((w) => w.scheduleId === selectedSchedules[0]) ?? [],
      //     ),
      //     mapWeekToWeekInput(selectedWeek),
      //   ],
      // }).then();
      setSelectedWeek(null);
    }
  }, [
    allocateSchedule,
    selectedSchedules,
    selectedWeek,
    synchronizeAllocateSchedules,
  ]);

  if (error) return <p>Error to load schedule weeks!</p>;
  if (loading) return <p>Loading...</p>;

  return (
    <Card>
      <CardHeader color="primary" icon>
        <CardIcon color="primary">
          <DateRangeIcon />
        </CardIcon>
      </CardHeader>
      <CardBody>
        <h3>{termStart.format('YYYY')}</h3>
        <Box m={1}>
          {termWeeks.map((tw) => (
            <Fragment key={tw.weekNumber}>
              <Week
                weekText={String(tw.weekNumber)}
                scheduleText={tw.schedule?.name ?? ''}
                assigned={!!tw.schedule}
                onClick={() => onWeekClick(tw.year, tw.weekNumber)}
              />
            </Fragment>
          ))}
        </Box>
        <h3>{termEnd.format('YYYY')}</h3>
        <Dialog
          TransitionComponent={Transition}
          open={!!selectedWeek}
          onClose={handleDialogClose}
          aria-labelledby="duplicate-schedule"
          classes={{
            root: `${notificationsClasses.center} ${notificationsClasses.modalRoot}`,
            paper: notificationsClasses.modal,
          }}
        >
          <DialogTitle
            id="assign-schedule"
            className={notificationsClasses.modalHeader}
          >
            {parseTemplate(texts.planner.allocationStep.selectScheduleForWeek, {
              week: selectedWeek?.week,
            })}
          </DialogTitle>
          <DialogContent className={notificationsClasses.modalBody}>
            <DialogContentText>
              <ScheduleSelector singleSelection />
              <Danger>{dangerText}</Danger>
            </DialogContentText>
          </DialogContent>
          <DialogActions className={notificationsClasses.modalFooter}>
            <Button
              autoFocus
              color="primary"
              className={clsx(
                classes.button,
                classes.primary,
                classes.marginRight,
              )}
              onClick={handleDialogAssign}
            >
              {texts.oasCommon.assign}
            </Button>
            <Button
              simple
              color="primary"
              className={classes.marginRight}
              onClick={handleDialogClose}
            >
              {texts.planner.common.cancel}
            </Button>
          </DialogActions>
        </Dialog>
      </CardBody>
    </Card>
  );
};
