import { CSSProperties, makeStyles, createStyles } from 'core/atoms/styles';

import { cardTitle } from '../../material-dashboard-pro-react';

export interface RegularTablesStyle {
  customCardContentClass: CSSProperties;
  cardIconTitle: CSSProperties;
}

const regularTablesStyle: RegularTablesStyle = {
  customCardContentClass: {
    paddingLeft: '0',
    paddingRight: '0',
  },
  cardIconTitle: {
    ...cardTitle,
    marginTop: '15px',
    marginBottom: '0px',
  },
};

export const useStyles = makeStyles(() =>
  createStyles({ ...regularTablesStyle }),
);
