/*eslint-disable*/
import React from 'react';
import clsx from 'clsx';

// @material-ui/core components
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';

import { useTranslations } from 'core/dna/translations';

import { useStyles } from './footer.styles';

export interface FooterProps {
  fluid?: boolean; // PropTypes.bool,
  white?: boolean; // PropTypes.bool,
  rtlActive?: boolean; // PropTypes.bool,
}

export const Footer = (props: FooterProps) => {
  const { fluid, white, rtlActive } = props;

  const classes = useStyles();
  const { lang } = useTranslations();

  const container = clsx({
    [classes.container]: !fluid,
    [classes.containerFluid]: fluid,
    [classes.whiteColor]: white,
  });

  const block = clsx({
    [classes.block]: true,
    [classes.whiteColor]: white,
  });

  return (
    <footer className={classes.footer}>
      <div className={container}>
        <div className={classes.left}>
          <List className={classes.list}>
            <ListItem className={classes.inlineBlock}>
              <a
                href="https://transponder.no"
                target="_blank"
                className={block}
              >
                {rtlActive ? lang.common.home : lang.common.home}
              </a>
            </ListItem>
          </List>
        </div>
        <p className={classes.right}>&copy; Transponder AS</p>
      </div>
    </footer>
  );
};
