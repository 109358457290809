import {
  useGetSelectedRoomsState,
  useSetSelectedRoomsState,
} from 'modules/planner/memory/apollo/rooms/local';
import {
  useGetRooms,
  useUpdateAvailabilityPreferencesForRooms,
} from 'modules/planner/memory/apollo/rooms/remote';

export const useRooms = () => {
  const { rooms, roomsLoading, roomsError } = useGetRooms();

  const {
    selectedRoomsState,
    loading: roomStateLoading,
    error: roomStateError,
  } = useGetSelectedRoomsState();

  const { setSelectedRoomsState } = useSetSelectedRoomsState();
  const {
    updateAvailabilityPreferencesForRooms,
  } = useUpdateAvailabilityPreferencesForRooms();

  return {
    rooms,
    selectedRooms: selectedRoomsState || [],
    setSelectedRooms: setSelectedRoomsState,
    updateAvailabilityPreferencesForRooms,
    roomsLoading: roomsLoading || roomStateLoading,
    roomsError: roomsError || roomStateError,
  };
};
