/* eslint-disable */
import React, { ReactNode } from 'react';

import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';

// @material-ui/icons
import ExpandMore from '@material-ui/icons/ExpandMore';

import { useStyles } from './accordion.styles';

interface AccordionCollapse {
  title?: string; // PropTypes.string,
  content?: ReactNode; // PropTypes.node,
}

export interface AccordionProps {
  // index of the default active collapse
  active?: number; // PropTypes.number,
  collapses: AccordionCollapse[]; // AccordionCollapse PropTypes.arrayOf(PropTypes.shape()).isRequired,
}

const defaultProps: Partial<AccordionProps> = {
  active: -1,
};

export const Accordion = (props: AccordionProps) => {
  const [active, setActive] = React.useState(
    props.active ?? defaultProps.active!,
  );
  const handleChange = (panel: any) => (event: any, expanded: any) => {
    setActive(expanded ? panel : -1);
  };
  const classes = useStyles();
  const { collapses } = props;

  return (
    <div className={classes.root}>
      {collapses.map((prop, key) => {
        return (
          <ExpansionPanel
            expanded={active === key}
            onChange={handleChange(key)}
            key={key}
            classes={{
              root: classes.expansionPanel,
              expanded: classes.expansionPanelExpanded,
            }}
          >
            <ExpansionPanelSummary
              expandIcon={<ExpandMore />}
              classes={{
                root: classes.expansionPanelSummary,
                expanded: classes.expansionPanelSummaryExpanded,
                content: classes.expansionPanelSummaryContent,
                expandIcon: classes.expansionPanelSummaryExpandIcon,
              }}
            >
              <h4 className={classes.title}>{prop.title}</h4>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails className={classes.expansionPanelDetails}>
              {prop.content}
            </ExpansionPanelDetails>
          </ExpansionPanel>
        );
      })}
    </div>
  );
};

Accordion.defaultProps = defaultProps;
