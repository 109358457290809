import React from 'react';

import { List } from '@material-ui/icons';

import { Skeleton } from 'core/cells/skeleton';
import { createReactTableStore } from 'core/memory/pure/react-table-store';
import { useGetTermsState } from 'core/memory/apollo/terms/local/get-terms-state';
import { useReactTableState } from 'core/metabolism/table-state';

import { GridContainer, GridItem } from 'templates/mdp/components/grid';
import {
  Card,
  CardBody,
  CardHeader,
  CardIcon,
} from 'templates/mdp/components/card';
import { Table } from 'templates/mdp/components/react-table';
import { useStyles as reactTablesStyle } from 'templates/mdp/views/tables/react-tables.styles';

import { GroupWithTotalAbsences } from 'modules/reports/dna/types';

import { useGroupsAbsenceTableRead } from './use-groups-absence-table-read';

interface GroupsAbsenceTableProps {
  data: GroupWithTotalAbsences[];
  loading: boolean;
}

export const groupsT2absencesTableStore = createReactTableStore({}, {});

export const GroupsAbsenceTable = ({
  data,
  loading,
}: GroupsAbsenceTableProps) => {
  const reactTablesClasses = reactTablesStyle();

  const { data: termsState } = useGetTermsState();

  const { columns, tableData, title } = useGroupsAbsenceTableRead(data);

  const {
    initialPage,
    pageSize,
    sort,
    filters,
    setSort,
    setFilters,
    setPage,
    setPageSize,
  } = useReactTableState(
    groupsT2absencesTableStore,
    termsState?.selected ?? undefined,
  );

  return loading || !termsState?.selected ? (
    <Skeleton variant="rect" width="100%" height="80vh" />
  ) : (
    <GridContainer spacing={3} justify="center">
      <GridItem xs={12}>
        <Card>
          <CardHeader color="primary" icon>
            <CardIcon color="primary">
              <List />
            </CardIcon>
            <h4 className={reactTablesClasses.cardIconTitle}>{title}</h4>
          </CardHeader>
          <CardBody>
            <Table
              noActions
              columns={columns}
              data={tableData ?? []}
              key={termsState.selected}
              onChangeRowsPerPage={setPageSize}
              onChangePage={setPage}
              onFilterChange={setFilters}
              onOrderChange={setSort}
              options={{
                initialPage,
                pageSize,
                sort,
                filters,
              }}
            />
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
};
