import React, { useMemo } from 'react';

import { parseTemplate } from 'core/atoms/functions/string';

import { useLocale } from 'core/dna/routing';
import { useTranslations } from 'core/dna/translations';

import { ScheduleTableItem } from 'modules/planner/dna/types/schedule';

import PlannerUrls from 'modules/planner/routing/urls';

import { ColPageLink } from './col-page-link';

export interface ColLessonGroupsProps {
  item: ScheduleTableItem;
}

export const ColLessonGroups = (props: ColLessonGroupsProps) => {
  const { lang } = useTranslations();
  const locale = useLocale();

  const { item } = props;

  const text = useMemo(() => {
    if (!item.lessonGroupCount || item.lessonGroupCount < 1) {
      return lang.common.create;
    }
    if (item.lessonGroupCount === 1) {
      return lang.common.definedCountChange.one;
    }
    return parseTemplate(lang.common.definedCountChange.many, {
      count: item.lessonGroupCount,
    });
  }, [item.lessonGroupCount, lang]);

  return item.lessonGroupCount ? (
    <ColPageLink
      item={item}
      pageUrl={PlannerUrls.lessonGroups({ locale })}
      text={text}
    />
  ) : (
    <ColPageLink
      item={item}
      pageUrl={PlannerUrls.createLessonGroup({ locale })}
      text={text}
    />
  );
};
