import { useCallback } from 'react';
import axios from 'axios';

import { OasMemoryError, OasError } from 'core/atoms/errors';
import { runtimeEnv } from 'core/atoms/env';
import { useAuthContext } from 'auth/metabolism/use-auth-context';
import { IssueAccessTokenPinValid } from 'auth/dna/types';

const env = runtimeEnv();

const TITLE = 'use-reissue-access-token';

export const useReissueAccessToken = () => {
  const { getIdentity } = useAuthContext();
  const identity = getIdentity();

  const func = useCallback(async (): Promise<IssueAccessTokenPinValid> => {
    if (!identity) {
      throw OasMemoryError.fromError('No identity', {
        title: TITLE,
      });
    }

    try {
      const accessTokensUri = `${env.REACT_APP_CORE_URI}/v1/sessions/${identity.session.jti}/accessTokens`;
      const result = await axios.put(
        `${accessTokensUri}`,
        { accessToken: identity.token, sessionToken: identity.session.token },
        { headers: { Authorization: `Bearer ${identity.token}` } },
      );

      if (!result || !result.data?.accessToken) {
        throw new OasMemoryError('Not Found', {
          title: TITLE,
        });
      }
      return result.data;
    } catch (e: any) {
      if (e instanceof OasError) {
        throw e;
      } else {
        throw OasMemoryError.fromError(e, {
          title: TITLE,
        });
      }
    }
  }, [identity]);

  return {
    reissueAccessToken: func,
  };
};
