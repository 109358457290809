import { CSSProperties, makeStyles, createStyles } from 'core/atoms/styles';

import {
  cardTitle,
  blackColor,
  hexToRgb,
} from '../../material-dashboard-pro-react';

import {
  customCheckboxRadioSwitch,
  CustomCheckboxRadioSwitch,
} from '../../custom-checkbox-radio-switch';
import {
  customSelectStyle,
  CustomSelectStyle,
} from '../../custom-select-style';

interface ExtendedFormsStyleInternal {
  cardTitle: CSSProperties;
  cardIconTitle: CSSProperties;
  label: CSSProperties;
  mrAuto: CSSProperties;
  mlAuto: CSSProperties;
  block: CSSProperties;
}

export type ExtendedFormsStyle = CustomCheckboxRadioSwitch &
  CustomSelectStyle &
  ExtendedFormsStyleInternal;

export const extendedFormsStyle: ExtendedFormsStyle = {
  ...customCheckboxRadioSwitch,
  ...customSelectStyle,
  cardTitle,
  cardIconTitle: {
    ...cardTitle,
    marginTop: '15px',
    marginBottom: '0px',
  },
  label: {
    cursor: 'pointer',
    paddingLeft: '0',
    color: `rgba(${hexToRgb(blackColor)}, 0.26)`,
    fontSize: '14px',
    lineHeight: '1.428571429',
    fontWeight: 400,
    display: 'inline-flex',
  },
  mrAuto: {
    marginRight: 'auto',
  },
  mlAuto: {
    marginLeft: 'auto',
  },
  block: {},
};

export const useStyles = makeStyles(() =>
  createStyles({ ...extendedFormsStyle }),
);
