import moment from 'moment';
import { dateNo, apiFormat } from 'core/atoms/date-time/formats';
import { OasError } from '../../errors';

export const formatDateString = (time: string) => {
  const m = moment(time, apiFormat);

  if (m) {
    return m.format(dateNo);
  }

  throw new OasError('Time string should be "YYYY-MM-DDTHH:mm:ss" format', {
    title: 'format-time-string',
    type: 'OasError',
  });
};
