import { gql } from 'core/dna/types/apollo';

export const SUBSTITUTE_TASK_DATA_FRAGMENT = gql`
  fragment SubstituteTaskData on SubstituteTask {
    id
    subject
    comment
    date {
      formatted
    }
    timeFrom {
      formatted
    }
    timeTo {
      formatted
    }
    responses {
      SubstituteTeacher {
        id
        firstName
        lastName
      }
      response
    }
  }
`;

export const EXECUTION_STATUS = gql`
  fragment ExecutionStatus on ExecutionStatus {
    status
    statusCode
    description
  }
`;
