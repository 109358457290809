import { CSSProperties, makeStyles, createStyles } from 'core/atoms/styles';

import { boxShadow } from '../../material-dashboard-pro-react';

export interface IconsStyle {
  iframe: CSSProperties;
  iframeContainer: CSSProperties;
}

export const iconsStyle: IconsStyle = {
  iframe: {
    width: '100%',
    height: '500px',
    border: '0',
    ...boxShadow,
  },
  iframeContainer: {
    margin: '0 -20px 0',
  },
};

export const useStyles = makeStyles(() => createStyles({ ...iconsStyle }));
