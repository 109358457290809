import React, { ReactNode } from 'react';
import clsx from 'clsx';

import { GridContainer, GridItem } from '../grid';

import { useStyles } from './instruction.styles';

export interface InstructionProps {
  title: ReactNode; // PropTypes.node.isRequired,
  text: ReactNode; // PropTypes.node.isRequired,
  image: string; // PropTypes.string.isRequired,
  imageAlt?: string; // PropTypes.string,
  className?: string; // PropTypes.string,
  imageClassName?: string; // PropTypes.string,
}

export const Instruction = (props: InstructionProps) => {
  const classes = useStyles();
  const { title, text, image, className, imageClassName, imageAlt } = props;
  const instructionClasses = clsx(
    classes.instruction,
    className !== undefined ? className : '',
  );
  const pictureClasses = clsx(
    classes.picture,
    imageClassName !== undefined ? imageClassName : '',
  );
  return (
    <div className={instructionClasses}>
      <GridContainer>
        <GridItem xs={12} sm={12} md={8}>
          <strong>{title}</strong>
          <p>{text}</p>
        </GridItem>
        <GridItem xs={12} sm={12} md={4}>
          <div className={pictureClasses}>
            <img src={image} alt={imageAlt} className={classes.image} />
          </div>
        </GridItem>
      </GridContainer>
    </div>
  );
};

Instruction.defaultProps = {
  imageAlt: '...',
};
