export interface Neo4jTime {
  formatted?: string;
  hour?: number;
  minute?: number;
  second?: number;
  millisecond?: number;
  microsecond?: number;
  nanosecond?: number;
  timezone?: string;
}

export interface Neo4jLocalTime {
  formatted?: string;
  hour?: number;
  minute?: number;
  second?: number;
  millisecond?: number;
  microsecond?: number;
  nanosecond?: number;
}

export interface OasTime {
  formatted?: string;
  hour?: number;
  minute?: number;
  second?: number;
}

export const mapNeo4JTimeToOasTime = (time: Neo4jTime): OasTime => {
  const { formatted, hour, minute, second } = time;
  return {
    formatted,
    hour,
    minute,
    second,
  };
};

export enum Weekday {
  Sunday,
  Monday,
  Tuesday,
  Wednesday,
  Thursday,
  Friday,
  Saturday,
}

export type WeekdayType = 0 | 1 | 2 | 3 | 4 | 5 | 6;

export interface WeekdayObject {
  title: string;
  letter: string;
  day: number;
}

export interface WeekObject {
  [key: string]: WeekdayObject;
}

export enum Month {
  January,
  February,
  March,
  April,
  May,
  June,
  July,
  August,
  September,
  October,
  November,
  December,
}

export type MonthType = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11;

export interface MonthObject {
  long: string;
  short: string;
  num: number;
}

export interface YearObject {
  [key: string]: MonthObject;
}
