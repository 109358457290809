import { GetAllocationPlans_AllocationPlan_allocations as ApiAllocation } from 'core/dna/types/generated/GetAllocationPlans';

import { Allocation } from 'modules/planner/dna/types/allocation';
import { mapApiWeekToWeek } from '../map-ApiWeek-to-Week';

export const mapApiAllocationListToAllocationList = (
  apiAllocationList: (ApiAllocation | null)[] | null,
): Allocation[] | undefined => {
  if (!apiAllocationList) {
    return [];
  }
  const result: Allocation[] = [];
  apiAllocationList.forEach((apiAllocation) => {
    const a = mapApiAllocationToAllocation(apiAllocation);
    if (a) {
      result.push(a);
    }
  });
  return result;
};

export const mapApiAllocationToAllocation = (
  apiAllocation: ApiAllocation | null,
): Allocation | undefined => {
  if (!apiAllocation) {
    return;
  }
  const { Schedule, weekNumber, published } = apiAllocation;
  const w = mapApiWeekToWeek(weekNumber);
  if (!w) {
    return;
  }

  return {
    schedule: {
      id: Schedule?.id ?? '',
      text: Schedule?.name ?? '',
    },
    published: !!published,
    ...w,
  };
};
