import { useMemo } from 'react';
import { useQuery } from 'core/dna/types/apollo';
import { GetJournals } from 'core/dna/types/generated/GetJournals';
import { mapApiPersonT2JournalsToPersonT2JournalsList } from 'modules/student/dna/functions';
import { ShortDataWithJournals } from 'modules/student/dna/types';
import { GET_JOURNALS_QUERY } from './gql';

export const useGetJournals = () => {
  const { data, error, loading } = useQuery<GetJournals>(GET_JOURNALS_QUERY);

  const journals = useMemo<ShortDataWithJournals[] | undefined>(() => {
    if (loading || error || !data) {
      return;
    }

    return mapApiPersonT2JournalsToPersonT2JournalsList(data.PersonT2);
  }, [loading, error, data]);

  return {
    loading,
    error,
    data: journals,
  };
};
