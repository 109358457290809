import { makeStyles, createStyles, Theme } from 'core/atoms/styles';

import { Select } from 'core/cells/select5';

import { useTranslations } from 'core/dna/translations';

import { useClassSelector } from './use-class-selector';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    select: {
      width: 200,
    },
    separator: {
      fontSize: theme.spacing(3),
    },
  }),
);

export const ClassSelector = () => {
  const classes = useStyles();
  const { lang } = useTranslations();

  const {
    classes: classesData,
    selectedClasses,
    setSelectedClassesState,
    // onChange,
    classesLoading,
    classesError,
  } = useClassSelector();

  if (classesLoading) return <p>Loading Classes...</p>;
  if (classesError) return <p>Classes Error!</p>;

  return (
    <Select
      multiple
      name="classes"
      label={lang.common.classes}
      data={classesData}
      value={selectedClasses}
      className={classes.select}
      minWidth={200}
      onChange={setSelectedClassesState}
    />
  );
};
