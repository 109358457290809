import { useMemo } from 'react';

import { gql, useQuery } from 'core/dna/types/apollo';
import { GetSelectedRoomsState } from 'core/dna/types/generated/GetSelectedRoomsState';
import {
  mapNullableListToList,
  mapNullableStringToString,
} from 'core/dna/functions';

const GET_SELECTED_ROOMS_STATE_QUERY = gql`
  query GetSelectedRoomsState {
    selectedRoomsState @client
  }
`;

export const useGetSelectedRoomsState = () => {
  const { data, loading, error } = useQuery<GetSelectedRoomsState>(
    GET_SELECTED_ROOMS_STATE_QUERY,
  );

  const selectedRoomsState = useMemo<string[] | undefined>(() => {
    if (!loading && !error && data) {
      return (
        mapNullableListToList(
          data.selectedRoomsState,
          mapNullableStringToString,
        ) ?? undefined
      );
    }
  }, [loading, error, data]);

  return {
    selectedRoomsState,
    loading,
    error,
  };
};
