import React, { useMemo } from 'react';

import { AssignmentInd } from '@material-ui/icons';
import { Skeleton } from 'core/cells/skeleton';
import { useTranslations } from 'core/dna/translations';
import { NoData } from 'core/organisms/no-data';

import { Table } from 'templates/mdp/components/react-table';
import { GridContainer, GridItem } from 'templates/mdp/components/grid';
import {
  Card,
  CardBody,
  CardHeader,
  CardIcon,
} from 'templates/mdp/components/card';
import { useStyles } from 'templates/mdp/views/tables/react-tables.styles';

import { formatDateTimeString } from 'core/atoms/functions/string';
import { dateTimeShortForTableSorting } from 'core/atoms/date-time/formats';
import { createReactTableStore } from 'core/memory/pure/react-table-store';
import { useGetTermsState } from 'core/memory/apollo/terms/local/get-terms-state';
import { useReactTableState } from 'core/metabolism/table-state';

import { useAssessmentsTableRead } from './use-assessments-table-read';

export const assessmentsTableStore = createReactTableStore({}, {});

export const AssessmentsTable = () => {
  const classes = useStyles();
  const { texts } = useTranslations();

  const { data: termsState } = useGetTermsState();

  const { columns, data, title, loading } = useAssessmentsTableRead();

  const {
    initialPage,
    pageSize,
    sort,
    filters,
    setSort,
    setFilters,
    setPage,
    setPageSize,
  } = useReactTableState(
    assessmentsTableStore,
    termsState?.selected ?? undefined,
  );

  const preparedData = useMemo(
    () =>
      data?.map((tableItem) => ({
        ...tableItem,
        type: !tableItem.type
          ? ''
          : texts.grades.gradesCommon.batchType[tableItem.type],
        deadlineAt: !tableItem.deadlineAt
          ? ''
          : formatDateTimeString(
              tableItem.deadlineAt,
              dateTimeShortForTableSorting,
            ),
      })),
    [texts, data],
  );

  if ((!data || !data.length) && !loading) {
    return <NoData text={texts.grades.assessment.noData} />;
  }

  return !termsState?.selected || loading ? (
    <Skeleton variant="rect" width="100%" height="80vh" />
  ) : (
    <GridContainer spacing={3} justifyContent="center">
      <GridItem xs={12}>
        <Card>
          <CardHeader color="primary" icon>
            <CardIcon color="primary">
              <AssignmentInd />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>{title}</h4>
          </CardHeader>
          <CardBody>
            <Table
              noActions
              columns={columns}
              data={preparedData ?? []}
              key={termsState.selected}
              onChangeRowsPerPage={setPageSize}
              onChangePage={setPage}
              onFilterChange={setFilters}
              onOrderChange={setSort}
              options={{
                initialPage,
                pageSize,
                sort,
                filters,
              }}
            />
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
};
