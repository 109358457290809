import React from 'react';
import { ApolloError } from '@apollo/client/errors';
import { Typography } from '@material-ui/core';
import { Grid } from 'core/cells/grid';
import { Skeleton } from 'core/cells/skeleton';
import { Carer } from 'modules/student/dna/types';
import { useTranslations } from 'core/dna/translations';
import { CarerTable } from './components/carer-table';

interface StudentCarersProps {
  data: Carer[] | null | undefined;
  error: ApolloError | undefined;
}

export const StudentCarers = ({ data, error }: StudentCarersProps) => {
  const { texts } = useTranslations();

  if (
    error &&
    data === null &&
    error.graphQLErrors.some(
      (e) => e.path && e.path[e.path.length - 1] === 'carers',
    )
  ) {
    return (
      <Typography variant="h5" color="textSecondary" align="center">
        {texts.oasCommon.notAuthForResource}
      </Typography>
    );
  }

  return !data ? (
    <Skeleton variant="rect" width="100%" height="80vh" />
  ) : (
    <Grid container spacing={3} justify="center">
      <Grid item xs={12} lg={8}>
        <CarerTable data={data} loading={false} />
      </Grid>
    </Grid>
  );
};
