import { useMemo } from 'react';

import { useTranslations } from 'core/dna/translations';
import { BatchState, BatchType } from 'core/dna/types/generated/_globalTypes';

import { useIdentity } from 'auth/metabolism';

import { EvaluationGroup } from 'modules/grades/dna/types';
import { useGetEvaluationGroup } from 'modules/grades/memory/apollo/evaluation/remote';

import { DisplayCellData } from 'modules/grades/pages/assessment/list/components/assessments-table/display-cell-data';

export interface TeacherAssessmentTableItem {
  subjectCode: string;
  type: BatchType;
  term: string;
  deadlineAt: string;
  state: BatchState;
  batchId: string;
}

export const useAssessmentsTableRead = () => {
  const { texts } = useTranslations();
  const identity = useIdentity();

  const { loading, data } = useGetEvaluationGroup({
    batchState: BatchState.open,
    teacherTuid: identity?.sub,
  });

  const mapEvaluationGroupsToTeacherAssessments = (
    groups?: EvaluationGroup[],
  ): TeacherAssessmentTableItem[] => {
    if (!groups || !groups.length) return [];

    const obj: any = {};
    groups.forEach((evg) => {
      if (evg.subjectCode && evg.evaluationBatch.id) {
        const uniqKey = `${evg.subjectCode}-${evg.evaluationBatch.id}`;
        obj[uniqKey] = {
          subjectCode: evg.subjectCode,
          type: evg.evaluationBatch.type,
          term: evg.evaluationBatch.term,
          deadlineAt: evg.evaluationBatch.deadlineAt,
          state: evg.evaluationBatch.state,
          batchId: evg.evaluationBatch.id,
        } as TeacherAssessmentTableItem;
      }
    });

    return Object.values(obj);
  };

  const columns = useMemo(() => {
    return [
      {
        Header: texts.grades.gradesCommon.subjectCode,
        accessor: 'subjectCode',
        Cell: (row: any) => <DisplayCellData {...row} />,
      },
      {
        Header: texts.oasCommon.type,
        accessor: 'type',
      },
      {
        Header: texts.grades.gradesCommon.subTerm,
        accessor: 'term',
      },
      {
        Header: texts.oasCommon.deadline,
        accessor: 'deadlineAt',
      },
    ];
  }, [texts]);

  const preparedData = useMemo<TeacherAssessmentTableItem[]>(() => {
    if (!data?.length) {
      return [];
    }

    return mapEvaluationGroupsToTeacherAssessments(data);
  }, [data]);

  return {
    title: texts.grades.assessment.title,
    columns,
    data: preparedData,
    loading,
  };
};
