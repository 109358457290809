import { forwardRef, ReactNode } from 'react';
import clsx from 'clsx';

import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';

import { WriteMode, WriteModeEnum } from 'core/dna/types/write-mode';

import { useStyles } from 'templates/mdp/views/components/notifications.styles';
import { useStyles as useValidationFormsStyles } from 'templates/mdp/views/forms/validation-forms.styles';
import {
  CardHeader,
  CardIcon,
  Card,
  CardBody,
} from 'templates/mdp/components/card';

interface WriteTableModalProps {
  title: string;
  children: ReactNode;
  isShow: boolean;
  onClose: any; // () => void;
  writeMode: WriteMode; // 'add' | 'edit';
  width?: number | string;
  [key: string]: any;
}

const Transition: any = forwardRef(function Transition(props: any, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export const WriteTableModal = (props: WriteTableModalProps) => {
  const { children, isShow, onClose, writeMode, title, width } = props;
  const classes = useStyles();
  const validationFormsClasses = useValidationFormsStyles();

  return (
    <Dialog
      classes={{
        root: clsx(classes.center, classes.modalRoot),
        paper: classes.modal,
      }}
      open={isShow}
      TransitionComponent={Transition}
      // keepMounted
      onClose={onClose}
      aria-labelledby="classic-modal-slide-title"
      aria-describedby="classic-modal-slide-description"
      PaperComponent={(props: any) => (
        <div style={{ width: width ?? 400 }}>
          <Card>
            <CardHeader color="primary" icon>
              <CardIcon color="primary">
                {writeMode === WriteModeEnum.create && <AddIcon />}
                {writeMode === WriteModeEnum.update && <EditIcon />}
              </CardIcon>
              <h4 className={validationFormsClasses.cardIconTitle}>{title}</h4>
            </CardHeader>
            <CardBody className={classes.modalBody}>{children}</CardBody>
          </Card>
        </div>
      )}
    />
  );
};
