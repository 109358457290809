import { useCallback } from 'react';

import { OasMemoryError, OasError } from 'core/atoms/errors';

import { gql, useMutation } from 'core/dna/types/apollo';

import { UpdateEvaluationGroupInput } from 'core/dna/types/generated/_globalTypes';
import {
  UpdateEvaluationGroup,
  UpdateEvaluationGroupVariables,
} from 'core/dna/types/generated/UpdateEvaluationGroup';

import { EVALUATION_GROUP_FRAGMENT } from 'modules/grades/memory/apollo/_fragments';
import { GET_EVALUATION_GROUP_QUERY } from './use-get-evaluation-group';

export const UPDATE_EVALUATION_GROUP_MUTATION = gql`
  mutation UpdateEvaluationGroup($input: UpdateEvaluationGroupInput) {
    updateEvaluationGroup(input: $input) {
      ...EvaluationGroup
    }
  }
  ${EVALUATION_GROUP_FRAGMENT}
`;

const TITLE = 'use-update-evaluation-group';

export const useUpdateEvaluationGroup = () => {
  const [updateEvaluationGroup, { loading, error }] = useMutation<
    UpdateEvaluationGroup,
    UpdateEvaluationGroupVariables
  >(UPDATE_EVALUATION_GROUP_MUTATION);

  const func = useCallback(
    async (input: UpdateEvaluationGroupInput, batchId: string) => {
      try {
        const result = await updateEvaluationGroup({
          variables: {
            input: { ...input },
          },
          refetchQueries: [
            {
              query: GET_EVALUATION_GROUP_QUERY,
              variables: { filter: { batchId } },
            },
          ],
        });

        if (result.errors) {
          throw new OasMemoryError(JSON.stringify(result.errors), {
            title: TITLE,
          });
        }
        return result.data?.updateEvaluationGroup ?? undefined;
      } catch (e: any) {
        if (e instanceof OasError) {
          throw e;
        } else {
          throw OasMemoryError.fromError(e, {
            title: TITLE,
          });
        }
      }
    },
    [updateEvaluationGroup],
  );

  return {
    updateEvaluationGroup: func,
    loading,
    error,
  };
};
