import clsx from 'clsx';

import { makeStyles, Theme, createStyles } from 'core/atoms/styles';

import { Backdrop } from 'core/cells/backdrop';
import { CircularProgress } from 'core/cells/circular-progress';

export interface ExpandedCircularProgressProps {
  open: boolean;
  invisible?: boolean;
  className?: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      zIndex: theme.zIndex.drawer + 1,
      color: (props: ExpandedCircularProgressProps) =>
        props.invisible ? theme.palette.primary.main : '#fff',
      position: 'absolute',
      width: '100%',
      height: '100%',
    },
  }),
);

export const ExpandedCircularProgress = (
  props: ExpandedCircularProgressProps,
) => {
  const { open, invisible, className } = props;
  const classes = useStyles(props);

  return (
    <Backdrop
      className={clsx(
        'expanded-circular-progress',
        classes.root,
        className ?? '',
      )}
      open={open}
      invisible={invisible}
    >
      <CircularProgress size={100} color="inherit" />
    </Backdrop>
  );
};
