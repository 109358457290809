import { useMemo, useCallback, useEffect, useState } from 'react';

import { Resource, EventInput } from 'core/cells/full-calendar';

import { UpdateAvailabilityPreferencesForEntityInput } from 'core/dna/types/generated/_globalTypes';
import {
  CalendarEvent,
  AvailabilityPreferencesEntityType,
} from 'core/dna/types';
import {
  mapAvailabilityPreferenceListToEventInputList,
  mapResourceListToAvailabilityPreferenceList,
  mapCalendarEventListToApiAvailabilityPreferenceList,
} from 'modules/planner/dna/functions';

import { useRooms } from 'modules/planner/metabolism/use-rooms';
import { useOrganizationCalendar } from 'modules/planner/metabolism/use-organization-calendar';

import { useRemovableEvent } from 'core/organisms/week-calendar/components';

export const useRoomsAvailability = () => {
  const { startTime, endTime, getBackgroundEvents } = useOrganizationCalendar();

  const {
    rooms,
    selectedRooms: selectedRoomIds,
    updateAvailabilityPreferencesForRooms,
    roomsLoading,
    roomsError,
  } = useRooms();

  const [events, setEvents] = useState<EventInput[]>([] as EventInput[]);
  const [dirty, setDirty] = useState(false);
  const [saving, setSaving] = useState(false);

  const selectedRooms = useMemo(
    () => rooms.filter((r) => selectedRoomIds.indexOf(r.id) >= 0),
    [rooms, selectedRoomIds],
  );

  const selectedRoomsCount = selectedRooms?.length;

  const resources: Resource[] = useMemo(() => {
    const result = selectedRooms.map((r) => ({
      id: r.id,
      title: r.name || 'noname',
    }));
    return result.length ? result : [{ id: '', title: '' }];
  }, [selectedRooms]);

  useEffect(() => {
    if (!roomsLoading && !roomsError && selectedRoomsCount) {
      const newEvents = mapAvailabilityPreferenceListToEventInputList(
        mapResourceListToAvailabilityPreferenceList(selectedRooms),
        'Not Available',
      );
      setEvents(newEvents);
    }
  }, [roomsError, roomsLoading, selectedRooms, selectedRoomsCount]);

  const updateEvents = useCallback((newEvents: EventInput[]) => {
    setDirty(true);
    setEvents(newEvents);
  }, []);

  const addPreference = useCallback(
    (event: EventInput) => {
      if (!events) {
        throw new Error("It's not possible to add new event undefined!");
      }
      updateEvents([...events, event]);
    },
    [events, updateEvents],
  );

  const editPreference = useCallback(
    (event: EventInput) => {
      if (!events) {
        throw new Error("It's not possible to edit not existing events!");
      }
      const existingEvent = events.find((e) => e.id === event.id);
      if (!existingEvent) {
        throw new Error('Event to edit have to match data');
      }

      existingEvent.start = event.start;
      existingEvent.end = event.end;

      updateEvents([...events]);
    },
    [events, updateEvents],
  );

  const deletePreference = useCallback(
    (eventId: string) => {
      if (!events) {
        throw new Error("It's not possible to edit not existing events!");
      }
      const updatedEvents = events.filter((e) => e.id !== eventId);
      if (updatedEvents.length === events.length) {
        throw new Error('Item to delete is not found in the data source!');
      }
      updateEvents(updatedEvents);
    },
    [events, updateEvents],
  );

  const { eventContent } = useRemovableEvent({ onDelete: deletePreference });

  const save = useCallback(() => {
    setSaving(true);
    const entityType: AvailabilityPreferencesEntityType = 'room';
    const roomPreferences = resources.map((r) => {
      return {
        entityId: r.id,
        entityType,
        availabilityPreferences: mapCalendarEventListToApiAvailabilityPreferenceList(
          events as CalendarEvent[],
          r.id,
          -2,
        ),
      } as UpdateAvailabilityPreferencesForEntityInput;
    });
    updateAvailabilityPreferencesForRooms({
      preferences: roomPreferences,
    }).then(() => {
      setDirty(false);
      setSaving(false);
    });
  }, [events, resources, updateAvailabilityPreferencesForRooms]);

  return {
    startTime,
    endTime,
    bgEvents: getBackgroundEvents(resources?.map((r) => r.id)),
    resources,
    events,
    add: addPreference,
    edit: editPreference,
    save,
    dirty,
    saving,
    loading: roomsLoading,
    error: roomsError,
    eventContent,
  };
};
