import { useCallback } from 'react';

import { OasMemoryError, OasError } from 'core/atoms/errors';

import { gql, useMutation } from 'core/dna/types/apollo';
import {
  CreateSchedule,
  CreateScheduleVariables,
} from 'core/dna/types/generated/CreateSchedule';

import { useLazyGetTermsState } from 'core/memory/apollo/terms/local';

import { GET_SCHEDULE_DESCRIPTIONS_QUERY } from './_gql';

const CREATE_SCHEDULE_MUTATION = gql`
  mutation CreateSchedule($input: CreateScheduleInput!) {
    createSchedule(input: $input) {
      id
      name
    }
  }
`;

const TITLE = 'UseCreateSchedule';

export const useCreateSchedule = () => {
  const { getTermState } = useLazyGetTermsState();

  const [createSchedule] = useMutation<CreateSchedule, CreateScheduleVariables>(
    CREATE_SCHEDULE_MUTATION,
  );

  const func = useCallback(
    async (name: string) => {
      try {
        const termsState = await getTermState(true);

        const result = await createSchedule({
          variables: { input: { name, termId: termsState!.selected! } },
          refetchQueries: [
            {
              query: GET_SCHEDULE_DESCRIPTIONS_QUERY,
              variables: { termId: termsState!.selected! },
            },
          ],
        });

        if (result.errors) {
          throw new OasMemoryError(JSON.stringify(result.errors), {
            title: TITLE,
          });
        }
        return result.data?.createSchedule ?? undefined;
      } catch (e: any) {
        if (e instanceof OasError) {
          throw e;
        } else {
          throw OasMemoryError.fromError(e, {
            title: TITLE,
          });
        }
      }
    },
    [createSchedule, getTermState],
  );

  return {
    createSchedule: func,
  };
};
