import { useCallback } from 'react';

import { TextItem } from 'core/atoms/types';
import { OasMemoryError } from 'core/atoms/errors';
import { OasError } from 'core/atoms/errors/oas-error';

import { useMutation, gql } from 'core/dna/types/apollo';
import { RoleType } from 'core/dna/types/generated/_globalTypes';
import {
  CreateStudentFromSyncPerson,
  CreateStudentFromSyncPersonVariables,
} from 'core/dna/types/generated/CreateStudentFromSyncPerson';

import { useLazyGetTermsState } from 'core/memory/apollo/terms/local';
import { useLazyGetRole } from '../../roles/remote/use-lazy-get-role';

const CREATE_STUDENT_FROM_SYNC_PERSON_MUTATION = gql`
  mutation CreateStudentFromSyncPerson(
    $input: CreatePersonAndRoleFromSyncPersonInput
  ) {
    createPersonAndRoleFromSyncPerson(input: $input) {
      personId
      roleId
    }
  }
`;

const FILE_NAME = 'use-create-student-from-sync-person';

export const useCreateStudentFromSyncPerson = () => {
  const { getTermState } = useLazyGetTermsState();
  const { getRole } = useLazyGetRole();

  const [create, { loading, error }] = useMutation<
    CreateStudentFromSyncPerson,
    CreateStudentFromSyncPersonVariables
  >(CREATE_STUDENT_FROM_SYNC_PERSON_MUTATION);

  const func = useCallback(
    async (syncPerson: TextItem) => {
      try {
        const termsState = await getTermState(true);

        const result = await create({
          variables: {
            input: {
              syncPersonId: syncPerson.id,
              termId: termsState!.selected!,
              roleType: RoleType.student,
              title: 'Student',
            },
          },
          // refetchQueries: [
          //   {
          //     query: GET_TEACHERS_QUERY,
          //     variables: { termId: termsState!.selected! },
          //   },
          // ],
        });

        if (result.errors) {
          throw new OasMemoryError(JSON.stringify(result.errors), {
            title: FILE_NAME,
          });
        }
        const personRoleResult = result.data?.createPersonAndRoleFromSyncPerson;
        if (personRoleResult?.roleId) {
          const role = await getRole(personRoleResult.roleId);
          return {
            personId: personRoleResult.personId,
            personDetails: {
              firstName: role?.person?.firstName ?? '',
              lastName: role?.person?.lastName ?? '',
            },
            role: role ? { id: role.id, title: role.title } : null,
          };
        }
        return;
      } catch (e: any) {
        if (e instanceof OasError) {
          throw e;
        } else {
          throw OasMemoryError.fromError(e, {
            title: FILE_NAME,
          });
        }
      }
    },
    [create, getRole, getTermState],
  );

  return {
    createStudentFromSyncPerson: func,
    loading,
    error,
  };
};
