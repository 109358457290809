import { gql } from 'core/dna/types/apollo';

export const GET_ALLOCATION_PLANS_QUERY = gql`
  query GetAllocationPlans($termId: String!) {
    AllocationPlan(filter: { term: { id: $termId } }) {
      term {
        id
      }
      allocations {
        Schedule {
          id
          name
        }
        published
        weekNumber
      }
    }
  }
`;
