import React, { Fragment, useState } from 'react';
import { List, Typography } from '@material-ui/core';
import { Delete } from '@material-ui/icons';
import { Autocomplete } from '@material-ui/lab';

import { ListItem } from 'core/cells/list-item';
import { ListItemText } from 'core/cells/list-item-text';
import { ListItemSecondaryAction } from 'core/cells/list-item-secondary-action';
import { IconButton } from 'core/cells/icon-button';
import { Divider } from 'core/cells/divider';
import { TextField } from 'core/cells/text-field';
import { useTranslations } from 'core/dna/translations';
import { makeStyles } from 'core/atoms/styles';
import { getDiffInHours, WeekdayObject } from 'core/atoms/date-time';
import { useSnackbar, VariantType } from 'core/cells/notistack';
import { timeShort } from 'core/atoms/date-time/formats';

import { Button } from 'templates/mdp/components/custom-buttons';

import { useWeek } from 'modules/planner/metabolism/use-week';
import { emptyWeeklyEvent, WeeklyEvent } from 'modules/student/dna/types';

import { studentProfileStyles } from './student-profile.styles';

const useStyles = makeStyles(studentProfileStyles);

interface WeeklyEventsProps {
  weeklyEvents?: WeeklyEvent[];
  onAdd: (weeklyEvent: WeeklyEvent) => void;
  onRemove: (index: number) => void;
  loading: boolean;
}

export const WeeklyEvents = ({
  weeklyEvents,
  onAdd,
  onRemove,
  loading,
}: WeeklyEventsProps) => {
  const classes = useStyles();
  const { texts } = useTranslations();
  const { weekArr, getWeekday } = useWeek();
  const { enqueueSnackbar } = useSnackbar();

  const [weeklyEvent, setWeeklyEvent] = useState(emptyWeeklyEvent);

  const handleAdd = () => {
    if (
      !weeklyEvent.value ||
      !weeklyEvent.startTime ||
      !weeklyEvent.endTime ||
      !weeklyEvent.days.length
    ) {
      enqueueSnackbar('The event must have 15min duration at least!', {
        variant: VariantType.Error,
      });
      return;
    }

    const diffInHours = getDiffInHours(
      weeklyEvent.startTime,
      weeklyEvent.endTime,
      timeShort,
    );

    if (diffInHours < 0.25) {
      enqueueSnackbar('The event must have 15min duration at least!', {
        variant: VariantType.Error,
      });
      return;
    }

    onAdd(weeklyEvent);

    setWeeklyEvent(emptyWeeklyEvent);
  };

  return (
    <div className={classes.box}>
      <div className={classes.groupTitle}>
        <Typography variant="h5">
          {texts.studentModule.weeklyEvents}:
        </Typography>
      </div>
      <div className={classes.groupInput}>
        {weeklyEvents && !!weeklyEvents.length && (
          <List className={classes.list}>
            {weeklyEvents.map((we, index) => (
              <Fragment key={`weeklyEvent-${index}`}>
                <ListItem>
                  <ListItemText
                    primary={we.value}
                    secondary={`${we.startTime} - ${we.endTime},
                             ${we.days.map((d) => getWeekday(d).title)}`}
                  />
                  <ListItemSecondaryAction>
                    <IconButton
                      edge="end"
                      aria-label="delete"
                      onClick={() => onRemove(index)}
                    >
                      <Delete />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
                <Divider component="li" />
              </Fragment>
            ))}
          </List>
        )}
        <TextField
          className={classes.input}
          label={null}
          value={weeklyEvent.value}
          placeholder={texts.studentModule.placeholders.enterWeeklyEvent}
          onChange={(e) => {
            setWeeklyEvent({
              ...weeklyEvent,
              value: e.target.value,
            });
          }}
        />
        <Autocomplete
          multiple
          limitTags={2}
          id="weeklyEvent-select"
          options={weekArr}
          getOptionLabel={(option) => option.title || ''}
          // value={weeklyEvent.days || null}
          // getOptionSelected={(option, value) => {
          //   console.log('option', option);
          //   console.log('value', value);
          //   return value === '' || value === option;
          // }}
          onChange={(event: any, newValue: WeekdayObject[]) => {
            setWeeklyEvent({
              ...weeklyEvent,
              days: newValue.map((w) => w.day),
            });
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder={texts.studentModule.placeholders.selectDays}
            />
          )}
        />
        <div className={classes.pickerBox}>
          <TextField
            id="weeklyEvent-time-from"
            label={texts.planner.common.from}
            type="time"
            margin="dense"
            className={classes.picker}
            InputLabelProps={{ shrink: true }}
            value={weeklyEvent.startTime}
            onChange={(e) => {
              if (!e.target.value) return;
              setWeeklyEvent({
                ...weeklyEvent,
                startTime: e.target.value,
              });
            }}
          />
          <TextField
            id="weeklyEvent-time-to"
            label={texts.planner.common.to}
            type="time"
            margin="dense"
            className={classes.picker}
            InputLabelProps={{ shrink: true }}
            value={weeklyEvent.endTime}
            disabled={!weeklyEvent.startTime}
            onChange={(e) => {
              if (!e.target.value) return;
              setWeeklyEvent({
                ...weeklyEvent,
                endTime: e.target.value,
              });
            }}
          />
        </div>
        <Button
          className={classes.addBtn}
          size="sm"
          color="success"
          disabled={
            !weeklyEvent.startTime ||
            !weeklyEvent.endTime ||
            !weeklyEvent.days.length ||
            !weeklyEvent.value ||
            loading
          }
          onClick={() => handleAdd()}
        >
          {texts.planner.common.add}
        </Button>
      </div>
    </div>
  );
};
