import { useMemo, useCallback, useState } from 'react';
import moment from 'moment';
import clsx from 'clsx';

import { makeStyles, createStyles } from 'core/atoms/styles';
import { yellow, deepOrange, grey } from 'core/atoms/colors';

import { EventApi } from 'core/cells/full-calendar';
import { MenuItem } from 'core/cells/menu-item';

import { useTranslations } from 'core/dna/translations';

import { CalendarEvent } from 'core/dna/types';

import { mapEventApiToCalendarEvent } from 'modules/planner/dna/functions';

import { useConflictsState } from 'modules/planner/memory/rx/conflicts';

import { blue } from '@material-ui/core/colors';
import { OneLineEventContent } from './components/one-line-event-content';
import { TwoLinesEventContent } from './components/two-lines-event-content';
import { ThreeLinesEventContent } from './components/three-lines-event-content';
import { FourLinesEventContent } from './components/four-lines-event-content';
import { WithConflicts } from './components/with-conflicts';
import { TimeplanEventMenu } from './components/timeplan-event-menu';
import { TimeplanEventProps } from './timeplan-event.types';

const useStyles = makeStyles(() => {
  return createStyles({
    root: {
      height: '100%',
      position: 'relative',
      padding: '2px 3px',
      '& .timeplan-event-menu': {
        display: 'none',
      },
      '&:hover': {
        '& .timeplan-event-menu': {
          display: 'block',
        },
      },
    },
    pinned: {
      border: `2px dotted ${grey['900']}`,
      background: `
        linear-gradient(to top, ${grey['100']}, 2px, transparent 2px),
        linear-gradient(to right, ${grey['100']}, 2px, transparent 2px),
        linear-gradient(to bottom, ${grey['100']}, 2px, transparent 2px),
        linear-gradient(to left, ${grey['100']}, 2px, transparent 2px);
      `,
      backgroundOrigin: 'border-box',
      borderRadius: 2,
    },
    selected: {
      borderRadius: 3,
      borderStyle: 'solid',
      borderColor: 'black',
      borderWidth: 2,
      opacity: 0.5,
    },
    conflict: {
      border: `2px dashed ${deepOrange.A700}`,
      background: `
        linear-gradient(to top, ${yellow.A200}, 2px, transparent 2px),
        linear-gradient(to right, ${yellow.A200}, 2px, transparent 2px),
        linear-gradient(to bottom, ${yellow.A200}, 2px, transparent 2px),
        linear-gradient(to left, ${yellow.A200}, 2px, transparent 2px);
      `,
      backgroundOrigin: 'border-box',
      borderRadius: 2,
    },
    highlighted: {
      // borderWidth: 1,
      // background: 'initial',
      borderColor: deepOrange.A700,
      borderStyle: 'solid',
      boxShadow: `0px 0px 8px 4px ${deepOrange.A200}`,
      /* in order: x offset, y offset, blur size, spread size, color */
      /* blur size and spread size are optional (they default to 0) */
    },
    cursorPointer: {
      cursor: 'grab',
    },
  });
});

export const TimeplanEvent = (props: TimeplanEventProps) => {
  const { event, view, onPin, readonly, onDelete } = props;
  const classes = useStyles(props);

  const { texts } = useTranslations();

  const linesCount = useMemo(() => {
    const slotDuration = moment
      .duration(view.calendar.getOption('slotDuration'))
      .asMinutes();

    const eventDuration = moment
      .duration(moment(event.end).diff(event.start))
      .asMinutes();

    return Math.floor(eventDuration / slotDuration) || 1;
  }, [event.end, event.start, view.calendar]);

  const pinEvent = useCallback(
    (onPin?: (event: CalendarEvent) => void, event?: EventApi) => {
      onPin && onPin(mapEventApiToCalendarEvent(event!));
    },
    [],
  );

  const handleDelete = useCallback(() => {
    onDelete && onDelete(event.id);
  }, [event.id, onDelete]);

  const [open, setOpen] = useState(false);

  const { conflictsState } = useConflictsState();

  if (event.display === 'background') {
    return <></>;
  }

  const Content = () => {
    switch (linesCount) {
      case 1:
        return <OneLineEventContent {...props} />;
      case 2:
        return <TwoLinesEventContent {...props} />;
      case 3:
        return <ThreeLinesEventContent {...props} />;
      default:
        return <FourLinesEventContent {...props} />;
    }
  };

  const handlePin = () => {
    pinEvent(onPin, event);
  };

  return (
    <div
      className={clsx(
        classes.root,
        open ? classes.selected : '',
        event.extendedProps?.pinned && !readonly ? classes.pinned : '',
        event.extendedProps?.aiHardConflicts?.length ? classes.conflict : '',
        event.extendedProps?.timeSlotId === conflictsState.timeSlotId
          ? classes.highlighted
          : '',
      )}
    >
      {!readonly && (
        <TimeplanEventMenu
          id={event.id}
          color={event.textColor}
          onOpenChanged={setOpen}
        >
          <MenuItem onClick={handlePin}>
            {event.extendedProps?.pinned
              ? texts.planner.common.unpin
              : texts.planner.common.pin}
          </MenuItem>
          <MenuItem onClick={handleDelete}>{texts.oasCommon.reset}</MenuItem>
        </TimeplanEventMenu>
      )}

      <WithConflicts
        hardConflicts={event.extendedProps?.aiHardConflicts}
        // softConflicts={event.extendedProps?.aiSoftConflicts}
      >
        <Content />
      </WithConflicts>
    </div>
  );
};
