import { useCallback, useEffect, useMemo } from 'react';
import moment from 'moment';

import { logger, LogType } from 'core/atoms/housekeeping';
import { OasPageError } from 'core/atoms/errors';
import { tick } from 'core/atoms/date-time';
import { timeShort } from 'core/atoms/date-time/formats';

import { EventApi } from 'core/cells/full-calendar';

import { CalendarEvent } from 'core/dna/types';
import {
  ScheduleStatus,
  UpdateTimeSlot,
} from 'core/dna/types/generated/_globalTypes';

import { useTimeplanEvent } from 'core/organisms/week-calendar/components';

import { mapLessonGroupListToScheduleEventList } from 'modules/planner/dna/functions';

import { useGetTimePlanState } from 'modules/planner/memory/apollo/time-plan/local';
import {
  useStartScheduling,
  useStopScheduling,
  useGetSchedule,
  useDeleteAllTimeslots,
  useUpdateTimeslot,
  useDeleteTimeslot,
} from 'modules/planner/memory/apollo/schedules/remote';

import { useTpcState, tpcStore } from './store';

const FILE_NAME = 'use-time-plan-calendar';

export interface UseTimePlanCalendarProps {
  scheduleId: string;
  showClasses: boolean;
  showGroups: boolean;
  showTeachers: boolean;
  showRooms: boolean;
}

export const useTimePlanCalendar = (props: UseTimePlanCalendarProps) => {
  const {
    scheduleId,
    showClasses,
    showGroups,
    showTeachers,
    showRooms,
  } = props;

  const { data: schedule, refetch } = useGetSchedule({
    scheduleId,
    watch: true,
  });

  const { startScheduling: startSchedulingApiFunc } = useStartScheduling();
  const { stopScheduling: stopSchedulingApiFunc } = useStopScheduling();
  const { updateTimeSlot: updateTimeSlotApiFunc } = useUpdateTimeslot();
  const { deleteTimeslot: deleteTimeSlotApiFunc } = useDeleteTimeslot();
  const {
    deleteAllTimeslots: deleteAllTimeslotsApiFunc,
  } = useDeleteAllTimeslots();
  const { data: timePlanState } = useGetTimePlanState();

  const {
    tpcState: { starting, scheduling, executing, stopping, locked },
  } = useTpcState();

  useEffect(() => {
    if (schedule) {
      tpcStore.updateSchedule(
        mapLessonGroupListToScheduleEventList(schedule.lessonGroups, {
          withClasses: true,
          withGroups: showGroups,
          withTeachers: showTeachers,
          withRooms: showRooms,
        }),
        schedule.status,
      );
    }
  }, [schedule, showGroups, showRooms, showTeachers]);

  const updateTimeSlotFunc = useCallback(
    (input: UpdateTimeSlot) => {
      tpcStore.updateTimeSlot(
        updateTimeSlotApiFunc,
        { apiInput: input, scheduleId },
        true,
      );
    },
    [scheduleId, updateTimeSlotApiFunc],
  );

  const deleteAllTimeslotsFunc = useCallback(() => {
    tpcStore.deleteAllTimeSlots(
      deleteAllTimeslotsApiFunc,
      { scheduleId },
      refetch,
      true,
    );
  }, [deleteAllTimeslotsApiFunc, refetch, scheduleId]);

  const startSchedulingFunc = useCallback(() => {
    const s = schedule;
    if (!s) {
      throw new OasPageError('startSchedulingFunc => No schedule', {
        title: FILE_NAME,
      });
    }
    if (
      s.status !== ScheduleStatus.starting &&
      s.status !== ScheduleStatus.running
    ) {
      tpcStore.startScheduling(
        startSchedulingApiFunc,
        { scheduleId: s.id },
        true,
      );
    } else {
      logger.debug({
        title: 'startScheduling REJECTED',
        logger: FILE_NAME,
        type: LogType.Error,
        value: [
          {
            id: `${s.name}, term: ${s.termId}`,
            scheduleId: s.id,
            scheduleName: s.name,
            scheduleStatus: s.status,
            lessonGroupsLength: s.lessonGroups?.length ?? 'undefined',
            lessonGroupsCount: s.lessonGroupCount ?? 'undefined',
            timeSlotsUpdatedAt: s.timeSlotsUpdatedAt ?? 'undefined',
          },
        ],
      });
    }
  }, [schedule, startSchedulingApiFunc]);

  const stopSchedulingFunc = useCallback(() => {
    const s = schedule;
    if (!s) {
      throw new OasPageError('stopSchedulingFunc => No schedule', {
        title: FILE_NAME,
      });
    }
    if (
      s.status === ScheduleStatus.starting ||
      s.status === ScheduleStatus.running
    ) {
      tpcStore.stopScheduling(
        stopSchedulingApiFunc,
        { scheduleId: s.id },
        true,
      );
    }
  }, [schedule, stopSchedulingApiFunc]);

  const handleUpdateTimeSlot = useCallback(
    (event: CalendarEvent, pinned: boolean) => {
      if (!event.splitId && event.locked) {
        return;
      }
      let endTime = moment(event.end).format(timeShort);
      const split = !!(event.splitId && event.splitDragObject);
      if (split) {
        endTime = moment(event.start)
          .add(event.splitDragObject!.duration, 'minutes')
          .format(timeShort);
      }

      updateTimeSlotFunc({
        timeSlotId: event.timeSlotId ?? '',
        pinned,
        startTime: moment(event.start).format(timeShort),
        endTime,
        weekday: tick(event.start).weekDay,
        mergeTimeSlotsWithSameSplitId: split,
      });
    },
    [updateTimeSlotFunc],
  );

  const getEvents = useCallback(() => {
    if (timePlanState?.scheduleId === scheduleId) {
      // if (timePlanState?.calendarEvents) {
      //   const str = JSON.stringify(timePlanState?.calendarEvents);
      //   logger.debug({
      //     title: 'JSON.stringify(scheduleEvents)',
      //     logger: 'mapper',
      //     type: LogType.Info,
      //     value: str,
      //   });
      // }
      return timePlanState?.calendarEvents;
    }
    logger.debug({
      title: 'getEvents(): timePlanState?.scheduleId !== scheduleId',
      logger: FILE_NAME,
      type: LogType.Error,
      value: [
        {
          scheduleFromTimePlanState: timePlanState?.scheduleId ?? '',
          scheduleFromSelector: scheduleId,
        },
      ],
    });
    return [];
  }, [scheduleId, timePlanState?.calendarEvents, timePlanState?.scheduleId]);

  const onPin = useCallback(
    (event: CalendarEvent) => {
      handleUpdateTimeSlot(event, !(event.pinned ?? false));
    },
    [handleUpdateTimeSlot],
  );

  const onDelete = useCallback(
    (eventId: string) => {
      const events = getEvents();
      const event = events.find((e) => e.id === eventId);
      if (!event) {
        return;
      }

      let eventsToDelete = [event];
      if (event.splitId) {
        eventsToDelete = events?.filter((ce) => ce.splitId === event.splitId);
      }
      const timeSlotIds = eventsToDelete.map((etd) => etd.timeSlotId ?? '');

      tpcStore.deleteTimeSlot(deleteTimeSlotApiFunc, timeSlotIds);
    },
    [deleteTimeSlotApiFunc, getEvents],
  );

  const onSplitDragStart = useCallback(
    (
      event: CalendarEvent,
      relatives: EventApi[],
      duration: number,
      relativesTimeSlotIds: string[],
    ) => {
      // tpcStore.deleteTimeSlot(deleteTimeSlotApiFunc, relativesTimeSlotIds);
      // relatives.forEach((evt) => evt.remove());
    },
    [],
  );

  const onDrop = useCallback(
    (event: CalendarEvent) => {
      handleUpdateTimeSlot(event, true);
    },
    [handleUpdateTimeSlot],
  );

  const { eventContent } = useTimeplanEvent({
    showClasses,
    showGroups,
    showTeachers,
    showRooms,
    onPin,
    onDelete,
  });

  const noLessons = useMemo(() => !schedule?.lessonGroupCount, [
    schedule?.lessonGroupCount,
  ]);

  return {
    startScheduling: startSchedulingFunc,
    stopScheduling: stopSchedulingFunc,
    clean: deleteAllTimeslotsFunc,
    events: getEvents(),
    starting,
    scheduling,
    executing,
    stopping,
    locked,
    eventContent,
    onSplitDragStart,
    onDrop,
    noLessons,
  };
};
