import { BehaviorSubject } from 'rxjs';

// import { logger, LogType } from 'core/dna/logging';

const initialState: { timeSlotId: string | null } = {
  timeSlotId: null,
};

const subject = new BehaviorSubject(initialState);

let state = initialState;

export const conflictsStore = {
  subscribe: (setState: any) => subject.subscribe(setState),
  select: (timeSlotId: string | null) => {
    if (timeSlotId !== state.timeSlotId) {
      state = { timeSlotId };
      subject.next(state);
      // logger.debug({
      //   title: 'select',
      //   logger: 'conflicts-store',
      //   type: LogType.Accent,
      //   value: `New timeSlotId was sent: "${timeSlotId}"`,
      // });
    }
  },
  initialState,
};
