import { useCallback } from 'react';

import { OasMemoryError, OasError } from 'core/atoms/errors';

import { gql, useMutation } from 'core/dna/types/apollo';

import {
  UpdateAvailabilityPreferencesForClasses,
  UpdateAvailabilityPreferencesForClassesVariables,
} from 'core/dna/types/generated/UpdateAvailabilityPreferencesForClasses';
import { UpdateAvailabilityPreferencesForEntitiesInput } from 'core/dna/types/generated/_globalTypes';

import { useLazyGetTermsState } from 'core/memory/apollo/terms/local';

import { GET_CLASSES_QUERY } from './_gql';

// TODO: Use "AvailabilityPreferenceData" fragment as a response as soon as "availabilityLevel" will be accessible
export const UPDATE_AVAILABILITY_PREFERENCES_FOR_CLASSES_MUTATION = gql`
  mutation UpdateAvailabilityPreferencesForClasses(
    $input: UpdateAvailabilityPreferencesForEntitiesInput!
  ) {
    updateAvailabilityPreferencesForEntities(input: $input) {
      id
      preferences {
        id
        startTime {
          formatted
        }
        endTime {
          formatted
        }
        weekday
      }
    }
  }
`;

const TITLE = 'use-update-availability-preferences-for-classes';

export const useUpdateAvailabilityPreferencesForClasses = () => {
  const { getTermState } = useLazyGetTermsState();

  const [updateAvailabilityPreferencesForClasses] = useMutation<
    UpdateAvailabilityPreferencesForClasses,
    UpdateAvailabilityPreferencesForClassesVariables
  >(UPDATE_AVAILABILITY_PREFERENCES_FOR_CLASSES_MUTATION);

  const func = useCallback(
    async (input: UpdateAvailabilityPreferencesForEntitiesInput) => {
      try {
        const termsState = await getTermState(true);

        const result = await updateAvailabilityPreferencesForClasses({
          variables: { input },
          refetchQueries: [
            {
              query: GET_CLASSES_QUERY,
              variables: { termId: termsState!.selected },
            },
          ],
        });

        if (result.errors) {
          throw new OasMemoryError(JSON.stringify(result.errors), {
            title: TITLE,
          });
        }
      } catch (e: any) {
        if (e instanceof OasError) {
          throw e;
        } else {
          throw OasMemoryError.fromError(e, {
            title: TITLE,
          });
        }
      }
    },
    [getTermState, updateAvailabilityPreferencesForClasses],
  );

  return {
    updateAvailabilityPreferencesForClasses: func,
  };
};
