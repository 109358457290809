import { GetTeachers_Role as ApiTeacherRole } from 'core/dna/types/generated/GetTeachers';
import { GetRole_Role as ApiRole } from 'core/dna/types/generated/GetRole';
import { mapNullableListToList } from 'core/dna/functions';

import { Role } from 'modules/planner/dna/types/role';
import { mapApiPersonToPerson } from '../map-ApiPerson-to-Person';

export const mapApiRoleListToRoleList = (
  apiRoleList: (ApiTeacherRole | ApiRole | null)[] | null,
): Role[] | undefined => {
  return mapNullableListToList<ApiTeacherRole | ApiRole, Role>(
    apiRoleList,
    mapApiRoleToRole,
  );
};

export const mapApiRoleToRole = (
  apiRole: ApiTeacherRole | ApiRole | null,
): Role | undefined => {
  if (!apiRole) {
    return;
  }
  const { id, title, person } = apiRole;

  return {
    id,
    title: title ?? undefined,
    person: mapApiPersonToPerson(person),
  };
};
