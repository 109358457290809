import { useRef, useEffect } from 'react';

export const useRenderCounter = (props: { name: string }) => {
  // const ref = useRef<any>();

  // useEffect(() => {
  //   ref.current.textContent = Number(ref.current?.textContent || '0') + 1;
  // });

  return <></>;

  /*
  return (
    <div
      data-name={props.name}
      style={{
        borderColor: 'black',
        borderStyle: 'solid',
        borderWidth: 1,
        backgroundColor: '#e5c64d',
        borderRadius: 4,
        padding: '2px 4px',
        fontSize: '0.8rem',
        margin: '0 6px',
        display: 'inline-block',
        zIndex: 100000,
        position: 'relative',
      }}
    >
      <span style={{ fontWeight: 'bold' }} ref={ref} /> - {props.name}
    </div>
  );

   */
};
