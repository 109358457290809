import { GetStudents_PersonT2 as ApiShortPersonT2 } from 'core/dna/types/generated/GetStudents';
import { mapNullableListToList } from 'core/dna/functions/map-nullable-list-to-list';
import { StudentBasicData } from 'modules/student/dna/types/short-data';
import { mapApiConfigurationListToConfigurationObject } from '../map-api-configuration-to-configuration';
import { mapApiPersonT2ShortDataToPersonT2ShortData } from '../map-api-person-t2-short-data-to-person-t2-short-data';

export const mapApiPersonT2BasicDataToPersonT2BasicData = (
  apiPersonT2: ApiShortPersonT2 | null,
): StudentBasicData => {
  if (apiPersonT2 === null) {
    throw new TypeError('"apiPersonT2" should not be null!');
  }

  return {
    ...mapApiPersonT2ShortDataToPersonT2ShortData(apiPersonT2),
    configuration: mapApiConfigurationListToConfigurationObject(
      apiPersonT2.configurations,
    ),
  };
};

export const mapApiPersonT2BasicDataToPersonT2BasicDataList = (
  apiPersonT2List: (ApiShortPersonT2 | null)[] | null,
): StudentBasicData[] | undefined => {
  return mapNullableListToList<ApiShortPersonT2, StudentBasicData>(
    apiPersonT2List,
    mapApiPersonT2BasicDataToPersonT2BasicData,
  );
};
