import { PATHS, OasLanguage } from 'core/dna/routing';
import { RouteConfig } from 'core/dna/types/local';
import { useTranslations } from 'core/dna/translations';
import { OasLocales } from 'core/dna/translations/types';
import { Modules } from 'core/dna/types/modules';

import { MdProTemplateContent } from 'core/pages/sandbox/mdpro-template';
import { NoMatch } from 'core/pages/no-match';

import { getRoutes as getAuthRoutes } from 'auth/routes';

import { getRoutes as getPlannerRoutes } from 'modules/planner/routing/routes';
import { getRoutes as getStudentRoutes } from 'modules/student/routes';
import { getRoutes as getSubstituteRoutes } from 'modules/substitute/routes';
import { getRoutes as getUnsetRoutes } from 'modules/unset/routes';
import { getRoutes as getReportsRoutes } from 'modules/reports/routes';
import { getRoutes as getGradesRoutes } from 'modules/grades/routes';
import { Organization } from 'core/dna/types/organization';
import { RootAccessRoles } from 'core/dna/types/access-roles';

export interface GetDataRoutesProps {
  module: string;
  locale: OasLanguage;
  texts: OasLocales;
  organization?: Organization;
  myAccessRoles?: RootAccessRoles[] | null;
}

export const getCommonRoutes = () => [
  {
    path: PATHS.Common.Sandbox.MdProTemplate.path,
    component: MdProTemplateContent,
  },
  {
    component: NoMatch,
  },
];

export const useGetAuthRoutes = (): RouteConfig[] => {
  const { texts } = useTranslations();

  return getAuthRoutes(texts);
};

export const getDataRoutes = (data: GetDataRoutesProps) => {
  const { module, texts, locale } = data;

  switch (module) {
    case Modules.planner:
      return getPlannerRoutes(locale, texts);
    case Modules.student:
      return getStudentRoutes(data);
    case Modules.reports:
      return getReportsRoutes(locale, texts);
    case Modules.substitute:
      return getSubstituteRoutes(locale, texts);
    case Modules.grades:
      return getGradesRoutes(data);
    case Modules.unset:
      return getUnsetRoutes(locale, texts);
    default:
      return [];
  }
};
