import { useGetTestError } from 'core/memory/apollo/test-error/remote/get-test-error';

export const Test = () => {
  const { data, error } = useGetTestError();

  if (error) {
    return (
      <p>
        Error: {error.graphQLErrors.map((e) => e.path?.join('/')).join(' ')}
      </p>
    );
  }

  return (
    <>
      {/* <p>{data?.apolloError ?? 'apolloError === null'}</p> */}
      {/* <p>{data?.authenticationError ?? 'authenticationError === null'}</p> */}
      <p>{data?.forbiddenError ?? 'forbiddenError === null'}</p>
      {/* <p>{data?.userInputError ?? 'userInputError === null'}</p> */}
      <p>{data?.validString ?? 'validString === null'}</p>
    </>
  );
};
