import { uniq } from 'core/atoms/functions/array';

import {
  getCurrentScheduleStorage,
  getSelectedSchedulesStorage,
} from 'modules/planner/memory/browser/schedule';

const { getCurrentSchedule, setCurrentSchedule } = getCurrentScheduleStorage();
const {
  getSelectedSchedules,
  removeSelectedSchedule,
  removeSelectedSchedules,
  setSelectedSchedules,
} = getSelectedSchedulesStorage();

export const updateSelectedSchedulesStorage = (
  scheduleIds: string[],
  init?: boolean,
) => {
  if (!scheduleIds.length) {
    removeSelectedSchedules();
    return null;
  }

  getSelectedSchedules()?.forEach((ss) => {
    if (!scheduleIds.includes(ss)) {
      removeSelectedSchedule(ss);
    }
  });

  const storedSelectedSchedules = getSelectedSchedules();

  if (!storedSelectedSchedules?.length) {
    const first = [scheduleIds[0]];
    setSelectedSchedules(first);
    return first;
  }

  if (!init && scheduleIds.length > storedSelectedSchedules.length) {
    const result = uniq([...scheduleIds, ...storedSelectedSchedules]);
    setSelectedSchedules(result);
    return result;
  }

  return storedSelectedSchedules;
};

export const updateCurrentScheduleStorage = (
  selectedSchedules: string[] | null,
) => {
  const currentSchedule = getCurrentSchedule();

  if (selectedSchedules?.length) {
    if (!currentSchedule || !selectedSchedules.includes(currentSchedule)) {
      setCurrentSchedule(selectedSchedules[0]);
    }
  } else {
    setCurrentSchedule(null);
  }

  return getCurrentSchedule();
};

export const updateScheduleStorages = (
  scheduleIds: string[],
  init?: boolean,
) => {
  const selectedSchedules = updateSelectedSchedulesStorage(scheduleIds, init);
  const currentSchedule = updateCurrentScheduleStorage(selectedSchedules);
  return {
    selectedSchedules,
    currentSchedule,
  };
};
