import { useCallback } from 'react';

import { OasMemoryError, OasError } from 'core/atoms/errors';

import { UpdateRoleInput } from 'core/dna/types/generated/_globalTypes';
import {
  UpdateTeacher,
  UpdateTeacherVariables,
} from 'core/dna/types/generated/UpdateTeacher';
import { gql, useMutation } from 'core/dna/types/apollo';

import { useLazyGetTermsState } from 'core/memory/apollo/terms/local';

import { GET_TEACHERS_QUERY } from './_gql';

export interface UpdateTeacherInput extends UpdateRoleInput {}

export const UPDATE_TEACHER_MUTATION = gql`
  mutation UpdateTeacher($input: UpdateRoleInput!) {
    updateRole(input: $input) {
      id
      type
    }
  }
`;

const TITLE = 'use-update-teacher';

export const useUpdateTeacher = () => {
  const { getTermState } = useLazyGetTermsState();

  const [updateTeacherApi] = useMutation<UpdateTeacher, UpdateTeacherVariables>(
    UPDATE_TEACHER_MUTATION,
  );

  const updateTeacher = useCallback(
    async (input: UpdateTeacherInput) => {
      try {
        const termsState = await getTermState(true);

        const result = await updateTeacherApi({
          variables: { input: { ...input } },
          refetchQueries: [
            {
              query: GET_TEACHERS_QUERY,
              variables: { termId: termsState!.selected! },
            },
          ],
        });

        if (result.errors) {
          throw new OasMemoryError(JSON.stringify(result.errors), {
            title: TITLE,
          });
        }
        return result.data?.updateRole ?? undefined;
      } catch (e: any) {
        if (e instanceof OasError) {
          throw e;
        } else {
          throw OasMemoryError.fromError(e, {
            title: TITLE,
          });
        }
      }
    },
    [updateTeacherApi, getTermState],
  );

  return {
    updateTeacher,
  };
};
