import { AuthStatus } from 'core/atoms/errors';

import { AUTH_STATUS_KEY } from './constants';

const hasStatus = () => {
  return !!localStorage.getItem(AUTH_STATUS_KEY);
};

const getStatus = (): AuthStatus | null => {
  return hasStatus()
    ? JSON.parse(localStorage.getItem(AUTH_STATUS_KEY)!)
    : null;
};

const removeStatus = () => {
  hasStatus() && localStorage.removeItem(AUTH_STATUS_KEY);
};

const setStatus = (status: AuthStatus | null) => {
  if (status) {
    localStorage.setItem(AUTH_STATUS_KEY, JSON.stringify(status));
  } else {
    removeStatus();
  }
};

export const getStatusStorage = () => {
  return {
    key: AUTH_STATUS_KEY,
    hasStatus,
    getStatus,
    removeStatus,
    setStatus,
  };
};
