import { gql } from 'core/dna/types/apollo';
import {
  PERSON_CONFIGURATION_FRAGMENT,
  PERSON_T2_DETAILS_FRAGMENT,
} from 'modules/student/memory/apollo/_fragments';

export const GET_STUDENTS_QUERY = gql`
  query GetStudents {
    PersonT2 {
      tuid
      details {
        ...PersonDetailsT2
      }
      configurations {
        ...Configuration
      }
    }
  }
  ${PERSON_T2_DETAILS_FRAGMENT}
  ${PERSON_CONFIGURATION_FRAGMENT}
`;
