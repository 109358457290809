export interface TermData {
  id: string;
  name?: string;
  startDate?: Date;
  endDate?: Date;
  holidays?: Date[];
  allocationPlan?: {
    allocations: { weekNumber: number; published: boolean }[];
  };
}

export type Term = TermData;

export enum TermTense {
  past = 'past',
  current = 'current',
  future = 'future',
}
