import { useCallback, useMemo, useState } from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import clsx from 'clsx';

import { useTranslations } from 'core/dna/translations';

import { useStyles as useSweetAlertStyles } from 'templates/mdp/views/components/sweet-alert.styles';
import { ConfirmationAutoFocus } from '../../../../core/atoms/types';

export interface ConfirmationAlertInput {
  title?: string;
  message?: string;
  onConfirm?: (response?: any) => any;
  onCancel?: () => any;
  confirmText?: string;
  cancelText?: string;
  autoFocus?: ConfirmationAutoFocus;
}

export const useConfirmationAlert = (options?: ConfirmationAlertInput) => {
  const swAlClasses = useSweetAlertStyles();
  const { texts } = useTranslations();
  const {
    autoFocus = ConfirmationAutoFocus.None,
    onConfirm,
    onCancel,
    title,
    confirmText,
    cancelText,
    message,
  } = options ?? {};

  const cancelAutoFocus = useMemo(
    () => autoFocus === ConfirmationAutoFocus.Cancel,
    [autoFocus],
  );
  const confirmAutoFocus = useMemo(
    () => autoFocus === ConfirmationAutoFocus.Ok,
    [autoFocus],
  );

  const [alert, setAlert] = useState<any>(null);

  const hide = useCallback(() => {
    setAlert(null);
  }, []);

  const show = useCallback(
    (input?: ConfirmationAlertInput) => {
      setAlert(
        <SweetAlert
          title={input?.title ?? title ?? ''}
          style={{ display: 'block', marginTop: '-100px' }}
          onConfirm={input?.onConfirm ?? onConfirm ?? hide}
          onCancel={input?.onCancel ?? onCancel ?? hide}
          confirmBtnCssClass={clsx(swAlClasses.button, swAlClasses.success)}
          cancelBtnCssClass={clsx(swAlClasses.button, swAlClasses.danger)}
          confirmBtnText={
            input?.confirmText ?? confirmText ?? texts.oasCommon.continue
          }
          cancelBtnText={
            input?.cancelText ?? cancelText ?? texts.oasCommon.cancel
          }
          focusConfirmBtn={confirmAutoFocus}
          focusCancelBtn={cancelAutoFocus}
          showCancel
        >
          {input?.message ?? message ?? ''}
        </SweetAlert>,
      );
    },
    [
      cancelAutoFocus,
      cancelText,
      confirmAutoFocus,
      confirmText,
      hide,
      message,
      onCancel,
      onConfirm,
      swAlClasses.button,
      swAlClasses.danger,
      swAlClasses.success,
      texts.oasCommon.cancel,
      texts.oasCommon.continue,
      title,
    ],
  );

  return {
    alert,
    showAlert: show,
    hideAlert: hide,
  };
};
