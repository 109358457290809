import { useState, useRef, useCallback } from 'react';

import { useInterval } from 'core/atoms/functions/use-interval';

import { VariantType, useSnackbar } from 'core/cells/notistack';

import { useTranslations } from 'core/dna/translations';

import { Union } from 'modules/planner/dna/types/union';
import { Chapter } from 'modules/planner/dna/types/chapter';

import {
  useCreateUnion,
  useDeleteChapters,
} from 'modules/planner/memory/apollo/classes/remote';

export const useGroupsEditor = () => {
  const { createUnion } = useCreateUnion();
  const { deleteChapters } = useDeleteChapters();
  const { enqueueSnackbar } = useSnackbar();
  const { lang } = useTranslations();

  const newChaptersInputRef = useRef<HTMLInputElement>(null);
  const [newChapters, setNewChapters] = useState<string[]>([]);
  const [newChaptersError, setNewChaptersError] = useState<string | null>(null);
  const [unionErrors, setUnionErrors] = useState<string[] | null>(null);

  const removeUnionError = useCallback(() => {
    const errors = unionErrors?.slice(1);
    setUnionErrors(errors?.length ? errors : null);
  }, [unionErrors]);

  const addUnionError = useCallback(
    (unionId: string) => {
      const errors = unionErrors?.filter((ue) => ue !== unionId) ?? [];
      errors.push(unionId);
      setUnionErrors(errors);
    },
    [unionErrors],
  );

  useInterval(() => {
    removeUnionError();
  }, 3000);

  const addNewChapter = (newChapter: string) => {
    const data = [...newChapters, newChapter];
    setNewChaptersError(null);
    setNewChapters(data);
  };

  const deleteNewChapter = (newChapter: string) => {
    setNewChaptersError(null);
    setNewChapters(newChapters.filter((ch) => ch !== newChapter));
  };

  const clearNewChapters = () => {
    setNewChaptersError(null);
    setNewChapters([]);
    newChaptersInputRef.current?.focus();
  };

  const addNewUnion = (classId: string) => {
    if (newChapters.length < 2) {
      setNewChaptersError(
        lang.sourceDataSpace.classesModule
          .cannotAddUnionOfLessThanTwoChaptersMessage,
      );
      return;
    }
    createUnion({ classId, chapters: newChapters })
      .then(() => {
        setNewChapters([]);
        enqueueSnackbar(`"${newChapters}" were created successfully`, {
          variant: VariantType.Success,
        });
        newChaptersInputRef.current?.focus();
      })
      .catch(() => {
        enqueueSnackbar(`"${newChapters}": Creating Error!`, {
          variant: VariantType.Error,
        });
      });
  };

  const addChapterToExistingUnion = () => {
    console.log('handleAddChapterToExistingUnion');
    // TODO: Call server from here
  };

  const deleteChapterFromExistingUnion = (
    classId: string,
    chapter: string,
    chapters?: Chapter[],
  ) => {
    const ch = chapters?.find((ch) => ch.name === chapter);
    if (ch) {
      if ((chapters?.length ?? 0) < 3) {
        addUnionError(ch.union!.id!);
        // enqueueSnackbar(
        //   trLang[Phrase.CannotRemoveChapterFromUnionOfTwoOrLess],
        //   {
        //     variant: VariantType.Error,
        //   },
        // );
        return;
      }
      deleteChapters({
        classId,
        chapterIds: [ch.id],
      })
        .then(() => {
          enqueueSnackbar(`"${ch.name}" was deleted successfully`, {
            variant: VariantType.Success,
          });
        })
        .catch((error: { name: string; description: string }) => {
          enqueueSnackbar(`"${ch.name}": ${error.description}`, {
            variant: VariantType.Error,
          });
        });
    }
  };

  const deleteExistingUnion = (classId: string, union: Union) => {
    if ((union.chapters?.length ?? 0) < 2) {
      addUnionError(union.id);
      return;
    }
    deleteChapters({
      classId,
      chapterIds: union.chapters?.map((ch) => ch.id) ?? [],
    })
      .then(() => {
        enqueueSnackbar(
          `"${union.chapters?.map((ch) => ch.name)}" were deleted successfully`,
          {
            variant: VariantType.Success,
          },
        );
      })
      .catch((error: { name: string; description: string }) => {
        enqueueSnackbar(
          `"${union.chapters?.map((ch) => ch.name)}": ${error.description}`,
          {
            variant: VariantType.Error,
          },
        );
      });
  };

  return {
    addNewChapter,
    deleteNewChapter,
    clearNewChapters,
    newChaptersInputRef,
    newChapters,
    newChaptersError,
    unionErrors,
    addNewUnion,
    addChapterToExistingUnion,
    deleteChapterFromExistingUnion,
    deleteExistingUnion,
  };
};
