import { useCallback, useState, useEffect } from 'react';
import clsx from 'clsx';
import YouTube, { Options as YoutubeOptions } from 'react-youtube';

import { runtimeEnv } from 'core/atoms/env';
import { createStyles, makeStyles, Theme } from 'core/atoms/styles';
import cityImage from 'core/atoms/pictures/city.png';

import { Typography } from 'core/cells/typography';
import { Box } from 'core/cells/box';
import { Button } from 'core/cells/button';

import { useLocale } from 'core/dna/routing';
import { useTranslations } from 'core/dna/translations';

import { useOasHistory } from 'core/metabolism/use-oas-history';
import { useTerm } from 'modules/planner/metabolism/terms';

import PlannerUrls from 'modules/planner/routing/urls';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    welcomeContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      height: '100%',
    },
    titleRow: {
      textAlign: 'center',
    },
    button: {
      width: 200,
      margin: theme.spacing(2),
    },
    city: {
      backgroundImage: `url("${cityImage}")`,
      height: 265,
      width: '100%',
      position: 'fixed',
      bottom: 0,
      zIndex: -1,
    },
    expanded: {
      flexGrow: 1,
    },
  }),
);

const env = runtimeEnv();

const sizeString = String(env.REACT_APP_WELCOME_VIDEO_SIZE);

let size = {
  width: '400',
  height: '250',
};

if (sizeString) {
  const sizes = sizeString.split('x');
  if (sizes.length === 2) {
    size = {
      width: sizes[0],
      height: sizes[1],
    };
  }
}

const youtubeOpts: YoutubeOptions = { ...size };

export const Welcome = () => {
  const classes = useStyles();
  const { lang } = useTranslations();
  const { push } = useOasHistory();
  const { term } = useTerm();
  const locale = useLocale();

  const goToSourceData = useCallback(() => {
    push(PlannerUrls.organization(locale));
  }, [locale, push]);

  const goToPlan = useCallback(() => {
    push(PlannerUrls.schedules(locale));
  }, [locale, push]);

  const goToCourse = useCallback(() => {
    window.open('https://transponder.no/timeplan-kurs', '_blank');
  }, []);

  const [buttonsVisible, setButtonsVisible] = useState<boolean>(!!term);
  useEffect(() => {
    setButtonsVisible(!!term);
  }, [term]);

  return (
    <div className={clsx('welcome-container', classes.welcomeContainer)}>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        mt={2}
        mb={1}
      >
        <Typography variant="h2">{lang.welcome.title}</Typography>
      </Box>
      <Typography variant="h4">{lang.welcome.subtitle}</Typography>
      {buttonsVisible && !env.REACT_APP_WELCOME_VIDEO_ID ? (
        <Box display="flex" m={4}>
          <Button
            variant="outlined"
            className={classes.button}
            onClick={goToSourceData}
          >
            {lang.common.sourceData}
          </Button>
          <Button
            variant="outlined"
            className={classes.button}
            onClick={goToPlan}
          >
            {lang.common.preparation}
          </Button>
        </Box>
      ) : env.REACT_APP_WELCOME_VIDEO_ID ? (
        <Box p={4}>
          <Button
            variant="outlined"
            className={classes.button}
            onClick={goToCourse}
          >
            Gå til videokurs
          </Button>
          {/* <YouTube */}
          {/*  videoId={env.REACT_APP_WELCOME_VIDEO_ID} */}
          {/*  opts={youtubeOpts} */}
          {/* /> */}
        </Box>
      ) : (
        <> </>
      )}
      <div className={classes.expanded}> </div>
      {/* <div className={classes.city}> </div> */}
    </div>
  );
};
