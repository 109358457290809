import { useCallback } from 'react';

import { OasMemoryError, OasError } from 'core/atoms/errors';

import { gql, useMutation } from 'core/dna/types/apollo';
import {
  UpdateSubstituteJob,
  UpdateSubstituteJobVariables,
} from 'core/dna/types/generated/UpdateSubstituteJob';

import { GET_SUBSTITUTE_JOBS_QUERY } from 'modules/substitute/memory/apollo/substitute-jobs/remote';

export const UPDATE_SUBSTITUTE_JOB_MUTATION = gql`
  mutation UpdateSubstituteJob($input: UpdateSubstituteJobInput) {
    updateSubstituteJob(input: $input) {
      id
      description
      internalTitle
      deadline {
        formatted
      }
      state
    }
  }
`;

const TITLE = 'use-update-substitute-job';

export const useUpdateSubstituteJob = () => {
  const [updateSubstituteJob] = useMutation<
    UpdateSubstituteJob,
    UpdateSubstituteJobVariables
  >(UPDATE_SUBSTITUTE_JOB_MUTATION);

  const func = useCallback(
    async (
      substituteJobId: string,
      description: string,
      internalTitle: string,
      deadline: string,
    ) => {
      try {
        const result = await updateSubstituteJob({
          variables: {
            input: {
              substituteJobId,
              description,
              internalTitle,
              deadline,
            },
          },
          refetchQueries: [
            {
              query: GET_SUBSTITUTE_JOBS_QUERY,
            },
          ],
        });

        if (result.errors) {
          throw new OasMemoryError(JSON.stringify(result.errors), {
            title: TITLE,
          });
        }
        return result.data?.updateSubstituteJob ?? undefined;
      } catch (e) {
        if (e instanceof OasError) {
          throw e;
        } else {
          throw OasMemoryError.fromError(e, {
            title: TITLE,
          });
        }
      }
    },
    [updateSubstituteJob],
  );

  return {
    updateSubstituteJob: func,
  };
};
