import { CSSProperties, makeStyles, createStyles } from 'core/atoms/styles';

import { cardTitle } from '../../material-dashboard-pro-react';

export interface ReactTablesStyle {
  cardIconTitle: CSSProperties;
  cardBoldTitle: CSSProperties;
}

export const reactTablesStyle: ReactTablesStyle = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: '15px',
    marginBottom: '0px',
  },
  cardBoldTitle: {
    ...cardTitle,
    fontWeight: 'bold',
    marginTop: '15px',
    marginBottom: '0px',
    marginLeft: '5px',
  },
};

export const useStyles = makeStyles(() =>
  createStyles({ ...reactTablesStyle }),
);
