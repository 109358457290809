export const PATHS = {
  Home: {
    path: '/:locale(no|en|uk)',
    part: 'unset',
  },
  Common: {
    path: '/:locale(no|en|uk)/common',
    part: 'common',
    Sandbox: {
      path: '/:locale(no|en|uk)/common/sandbox',
      part: 'sandbox',
      MdProTemplate: {
        path: '/:locale(no|en|uk)/common/sandbox/mdpro',
        part: 'mdpro',
        params: {
          layout: {
            pathKey: ':layout',
            key: 'layout',
          },
          page: {
            pathKey: ':page',
            key: 'page',
          },
        },
      },
    },
  },
  Directory: {
    path: '/:locale(no|en|uk)/directory',
    part: 'directory',
    People: {
      path: '/:locale(no|en|uk)/directory/people',
      part: 'people',
    },
  },
};
