import { useState } from 'react';

import { makeStyles, createStyles, CSSProperties } from 'core/atoms/styles';
import { DraggableTypes } from 'core/atoms/types';
import { blue, grey } from 'core/atoms/colors';

import { DroppableOutlinedArea } from 'core/cells/droppable-outlined-area';
import { FormControlLabel } from 'core/cells/form-control-label';
import { Checkbox } from 'core/cells/checkbox';
import { FlexBox } from 'core/cells/box';

import { useTranslations } from 'core/dna/translations';

import { LessonProperty, LessonItemsData } from '../../types';

import { Lesson } from './components/lesson';
import { SubjectsBlock } from './components/subjects-block';
import { TeachersBlock } from './components/teachers-block';
import { RoomsBlock } from './components/rooms-block';
import { ClassesBlock } from './components/classes-block';
import { MinutesPerTimeSlotEditor } from './components/minutes-per-time-slot-editor';

import { useLessonGroupCreator } from './use-lesson-group-creator';
import { Avatar } from '../../../../../../../../../../../core/cells/avatar';
import { Chip } from '../../../../../../../../../../../core/cells/chip';

const useStyles = makeStyles((theme) => {
  const fullHeightMixin: CSSProperties = {
    height: '100%',
  };
  const fullWidthMixin: CSSProperties = {
    width: '100%',
  };
  const fullHeightWithScrollMixin: CSSProperties = {
    ...fullHeightMixin,
    overflow: 'scroll',
  };

  return createStyles({
    rootContainer: {
      ...fullHeightMixin,
      display: 'grid',
      gridTemplateColumns: 'repeat(24, 1fr)',
      gridTemplateRows: 'repeat(16, 1fr)',
      gap: '8px 8px',
      justifyItems: 'stretch',
      alignItems: 'stretch',
    },
    subjectsBlockArea: {
      gridColumn: '1 / 7',
      gridRow: '1 / 9',
    },
    teachersBlockArea: {
      gridColumn: '1 / 7',
      gridRow: '9 / 17',
    },
    roomsBlockArea: {
      gridColumn: '19 / 25',
      gridRow: '1 / 9',
    },
    classesBlockArea: {
      gridColumn: '19 / 25',
      gridRow: '9 / 17',
    },
    lessonsArea: {
      gridColumn: '7 / 19',
      gridRow: '1 / 14',
    },
    additionalArea: {
      gridColumn: '7 / 19',
      gridRow: '14 / 17',
    },
    lesson: {
      ...fullWidthMixin,
      maxWidth: theme.spacing(100),
      marginTop: theme.spacing(1),
    },
    lessonHours: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: theme.spacing(1),
    },
    ptb0: {
      '&.MuiGrid-item': {
        paddingTop: 0,
        paddingBottom: 0,
      },
    },
    subjectsDroppableArea: {
      ...fullHeightMixin,
      ...fullWidthMixin,
    },
    fullHeightWithScroll: {
      ...fullHeightWithScrollMixin,
    },
    lessonsAreaSection: {
      ...fullHeightMixin,
      padding: theme.spacing(2),
      backgroundColor: blue['100'],
      borderTopLeftRadius: theme.spacing(0.5),
      borderTopRightRadius: theme.spacing(0.5),
    },
    lessonsScrollableSection: {
      ...fullHeightWithScrollMixin,
      borderRadius: theme.spacing(0.5),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    additionalAreaSection: {
      ...fullHeightWithScrollMixin,
      padding: theme.spacing(2),
      backgroundColor: blue['100'],
      borderBottomLeftRadius: theme.spacing(0.5),
      borderBottomRightRadius: theme.spacing(0.5),
    },
    error: {
      color: theme.palette.extended?.danger[0] ?? 'red',
    },
  });
});

export interface LessonGroupCreatorProps {
  data?: LessonItemsData;
}

export const LessonGroupCreator = (props: LessonGroupCreatorProps) => {
  const { data } = props;
  const classes = useStyles();

  const { texts } = useTranslations();

  const [subjectOver, setSubjectOver] = useState<boolean>(false);

  const {
    lessons,
    onLessonDelete,
    onNewSubjectDropped,
    teachers,
    onTeacherDropped,
    onTeacherDelete,
    rooms,
    onRoomDropped,
    onRoomDelete,
    chapters,
    onChapterDropped,
    onChapterDelete,
    plannedMinutesPerTimeSlot,
    errorSubjects,
    errorPlannedMinutesPerTimeSlot,
    addMinutesPerTimeSlot,
    deleteMinutesPerTimeSlot,
    canBeSplit,
    // mustBeNonstop,
    changeCanBeSplit,
    // changeMustBeNonstop,
    onLessonSelect,
    onTeacherDoubleClick,
    onRoomDoubleClick,
    onChapterDoubleClick,
  } = useLessonGroupCreator({ data });

  const filteredByParentId = (parentId: string, list?: LessonProperty[]) => {
    if (!list || !list.length) {
      return undefined;
    }
    const filteredList = list.filter((li) => li.lessonId === parentId);
    return filteredList && filteredList.length ? filteredList : undefined;
  };

  return (
    <section className={classes.rootContainer}>
      <div
        className={classes.subjectsBlockArea}
        data-intercom-target="Subjects Block"
      >
        <SubjectsBlock
          className={classes.fullHeightWithScroll}
          onDoubleClick={onNewSubjectDropped}
        />
      </div>
      <div
        className={classes.teachersBlockArea}
        data-intercom-target="Teachers Block"
      >
        <TeachersBlock
          className={classes.fullHeightWithScroll}
          onDoubleClick={onTeacherDoubleClick}
        />
      </div>
      <div
        className={classes.roomsBlockArea}
        data-intercom-target="Rooms Block"
      >
        <RoomsBlock
          className={classes.fullHeightWithScroll}
          onDoubleClick={onRoomDoubleClick}
        />
      </div>
      <div
        className={classes.classesBlockArea}
        data-intercom-target="Classes Block"
      >
        <ClassesBlock
          className={classes.fullHeightWithScroll}
          onChapterDoubleClick={onChapterDoubleClick}
        />
      </div>
      <div className={classes.lessonsArea} data-intercom-target="Lessons Area">
        <section className={classes.lessonsAreaSection}>
          <DroppableOutlinedArea
            // centeredContent
            label={
              texts.planner.preparationSpace.schedulesModule.dropSubjectHere
            }
            type={DraggableTypes.Subject}
            isOver={subjectOver}
            onOverChanged={setSubjectOver}
            onDrop={onNewSubjectDropped}
            className={classes.subjectsDroppableArea}
            error={!!errorSubjects}
          >
            <section className={classes.lessonsScrollableSection}>
              {lessons &&
                lessons.length &&
                lessons.map((lesson) => (
                  <Lesson
                    key={lesson.id}
                    className={classes.lesson}
                    lesson={lesson}
                    onDelete={onLessonDelete}
                    teachers={filteredByParentId(lesson.id, teachers)}
                    onTeacherDropped={onTeacherDropped}
                    onTeacherDelete={onTeacherDelete}
                    rooms={filteredByParentId(lesson.id, rooms)}
                    onRoomDropped={onRoomDropped}
                    onRoomDelete={onRoomDelete}
                    chapters={filteredByParentId(lesson.id, chapters)}
                    onChapterDropped={onChapterDropped}
                    onChapterDelete={onChapterDelete}
                    onSelect={onLessonSelect}
                  />
                ))}
              {errorSubjects ? (
                <FlexBox justifyContent="center" className={classes.error}>
                  {errorSubjects}
                </FlexBox>
              ) : undefined}
            </section>
          </DroppableOutlinedArea>
        </section>
      </div>
      <div
        className={classes.additionalArea}
        data-intercom-target="Additional Area"
      >
        <section className={classes.additionalAreaSection}>
          <div data-intercom-target="MinutesPerTimeSlot Editor">
            <MinutesPerTimeSlotEditor
              value={plannedMinutesPerTimeSlot}
              onAdd={addMinutesPerTimeSlot}
              onDelete={deleteMinutesPerTimeSlot}
              error={errorPlannedMinutesPerTimeSlot ?? undefined}
            />
          </div>
          <div data-intercom-target="MustBeNonstop Checkbox">
            <FormControlLabel
              sx={{
                marginLeft: 0,
                '& .MuiTypography-root': { color: grey['900'] },
              }}
              control={
                <Checkbox
                  sx={{ '& .MuiSvgIcon-root': { borderStyle: 'none' } }}
                  checked={canBeSplit}
                  onChange={changeCanBeSplit}
                  name="mustBeNonstop"
                  color="secondary"
                />
              }
              label={texts.planner.preparationSpace.schedulesModule.canBeSplit}
            />
          </div>
        </section>
      </div>
    </section>
  );
};

// rgba(0, 0, 0, 0.87)
