import moment from 'moment';

export enum Types {
  Array = '[object Array]',
  Date = '[object Date]',
  Weekday = 'WEEKDAY',
  Number = '[object Number]',
  String = '[object String]',
  Boolean = '[object Boolean]',
  Object = '[object Object]',
  Undefined = '[object Undefined]',
  Unknown = 'UNKNOWN',
}

/** https://momentjs.com/docs/#/query/is-a-moment/ */
export const isMoment = (value: any) => {
  return value instanceof moment;
};

export const isDate = (value: any): boolean => {
  return Object.prototype.toString.call(value) === Types.Date;
};

export const isWeekday = (value: any): boolean => {
  return isNumber(value) && value >= 0 && value <= 7;
};

export const isNumber = (value: any): boolean => {
  return (
    Object.prototype.toString.call(value) === Types.Number && !isNaN(value)
  );
};

export const isString = (value: any): boolean => {
  return Object.prototype.toString.call(value) === Types.String;
};

export const isBoolean = (value: any): boolean => {
  return Object.prototype.toString.call(value) === Types.Boolean;
};

export const isUndefined = (value: any): boolean => {
  return Object.prototype.toString.call(value) === Types.Undefined;
};

export const isArray = (value: any): boolean => {
  return Array.isArray(value);
};

export const isObject = (value: any): boolean => {
  return Object.prototype.toString.call(value) === Types.Object;
};

export const checkType = (value: any): Types => {
  if (isArray(value)) {
    return Types.Array;
  }
  if (isDate(value)) {
    return Types.Date;
  }
  if (isWeekday(value)) {
    return Types.Weekday;
  }
  if (isNumber(value)) {
    return Types.Number;
  }
  if (isString(value)) {
    return Types.String;
  }
  if (isBoolean(value)) {
    return Types.Boolean;
  }
  if (isObject(value)) {
    return Types.Object;
  }
  if (isUndefined(value)) {
    return Types.Undefined;
  }
  return Types.Unknown;
};
