import { forwardRef, ReactElement, Ref } from 'react';

import { TransitionProps } from 'core/atoms/transitions';

import { Slide } from 'core/cells/slide';

export const SlideUpTransition = forwardRef(function Transition(
  props: TransitionProps & { children?: ReactElement },
  ref: Ref<unknown>,
) {
  return (
    <Slide ref={ref} {...props} direction="up" mountOnEnter unmountOnExit />
  );
});
