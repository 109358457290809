import { useEffect, useState, useCallback } from 'react';
import invert from 'invert-color';
import { v4 as uuidv4 } from 'uuid';
import * as _ from 'lodash';

import { grey } from 'core/atoms/colors';
import { tick } from 'core/atoms/date-time';
import { uniq } from 'core/atoms/functions/array';

import { EventInput } from 'core/cells/full-calendar';

import { useParams, useHistory, useLocale } from 'core/dna/routing';

import { getSharedSchedule } from './firebase';
import useQueryString from './use-query-string';
import { DisplayProps } from './display-props';

function invertedColor(bgColor: string) {
  return invert(bgColor!, {
    black: '#3a3a3a',
    white: '#fafafa',
  });
}

export const useShareDesktop = () => {
  const history = useHistory();
  const locale = useLocale();
  const { shareId, resourceType, resourceId } = useParams<any>();

  const [sharedSchedule, setSharedSchedule] = useState<any>({});
  const [sharedScheduleEvents, setSharedScheduleEvents] = useState<
    EventInput[]
  >([]);
  const [scheduleData, setScheduleData] = useState<any>({});
  const [selectedResource, setSelectedResource] = useState<string>('');
  const [filter, setFilter] = useState<string>('');

  const [displayProps, setDisplayProps] = useState<{ [key: string]: boolean }>({
    [DisplayProps.showClasses]: true,
    [DisplayProps.showGroups]: true,
    [DisplayProps.showTeachers]: true,
    [DisplayProps.showRooms]: true,
  });

  const [labelQs, setLabelQs] = useQueryString('label', 'latest');

  // useTraceUpdate({ filter, displayProps, selectedResource });

  const selectTag = useCallback(
    (labelName: string) => {
      if (sharedSchedule?.labels?.[labelName]) {
        if (labelName !== labelQs) {
          setLabelQs(labelName);
        }

        sharedSchedule.labels[labelName]?.get().then((response: any) => {
          const data = response.data();
          const personsById = _.keyBy(data.persons, 'id');
          const classesById = _.keyBy(data.classes, 'id');
          const roomsById = _.keyBy(data.rooms, 'id');
          const subjectsById = _.keyBy(data.subjects, 'id');

          setScheduleData({
            ...data,
            personsById,
            classesById,
            roomsById,
            subjectsById,
          });
        });
      }
    },
    [labelQs, setLabelQs, sharedSchedule],
  );

  const mapEvents = useCallback(
    (data: any) => {
      if (!resourceType) {
        return [];
      }
      const m: any = {
        persons: 'teachers',
        classes: 'classes',
        rooms: 'rooms',
      };
      // console.log(fbEvents);
      // console.log(selectedClass);
      return _.chain(data.events)
        .filter((event) => {
          if (!resourceId) {
            return true;
          }
          return (
            event[m[resourceType]].includes(resourceId) &&
            data[`${resourceType}ById`][resourceId]
          );
        })
        .map((event) => {
          const subject = data.subjectsById[event.subject];
          const bgColor = subject?.bgColor ?? '#000000';

          // const classes = event.classes
          //   .map((classId: string) => data.classesById[classId].name)
          //   .join(', ');

          const classesArr: string[] = [];
          let chaptersArr: { id: string; name: string }[] = [];
          event.classes?.forEach((classId: string) => {
            const c = data.classesById[classId];
            classesArr.push(c.name);
            c.groups?.forEach((cg: any) => {
              chaptersArr = [...chaptersArr, ...cg.chapters];
            });
          });

          const chapters = uniq(
            chaptersArr
              .filter((c) => event.chapters.indexOf(c.id) > -1)
              .map((c) => c.name),
          ).join(', ');

          const rooms = event.rooms
            .map((roomId: string) => data.roomsById[roomId].name)
            .join(', ');

          const teachers =
            event.teachers
              ?.map(
                (personId: string) =>
                  `${data.personsById[personId].firstName} ${data.personsById[personId].lastName}`,
              )
              .join(', ') ?? null;

          return {
            id: uuidv4(),
            title: subject?.name ?? '',
            backgroundColor: bgColor,
            textColor: invertedColor(bgColor),
            borderColor: grey['600'],
            classes: displayProps.showClasses
              ? classesArr.join(', ')
              : undefined,
            groups: displayProps.showGroups ? chapters : undefined,
            rooms: displayProps.showRooms ? rooms : undefined,
            teachers: displayProps.showTeachers ? teachers : undefined,
            start: tick(event.weekday).setTime(event.startTime).toDate(),
            end: tick(event.weekday).setTime(event.endTime).toDate(),
          };
        })
        .value();
    },
    [
      displayProps.showClasses,
      displayProps.showGroups,
      displayProps.showRooms,
      displayProps.showTeachers,
      resourceId,
      resourceType,
    ],
  );

  const selectResourceTypeAndId = useCallback(
    (resourceType: string, resourceId: string) => {
      history.push(
        `/${locale}/planner/share/${shareId}/${resourceType}/${resourceId}`,
      );
    },
    [history, locale, shareId],
  );

  useEffect(() => {
    if (shareId) {
      getSharedSchedule(shareId).then((result) => {
        setSharedSchedule(result.data());
      });
    }
  }, [shareId]);

  useEffect(() => {
    selectTag(labelQs);
  }, [labelQs, selectTag]);

  useEffect(() => {
    // console.log(JSON.stringify(fbResponse));
    const resourceIdMap = scheduleData[`${resourceType}ById`];
    if (resourceId && resourceIdMap) {
      switch (resourceType) {
        case 'classes':
        case 'rooms':
          setSelectedResource(resourceIdMap[resourceId].name);
          break;
        case 'persons':
          setSelectedResource(
            `${resourceIdMap[resourceId].firstName} ${resourceIdMap[resourceId].lastName}`,
          );
          break;
        default:
          break;
      }
    }

    const entries = scheduleData?.events ?? null;
    if (entries && resourceType && resourceId && labelQs) {
      const mappedEvents = mapEvents(scheduleData);
      setSharedScheduleEvents(mappedEvents);
    }
  }, [labelQs, mapEvents, resourceId, resourceType, scheduleData]);

  return {
    resourceId,
    filter,
    setFilter,
    sharedSchedule,
    scheduleData,
    displayProps,
    setDisplayProps,
    sharedScheduleEvents,
    selectedResource,
    selectResourceTypeAndId,
  };
};
