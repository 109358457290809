import { useCallback } from 'react';

import {
  selectedClassesStateVar,
  selectedRoomsStateVar,
  selectedSubjectsStateVar,
  selectedTeachersStateVar,
  schedulesStateVar,
  timePlanStateVar,
  defaults,
} from 'modules/planner/memory/apollo/cache';

export const useResetTermStateDependencies = () => {
  const func = useCallback(() => {
    selectedClassesStateVar(defaults.selectedClassesState);
    selectedRoomsStateVar(defaults.selectedRoomsState);
    selectedSubjectsStateVar(defaults.selectedSubjectsState);
    selectedTeachersStateVar(defaults.selectedTeachersState);
    schedulesStateVar(defaults.schedulesState);
    timePlanStateVar(defaults.timePlanState);
  }, []);

  return {
    resetTermStateDependencies: func,
  };
};
