import { useCallback } from 'react';

import { Grid } from 'core/cells/grid';
import { Drawer } from 'core/cells/drawer';
import { CircularProgress } from 'core/cells/circular-progress';
import { useSnackbar } from 'core/cells/notistack';

import { Class } from 'modules/planner/dna/types/class';

import { ClassesTabbedPage } from '../../components/classes-tabbed-page';
import { useStudentsState, studentsStore } from '../../store/students';

import { SyncStudentsTree } from './components/sync-students-tree';
import { StudentsTable } from './components/students-table';

export const ClassesStudents = () => {
  const { enqueueSnackbar } = useSnackbar();

  const { studentsState } = useStudentsState();

  const importing = false;

  const handleImportSuccess = useCallback(
    (count: number) => {
      enqueueSnackbar(`*${count} teacher(s) w(as/ere) exported successfully!`, {
        variant: 'success',
      });
    },
    [enqueueSnackbar],
  );

  const handleImportError = useCallback(() => {
    enqueueSnackbar(`Error importing teachers`, {
      variant: 'error',
    });
  }, [enqueueSnackbar]);

  const studentsTabContent = useCallback(
    () => (
      <StudentsTable onImportStudentsClick={studentsStore.showImportList} />
    ),
    [],
  );

  return (
    <Grid container>
      <Grid item xs={12}>
        <Drawer
          anchor="left"
          open={studentsState.importListVisible}
          onClose={studentsStore.hideImportList}
        >
          <SyncStudentsTree />
        </Drawer>
      </Grid>
      <Grid item xs={12}>
        {importing && <CircularProgress size={100} />}
        <ClassesTabbedPage tabContent={studentsTabContent} />
      </Grid>
    </Grid>
  );
};
