import { JournalEntry } from 'core/dna/types/generated/JournalEntry';
import { mapNullableListToList } from 'core/dna/functions/map-nullable-list-to-list';

export const mapApiJournalToJournal = (
  apiJournal: JournalEntry | null,
): JournalEntry => {
  if (apiJournal === null) {
    throw new TypeError('"apiJournal" should not be null!');
  }

  return apiJournal;
};

export const mapApiJournalToJournalList = (
  apiJournalList: (JournalEntry | null)[] | null,
): JournalEntry[] | undefined => {
  return mapNullableListToList<JournalEntry, JournalEntry>(
    apiJournalList,
    mapApiJournalToJournal,
  );
};
