export type IsSubstringCondition = 'AND' | 'OR';

export const isSubstring = (
  str: string,
  of: string | string[],
  condition?: IsSubstringCondition,
) => {
  function singleCheck(ofSingle: string) {
    return ofSingle.toLowerCase().indexOf(str.toLowerCase()) > -1;
  }
  if (Array.isArray(of)) {
    if (condition === 'AND') {
      const negativeResult = of.some((source) => !singleCheck(source));
      return !negativeResult;
    }
    const positiveResult = of.some((source) => singleCheck(source));
    return positiveResult;
  }
  return singleCheck(of);
};
