import { useEffect } from 'react';
import { LocalHospital } from '@material-ui/icons';

import { logger, LogType } from 'core/atoms/housekeeping';

import { Skeleton } from 'core/cells/skeleton';

import { createReactTableStore } from 'core/memory/pure/react-table-store';
import { useGetTermsState } from 'core/memory/apollo/terms/local/get-terms-state';

import { useReactTableState } from 'core/metabolism/table-state';

import { Table } from 'templates/mdp/components/react-table';
import { GridContainer, GridItem } from 'templates/mdp/components/grid';
import {
  Card,
  CardBody,
  CardHeader,
  CardIcon,
} from 'templates/mdp/components/card';
import { useStyles } from 'templates/mdp/views/tables/react-tables.styles';

import { useAllergiesTableRead } from './use-allergies-table-read';

export const allergiesTableStore = createReactTableStore({}, {});

export const AllergiesTable = () => {
  const classes = useStyles();
  const { data: termsState } = useGetTermsState();
  const { columns, data, loading, title } = useAllergiesTableRead();

  const {
    initialPage,
    pageSize,
    sort,
    filters,
    setSort,
    setFilters,
    setPage,
    setPageSize,
  } = useReactTableState(
    allergiesTableStore,
    termsState?.selected ?? undefined,
  );

  useEffect(() => {
    logger.debug({
      title: 'did mount',
      type: LogType.Info,
      logger: 'allergies-table',
    });
  }, []);

  return loading || !termsState?.selected ? (
    <Skeleton variant="rect" width="100%" height="80vh" />
  ) : (
    <GridContainer spacing={3} justify="center">
      <GridItem xs={12} lg={8} xl={6}>
        <Card>
          <CardHeader color="primary" icon>
            <CardIcon color="primary">
              <LocalHospital />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>{title}</h4>
          </CardHeader>
          <CardBody>
            <Table
              noActions
              columns={columns}
              data={data ?? []}
              key={termsState.selected}
              onChangeRowsPerPage={setPageSize}
              onChangePage={setPage}
              onFilterChange={setFilters}
              onOrderChange={setSort}
              options={{
                initialPage,
                pageSize,
                sort,
                filters,
              }}
            />
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
};
