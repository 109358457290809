import { interval } from 'rxjs';
import {
  map,
  distinctUntilChanged as distinctUntilChangedOp,
} from 'rxjs/operators';

import { logger, LogType } from 'core/atoms/housekeeping';

import {
  getAccessTokenStorage,
  getSessionTokenStorage,
} from 'auth/memory/browser';
import { getHasPinCodeStorage } from 'auth/memory/browser/has-pin-code-storage';

import { authStore } from './auth-store';

interface ValueType {
  accessToken: string | null;
  sessionToken: string | null;
  hasPinCode: boolean | null;
}

interval(1000)
  .pipe(
    map(
      (): ValueType => ({
        accessToken: getAccessTokenStorage().getAccessToken(),
        sessionToken: getSessionTokenStorage().getSessionToken(),
        hasPinCode: getHasPinCodeStorage().getHasPinCode(),
      }),
    ),
  )
  .pipe(
    distinctUntilChangedOp((prev: any, cur: any) =>
      ['accessToken', 'sessionToken'].every(
        (el) =>
          prev.hasOwnProperty(el) &&
          cur.hasOwnProperty(el) &&
          prev[el] === cur[el],
      ),
    ),
  )
  .subscribe((value: ValueType) => {
    logger.debug({
      title: 'on value',
      logger: 'time-interval-check',
      type: LogType.Info,
      value: value ? [{ ...value }] : 'NO VALUE',
    });
    authStore.setAuth(value);
  });
