import moment from 'moment';
import {
  BatchState,
  BatchType,
  UpdateEvaluationBatchInput,
} from 'core/dna/types/generated/_globalTypes';
import { dateTimeInputFormat } from 'core/atoms/date-time/formats';
import { EvaluationBatch } from '../../types';

export const mapEvaluationBatchToUpdateEvaluationBatchInput = (
  batch: EvaluationBatch,
): UpdateEvaluationBatchInput => {
  const { state, deadlineAt, type, term, id } = batch;

  return {
    deadline: moment(deadlineAt).format(dateTimeInputFormat) || '',
    state: state || BatchState.draft,
    term: term || '',
    type: type || BatchType.halfYear,
    evaluationBatchId: id || '',
  };
};
