import { useEffect } from 'react';
import queryString from 'query-string';

import { useLocation } from 'core/dna/routing';

import { LoginCallback, AuthorizationErrorCallback } from 'auth/dna/types';

import { useLazyLogin } from './use-lazy-login';

export const useLogin = (
  callback: LoginCallback,
  errorCallback?: AuthorizationErrorCallback,
) => {
  const { login } = useLazyLogin(callback, errorCallback);
  const location = useLocation();
  const { transient } = queryString.parse(location.search);

  useEffect(() => {
    login({ transient: String(transient) });
  }, [login, transient]);
};
