import { useState, useCallback, useMemo } from 'react';
import DateRangeIcon from '@mui/icons-material/DateRange';

import { makeStyles, createStyles } from 'core/atoms/styles';
// import { OasOrganismError } from 'core/atoms/errors';

import { DayCellContentType } from 'core/cells/full-calendar';

import { useTranslations } from 'core/dna/translations';

import {
  CardHeader,
  CardIcon,
  CardBody,
  Card,
} from 'templates/mdp/components/card';

import { useStyles as useTablesStyles } from 'templates/mdp/views/tables/react-tables.styles';

// import { mapScheduleToScheduleStateInput } from 'modules/planner/dna/functions/map-Schedule-to-ScheduleStateInput';
// import { ScheduleStateInput } from 'modules/planner/dna/types/local';
//
// import { useSetSchedulesSelectedState } from 'modules/planner/memory/apollo/schedules/local';
// import { useGetScheduleDescriptions } from 'modules/planner/memory/apollo/schedules/remote';

import { TermWeek } from 'modules/planner/metabolism/use-term-weeks';
import { useTimePlan } from 'modules/planner/metabolism/use-time-plan';

import { TimePlanCalendar } from 'modules/planner/organisms/time-plan-calendar';

import { WeeksSelector } from './components/weeks-selector';
import { HolidayOverlay } from './components/holiday-overlay';
import { useSetCurrentSchedule } from '../../../../metabolism/schedules';

const useStyles = makeStyles((theme) =>
  createStyles({
    weeksSelectorMargin: {
      marginBottom: theme.spacing(1),
    },
  }),
);

// const FILE_NAME = 'year-plan';

export const YearPlan = () => {
  const classes = useStyles();
  const tableClasses = useTablesStyles();

  const { texts } = useTranslations();
  const [week, setWeek] = useState<TermWeek | null>(null);

  const {
    showClasses,
    showGroups,
    setShowGroups,
    showTeachers,
    setShowTeachers,
    showRooms,
    setShowRooms,
  } = useTimePlan({ scheduleId: week?.schedule?.id ?? '' });

  // const { setSchedulesSelectedState } = useSetSchedulesSelectedState();
  const { setCurrentSchedule } = useSetCurrentSchedule();

  // const { map: scheduleMap } = useGetScheduleDescriptions();

  // const mapValueToScheduleStateInput = useCallback(
  //   (value: string) => {
  //     const s = scheduleMap?.byId[value];
  //     if (!s) {
  //       throw new OasOrganismError(
  //         `mapValueToScheduleStateInput() => No schedule with ID: ${value}`,
  //         {
  //           title: FILE_NAME,
  //         },
  //       );
  //     }
  //     return mapScheduleToScheduleStateInput(s);
  //   },
  //   [scheduleMap?.byId],
  // );

  const onWeekSelect = useCallback(
    (week: TermWeek) => {
      if (!week.schedule) {
        return;
      }
      setWeek(week);
      // const schedulesInput: ScheduleStateInput[] = [
      //   mapValueToScheduleStateInput(week.schedule.id),
      // ];
      setCurrentSchedule(week.schedule.id);
      // setSchedulesSelectedState({ schedules: schedulesInput });
    },
    [setCurrentSchedule],
  );

  const dayCellContent: DayCellContentType = useCallback(
    (arg) => {
      if (week?.holidays?.map((h) => h.day()).find((hd) => hd === arg.dow)) {
        return <HolidayOverlay />;
      }
    },
    [week],
  );

  const now = useMemo(() => {
    if (week?.monDate) {
      return week.monDate;
    }
  }, [week]);

  return (
    <Card>
      <CardHeader color="primary" icon>
        <CardIcon color="primary">
          <DateRangeIcon />
        </CardIcon>
        {week?.schedule && (
          <h4 className={tableClasses.cardIconTitle}>
            {texts.oasCommon.weeks.one} {week.weekNumber}: {week.schedule.name}
          </h4>
        )}
      </CardHeader>
      <CardBody>
        <WeeksSelector
          className={classes.weeksSelectorMargin}
          onSelect={onWeekSelect}
          selected={week}
        />
        <TimePlanCalendar
          readonly
          scheduleId={week?.schedule?.id ?? ''}
          showClasses={showClasses}
          showGroups={showGroups}
          setShowGroups={setShowGroups}
          showTeachers={showTeachers}
          setShowTeachers={setShowTeachers}
          showRooms={showRooms}
          setShowRooms={setShowRooms}
          dayCellContent={dayCellContent}
          now={now}
        />
      </CardBody>
    </Card>
  );
};
