import { gql } from 'core/dna/types/apollo';

import { SUBJECT_DATA_FRAGMENT } from 'modules/planner/memory/apollo/_fragments';

export const EDIT_SUBJECT_MUTATION = gql`
  mutation EditSubject($input: EditSubjectInput) {
    editSubject(input: $input) {
      ...SubjectData
    }
  }
  ${SUBJECT_DATA_FRAGMENT}
`;
