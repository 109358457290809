import React, { ReactNode } from 'react';
import clsx from 'clsx';

import { useStyles } from './typography.styles';

export interface SuccessProps {
  children?: ReactNode; // PropTypes.node,
}

export const Success = (props: SuccessProps) => {
  const classes = useStyles();
  const { children } = props;
  return (
    <div className={clsx(classes.defaultFontStyle, classes.successText)}>
      {children}
    </div>
  );
};
