import {
  OasError,
  OasErrorConstructor,
  getOasErrorFromError,
  OasErrorOptions,
  OasErrorInterface,
} from './oas-error';

export const OasCellError: OasErrorConstructor = class OasCellError extends OasError {
  constructor(message?: string, options?: OasErrorOptions) {
    super(message, { ...options, type: 'OasCellError' });
  }

  static fromError(e: unknown, options?: OasErrorOptions): OasErrorInterface {
    return getOasErrorFromError(OasCellError, e, options);
  }
};
