import moment from 'moment';
import { apiFormat } from './formats';

type DateInput = Date | string;

export const getDiffInHours = (
  start: DateInput,
  end: DateInput,
  format = apiFormat,
): number => {
  const startTime = moment(start, format);
  const endTime = moment(end, format);

  const duration = moment.duration(endTime.diff(startTime));

  const hours = duration.asHours();

  return parseFloat(hours.toFixed(2));
};
