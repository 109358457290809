import { AbsenceState } from 'core/dna/types/generated/_globalTypes';
import { getDiffInHours } from 'core/atoms/date-time';

import { Absence } from 'modules/student/dna/types';

export const calculateTotalHoursByAbsenceState = (
  absences: Absence[],
  state: AbsenceState,
) => {
  const notWholeDays = absences
    .filter((absence) => absence.state === state)
    .filter((absence) => absence.wholeDay === false);

  let hours = 0;

  notWholeDays.forEach((absence) => {
    if (absence.to && absence.from) {
      hours += getDiffInHours(absence.from, absence.to);
    }
  });

  return hours;
};
