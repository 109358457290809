import { useState, useLayoutEffect } from 'react';

import { conflictsStore } from './conflicts-store';

export const useConflictsState = () => {
  const [conflictsState, setConflictsState] = useState(
    conflictsStore.initialState,
  );

  useLayoutEffect(() => {
    const subscription = conflictsStore.subscribe(setConflictsState);
    return () => {
      subscription.unsubscribe();
    };
  }, []);

  return {
    conflictsState,
  };
};
