import { useCallback } from 'react';

import { OasMemoryError, OasError } from 'core/atoms/errors';

import { gql, useMutation } from 'core/dna/types/apollo';
import {
  SynchronizeAllocationSchedules,
  SynchronizeAllocationSchedulesVariables,
} from 'core/dna/types/generated/SynchronizeAllocationSchedules';

import { useLazyGetTermsState } from 'core/memory/apollo/terms/local';

import { GET_ALLOCATION_PLANS_QUERY } from './_gql';

const SYNCHRONIZE_ALLOCATION_SCHEDULES_MUTATION = gql`
  mutation SynchronizeAllocationSchedules(
    $input: SynchroniseAllocationSchedulesInput!
  ) {
    synchroniseAllocationSchedules(input: $input) {
      statusCode
      status
      description
    }
  }
`;

const FILE_NAME = 'use-synchronize-allocate-schedules';

export const useSynchronizeAllocationSchedules = () => {
  const { getTermState } = useLazyGetTermsState();

  const [synchronizeAllocateSchedules] = useMutation<
    SynchronizeAllocationSchedules,
    SynchronizeAllocationSchedulesVariables
  >(SYNCHRONIZE_ALLOCATION_SCHEDULES_MUTATION);

  const func = useCallback(async () => {
    try {
      const termsState = await getTermState(true);

      const result = await synchronizeAllocateSchedules({
        variables: { input: { termId: termsState!.selected! } },
        refetchQueries: [
          {
            query: GET_ALLOCATION_PLANS_QUERY,
            variables: { termId: termsState!.selected! },
          },
        ],
      });

      if (result.errors) {
        throw new OasMemoryError(JSON.stringify(result.errors), {
          title: FILE_NAME,
        });
      }
      return result.data?.synchroniseAllocationSchedules ?? undefined;
    } catch (e: any) {
      if (e instanceof OasError) {
        throw e;
      } else {
        throw OasMemoryError.fromError(e, {
          title: FILE_NAME,
        });
      }
    }
  }, [synchronizeAllocateSchedules, getTermState]);

  return {
    synchronizeAllocateSchedules: func,
  };
};
