import {
  OasModule,
  OasSitemapData,
  OasBlock,
  OasLanguage,
} from 'core/dna/routing/types';
import { DEFAULT_LOCALE } from 'core/dna/routing/constants';

export const mapOasModuleToOasSitemapData = (
  oasModule: OasModule,
): OasSitemapData => {
  const { blocks, ...rest } = oasModule;
  return { ...rest };
};

export const mapOasBlockToOasSitemapData = (
  oasBlock: OasBlock,
): OasSitemapData => {
  const { pages, ...rest } = oasBlock;
  return { ...rest };
};

export const isOasLanguage = (
  locale: OasLanguage | string,
): locale is OasLanguage => {
  return locale === 'no' || locale === 'en' || locale === 'uk';
};

export const locale = (): OasLanguage => {
  const pathArray = window.location.pathname.split('/');
  if (pathArray.length > 1 && isOasLanguage(pathArray[1])) {
    return pathArray[1];
  }
  return DEFAULT_LOCALE;
};
