import { GetStudentDetails_PersonT2_carers as ApiCarer } from 'core/dna/types/generated/GetStudentDetails';
import { mapNullableListToList } from 'core/dna/functions/map-nullable-list-to-list';
import { Carer } from 'modules/student/dna/types/carer';

export const mapApiCarerToCarer = (apiCarer: ApiCarer | null): Carer => {
  if (apiCarer === null) {
    throw new TypeError('"apiCarer" should not be null!');
  }

  const { tuid, details } = apiCarer;

  return {
    tuid,
    firstName: details?.firstName || null,
    lastName: details?.lastName || null,
    mail: details?.mail || null,
    mobile: details?.mobile || null,
  };
};

export const mapApiCarerToCarerList = (
  apiCarerList: (ApiCarer | null)[] | null,
): Carer[] | undefined => {
  return mapNullableListToList<ApiCarer, Carer>(
    apiCarerList,
    mapApiCarerToCarer,
  );
};
