import React from 'react';

import { Skeleton } from 'core/cells/skeleton';
import { createStyles, makeStyles } from 'core/atoms/styles';
import { createReactTableStore } from 'core/memory/pure/react-table-store';
import { useGetTermsState } from 'core/memory/apollo/terms/local/get-terms-state';
import {
  useReactTableExport,
  useReactTableState,
} from 'core/metabolism/table-state';
import { useTranslations } from 'core/dna/translations';

import { Remark } from 'modules/student/dna/types/remark';
import { Button } from 'templates/mdp/components/custom-buttons';
import { Table } from 'templates/mdp/components/react-table';
import { Card, CardBody } from 'templates/mdp/components/card';

import { RemarkTableItem, useRemarkTableRead } from './use-remark-table-read';

const useStyles = makeStyles(() =>
  createStyles({
    exportBtn: {
      position: 'absolute',
      right: 0,
      bottom: 'calc(100% + 8px)',
    },
  }),
);

interface UseRemarkTableProps {
  data: Remark[] | undefined;
  loading: boolean;
}

export const remarkTableStore = createReactTableStore({}, {});

export const RemarkTable = (props: UseRemarkTableProps) => {
  const { data, loading } = props;
  const classes = useStyles();

  const { texts } = useTranslations();

  const { columns, tableData } = useRemarkTableRead(data);
  const { data: termsState } = useGetTermsState();
  const { exportToExcel } = useReactTableExport<RemarkTableItem>(
    columns,
    tableData,
  );

  const {
    initialPage,
    pageSize,
    sort,
    filters,
    setSort,
    setFilters,
    setPage,
    setPageSize,
  } = useReactTableState(remarkTableStore, termsState?.selected ?? undefined);

  return loading || !termsState?.selected ? (
    <Skeleton variant="rect" width="100%" height="300px" />
  ) : (
    <Card>
      {!!data?.length && (
        <Button
          className={classes.exportBtn}
          color="secondary"
          size="sm"
          onClick={() => {
            exportToExcel(`${data[0].regarding} Anmerkninger`);
          }}
        >
          {texts.oasCommon.exportTo} excel
        </Button>
      )}
      <CardBody>
        <Table
          noActions
          noPagination
          columns={columns}
          data={tableData}
          key={termsState?.selected ?? 'unknown_term'}
          onChangeRowsPerPage={setPageSize}
          onChangePage={setPage}
          onFilterChange={setFilters}
          onOrderChange={setSort}
          options={{
            initialPage,
            pageSize,
            sort,
            filters,
          }}
        />
      </CardBody>
    </Card>
  );
};
