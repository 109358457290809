import { v4 as uuidv4 } from 'uuid';

import { SelectionInfo } from 'core/cells/full-calendar';

import { CalendarEvent } from 'core/dna/types';

export const mapSelectionInfoToCalendarEvent = ({
  selectionInfo,
  title,
}: {
  selectionInfo: SelectionInfo;
  title: string;
}): CalendarEvent => {
  const { start, end, resource } = selectionInfo;

  const event: CalendarEvent = {
    id: uuidv4(),
    title,
    start,
    end,
    rendering: '',
  };
  if (resource) {
    event.resourceIds = [resource.id];
  }
  return event;
};
