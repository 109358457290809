import { gql } from 'core/dna/types/apollo';

import { CLASS_DATA_FRAGMENT } from 'modules/planner/memory/apollo/_fragments';

export const GET_CLASS_QUERY = gql`
  query GetClass($id: String!) {
    Class(id: $id) {
      ...ClassData
    }
  }
  ${CLASS_DATA_FRAGMENT}
`;
