import { useCallback, useMemo, useRef, useEffect } from 'react';

import { OasOrganismError, OasError } from 'core/atoms/errors';

import { useApolloClient } from 'core/dna/types/apollo';
import { ModuleState } from 'core/dna/types/local';
import { useModuleGoHome } from 'core/dna/routing';
import { useAccessRolesState } from 'core/dna/access-roles';
import {
  useSelectedOrganizationState,
  useSelectedOrganizationUpdater,
} from 'core/dna/organizations';
import { useModulesState } from 'core/dna/modules';

import { useRouteModule } from 'app/routes';

import { mapRootAccessRolesToOrgsFlatList } from './functions';

const FILE_NAME = 'use-organization-selector';

export const useOrganizationSelector = () => {
  const apolloClient = useApolloClient();

  const modules = useModulesState();
  const goHome = useModuleGoHome();

  const myAccessRoles = useAccessRolesState();
  const selected = useSelectedOrganizationState();
  const select = useSelectedOrganizationUpdater();
  const previousOrgRef = useRef(selected?.id ?? '');
  const { getSelectedModuleFromRoute } = useRouteModule();

  const orgsFlatList = useMemo(() => {
    if (myAccessRoles?.length) {
      return mapRootAccessRolesToOrgsFlatList(myAccessRoles);
    }
  }, [myAccessRoles]);

  useEffect(() => {
    if (previousOrgRef.current !== selected?.id) {
      previousOrgRef.current = selected?.id ?? '';
    }
  }, [selected]);

  const getSelectedModule = useCallback(
    (modules: ModuleState[]) => {
      const moduleId = getSelectedModuleFromRoute();
      // TODO: To think do we need to filter module by expiration or "on"/"of" status
      const module = modules?.find((m) => m.id === moduleId);
      return module || (modules?.length ? modules[0] : null);
    },
    [getSelectedModuleFromRoute],
  );

  const handleChange = useCallback(
    (newOrgId: string, isFederation = false) => {
      try {
        if (previousOrgRef.current === newOrgId) {
          return;
        }

        previousOrgRef.current = newOrgId;
        select(newOrgId);

        // const moduleState = resetModule(
        //   isFederation
        //     ? OrganizationLevel.Federation
        //     : OrganizationLevel.Organization,
        // );

        apolloClient.clearStore().then();

        goHome(getSelectedModule(modules)?.id);

        // TODO (Ihor): create organizationStore.setFederation(newOFederationId);
        // if (isFederation) {
        //   replace(URLS.directoryPeople({ locale, federationId: newOrgId }));
        //   return;
        // }
        //
        // replace(URLS.orgWelcome(locale));
      } catch (e: any) {
        if (e instanceof OasError) {
          throw e;
        } else {
          throw OasOrganismError.fromError(e, {
            title: FILE_NAME,
          });
        }
      }
    },
    [apolloClient, getSelectedModule, goHome, modules, select],
  );

  return {
    onChange: handleChange,
    selected,
    formattedData: orgsFlatList,
  };
};
