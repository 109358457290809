import { GetClasses_Class_unions_chapters as ApiChapter } from 'core/dna/types/generated/GetClasses';

import { Chapter } from 'modules/planner/dna/types/chapter';
import { mapGetClassesClassUnionsChaptersUnionToUnionData } from 'modules/planner/dna/functions/map-GetClasses_Class_unions_chapters_union-to-Identity';

export const mapGetClassesClassUnionsChaptersListToUnionList = (
  apiChapterList: (ApiChapter | null)[] | null,
): Chapter[] => {
  if (!apiChapterList) {
    return [];
  }
  return apiChapterList.map((ch) =>
    mapGetClassesClassUnionsChaptersToChapter(ch!),
  );
};

export const mapGetClassesClassUnionsChaptersToChapter = (
  apiChapter: ApiChapter,
): Chapter => {
  const { id, name, union } = apiChapter;
  return {
    id,
    name,
    union: mapGetClassesClassUnionsChaptersUnionToUnionData(union),
  };
};
