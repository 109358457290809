import { makeVar } from 'core/dna/types/apollo';
import { TermsState } from 'core/dna/types/local';
import { ApolloCacheFields } from 'core/dna/types/apollo-cache-fields';

import { getTermStorage } from 'core/memory/browser';

export const coreFields: ApolloCacheFields = {
  termsState: {
    read: () => termsStateVar(),
  },
};

const termsStorage = getTermStorage();

const defaults = {
  termsState: {
    selected: termsStorage.getTerm() ?? '',
  },
};

export const termsStateVar = makeVar<TermsState>(defaults.termsState);
