import { gql } from 'core/dna/types/apollo';

import { CLASS_DATA_FRAGMENT } from 'modules/planner/memory/apollo/_fragments';

export const GET_CLASSES_QUERY = gql`
  query GetClasses($termId: String!) {
    Class(filter: { term: { id: $termId } }) {
      ...ClassData
    }
  }
  ${CLASS_DATA_FRAGMENT}
`;
