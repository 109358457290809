import { red } from 'core/atoms/colors';
import { mdStyled } from 'core/atoms/themes';

export const HolidayOverlay = mdStyled('div')({
  width: '100%',
  height: '100%',
  background: red['900'],
  opacity: 0.5,
});

HolidayOverlay.displayName = 'HolidayOverlay';
