import { useCallback } from 'react';

import { OasError, OasPageError } from 'core/atoms/errors';
import { parseTemplate } from 'core/atoms/functions/string';

import { useSnackbar } from 'core/cells/notistack';

import { useTranslations } from 'core/dna/translations';

import { StudentsTableItem } from 'modules/planner/dna/types/student';

import { useDeleteClassMembers } from 'modules/planner/memory/apollo/classes/remote';

const TITLE = 'use-students-table-write';

export const useStudentsTableWrite = () => {
  const { texts } = useTranslations();
  const { deleteClassMembers } = useDeleteClassMembers();
  const { enqueueSnackbar } = useSnackbar();

  const onRowDelete = useCallback(
    async (oldData: StudentsTableItem) => {
      try {
        const result = await deleteClassMembers({
          classId: oldData.classId!,
          roleIds: [oldData.roleId],
        });

        if (result?.statusCode !== 'OK') {
          throw new OasPageError(
            result?.description ??
              parseTemplate(texts.oasCommon.deleteEntitiesOfTypeError.one, {
                entityType: texts.oasCommon.classes.one,
                entity: `${oldData.firstName} ${oldData.lastName}`,
              }),
            { title: TITLE },
          );
        }
        enqueueSnackbar(
          parseTemplate(texts.oasCommon.entitiesOfTypeWereDeleted.one, {
            entityType: texts.oasCommon.classes.one,
            entity: `${oldData.firstName} ${oldData.lastName}`,
          }),
          {
            variant: 'success',
          },
        );
        return 0;
      } catch (e: any) {
        const error =
          e instanceof OasError
            ? e
            : OasPageError.fromError(e, { title: TITLE });
        enqueueSnackbar(
          error.originalMessage ??
            parseTemplate(texts.oasCommon.deleteEntitiesOfTypeError.one, {
              entityType: texts.oasCommon.classes.one,
              entity: `${oldData.firstName} ${oldData.lastName}`,
            }),
          {
            variant: 'error',
          },
        );
        return 1;
      }
    },
    [
      deleteClassMembers,
      enqueueSnackbar,
      texts.oasCommon.classes.one,
      texts.oasCommon.deleteEntitiesOfTypeError.one,
      texts.oasCommon.entitiesOfTypeWereDeleted.one,
    ],
  );

  return { onRowDelete };
};
