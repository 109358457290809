import { useGetClasses } from 'modules/planner/memory/apollo/classes/remote';
import {
  useSetSelectedClassesState,
  useGetSelectedClassesState,
} from 'modules/planner/memory/apollo/classes/local';
import { useUpdateAvailabilityPreferencesForClasses } from 'modules/planner/memory/apollo/classes/remote/use-update-availability-preferences-for-classes';

export const useClasses = () => {
  const { classes, classMap, classesLoading, classesError } = useGetClasses();

  const {
    selectedClassesState,
    loading: classStateLoading,
    error: classStateError,
  } = useGetSelectedClassesState();

  const { setSelectedClassesState } = useSetSelectedClassesState();

  const {
    updateAvailabilityPreferencesForClasses,
  } = useUpdateAvailabilityPreferencesForClasses();

  return {
    classData: classes ?? [],
    classMap: classMap ?? { byId: {} },
    selectedClasses: selectedClassesState || [],
    setSelectedClasses: setSelectedClassesState,
    updateAvailabilityPreferencesForClasses,
    classesLoading: classesLoading || classStateLoading,
    classesError: classesError || classStateError,
  };
};
