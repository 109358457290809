import { useCallback } from 'react';

import { selectedRoomsStateVar } from 'modules/planner/memory/apollo/cache';

export const useSetSelectedRoomsState = () => {
  const func = useCallback((values: string[]) => {
    try {
      selectedRoomsStateVar([...values]);
      return true;
    } catch (e: any) {
      throw new Error(e);
    }
  }, []);

  return {
    setSelectedRoomsState: func,
  };
};
