import { useCallback, useEffect, useMemo, useState } from 'react';

import {
  mapTranslationDueToOrgCategoryType,
  useTranslations,
} from 'core/dna/translations';
import { useOrganization } from 'core/metabolism/organizations';

// import { PersonDetailsT2Group } from 'modules/student/dna/types';
import { StudentWithTotalAbsences } from 'modules/reports/dna/types';
import { mapGroupsToString } from 'modules/student/dna/functions';
// import { CheckboxCell } from './checkbox-cell';

type OmitedStudentWithTotalAbsences = Omit<StudentWithTotalAbsences, 'details'>;
interface AbsencesTableItem extends OmitedStudentWithTotalAbsences {
  details: {
    firstName: string | null;
    lastName: string | null;
    groups: string;
  };
  include: boolean;
}

export const useStudentsAbsenceTableRead = (
  data: StudentWithTotalAbsences[],
) => {
  const { texts } = useTranslations();
  const { organization } = useOrganization();

  const preparedData = useMemo<AbsencesTableItem[]>(() => {
    if (!data) {
      return [];
    }

    return data.map((student) => {
      return {
        ...student,
        details: {
          ...student.details,
          groups: mapGroupsToString(student.details.groups),
        },
        include: true,
      };
    });
  }, [data]);

  const [tableData, setTableData] = useState<AbsencesTableItem[]>(preparedData);

  useEffect(() => {
    setTableData(preparedData);
  }, [preparedData]);

  // const onIncludeToggle = useCallback(
  //   (id: string) => {
  //     const index = tableData.findIndex((el) => el.tuid === id);
  //     const student = tableData.find((s) => s.tuid === id);
  //
  //     if (!student || index === undefined) {
  //       return;
  //     }
  //
  //     student.include = !student.include;
  //     tableData.splice(index, 1, student);
  //
  //     setTableData([...tableData]);
  //   },
  //   [tableData],
  // );

  const columns = useMemo(
    () => [
      // {
      //   Header: 'Include',
      //   accessor: 'include',
      //   Cell: (include: any) => (
      //     <CheckboxCell {...include} onToggle={onIncludeToggle} />
      //   ),
      //   width: 60,
      //   disableFilters: true,
      // },
      {
        Header: texts.planner.common.firstName,
        accessor: 'details.firstName',
      },
      {
        Header: texts.planner.common.lastName,
        accessor: 'details.lastName',
      },
      {
        Header: texts.oasCommon.group.one,
        accessor: 'details.groups',
      },
      {
        Header: texts.planner.common.hours.many,
        accessor: 'total.hours',
        disableFilters: true,
        // minWidth: 80,
        // width: '12.5%',
      },
      {
        Header: texts.planner.common.days.many,
        accessor: 'total.days',
        disableFilters: true,
        // minWidth: 80,
        // width: '12.5%',
      },
    ],
    [texts],
  );

  return {
    title: mapTranslationDueToOrgCategoryType(
      texts,
      organization?.category,
      'students',
    ),
    columns,
    tableData,
  };
};
