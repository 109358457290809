import { makeStyles, Theme, createStyles } from 'core/atoms/styles';

import { useTimePlan } from 'modules/planner/metabolism/use-time-plan';

import { TimePlanCalendar } from 'modules/planner/organisms/time-plan-calendar';

import { Worklist } from './components/worklist';

export interface SchedulePlannerProps {
  scheduleId: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    mb: {
      marginBottom: theme.spacing(2),
    },
  }),
);

export const SchedulePlanner = (props: SchedulePlannerProps) => {
  const { scheduleId } = props;
  const classes = useStyles();

  const {
    showClasses,
    showGroups,
    setShowGroups,
    showTeachers,
    setShowTeachers,
    showRooms,
    setShowRooms,
  } = useTimePlan({ scheduleId });

  return (
    <>
      <TimePlanCalendar
        className={classes.mb}
        scheduleId={props.scheduleId}
        showClasses={showClasses}
        showGroups={showGroups}
        setShowGroups={setShowGroups}
        showTeachers={showTeachers}
        setShowTeachers={setShowTeachers}
        showRooms={showRooms}
        setShowRooms={setShowRooms}
      />
      <Worklist scheduleId={props.scheduleId} />
    </>
  );
};
