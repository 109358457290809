export interface TeacherShort {
  id: string;
  firstName: string;
  lastName: string;
}

export interface Teacher extends TeacherShort {
  phone?: string;
  email?: string;
  tags?: string;
  state?: string;
}

export interface TeacherTableItem extends Teacher {}

export const emptyTeacherItem: Partial<TeacherTableItem> = {
  firstName: '',
  lastName: '',
  phone: '',
  email: '',
  tags: '',
};
