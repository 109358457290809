import { useCallback } from 'react';
import produce from 'immer';

import { OasMemoryError } from 'core/atoms/errors';

import {
  SetSchedulesStateInput,
  SchedulesState,
} from 'modules/planner/dna/types/local';

import { schedulesStateVar } from 'modules/planner/memory/apollo/cache';

export const useSetSchedulesState = () => {
  const setSchedulesState = useCallback((input: SetSchedulesStateInput) => {
    try {
      const previousData = schedulesStateVar();
      const { selected, subscribed, values } = input;
      const data = produce<SchedulesState, SchedulesState>(
        previousData,
        (draft) => {
          if (selected && !previousData.selected?.length) {
            draft.selected = selected;
          }
          if (subscribed) {
            draft.subscribed = subscribed;
          }
          if (values) {
            draft.byId = values
              .map((v) => ({
                id: v.id,
                name: v.name ?? null,
                status: v.status ?? null,
              }))
              .reduce((prev, cur) => {
                return { ...prev, [cur.id]: cur };
              }, {});
          }
        },
      );
      schedulesStateVar(data);
      return true;
    } catch (e: any) {
      throw OasMemoryError.fromError(e, {
        title: 'use-set-schedules-state',
      });
    }
  }, []);

  return {
    setSchedulesState,
  };
};
