import { useCallback, useMemo, useState } from 'react';

import { createStyles, makeStyles } from 'core/atoms/styles';
import { DataItem } from 'core/atoms/types';
import { uniqBy } from 'core/atoms/functions/array/uniq-by';

import { Box, FullBox } from 'core/cells/box';
import { CircularProgress } from 'core/cells/circular-progress';
import { Button } from 'core/cells/button';
import LoadingButton from '@mui/lab/LoadingButton';

import { useTranslations } from 'core/dna/translations';

import { useGetSyncStudents } from 'modules/planner/memory/apollo/sync-students/remote';
import { useGetSelectedClassState } from 'modules/planner/memory/apollo/classes/local';

import { useClasses } from 'modules/planner/metabolism/use-classes';
import { useImportStudents } from 'modules/planner/metabolism/use-import-students';

import { studentsStore, useStudentsState } from '../../../store/students';

import { Tree, bfsSearch } from 'core/cells/tree-view/tree';
import { Backdrop } from '@mui/material';

const defaultWidth = 480;

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      width: defaultWidth,
      padding: theme.spacing(2),
    },
    selected: {
      color: theme.palette.primary.main,
    },
  }),
);

export const SyncStudentsTree = () => {
  const classes = useStyles();
  const { texts } = useTranslations();
  const { studentsState } = useStudentsState();
  const {
    loading,
    error,
    // syncStudentGroupMap,
    syncClasses,
  } = useGetSyncStudents();

  const [selected, setSelected] = useState<string[]>([]);
  const selectedCount = useMemo<number>(() => {
    if (selected.length) {
      return selected.filter((s) => s.includes('_student_')).length ?? 0;
    }
    return 0;
  }, [selected]);

  const { classMap, classesLoading, classesError } = useClasses();

  const { selectedClassState: selectedClass } = useGetSelectedClassState();

  const { importStudents } = useImportStudents();

  const handleImportStudents = useCallback(async () => {
    if (!selectedClass || !syncClasses) {
      return;
    }

    const students = uniqBy(
      selected
        .map((s) => {
          const item = bfsSearch(syncClasses, s);
          return {
            id: item?.data?.studentId ?? '',
            text: item?.text ?? '',
            data: { tuid: item?.data?.tuid ?? '' },
          } as DataItem<{ tuid: string }>;
        })
        .filter((s) => s.id),
      'id',
    );
    await studentsStore.importStudents(importStudents, {
      classId: selectedClass,
      students,
    });
    studentsStore.hideImportList();
  }, [importStudents, selected, selectedClass, syncClasses]);

  if (classesError) return <p>Classes Error!</p>;
  if (error) return <p>Sync Students Error!</p>;

  if (!selectedClass) return <p>Select at least one class!</p>;

  if (classesLoading || loading || !syncClasses) {
    return (
      <Box width={defaultWidth} height="100%" m={0} p={0}>
        <FullBox>
          <CircularProgress />
        </FullBox>
      </Box>
    );
  }

  return (
    <div className={classes.root}>
      <LoadingButton
        sx={{ marginBottom: '16px !important' }}
        color="primary"
        fullWidth
        onClick={handleImportStudents}
        loading={studentsState.importing}
        variant="outlined"
        disabled={!classMap.byId[selectedClass]}
      >
        {texts.planner.common.import}
        {selectedCount ? ` (${selectedCount})` : ''}
      </LoadingButton>
      {studentsState.importing ? (
        <p>Importing...</p>
      ) : (
        <Tree
          data={syncClasses}
          selected={selected}
          setSelected={setSelected}
        />
      )}
    </div>
  );
};
