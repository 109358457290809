import React, { ChangeEvent, useState } from 'react';

import { FormControlLabel, Radio } from '@material-ui/core';

import { useTranslations } from 'core/dna/translations';
import { FormControl } from 'core/cells/form-control';
import { RadioGroup } from 'core/cells/radio-group';
import { Dialog } from 'core/cells/dialog';
import { DialogContent } from 'core/cells/dialog-content';
import { DialogActions } from 'core/cells/dialog-actions';

import { Button } from 'templates/mdp/components/custom-buttons';

import { AbsenceTotal } from 'modules/reports/dna/types';

import { Chart } from './chart';

enum TimeView {
  days = 'days',
  hours = 'hours',
}

interface AbsenceTotalWithTitle extends AbsenceTotal {
  title: string;
}

export interface AbsenceStateChartCreatorProps {
  data: AbsenceTotalWithTitle[];
  open: boolean;
  setOpen: (open: boolean) => void;
}

export const AbsenceStateChartCreator = ({
  data,
  open,
  setOpen,
}: AbsenceStateChartCreatorProps) => {
  const { texts } = useTranslations();

  const timeViews = [
    {
      text: texts.planner.common.days.many,
      value: TimeView.days,
    },
    {
      text: texts.planner.common.hours.many,
      value: TimeView.hours,
    },
  ];

  const [timeView, setTimeView] = useState<TimeView>(TimeView.days);

  const prepareToChart = () => {
    switch (timeView) {
      case TimeView.days:
        return data
          .sort((a, b) => a.days - b.days)
          .map((s) => ({ ...s.daysByState, title: s.title }));
      case TimeView.hours:
        return data
          .sort((a, b) => a.hours - b.hours)
          .map((s) => ({ ...s.hoursByState, title: s.title }));
      default:
        return [];
    }
  };

  const handleTimeViewChange = (event: ChangeEvent<HTMLInputElement>) => {
    setTimeView((event.target as HTMLInputElement).value as TimeView);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog fullWidth maxWidth="xl" open={open} onClose={handleClose}>
      <DialogContent>
        <FormControl component="fieldset">
          {/* <FormLabel component="legend">Select time view type:</FormLabel> */}
          <RadioGroup
            row
            aria-label="entity"
            name="entity"
            value={timeView}
            onChange={handleTimeViewChange}
          >
            {timeViews.map((t, index) => (
              <FormControlLabel
                key={`timeViews-${index}`}
                value={t.value}
                control={<Radio />}
                label={t.text}
              />
            ))}
          </RadioGroup>
        </FormControl>
        <Chart data={prepareToChart()} />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="secondary">
          {texts.planner.common.close}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
