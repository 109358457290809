import { createContext } from 'react';

import { ModuleState } from 'core/dna/types/local';

export const SelectedModuleUpdaterContext = createContext<
  (id: string) => ModuleState | null
>(() => {
  throw new Error('not implemented');
});

SelectedModuleUpdaterContext.displayName = 'SelectedModuleUpdater Context';
