import React from 'react';

import { Grid } from 'core/cells/grid';

import { AssessmentsTable } from './components/assessments-table';

export const AssessmentListMainContent = () => {
  return (
    <Grid container>
      <Grid item xs={12}>
        <AssessmentsTable />
      </Grid>
    </Grid>
  );
};
