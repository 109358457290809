import { makeStyles, Theme, createStyles } from 'core/atoms/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: '100%',
      fontFamily: theme.typography.fontFamily,
      color: theme.palette.grey['900'],
      backgroundColor: theme.palette.background.paper,
      '& .fc-timegrid-cols .fc-day-today': {
        backgroundColor: theme.palette.background.paper,
      },
      '& .fc-timegrid-cols .fc-timegrid-col-frame .fc-timegrid-col-misc': {
        position: 'absolute',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        zIndex: 3,
      },
      '& .fc-highlight': {
        backgroundColor: 'red',
      },
      '& .fc-col-header-cell-cushion': {
        color: theme.palette.form.default.text.normal,
      },
    },
    bgEvent: {
      '&.fc-bg-event': {
        backgroundColor: theme.palette.grey['300'],
        opacity: 1.0,
      },
    },
    extendedEvent: {
      // .fc-time-grid-event.fc-short .fc-time:before {
      content: 'attr(data-full)',
    },
    event: {
      '& .fc-content': {
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'wrap',
        '& .fc-time': {
          marginRight: 8,
        },
        '& .fc-title': {
          fontWeight: 'bold',
          marginRight: 12,
        },
        '& .fc-room': {
          flexGrow: 2,
        },
        '& .fc-teacher': {
          // width: '60%',
        },
        '& .fc-locked': {
          // display: 'inline',
          // position: 'absolute',
          // top: 0,
          // right: 16,
          '& > *': {
            padding: 4,
          },
        },
      },
    },
    extEvent: {
      '& .fc-content .fc-delete': {
        display: 'none',
      },
      '&:hover': {
        '& .fc-content .fc-delete': {
          display: 'block',
          position: 'absolute',
          top: 0,
          right: 0,
        },
      },
    },
    notPinned: {
      '& .fc-content .fc-pinned': {
        display: 'none',
      },
      '&:hover': {
        '& .fc-content .fc-pinned': {
          display: 'block',
          position: 'absolute',
          top: 0,
          right: 0,
          '& > *': {
            padding: 4,
          },
        },
      },
    },
    pinned: {
      '& .fc-content .fc-pinned': {
        display: 'block',
        position: 'absolute',
        top: 0,
        right: 0,
        '& > *': {
          padding: 4,
        },
      },
    },
    pinIcon: {
      fontSize: '0.8rem',
      // color: theme-light.palette.secondary.contrastText,
      // '&:hover': {
      //   color: 'yellow',
      // },
    },
    lockIcon: {
      fontSize: '0.8rem',
      // color: theme-light.palette.secondary.contrastText,
      // '&:hover': {
      //   color: 'yellow',
      // },
    },
    personAvatar: {
      height: '20px',
      width: '20px',
      fontSize: '12px',
    },
    roomEventText: {
      paddingLeft: '2px',
      // color: theme-light.palette.secondary.contrastText,
    },
    bold: {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      fontWeight: theme.typography.fontWeightBold,
    },
    deleteEventTitle: {
      color: theme.palette.secondary.dark,
    },
  }),
);
