import { RoleType } from 'core/dna/types/generated/_globalTypes';
import { OasLocales } from 'core/dna/translations/types';

import { getEmployeeTypeString } from './get-employee-type-string';

export const getEmployeeTypedName = (input: {
  name: string;
  type: RoleType;
  texts: OasLocales;
}) => {
  const { name, type, texts } = input;
  return `${name} (${getEmployeeTypeString({ type, texts })})`;
};
