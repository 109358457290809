import { useCallback, useMemo } from 'react';
import moment from 'moment';
import { Column } from 'react-table';

import { useTranslations } from 'core/dna/translations';
import { Absence } from 'modules/student/dna/types/absence';
import { dateDot, timeShort } from 'core/atoms/date-time/formats';
import { StateValues } from 'core/dna/translations/types';
import { AbsenceState } from 'core/dna/types/generated/_globalTypes';
import { getDiffInHours } from 'core/atoms/date-time';

type OverridedAbsence = Omit<Absence, 'exempt'>;
export interface AbsenceTableItem extends OverridedAbsence {
  date: string | null;
  hours: string | number;
  days: string;
  exempt: string;
  comment: string;
}

export const useAbsenceTableRead = (absences: Absence[] | undefined) => {
  const { texts } = useTranslations();

  const calculateHours = (absence: Absence) => {
    if (absence.wholeDay) {
      return '';
    }
    if (!absence.from || !absence.to) {
      return '';
    }

    return getDiffInHours(absence.from, absence.to);
  };

  const columns = useMemo<Column<AbsenceTableItem>[]>(
    () => [
      {
        Header: texts.planner.common.date,
        accessor: 'date',
        disableFilters: true,
        minWidth: 80,
        width: '11.11%',
      },
      {
        Header: texts.oasCommon.category.one,
        accessor: 'state',
        disableFilters: true,
        minWidth: 80,
        width: '11.11%',
      },
      {
        Header: texts.planner.common.from,
        accessor: 'from',
        disableFilters: true,
        minWidth: 80,
        width: '11.11%',
      },
      {
        Header: texts.planner.common.to,
        accessor: 'to',
        disableFilters: true,
        minWidth: 80,
        width: '11.11%',
      },
      {
        Header: texts.planner.common.hours.many,
        accessor: 'hours',
        disableFilters: true,
        minWidth: 80,
        width: '11.11%',
      },
      {
        Header: texts.planner.common.days.many,
        accessor: 'days',
        disableFilters: true,
        minWidth: 80,
        width: '11.11%',
      },
      {
        Header: texts.studentModule.absence.signedBy,
        accessor: 'signedBy',
        disableFilters: true,
        minWidth: 80,
        width: '11.11%',
      },
      {
        Header: texts.studentModule.absence.exempt,
        accessor: 'exempt',
        disableFilters: true,
        minWidth: 80,
        width: '11.11%',
      },
      {
        Header: texts.studentModule.absence.comment,
        accessor: 'comment',
        disableFilters: true,
        minWidth: 80,
        width: '11.11%',
      },
    ],
    [texts],
  );

  const nullOrBooleanMapper = useCallback(
    (item: null | boolean) => {
      if (item === null) {
        return '';
      }

      return item ? texts.planner.common.yes : texts.planner.common.no;
    },
    [texts.planner.common.no, texts.planner.common.yes],
  );

  const tableData = useMemo<AbsenceTableItem[]>(() => {
    if (!absences) {
      return [];
    }
    return absences.map((absence) => {
      return {
        ...absence,
        state:
          absence.state &&
          (texts.studentModule.absence.stateValues![
            absence.state as keyof StateValues
          ] as AbsenceState),
        exempt: nullOrBooleanMapper(absence.exempt),
        date: absence.from && moment(absence.from).format(dateDot),
        from:
          absence.from && !absence.wholeDay
            ? moment(absence.from).utc().format(timeShort)
            : '',
        to:
          absence.to && !absence.wholeDay
            ? moment(absence.to).utc().format(timeShort)
            : '',
        hours: calculateHours(absence),
        days: absence.wholeDay ? '1' : '0',
        comment: absence.origin?.comment || '',
      };
    });
  }, [absences, nullOrBooleanMapper, texts]);

  return { columns, tableData };
};
