import { makeStyles, createStyles, CSSProperties } from 'core/atoms/styles';

import { grayColor } from '../../material-dashboard-pro-react';
import {
  buttonStyle,
  ButtonStyle,
} from '../../components/custom-buttons/button.styles';

export interface SweetAlertStyle extends ButtonStyle {
  cardTitle: CSSProperties;
  center: CSSProperties;
  right: CSSProperties;
  left: CSSProperties;
}

export const sweetAlertStyle: SweetAlertStyle = {
  cardTitle: {
    marginTop: '0',
    marginBottom: '3px',
    color: grayColor[2],
    fontSize: '18px',
  },
  center: {
    textAlign: 'center',
  },
  right: {
    textAlign: 'right',
  },
  left: {
    textAlign: 'left',
  },
  ...buttonStyle,
};

export const useStyles = makeStyles(() => createStyles({ ...sweetAlertStyle }));
