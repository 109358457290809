import { FC, useRef, useEffect, useState, ReactElement, useMemo } from 'react';
import clsx from 'clsx';

import MuiSelect from '@material-ui/core/Select';

import { TextValueData } from 'core/atoms/types';
import { orderBy } from 'core/atoms/functions/array';
import {
  makeStyles,
  createStyles,
  Theme,
  FormPalette,
  getFormPalette,
} from 'core/atoms/styles';
import { focused } from 'core/atoms/styles/global-css';

import { OutlinedInput, OutlinedInputProps } from 'core/cells/outlined-input';
import { InputLabel, InputLabelProps } from 'core/cells/input-label';
import { FormControl } from 'core/cells/form-control';
import { MenuItem } from 'core/cells/menu-item';
import { ListItemText } from 'core/cells/list-item-text';
import { Checkbox } from 'core/cells/checkbox';

export interface SelectProps {
  name: string;
  label: string;
  className?: string;
  minWidth?: number | string;
  data?: TextValueData[];
  value?: any;
  onChange?: (value: any, props?: any) => void;
  onClose?: (event: Record<string, any>) => void;
  multiple?: boolean;
  classes?: any;
  inputClassName?: any;
  dense?: boolean;
  palette?: FormPalette;
  disabled?: boolean;
  renderItem?: (
    item: TextValueData,
    options: { last: boolean; selected: boolean },
  ) => any;
  notFoundText?: string;
  unsorted?: boolean;
}

const defaultProps: Partial<SelectProps> = {
  minWidth: '100%',
  dense: true,
  palette: 'default',
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      minWidth: (props: SelectProps) => props.minWidth || defaultProps.minWidth,
    },
    formControl: (props: SelectProps) => {
      const palette = getFormPalette(theme, props.palette);
      return {
        '& .MuiInputLabel-root': {
          color: palette.layout.normal,
          [`&${focused}`]: {
            color: palette.layout.focused,
          },
        },
      };
    },
    icon: {
      color: (props: SelectProps) =>
        getFormPalette(theme, props.palette).layout.normal,
    },
  }),
);

export const Select: FC<SelectProps> = (props) => {
  const classes = useStyles(props);
  const {
    name,
    label,
    data,
    className,
    value,
    onChange,
    onClose,
    multiple,
    classes: selectClasses,
    inputClassName,
    dense,
    palette,
    disabled,
    renderItem,
    notFoundText,
    unsorted,
  } = props;

  const inputLabel = useRef<HTMLLabelElement>(null);
  const [labelWidth, setLabelWidth] = useState(0);

  useEffect(() => {
    setLabelWidth(inputLabel.current!.offsetWidth);
  }, []);

  const sortedData = useMemo(() => {
    if (data?.length) {
      return unsorted ? data : orderBy(data, ['text']);
    }
  }, [data, unsorted]);

  const handleChange = (
    event: React.ChangeEvent<{ name?: string; value: unknown }>,
    child: React.ReactNode,
  ) => {
    onChange && onChange(event.target.value, (child as ReactElement)?.props);
  };

  const getText = (value: string) => {
    if (!data) {
      return 'No Data';
    }
    const dataItem = data.find((i) => i.value === value);
    return dataItem ? dataItem.text : notFoundText ?? 'Item Not Found';
  };

  const renderValue = (value: any) => {
    if (Array.isArray(value)) {
      const arr = (value as string[]).map(getText);
      return unsorted ? arr.join(', ') : orderBy(arr).join(', ');
    }
    return getText(value as string);
  };

  const inputLabelProps: InputLabelProps = {
    ref: inputLabel,
    htmlFor: `outlined-${name}`,
    margin: dense ? 'dense' : undefined,
  };

  const outlinedInputProps: OutlinedInputProps = {
    id: `outlined-${name}`,
    name,
    labelWidth,
    margin: dense ? 'dense' : undefined,
    palette,
    className: inputClassName,
  };

  return (
    <FormControl
      className={clsx(classes.root, className)}
      classes={{ root: classes.formControl }}
      variant="outlined"
      component="div"
      disabled={disabled}
    >
      <InputLabel {...inputLabelProps}>{label}</InputLabel>
      <MuiSelect
        classes={{ icon: classes.icon, ...selectClasses }}
        value={value}
        multiple={multiple}
        onChange={handleChange}
        onClose={onClose}
        input={<OutlinedInput {...outlinedInputProps} />}
        renderValue={renderValue}
      >
        {sortedData?.map((item, index, arr) =>
          renderItem ? (
            renderItem(item, {
              last: index === arr.length - 1,
              selected: item.value === value,
            })
          ) : (
            <MenuItem key={item.value} value={item.value}>
              {multiple ? (
                <>
                  <ListItemText primary={item.text} />
                  <Checkbox
                    checked={value ? value.indexOf(item.value) > -1 : false}
                  />
                </>
              ) : (
                item.text
              )}
            </MenuItem>
          ),
        )}
      </MuiSelect>
    </FormControl>
  );
};

Select.defaultProps = defaultProps;
