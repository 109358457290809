import { useCallback } from 'react';

import { OasMemoryError, OasError } from 'core/atoms/errors';

import { useApolloClient } from 'core/dna/types/apollo';
import { GetClass, GetClassVariables } from 'core/dna/types/generated/GetClass';

import { mapApiClassToClass } from 'modules/planner/dna/functions/map-ApiClass-to-Class';

import { GET_CLASS_QUERY } from './queries/get-class-query';

const FILE_NAME = 'use-lazy-get-class';

export const useLazyGetClass = () => {
  const client = useApolloClient();

  const getClass = useCallback(
    async (id: string) => {
      try {
        const result = await client.query<GetClass, GetClassVariables>({
          query: GET_CLASS_QUERY,
          variables: { id },
        });
        if (result.errors) {
          throw new OasMemoryError(JSON.stringify(result.errors), {
            title: FILE_NAME,
          });
        }
        return mapApiClassToClass(result.data.Class?.[0] ?? null);
      } catch (e: any) {
        if (e instanceof OasError) {
          throw e;
        } else {
          throw OasMemoryError.fromError(e, {
            title: FILE_NAME,
          });
        }
      }
    },
    [client],
  );

  return {
    getClass,
  };
};
