import { useCallback } from 'react';

import { OasMemoryError, OasError } from 'core/atoms/errors';

import { gql, useMutation } from 'core/dna/types/apollo';
import { DeleteSubstituteTaskInput } from 'core/dna/types/generated/_globalTypes';
import {
  DeleteSubstituteTask,
  DeleteSubstituteTaskVariables,
} from 'core/dna/types/generated/DeleteSubstituteTask';

import { GET_SUBSTITUTE_JOBS_QUERY } from 'modules/substitute/memory/apollo/substitute-jobs/remote';

export const DELETE_SUBSTITUTE_TASK_MUTATION = gql`
  mutation DeleteSubstituteTask($input: DeleteSubstituteTaskInput!) {
    deleteSubstituteTask(input: $input) {
      description
      status
      statusCode
    }
  }
`;

const TITLE = 'use-delete-substitute-task';

export const useDeleteSubstituteTask = () => {
  const [deleteSubstituteTask] = useMutation<
    DeleteSubstituteTask,
    DeleteSubstituteTaskVariables
  >(DELETE_SUBSTITUTE_TASK_MUTATION);

  const func = useCallback(
    async (input: DeleteSubstituteTaskInput) => {
      try {
        const result = await deleteSubstituteTask({
          variables: {
            input,
          },
          refetchQueries: [
            {
              query: GET_SUBSTITUTE_JOBS_QUERY,
            },
          ],
        });

        if (result.errors) {
          throw new OasMemoryError(JSON.stringify(result.errors), {
            title: TITLE,
          });
        }
        return result.data?.deleteSubstituteTask ?? undefined;
      } catch (e) {
        if (e instanceof OasError) {
          throw e;
        } else {
          throw OasMemoryError.fromError(e, {
            title: TITLE,
          });
        }
      }
    },
    [deleteSubstituteTask],
  );

  return {
    deleteSubstituteTask: func,
  };
};
