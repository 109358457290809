import { useState, ReactNode, Key, Fragment } from 'react';

import { DropFn } from 'core/atoms/types';

import { Box } from 'core/cells/box';
import { DroppableOutlinedArea } from 'core/cells/droppable-outlined-area';

interface ChapterProps {
  title: string;
  dropType: string;
  onDrop?: DropFn;
  children?: ReactNode;
  classes?: { root?: string; label?: string };
  key?: Key;
  selected?: boolean;
  onSelect?: () => void;
  isEmpty?: boolean;
}

export const Chapter = (props: ChapterProps) => {
  const {
    title,
    onDrop,
    children,
    classes: propsClasses,
    key,
    dropType,
    selected,
    onSelect,
    isEmpty,
  } = props;

  const [isOver, setIsOver] = useState(false);

  return (
    <Fragment key={key}>
      <Box className={propsClasses?.root} onClick={onSelect}>
        <DroppableOutlinedArea
          centeredContent={isEmpty}
          label={title}
          type={dropType}
          isOver={isOver}
          selected={selected}
          onOverChanged={setIsOver}
          onDrop={onDrop}
          bigLabel={true}
        >
          {children}
        </DroppableOutlinedArea>
      </Box>
    </Fragment>
  );
};
