import { cloneDeep } from 'core/atoms/functions/clone-deep';

import { RouteConfig } from 'core/dna/types/local';

export const filterRoutes = (
  routes: RouteConfig[],
  options: { fieldName: keyof RouteConfig; fieldValue: any },
): RouteConfig[] => {
  const top = cloneDeep(routes).filter(
    (r) => r[options.fieldName] === options.fieldValue,
  );
  for (let i = 0; i < top.length; i++) {
    if (top[i].views?.length) {
      top[i].views = filterRoutes(top[i].views ?? [], {
        fieldName: options.fieldName,
        fieldValue: options.fieldValue,
      });
    }
  }
  return top;
};
