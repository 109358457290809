import { EventApi } from 'core/cells/full-calendar';

import { CalendarEvent } from 'core/dna/types';

export const mapEventApiToCalendarEvent = (event: EventApi): CalendarEvent => {
  const {
    id,
    title,
    start,
    end,
    backgroundColor,
    borderColor,
    textColor,
    extendedProps,
  } = event;
  return {
    id,
    title,
    start: start!,
    end: end!,
    backgroundColor,
    borderColor,
    textColor,
    rendering: '',
    pinned: extendedProps?.pinned,
    locked: extendedProps?.locked,
    timeSlotId: extendedProps?.timeSlotId,
    splitId: extendedProps?.splitId,
    splitDragObject: extendedProps?.splitDragObject,
  };
};
