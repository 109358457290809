import { useCallback } from 'react';

import { OasMemoryError, OasError } from 'core/atoms/errors';

import { gql, useMutation } from 'core/dna/types/apollo';
import { DeleteRoomInput } from 'core/dna/types/generated/_globalTypes';
import {
  DeleteRoom,
  DeleteRoomVariables,
} from 'core/dna/types/generated/DeleteRoom';

import { useLazyGetTermsState } from 'core/memory/apollo/terms/local';

import { GET_ROOMS_QUERY } from '../graphql';

const DELETE_ROOM_MUTATION = gql`
  mutation DeleteRoom($input: DeleteRoomInput!) {
    deleteRoom(input: $input) {
      description
      status
      statusCode
    }
  }
`;

const TITLE = 'Use Delete Room';

export const useDeleteRoom = () => {
  const { getTermState } = useLazyGetTermsState();

  const [deleteRoom] = useMutation<DeleteRoom, DeleteRoomVariables>(
    DELETE_ROOM_MUTATION,
  );

  const func = useCallback(
    async (input: DeleteRoomInput) => {
      try {
        const termsState = await getTermState(true);

        const result = await deleteRoom({
          variables: { input },
          refetchQueries: [
            {
              query: GET_ROOMS_QUERY,
              variables: { termId: termsState!.selected! },
            },
          ],
        });

        if (result.errors) {
          throw new OasMemoryError(JSON.stringify(result.errors), {
            title: TITLE,
          });
        }
        return result.data?.deleteRoom ?? undefined;
      } catch (e: any) {
        if (e instanceof OasError) {
          throw e;
        } else {
          throw OasMemoryError.fromError(e, {
            title: TITLE,
          });
        }
      }
    },
    [deleteRoom, getTermState],
  );

  return {
    deleteRoom: func,
  };
};
