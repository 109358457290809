import { IdentityMap } from 'core/atoms/types';

export const mapNullableListToIdentityMap = <
  TI extends { id: string; __typename?: string },
  TO extends { id: string }
>(
  list: (TI | null)[] | null,
  itemMapper: (item: TI | null) => TO | undefined,
): IdentityMap<TO> | undefined => {
  let map: IdentityMap<TO> | undefined;
  if (list?.length) {
    map = { byId: {}, allIds: [] };
    list.forEach((item) => {
      const itemOut = itemMapper(item);
      if (!itemOut) {
        throw new TypeError(
          `Couldn't map item { id: ${item?.id}, __typename: ${item?.__typename} }`,
        );
      }
      map!.allIds.push(itemOut.id);
      map!.byId[itemOut.id] = itemOut;
    });
  }
  return map;
};
