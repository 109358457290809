import { FC } from 'react';

import { styled } from 'core/atoms/styles';

export interface TestBoxProps {
  /** Height of the Box */
  height?: number | string;
  /** Width of the Box */
  width?: number | string;
  /** Background color of the Box */
  bgcolor?: string;
}

export const TestBox = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: (props: any) => props.height,
  width: (props: any) => props.width,
  backgroundColor: (props) => props.bgcolor,
  margin: 0,
  padding: 0,
}) as FC<TestBoxProps>;

TestBox.defaultProps = {
  height: '100%',
  width: '100%',
};
