import { createContext } from 'react';

import { Identity } from 'core/atoms/types';

import { OrganizationState } from 'core/dna/types/local';

export interface OrganizationsStateContextValue {
  organizations: OrganizationState[] | null;
  differentTo: (values: Identity[]) => boolean;
}

export const defaultOrganizationsStateContextValue: OrganizationsStateContextValue = {
  organizations: null,
  differentTo: () => {
    throw new Error('not implemented');
  },
};

export const OrganizationsStateContext = createContext(
  defaultOrganizationsStateContextValue,
);

OrganizationsStateContext.displayName = 'OrganizationsState Context';
