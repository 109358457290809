import React, { useCallback } from 'react';

import { FlexBox } from 'core/cells/box';
import { Field, Form, FormController } from 'core/cells/forms';
import { Skeleton } from 'core/cells/skeleton';
import { FormSelect } from 'core/cells/form-select';
import { useTranslations } from 'core/dna/translations';
import { WriteModeEnum } from 'core/dna/types/write-mode';
import { WriteTableModal } from 'core/organisms/write-modal';

import { Button } from 'templates/mdp/components/custom-buttons';

import { useTeacherTableWrite } from 'modules/grades/metabolism';
import { useGetClasses } from 'modules/planner/memory/apollo/classes/remote';
import { useGetSubjects } from 'modules/planner/memory/apollo/subjects/remote';
import { useGetScheduleDescriptions } from 'modules/planner/memory/apollo/schedules/remote';

interface Values {
  evaluationBatchId: string;
  scheduleId: string;
  classIds: string[];
  subjectIds: string[];
}

interface Errors {
  scheduleId: string;
  classIds: string;
  subjectIds: string;
}

interface StudentEvaluationsCreatorData {
  batchId: string;
}

interface StudentEvaluationsCreatorProps {
  data: StudentEvaluationsCreatorData;
  open: boolean;
  setOpen: (open: boolean) => void;
}

export const StudentEvaluationsCreator = (
  props: StudentEvaluationsCreatorProps,
) => {
  const { open, setOpen, data } = props;
  const { onAddEvaluation } = useTeacherTableWrite();
  const { texts } = useTranslations();
  const { classes, classesLoading } = useGetClasses();
  const { subjects, subjectsLoading } = useGetSubjects();
  const {
    list: schedules,
    loading: schedulesLoading,
  } = useGetScheduleDescriptions();

  const onClose = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  const handleSubmit = async (values: Values) => {
    await onAddEvaluation({
      evaluationBatchId: data.batchId,
      scheduleId: values.scheduleId,
      classIds: values.classIds,
      subjectIds: values.subjectIds,
    });
    setOpen(false);
  };

  return (
    <WriteTableModal
      width={480}
      isShow={open ?? false}
      onClose={onClose}
      writeMode={WriteModeEnum.create}
      title={texts.grades.evaluationGroups.generateGroups}
    >
      {subjectsLoading || classesLoading || schedulesLoading ? (
        <Skeleton variant="rect" width="100%" height="30vh" />
      ) : (
        <FormController
          initialValues={{
            evaluationBatchId: data.batchId,
            scheduleId: '',
            classIds: [],
            subjectIds: [],
          }}
          validate={(values) => {
            const errors: Partial<Errors> = {};
            if (!values.scheduleId) {
              errors.scheduleId = texts.oasCommon.validation.required;
            }
            if (!values.classIds.length) {
              errors.classIds = texts.oasCommon.validation.required;
            }
            if (!values.subjectIds.length) {
              errors.subjectIds = texts.oasCommon.validation.required;
            }
            return errors;
          }}
          onSubmit={async (values) => {
            await handleSubmit(values);
          }}
        >
          {({ submitForm, isSubmitting, errors }) => (
            <Form>
              <Field
                select
                component={FormSelect}
                data={schedules?.map((option) => ({
                  text: option.name,
                  value: option.id,
                }))}
                error={errors.scheduleId}
                name="scheduleId"
                label={texts.planner.common.schedules.many}
                disabled={isSubmitting}
                fullWidth
              />
              <Field
                multiple
                component={FormSelect}
                data={subjects?.map((option) => ({
                  text: option.name,
                  value: option.id,
                }))}
                error={errors.subjectIds}
                name="subjectIds"
                label={texts.planner.common.subjects}
                disabled={isSubmitting}
                fullWidth
              />
              <Field
                multiple
                component={FormSelect}
                data={classes?.map((option) => ({
                  text: option.name,
                  value: option.id,
                }))}
                error={errors.classIds}
                name="classIds"
                label={texts.planner.common.classes}
                disabled={isSubmitting}
                fullWidth
              />
              <FlexBox justifyContent="flex-end">
                <Button
                  color="secondary"
                  disabled={isSubmitting}
                  onClick={submitForm}
                >
                  {texts.planner.common.add}
                </Button>
                <Button onClick={onClose} color="secondary" simple>
                  {texts.planner.common.close}
                </Button>
              </FlexBox>
            </Form>
          )}
        </FormController>
      )}
    </WriteTableModal>
  );
};
