import { RoleType } from 'core/dna/types/generated/_globalTypes';
import { useTranslations } from 'core/dna/translations';
import { getEmployeeTypedName } from '../dna/functions';

export const useEmployeeTypedName = () => {
  const { texts } = useTranslations();

  return {
    getName: (input: { name: string; type: RoleType }) =>
      getEmployeeTypedName({ ...input, texts }),
  };
};
