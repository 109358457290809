import { useCallback } from 'react';

import { OasMemoryError } from 'core/atoms/errors';

import { useMutation, useApolloClient } from 'core/dna/types/apollo';
import { EditLessonGroupInput } from 'core/dna/types/generated/_globalTypes';
import { GetSchedulesSelectedState } from 'core/dna/types/generated/GetSchedulesSelectedState';

import { GET_SCHEDULES_SELECTED_STATE_QUERY } from 'modules/planner/memory/apollo/schedules/local/graphql';
import { GET_SCHEDULE_QUERY } from 'modules/planner/memory/apollo/schedules/remote';

import { EDIT_LESSON_GROUP_MUTATION } from './edit-lesson-group-mutation';

const TITLE = 'useEditLessonGroup';

export const useEditLessonGroup = () => {
  const [editLessonGroup, { loading, error }] = useMutation(
    EDIT_LESSON_GROUP_MUTATION,
  );
  const client = useApolloClient();

  const func = useCallback(
    (input: EditLessonGroupInput) => {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise<any>(async (resolve, reject) => {
        try {
          const getScheduleStateSelectedResult = await client.query<GetSchedulesSelectedState>(
            {
              query: GET_SCHEDULES_SELECTED_STATE_QUERY,
            },
          );

          const scheduleIds =
            getScheduleStateSelectedResult.data?.schedulesState?.selected;

          if (scheduleIds?.length !== 1) {
            throw new OasMemoryError(
              'Schedule set incorrectly! Only one is expected.',
              { title: TITLE },
            );
          }

          const executionResult = await editLessonGroup({
            variables: {
              input: {
                ...input,
              },
            },
            refetchQueries: [
              {
                query: GET_SCHEDULE_QUERY,
                variables: { scheduleId: scheduleIds[0] },
              },
            ],
          });

          if (executionResult.data && !executionResult.errors) {
            resolve(executionResult);
          } else {
            reject(new Error('editLessonGroup error!'));
          }
        } catch (e: any) {
          reject(e);
        }
      });
    },
    [client, editLessonGroup],
  );

  return {
    editLessonGroup: func,
    loading,
    error,
  };
};
