import { useCallback } from 'react';

import { OasMemoryError, OasError } from 'core/atoms/errors';

import { gql, useMutation } from 'core/dna/types/apollo';
import {
  CreateUnion,
  CreateUnionVariables,
} from 'core/dna/types/generated/CreateUnion';

import { useLazyGetTermsState } from 'core/memory/apollo/terms/local';

import { GET_CLASSES_QUERY } from './_gql';

export interface CreateUnionInput {
  classId: string;
  chapters?: string[];
}

const CREATE_UNION_MUTATION = gql`
  mutation CreateUnion($input: CreateUnionInput) {
    createUnion(input: $input) {
      id
    }
  }
`;

const TITLE = 'UseCreateUnion';

export const useCreateUnion = () => {
  const { getTermState } = useLazyGetTermsState();

  const [createUnion, { loading, error }] = useMutation<
    CreateUnion,
    CreateUnionVariables
  >(CREATE_UNION_MUTATION);

  const func = useCallback(
    async (input: CreateUnionInput) => {
      try {
        const termsState = await getTermState(true);

        const result = await createUnion({
          variables: {
            input: {
              ...input,
            },
          },
          refetchQueries: [
            {
              query: GET_CLASSES_QUERY,
              variables: { termId: termsState!.selected },
            },
          ],
        });

        if (result.errors) {
          throw new OasMemoryError(JSON.stringify(result.errors), {
            title: TITLE,
          });
        }
        return result.data?.createUnion ?? undefined;
      } catch (e: any) {
        if (e instanceof OasError) {
          throw e;
        } else {
          throw OasMemoryError.fromError(e, {
            title: TITLE,
          });
        }
      }
    },
    [createUnion, getTermState],
  );

  return {
    createUnion: func,
    loading,
    error,
  };
};
