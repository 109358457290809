import React, { ChangeEvent, useEffect, useState } from 'react';
import moment from 'moment';
import { FormControlLabel, Radio } from '@material-ui/core';
import { NotificationsActive } from '@material-ui/icons';

import {
  OrganizationFeatureCode,
  PersonT2_DateInputFilter,
} from 'core/dna/types/generated/_globalTypes';
import { RadioGroup } from 'core/cells/radio-group';
import { FormControl } from 'core/cells/form-control';
import { FormLabel } from 'core/cells/form-label';
import { Grid } from 'core/cells/grid';
import {
  mapTranslationDueToOrgCategoryType,
  useTranslations,
} from 'core/dna/translations';
import { Divider } from 'core/cells/divider';
import { TermTense } from 'core/dna/types/term';

import { useOrganization } from 'core/metabolism/organizations';
import { isFeatureAvailable } from 'core/dna/functions';

import { useTerm } from 'modules/planner/metabolism/terms';

import { Card, CardBody } from 'templates/mdp/components/card';
import { Snackbar } from 'templates/mdp/components/snackbar';

import { AbsenceEntity } from 'modules/reports/dna/types';

import { useGetAbsences } from 'modules/student/memory/apollo/students/remote/get-absences';

import {
  PeriodSelection,
  QuickPeriodSelector,
} from './components/period-selection';
import { ReportManager } from './components/report-manager';

export const AbsenceMainContent = () => {
  const { texts } = useTranslations();
  const { term, tense } = useTerm();
  const { organization } = useOrganization();

  const [entity, setEntity] = useState<AbsenceEntity>(AbsenceEntity.student);
  const [period, setPeriod] = useState<PersonT2_DateInputFilter>({
    from: '',
    to: '',
  });

  useEffect(() => {
    setPeriod({
      from: moment(term?.startDate).startOf('day').utc().toISOString(),
      to:
        tense === TermTense.current
          ? moment().utc().toISOString()
          : moment(term?.endDate).utc().toISOString(),
    });
  }, [tense, term?.endDate, term?.startDate]);

  const { data, loading } = useGetAbsences({
    input: period,
    term,
  });

  const absenceEntities = [
    {
      text: mapTranslationDueToOrgCategoryType(
        texts,
        organization?.category,
        'students',
      ),
      value: AbsenceEntity.student,
    },
    {
      text: texts.oasCommon.group.many,
      value: AbsenceEntity.class,
    },
  ];

  const handleEntityChange = (event: ChangeEvent<HTMLInputElement>) => {
    setEntity((event.target as HTMLInputElement).value as AbsenceEntity);
  };

  const isT1Available = isFeatureAvailable(
    organization,
    OrganizationFeatureCode.canSendSmsNotifications,
  );
  const isT2Available = isFeatureAvailable(
    organization,
    OrganizationFeatureCode.messageBook,
  );
  const isAbsencesAvailable = isFeatureAvailable(
    organization,
    OrganizationFeatureCode.absence,
  );

  const isModuleAvailable =
    isAbsencesAvailable &&
    (isT1Available || isT2Available || (isT1Available && isT2Available));

  if (!isModuleAvailable) {
    return (
      <Snackbar
        place="bc"
        color="warning"
        icon={NotificationsActive}
        message={texts.reports.absenceReport.moduleNotAvailable}
        open={!!organization && !loading}
      />
    );
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} lg={6} xl={4}>
        {!!term && tense !== TermTense.future && (
          <Card>
            <CardBody>
              <QuickPeriodSelector
                onChange={(input) => {
                  setPeriod(input);
                }}
              />
              <Divider light style={{ margin: '15px 0' }} />
              <PeriodSelection
                period={period}
                onChange={(input) => {
                  setPeriod(input);
                }}
              />
            </CardBody>
          </Card>
        )}
        <FormControl component="fieldset">
          <FormLabel component="legend">
            {texts.reports.reportsCommon.entityTypeSelection}
          </FormLabel>
          <RadioGroup
            row
            aria-label="entity"
            name="entity"
            value={entity}
            onChange={handleEntityChange}
          >
            {absenceEntities.map((e, index) => (
              <FormControlLabel
                key={`absenceEntities-${index}`}
                value={e.value}
                control={<Radio />}
                label={e.text}
              />
            ))}
          </RadioGroup>
        </FormControl>
      </Grid>
      <Grid item xs={12} lg={12}>
        <ReportManager data={data} loading={loading} entity={entity} />
      </Grid>
      {tense === TermTense.future && (
        <Snackbar
          place="bc"
          color="warning"
          icon={NotificationsActive}
          message={texts.reports.absenceReport.futureTermChosen}
          open={!!organization && !loading}
        />
      )}
    </Grid>
  );
};
