import { GetTermsState_termsState as LocalApiTermState } from 'core/dna/types/generated/GetTermsState';
import { TermsState } from 'core/dna/types/local';

export const mapLocalApiTermsStateToTermsState = (
  localTermState: LocalApiTermState | null,
): TermsState | undefined => {
  if (!localTermState) {
    return;
  }
  const { selected } = localTermState;

  return {
    selected,
  };
};
