import { useCallback } from 'react';

import { isSubstring } from 'core/atoms/functions/string';

import { useSnackbar } from 'core/cells/notistack';
import { FullBox, Box } from 'core/cells/box';
import { CircularProgress } from 'core/cells/circular-progress';

import { SubjectInfo } from 'modules/planner/dna/types/subject';

import { ImportList } from 'modules/planner/organisms/import-list';

import { subjectsStore } from '../../store/subjects';

import { useSubjectsImportList } from './use-subjects-import-list';

export interface SubjectsImportListProps {
  width?: number;
}

const FILE_NAME = 'subjects-import-list';

const defaultWidth = 260;

export const SubjectsImportList = (props: SubjectsImportListProps) => {
  const { width } = props;
  const { enqueueSnackbar } = useSnackbar();

  const {
    loading,
    error,
    data,
    checkedForImport,
    handleCheck,
    importSubjects,
  } = useSubjectsImportList(FILE_NAME);

  const handleImport = useCallback(async () => {
    try {
      const results = await subjectsStore.importSubjects(importSubjects);

      results.forEach((result) => {
        switch (result.type) {
          case 'success':
            enqueueSnackbar(
              `"${result.name || 'Subject'}" was exported successfully!`,
              {
                variant: 'success',
              },
            );
            break;
          case 'duplicate':
            enqueueSnackbar(`NOT CREATED: "${result.name}" already exist`, {
              variant: 'warning',
            });
            break;
          case 'error':
            enqueueSnackbar(`Error importing "${result.name}"`, {
              variant: 'error',
            });
            break;
          default:
            break;
        }
      });
    } catch (error: any) {
      enqueueSnackbar(`Error importing subjects`, {
        variant: 'error',
      });
    }
  }, [enqueueSnackbar, importSubjects]);

  const filterPredicate = useCallback(
    (item: SubjectInfo, filterText: string) =>
      isSubstring(filterText, [item.name, item.alias ?? '']),
    [],
  );

  const getText = useCallback((item: SubjectInfo) => item.name, []);

  if (error) return <p>Subject Infos Error!</p>;
  if (loading)
    return (
      <Box width={width ?? defaultWidth} height="100%" m={0} p={0}>
        <FullBox>
          <CircularProgress />
        </FullBox>
      </Box>
    );

  return (
    <ImportList
      checked={checkedForImport.allIds}
      onCheck={handleCheck}
      onClear={subjectsStore.clearIdsForImport}
      data={data}
      filterPredicate={filterPredicate}
      getText={getText}
      onImport={handleImport}
      width={width ?? defaultWidth}
    />
  );
};
