import { useCallback } from 'react';

import { logger, LogType } from 'core/atoms/housekeeping';
import { OasPageError, OasError } from 'core/atoms/errors';
import { parseTemplate } from 'core/atoms/functions/string';

import { useSnackbar } from 'core/cells/notistack';

import { useTranslations } from 'core/dna/translations';

import { RoomTableItem } from 'modules/planner/dna/types/room';

import {
  useCreateRoom,
  useEditRoom,
  useDeleteRoom,
} from 'modules/planner/memory/apollo/rooms/remote';

const TITLE = 'use-rooms-table-write';

export const useRoomsTableWrite = () => {
  const { texts } = useTranslations();
  const { createRoom } = useCreateRoom();
  const { editRoom } = useEditRoom();
  const { deleteRoom } = useDeleteRoom();
  const { enqueueSnackbar } = useSnackbar();

  const onRowAdd = useCallback(
    async (item: { name: string; alias?: string; note?: string }) => {
      try {
        const result = await createRoom({
          name: item.name,
          alias: item.alias,
          note: item.note,
        });

        if (result?.statusCode !== 'OK') {
          throw new OasPageError(
            result?.description ??
              `Room create error { name: ${item.name}, alias: ${item.alias}, note: ${item.note} }`,
            { title: TITLE },
          );
        }

        enqueueSnackbar(
          parseTemplate(texts.oasCommon.entityCreated, {
            entityType: texts.oasCommon.rooms.one,
          }),
          {
            variant: 'success',
          },
        );
        return true;
      } catch (e: any) {
        const error =
          e instanceof OasError
            ? e
            : OasPageError.fromError(e, { title: TITLE });
        if (error.originalMessage) {
          logger.info(error.originalMessage, LogType.Error);
        }
        throw error;
      }
    },
    [createRoom, enqueueSnackbar, texts],
  );

  const onRowUpdate = useCallback(
    async (item: {
      id: string;
      name?: string;
      alias?: string;
      note?: string;
    }) => {
      try {
        const result = await editRoom({
          roomId: item.id,
          name: item.name,
          alias: item.alias,
          note: item.note,
        });

        if (result?.statusCode !== 'OK') {
          throw new OasPageError(
            result?.description ?? `Room update error { id: ${item.id} }`,
            { title: TITLE },
          );
        }

        return true;
      } catch (e: any) {
        const error =
          e instanceof OasError
            ? e
            : OasPageError.fromError(e, { title: TITLE });
        if (error.originalMessage) {
          logger.info(error.originalMessage, LogType.Error);
        }
        throw error;
      }
    },
    [editRoom],
  );

  const onRowDelete = useCallback(
    async (oldData: RoomTableItem) => {
      try {
        const result = await deleteRoom({ roomId: oldData.id });

        if (result?.statusCode !== 'OK') {
          throw new OasPageError(
            result?.description ??
              parseTemplate(texts.oasCommon.deleteEntitiesOfTypeError.one, {
                entityType: texts.oasCommon.rooms.one,
                entity: oldData.name,
              }),
            { title: TITLE },
          );
        }
        enqueueSnackbar(
          parseTemplate(texts.oasCommon.entitiesOfTypeWereDeleted.one, {
            entityType: texts.oasCommon.rooms.one,
            entity: oldData.name,
          }),
          {
            variant: 'success',
          },
        );
        return 0;
      } catch (e: any) {
        const error =
          e instanceof OasError
            ? e
            : OasPageError.fromError(e, { title: TITLE });
        if (error.originalMessage) {
          logger.info(error.originalMessage, LogType.Error);
        }
        enqueueSnackbar(
          parseTemplate(texts.oasCommon.deleteEntitiesOfTypeError.one, {
            entityType: texts.oasCommon.rooms.one,
            entity: oldData.name,
          }),
          {
            variant: 'error',
          },
        );
        return 1;
      }
    },
    [deleteRoom, enqueueSnackbar, texts],
  );

  return {
    onRowAdd,
    onRowUpdate,
    onRowDelete,
  };
};
