import { OasMappingError } from 'core/atoms/errors';

import {
  GetSubstituteJobs_SubstituteJob_tasks as ApiSubstituteTask,
  GetSubstituteJobs_SubstituteJob_tasks_responses as ApiSubstituteTaskResponse,
} from 'core/dna/types/generated/GetSubstituteJobs';
import { mapNullableListToList } from 'core/dna/functions/map-nullable-list-to-list';

import {
  SubstituteTask,
  SubstituteTaskResponse,
} from 'modules/substitute/dna/types/substitute-task';

export const mapApiSubstituteTaskListToSubstituteTaskList = (
  apiSubstituteTaskList: (ApiSubstituteTask | null)[] | null,
): SubstituteTask[] | undefined => {
  return mapNullableListToList<ApiSubstituteTask, SubstituteTask>(
    apiSubstituteTaskList,
    mapApiSubstituteTaskToSubstituteTask,
  );
};

export const mapApiSubstituteTaskToSubstituteTask = (
  apiSubstituteTask: ApiSubstituteTask | null,
): SubstituteTask => {
  if (apiSubstituteTask === null) {
    throw new OasMappingError('apiSubstituteTask" should not be null!', {
      title: 'Map ApiSubstituteTask to SubstituteTask',
    });
  }

  const {
    id,
    subject,
    date,
    timeFrom,
    timeTo,
    comment,
    responses,
  } = apiSubstituteTask;

  return {
    id,
    subject,
    date: date.formatted ?? '',
    timeFrom: timeFrom.formatted ?? '',
    timeTo: timeTo.formatted ?? '',
    comment: comment ?? '',
    responses: mapNullableListToList<
      ApiSubstituteTaskResponse,
      SubstituteTaskResponse
    >(responses, mapApiSubstituteTaskResponseToSubstituteTaskResponse),
  };
};

export const mapApiSubstituteTaskResponseToSubstituteTaskResponse = (
  apiSubstituteTaskResponse: ApiSubstituteTaskResponse | null,
): SubstituteTaskResponse => {
  if (apiSubstituteTaskResponse === null) {
    throw new OasMappingError(
      'apiSubstituteTaskResponse" should not be null!',
      {
        title: 'Map ApiSubstituteTaskResponse to SubstituteTaskResponse',
      },
    );
  }

  const { SubstituteTeacher, response } = apiSubstituteTaskResponse;

  return {
    response: response === 'yes' ? 'yes' : 'no',
    teacher: {
      id: SubstituteTeacher?.id ?? '',
      firstName: SubstituteTeacher?.firstName ?? '',
      lastName: SubstituteTeacher?.lastName ?? '',
    },
  };
};
