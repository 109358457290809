import { useCallback } from 'react';

import { OasMemoryError, OasError } from 'core/atoms/errors';

import { gql, useMutation } from 'core/dna/types/apollo';

import {
  CreateSubstituteTeacher,
  CreateSubstituteTeacherVariables,
} from 'core/dna/types/generated/CreateSubstituteTeacher';

import { GET_SUBSTITUTE_TEACHERS_QUERY } from '../get-substitute-teachers';

export const CREATE_SUBSTITUTE_TEACHER_MUTATION = gql`
  mutation CreateSubstituteTeacher($input: CreateSubstituteTeacherInput!) {
    createSubstituteTeacher(input: $input) {
      id
      firstName
      lastName
      msisdn
      mail
      tags
      state
    }
  }
`;

const TITLE = 'use-create-substitute-teacher';

export const useCreateSubstituteTeacher = () => {
  const [createSubstituteTeacher] = useMutation<
    CreateSubstituteTeacher,
    CreateSubstituteTeacherVariables
  >(CREATE_SUBSTITUTE_TEACHER_MUTATION);

  const func = useCallback(
    async (
      firstName: string,
      lastName: string,
      phone?: string,
      email?: string,
      tags?: string,
    ) => {
      try {
        const result = await createSubstituteTeacher({
          variables: {
            input: {
              firstName,
              lastName,
              mail: email,
              msisdn: phone,
              tags: tags?.split(',') ?? [],
            },
          },
          refetchQueries: [
            {
              query: GET_SUBSTITUTE_TEACHERS_QUERY,
            },
          ],
        });

        if (result.errors) {
          throw new OasMemoryError(JSON.stringify(result.errors), {
            title: TITLE,
          });
        }
        return result.data?.createSubstituteTeacher ?? undefined;
      } catch (e) {
        if (e instanceof OasError) {
          throw e;
        } else {
          throw OasMemoryError.fromError(e, {
            title: TITLE,
          });
        }
      }
    },
    [createSubstituteTeacher],
  );

  return {
    createSubstituteTeacher: func,
  };
};
