import { useMemo, useCallback, useRef } from 'react';
import moment from 'moment';

import { useQuery } from 'core/dna/types/apollo';
import {
  GetOrganization,
  GetOrganizationVariables,
} from 'core/dna/types/generated/GetOrganization';
import { Organization } from 'core/dna/types/organization';
import { useSelectedOrganizationState } from 'core/dna/organizations';

import { useResetTermsState } from 'core/memory/apollo/terms/local';

import { mapApiOrganizationListToOrganizationList } from 'modules/planner/dna/functions';

import { GET_ORGANIZATION_QUERY } from './get-organization-query';
import { mapNullableListToList } from '../../../../../dna/functions';
import { getOrganizationStorage } from '../../../../browser';

export const useGetOrganization = (runCondition = true) => {
  const selected = useSelectedOrganizationState();
  const { resetTermsState } = useResetTermsState();
  const { getOrganization: getBrowserOrganization } = getOrganizationStorage();

  /**
   *
   * TODO (Ihor): after choose federation this request is empty
   *
   */
  const handleCompleted = useCallback(
    (data: GetOrganization) => {
      const terms: { id: string; start?: Date; end?: Date }[] = [];
      const orgs = mapNullableListToList(
        data.Organization?.filter((o) => o?.terms?.length) ?? null,
        (i) => i ?? undefined,
      );

      if (!orgs) {
        return;
      }

      orgs.forEach((o) => {
        if (o) {
          o.terms?.forEach((t) => {
            if (t) {
              terms.push({
                id: t.id,
                start: t.startDate
                  ? moment(t.startDate.formatted).toDate()
                  : undefined,
                end: t.endDate
                  ? moment(t.endDate.formatted).toDate()
                  : undefined,
              });
            }
          });
        }
      });
      resetTermsState(terms.length ? terms : undefined);
    },
    [resetTermsState],
  );

  const getOrgQuery = useQuery<GetOrganization, GetOrganizationVariables>(
    GET_ORGANIZATION_QUERY,
    {
      skip: !runCondition || !selected?.id,
      variables: { organizationId: selected?.id ?? '' },
      onCompleted: handleCompleted,
    },
  );

  const data = useMemo<Organization[] | undefined>(() => {
    if (
      !getOrgQuery.loading &&
      !getOrgQuery.error &&
      getOrgQuery.data?.Organization
    ) {
      return mapApiOrganizationListToOrganizationList(
        getOrgQuery.data.Organization,
      );
    }
  }, [getOrgQuery.data, getOrgQuery.error, getOrgQuery.loading]);

  const refetch = useCallback(
    (organizationId: string) => {
      getOrgQuery?.refetch({ organizationId });
    },
    [getOrgQuery],
  );

  return {
    refetch,
    loading: getOrgQuery.loading,
    error: getOrgQuery.error,
    data: data?.[0],
  };
};
