import { createContext } from 'react';

import { OrganizationLevel } from 'core/dna/types/local';

export type ModuleGoHomeContextValue = (
  id?: string,
  level?: OrganizationLevel,
) => void;

export const ModuleGoHomeContext = createContext<ModuleGoHomeContextValue>(
  () => {
    throw new Error('not implemented');
  },
);

ModuleGoHomeContext.displayName = 'Module Go Home Context';
