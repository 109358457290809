import { makeStyles, createStyles } from 'core/atoms/styles';

import { EventContentArg } from 'core/cells/full-calendar';

import { DefaultEvent } from './default-event';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      '& .fc-event-title-container': {
        flexGrow: 'initial',
      },
    },
    description: {},
  }),
);

export const DescriptionEvent = (props: EventContentArg) => {
  const classes = useStyles();
  const { event, ...rest } = props;

  return (
    <DefaultEvent className={classes.root} event={event} {...rest}>
      <div className={classes.description}>
        {event.extendedProps.description}
      </div>
    </DefaultEvent>
  );
};
