import React, { Fragment, useState } from 'react';
import { List, Typography } from '@material-ui/core';
import { Delete } from '@material-ui/icons';

import { ListItem } from 'core/cells/list-item';
import { ListItemText } from 'core/cells/list-item-text';
import { ListItemSecondaryAction } from 'core/cells/list-item-secondary-action';
import { IconButton } from 'core/cells/icon-button';
import { Divider } from 'core/cells/divider';
import { TextField } from 'core/cells/text-field';
import { useTranslations } from 'core/dna/translations';
import { makeStyles } from 'core/atoms/styles';

import { Button } from 'templates/mdp/components/custom-buttons';

import { studentProfileStyles } from './student-profile.styles';

const useStyles = makeStyles(studentProfileStyles);

interface AllergiesProps {
  allergies?: string[];
  onAdd: (allergy: string) => void;
  onRemove: (index: number) => void;
  loading: boolean;
}

export const Allergies = ({
  allergies,
  onAdd,
  onRemove,
  loading,
}: AllergiesProps) => {
  const classes = useStyles();
  const { texts } = useTranslations();

  const [allergy, setAllergy] = useState('');

  const handleAdd = () => {
    if (!allergy) return;
    onAdd(allergy);
    setAllergy('');
  };

  return (
    <div className={classes.box}>
      <div className={classes.groupTitle}>
        <Typography variant="h5">{texts.studentModule.allergies}:</Typography>
      </div>
      <div className={classes.groupInput}>
        {allergies && !!allergies.length && (
          <List className={classes.list}>
            {allergies.map((a, index) => (
              <Fragment key={`allergy-${index}`}>
                <ListItem>
                  <ListItemText secondary={a} />
                  <ListItemSecondaryAction>
                    <IconButton
                      edge="end"
                      aria-label="delete"
                      onClick={() => onRemove(index)}
                    >
                      <Delete />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
                <Divider component="li" />
              </Fragment>
            ))}
          </List>
        )}
        <TextField
          className={classes.input}
          label={null}
          value={allergy}
          placeholder={texts.studentModule.placeholders.enterAllergy}
          onChange={(e) => {
            setAllergy(e.target.value);
          }}
        />
        <Button
          className={classes.addBtn}
          size="sm"
          color="success"
          disabled={!allergy || loading}
          onClick={() => handleAdd()}
        >
          {texts.planner.common.add}
        </Button>
      </div>
    </div>
  );
};
