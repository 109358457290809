import { useMemo } from 'react';

import { useTranslations } from 'core/dna/translations';

import { mapScheduleToScheduleTableItem } from 'modules/planner/dna/functions/map-schedule-to-schedule-table-item';

import { useSchedules } from 'modules/planner/metabolism/schedules/use-schedules';

import PlannerPaths from 'modules/planner/routing/paths';

export const useSchedulesTableRead = () => {
  const { lang } = useTranslations();

  const { scheduleDescriptionMap, loading } = useSchedules();

  const columns = useMemo(
    () => [
      {
        Header: lang.common.names.one,
        accessor: 'name',
      },
      {
        Header: lang.preparationSpace.schedulesModule.lessonGroups.many,
        accessor: PlannerPaths.Plan.Schedules.LessonGroups.part,
        disableFilters: true,
      },
      {
        Header: lang.preparationSpace.schedulesModule.timePlanning,
        accessor: PlannerPaths.Plan.Schedules.TimePlan.part,
        disableFilters: true,
      },
      {
        Header: lang.common.share,
        accessor: PlannerPaths.Share.part,
        disableFilters: true,
      },
      {
        Header: lang.common.appShareCode,
        accessor: 'appShareCode',
        disableFilters: true,
      },
      {
        Header: lang.common.actions.many,
        accessor: 'actions',
      },
    ],
    [lang],
  );

  return {
    title: lang.common.schedules.many,
    columns,
    loading,
    data: scheduleDescriptionMap?.allIds?.map((sId) =>
      mapScheduleToScheduleTableItem(scheduleDescriptionMap?.byId[sId]),
    ),
  };
};
