import React, { useState } from 'react';

import { makeStyles, createStyles, Theme } from 'core/atoms/styles';

import { Box } from 'core/cells/box';
import { Grid } from 'core/cells/grid';
import { LinearProgress } from 'core/cells/linear-progress';

import { useTranslations } from 'core/dna/translations';

import { Button } from 'templates/mdp/components/custom-buttons';
import { GridContainer, GridItem } from 'templates/mdp/components/grid';
import {
  Card,
  CardBody,
  CardHeader,
  CardIcon,
} from 'templates/mdp/components/card';
import { MeetingRoom } from '@material-ui/icons';

import { WeekCalendar } from 'core/organisms/week-calendar';
import { RouteLeavingGuard } from 'modules/planner/organisms/route-leaving-guard';
import {
  DayResourceSelector,
  useDayResourceSelector,
} from 'modules/planner/organisms/day-resource-selector';
import {
  Duration,
  TimeSlotDurationSelector,
} from 'modules/planner/organisms/time-slot-duration-selector';

import { useRoomsAvailability } from './use-rooms-availability';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(2),
    },
    resourcesRow: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: theme.spacing(2),
    },
    runButton: {
      marginRight: theme.spacing(0.5),
    },
    clearButton: {
      marginLeft: theme.spacing(0.5),
    },
    naEvent: {
      background: `
        repeating-linear-gradient(
          45deg,
          ${theme.palette.primary.main},
          ${theme.palette.primary.main} 10px,
          ${theme.palette.primary.dark} 10px,
          ${theme.palette.primary.dark} 20px
        )
      `,
      borderColor: theme.palette.primary.dark,
      color: theme.palette.primary.contrastText,
      '& .fc-content .fc-delete': {
        display: 'none',
      },
      '&:hover': {
        '& .fc-content .fc-delete': {
          display: 'block',
          position: 'absolute',
          top: 0,
          right: 0,
        },
      },
    },
  }),
);

export const RoomsAvailability = () => {
  const classes = useStyles();

  const { lang } = useTranslations();

  const {
    startTime,
    endTime,
    bgEvents,
    resources,
    events,
    add,
    edit,
    save,
    saving,
    dirty,
    loading,
    error,
    eventContent,
  } = useRoomsAvailability();

  const {
    dayResourceSelectorValue,
    handleViewChange,
  } = useDayResourceSelector();

  const [timeSlotDurationValue, setTimeSlotDurationValue] = useState(
    Duration.M30,
  );

  if (loading) return <p>Loading Rooms...</p>;
  if (error) return <p>Rooms Error!</p>;

  return (
    <GridContainer>
      <GridItem xs={12}>
        <Card>
          <CardHeader color="primary" icon>
            <CardIcon color="primary">
              <MeetingRoom />
            </CardIcon>
          </CardHeader>
          <CardBody>
            <RouteLeavingGuard
              when={dirty}
              shouldBlockNavigation={() => true}
            />
            <Grid container spacing={2}>
              <Grid item xs={12} className={classes.resourcesRow}>
                <DayResourceSelector
                  onChange={handleViewChange}
                  resourceName={lang.common.viewRooms}
                  value={dayResourceSelectorValue}
                />
                <Box ml={1}>
                  <TimeSlotDurationSelector
                    value={timeSlotDurationValue}
                    onChange={setTimeSlotDurationValue}
                  />
                </Box>
              </Grid>
              <Grid item xs={12}>
                <WeekCalendar
                  type="resource-week"
                  slotMinTime={startTime}
                  slotMaxTime={endTime}
                  slotDuration={timeSlotDurationValue}
                  resources={resources}
                  datesAboveResources={dayResourceSelectorValue === 'days'}
                  bgEvents={bgEvents}
                  events={events}
                  eventTitle="Not Available"
                  eventClass={classes.naEvent}
                  onAdd={add}
                  onEdit={edit}
                  contentHeight="auto"
                  eventContent={eventContent}
                />
              </Grid>
              <Grid item xs={12}>
                {saving && <LinearProgress />}
                <Box centeredContent>
                  <Button
                    color="secondary"
                    className={classes.runButton}
                    onClick={save}
                    disabled={!dirty}
                  >
                    {lang.common.save}
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
};
