import { useCallback } from 'react';

import { Link } from 'core/cells/link';
import { Typography } from 'core/cells/typography';

import { useLocale } from 'core/dna/routing';
import { SubstituteJobState } from 'core/dna/types/generated/_globalTypes';

import { useOasHistory } from 'core/metabolism/use-oas-history';

import { JobTableItem } from 'modules/substitute/dna/types/job';

import { selectJob } from 'modules/substitute/memory/rx/jobs';

import URLS from 'modules/substitute/urls';

interface DisplayCellDataProps {
  value: string;
  row: any;
  data: JobTableItem[];
}

export const DisplayCellInternalTitle = (props: DisplayCellDataProps) => {
  const { row, value, data } = props;
  const { push } = useOasHistory();
  const locale = useLocale();

  const rowData = data[row.index];

  const handleOpenJobDetails = useCallback(
    async (id: string) => {
      const job = data?.find((item) => item.id === id);
      if (job) {
        selectJob(job);
        push(URLS.jobDetails({ jobId: job.id, locale }));
      }
    },
    [data, locale, push],
  );

  const handleEdit = useCallback(
    async (id: string) => {
      const job = data?.find((item) => item.id === id);
      if (job) {
        selectJob(job);
        push(URLS.editJob({ jobId: job.id, locale }));
      }
    },
    [data, locale, push],
  );

  return (
    <Link
      onClick={() =>
        rowData.state === SubstituteJobState.draft
          ? handleEdit(rowData.id)
          : handleOpenJobDetails(rowData.id)
      }
    >
      <Typography>{value}</Typography>
    </Link>
  );
};
