import { mapNullableListToList } from 'core/dna/functions/map-nullable-list-to-list';
import { EvaluationGroupAccess } from 'modules/grades/dna/types';
import { GetEvaluationGroupAccess_EvaluationGroupAccess as ApiEvaluationGroupAccess } from 'core/dna/types/generated/GetEvaluationGroupAccess';
import { mapApiEvaluationGroupToEvaluationGroup } from '../map-api-evaluation-group-to-evaluation-group';

export const mapApiEvaluationGroupAccessToEvaluationGroupAccess = (
  apiEvaluationGroupAccess: ApiEvaluationGroupAccess | null,
): EvaluationGroupAccess => {
  if (apiEvaluationGroupAccess === null) {
    throw new TypeError('"apiEvaluationGroupAccess" should not be null!');
  }

  const {
    createdAt,
    updatedAt,
    teacher,
    evaluationGroup,
    evaluator,
  } = apiEvaluationGroupAccess;

  return {
    evaluator,
    createdAt: createdAt?.formatted ?? null,
    updatedAt: updatedAt?.formatted ?? null,
    evaluationGroup: mapApiEvaluationGroupToEvaluationGroup(evaluationGroup),
    teacher,
  };
};

export const mapApiEvaluationGroupAccessToEvaluationGroupAccessList = (
  apiEvaluationGroupAccess: (ApiEvaluationGroupAccess | null)[] | null,
): EvaluationGroupAccess[] | undefined => {
  return mapNullableListToList<ApiEvaluationGroupAccess, EvaluationGroupAccess>(
    apiEvaluationGroupAccess,
    mapApiEvaluationGroupAccessToEvaluationGroupAccess,
  );
};
