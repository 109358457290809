import { DraggableType, DropFn } from 'core/atoms/types';

import { Droppable } from 'core/cells/droppable';
import { OutlinedArea } from 'core/cells/outlined-area';

export interface DroppableOutlinedAreaProps {
  label: string;
  type: DraggableType;
  isOver?: boolean;
  selected?: boolean;
  onOverChanged?: (isOver: boolean) => void;
  onDrop?: DropFn;
  minWidth?: string | number;
  minHeight?: string | number;
  centeredContent?: boolean;
  children?: any;
  className?: string;
  classes?: { label?: string };
  bigLabel?: boolean;
  error?: boolean;
}

const defaultProps: Partial<DroppableOutlinedAreaProps> = {
  minWidth: '100%',
  minHeight: '100%',
};

export const DroppableOutlinedArea = (props: DroppableOutlinedAreaProps) => {
  const {
    label,
    type,
    isOver,
    selected,
    onOverChanged,
    onDrop,
    children,
    minWidth = defaultProps.minWidth,
    minHeight = defaultProps.minHeight,
    centeredContent,
    className,
    classes: propsClasses,
    bigLabel,
    error,
  } = props;

  const occupied = !!children;

  return (
    <OutlinedArea
      minWidth={minWidth}
      minHeight={minHeight}
      focused={isOver}
      occupied={occupied}
      selected={selected}
      label={label}
      className={className}
      classes={{ label: propsClasses?.label }}
      bigLabel={bigLabel}
      error={error}
    >
      <Droppable
        minHeight={minHeight}
        centeredContent={centeredContent}
        type={type}
        onDrop={onDrop}
        onOver={(isOverNew) => {
          if (isOverNew !== isOver) {
            onOverChanged && onOverChanged(isOverNew);
          }
        }}
      >
        {children}
      </Droppable>
    </OutlinedArea>
  );
};

DroppableOutlinedArea.defaultProps = defaultProps;
