import { ComponentsVariants } from '@mui/material/styles/variants';

import colors from '../base/colors';

export const MuiChipThemeComponent: {
  variants?: ComponentsVariants['MuiChip'];
} = {
  variants: [
    {
      props: { variant: 'filled', color: 'secondary' },
      style: {
        '&.MuiButtonBase-root': {
          backgroundColor: colors.secondary.main,
        },
      },
    },
  ],
};
