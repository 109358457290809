import React from 'react';

import MuiListItem, {
  ListItemProps as MuiListItemProps,
} from '@material-ui/core/ListItem';

import { makeStyles, createStyles } from 'core/atoms/styles';

export interface ListItemProps extends MuiListItemProps {
  vertical?: boolean;
}

const verticalContentMixin = {
  flexDirection: 'column',
  '& .MuiListItemIcon-root': {
    justifyContent: 'center',
  },
};

const smallContentMixin = {
  '& .MuiTypography-body1': {
    fontSize: '0.7rem',
  },
};

const useStyles = makeStyles(
  createStyles({
    root: (props: ListItemProps) => {
      return props.vertical
        ? { ...verticalContentMixin, ...smallContentMixin }
        : {};
    },
  }),
);

export const ListItem = (props: ListItemProps) => {
  const classes = useStyles(props);

  const { vertical, button, children, ...other } = props;

  const liProps: any = {
    button,
    className: classes.root,
    ...other,
  };

  return <MuiListItem {...liProps}>{children}</MuiListItem>;
};
