import { useCallback } from 'react';

import { OasMemoryError, OasError } from 'core/atoms/errors';

import { gql, useMutation } from 'core/dna/types/apollo';
import { DeleteClassInput } from 'core/dna/types/generated/_globalTypes';
import {
  DeleteClass,
  DeleteClassVariables,
} from 'core/dna/types/generated/DeleteClass';

import { useLazyGetTermsState } from 'core/memory/apollo/terms/local';

import { GET_CLASSES_QUERY } from './_gql';

const DELETE_CLASS_MUTATION = gql`
  mutation DeleteClass($input: DeleteClassInput!) {
    deleteClass(input: $input) {
      description
      status
      statusCode
    }
  }
`;

const TITLE = 'UseDeleteClass';

export const useDeleteClass = () => {
  const { getTermState } = useLazyGetTermsState();

  const [deleteClass] = useMutation<DeleteClass, DeleteClassVariables>(
    DELETE_CLASS_MUTATION,
  );

  const func = useCallback(
    async (input: DeleteClassInput) => {
      try {
        const termsState = await getTermState(true);

        const result = await deleteClass({
          variables: { input },
          refetchQueries: [
            {
              query: GET_CLASSES_QUERY,
              variables: { termId: termsState!.selected! },
            },
          ],
        });

        if (result.errors) {
          throw new OasMemoryError(JSON.stringify(result.errors), {
            title: TITLE,
          });
        }
        return result.data?.deleteClass ?? undefined;
      } catch (e: any) {
        if (e instanceof OasError) {
          throw e;
        } else {
          throw OasMemoryError.fromError(e, {
            title: TITLE,
          });
        }
      }
    },
    [deleteClass, getTermState],
  );

  return {
    deleteClass: func,
  };
};
