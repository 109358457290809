import { useCallback } from 'react';
import { produce } from 'immer';

import { logger, LogType } from 'core/atoms/housekeeping';
import { OasMemoryError } from 'core/atoms/errors';
import { AnyMap, TextItem } from 'core/atoms/types';
import { intersection, intersectionBy, uniq } from 'core/atoms/functions/array';

import {
  ChapterAssignments,
  StudentItem,
  AssignmentState,
  UnionStoreItem,
} from 'modules/planner/dna/types/local';
import { Student } from 'modules/planner/dna/types/student';
import { Union } from 'modules/planner/dna/types/union';

import { classStudentsGroupsMapStateVar } from 'modules/planner/memory/apollo/cache';

const getChapters = (unions: AnyMap<UnionStoreItem>) => {
  let union: UnionStoreItem;
  let students: StudentItem[];
  const chapters: ChapterAssignments[] = [];

  unions.allIds.forEach((uId) => {
    union = unions.byId[uId];
    union.chapters.forEach((ch) => {
      students = union.students.filter((s) => s.chapter === ch.id);
      chapters.push({
        id: ch.id,
        roleIds: students.length ? students.map((s) => s.id) : [],
      });
    });
  });
  return chapters;
};

const getAssignedChapters = (unions: AnyMap<UnionStoreItem>) =>
  getChapters(unions).filter((c) => c.roleIds.length);

const isChangedFromLastAssignment = (
  unions: AnyMap<UnionStoreItem>,
  prevAssignments?: ChapterAssignments[],
) => {
  const currentAssignments = getAssignedChapters(unions);
  if (prevAssignments?.length !== currentAssignments.length) {
    return true;
  }
  const intersectionById = intersectionBy(
    prevAssignments,
    currentAssignments,
    'id',
  );
  if (intersectionById.length !== prevAssignments.length) {
    return true;
  }
  const chapterWithDiffStudents = prevAssignments.find((prev) => {
    const currentRoleIds =
      currentAssignments.find((current) => current.id === prev.id)?.roleIds ??
      [];
    if (prev.roleIds.length !== currentRoleIds.length) {
      return true;
    }
    const int1 = intersection(prev.roleIds, currentRoleIds);
    return int1.length !== prev.roleIds.length;
  });
  return !!chapterWithDiffStudents;
};

const getUnionState = (students?: StudentItem[]) => {
  const assignedStudentsCount = students?.filter((s) => s.chapter).length;
  const allStudentsCount = students?.length;

  const state: AssignmentState =
    !assignedStudentsCount || !allStudentsCount
      ? 'unassigned'
      : assignedStudentsCount < allStudentsCount
      ? 'in progress'
      : 'all set';

  return state;
};

const getOverallState = (unions: AnyMap<UnionStoreItem>): AssignmentState => {
  const unionsExceptWholeClassUnion = unions.allIds.filter(
    (id) => unions.byId[id].chapters.length > 1,
  );

  if (!unionsExceptWholeClassUnion.length) {
    return 'unassigned';
  }

  const states = unionsExceptWholeClassUnion.map((id) => unions.byId[id].state);

  if (states.every((s) => s === 'all set')) {
    return 'all set';
  }
  if (states.every((s) => s === 'unassigned')) {
    return 'unassigned';
  }
  return 'in progress';
};

export const useSetStudentsGroupsState = () => {
  const init = useCallback(
    (input: { classId: string; unions: Union[]; students: Student[] }) => {
      const { classId, unions, students } = input;
      let stateMap = classStudentsGroupsMapStateVar();
      const unionsMap = unions
        .map((u) => {
          const chapters = u.chapters?.map(
            (c) =>
              ({
                id: c.id,
                text: c.name,
              } as TextItem),
          );
          const studentItems = students.map(
            (s) =>
              ({
                id: s.roleId,
                text: `${s.firstName ?? ''} ${s.lastName ?? ''}`.trim() || s.id,
                chapter: s.chapters?.find(
                  (ch) => (u.chapters?.map((c) => c.id) ?? []).indexOf(ch) > -1,
                ),
              } as StudentItem),
          );

          return {
            id: u.id,
            chapters,
            students: studentItems,
            state: getUnionState(studentItems),
          } as UnionStoreItem;
        })
        .reduce(
          (prev, cur) => {
            const val = { ...prev };
            val.byId[cur.id] = cur;
            val.allIds = uniq([...val.allIds, cur.id]);
            return val;
          },
          { byId: {}, allIds: [] } as AnyMap<UnionStoreItem>,
        );

      const state = produce(stateMap.byId[classId] ?? {}, (draft) => {
        draft.unions = unionsMap;
        draft.status = 'initialized';
        draft.prevChapterAssignments = getAssignedChapters(unionsMap);
        draft.changedFromLastAssignment = false;
        draft.assignmentsStatus = getOverallState(unionsMap);
      });

      stateMap = produce(stateMap, (draft) => {
        draft.byId[classId] = state;
        if (!draft.allIds.find((id) => classId === id)) {
          draft.allIds.push(classId);
        }
      });

      classStudentsGroupsMapStateVar(stateMap);
    },
    [],
  );

  const updateStudent = useCallback(
    (input: {
      classId: string;
      unionId: string;
      studentId: string;
      chapterId?: string;
    }) => {
      const { classId, unionId, studentId, chapterId } = input;
      let stateMap = classStudentsGroupsMapStateVar();
      if (!stateMap.allIds.find((id) => classId === id)) {
        throw new OasMemoryError();
      }
      let state = { ...stateMap.byId[classId] };
      if (state.status === 'empty') {
        return;
      }
      state = produce(state, (draft) => {
        const student = draft.unions.byId[unionId].students.find(
          (s) => s.id === studentId,
        );
        if (!student) {
          return;
        }
        student.chapter = chapterId;
        draft.changedFromLastAssignment = isChangedFromLastAssignment(
          draft.unions,
          draft.prevChapterAssignments,
        );
        const union = draft.unions.byId[unionId];
        union.state = getUnionState(union.students);
        draft.assignmentsStatus = getOverallState(draft.unions);
      });
      stateMap = produce(stateMap, (draft) => {
        draft.byId[classId] = state;
      });
      classStudentsGroupsMapStateVar(stateMap);
    },
    [],
  );

  const selectChapter = useCallback(
    (input: { classId: string; unionId: string; chapterId?: string }) => {
      const { classId, unionId, chapterId } = input;
      let stateMap = classStudentsGroupsMapStateVar();
      if (!stateMap.allIds.find((id) => classId === id)) {
        throw new OasMemoryError();
      }
      let state = { ...stateMap.byId[classId] };
      if (state.status === 'empty') {
        return;
      }

      state = produce(state, (draft) => {
        const union = draft.unions.byId[unionId];
        if (!union) {
          return;
        }
        union.selectedChapter = chapterId;
      });

      logger.debug({
        title: 'selectChapter',
        logger: 'students-groups-store',
        type: LogType.Info,
        value: [{ unionId, chapterId }],
      });

      stateMap = produce(stateMap, (draft) => {
        draft.byId[classId] = state;
      });

      classStudentsGroupsMapStateVar(stateMap);
    },
    [],
  );

  const assign = useCallback(
    async (input: {
      classId: string;
      assignFunc: (
        chapterId: string,
        roleIds: string[],
      ) => Promise<{ roleId: string; roleTitle: string }[] | undefined>;
    }) => {
      const { classId, assignFunc } = input;
      let stateMap = classStudentsGroupsMapStateVar();
      if (!stateMap.allIds.find((id) => classId === id)) {
        throw new OasMemoryError();
      }
      let state = produce(stateMap.byId[classId], (draft) => {
        draft.assigning = true;
      });
      stateMap = produce(stateMap, (draft) => {
        draft.byId[classId] = state;
      });
      classStudentsGroupsMapStateVar(stateMap);
      try {
        await Promise.all(
          getChapters(state.unions).map((chapter) =>
            assignFunc(chapter.id, chapter.roleIds),
          ),
        );
        state = produce(state, (draft) => {
          draft.assignSuccess = true;
          draft.assignError = false;
          draft.changedFromLastAssignment = false;
          draft.prevChapterAssignments = [];
        });
      } catch {
        state = produce(state, (draft) => {
          draft.assignSuccess = false;
          draft.assignError = true;
        });
      } finally {
        state = produce(state, (draft) => {
          draft.assigning = false;
        });
      }

      stateMap = produce(stateMap, (draft) => {
        draft.byId[classId] = state;
      });

      classStudentsGroupsMapStateVar(stateMap);
      // emit(state);
    },
    [],
  );

  return {
    init,
    updateStudent,
    selectChapter,
    assign,
  };
};
