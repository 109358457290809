export const mapWeekToApiWeek = (week: {
  week: number;
  year: number;
}): number => {
  const str = `${week.year}${week.week.toLocaleString('en-US', {
    minimumIntegerDigits: 2,
    useGrouping: false,
  })}`;
  return Number(str);
};
