import { useMemo } from 'react';

import { gql, useQuery } from 'core/dna/types/apollo';
import { GetSelectedClassesState } from 'core/dna/types/generated/GetSelectedClassesState';
import {
  mapNullableListToList,
  mapNullableStringToString,
} from 'core/dna/functions';

const GET_SELECTED_CLASSES_STATE_QUERY = gql`
  query GetSelectedClassesState {
    selectedClassesState @client
  }
`;

export const useGetSelectedClassesState = () => {
  const { data, loading, error } = useQuery<GetSelectedClassesState>(
    GET_SELECTED_CLASSES_STATE_QUERY,
  );

  const selectedClassesState = useMemo<string[] | undefined>(() => {
    if (!loading && !error && data) {
      return (
        mapNullableListToList(
          data.selectedClassesState,
          mapNullableStringToString,
        ) ?? undefined
      );
    }
  }, [loading, error, data]);

  return {
    selectedClassesState,
    loading,
    error,
  };
};
