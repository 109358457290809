import { useState } from 'react';
import { RGBColor, ColorChangeHandler, SwatchesPicker } from 'react-color';

import { makeStyles, createStyles, Theme } from 'core/atoms/styles';
import { hexToRgbA } from 'core/atoms/functions/hex-to-rgba';
import {
  lightBlue,
  teal,
  yellow,
  amber,
  purple,
  red,
  brown,
  blueGrey,
  grey,
  green,
  deepPurple,
  lime,
  orange,
  pink,
} from 'core/atoms/colors';

export interface ColorPickerProps {
  color?: RGBColor;
  colors?: string[];
  readonly?: boolean;
  onChange?: ColorChangeHandler;
}

export const defaultColors = [
  lightBlue['500'],
  teal['500'],
  yellow.A100,
  purple.A400,
  amber['500'],
  brown['500'],
  amber['100'],
  teal['900'],
  teal.A400,
  yellow['50'],
  blueGrey['100'],
  red['400'],
  grey['900'],
  grey['500'],
  green['500'],
  deepPurple['500'],
  lime['900'],
  lime['800'],
  orange['500'],
  red['300'],
  blueGrey['500'],
  pink.A200,
  yellow.A200,
  red['100'],
];

const defaultProps: Partial<ColorPickerProps> = {
  color: hexToRgbA('#cacaca'),
  colors: defaultColors,
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    swatch: {
      padding: theme.spacing(0.5),
      // background: '#fff',
      // borderRadius: 1,
      // boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
      display: 'inline-block',
      cursor: (props: ColorPickerProps) =>
        props.readonly ? 'inherit' : 'pointer',
    },
    color: {
      width: theme.spacing(3.5),
      height: theme.spacing(2.5),
      borderRadius: 2,
      border: '1px solid',
      borderColor: '#878787',
      background: (props: ColorPickerProps) => {
        const color = props.color ?? defaultProps.color;
        return `rgba(${color!.r}, ${color!.g}, ${color!.b}, ${color!.a})`;
      },
    },
    popover: {
      position: 'absolute',
      zIndex: 2,
    },
    cover: {
      position: 'fixed',
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
    },
  }),
);

export const ColorPicker = (props: ColorPickerProps) => {
  const classes = useStyles(props);
  const { color, readonly, onChange } = props;
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    !readonly && setOpen(!open);
  };

  const handleClose = () => {
    !readonly && setOpen(false);
  };

  return (
    <div>
      <div className={classes.swatch} onClick={handleClick}>
        <div className={classes.color} />
      </div>
      {open ? (
        <div className={classes.popover}>
          <div className={classes.cover} onClick={handleClose} />
          <SwatchesPicker
            width={282}
            height={200}
            color={color}
            // colors={colors}
            onChange={onChange}
          />
          {/* <GithubPicker */}
          {/*  width="162px" */}
          {/*  triangle="top-left" */}
          {/*  color={color} */}
          {/*  colors={colors} */}
          {/*  onChange={onChange} */}
          {/* /> */}
        </div>
      ) : null}
    </div>
  );
};

ColorPicker.defaultProps = defaultProps;
