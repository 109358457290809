import { AUTH_ACCESS_TOKEN_KEY } from './constants';

const hasAccessToken = () => {
  return !!localStorage.getItem(AUTH_ACCESS_TOKEN_KEY);
};

const getAccessToken = (): string | null => {
  return hasAccessToken()
    ? String(localStorage.getItem(AUTH_ACCESS_TOKEN_KEY))
    : null;
};

const removeAccessToken = () => {
  hasAccessToken() && localStorage.removeItem(AUTH_ACCESS_TOKEN_KEY);
};

const setAccessToken = (token: string | null) => {
  if (token) {
    localStorage.setItem(AUTH_ACCESS_TOKEN_KEY, token);
  } else {
    removeAccessToken();
  }
};

export const getAccessTokenStorage = () => {
  return {
    key: AUTH_ACCESS_TOKEN_KEY,
    hasAccessToken,
    getAccessToken,
    removeAccessToken,
    setAccessToken,
  };
};
