import { FC } from 'react';

import { makeStyles, createStyles } from 'core/atoms/styles';

import { TextField } from 'core/cells/text-field';

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    textField: {
      width: 'auto',
      // '& label.Mui-focused': {
      //   color: `${teal.A700}`,
      // },
      // '& .MuiInput-underline:after': {
      //   borderBottomColor: `${teal.A700}`,
      // },
      // '& .MuiOutlinedInput-root': {
      // '& fieldset': {
      //   borderColor: `${teal.A700}`,
      // },
      // '&:hover fieldset': {
      //   borderColor: `${teal.A700}`,
      // },
      // '&.Mui-focused fieldset': {
      //   borderColor: `${teal.A700}`,
      // },
      // },
    },
  }),
);

export interface TimeFieldProps {
  label?: string;
  step?: number;
  defaultValue?: string;
  value?: string;
  onChange?: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>;
  disabled?: boolean;
}

const defaultProps: Partial<TimeFieldProps> = {
  step: 300, // 5 min
  defaultValue: undefined,
};

export const TimeField: FC<TimeFieldProps> = (props) => {
  const classes = useStyles();

  const { label, step, defaultValue, value, onChange, disabled } = props;

  return (
    <form className={classes.container} noValidate>
      <TextField
        id="time"
        label={label}
        type="time"
        margin="dense"
        defaultValue={defaultValue}
        className={classes.textField}
        InputLabelProps={{
          shrink: true,
        }}
        inputProps={{ step }}
        value={value}
        onChange={onChange}
        disabled={disabled}
      />
    </form>
  );
};

TimeField.defaultProps = defaultProps;
