import { useCallback } from 'react';

import { OasMemoryError, OasError } from 'core/atoms/errors';

import { useApolloClient } from 'core/dna/types/apollo';
import { GetTermsState } from 'core/dna/types/generated/GetTermsState';
import { mapLocalApiTermsStateToTermsState } from 'modules/planner/dna/functions/map-LocalApiTermsState-to-TermsState';

import { GET_TERMS_STATE_QUERY } from './get-terms-state-query';

const TITLE = 'useLazyGetTermsState';

export const useLazyGetTermsState = () => {
  const client = useApolloClient();

  const func = useCallback(
    async (throwIfEmpty?: boolean) => {
      try {
        const getTermStateResult = await client.query<GetTermsState>({
          query: GET_TERMS_STATE_QUERY,
        });

        const termsState = mapLocalApiTermsStateToTermsState(
          getTermStateResult.data?.termsState ?? null,
        );

        if (throwIfEmpty && !termsState?.selected) {
          throw new OasMemoryError('Term is not set!', { title: TITLE });
        }

        return termsState;
      } catch (e: any) {
        if (e instanceof OasError) {
          throw e;
        } else {
          throw OasMemoryError.fromError(e, {
            title: TITLE,
          });
        }
      }
    },
    [client],
  );

  return {
    getTermState: func,
  };
};
