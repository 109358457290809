import { CircularProgress } from 'core/cells/circular-progress';
import { FullBox } from 'core/cells/box';

import { useLocale, useLocation } from 'core/dna/routing';

import { useOasHistory } from 'core/metabolism/use-oas-history';

import {
  useAuthorize,
  defaultAuthErrorCallback,
  defaultAuthCallback,
} from 'auth/metabolism';

const TITLE = 'Auth Callback';

export const AuthCallback = () => {
  const location = useLocation();
  const { replace } = useOasHistory();
  const locale = useLocale();

  const from = (location?.state as any)?.from ?? TITLE;

  useAuthorize(
    (identity) =>
      defaultAuthCallback(identity, { navigateTo: replace, from, locale }),
    (error) =>
      defaultAuthErrorCallback(error, { navigateTo: replace, from, locale }),
  );

  return (
    <FullBox>
      <CircularProgress size={100} />
    </FullBox>
  );
};
