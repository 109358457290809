import { useCallback, useState } from 'react';

import { logger, LogType } from 'core/atoms/housekeeping';
import {
  OasPageError,
  OasError,
  OasErrorInterface,
  OasMemoryError,
} from 'core/atoms/errors';
import { parseTemplate } from 'core/atoms/functions/string';
import { useSnackbar } from 'core/cells/notistack';
import { useTranslations } from 'core/dna/translations';
import {
  BatchCreateStudentEvaluationsInput,
  CreateEvaluationGroupAccessInput,
  CreateEvaluationGroupInput,
  DeleteEvaluationGroupAccessInput,
  UpdateEvaluationBatchInput,
  UpdateEvaluationGroupInput,
} from 'core/dna/types/generated/_globalTypes';

import {
  useBatchCreateStudentEvaluations,
  useCreateEvaluationGroup,
  useCreateEvaluationGroupAccess,
  useDeleteEvaluationGroupAccess,
  useUpdateEvaluationGroup,
} from '../memory/apollo/evaluation/remote';

const TITLE = 'use-teacher-table-write';

export const useTeacherTableWrite = () => {
  const { texts } = useTranslations();
  const { enqueueSnackbar } = useSnackbar();
  const { createEvaluationGroup } = useCreateEvaluationGroup();
  const { updateEvaluationGroup } = useUpdateEvaluationGroup();
  const { createEvaluationGroupAccess } = useCreateEvaluationGroupAccess();
  const { deleteEvaluationGroupAccess } = useDeleteEvaluationGroupAccess();
  const { createStudentEvaluation } = useBatchCreateStudentEvaluations();

  const [writing, setWriting] = useState(false);

  const onRowAdd = useCallback(
    async (item: CreateEvaluationGroupInput, batchId) => {
      try {
        setWriting(true);
        const result = await createEvaluationGroup(item, batchId);
        setWriting(false);
        enqueueSnackbar(
          parseTemplate(texts.oasCommon.entityCreated, {
            entityType: texts.grades.gradesCommon.subjectCode,
          }),
          {
            variant: 'success',
          },
        );
        return result;
      } catch (e: any) {
        setWriting(false);
        let error: OasErrorInterface;
        if (e instanceof OasError) {
          error = e;
        } else {
          error = OasMemoryError.fromError(e, {
            title: TITLE,
          });
        }
        enqueueSnackbar(error.message, { variant: 'error' });
        throw error;
      }
    },
    [createEvaluationGroup, enqueueSnackbar, texts],
  );

  const onRowUpdate = useCallback(
    async (item: UpdateEvaluationGroupInput, batchId) => {
      try {
        setWriting(true);
        const result = await updateEvaluationGroup(item, batchId);
        setWriting(false);
        enqueueSnackbar(
          parseTemplate(texts.oasCommon.entityUpdated, {
            entityType: texts.grades.gradesCommon.subjectCode,
          }),
          {
            variant: 'success',
          },
        );
        return result;
      } catch (e: any) {
        setWriting(false);
        let error: OasErrorInterface;
        if (e instanceof OasError) {
          error = e;
        } else {
          error = OasMemoryError.fromError(e, {
            title: TITLE,
          });
        }
        enqueueSnackbar(error.message, { variant: 'error' });
        throw error;
      }
    },
    [enqueueSnackbar, texts, updateEvaluationGroup],
  );

  const onRowDelete = useCallback(
    async (oldData: UpdateEvaluationBatchInput) => {
      try {
        setWriting(true);
        enqueueSnackbar('Not implemented!', { variant: 'warning' });
        // await deleteSubstituteJob(oldData.id);
        setWriting(false);
      } catch (e: any) {
        setWriting(false);
        const error =
          e instanceof OasError
            ? e
            : OasPageError.fromError(e, { title: TITLE });
        if (error.originalMessage) {
          logger.info(error.originalMessage, LogType.Error);
        }
        enqueueSnackbar(
          parseTemplate(texts.oasCommon.deleteEntitiesOfTypeError.one, {
            entityType: texts.oasCommon.job.one,
            entity: oldData.term.slice(0, 10),
          }),
          {
            variant: 'error',
          },
        );
        return 1;
      }
    },
    [enqueueSnackbar, texts],
  );

  const onAddAccess = useCallback(
    async (item: CreateEvaluationGroupAccessInput) => {
      try {
        setWriting(true);
        const result = await createEvaluationGroupAccess(item);
        setWriting(false);
        enqueueSnackbar(
          parseTemplate(texts.oasCommon.entityCreated, {
            entityType: 'Responsible teacher',
          }),
          {
            variant: 'success',
          },
        );
        return result;
      } catch (e: any) {
        setWriting(false);
        let error: OasErrorInterface;
        if (e instanceof OasError) {
          error = e;
        } else {
          error = OasMemoryError.fromError(e, {
            title: TITLE,
          });
        }
        enqueueSnackbar(error.message, { variant: 'error' });
        throw error;
      }
    },
    [createEvaluationGroupAccess, enqueueSnackbar, texts],
  );

  const onDeleteAccess = useCallback(
    async (item: DeleteEvaluationGroupAccessInput) => {
      try {
        setWriting(true);
        const result = await deleteEvaluationGroupAccess(item);
        setWriting(false);
        enqueueSnackbar('Access deleted!', { variant: 'success' });
        return result;
      } catch (e: any) {
        setWriting(false);
        let error: OasErrorInterface;
        if (e instanceof OasError) {
          error = e;
        } else {
          error = OasMemoryError.fromError(e, {
            title: TITLE,
          });
        }
        enqueueSnackbar(error.message, { variant: 'error' });
        throw error;
      }
    },
    [deleteEvaluationGroupAccess, enqueueSnackbar],
  );

  const onAddEvaluation = useCallback(
    async (item: BatchCreateStudentEvaluationsInput) => {
      try {
        setWriting(true);
        const result = await createStudentEvaluation(item);
        setWriting(false);
        enqueueSnackbar(
          parseTemplate(texts.oasCommon.entityCreated, {
            entityType: 'Evaluation',
          }),
          {
            variant: 'success',
          },
        );
        return result;
      } catch (e: any) {
        setWriting(false);
        let error: OasErrorInterface;
        if (e instanceof OasError) {
          error = e;
        } else {
          error = OasMemoryError.fromError(e, {
            title: TITLE,
          });
        }
        enqueueSnackbar(error.message, { variant: 'error' });
        throw error;
      }
    },
    [createStudentEvaluation, enqueueSnackbar, texts],
  );

  return {
    onRowAdd,
    onRowUpdate,
    onRowDelete,
    onAddAccess,
    onDeleteAccess,
    onAddEvaluation,
    writing,
  };
};
