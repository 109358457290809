import {
  OasError,
  getOasErrorFromError,
  OasErrorOptions,
  OasErrorInterface,
} from './oas-error';

export type AuthStatus =
  | ''
  | 'logging-in'
  | 'authorizing'
  | 'identity'
  | 'lock'
  | 'logged-out';

export interface AuthErrorData extends Record<string, unknown> {
  status: AuthStatus | null;
}

export const OasAuthError = class OasAuthError extends OasError<AuthErrorData> {
  static fromError(
    e: unknown,
    options?: OasErrorOptions,
  ): OasErrorInterface<AuthErrorData> {
    return getOasErrorFromError<AuthErrorData>(OasAuthError, e, options);
  }

  constructor(message?: string, options?: OasErrorOptions<AuthErrorData>) {
    super(message, { ...options, type: 'OasAuthError' });
  }
};
