import {
  OasError,
  OasErrorConstructor,
  getOasErrorFromError,
  OasErrorOptions,
  OasErrorInterface,
} from './oas-error';

export const OasDnaError: OasErrorConstructor = class OasDnaError extends OasError {
  constructor(message?: string, options?: OasErrorOptions) {
    super(message, { ...options, type: 'OasDnaError' });
  }

  static fromError(e: unknown, options?: OasErrorOptions): OasErrorInterface {
    return getOasErrorFromError(OasDnaError, e, options);
  }
};
