import { GetSyncStaff_SyncStaff as ApiSyncStaff } from 'core/dna/types/generated/GetSyncStaff';
import { mapNullableListToList } from 'core/dna/functions';

import { SyncStaff } from 'modules/planner/dna/types/sync-staff';

export const mapApiSyncStaffListToSyncStaffList = (
  apiSyncStaff: (ApiSyncStaff | null)[] | null,
): SyncStaff[] | undefined => {
  return mapNullableListToList<ApiSyncStaff, SyncStaff>(
    apiSyncStaff,
    mapApiSyncStaffToSyncStaff,
  );
};

export const mapApiSyncStaffToSyncStaff = (
  apiSyncStaff: ApiSyncStaff | null,
): SyncStaff | undefined => {
  if (!apiSyncStaff) {
    return;
  }

  const { id, firstName, lastName } = apiSyncStaff;

  return {
    id,
    firstName: firstName ?? 'NAME',
    lastName: lastName ?? 'LAST-NAME',
  };
};
