import { useCallback } from 'react';

import { OasMemoryError } from 'core/atoms/errors';

import { DataError } from 'modules/planner/dna/types';
import { useMutation, useApolloClient } from 'core/dna/types/apollo';
import { DeleteLessonGroupInput } from 'core/dna/types/generated/_globalTypes';
import { GetSchedulesSelectedState } from 'core/dna/types/generated/GetSchedulesSelectedState';

import { GET_SCHEDULE_QUERY } from 'modules/planner/memory/apollo/schedules/remote';
import { GET_SCHEDULES_SELECTED_STATE_QUERY } from 'modules/planner/memory/apollo/schedules/local';

import { DELETE_LESSON_GROUP_MUTATION } from './delete-lesson-group-mutation';

export interface UseDeleteLessonGroupResult {
  deleteLessonGroup: (input: DeleteLessonGroupInput) => Promise<any>;
  loading: boolean;
  error?: DataError;
}

const TITLE = 'use-delete-lesson-group';

export const useDeleteLessonGroup = (): UseDeleteLessonGroupResult => {
  const [deleteLessonGroup, { loading, error }] = useMutation(
    DELETE_LESSON_GROUP_MUTATION,
  );
  const client = useApolloClient();

  const func = useCallback(
    (input: DeleteLessonGroupInput) => {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise<any>(async (resolve, reject) => {
        try {
          const getScheduleStateSelectedResult = await client.query<GetSchedulesSelectedState>(
            {
              query: GET_SCHEDULES_SELECTED_STATE_QUERY,
            },
          );

          const scheduleIds =
            getScheduleStateSelectedResult.data?.schedulesState?.selected;

          if (scheduleIds?.length !== 1) {
            throw new OasMemoryError(
              'Schedule set incorrectly! Only one is expected.',
              { title: TITLE },
            );
          }

          const executionResult = await deleteLessonGroup({
            variables: {
              input: {
                ...input,
              },
            },
            refetchQueries: [
              {
                query: GET_SCHEDULE_QUERY,
                variables: { scheduleId: scheduleIds[0] },
              },
            ],
          });

          if (executionResult.data && !executionResult.errors) {
            resolve(executionResult);
          } else {
            reject(new Error('deleteLessonGroup error!'));
          }
        } catch (e: any) {
          reject(e);
        }
      });
    },
    [client, deleteLessonGroup],
  );

  return {
    deleteLessonGroup: func,
    loading,
    error,
  };
};
