import React, { useCallback } from 'react';

import { useOasHistory } from 'core/metabolism/use-oas-history';
import { useLocale } from 'core/dna/routing';
import { Button } from 'core/cells/button';
import { useTranslations } from 'core/dna/translations';

import GradesUrls from 'modules/grades/urls';

interface DisplayCellDataProps {
  // value?: any[];
  row: any;
  data: any[];
}

export const StudentsLinkCell = (props: DisplayCellDataProps) => {
  const { row, data } = props;

  const { texts } = useTranslations();
  const { push } = useOasHistory();
  const locale = useLocale();

  const handleClick = useCallback(() => {
    return push(
      GradesUrls.prepareEvaluationGroup({
        evaluationGroupId: data[row.index].id,
        locale,
      }),
    );
  }, [data, locale, push, row.index]);

  return (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    <Button onClick={() => handleClick()} color="primary">
      {texts.planner.common.manageCount.one}
    </Button>
  );
};
