import { useMemo } from 'react';
import { useQuery, gql } from 'core/dna/types/apollo';
import {
  GetEvaluationGroup,
  GetEvaluationGroupVariables,
} from 'core/dna/types/generated/GetEvaluationGroup';
import { EvaluationGroupInput } from 'core/dna/types/generated/_globalTypes';

import { mapApiEvaluationGroupToEvaluationGroupList } from 'modules/grades/dna/functions';
import { EvaluationGroup } from 'modules/grades/dna/types';

import { EVALUATION_GROUP_FRAGMENT } from 'modules/grades/memory/apollo/_fragments';

export const GET_EVALUATION_GROUP_QUERY = gql`
  query GetEvaluationGroup($filter: EvaluationGroupInput) {
    EvaluationGroup(filter: $filter) {
      ...EvaluationGroup
    }
  }
  ${EVALUATION_GROUP_FRAGMENT}
`;

export const useGetEvaluationGroup = (filter?: EvaluationGroupInput) => {
  const { data, error, loading } = useQuery<
    GetEvaluationGroup,
    GetEvaluationGroupVariables
  >(GET_EVALUATION_GROUP_QUERY, { variables: { filter } });

  const evaluationGroup = useMemo<EvaluationGroup[] | undefined>(() => {
    if (loading || error || !data) {
      return;
    }

    if (!data.EvaluationGroup) {
      return;
    }

    return mapApiEvaluationGroupToEvaluationGroupList(data.EvaluationGroup);
  }, [loading, error, data]);

  return {
    loading,
    error,
    data: evaluationGroup,
  };
};
