import { gql } from 'core/dna/types/apollo';

import { ROOM_DATA_FRAGMENT } from 'modules/planner/memory/apollo/_fragments';

export const EDIT_ROOM_MUTATION = gql`
  mutation EditRoom($input: EditRoomInput) {
    editRoom(input: $input) {
      ...RoomData
    }
  }
  ${ROOM_DATA_FRAGMENT}
`;
