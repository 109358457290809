import { useCallback } from 'react';

import { OasMemoryError, OasError } from 'core/atoms/errors';

import { gql, useMutation } from 'core/dna/types/apollo';
import { DeleteSubjectInput } from 'core/dna/types/generated/_globalTypes';
import {
  DeleteSubjectVariables,
  DeleteSubject,
} from 'core/dna/types/generated/DeleteSubject';

import { useLazyGetTermsState } from 'core/memory/apollo/terms/local';

import { GET_SUBJECTS_QUERY } from '../graphql';

const DELETE_SUBJECT_MUTATION = gql`
  mutation DeleteSubject($input: DeleteSubjectInput!) {
    deleteSubject(input: $input) {
      description
      status
      statusCode
    }
  }
`;

const TITLE = 'Use Delete Subject';

export const useDeleteSubject = () => {
  const { getTermState } = useLazyGetTermsState();

  const [deleteSubject] = useMutation<DeleteSubject, DeleteSubjectVariables>(
    DELETE_SUBJECT_MUTATION,
  );

  const func = useCallback(
    async (input: DeleteSubjectInput) => {
      try {
        const termsState = await getTermState(true);

        const result = await deleteSubject({
          variables: { input },
          refetchQueries: [
            {
              query: GET_SUBJECTS_QUERY,
              variables: { termId: termsState!.selected! },
            },
          ],
        });

        if (result.errors) {
          throw new OasMemoryError(JSON.stringify(result.errors), {
            title: TITLE,
          });
        }
        return result.data?.deleteSubject ?? undefined;
      } catch (e: any) {
        if (e instanceof OasError) {
          throw e;
        } else {
          throw OasMemoryError.fromError(e, {
            title: TITLE,
          });
        }
      }
    },
    [deleteSubject, getTermState],
  );

  return {
    deleteSubject: func,
  };
};
