import { GetJournals_PersonT2 as ApiPersonT2Journals } from 'core/dna/types/generated/GetJournals';
import { mapNullableListToList } from 'core/dna/functions/map-nullable-list-to-list';
import { ShortDataWithJournals } from 'modules/student/dna/types/short-data';
import { mapApiJournalToJournalList } from '../map-api-journal-to-journal';
import { mapApiPersonT2ShortDataToPersonT2ShortData } from '../map-api-person-t2-short-data-to-person-t2-short-data';

export const mapApiPersonT2JournalsToPersonT2Journals = (
  apiPersonT2Journals: ApiPersonT2Journals | null,
): ShortDataWithJournals => {
  if (apiPersonT2Journals === null) {
    throw new TypeError('"apiPersonT2Journals" should not be null!');
  }

  return {
    ...mapApiPersonT2ShortDataToPersonT2ShortData(apiPersonT2Journals),
    journals: mapApiJournalToJournalList(apiPersonT2Journals.journals),
  };
};

export const mapApiPersonT2JournalsToPersonT2JournalsList = (
  apiPersonT2List: (ApiPersonT2Journals | null)[] | null,
): ShortDataWithJournals[] | undefined => {
  return mapNullableListToList<ApiPersonT2Journals, ShortDataWithJournals>(
    apiPersonT2List,
    mapApiPersonT2JournalsToPersonT2Journals,
  );
};
