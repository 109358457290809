import { OrganizationLevel, RouteConfig } from 'core/dna/types/local';
import { PATHS } from 'modules/student/paths';
import {
  OrganizationCategoryType,
  OrganizationFeatureCode,
} from 'core/dna/types/generated/_globalTypes';
import { mapTranslationDueToOrgCategoryType } from 'core/dna/translations/functions';
import { isFeatureAvailable } from 'core/dna/functions';

import { GetDataRoutesProps } from 'app/routes';

import { StudentCardMainContent } from './pages/cards/card';
import { StudentListMainContent } from './pages/cards/list';
import { SleepHoursListMainContent } from './pages/sleep-hours/list';
import { AllergiesListMainContent } from './pages/allergies/list';
import { CountingMainContent } from './pages/counting/list';
import { WeeklyEvents } from './pages/weekly-events';

const cardId = `student-${PATHS.Cards.Card.path}`;

export const getRoutes = (data: GetDataRoutesProps): RouteConfig[] => {
  const { organization, texts } = data;

  const routes = {
    studentList: {
      id: `student-${PATHS.Cards.path}`,
      name: mapTranslationDueToOrgCategoryType(
        texts,
        organization?.category,
        'students',
      ),
      mini: mapTranslationDueToOrgCategoryType(
        texts,
        organization?.category,
        'students',
      )
        .toUpperCase()
        .slice(0, 2),
      private: true,
      path: PATHS.Cards.path,
      component: StudentListMainContent,
      sidebar: true,
      level: OrganizationLevel.Organization,
      exact: true,
    },
    sleepHours: {
      id: `student-${PATHS.SleepHours.path}`,
      name: texts.studentModule.sleepHours,
      mini: texts.studentModule.sleepHours.toUpperCase().slice(0, 2),
      private: true,
      path: PATHS.SleepHours.path,
      component: SleepHoursListMainContent,
      sidebar: isFeatureAvailable(organization, OrganizationFeatureCode.beta),
      level: OrganizationLevel.Organization,
      exact: true,
    },
    allergies: {
      id: `student-${PATHS.Allergies.path}`,
      name: texts.studentModule.allergiesList,
      mini: texts.studentModule.allergiesList.toUpperCase().slice(0, 2),
      private: true,
      path: PATHS.Allergies.path,
      component: AllergiesListMainContent,
      sidebar: isFeatureAvailable(organization, OrganizationFeatureCode.beta),
      level: OrganizationLevel.Organization,
      exact: true,
    },
    counting: {
      id: `counting-${PATHS.Counting.path}`,
      name: texts.studentModule.counting.countingList,
      mini: texts.studentModule.counting.countingList.toUpperCase().slice(0, 2),
      private: true,
      path: PATHS.Counting.path,
      component: CountingMainContent,
      sidebar: isFeatureAvailable(organization, OrganizationFeatureCode.beta),
      level: OrganizationLevel.Organization,
      exact: true,
    },
    weeklyEvents: {
      id: `weekly-events-${PATHS.WeeklyEvents.path}`,
      name: texts.studentModule.weeklyEvents,
      mini: texts.studentModule.weeklyEvents.toUpperCase().slice(0, 2),
      private: true,
      path: PATHS.WeeklyEvents.path,
      component: WeeklyEvents,
      sidebar: isFeatureAvailable(organization, OrganizationFeatureCode.beta),
      level: OrganizationLevel.Organization,
      exact: true,
    },
    studentCard: {
      id: cardId,
      name: '',
      mini: 'SC',
      private: true,
      path: PATHS.Cards.Card.path,
      component: StudentCardMainContent,
      level: OrganizationLevel.Organization,
      exact: true,
    },
  };

  // studentCard must be last in routes array !!!
  switch (organization?.category) {
    case OrganizationCategoryType.barnehage:
      return [
        routes.studentList,
        routes.weeklyEvents,
        routes.sleepHours,
        routes.allergies,
        routes.counting,
        routes.studentCard,
      ];

    default:
      return [
        routes.studentList,
        routes.weeklyEvents,
        routes.allergies,
        routes.studentCard,
      ];
  }
};
