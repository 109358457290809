/* eslint-disable */
import React from 'react';
import clsx from 'clsx';

import Button from '@material-ui/core/Button';

import { useStyles } from './pagination.styles';

interface Page {
  active?: boolean; // PropTypes.bool,
  disabled?: boolean; // PropTypes.bool,
  text: number | 'PREV' | 'NEXT' | '...'; // PropTypes.oneOfType([]).isRequired,
  onClick?: () => void; // PropTypes.func,
}

type PaginationColor = 'primary' | 'info' | 'success' | 'warning' | 'danger';

export interface PaginationProps {
  pages: Page[]; // PropTypes.arrayOf(PropTypes.shape({}),).isRequired,
  color?: PaginationColor; // PropTypes.oneOf([]),
}

const defaultProps: Partial<PaginationProps> = {
  color: 'primary',
};

export const Pagination = (props: PaginationProps) => {
  const classes = useStyles();
  const { pages, color = defaultProps.color! } = props;
  return (
    <ul className={classes.pagination}>
      {pages.map((prop, key) => {
        const paginationLink = clsx({
          [classes.paginationLink]: true,
          [classes[color]]: prop.active,
          [classes.disabled]: prop.disabled,
        });
        return (
          <li className={classes.paginationItem} key={key}>
            {prop.onClick !== undefined ? (
              <Button onClick={prop.onClick} className={paginationLink}>
                {prop.text}
              </Button>
            ) : (
              <Button
                onClick={() => alert("you've clicked " + prop.text)}
                className={paginationLink}
              >
                {prop.text}
              </Button>
            )}
          </li>
        );
      })}
    </ul>
  );
};

Pagination.defaultProps = defaultProps;
