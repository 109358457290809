import { useCallback, useMemo, useState } from 'react';
import axios from 'axios';
import moment from 'moment';

import { runtimeEnv } from 'core/atoms/env';
import { OasAuthError, OasError, AuthStatus } from 'core/atoms/errors';

import { AuthContext, AuthContextProps } from 'auth/dna';
import { mapTokensToAuthIdentity, parseJwt } from 'auth/dna/functions';

import {
  getStatusStorage,
  getAccessTokenStorage,
  getSessionTokenStorage,
} from 'auth/memory/browser';
import { getHasPinCodeStorage } from 'auth/memory/browser/has-pin-code-storage';

import AuthPath from 'auth/paths';

import './time-interval-check';

const env = runtimeEnv();

const config = {
  redirectUri: `${env.REACT_APP_BASE_URI}${AuthPath.Callback.path}`,
  loginUri: `${env.REACT_APP_AUTH_BASE_URI}/auth?callback=${Buffer.from(
    env.REACT_APP_BASE_URI + '/auth/callback',
  ).toString('base64')}`,
  sessionsUri: `${env.REACT_APP_CORE_URI}/v1/sessions`,
  onboardUri: `${env.REACT_APP_OAS_BASE_URI}/auth/onboard`,
};

const TITLE = 'auth-provider';

export const AuthProvider = ({ children }: { children: any }) => {
  const {
    getAccessToken,
    setAccessToken,
    removeAccessToken,
  } = getAccessTokenStorage();
  const {
    getSessionToken,
    setSessionToken,
    removeSessionToken,
  } = getSessionTokenStorage();
  const {
    getHasPinCode,
    setHasPinCode,
    removeHasPinCode,
  } = getHasPinCodeStorage();
  const { getStatus, setStatus } = getStatusStorage();

  const login = useCallback(() => {
    return new Promise<any>((resolve, reject) => {
      try {
        const status = getStatus();
        if (status && status !== 'logged-out') {
          reject(status);
        }

        setStatus('logging-in');
        resolve(config.loginUri);
      } catch (error: any) {
        reject(error);
      }
    });
  }, [getStatus, setStatus]);

  const doOnboard = useCallback(async (accessToken: string) => {
    await axios.get(`${config.onboardUri}`, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });
  }, []);

  const authorize = useCallback(
    (tgt: string) => {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise<any>(async (resolve, reject) => {
        try {
          const status = getStatus();
          if (status !== 'logging-in') {
            reject(status);
          }

          setStatus('authorizing');

          const response = await axios.post(`${config.sessionsUri}`, {
            tgt,
          });

          const { data } = response;

          const newIdentity = mapTokensToAuthIdentity(
            data['accessToken'],
            data['sessionToken'],
          );

          // await axios.get(`${config.onboardUri}`, {
          //   headers: { Authorization: `Bearer ${data['accessToken']}` },
          // });

          await doOnboard(data['accessToken']);

          const hasPincodeResult = await axios.get(
            `${env.REACT_APP_CORE_URI}/v1/sessions/${newIdentity?.session.jti}/pincode`,
            { headers: { Authorization: `Bearer ${data['accessToken']}` } },
          );

          setStatus('identity');
          setAccessToken(data['accessToken']);
          setSessionToken(data['sessionToken']);
          setHasPinCode(hasPincodeResult.data.pincode ?? false);

          resolve(newIdentity);
        } catch (error: any) {
          reject(error);
        }
      });
    },
    [
      getStatus,
      setAccessToken,
      setHasPinCode,
      setSessionToken,
      setStatus,
      doOnboard,
    ],
  );

  const removeTokens = useCallback(() => {
    removeAccessToken();
    removeSessionToken();
    removeHasPinCode();
  }, [removeAccessToken, removeSessionToken, removeHasPinCode]);

  const logout = useCallback(() => {
    return new Promise<void>((resolve, reject) => {
      try {
        const status = getStatus();
        if (status !== 'identity' && status !== 'lock') {
          reject(status);
        }

        setStatus('logged-out');
        removeTokens();

        resolve();
      } catch (error: any) {
        reject(error);
      }
    });
  }, [getStatus, removeTokens, setStatus]);

  const getIdentity = useCallback(() => {
    const accessToken = getAccessToken();
    const sessionToken = getSessionToken();
    return accessToken && sessionToken
      ? mapTokensToAuthIdentity(accessToken, sessionToken)
      : null;
  }, [getAccessToken, getSessionToken]);

  const setTokens = useCallback(
    (input: { accessToken: string; sessionToken: string }) => {
      setAccessToken(input.accessToken);
      setSessionToken(input.sessionToken);
    },
    [setAccessToken, setSessionToken],
  );

  const isAccessTokenExpired = useCallback(() => {
    const accessToken = getAccessToken();
    if (!accessToken) {
      return false;
    }
    const expiredAt = Number(parseJwt(accessToken)['exp']);
    const now = Math.round(new Date().getTime() / 1000);
    return now >= expiredAt;
  }, [getAccessToken]);

  const isSessionTokenExpired = useCallback(() => {
    const sessionToken = getSessionToken();
    if (!sessionToken) {
      return false;
    }
    const expiredAt = Number(parseJwt(sessionToken)['exp']);
    const now = Math.round(new Date().getTime() / 1000);
    return now >= expiredAt;
  }, [getSessionToken]);

  const lock = useCallback(async (): Promise<AuthStatus> => {
    try {
      const status = getStatus();
      if (!status) {
        return '';
      }
      if (status !== 'identity') {
        return status;
      }
      const hasPinCode = getHasPinCode();
      if (!hasPinCode) {
        return status;
      }

      setStatus('lock');
      removeAccessToken();
      return 'lock';
    } catch (e: any) {
      if (e instanceof OasError) {
        throw e;
      } else {
        throw OasAuthError.fromError(e, {
          title: TITLE,
        });
      }
    }
  }, [getHasPinCode, getStatus, removeAccessToken, setStatus]);

  const refresh = useCallback(
    (
      input: {
        accessToken: string | null;
        sessionToken: string | null;
      },
      startup?: boolean,
    ) => {
      const hasPinCode = getHasPinCode();
      const oldStatus = getStatus() ?? '';
      if (oldStatus === 'authorizing') {
        return;
      }
      if (!input.sessionToken || isSessionTokenExpired()) {
        const newStatus = startup ? '' : 'logged-out';
        if (oldStatus !== 'identity' && oldStatus !== newStatus) {
          removeTokens();
          setStatus(newStatus);
        }
        return;
      }

      if (!input.accessToken || isAccessTokenExpired()) {
        if (hasPinCode) {
          if (oldStatus !== 'lock') {
            removeAccessToken();
            setStatus('lock');
          }
        } else {
          const newStatus = startup ? '' : 'logged-out';
          if (oldStatus !== newStatus) {
            removeTokens();
            setStatus(newStatus);
          }
        }
      }
    },
    [
      getHasPinCode,
      getStatus,
      isAccessTokenExpired,
      isSessionTokenExpired,
      removeAccessToken,
      removeTokens,
      setStatus,
    ],
  );

  const [ping, setPing] = useState(moment().format('YYYY-MM-DD HH:mm:ss'));
  const reload = useCallback(() => {
    setPing(moment().format('YYYY-MM-DD HH:mm:ss'));
  }, []);

  const value = useMemo<AuthContextProps>(
    () => ({
      setTokens,
      isAccessTokenExpired,
      isSessionTokenExpired,
      removeAccessToken,
      removeTokens,
      getIdentity,
      getHasPinCode,
      setHasPinCode,
      login,
      authorize,
      logout,
      lock,
      refresh,
      ping,
      reload,
      doOnboard,
    }),
    [
      authorize,
      getHasPinCode,
      setHasPinCode,
      getIdentity,
      isAccessTokenExpired,
      isSessionTokenExpired,
      lock,
      login,
      logout,
      ping,
      refresh,
      reload,
      removeAccessToken,
      removeTokens,
      setTokens,
      doOnboard,
    ],
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
