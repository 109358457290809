import { gql } from 'core/dna/types/apollo';

import {
  SCHEDULE_DATA_FRAGMENT,
  SCHEDULE_SNAPSHOT_DATA_FRAGMENT,
  SCHEDULE_DESCRIPTION_DATA_FRAGMENT,
} from 'modules/planner/memory/apollo/_fragments';

export const GET_SCHEDULE_QUERY = gql`
  query GetSchedule($scheduleId: String!) {
    Schedule(filter: { id: $scheduleId }) {
      ...ScheduleData
    }
  }
  ${SCHEDULE_DATA_FRAGMENT}
`;

export const SCHEDULE_SNAPSHOT_SUBSCRIPTION = gql`
  subscription ApiScheduleSnapshot($scheduleId: String!) {
    scheduleSnapshot(scheduleId: $scheduleId) {
      ...ScheduleSnapshotData
    }
  }
  ${SCHEDULE_SNAPSHOT_DATA_FRAGMENT}
`;

export const GET_SCHEDULE_DESCRIPTIONS_QUERY = gql`
  query GetScheduleDescriptions($termId: String!) {
    Schedule(filter: { term: { id: $termId } }) {
      ...ScheduleDescriptionData
    }
  }
  ${SCHEDULE_DESCRIPTION_DATA_FRAGMENT}
`;
