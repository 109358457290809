import React, { useCallback } from 'react';

import { EventContentArg } from 'core/cells/full-calendar';

import { RemovableEvent } from './removable-event';

export interface UseRemovableEventProps {
  onDelete?: (eventId: string) => void;
}
export const useRemovableEvent = (props: UseRemovableEventProps) => {
  const eventContent = useCallback(
    (args: EventContentArg) => (
      <RemovableEvent {...args} onDelete={props.onDelete} />
    ),
    [props.onDelete],
  );
  return { eventContent };
};
