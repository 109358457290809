import { useCallback, useMemo, Key } from 'react';
import clsx from 'clsx';

import { makeStyles, createStyles } from 'core/atoms/styles';
import { DraggableTypes, DraggableItem } from 'core/atoms/types';
import { orderBy } from 'core/atoms/functions/array';

import { Box } from 'core/cells/box';
import { Typography } from 'core/cells/typography';
import { Paper } from 'core/cells/paper';
import { OutlinedArea } from 'core/cells/outlined-area';

import { useDrag } from 'modules/planner/dna/dnd/use-drag';
import { Class } from 'modules/planner/dna/types/class';
import { Chapter, ChapterSummary } from 'modules/planner/dna/types/chapter';
import { Union } from 'modules/planner/dna/types/union';

export interface ClassWithChaptersProps {
  cls: Class;
  assignedChapters: { chapterId: string; classId: string }[];
  onChapterDoubleClick?: (item: DraggableItem) => void;
}

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      margin: theme.spacing(1),
      padding: theme.spacing(0.5),
    },
    union: {
      // backgroundColor: '#e3e3e3',
      // marginBottom: theme-light.spacing(1),
    },
    chapter: {
      backgroundColor: '#e3e3e3',
      margin: theme.spacing(0.5),
      width: '100%',
      textAlign: 'center',
    },
    chapterText: {
      margin: 0,
      fontSize: 13,
    },
    disabled: {
      pointerEvents: 'none',
      opacity: 0.4,
    },
  }),
);

export const ClassWithChapters = (props: ClassWithChaptersProps) => {
  const classes = useStyles();
  const { cls, assignedChapters, onChapterDoubleClick } = props;

  const ChapterItem = (chapter: Chapter) => {
    const { id, name, union: { id: unionId } = { id: '' } } = chapter;

    const item: DraggableItem<ChapterSummary> = useMemo(
      () => ({
        type: DraggableTypes.Chapter,
        id,
        text: name || DraggableTypes.Chapter,
        data: {
          id,
          name,
          union: {
            id: unionId,
          },
          class: {
            id: cls.id,
            name: cls.name,
          },
        },
      }),
      [id, name, unionId],
    );

    const { isDragging, dragRef } = useDrag<ChapterSummary>(item);

    const onDoubleClick = useCallback(() => {
      onChapterDoubleClick?.(item);
    }, [item]);

    return (
      <Paper
        ref={dragRef}
        style={{
          opacity: isDragging ? 0.8 : 1,
          cursor: 'move',
          zIndex: 1,
        }}
        className={classes.chapter}
        onDoubleClick={onDoubleClick}
      >
        <Typography variant="caption" className={classes.chapterText}>
          {name}
        </Typography>
      </Paper>
    );
  };

  const UnionItem = (props: {
    union: Union;
    key?: Key;
    disabled?: boolean;
  }) => {
    const { union, key, disabled } = props;
    return (
      <Box
        key={key}
        fullSize
        display="flex"
        className={clsx(classes.union, disabled && classes.disabled)}
      >
        {union.chapters &&
          orderBy(
            union.chapters.filter(
              (ch) =>
                !assignedChapters.filter((ac) => ac.chapterId === ch.id)
                  ?.length,
            ),
            // union.chapters,
            ['name'],
          ).map((ch) => <ChapterItem key={ch.id} {...ch} />)}
      </Box>
    );
  };

  const classChapters = useMemo(
    () =>
      cls.unions
        ?.map(
          (u) =>
            u.chapters?.map((c) => ({ chapterId: c.id, unionId: u.id })) ?? [],
        )
        .reduce((prev, cur) => [...prev, ...cur], []) ?? [],
    [cls.unions],
  );

  // const hasAssignedChaptersFromClass = useCallback((unionId: string) => {
  //   cls.unions.some((u) => assignedChapters)
  //   assignedChapters.filter(ac => ac.)
  // }, []);

  const isUnionSelected = useCallback(
    (union: Union) =>
      union.chapters?.some(
        (uc) => !!assignedChapters.find((ac) => ac.chapterId === uc.id),
      ),
    [assignedChapters],
  );

  return (
    <OutlinedArea
      minWidth={120}
      minHeight={40}
      label={cls.name}
      focused
      className={classes.root}
    >
      <Box>
        {cls.unions &&
          cls.unions.map((u) => (
            <UnionItem
              key={u.id}
              union={u}
              disabled={
                !!assignedChapters.find((ac) => ac.classId === cls.id) &&
                !isUnionSelected(u)
              }
            />
          ))}
      </Box>
    </OutlinedArea>
  );
};
