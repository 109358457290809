import { useMemo, useCallback } from 'react';
import moment, { Moment } from 'moment';

import { OasMetabolismError } from 'core/atoms/errors';
import {
  getWeek as getWeekOfDate,
  getYear,
  getWeeksInYear,
  getMondayOfWeek,
} from 'core/atoms/date-time';
import { TextItem } from 'core/atoms/types';

import { Allocation } from 'modules/planner/dna/types/allocation';

import { useGetAllocations } from 'modules/planner/memory/apollo/allocation/remote';

import { useTerm } from './terms';

export interface TermWeek {
  monDate: Date;
  holidays: Moment[] | undefined;
  weekNumber: number;
  year: number;
  schedule: { id: string; name: string } | null;
}

const FILE_NAME = 'use-term-weeks';

export const useTermWeeks = () => {
  const { termStart, termEnd, term } = useTerm();
  const { data, loading, error } = useGetAllocations(term?.id);

  const findAllocationForWeek = useCallback(
    (input: { week: number; year: number }) =>
      data?.find((w) => w.week === input.week && w.year === input.year),
    [data],
  );

  const termWeeks = useMemo<TermWeek[]>(() => {
    const termLength = termEnd.diff(termStart, 'months');
    if (termLength > 12 || termLength < 10) {
      throw new OasMetabolismError('Term length is incorrect!', {
        title: FILE_NAME,
      });
    }

    const isOneYearTerm = getYear(termEnd) - getYear(termStart) === 0;

    const weekFrom = getWeekOfDate(termStart, true);
    const weekTo = getWeekOfDate(termEnd, true);

    const maxWeek1 = isOneYearTerm ? weekTo : getWeeksInYear(termStart);
    const items: TermWeek[] = [];
    let allocation: Allocation | undefined;

    const getTermWeek = (
      weekNumber: number,
      year: number,
      schedule?: TextItem,
    ): TermWeek => {
      const mon = getMondayOfWeek(weekNumber, year);
      const weekHolidays = term?.holidays
        ?.filter((h) => {
          const diff = moment(h).diff(mon, 'days');
          return diff >= 0 && diff <= 6;
        })
        .map((wh) => moment(wh));

      return {
        monDate: mon,
        holidays: weekHolidays ? [...weekHolidays] : undefined,
        weekNumber,
        year,
        schedule: schedule ? { id: schedule.id, name: schedule.text } : null,
      };
    };

    for (let i = weekFrom!; i <= maxWeek1!; i++) {
      allocation = findAllocationForWeek({
        week: i,
        year: getYear(termStart),
      });
      items.push(getTermWeek(i, getYear(termStart), allocation?.schedule));
    }
    if (!isOneYearTerm) {
      for (let i = 1; i <= weekTo!; i++) {
        allocation = findAllocationForWeek({
          week: i,
          year: getYear(termEnd),
        });
        items.push(getTermWeek(i, getYear(termEnd), allocation?.schedule));
      }
    }
    return items;
  }, [findAllocationForWeek, term?.holidays, termEnd, termStart]);

  return {
    termWeeks,
    findAllocationForWeek,
    allocations: data,
    loading,
    error,
  };
};
