export enum DraggableTypes {
  Teacher = 'teacher',
  Room = 'room',
  Subject = 'subject',
  Chapter = 'chapter',
  Student = 'student',
}

export type DraggableType = string;

export interface UiElementSize {
  width: number;
  height: number;
}

export interface TextValue {
  text: string;
  value: any;
}

export interface TextValueData<T = any> extends TextValue {
  data?: T;
}

export interface Identity {
  id: string;
}

export interface TextItem extends Identity {
  text: string;
}

export interface DataItem<T> extends TextItem {
  data?: T;
}

export interface DraggableItem<T = any> extends DataItem<T> {
  type: string;
}

export interface DropFn {
  <T extends DraggableItem>(item: T): void;
}

export interface AnyMap<T = any> {
  byId: { [id: string]: T };
  allIds: string[];
}

export type IdentityMap<T extends Identity> = AnyMap<T>;

export type OrderDirection = 'asc' | 'desc';

export enum ConfirmationAutoFocus {
  None = 'none',
  Ok = 'ok',
  Cancel = 'cancel',
}
