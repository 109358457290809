import { useCallback } from 'react';

import { parseTemplate } from 'core/atoms/functions/string';

import { useSnackbar } from 'core/cells/notistack';

import { useTranslations } from 'core/dna/translations';

import { useDeleteLessonGroup } from 'modules/planner/memory/apollo/lesson-groups/remote';

export const useLessonGroupsTableWrite = () => {
  const { texts } = useTranslations();
  const { deleteLessonGroup } = useDeleteLessonGroup();
  const { enqueueSnackbar } = useSnackbar();

  const onRowDelete = useCallback(
    (oldData: any) =>
      new Promise<any>((resolve, reject) => {
        deleteLessonGroup({
          lessonGroupId: oldData.id,
        })
          .then((result) => {
            enqueueSnackbar(
              parseTemplate(texts.oasCommon.entitiesOfTypeWereDeleted.one, {
                entityType: texts.oasCommon.lessonGroups.one,
                entity: oldData.subjects ?? '',
              }),
              {
                variant: 'success',
              },
            );
            resolve(result);
          })
          .catch((e) => {
            enqueueSnackbar(e.message, { variant: 'error' });
            reject(e);
          });
      }),
    [
      deleteLessonGroup,
      enqueueSnackbar,
      texts.oasCommon.entitiesOfTypeWereDeleted.one,
      texts.oasCommon.lessonGroups.one,
    ],
  );

  return {
    onRowDelete,
  };
};
