/* eslint-disable */
import React, { ReactNode } from 'react';
import clsx from 'clsx';

import { useStyles } from './heading.styles';

export interface HeadingProps {
  title?: ReactNode; // PropTypes.node,
  category?: ReactNode; // PropTypes.node,
  textAlign: 'right' | 'left' | 'center'; // PropTypes.oneOf([]),
}

export const Heading = (props: HeadingProps) => {
  const { textAlign, category, title } = props;
  const classes = useStyles();
  const heading =
    classes.heading +
    ' ' +
    clsx({
      [(classes as any)[textAlign + 'TextAlign']]: textAlign !== undefined,
    });
  if (title !== undefined || category !== undefined) {
    return (
      <div className={heading}>
        {title !== undefined ? (
          <h3 className={classes.title}>{title}</h3>
        ) : null}
        {category !== undefined ? (
          <p className={classes.category}>{category}</p>
        ) : null}
      </div>
    );
  }
  return null;
};
