/* eslint-disable */
import React, { ReactNode } from 'react';
import { useStyles } from './card-icon.styles';
import clsx from 'clsx';

type CardIconColor =
  | 'warning'
  | 'success'
  | 'danger'
  | 'info'
  | 'primary'
  | 'rose';

export interface CardIconProps {
  className?: string; // PropTypes.string,
  color?: CardIconColor; // PropTypes.oneOf([]),
  children?: ReactNode; // PropTypes.node;
}

export const CardIcon = (props: CardIconProps) => {
  const classes = useStyles();
  const { className, children, color, ...rest } = props;

  const cardIconClasses = clsx(
    classes.cardIcon,
    color ? (classes as any)[color + 'CardHeader'] ?? '' : '',
    className !== undefined ? className : '',
  );

  return (
    <div className={cardIconClasses} {...rest}>
      {children}
    </div>
  );
};
