import { useDrag as dndUseDrag } from 'react-dnd';

import { DraggableItem } from 'core/atoms/types';

export const useDrag = <T = any>(item: DraggableItem<T>) => {
  const { type, id, text, data } = item;

  const [{ isDragging }, dragRef] = dndUseDrag({
    item: { type, id, text, data },
    collect: (monitor) => {
      return {
        isDragging: monitor.isDragging(),
      };
    },
  });

  return { isDragging, dragRef };
};
