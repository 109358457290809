import { useMemo } from 'react';

import { useTranslations } from 'core/dna/translations';

import { EvaluationGroup, StudentEvaluation } from 'modules/grades/dna/types';

export interface EvaluationsTableItem extends StudentEvaluation {
  firstName: string;
  lastName: string;
}

export const useEvaluationsTableRead = (data: EvaluationGroup) => {
  const { texts } = useTranslations();

  const columns = useMemo(() => {
    return [
      {
        Header: texts.planner.common.firstName,
        accessor: 'firstName',
      },
      {
        Header: texts.planner.common.lastName,
        accessor: 'lastName',
      },
      {
        Header: texts.grades.gradesCommon.evaluation,
        accessor: 'evaluation',
        disableFilters: true,
      },
      {
        Header: texts.grades.gradesCommon.note,
        accessor: 'note',
        disableFilters: true,
      },
    ];
  }, [texts]);

  const preparedData = useMemo<EvaluationsTableItem[]>(() => {
    if (!data.evaluations?.length) {
      return [];
    }

    return data.evaluations.map((evaluation) => {
      return {
        ...evaluation,
        firstName: evaluation.student?.personDetails?.firstName || '',
        lastName: evaluation.student?.personDetails?.lastName || '',
      };
    });
  }, [data]);

  return {
    title: texts.grades.prepareBatch.title,
    columns,
    data: preparedData,
  };
};
