import { useMemo } from 'react';

import { gql, useQuery } from 'core/dna/types/apollo';
import { GetTimePlanState } from 'core/dna/types/generated/GetTimePlanState';

import { TimePlanState } from 'modules/planner/dna/types/local';

export const GET_TIME_PLAN_STATE_QUERY = gql`
  query GetTimePlanState {
    timePlanState @client {
      scheduleId
      calendarEvents
      worklist
    }
  }
`;

export const useGetTimePlanState = () => {
  const { data: localApiData, loading, error } = useQuery<GetTimePlanState>(
    GET_TIME_PLAN_STATE_QUERY,
  );

  const data = useMemo<TimePlanState | undefined>(() => {
    if (!loading && !error && localApiData) {
      return {
        scheduleId: localApiData.timePlanState?.scheduleId ?? '',
        calendarEvents: localApiData.timePlanState?.calendarEvents ?? [],
        worklist: localApiData.timePlanState?.worklist ?? [],
      };
    }
  }, [loading, error, localApiData]);

  return {
    loading,
    error,
    data,
  };
};
