import React from 'react';
import Intercom from 'react-intercom';

import { runtimeEnv } from 'core/atoms/env';

import { useAuthContext } from '../../../auth/metabolism/use-auth-context';

export interface IntercomProviderProps {
  children: any;
}

const appId = runtimeEnv().REACT_APP_INTERCOM_APP_ID;

export const IntercomProvider = (props: IntercomProviderProps) => {
  const { getIdentity } = useAuthContext();
  const identity = getIdentity();
  let userProps: any;
  if (identity) {
    userProps = {
      user_id: identity.sub,
      user_hash: identity.hash,
    };
  }

  return (
    <>
      {identity ? <Intercom appID={appId} {...userProps} /> : <></>}
      {props.children}
    </>
  );
};
