import React from 'react';
import { IntercomAPI } from 'react-intercom';
import { Button } from 'templates/mdp/components/custom-buttons';
import { Features } from 'core/dna/types/organization';
import { formatDateString, parseTemplate } from 'core/atoms/functions/string';
import { useTranslations } from 'core/dna/translations';
import { createStyles, makeStyles } from 'core/atoms/styles';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      width: '100%',
      minWidth: '40vw',
    },
  }),
);

interface BannerContentProps {
  feature?: Features;
}

export const BannerContent = (props: BannerContentProps) => {
  const { texts } = useTranslations();
  const { feature } = props;
  const classes = useStyles();

  const bannerMessage = () => {
    if (!feature || !feature.expiresAt) return '';

    return formatDateString(feature.expiresAt);
  };

  const handleClick = () => {
    IntercomAPI('show');
  };

  return (
    <div className={classes.root}>
      <div>
        {parseTemplate(texts.oasCommon.demoBanner, {
          date: bannerMessage(),
        })}
      </div>
      <Button color="white" size="sm" onClick={handleClick}>
        {texts.oasCommon.contactUs}
      </Button>
    </div>
  );
};
