import { useCallback } from 'react';

import { OasMemoryError, OasError } from 'core/atoms/errors';

import { UpdateAvailabilityPreferencesForEntitiesInput } from 'core/dna/types/generated/_globalTypes';
import { useMutation, gql } from 'core/dna/types/apollo';
import {
  UpdateAvailabilityPreferencesForTeachers,
  UpdateAvailabilityPreferencesForTeachersVariables,
} from 'core/dna/types/generated/UpdateAvailabilityPreferencesForTeachers';

import { useLazyGetTermsState } from 'core/memory/apollo/terms/local';

import { GET_TEACHERS_QUERY } from './_gql';

const UPDATE_AVAILABILITY_PREFERENCES_FOR_TEACHERS_MUTATION = gql`
  mutation UpdateAvailabilityPreferencesForTeachers(
    $input: UpdateAvailabilityPreferencesForEntitiesInput!
  ) {
    updateAvailabilityPreferencesForEntities(input: $input) {
      id
      preferences {
        id
        startTime {
          formatted
        }
        endTime {
          formatted
        }
        weekday
      }
    }
  }
`;

const FILE_NAME = 'use-update-availability-preferences-for-teachers';

export const useUpdateAvailabilityPreferencesForTeachers = () => {
  const { getTermState } = useLazyGetTermsState();

  const [updateAvailabilityPreferencesForTeachers] = useMutation<
    UpdateAvailabilityPreferencesForTeachers,
    UpdateAvailabilityPreferencesForTeachersVariables
  >(UPDATE_AVAILABILITY_PREFERENCES_FOR_TEACHERS_MUTATION);

  const func = useCallback(
    async (input: UpdateAvailabilityPreferencesForEntitiesInput) => {
      try {
        const termsState = await getTermState(true);

        const result = await updateAvailabilityPreferencesForTeachers({
          variables: { input },
          refetchQueries: [
            {
              query: GET_TEACHERS_QUERY,
              variables: { termId: termsState!.selected },
            },
          ],
        });

        if (result.errors) {
          throw new OasMemoryError(JSON.stringify(result.errors), {
            title: FILE_NAME,
          });
        }
      } catch (e: any) {
        if (e instanceof OasError) {
          throw e;
        } else {
          throw OasMemoryError.fromError(e, {
            title: FILE_NAME,
          });
        }
      }
    },
    [getTermState, updateAvailabilityPreferencesForTeachers],
  );

  return {
    updateAvailabilityPreferencesForTeachers: func,
  };
};
