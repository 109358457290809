/* eslint-disable */
import React from 'react';
import clsx from 'clsx';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import { useStyles } from './table.styles';

type TableHeaderColor =
  | 'warning'
  | 'primary'
  | 'danger'
  | 'success'
  | 'info'
  | 'rose'
  | 'gray';

export interface CustomTableProps {
  tableHeaderColor?: TableHeaderColor; // PropTypes.oneOf([]),
  tableHead?: string[]; // PropTypes.arrayOf(PropTypes.string),
  // Of(PropTypes.arrayOf(PropTypes.node)) || Of(PropTypes.object),
  tableData?: any[]; // PropTypes.array,
  hover?: boolean; // PropTypes.bool,
  coloredColls?: number[]; // PropTypes.arrayOf(PropTypes.number),
  // Of(["warning","primary","danger","success","info","rose","gray"]) - colorsColls
  colorsColls?: string[]; // PropTypes.array,
  customCellClasses?: string[]; // PropTypes.arrayOf(PropTypes.string),
  customClassesForCells?: number[]; // PropTypes.arrayOf(PropTypes.number),
  customHeadCellClasses?: string[]; // PropTypes.arrayOf(PropTypes.string),
  customHeadClassesForCells?: number[]; // PropTypes.arrayOf(PropTypes.number),
  striped?: boolean; // PropTypes.bool,
  // this will cause some changes in font
  tableShopping?: boolean; // PropTypes.bool,
}

export const CustomTable = (props: CustomTableProps) => {
  const classes = useStyles();
  const {
    tableHead,
    tableData,
    tableHeaderColor,
    hover,
    colorsColls,
    coloredColls,
    customCellClasses,
    customClassesForCells,
    striped,
    tableShopping,
    customHeadCellClasses,
    customHeadClassesForCells,
  } = props;
  return (
    <div className={classes.tableResponsive}>
      <Table className={classes.table}>
        {tableHead !== undefined ? (
          <TableHead
            className={tableHeaderColor ? classes[tableHeaderColor] : ''}
          >
            <TableRow className={classes.tableRow + ' ' + classes.tableRowHead}>
              {tableHead.map((prop, key) => {
                const tableCellClasses = clsx(
                  classes.tableHeadCell,
                  classes.tableCell,
                  customHeadCellClasses &&
                    customHeadClassesForCells &&
                    customHeadClassesForCells.indexOf(key) !== -1
                    ? customHeadCellClasses[
                        customHeadClassesForCells.indexOf(key)
                      ]
                    : '',
                  tableShopping
                    ? classes.tableShoppingHead
                    : classes.tableHeadFontSize,
                );

                return (
                  <TableCell className={tableCellClasses} key={key}>
                    {prop}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
        ) : null}
        <TableBody>
          {tableData ? (
            tableData.map((prop, key) => {
              let rowColor = '';
              let rowColored = false;
              if (prop.color !== undefined) {
                rowColor = prop.color;
                rowColored = true;
                prop = prop.data;
              }
              const tableRowClasses = clsx({
                [classes.tableRowBody]: true,
                [classes.tableRowHover]: hover,
                [(classes as any)[rowColor + 'Row']]: rowColored,
                [classes.tableStripedRow]: striped && key % 2 === 0,
              });
              if (prop.total) {
                return (
                  <TableRow key={key} hover={hover} className={tableRowClasses}>
                    <TableCell
                      className={classes.tableCell}
                      colSpan={prop.colspan}
                    />
                    <TableCell
                      className={
                        classes.tableCell + ' ' + classes.tableCellTotal
                      }
                    >
                      Total
                    </TableCell>
                    <TableCell
                      className={
                        classes.tableCell + ' ' + classes.tableCellAmount
                      }
                    >
                      {prop.amount}
                    </TableCell>
                    {tableHead &&
                    tableHead.length - (prop.colspan - 0 + 2) > 0 ? (
                      <TableCell
                        className={classes.tableCell}
                        colSpan={tableHead.length - (prop.colspan - 0 + 2)}
                      />
                    ) : null}
                  </TableRow>
                );
              }
              if (prop.purchase) {
                return (
                  <TableRow key={key} hover={hover} className={tableRowClasses}>
                    <TableCell
                      className={classes.tableCell}
                      colSpan={prop.colspan}
                    />
                    <TableCell
                      className={classes.tableCell + ' ' + classes.right}
                      colSpan={prop.col.colspan}
                    >
                      {prop.col.text}
                    </TableCell>
                  </TableRow>
                );
              }
              return (
                <TableRow
                  key={key}
                  hover={hover}
                  className={classes.tableRow + ' ' + tableRowClasses}
                >
                  {prop.map((prop: any, key: number) => {
                    const tableCellClasses = clsx(
                      classes.tableCell,
                      colorsColls &&
                        coloredColls &&
                        coloredColls.indexOf(key) !== -1
                        ? (classes as any)[
                            colorsColls[coloredColls.indexOf(key)]
                          ]
                        : '',
                      customClassesForCells &&
                        customCellClasses &&
                        customClassesForCells.indexOf(key) !== -1
                        ? customCellClasses[customClassesForCells.indexOf(key)]
                        : '',
                    );

                    return (
                      <TableCell className={tableCellClasses} key={key}>
                        {prop}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })
          ) : (
            <></>
          )}
        </TableBody>
      </Table>
    </div>
  );
};

CustomTable.defaultProps = {
  tableHeaderColor: 'gray',
  hover: false,
  colorsColls: [],
  coloredColls: [],
  striped: false,
  customCellClasses: [],
  customClassesForCells: [],
  customHeadCellClasses: [],
  customHeadClassesForCells: [],
};
