import { ORGANIZATION_KEY } from './constants';

const hasOrganization = () => {
  return !!localStorage.getItem(ORGANIZATION_KEY);
};

const getOrganization = (): string | null => {
  return hasOrganization() ? localStorage.getItem(ORGANIZATION_KEY) : null;
};

const removeOrganization = () => {
  hasOrganization() && localStorage.removeItem(ORGANIZATION_KEY);
};

const setOrganization = (organizationId: string | null) => {
  if (organizationId) {
    localStorage.setItem(ORGANIZATION_KEY, organizationId);
  } else {
    removeOrganization();
  }
};

export const getOrganizationStorage = () => {
  return {
    key: ORGANIZATION_KEY,
    hasOrganization,
    getOrganization,
    removeOrganization,
    setOrganization,
  };
};
