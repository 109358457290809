import { memo } from 'react';

import { FlexBox } from 'core/cells/box';
import { Breadcrumbs } from 'core/cells/breadcrumbs';

import { useActiveRoute } from 'core/dna/routing';

const BreadcrumbsNavigatorComponent = () => {
  const { activeRoute } = useActiveRoute();

  return (
    <div style={{ display: 'inline-block' }}>
      <FlexBox>
        <Breadcrumbs aria-label="breadcrumbs" maxItems={3}>
          {!!activeRoute.breadcrumbs?.length &&
            activeRoute.breadcrumbs.map((step) => (
              <step.component key={activeRoute.id} {...step.props} />
            ))}
        </Breadcrumbs>
      </FlexBox>
    </div>
  );
};

BreadcrumbsNavigatorComponent.displayName = 'Breadcrumbs Navigator';

export const BreadcrumbsNavigator = memo(BreadcrumbsNavigatorComponent);
