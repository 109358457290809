import { gql } from 'core/dna/types/apollo';

export const GET_SUBJECT_INFOS_FROM_SYNC_QUERY = gql`
  query GetSubjectInfosFromSync {
    SyncSubject {
      name
      short
    }
  }
`;
