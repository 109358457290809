import React from 'react';

// import loggedOut from 'core/atoms/pictures/logged-out.png';
// import { makeStyles, Theme, createStyles } from 'core/atoms/styles';

// import { Grid } from 'core/cells/grid';
// import { Typography } from 'core/cells/typography';

// import { PATHS } from 'core/dna/routing';

// import { useTranslations } from 'core/dna/translations';

// import { LoginForm } from '../organisms/login-form';
// import { LoginCard } from '../organisms/login-card';

// const useStyles = makeStyles((theme-light: Theme) =>
//   createStyles({
//     root: {
//       height: '100%',
//     },
//     flexColumn: {
//       height: '100%',
//       display: 'flex',
//       flexDirection: 'column',
//       justifyContent: 'center',
//       alignItems: 'center',
//     },
//     button: {
//       height: theme-light.spacing(8),
//     },
//   }),
// );

export const AuthLoggedOut = () => {
  // const classes = useStyles();
  // const { lang } = useTranslations();

  // return (
  //   <Grid container spacing={2} className={classes.root}>
  //     <Grid item xs={12} lg={9}>
  //       <Grid container className={classes.flexColumn}>
  //         <Grid item>
  //           <Typography variant="h1">
  //             {lang.authSpace.loggedOutTitle}
  //           </Typography>
  //         </Grid>
  //         <Grid item>
  //           <img alt="logged-out" src={loggedOut} height="500px" />
  //         </Grid>
  //       </Grid>
  //     </Grid>
  //     <Grid
  //       item
  //       xs={12}
  //       lg={3}
  //       container
  //       spacing={2}
  //       className={classes.flexColumn}
  //     >
  //       <Grid item>
  //         <LoginCard>
  //           <LoginForm from={{ pathname: PATHS.Auth.LoggedOut.path }} />
  //         </LoginCard>
  //       </Grid>
  //     </Grid>
  //   </Grid>
  // );

  return <> </>;
};
