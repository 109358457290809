import { forwardRef } from 'react';

import { mdStyled } from 'core/atoms/themes';

import { MDButton, MDButtonProps } from 'core/cells/md-button';

interface OmittedProps {
  color?: any;
}

export interface WhiteButtonProps
  extends Omit<MDButtonProps, keyof OmittedProps> {}

const WhiteButtonRoot = mdStyled(MDButton)({
  '&.MuiButton-root': {
    '&.Mui-disabled': {
      color: 'rgba(0, 0, 0, 0.8)',
    },
  },
});

export const WhiteButton = forwardRef<any, WhiteButtonProps>(
  ({ children, ...rest }, ref) => {
    return (
      <WhiteButtonRoot {...rest} ref={ref} color="white">
        {children}
      </WhiteButtonRoot>
    );
  },
);

WhiteButton.displayName = 'WhiteButton';
