import { useMemo } from 'react';

import { useQuery, gql } from 'core/dna/types/apollo';
import {
  GetAllocationPlans,
  GetAllocationPlansVariables,
} from 'core/dna/types/generated/GetAllocationPlans';

import { mapApiAllocationListToAllocationList } from 'modules/planner/dna/functions';

import { GET_ALLOCATION_PLANS_QUERY } from './_gql';

export const useGetAllocations = (termId: string | undefined) => {
  const { data, loading, error } = useQuery<
    GetAllocationPlans,
    GetAllocationPlansVariables
  >(GET_ALLOCATION_PLANS_QUERY, {
    variables: { termId: termId! },
    skip: !termId,
  });

  const allocations = useMemo(() => {
    if (!loading && !error && data?.AllocationPlan?.length) {
      return mapApiAllocationListToAllocationList(
        data.AllocationPlan[0]?.allocations ?? null,
      );
    }
  }, [data, error, loading]);

  return {
    data: allocations,
    loading,
    error,
  };
};
