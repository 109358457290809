import { OasErrorInterface, AuthErrorData } from 'core/atoms/errors';

import { AuthorizationErrorCallback } from 'auth/dna/types';

import AuthUrls from 'auth/urls';

export const defaultAuthErrorCallback: AuthorizationErrorCallback = (
  error: OasErrorInterface<AuthErrorData>,
  options,
) => {
  options?.navigateTo?.(AuthUrls.loggedOut(options?.locale));
};
