import { BehaviorSubject } from 'rxjs';
import { produce } from 'immer';

import { logger, LogType } from 'core/atoms/housekeeping';
import { OasErrorInterface } from 'core/atoms/errors';

export interface ErrorState {
  time: OasDateTime;
  error: OasErrorInterface;
}

export interface ErrorsStoreState {
  current: ErrorState | null;
  history: ErrorState[] | null;
}

const initialState: ErrorsStoreState = { current: null, history: null };

const subject = new BehaviorSubject(initialState);

let state = initialState;

const emit = (state: ErrorsStoreState) => {
  subject.next(state);
};

const FILE_NAME = 'core/metabolism/errors/errors-store';

export const errorsStore = {
  subscribe: (setState: (value: ErrorsStoreState) => void) =>
    subject.subscribe(setState),
  setError: (error: OasErrorInterface) => {
    state = produce(state, (draft) => {
      if (draft.current) {
        const history = draft.history ?? [];
        history.push(draft.current);
        draft.history = history;
      }
      draft.current = { time: new Date(), error };
    });

    logger.debug({
      title: 'setError',
      logger: FILE_NAME,
      type: LogType.Info,
    });

    emit(state);
  },
  clearError: () => {
    state = produce(state, (draft) => {
      if (draft.current) {
        const history = draft.history ?? [];
        history.push(draft.current);
        draft.history = history;
      }
      draft.current = null;
    });

    logger.debug({
      title: 'clearError',
      logger: FILE_NAME,
      type: LogType.Info,
    });

    emit(state);
  },
  initialState,
};
