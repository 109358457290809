import { useCallback } from 'react';

import { FlexBox } from 'core/cells/box';
import { Form, Field, FormController } from 'core/cells/forms';
import { MenuItem } from 'core/cells/menu-item';
import { FormTextField } from 'core/cells/form-text-field';

import {
  CreateStudentEvaluationInput,
  EvaluationCode,
} from 'core/dna/types/generated/_globalTypes';

import { useTranslations } from 'core/dna/translations';

import { Button } from 'templates/mdp/components/custom-buttons';

import { WriteModeEnum } from 'core/dna/types/write-mode';

import { WriteTableModal } from 'core/organisms/write-modal';
import { useEvaluationTableWrite } from 'modules/grades/metabolism';
import { useIdentity } from 'auth/metabolism';
import { useGetStudents } from 'modules/student/memory/apollo/students/remote';

export interface InitialEvaluation extends CreateStudentEvaluationInput {}

export const initialEvaluation: InitialEvaluation = {
  studentTuid: '',
  code: EvaluationCode.D,
  evaluationGroupId: '',
  evaluation: '',
  note: '',
};

export enum FieldName {
  evaluation = 'evaluation',
  note = 'note',
}

interface Values {
  studentTuid: string;
  evaluation?: string | null;
  note?: string | null;
}

interface EvaluationCreatorProps {
  writeMode: WriteModeEnum;
  data: InitialEvaluation;
  open: boolean;
  setOpen: (open: boolean) => void;
  selectedStudentsIds?: string[];
  fieldName?: FieldName;
  // onSubmit: (id: string) => void;
}

export const EvaluationCreator = (props: EvaluationCreatorProps) => {
  const {
    open,
    setOpen,
    data,
    writeMode,
    selectedStudentsIds,
    fieldName,
  } = props;
  const identity = useIdentity();
  const { onRowAdd, onRowUpdate } = useEvaluationTableWrite();
  const { texts } = useTranslations();
  const { data: students, loading } = useGetStudents();

  const filteredStudents = !selectedStudentsIds
    ? students
    : students?.filter((s) => !selectedStudentsIds.includes(s.tuid || ''));

  const onClose = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  const handleSubmit = async (values: Values) => {
    if (writeMode === WriteModeEnum.update) {
      await onRowUpdate(
        {
          ...data,
          evaluation: values.evaluation,
          note: values.note,
        },
        identity!.sub,
      );
    } else {
      await onRowAdd({
        ...values,
        code: data.code,
        studentTuid: values.studentTuid,
        evaluationGroupId: data.evaluationGroupId,
      });
    }
    setOpen(false);
  };

  return (
    <WriteTableModal
      width={420}
      isShow={open ?? false}
      onClose={onClose}
      writeMode={WriteModeEnum.create}
      title={
        writeMode === WriteModeEnum.create
          ? texts.planner.common.add
          : texts.planner.common.edit
      }
    >
      <FormController
        initialValues={
          writeMode === WriteModeEnum.create
            ? initialEvaluation
            : {
                ...data,
                note: data.note || '',
                evaluation: data.evaluation || '',
              }
        }
        validate={(values) => {
          const errors: Partial<Values> = {};
          if (writeMode === WriteModeEnum.create && !values.studentTuid) {
            errors.studentTuid = texts.oasCommon.validation.required;
          }
          if (writeMode === WriteModeEnum.update && !values.evaluation) {
            errors.evaluation = texts.oasCommon.validation.required;
          }
          return errors;
        }}
        onSubmit={async (values, formikHelpers) => {
          formikHelpers.validateForm();
          await handleSubmit(values);
        }}
      >
        {({ handleSubmit, isSubmitting }) => (
          <Form onSubmit={handleSubmit}>
            {writeMode === WriteModeEnum.create ? (
              !!filteredStudents &&
              !loading && (
                <Field
                  select
                  component={FormTextField}
                  name="studentTuid"
                  type="text"
                  label={texts.oasCommon.students.one}
                  disabled={isSubmitting}
                  fullWidth
                >
                  {filteredStudents.map((option) => (
                    <MenuItem key={option.tuid} value={option.tuid || ''}>
                      {`${option.details.firstName} ${option.details.lastName}`}
                    </MenuItem>
                  ))}
                </Field>
              )
            ) : (
              <>
                <Field
                  autoFocus={fieldName === FieldName.evaluation}
                  component={FormTextField}
                  name={FieldName.evaluation}
                  type="text"
                  label={texts.grades.gradesCommon.evaluation}
                  disabled={isSubmitting}
                  fullWidth
                />
                <Field
                  autoFocus={fieldName === FieldName.note}
                  component={FormTextField}
                  name={FieldName.note}
                  type="text"
                  label={texts.grades.gradesCommon.note}
                  disabled={isSubmitting}
                  fullWidth
                />
              </>
            )}
            <FlexBox justifyContent="flex-end">
              <Button color="secondary" disabled={isSubmitting} type="submit">
                {texts.planner.common.save}
              </Button>
              <Button onClick={onClose} color="secondary" simple>
                {texts.planner.common.cancel}
              </Button>
            </FlexBox>
          </Form>
        )}
      </FormController>
    </WriteTableModal>
  );
};
