import { CalendarEvent, ScheduleWorklistItem } from 'core/dna/types';

import { Schedule } from 'modules/planner/dna/types/schedule';
import { TimePlanState } from 'modules/planner/dna/types/local';
import { LessonGroup } from 'modules/planner/dna/types/lesson-group';

import { ExtractTimePlanOptions } from 'modules/planner/dna/types/extract-time-plan-options';

import { getConflictsFromLessonGroup } from './get-conflicts-from-lesson-group';
import { getEventDuration } from './get-event-duration';
import { getUnassignedLecturesFromLessonGroup } from './get-unassigned-lectures-from-lesson-group';
import { getCalendarEventsFromLessonGroup } from './get-calendar-events-from-lesson-group';

// const TITLE = 'extract-time-plan-from-schedule';

const getPlannedMinutes = (lessonGroup: LessonGroup) =>
  lessonGroup.plannedMinutesPerTimeSlot?.reduce((acc, cur) => acc + cur, 0) ??
  0;

const getAssignedMinutes = (lessonGroup: LessonGroup) =>
  lessonGroup.timeSlots
    ?.map((ts) => getEventDuration({ start: ts.startTime, end: ts.endTime }))
    .reduce((acc, cur) => acc + cur, 0) ?? 0;

const isUnassignedLessonGroup = (lessonGroup: LessonGroup) =>
  !lessonGroup.timeSlots?.length;

const isFullyAssignedLessonGroup = (lessonGroup: LessonGroup) =>
  getPlannedMinutes(lessonGroup) === getAssignedMinutes(lessonGroup);

const isPartiallyAssignedLessonGroup = (lessonGroup: LessonGroup) =>
  getPlannedMinutes(lessonGroup) > getAssignedMinutes(lessonGroup);

const withConflicts = (lessonGroup: LessonGroup) =>
  !!lessonGroup.timeSlots?.find((ts) => ts.aiHardConflicts?.length);

export const extractCalendarEventsFromSchedule = (
  lessonGroup: LessonGroup,
  options: ExtractTimePlanOptions,
): CalendarEvent[] => {
  if (!isUnassignedLessonGroup(lessonGroup)) {
    return getCalendarEventsFromLessonGroup(lessonGroup, options);
  }
  return [];
};

export const extractWorklistFromLessonGroup = (
  lessonGroup: LessonGroup,
  options: ExtractTimePlanOptions,
): ScheduleWorklistItem[] => {
  if (isUnassignedLessonGroup(lessonGroup)) {
    return getUnassignedLecturesFromLessonGroup(lessonGroup);
  }
  if (isFullyAssignedLessonGroup(lessonGroup) && withConflicts(lessonGroup)) {
    return getConflictsFromLessonGroup(lessonGroup, options);
  }
  if (isPartiallyAssignedLessonGroup(lessonGroup)) {
    let conflicts: ScheduleWorklistItem[] = [];
    if (withConflicts(lessonGroup)) {
      conflicts = getConflictsFromLessonGroup(lessonGroup, options);
    }
    return [...conflicts, ...getUnassignedLecturesFromLessonGroup(lessonGroup)];
  }
  return [];
};

export const extractTimePlanFromSchedule = (
  schedule: Schedule,
  options: ExtractTimePlanOptions,
): TimePlanState => {
  let calendarEvents: CalendarEvent[] = [];
  let worklist: ScheduleWorklistItem[] = [];

  schedule.lessonGroups?.forEach((lg) => {
    calendarEvents = [
      ...calendarEvents,
      ...extractCalendarEventsFromSchedule(lg, options),
    ];
    worklist = [...worklist, ...extractWorklistFromLessonGroup(lg, options)];
  });

  return {
    scheduleId: schedule.id,
    calendarEvents,
    worklist,
  };
};
