import { OrganizationState, RouteConfig } from 'core/dna/types/local';

export const isRouteAvailable = (
  route: RouteConfig,
  selectedOrg: OrganizationState,
) => {
  if (route.permissions === undefined) {
    return true;
  }

  return route.permissions.some((routePermission) =>
    selectedOrg.permissions.includes(routePermission),
  );
};
