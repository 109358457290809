import React from 'react';
import { Table } from 'templates/mdp/components/react-table';
import { Skeleton } from 'core/cells/skeleton';
import { Card, CardBody } from 'templates/mdp/components/card';
import { createReactTableStore } from 'core/memory/pure/react-table-store';
import { useGetTermsState } from 'core/memory/apollo/terms/local/get-terms-state';
import { useReactTableState } from 'core/metabolism/table-state';
import { JournalEntry } from 'core/dna/types/generated/JournalEntry';
import { useJournalsTableRead } from './use-journals-table-read';

interface UseJournalsTableProps {
  data: JournalEntry[] | undefined;
  loading: boolean;
}

export const journalsTableStore = createReactTableStore({}, {});

export const JournalsTable = (props: UseJournalsTableProps) => {
  const { data, loading } = props;

  const { columns, tableData } = useJournalsTableRead(data);
  const { data: termsState } = useGetTermsState();

  const {
    initialPage,
    pageSize,
    sort,
    filters,
    setSort,
    setFilters,
    setPage,
    setPageSize,
  } = useReactTableState(journalsTableStore, termsState?.selected ?? undefined);

  return loading || !termsState?.selected ? (
    <Skeleton variant="rect" width="100%" height="300px" />
  ) : (
    <Card>
      <CardBody>
        <Table
          noActions
          noPagination
          columns={columns}
          data={tableData}
          key={termsState?.selected ?? 'unknown_term'}
          onChangeRowsPerPage={setPageSize}
          onChangePage={setPage}
          onFilterChange={setFilters}
          onOrderChange={setSort}
          options={{
            initialPage,
            pageSize,
            sort,
            filters,
          }}
        />
      </CardBody>
    </Card>
  );
};
