import * as firebase from 'firebase/app';
import 'firebase/firestore';

import { runtimeEnv } from 'core/atoms/env';

const env = runtimeEnv();

// Initialize Firebase
firebase.initializeApp({
  apiKey: env.REACT_APP_FIREBASE_API_KEY,
  authDomain: env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: env.REACT_APP_FIREBASE_APP_ID,
  measurementId: env.REACT_APP_FIREBASE_MEASUREMENT_ID,
});

const db = firebase.firestore();

export const getSharedSchedule = (shareCode: string) => {
  return db.collection('sharedSchedules').doc(shareCode).get();
};
