import { AbsenceState } from 'core/dna/types/generated/_globalTypes';
import { Absence } from 'modules/student/dna/types';

export const calculateTotalDaysByAbsenceState = (
  absences: Absence[],
  state: AbsenceState,
) =>
  absences
    .filter((absence) => !!absence.wholeDay)
    .filter((absence) => absence.state === state).length;
