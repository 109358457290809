import { BehaviorSubject } from 'rxjs';
import { produce } from 'immer';

import { logger, LogType } from 'core/atoms/housekeeping';

import { LessonItem, LessonProperty } from '../types';

export interface LgcStoreState {
  plannedMinutesPerTimeSlot: number[];
  // mustBeNonstop: boolean;
  canBeSplit: boolean;
  errorPlannedMinutesPerTimeSlot: string | null;
  errorSubjects: string | null;
  lessons?: LessonItem[];
  subjects?: LessonProperty[];
  teachers?: LessonProperty[];
  rooms?: LessonProperty[];
  chapters?: LessonProperty[];
  selectedLessonId?: string;
}

const initialState: LgcStoreState = {
  plannedMinutesPerTimeSlot: [],
  errorPlannedMinutesPerTimeSlot: null,
  errorSubjects: null,
  lessons: undefined,
  subjects: undefined,
  teachers: undefined,
  rooms: undefined,
  chapters: undefined,
  // mustBeNonstop: false,
  canBeSplit: true,
  selectedLessonId: undefined,
};

const subject = new BehaviorSubject(initialState);

let state = initialState;

const emit = (state: LgcStoreState) => {
  subject.next(state);
};

const FILE_NAME = 'planner/plan/schedules/lesson-group-constructor/lgc-store';

export const lgcStore = {
  subscribe: (setState: (value: LgcStoreState) => void) =>
    subject.subscribe(setState),
  setLessons: (lessons?: LessonItem[]) => {
    state = produce(state, (draft) => {
      draft.lessons = lessons;
    });

    logger.debug({
      title: 'setLessons',
      logger: FILE_NAME,
      type: LogType.Info,
    });

    emit(state);
  },
  selectLesson: (lessonId: string) => {
    state = produce(state, (draft) => {
      draft.selectedLessonId = lessonId;
    });

    logger.debug({
      title: 'selectLesson',
      logger: FILE_NAME,
      type: LogType.Info,
    });

    emit(state);
  },
  setSubjects: (subjects?: LessonProperty[]) => {
    state = produce(state, (draft) => {
      draft.subjects = subjects;
    });

    logger.debug({
      title: 'setSubjects',
      logger: FILE_NAME,
      type: LogType.Info,
    });

    emit(state);
  },
  setTeachers: (teachers?: LessonProperty[]) => {
    state = produce(state, (draft) => {
      draft.teachers = teachers;
    });

    logger.debug({
      title: 'setTeachers',
      logger: FILE_NAME,
      type: LogType.Info,
    });

    emit(state);
  },
  setRooms: (rooms?: LessonProperty[]) => {
    state = produce(state, (draft) => {
      draft.rooms = rooms;
    });

    logger.debug({
      title: 'setRooms',
      logger: FILE_NAME,
      type: LogType.Info,
    });

    emit(state);
  },
  setChapters: (chapters?: LessonProperty[]) => {
    state = produce(state, (draft) => {
      // draft.chapters = [...new Set([...(action.chapters ?? [])])];
      draft.chapters = chapters;
    });

    logger.debug({
      title: 'setChapters',
      logger: FILE_NAME,
      type: LogType.Info,
    });

    emit(state);
  },
  setErrorSubjects: (errorSubjects: string | null) => {
    state = produce(state, (draft) => {
      draft.errorSubjects = errorSubjects;
    });

    logger.debug({
      title: 'setErrorSubjects',
      logger: FILE_NAME,
      type: LogType.Info,
    });

    emit(state);
  },
  setPlannedMinutesPerTimeSlot: (plannedMinutesPerTimeSlot: number[]) => {
    state = produce(state, (draft) => {
      draft.plannedMinutesPerTimeSlot = plannedMinutesPerTimeSlot;
    });

    logger.debug({
      title: 'setPlannedMinutesPerTimeSlot',
      logger: FILE_NAME,
      type: LogType.Info,
    });

    emit(state);
  },
  setErrorPlannedMinutesPerTimeSlot: (
    errorPlannedMinutesPerTimeSlot: string | null,
  ) => {
    state = produce(state, (draft) => {
      draft.errorPlannedMinutesPerTimeSlot = errorPlannedMinutesPerTimeSlot;
    });

    logger.debug({
      title: 'setErrorPlannedMinutesPerTimeSlot',
      logger: FILE_NAME,
      type: LogType.Info,
    });

    emit(state);
  },
  // setMustBeNonStop: (mustBeNonstop: boolean) => {
  //   state = produce(state, (draft) => {
  //     draft.mustBeNonstop = mustBeNonstop;
  //   });
  //
  //   logger.debug({
  //     title: 'setMustBeNonStop',
  //     logger: FILE_NAME,
  //     type: LogType.Info,
  //   });
  //
  //   emit(state);
  // },
  setCanBeSplit: (canBeSplit: boolean) => {
    state = produce(state, (draft) => {
      draft.canBeSplit = canBeSplit;
    });

    logger.debug({
      title: 'setCanBeSplit',
      logger: FILE_NAME,
      type: LogType.Info,
    });

    emit(state);
  },
  reset: () => {
    state = produce(state, (draft) => {
      draft.chapters = initialState.chapters;
      draft.rooms = initialState.rooms;
      draft.teachers = initialState.teachers;
      draft.subjects = initialState.subjects;
      draft.lessons = initialState.lessons;
      draft.plannedMinutesPerTimeSlot = initialState.plannedMinutesPerTimeSlot;
      draft.errorSubjects = initialState.errorSubjects;
      draft.errorPlannedMinutesPerTimeSlot =
        initialState.errorPlannedMinutesPerTimeSlot;
      draft.canBeSplit = initialState.canBeSplit;
    });

    logger.debug({
      title: 'reset',
      logger: FILE_NAME,
      type: LogType.Info,
    });

    emit(state);
  },
  initialState,
};
