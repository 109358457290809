import { useMemo } from 'react';
import moment from 'moment';

import { makeStyles, Theme, createStyles } from 'core/atoms/styles';

import { Box, FlexBox } from 'core/cells/box';

import { useParams } from 'core/dna/routing';
import { useTranslations } from 'core/dna/translations';

import { GridItem, GridContainer } from 'templates/mdp/components/grid';

import { useGetSubstituteJobs } from 'modules/substitute/memory/apollo/substitute-jobs/remote';

import { TasksTable } from './components/tasks-table';
import { TextItem } from '../../../../core/atoms/types';
import { Chip } from '../../../../core/cells/chip';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    mt0: {
      marginTop: 0,
    },
    mr05: {
      marginRight: theme.spacing(0.5),
    },
    leftLabel: {
      width: 100,
    },
  }),
);

export const JobDetails = () => {
  const { id: idParam } = useParams<{ id: string }>();
  const classes = useStyles();
  const { data } = useGetSubstituteJobs();
  const { texts } = useTranslations();

  const job = useMemo(
    () => (data ? data.find((i) => idParam && i.id === idParam) ?? null : null),
    [data, idParam],
  );

  const invited = useMemo<TextItem[] | undefined>(() => {
    if (job) {
      return job.invitations?.map((i) => ({
        id: i.teacher.id,
        text: `${i.teacher.firstName} ${i.teacher.lastName}`,
      }));
    }
  }, [job]);

  return job ? (
    <GridContainer>
      <GridItem xs={12}>
        <h1 className={classes.mt0}>{job.internalTitle}</h1>
      </GridItem>
      <GridItem xs={12}>
        <FlexBox fullSize={false}>
          <h6 className={classes.leftLabel}>{texts.oasCommon.deadline}:</h6>
          <h6>{moment(job.deadline).format('DD.MM.yyyy [kl.] HH:mm')}</h6>
        </FlexBox>
        <FlexBox fullSize={false}>
          <h5 className={classes.leftLabel}>{texts.oasCommon.description}:</h5>
          <h5>{job.description}</h5>
        </FlexBox>
      </GridItem>
      <GridItem xs={12}>
        <Box mt={5}>
          <TasksTable jobId={job.id} />
        </Box>
      </GridItem>
      {invited ? (
        <GridItem xs={12}>
          <FlexBox>
            <h5 className={classes.leftLabel}>{texts.substitute.invited}: </h5>
            {invited.map((item) => (
              <Chip
                key={item.id}
                label={item.text}
                size="small"
                color="primary"
                className={classes.mr05}
              />
            ))}
          </FlexBox>
        </GridItem>
      ) : (
        <></>
      )}
    </GridContainer>
  ) : (
    <></>
  );
};
