import { useMemo } from 'react';
import { Close } from '@material-ui/icons';

import { makeStyles, createStyles } from 'core/atoms/styles';
import { orderBy } from 'core/atoms/functions/array';

import { Grid } from 'core/cells/grid';
import { Paper } from 'core/cells/paper';
import { FlexBox } from 'core/cells/box';
import { ChipInput } from 'core/cells/chip-input';

import { useTranslations } from 'core/dna/translations';

import { Button } from 'templates/mdp/components/custom-buttons';

import { Union } from 'modules/planner/dna/types/union';

import { useSelectedClass } from 'modules/planner/metabolism/use-selected-class';

import { Chapters } from '../../../components/chapters';

import { useGroupsEditor } from './use-groups-editor';

const useStyles = makeStyles((theme) =>
  createStyles({
    paper: {
      padding: theme.spacing(2),
    },
    flex: {
      display: 'flex',
    },
    expanded: {
      flexGrow: 1,
    },
    ml: {
      marginLeft: theme.spacing(1),
    },
    chaptersRoot: {
      width: '100%',
      padding: '5px 0 0',
    },
    chapter: {
      height: 54,
      margin: '11px 4px',
    },
    editorInputRoot: {
      '&.MuiOutlinedInput-root': {
        paddingTop: theme.spacing(1),
        paddingLeft: theme.spacing(1),
      },
    },
    addUnionButton: {
      marginLeft: theme.spacing(1),
      width: 290,
      height: '100%',
    },
    pb2: {
      paddingBottom: theme.spacing(2),
    },
  }),
);

export const GroupsEditor = () => {
  const classes = useStyles();
  const { lang } = useTranslations();
  const { selectedClass, loading, error } = useSelectedClass();

  const {
    addChapterToExistingUnion,
    deleteChapterFromExistingUnion,
    deleteExistingUnion,
    newChaptersInputRef,
    newChapters,
    newChaptersError,
    unionErrors,
    addNewChapter,
    deleteNewChapter,
    addNewUnion,
    clearNewChapters,
  } = useGroupsEditor();

  if (loading) return <p>Classes loading...</p>;
  if (error) return <p>Classes error!</p>;
  if (!selectedClass) return <p>Class is not found!</p>;

  const ClassUnion = (props: { classId: string; union: Union }) => {
    const { union, classId } = props;

    const error = useMemo(() => unionErrors?.find((ue) => ue === union.id), [
      union.id,
    ]);

    return (
      <ChipInput
        key={union.id}
        fullWidth
        variant="outlined"
        classes={{ root: classes.editorInputRoot }}
        value={orderBy(union.chapters?.map((ch) => ch.name))}
        onAdd={addChapterToExistingUnion}
        onDelete={(chapter: string) =>
          deleteChapterFromExistingUnion(classId, chapter, union.chapters)
        }
        error={!!error}
        helperText={
          error
            ? lang.sourceDataSpace.classesModule
                .cannotRemoveChapterFromUnionOfTwoOrLessMessage
            : undefined
        }
      />
    );
  };

  return (
    <Grid container spacing={1}>
      <Grid item lg={4} sm={12}>
        <FlexBox justifyContent="center" alignItems="flex-start">
          <Chapters
            className={classes.chaptersRoot}
            unions={selectedClass.unions}
            classes={{ chapter: classes.chapter }}
          />
        </FlexBox>
      </Grid>
      <Grid item lg={8} sm={12}>
        <Paper className={classes.paper}>
          <Grid container spacing={1}>
            {selectedClass.unions?.map((u) => (
              <Grid item xs={12} key={u!.id}>
                <FlexBox className={classes.pb2}>
                  <ClassUnion classId={selectedClass.id} union={u} />
                  <Button
                    justIcon
                    round
                    simple
                    onClick={() => deleteExistingUnion(selectedClass.id, u)}
                    color="danger"
                    className="remove"
                  >
                    <Close />
                  </Button>
                </FlexBox>
              </Grid>
            ))}
            <Grid item xs={12} key="new-union">
              <FlexBox className={classes.pb2}>
                <ChipInput
                  inputRef={newChaptersInputRef}
                  fullWidth
                  variant="outlined"
                  classes={{ inputRoot: classes.editorInputRoot }}
                  value={newChapters}
                  onAdd={addNewChapter}
                  onDelete={deleteNewChapter}
                  blurBehavior="add"
                  error={!!newChaptersError}
                  helperText={newChaptersError}
                />
                <Button
                  color="secondary"
                  variant="contained"
                  className={classes.addUnionButton}
                  onClick={() => addNewUnion(selectedClass.id)}
                >
                  {lang.sourceDataSpace.classesModule.addUnion}
                </Button>
                <Button
                  color="transparent"
                  variant="contained"
                  className={classes.ml}
                  onClick={clearNewChapters}
                >
                  {lang.common.clear}
                </Button>
              </FlexBox>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
};
