import { makeStyles, createStyles, CSSProperties } from 'core/atoms/styles';

import {
  defaultFont,
  container,
  containerFluid,
  primaryColor,
  whiteColor,
  grayColor,
} from 'templates/mdp/material-dashboard-pro-react';

export interface FooterStyle {
  block: CSSProperties;
  left: CSSProperties;
  right: CSSProperties;
  footer: CSSProperties;
  container: CSSProperties;
  containerFluid: CSSProperties;
  a: CSSProperties;
  list: CSSProperties;
  inlineBlock: CSSProperties;
  whiteColor: CSSProperties;
}

export const footerStyle: FooterStyle = {
  block: {},
  left: {
    float: [['left'], '!important'] as any,
    display: 'block',
  },
  right: {
    margin: '0',
    fontSize: '14px',
    float: [['right'], '!important'] as any,
    padding: '15px',
  },
  footer: {
    bottom: '0',
    borderTop: `1px solid ${grayColor[15]}`,
    padding: '15px 0',
    ...defaultFont,
    zIndex: 4,
  },
  container: {
    zIndex: 3,
    ...container,
    position: 'relative',
  },
  containerFluid: {
    zIndex: 3,
    ...containerFluid,
    position: 'relative',
  },
  a: {
    color: primaryColor[0],
    textDecoration: 'none',
    backgroundColor: 'transparent',
  },
  list: {
    marginBottom: '0',
    padding: '0',
    marginTop: '0',
  },
  inlineBlock: {
    display: 'inline-block',
    padding: '0',
    width: 'auto',
  },
  whiteColor: {
    '&,&:hover,&:focus': {
      color: whiteColor,
    },
  },
};

export const useStyles = makeStyles(() => createStyles({ ...footerStyle }));
