import { useMemo } from 'react';

import { useTranslations } from 'core/dna/translations';

import { useGetSubstituteJobs } from 'modules/substitute/memory/apollo/substitute-jobs/remote';
import { SubstituteTaskTableItem } from '../../../../dna/types/substitute-task';
import { formatTimeString } from '../../../../../../core/atoms/functions/string';

export const useTasksTableRead = (jobId: string) => {
  const { texts } = useTranslations();
  const { data: jobs, loading } = useGetSubstituteJobs();

  const columns = useMemo(
    () => [
      {
        Header: texts.oasCommon.dates.one,
        accessor: 'date',
      },
      {
        Header: texts.oasCommon.from,
        accessor: 'timeFrom',
      },
      {
        Header: texts.oasCommon.to,
        accessor: 'timeTo',
      },
      {
        Header: texts.oasCommon.subjects.one,
        accessor: 'subject',
      },
      {
        Header: texts.oasCommon.comments.one,
        accessor: 'comment',
      },
      {
        Header: texts.substitute.assignedTo,
        accessor: 'assignedTo',
      },
    ],
    [texts],
  );

  const job = useMemo(() => {
    return jobs ? jobs.find((i) => jobId && i.id === jobId) ?? null : null;
  }, [jobs, jobId]);

  const data = useMemo(
    () =>
      job?.tasks?.map((t) => {
        const positiveResponse = t.responses?.find((r) => r.response === 'yes');
        return {
          id: t.id,
          date: t.date,
          timeFrom: formatTimeString(t.timeFrom),
          timeTo: formatTimeString(t.timeTo),
          assignedTo: positiveResponse
            ? `${positiveResponse.teacher.firstName} ${positiveResponse.teacher.lastName}`
            : '',
          subject: t.subject,
          comment: t.comment,
        } as SubstituteTaskTableItem;
      }) ?? [],
    [job?.tasks],
  );

  return {
    title: texts.oasCommon.tasks.many,
    columns,
    data,
    loading,
  };
};
