/* eslint-disable */
import React from 'react';
import clsx from 'clsx';

import LinearProgress, {
  LinearProgressProps,
} from '@material-ui/core/LinearProgress';

import { useStyles } from './custom-linear-progress.styles';

interface OmittedProps {
  color?: any;
}

export interface CustomLinearProgressProps
  extends Omit<LinearProgressProps, keyof OmittedProps> {
  color?:
    | 'primary'
    | 'warning'
    | 'danger'
    | 'success'
    | 'info'
    | 'rose'
    | 'gray'; // PropTypes.oneOf([]),
}
const defaultProps: Partial<CustomLinearProgressProps> = {
  color: 'gray',
};

export const CustomLinearProgress = (props: CustomLinearProgressProps) => {
  const classes = useStyles();
  const { color = defaultProps.color!, ...rest } = props;
  return (
    <LinearProgress
      {...rest}
      classes={{
        root: clsx(classes.root, (classes as any)[color + 'Background'] ?? ''),
        bar: clsx(classes.bar, classes[color]),
      }}
    />
  );
};

CustomLinearProgress.defaultProps = defaultProps;
