import { Identity } from 'core/atoms/types';

import { OrganizationCategoryType } from './generated/_globalTypes';

export enum AccessRoleEnum {
  admin = 'admin',
  carer = 'carer',
  rootAdmin = 'rootAdmin',
  staff = 'staff',
  student = 'student',
  system = 'system',
}

export enum OasPermissions {
  planner = 'planner',
  substitute = 'substitute',
  absence = 'absence',
  remarks = 'remarks',
  messageBook = 'messageBook',
  accessToOas = 'accessToOas',
}

export interface AccessRoles extends Identity {
  name?: string;
  accessRoles?: string[];
  permissions?: string[];
  category?: OrganizationCategoryType;
}

export interface RootAccessRoles extends AccessRoles {
  organizations?: AccessRoles[];
}
