import { useCallback } from 'react';

import { FlexBox } from 'core/cells/box';
import { Field, Form, FormController } from 'core/cells/forms';
import { FormTextField } from 'core/cells/form-text-field';
import { MenuItem } from 'core/cells/menu-item';

import { useTranslations } from 'core/dna/translations';

import { Button } from 'templates/mdp/components/custom-buttons';

import { WriteModeEnum } from 'core/dna/types/write-mode';

import { useGetTeachers } from 'modules/planner/memory/apollo/teachers/remote';

import { WriteTableModal } from 'core/organisms/write-modal';
import { useTeacherTableWrite } from 'modules/grades/metabolism';

export interface EvaluationGroupAccessCreatorData {
  evaluationGroupId: string;
  tuid: string | null;
  evaluator: boolean;
  selectedTeachersIds: string[];
}

interface Values {
  evaluationGroupId: string;
  tuid: string;
  evaluator: boolean;
}

interface EvaluationGroupCreatorProps {
  data: EvaluationGroupAccessCreatorData;
  open: boolean;
  setOpen: (open: boolean) => void;
  // onSubmit: (id: string) => void;
}

export const EvaluationGroupAccessCreator = (
  props: EvaluationGroupCreatorProps,
) => {
  const { open, setOpen, data } = props;
  const { onAddAccess } = useTeacherTableWrite();
  const { texts } = useTranslations();
  const { teachers, teachersLoading } = useGetTeachers();

  const filteredTeachers = teachers?.filter(
    (t) => !data.selectedTeachersIds.includes(t?.person?.tuid || ''),
  );

  const onClose = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  const handleSubmit = async (values: Values) => {
    await onAddAccess({
      ...values,
      evaluator: values.evaluator,
    });
    setOpen(false);
  };

  return (
    <WriteTableModal
      width={420}
      isShow={open ?? false}
      onClose={onClose}
      writeMode={WriteModeEnum.create}
      title={texts.planner.common.add}
    >
      <FormController
        initialValues={{
          tuid: data.tuid || '',
          evaluator: data.evaluator,
          evaluationGroupId: data.evaluationGroupId,
        }}
        validate={(values) => {
          const errors: Partial<Values> = {};
          if (!values.tuid) {
            errors.tuid = texts.oasCommon.validation.required;
          }
          return errors;
        }}
        onSubmit={async (values) => {
          await handleSubmit(values);
        }}
      >
        {({ submitForm, isSubmitting }) => (
          <Form>
            {!!filteredTeachers?.length && !teachersLoading && (
              <Field
                select
                component={FormTextField}
                name="tuid"
                type="text"
                label={texts.planner.common.teachers}
                disabled={isSubmitting}
                fullWidth
              >
                {filteredTeachers.map((option) => (
                  <MenuItem key={option.id} value={option.person?.tuid}>
                    {option.person?.name}
                  </MenuItem>
                ))}
              </Field>
            )}
            <FlexBox justifyContent="flex-end">
              <Button
                color="secondary"
                disabled={isSubmitting}
                onClick={submitForm}
              >
                {texts.planner.common.add}
              </Button>
              <Button onClick={onClose} color="secondary" simple>
                {texts.planner.common.close}
              </Button>
            </FlexBox>
          </Form>
        )}
      </FormController>
    </WriteTableModal>
  );
};
