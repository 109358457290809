import { useMemo, useCallback } from 'react';

import { parseTemplate } from 'core/atoms/functions/string';

import { useHistory, useLocale } from 'core/dna/routing';
import { useTranslations } from 'core/dna/translations';

import { Class } from 'modules/planner/dna/types/class';
import { StudentsTableItem } from 'modules/planner/dna/types/student';

import { useClasses } from 'modules/planner/metabolism/use-classes';

import PlannerUrls from 'modules/planner/routing/urls';

import { ChaptersTooltipButton } from 'modules/planner/pages/source-data/classes/components/chapters-tooltip-button';

export interface ColStudentGroupsProps {
  item?: StudentsTableItem;
}

export const ColStudentGroups = (props: ColStudentGroupsProps) => {
  const { item } = props;

  const { texts } = useTranslations();
  const history = useHistory();
  const locale = useLocale();

  const { setSelectedClasses, classData } = useClasses();

  const selectedClass = useMemo(
    () => classData.find((c) => c.id === item?.classId),
    [classData, item],
  );

  const text = useMemo(() => {
    if (!item?.chaptersCount || item.chaptersCount < 2) {
      return texts.planner.common.manageCount.one;
    }
    return parseTemplate(texts.planner.common.manageCount.many, {
      count: item.chaptersCount,
    });
  }, [item, texts]);

  const handleClick = useCallback((itemId: string) => {
    // setSelectedClasses([itemId]);
    // history.push(PlannerUrls.classesGroups(locale));
  }, []);

  return item ? (
    <ChaptersTooltipButton
      itemId={item.id}
      unions={selectedClass?.unions}
      selectedChapters={item.chapters}
      text={text}
      onClick={handleClick}
    />
  ) : (
    <span> </span>
  );
};
