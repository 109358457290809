import { useCallback } from 'react';

import { OasError, OasPageError } from 'core/atoms/errors';
import { parseTemplate } from 'core/atoms/functions/string';

import { useSnackbar } from 'core/cells/notistack';

import { useTranslations } from 'core/dna/translations';

import { ScheduleTableItem } from 'modules/planner/dna/types/schedule';

import {
  useCreateSchedule,
  useEditSchedule,
  useDeleteSchedule,
  useDuplicateSchedule,
} from 'modules/planner/memory/apollo/schedules/remote';

const TITLE = 'Use SchedulesTableWrite';

export const useSchedulesTableWrite = () => {
  const { texts } = useTranslations();
  const { enqueueSnackbar } = useSnackbar();

  const { createSchedule } = useCreateSchedule();
  const { editSchedule } = useEditSchedule();
  const { deleteSchedule } = useDeleteSchedule();
  const { duplicateSchedule } = useDuplicateSchedule();

  const onRowAdd = useCallback(
    async (data: { name: string }) => {
      try {
        await createSchedule(data.name);
        enqueueSnackbar(
          parseTemplate(texts.oasCommon.entityCreated, {
            entityType: texts.oasCommon.schedules.one,
          }),
          {
            variant: 'success',
          },
        );
      } catch (e: any) {
        if (e instanceof OasError) {
          throw e;
        }
        throw OasPageError.fromError(e, { title: TITLE });
      }
    },
    [
      createSchedule,
      enqueueSnackbar,
      texts.oasCommon.entityCreated,
      texts.oasCommon.schedules.one,
    ],
  );

  const onRowUpdate = useCallback(
    async (data: { id: string; name?: string }) => {
      try {
        await editSchedule({ scheduleId: data.id, name: data.name });
      } catch (e: any) {
        if (e instanceof OasError) {
          throw e;
        }
        throw OasPageError.fromError(e, { title: TITLE });
      }
    },
    [editSchedule],
  );

  const onRowDelete = useCallback(
    async (oldData: ScheduleTableItem) => {
      try {
        await deleteSchedule({ scheduleId: oldData.id });
        enqueueSnackbar(
          parseTemplate(texts.oasCommon.entitiesOfTypeWereDeleted.one, {
            entityType: texts.oasCommon.schedules.one,
            entity: oldData.name,
          }),
          {
            variant: 'success',
          },
        );
      } catch (e: any) {
        enqueueSnackbar(
          parseTemplate(texts.oasCommon.deleteEntitiesOfTypeError.one, {
            entityType: texts.oasCommon.schedules.one,
            entity: oldData.name,
          }),
          {
            variant: 'error',
          },
        );
        if (e instanceof OasError) {
          throw e;
        }
        throw OasPageError.fromError(e, { title: TITLE });
      }
    },
    [
      deleteSchedule,
      enqueueSnackbar,
      texts.oasCommon.deleteEntitiesOfTypeError.one,
      texts.oasCommon.entitiesOfTypeWereDeleted.one,
      texts.oasCommon.schedules.one,
    ],
  );

  const onRowDuplicate = useCallback(
    async (name: string, sourceScheduleId: string) => {
      try {
        await duplicateSchedule({ name, sourceScheduleId });
      } catch (e: any) {
        if (e instanceof OasError) {
          throw e;
        }
        throw OasPageError.fromError(e, { title: TITLE });
      }
    },
    [duplicateSchedule],
  );

  return {
    onRowAdd,
    onRowUpdate,
    onRowDelete,
    onRowDuplicate,
  };
};
