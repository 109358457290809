import React, { useEffect, useMemo, useRef, useState } from 'react';
import clsx from 'clsx';
import moment from 'moment';

import { FlexBox, Box } from 'core/cells/box';
import { FormTextField } from 'core/cells/form-text-field';
import { FormController, Form, Field } from 'core/cells/forms';
import { SubstituteJobState } from 'core/dna/types/generated/_globalTypes';
import { Grid } from 'core/cells/grid';
import { ConfirmationDialog } from 'core/cells/confirmation-dialog';
import { parseTemplate } from 'core/atoms/functions/string';
import { createStyles, makeStyles } from 'core/atoms/styles';

import { useParams, useLocale } from 'core/dna/routing';
import { useTranslations } from 'core/dna/translations';

import { useOasHistory } from 'core/metabolism/use-oas-history';

import { Card, CardBody } from 'templates/mdp/components/card';
import { Button } from 'templates/mdp/components/custom-buttons';

import URLS from 'modules/substitute/urls';

import {
  useGetSubstituteJobs,
  usePublishSubstituteJob,
} from 'modules/substitute/memory/apollo/substitute-jobs/remote';
import { Tasks } from 'modules/substitute/organisms/tasks';
import { Invitations } from 'modules/substitute/organisms/invitations';
import { sortByDateTime } from 'modules/substitute/dna/functions/sort-by-date';
import { useJobsTableWrite } from 'modules/substitute/metabolism/use-jobs-table-write';

interface Values {
  internalTitle: string;
  description: string;
  deadline: string;
}

const useStyles = makeStyles(() =>
  createStyles({
    dateTimeField: {
      '& .MuiInputBase-input': {
        color: 'transparent',
      },
      '& .Mui-focused .MuiInputBase-input': {
        color: 'currentColor',
      },
    },
    dateTimeFieldFilled: {
      '& .MuiInputBase-input': {
        color: 'currentColor',
      },
    },
  }),
);

export const EditJob = () => {
  const classes = useStyles();

  const { id: idParam } = useParams<{ id: string }>();
  const { onRowUpdate } = useJobsTableWrite();
  const { texts } = useTranslations();
  const { replace } = useOasHistory();
  const locale = useLocale();
  const { data, error } = useGetSubstituteJobs();
  const { publishSubstituteJob } = usePublishSubstituteJob();

  const formRef = useRef<any>(null);

  const [showConfirmPublishModal, setShowConfirmPublishModal] = useState(false);
  const [isPublish, setIsPublish] = useState(false);

  const job = useMemo(() => {
    const job = data
      ? data.find((i) => idParam && i.id === idParam) ?? null
      : null;

    if (!job?.tasks?.length) {
      return job;
    }

    const { tasks } = job;
    tasks.sort((a, b) =>
      sortByDateTime(`${a.date}T${a.timeFrom}`, `${b.date}T${b.timeFrom}`),
    );

    job.tasks = tasks;

    return job;
  }, [data, idParam]);

  const [deadline, setDeadline] = useState(
    !job?.tasks?.length ? '' : `${job.tasks[0].date}T${job.tasks[0].timeFrom}`,
  );

  useEffect(() => {
    if (job?.tasks?.length) {
      setDeadline(`${job.tasks[0].date}T${job.tasks[0].timeFrom}`);
    }
  }, [job, job?.tasks]);

  const onClose = async () => {
    if (!formRef.current) {
      return;
    }
    setIsPublish(false);
    await formRef.current.handleSubmit();
  };

  const handleConfirmClosePublish = async (confirmed: boolean) => {
    setShowConfirmPublishModal(false);

    if (!confirmed) {
      return;
    }

    if (formRef.current) {
      setIsPublish(true);
      await formRef.current.handleSubmit();
    }
  };

  if (error) {
    replace(URLS.jobs(locale));
    return;
  }

  const validateDeadline = (deadline: string) => {
    if (job?.tasks?.length) {
      return moment(deadline).isAfter(
        `${job.tasks[0].date}T${job.tasks[0].timeFrom}`,
      );
    }
  };

  return job ? (
    <>
      <FormController
        enableReinitialize
        innerRef={formRef}
        initialValues={{
          description: job.description,
          internalTitle: job.internalTitle,
          deadline,
        }}
        validate={(values) => {
          const errors: Partial<Values> = {};
          if (!values.description) {
            errors.description = texts.oasCommon.validation.required;
          }
          if (!values.internalTitle) {
            errors.internalTitle = texts.oasCommon.validation.required;
          }
          if (!values.deadline) {
            errors.deadline = texts.oasCommon.validation.required;
          }
          if (values.deadline && validateDeadline(values.deadline)) {
            errors.deadline = texts.oasCommon.validation.substituteDeadline;
          }
          return errors;
        }}
        onSubmit={async (values) => {
          if (job?.id) {
            if (job.state === SubstituteJobState.draft) {
              await onRowUpdate({
                substituteJobId: job.id,
                description: values.description,
                internalTitle: values.internalTitle,
                deadline: values.deadline,
              });
              if (isPublish) {
                await publishSubstituteJob({ substituteJobId: job.id });
              }
            }
            replace(URLS.jobs(locale));
          }
        }}
      >
        {({ submitForm, isSubmitting, values }) => (
          <Form>
            <Grid container>
              <Grid item xs={12} sm={6}>
                <Field
                  component={FormTextField}
                  name="internalTitle"
                  type="text"
                  label={texts.oasCommon.title}
                  disabled={isSubmitting}
                  fullWidth
                />
                <Field
                  component={FormTextField}
                  name="description"
                  type="text"
                  label={texts.planner.common.notes.one}
                  disabled={isSubmitting}
                  fullWidth
                />
                <Card>
                  <CardBody>
                    <Tasks substituteJobId={job!.id} data={job!.tasks} />
                  </CardBody>
                </Card>
                <Field
                  component={FormTextField}
                  name="deadline"
                  type="datetime-local"
                  label={texts.oasCommon.deadline}
                  disabled={isSubmitting}
                  fullWidth
                  inputProps={{
                    step: 900,
                  }}
                  className={clsx(
                    classes.dateTimeField,
                    values.deadline ? classes.dateTimeFieldFilled : '',
                  )}
                />
              </Grid>
            </Grid>
            <Invitations substituteJobId={job!.id} data={job!.invitations} />
            <Box mb={5}>
              <FlexBox justifyContent="flex-end">
                <Button
                  color="secondary"
                  disabled={
                    isSubmitting ||
                    !job?.invitations?.length ||
                    !job.tasks?.length
                  }
                  onClick={() => {
                    setShowConfirmPublishModal(true);
                  }}
                >
                  {texts.oasCommon.publish}
                </Button>
                <Button onClick={onClose} color="secondary" simple>
                  {texts.oasCommon.saveDtaftReturn}
                </Button>
              </FlexBox>
            </Box>
          </Form>
        )}
      </FormController>
      <ConfirmationDialog
        id="publish-confirmation"
        onClose={handleConfirmClosePublish}
        open={showConfirmPublishModal}
        title={texts.substitute.confirm.publish.title}
        content={parseTemplate(texts.substitute.confirm.publish.body, {
          entity: !job.invitations?.length ? '0' : job.invitations?.length,
        })}
        okText={texts.planner.common.yes}
        cancelText={texts.planner.common.no}
      />
    </>
  ) : (
    <> </>
  );
};
