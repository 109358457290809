import { useCallback } from 'react';

import { Link } from 'core/cells/link';
import { Typography } from 'core/cells/typography';

import { useLocale } from 'core/dna/routing';

import { useOasHistory } from 'core/metabolism/use-oas-history';

import { StudentBasicData } from 'modules/student/dna/types';

import StudentUrls from 'modules/student/urls';

interface DisplayCellDataProps {
  value: string;
  row: any;
  data: StudentBasicData[];
}

export const DisplayCellData = (props: DisplayCellDataProps) => {
  const { row, value, data } = props;
  const { push } = useOasHistory();
  const locale = useLocale();

  const handleClick = useCallback(() => {
    return push(
      StudentUrls.studentCard({ id: data[row.index].tuid as OasUUID, locale }),
    );
  }, [locale, push, row, data]);

  return (
    <Link onClick={() => handleClick()}>
      <Typography>{value}</Typography>
    </Link>
  );
};
