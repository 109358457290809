import { useCallback, useRef, useEffect } from 'react';

import { logger } from 'core/atoms/housekeeping';
import { OasAuthError } from 'core/atoms/errors';

import {
  AuthorizationErrorCallback,
  LogoutCallback,
  LockCallback,
  LockErrorCallback,
} from 'auth/dna/types';

import { getStatusStorage } from 'auth/memory/browser';

import { useAuthContext } from './use-auth-context';

export const useLock = (
  callback: LogoutCallback,
  errorCallback?: AuthorizationErrorCallback,
) => {
  const { lock, getIdentity, removeAccessToken } = useAuthContext();
  const { getStatus, setStatus } = getStatusStorage();

  const savedCallback = useRef<LockCallback>();
  const savedErrorCallback = useRef<LockErrorCallback>();

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    savedErrorCallback.current = errorCallback;
  }, [errorCallback]);

  const func = useCallback(() => {
    const status = getStatus();
    const identity = getIdentity();
    if (identity) {
      lock()
        .then((s) => {
          if (s === 'lock') {
            savedCallback.current?.();
          } else {
            logger.debug({
              title: 'auth context lock()',
              logger: 'use-lock',
              value: String(s),
            });
          }
        })
        .catch((reason) => {
          setStatus(null);
          removeAccessToken();
          savedErrorCallback.current?.(
            new OasAuthError(reason?.message ?? reason, { data: { status } }),
          );
        });
    } else if ((status ?? null) !== null) {
      setStatus(null);
    }
  }, [getIdentity, getStatus, lock, removeAccessToken, setStatus]);

  return {
    lock: func,
  };
};
