import { useCallback } from 'react';

import { DataItem } from 'core/atoms/types';
import { groupBy } from 'core/atoms/functions/array';
import { round } from 'core/atoms/functions/math';

import { useTranslations } from 'core/dna/translations';
import { mapNullableListToList } from 'core/dna/functions';
import { RoleType } from 'core/dna/types/generated/_globalTypes';

import { Teacher } from 'modules/planner/dna/types/teacher';
import { LessonGroup } from 'modules/planner/dna/types/lesson-group';

import { useGetSchedule } from 'modules/planner/memory/apollo/schedules/remote';
import { useGetTeachers } from 'modules/planner/memory/apollo/teachers/remote';

interface RowData {
  id: string;
  name: string;
  type: RoleType;
  sumWeekHours: number;
  utilizationRate?: number;
}

export interface TeachingTimeTableItem {
  id: string;
  teacher: RowData;
  subjects: RowData[];
}

interface LessonItem {
  id: string;
  subject: DataItem<number>;
  minutes: number[];
}

const getTeacherLessons = (teacher: Teacher, lessonGroups: LessonGroup[]) => {
  let lessons: LessonItem[] = [];
  lessonGroups.forEach((lg) => {
    if (lg.lessons?.length) {
      lessons = [
        ...lessons,
        ...(mapNullableListToList(
          lg.lessons
            .filter((l) => l.teachers?.find((t) => t.id === teacher.id))
            .map((l) => {
              if (l.subjects?.length) {
                return {
                  id: l.subjects[0].id,
                  subject: {
                    id: l.subjects[0].id,
                    text: l.subjects[0].name,
                    data: l.subjects[0].annualHours ?? 0,
                  },
                  minutes: lg.plannedMinutesPerTimeSlot,
                } as LessonItem;
              }
              return null;
            }),
          (item) => item ?? undefined,
        ) ?? []),
      ];
    }
  });
  return Object.values(groupBy(lessons, 'id')).map((vArr) =>
    vArr.reduce(
      (acc, cur) => ({
        id: cur.id,
        subject: cur.subject,
        minutes: [...(acc.minutes ?? []), ...cur.minutes],
      }),
      {} as LessonItem,
    ),
  );
};

const getUtilizationRate = (input: {
  employmentPercentage: number;
  annualHours: number;
  sumWeekHours: number;
}) => {
  if (input.annualHours === 0 || input.employmentPercentage === 0) {
    return 0;
  }
  const employmentPart = input.employmentPercentage / 100;
  /** 38 - Number of weeks of teaching in a school year */
  return round(
    (38 / (input.annualHours * employmentPart)) * input.sumWeekHours * 100,
    2,
  );
};

export const useTeachingTimeTable = (scheduleId: string) => {
  const { texts } = useTranslations();
  const { data: schedule, loading } = useGetSchedule({ scheduleId });
  const { teachers, teachersError } = useGetTeachers();

  const getData = useCallback(() => {
    const data: TeachingTimeTableItem[] = [];
    if (teachers?.length && !teachersError) {
      let item: TeachingTimeTableItem;
      teachers.forEach((t) => {
        const lessons = getTeacherLessons(t, schedule?.lessonGroups ?? []);
        if (lessons.length) {
          item = {
            id: t.id,
            teacher: {
              id: t.id,
              name: `${t.person?.firstName} ${t.person?.lastName}`,
              type: t.type ?? RoleType.teacher,
              sumWeekHours: 0,
            },
            subjects: lessons.map((l) => {
              const sumWeekHours =
                l.minutes.reduce((acc, cur) => acc + cur, 0) / 60;
              return {
                id: l.subject.id,
                name: l.subject.text,
                type: t.type ?? RoleType.teacher,
                sumWeekHours: round(sumWeekHours, 2),
                utilizationRate: getUtilizationRate({
                  annualHours: l.subject.data ?? 0,
                  employmentPercentage: t.employmentPercentage ?? 100,
                  sumWeekHours,
                }),
              };
            }),
          };
          if (t.type === RoleType.teacher) {
            item.subjects.push({
              id: `other-tasks-${t.id}`,
              name: texts.planner.otherAssignments,
              type: t.type ?? RoleType.teacher,
              sumWeekHours: 0,
              utilizationRate:
                t.reservedEmploymentPercentage &&
                t.reservedEmploymentPercentage > 0
                  ? round(t.reservedEmploymentPercentage, 2)
                  : 0,
            });
          }
          item.teacher.sumWeekHours = round(
            item.subjects
              .map((s) => s.sumWeekHours)
              .reduce((acc, cur) => acc + cur, 0),
            2,
          );
          if (t.type === RoleType.teacher) {
            item.teacher.utilizationRate = round(
              item.subjects
                .map((s) => s.utilizationRate)
                .reduce((acc: number, cur) => (cur ? acc + cur : acc), 0),
              2,
            );
          }

          data.push(item);
        }
      });
    }
    return data.sort((i1, i2) => (i1.teacher.name < i2.teacher.name ? -1 : 1));
  }, [schedule?.lessonGroups, teachers, teachersError, texts]);

  return {
    data: getData(),
    loading,
  };
};
