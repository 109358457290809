import { AuthIdentity } from 'auth/dna/types';
import { parseJwt } from 'auth/dna/functions/parse-jwt';

export const mapTokensToAuthIdentity = (
  accessToken: string,
  sessionToken: string,
): AuthIdentity | null => {
  if (!accessToken || !sessionToken) {
    return null;
  }
  const accessTokenDecoded = parseJwt(accessToken);
  const sessionTokenDecoded = parseJwt(sessionToken);

  return {
    token: accessToken,
    expiredAt: Number(accessTokenDecoded['exp']),
    issuedAt: Number(accessTokenDecoded['iat']),
    sub: accessTokenDecoded['sub'],
    iss: accessTokenDecoded['iss'],
    jti: accessTokenDecoded['jti'],
    hash: accessTokenDecoded['hash'],
    provider:
      String(accessTokenDecoded['iss'])?.indexOf('auth-sms') > -1 ? 't1' : 't2',
    session: {
      token: sessionToken,
      expiredAt: Number(sessionTokenDecoded['exp']),
      issuedAt: Number(sessionTokenDecoded['iat']),
      sub: sessionTokenDecoded['sub'],
      iss: sessionTokenDecoded['iss'],
      jti: sessionTokenDecoded['jti'],
    },
  };
};
