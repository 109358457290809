import React from 'react';
import { Field, Form, FormController } from 'core/cells/forms';
import { FormTextField } from 'core/cells/form-text-field';
import { FlexBox } from 'core/cells/box';

import { Button } from 'templates/mdp/components/custom-buttons';

import { JournalType } from 'core/dna/types/generated/_globalTypes';
import { useTranslations } from 'core/dna/translations';
import { mapTimeStringToDateTime } from 'core/atoms/functions/string';
import { useStudentsTableWrite } from '../use-students-table-write';

interface Values {
  from: string;
  to: string;
  info: string;
}

interface JournalSleepProps {
  tuid: string | null;
  setOpen: (open: boolean) => void;
  resetJournalType: (selectedType: JournalType | null) => void;
}

export const JournalSleep = ({
  tuid,
  setOpen,
  resetJournalType,
}: JournalSleepProps) => {
  const { texts } = useTranslations();
  const { onCreateJournalEntry } = useStudentsTableWrite();

  return (
    <FormController
      initialValues={{
        from: '',
        to: '',
        info: '',
      }}
      validate={(values) => {
        const errors: Partial<Values> = {};
        if (!values.from) {
          errors.from = texts.oasCommon.validation.required;
        }
        if (!values.to) {
          errors.to = texts.oasCommon.validation.required;
        }
        return errors;
      }}
      onSubmit={async (values) => {
        if (!tuid) return;
        await onCreateJournalEntry({
          regardingTuid: tuid,
          type: JournalType.sleep,
          data: {
            [JournalType.sleep]: values.info,
          },
          from: mapTimeStringToDateTime(values.from),
          to: mapTimeStringToDateTime(values.to),
        });
        setOpen(false);
        resetJournalType(null);
      }}
    >
      {({ submitForm, isSubmitting }) => (
        <Form>
          <Field
            component={FormTextField}
            name="from"
            type="time"
            label={texts.planner.common.from}
            disabled={isSubmitting}
            fullWidth
            InputLabelProps={{ shrink: true }}
          />
          <Field
            component={FormTextField}
            name="to"
            type="time"
            label={texts.planner.common.to}
            disabled={isSubmitting}
            fullWidth
            InputLabelProps={{ shrink: true }}
          />
          <Field
            component={FormTextField}
            name="info"
            type="text"
            label={texts.studentModule.journal.optionalInfo}
            disabled={isSubmitting}
            fullWidth
          />
          <FlexBox justifyContent="flex-end">
            <Button
              color="secondary"
              disabled={isSubmitting}
              onClick={submitForm}
            >
              {texts.planner.common.save}
            </Button>
            <Button
              color="secondary"
              simple
              onClick={() => {
                setOpen(false);
              }}
            >
              {texts.planner.common.close}
            </Button>
          </FlexBox>
        </Form>
      )}
    </FormController>
  );
};
