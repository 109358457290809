import { useCallback } from 'react';

import { useSetSelectedScheduleState } from 'modules/planner/memory/apollo/schedules/local';
import { getCurrentScheduleStorage } from 'modules/planner/memory/browser/schedule';

const {
  getCurrentSchedule,
  setCurrentSchedule: setCurrentScheduleStorage,
} = getCurrentScheduleStorage();

export const useSetCurrentSchedule = () => {
  const { setSelectedScheduleState } = useSetSelectedScheduleState();

  const setCurrentSchedule = useCallback(
    (value: string) => {
      const storedCurrentSchedule = getCurrentSchedule();
      if (storedCurrentSchedule !== value) {
        setCurrentScheduleStorage(value);
      }
      setSelectedScheduleState(value);
    },
    [setSelectedScheduleState],
  );

  return {
    setCurrentSchedule,
  };
};
