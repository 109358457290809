import { useMemo } from 'react';

import { useQuery } from 'core/dna/types/apollo';
import {
  GetTeachers,
  GetTeachersVariables,
} from 'core/dna/types/generated/GetTeachers';

import { useGetTermsState } from 'core/memory/apollo/terms/local';

import { mapApiRoleListToTeacherList } from 'modules/planner/dna/functions/map-ApiRole-to-Teacher';
import { Teacher } from 'modules/planner/dna/types/teacher';

import { GET_TEACHERS_QUERY } from './_gql';

export const useGetTeachers = () => {
  const { data: termsState } = useGetTermsState();

  const {
    data: teachersData,
    error: teachersError,
    loading: teachersLoading,
  } = useQuery<GetTeachers, GetTeachersVariables>(GET_TEACHERS_QUERY, {
    variables: { termId: termsState!.selected! },
    skip: !termsState?.selected,
  });

  const teachers = useMemo<Teacher[] | undefined>(() => {
    if (!teachersLoading && !teachersError && teachersData) {
      return mapApiRoleListToTeacherList(teachersData.Role);
    }
  }, [teachersLoading, teachersError, teachersData]);

  return {
    teachersLoading,
    teachersError,
    teachers,
  };
};
