/* eslint-disable */
import moment, { Moment } from 'moment';
import clsx from 'clsx';

import { makeStyles, createStyles } from 'core/atoms/styles';
import { red, grey } from 'core/atoms/colors';

import { HeaderRow, MonthRow } from './components';

export interface YearlyCalendarProps {
  startDay: Date;
  endDay: Date;
  selectedDays?: Date[];
  showDaysOfWeek?: boolean;
  showWeekNumbers?: boolean;
  firstDayOfWeek?: number;
  onPickDay?: (date: any, classes: any) => void;
  onHoverDay?: (hoveredDay: any, classes: any) => void;
  forceFullWeeks?: boolean;
  showWeekSeparators?: boolean;
  customClasses?: object | Function;
}

const defaultProps: Partial<YearlyCalendarProps> = {
  forceFullWeeks: false,
  showDaysOfWeek: true,
  firstDayOfWeek: 1,
  onPickDay: undefined,
  selectedDays: [moment().toDate()],
  customClasses: undefined,
};

const prevNextMonthMixin: any = {
  color: 'transparent',
  cursor: 'default',
  pointerEvents: 'none',
};

const useStyles = makeStyles(() =>
  createStyles({
    // .calendar
    root: {
      fontFamily: 'sans-serif',
      border: '1px solid rgba(185, 185, 185, 0.13)',
      borderCollapse: 'collapse',
      '& tr:last-child td': {
        borderBottom: 'none',
      },
      '& .bolder': {
        fontWeight: 'bold',
      },
    },
    // thead.day-headers
    header: {
      backgroundColor: '#5A5A5A',
      color: 'white',
      marginBottom: 3,
      borderBottom: '2px solid white',
      '& th': {
        fontWeight: 'lighter',
        padding: '10px 3px',
        '&.week-separator': {
          pointerEvents: 'none',
          padding: 0,
          width: 8,
          minWidth: 0,
        },
        '&.weekday': {},
      },
    },
    // tbody.calendar-body
    body: {
      fontSize: '0.8em',
      '& td': {
        textAlign: 'center',
        padding: 8,
        cursor: 'pointer',
        border: '1px solid rgba(185, 185, 185, 0.13)',
        minWidth: 15,
        '&.month-name': {
          fontWeight: 'bold',
          textAlign: 'left',
          cursor: 'default',
          borderLeft: 'none',
        },
        '&.prev-month': {
          ...prevNextMonthMixin,
        },
        '&.next-month': {
          ...prevNextMonthMixin,
        },
        '&.bolder': {
          fontWeight: 'bold',
        },
        '&.selected': {
          backgroundColor: 'orangered',
          color: 'white',
          fontWeight: 'bold',
        },
        '&.week-separator': {
          pointerEvents: 'none',
          padding: 0,
          width: 8,
          minWidth: 0,
        },
        '&.winter': {
          backgroundColor: 'rgba(159, 220, 249, 0.32)',
          '&.selected': {
            backgroundColor: 'rgba(159, 220, 249, 1)',
            fontWeight: 'bold',
          },
        },
        '&.spring': {
          backgroundColor: 'rgba(145, 212, 110, 0.32)',
          '&.selected': {
            backgroundColor: 'rgba(145, 212, 110, 1)',
            fontWeight: 'bold',
          },
        },
        '&.summer': {
          backgroundColor: 'rgba(255, 232, 91, 0.32)',
          '&.selected': {
            backgroundColor: 'rgba(255, 232, 91, 1)',
            fontWeight: 'bold',
          },
        },
        '&.autumn': {
          backgroundColor: 'rgba(246, 151, 60, 0.32)',
          '&.selected': {
            backgroundColor: 'rgba(246, 151, 60, 1)',
            fontWeight: 'bold',
          },
        },
        '&.year': {
          backgroundColor: 'rgba(182,190,177,0.22)',
          '&.selected': {
            backgroundColor: red['500'],
            fontWeight: 'bold',
          },
          '&.outside-of-term': {
            backgroundColor: 'transparent',
            color: grey['700'],
            fontWeight: 400,
            fontStyle: 'normal',
            pointerEvents: 'none',
            cursor: 'default',
          },
        },
        '&.weekend': {
          fontStyle: 'italic',
          fontWeight: 'bold',
          backgroundColor: red['100'],
          cursor: 'default',
        },
        '&.week-number': {
          cursor: 'default',
        },
        '&.holidays': {
          fontWeight: 'bold',
          color: 'red',
          textDecoration: 'underline',
        },
      },
    },
  }),
);

export const YearlyCalendar = (props: YearlyCalendarProps) => {
  const {
    onPickDay,
    onHoverDay,
    firstDayOfWeek,
    forceFullWeeks,
    showDaysOfWeek,
    showWeekNumbers,
    showWeekSeparators,
    selectedDays,
    customClasses,
    startDay,
    endDay,
  } = props;
  const classes = useStyles();

  const onDayClick = (day: Moment, classes: string) => {
    const d = day.weekday();
    // 6 - Saturday, 0 - Sunday
    if (d === 6 || d === 0) {
      return;
    }
    onPickDay?.(day, classes);
  };

  const dayHovered = (hoveredDay: any, classes: any) => {
    if (!hoveredDay) {
      // hovered over on prev or next month
      return;
    }
    onHoverDay && onHoverDay(hoveredDay, classes);
  };

  const getMonthsArray = (): { month: number; year: number }[] => {
    const from = moment(startDay);

    const daysDiff = moment(endDay).diff(from, 'days');
    // console.log('days diff', daysDiff);

    if (from.isSameOrAfter(endDay) || daysDiff > 365) {
      throw new Error(
        `Days diff is ${daysDiff} but should be in between 0 and 365`,
      );
    }

    const addMonthsTo = (
      monthsArray: { month: number; year: number }[],
      prevDate: Moment,
      endDate: Date,
    ) => {
      const forDate = prevDate.add(1, 'month');

      if (forDate.isSameOrBefore(endDate, 'month')) {
        monthsArray.push({ month: forDate.month(), year: forDate.year() });
        addMonthsTo(monthsArray, forDate.clone(), endDate);
      }
    };

    const monthsArray = [{ month: from.month(), year: from.year() }];

    addMonthsTo(monthsArray, from, endDay);

    return [...monthsArray];
  };

  return (
    <table className={clsx(classes.root, 'calendar')}>
      <thead className={clsx(classes.header, 'day-headers')}>
        {showDaysOfWeek ? (
          <HeaderRow
            firstDayOfWeek={firstDayOfWeek}
            forceFullWeeks={forceFullWeeks}
            showWeekNumbers={showWeekNumbers}
            showWeekSeparators={showWeekSeparators}
          />
        ) : null}
      </thead>
      <tbody className={clsx(classes.body, 'calendar-body')}>
        {getMonthsArray().map((date) => (
          <MonthRow
            key={`month-${date.year}-${date.month}`}
            yearStartDate={startDay}
            yearEndDate={endDay}
            year={date.year}
            month={date.month}
            selectedDays={selectedDays || defaultProps.selectedDays || []}
            firstDayOfWeek={firstDayOfWeek || defaultProps.firstDayOfWeek}
            onClick={onDayClick}
            dayHovered={dayHovered}
            forceFullWeeks={forceFullWeeks || defaultProps.forceFullWeeks}
            showWeekNumbers={showWeekNumbers}
            showWeekSeparators={showWeekSeparators}
            customClasses={customClasses}
          />
        ))}
      </tbody>
    </table>
  );
};

YearlyCalendar.defaultProps = defaultProps;
