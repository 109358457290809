import { useCallback } from 'react';
import { gql, useMutation } from 'core/dna/types/apollo';
import { OasError, OasMemoryError } from 'core/atoms/errors';
import { CreateEvaluationGroupAccessInput } from 'core/dna/types/generated/_globalTypes';
import {
  CreateEvaluationGroupAccess,
  CreateEvaluationGroupAccessVariables,
} from 'core/dna/types/generated/CreateEvaluationGroupAccess';

import { GET_EVALUATION_GROUP_ACCESS_QUERY } from './use-get-evaluation-group-access';

export const CREATE_EVALUATION_GROUP_ACCESS_MUTATION = gql`
  mutation CreateEvaluationGroupAccess(
    $input: CreateEvaluationGroupAccessInput
  ) {
    createEvaluationGroupAccess(input: $input) {
      ok
    }
  }
`;

const TITLE = 'use-create-evaluation-group-access';

export const useCreateEvaluationGroupAccess = () => {
  const [createEvaluationGroupAccess, { loading, error }] = useMutation<
    CreateEvaluationGroupAccess,
    CreateEvaluationGroupAccessVariables
  >(CREATE_EVALUATION_GROUP_ACCESS_MUTATION);

  const func = useCallback(
    async (input: CreateEvaluationGroupAccessInput) => {
      try {
        const result = await createEvaluationGroupAccess({
          variables: {
            input: { ...input },
          },
          refetchQueries: [
            {
              query: GET_EVALUATION_GROUP_ACCESS_QUERY,
            },
          ],
        });

        if (result.errors) {
          throw new OasMemoryError(JSON.stringify(result.errors), {
            title: TITLE,
          });
        }
        return result.data?.createEvaluationGroupAccess ?? undefined;
      } catch (e: any) {
        if (e instanceof OasError) {
          throw e;
        } else {
          throw OasMemoryError.fromError(e, {
            title: TITLE,
          });
        }
      }
    },
    [createEvaluationGroupAccess],
  );

  return {
    createEvaluationGroupAccess: func,
    loading,
    error,
  };
};
