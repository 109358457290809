interface DisplayCellStateProps {
  value: string | undefined;
  // row: any;
  // data: string[] | undefined;
}

export const DisplayCellData = (props: DisplayCellStateProps) => {
  const { value } = props;

  if (!value) return <span />;

  // for rendering multiline text
  return <div style={{ whiteSpace: 'pre-line' }}>{value}</div>;
};
