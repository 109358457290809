import React, { useCallback, useMemo, useState } from 'react';
import moment from 'moment';
import { Work } from '@material-ui/icons';

import { dateTimeShortForTableSorting } from 'core/atoms/date-time/formats';

import { Skeleton } from 'core/cells/skeleton';
import { useTranslations } from 'core/dna/translations';
import { useLocale } from 'core/dna/routing';

import { SubstituteJobState } from 'core/dna/types/generated/_globalTypes';
import { useGetTermsState } from 'core/memory/apollo/terms/local';

import { createReactTableStore } from 'core/memory/pure/react-table-store';
import { useReactTableState } from 'core/metabolism/table-state';

import { useOasHistory } from 'core/metabolism/use-oas-history';

import { ConfirmationDialog } from 'core/cells/confirmation-dialog';

import {
  Card,
  CardBody,
  CardHeader,
  CardIcon,
} from 'templates/mdp/components/card';
import { GridContainer, GridItem } from 'templates/mdp/components/grid';

import { Button } from 'templates/mdp/components/custom-buttons';

import { Table } from 'templates/mdp/components/react-table';

import { useStyles } from 'templates/mdp/views/tables/react-tables.styles';

import { emptyJobItem, JobTableItem } from 'modules/substitute/dna/types/job';

import URLS from 'modules/substitute/urls';
import { selectJob } from 'modules/substitute/memory/rx/jobs';

import { useJobsTableRead } from 'modules/substitute/metabolism/use-jobs-table-read';
import { useJobsTableWrite } from 'modules/substitute/metabolism/use-jobs-table-write';
import { JobCreator } from './job-creator';

export const jobsTableStore = createReactTableStore({}, {});

export const JobsTable = () => {
  const classes = useStyles();
  const { texts } = useTranslations();
  const { push } = useOasHistory();
  const locale = useLocale();

  const now = moment().valueOf();

  const { data: termsState } = useGetTermsState();

  const { columns, data, loading, title } = useJobsTableRead();
  const { writing, onRowCancel, onRowDelete } = useJobsTableWrite();

  const {
    initialPage,
    pageSize,
    sort,
    filters,
    setSort,
    setFilters,
    setPage,
    setPageSize,
  } = useReactTableState(jobsTableStore, termsState?.selected ?? undefined);

  const [showModal, setShowModal] = useState(false);
  const [showConfirmCancelModal, setShowConfirmCancelModal] = useState(false);
  const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);

  const [selectedJob, setSelectedJob] = useState<JobTableItem | null>(null);

  const handleAdd = () => {
    selectJob(emptyJobItem);
    setShowModal(true);
  };

  const handleCreateJob = useCallback(
    (id: string) => {
      push(URLS.editJob({ jobId: id, locale }));
    },
    [locale, push],
  );

  const handleCancel = useCallback(
    (id: string) => {
      const jobToCancel = data?.find((o) => o.id === id);
      if (jobToCancel) {
        setSelectedJob(jobToCancel);
        setShowConfirmCancelModal(true);
      }
    },
    [data],
  );

  const handleDelete = useCallback(
    async (id: string) => {
      const jobToDelete = data?.find((o) => o.id === id);
      if (jobToDelete) {
        setSelectedJob(jobToDelete);
        setShowConfirmDeleteModal(true);
        // await onRowDelete(jobToDelete);
      }
    },
    [data],
  );

  const handleConfirmCloseCanceling = async (confirmed: boolean) => {
    setShowConfirmCancelModal(false);

    if (!confirmed || !selectedJob) {
      setSelectedJob(null);
      return;
    }

    if (confirmed && selectedJob) {
      await onRowCancel(selectedJob);
    }
    setSelectedJob(null);
  };

  const handleConfirmCloseDeleting = async (confirmed: boolean) => {
    setShowConfirmDeleteModal(false);

    if (!confirmed || !selectedJob) {
      setSelectedJob(null);
      return;
    }

    if (confirmed && selectedJob) {
      await onRowDelete(selectedJob);
    }
    setSelectedJob(null);
  };

  const isDeadlineExpired = useCallback(
    (job: JobTableItem) => {
      if (!job.deadline) return false;

      const formattedDeadline = moment(
        job.deadline,
        dateTimeShortForTableSorting,
      );

      return moment(now).isAfter(formattedDeadline);
    },
    [now],
  );

  const preparedData = useMemo<any[]>(() => {
    return data.map((item) => {
      const result: any = {
        ...item,
        actions: (
          <div className="actions-right">
            {item.state === SubstituteJobState.published &&
              !isDeadlineExpired(item) && (
                <Button
                  onClick={() => handleCancel(item.id)}
                  className="remove"
                  size="sm"
                  disabled={writing}
                  style={{ minWidth: 100 }}
                >
                  {texts.planner.common.cancel}
                </Button>
              )}
            {item.state === SubstituteJobState.draft && (
              <Button
                onClick={() => handleDelete(item.id)}
                color="danger"
                size="sm"
                disabled={writing}
                style={{ minWidth: 100 }}
              >
                {texts.planner.common.delete}
              </Button>
            )}
          </div>
        ),
      };
      return result;
    });
  }, [data, isDeadlineExpired, writing, texts, handleCancel, handleDelete]);

  return loading || !termsState?.selected ? (
    <Skeleton variant="rect" width="100%" height="80vh" />
  ) : (
    <GridContainer>
      <GridItem xs={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button color="secondary" onClick={handleAdd}>
          {texts.substitute.addJob}
        </Button>
      </GridItem>
      <GridItem xs={12}>
        <Card>
          <CardHeader color="primary" icon>
            <CardIcon color="primary">
              <Work />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>{title}</h4>
          </CardHeader>
          <CardBody>
            <Table
              columns={columns}
              data={preparedData}
              key={termsState?.selected ?? 'unknown_term'}
              onChangeRowsPerPage={setPageSize}
              onChangePage={setPage}
              onFilterChange={setFilters}
              onOrderChange={setSort}
              options={{
                initialPage,
                pageSize,
                sort,
                filters,
              }}
            />
          </CardBody>
        </Card>
      </GridItem>
      <JobCreator
        open={showModal}
        setOpen={setShowModal}
        onSubmit={handleCreateJob}
      />
      <ConfirmationDialog
        id="cancel-confirmation"
        onClose={handleConfirmCloseCanceling}
        open={showConfirmCancelModal}
        title={texts.substitute.confirm.cancel.title}
        content={texts.substitute.confirm.cancel.body}
        okText={texts.planner.common.yes}
        cancelText={texts.planner.common.no}
      />
      <ConfirmationDialog
        id="delete-confirmation"
        onClose={handleConfirmCloseDeleting}
        open={showConfirmDeleteModal}
        title={texts.substitute.confirm.delete.title}
        content={texts.substitute.confirm.delete.body}
        okText={texts.planner.common.yes}
        cancelText={texts.planner.common.no}
      />
    </GridContainer>
  );
};
