import { useCallback } from 'react';

import {
  useSetSchedulesSelectedState,
  useSetSelectedScheduleState,
} from 'modules/planner/memory/apollo/schedules/local';

import { updateScheduleStorages } from './update-schedule-storages';

export const useSetSelectedSchedules = () => {
  const { setSchedulesSelectedState } = useSetSchedulesSelectedState();
  const { setSelectedScheduleState } = useSetSelectedScheduleState();

  const setSelectedSchedules = useCallback(
    (values: string[]) => {
      const { selectedSchedules, currentSchedule } = updateScheduleStorages(
        values,
      );

      setSchedulesSelectedState(selectedSchedules);
      setSelectedScheduleState(currentSchedule);
    },
    [setSchedulesSelectedState, setSelectedScheduleState],
  );

  return {
    setSelectedSchedules,
  };
};
