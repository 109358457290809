interface PersonDetails {
  firstName: string | null;
  lastName: string | null;
}

export const mapPersonDetailsToFullName = (
  item: PersonDetails,
): string | null => {
  if (!item.firstName && !item.lastName) {
    return null;
  }

  if (item.firstName && item.lastName) {
    return `${item.firstName} ${item.lastName}`;
  }

  if (item.firstName) {
    return item.firstName;
  }

  if (item.lastName) {
    return item.lastName;
  }

  return null;
};
