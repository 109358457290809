/*eslint-disable*/
import React from 'react';
import clsx from 'clsx';
import PerfectScrollbar from 'perfect-scrollbar';
import { NavLink } from 'react-router-dom';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Hidden from '@material-ui/core/Hidden';
import Collapse from '@material-ui/core/Collapse';
import Icon from '@material-ui/core/Icon';

import { URLS, PARAMS, PATHS } from 'core/dna/routing';

import avatar from '../../assets/img/faces/avatar.jpg';

import { HeaderLinks as AdminNavbarLinks } from '../navbars';

import { sidebarStyle, SidebarStyleClasses } from './sidebar.styles';

let ps: any;

export interface SidebarWrapperProps {
  className?: string; // PropTypes.string,
  user?: any; // PropTypes.object,
  headerLinks?: any; // PropTypes.object,
  links?: any; // PropTypes.object,
}

// We've created this component so we can have a ref to the wrapper of the links that appears in our sidebar.
// This was necessary so that we could initialize PerfectScrollbar on the links.
// There might be something with the Hidden component from material-ui, and we didn't have access to
// the links, and couldn't initialize the plugin.
class SidebarWrapper extends React.Component<SidebarWrapperProps, any> {
  sidebarWrapper = React.createRef<any>();
  componentDidMount() {
    if (navigator.platform.indexOf('Win') > -1) {
      ps = new PerfectScrollbar(this.sidebarWrapper.current, {
        suppressScrollX: true,
        suppressScrollY: false,
      });
    }
  }
  componentWillUnmount() {
    if (navigator.platform.indexOf('Win') > -1) {
      ps.destroy();
    }
  }
  render() {
    const { className, user, headerLinks, links } = this.props;
    return (
      <div className={className} ref={this.sidebarWrapper}>
        {user}
        {headerLinks}
        {links}
      </div>
    );
  }
}

type SidebarColor =
  | 'white'
  | 'red'
  | 'orange'
  | 'green'
  | 'blue'
  | 'purple'
  | 'rose';

export interface SidebarProps {
  classes: SidebarStyleClasses; // PropTypes.object.isRequired,
  routes: any[]; // PropTypes.arrayOf(PropTypes.object),
  bgColor?: 'white' | 'black' | 'blue'; // PropTypes.oneOf([]),
  rtlActive?: boolean; // PropTypes.bool,
  color?: SidebarColor; // PropTypes.oneOf([]),
  logo?: string; // PropTypes.string,
  logoText?: string; // PropTypes.string,
  image?: string; // PropTypes.string,
  miniActive?: boolean; // PropTypes.bool,
  open?: boolean; // PropTypes.bool,
  handleDrawerToggle?: () => void; // PropTypes.func,
}

class SidebarComponent extends React.Component<SidebarProps, any> {
  static defaultProps: Partial<SidebarProps> = {
    bgColor: 'blue',
  };

  constructor(props: SidebarProps) {
    super(props);
    this.state = {
      openAvatar: false,
      miniActive: true,
      ...this.getCollapseStates(props.routes ?? []),
    };
  }
  mainPanel = React.createRef<any>();
  // this creates the intial state of this component based on the collapse routes
  // that it gets through this.props.routes
  getCollapseStates = (routes: any[]) => {
    let initialState = {};
    routes.map((prop) => {
      if (prop.collapse) {
        initialState = {
          [prop.state]: this.getCollapseInitialState(prop.views),
          ...this.getCollapseStates(prop.views),
          ...initialState,
        };
      }
      return null;
    });
    return initialState;
  };
  // this verifies if any of the collapses should be default opened on a rerender of this component
  // for example, on the refresh of the page,
  // while on the src/views/forms/RegularForms.jsx - route /admin/regular-forms
  getCollapseInitialState(routes: any[]) {
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse && this.getCollapseInitialState(routes[i].views)) {
        return true;
      } else if (window.location.href.indexOf(routes[i].path) !== -1) {
        return true;
      }
    }
    return false;
  }
  // verifies if routeName is the one active (in browser input)
  activeRoute = (routeName: string) => {
    return window.location.href.indexOf(routeName) > -1 ? 'active' : '';
  };
  openCollapse(collapse: string) {
    const st: any = {};
    st[collapse] = !this.state[collapse];
    this.setState(st);
  }
  // this function creates the links and collapses that appear in the sidebar (left menu)
  createLinks = (routes: any[]) => {
    const { classes, color, rtlActive } = this.props;
    return routes.map((route: any, key) => {
      if (route.redirect) {
        return null;
      }
      if (route.collapse) {
        const st: any = {};
        st[route['state']] = !this.state[route.state];

        const navLinkClasses = clsx(
          classes.itemLink,
          this.getCollapseInitialState(route.views)
            ? classes.collapseActive
            : '',
        );

        const itemText = clsx(
          classes.itemText,
          this.props.miniActive && this.state.miniActive
            ? classes.itemTextMini
            : '',
          rtlActive && this.props.miniActive && this.state.miniActive
            ? classes.itemTextMiniRTL
            : '',
          rtlActive ? classes.itemTextRTL : '',
        );

        const collapseItemText = clsx(
          classes.collapseItemText,
          this.props.miniActive && this.state.miniActive
            ? classes.collapseItemTextMini
            : '',
          rtlActive && this.props.miniActive && this.state.miniActive
            ? classes.collapseItemTextMiniRTL
            : '',
          rtlActive ? classes.collapseItemTextRTL : '',
        );

        const itemIcon = clsx(
          classes.itemIcon,
          rtlActive ? classes.itemIconRTL : '',
        );

        const caret = clsx(classes.caret, rtlActive ? classes.caretRTL : '');

        const collapseItemMini = clsx(
          classes.collapseItemMini,
          rtlActive ? classes.collapseItemMiniRTL : '',
        );

        return (
          <ListItem
            key={key}
            className={clsx(
              route.icon !== undefined ? classes.item : '',
              route.icon === undefined ? classes.collapseItem : '',
            )}
          >
            <NavLink
              to={'#'}
              className={navLinkClasses}
              onClick={(e) => {
                e.preventDefault();
                this.setState(st);
              }}
            >
              {route.icon !== undefined ? (
                typeof route.icon === 'string' ? (
                  <Icon className={itemIcon}>{route.icon}</Icon>
                ) : (
                  <route.icon className={itemIcon} />
                )
              ) : (
                <span className={collapseItemMini}>
                  {rtlActive ? route.rtlMini : route.mini}
                </span>
              )}
              <ListItemText
                primary={rtlActive ? route.rtlName : route.name}
                secondary={
                  <b
                    className={
                      caret +
                      ' ' +
                      (this.state[route.state] ? classes.caretActive : '')
                    }
                  />
                }
                disableTypography={true}
                className={clsx(
                  { [itemText]: route.icon !== undefined },
                  { [collapseItemText]: route.icon === undefined },
                )}
              />
            </NavLink>
            <Collapse in={this.state[route.state]} unmountOnExit>
              <List className={classes.list + ' ' + classes.collapseList}>
                {this.createLinks(route.views)}
              </List>
            </Collapse>
          </ListItem>
        );
      }
      const innerNavLinkClasses = clsx(
        classes.collapseItemLink,
        this.activeRoute(route.path) ? (color ? classes[color] : '') : '',
      );

      const collapseItemMini = clsx(
        classes.collapseItemMini,
        rtlActive ? classes.collapseItemMiniRTL : '',
      );

      const navLinkClasses = clsx(
        classes.itemLink,
        this.activeRoute(route.path) ? (color ? classes[color] : '') : '',
      );

      const itemText = clsx(
        classes.itemText,
        this.props.miniActive && this.state.miniActive
          ? classes.itemTextMini
          : '',
        rtlActive && this.props.miniActive && this.state.miniActive
          ? classes.itemTextMiniRTL
          : '',
        rtlActive ? classes.itemTextRTL : '',
      );

      const collapseItemText = clsx(
        classes.collapseItemText,
        this.props.miniActive && this.state.miniActive
          ? classes.collapseItemTextMini
          : '',
        rtlActive && this.props.miniActive && this.state.miniActive
          ? classes.collapseItemTextMiniRTL
          : '',
        rtlActive ? classes.collapseItemTextRTL : '',
      );

      const itemIcon = clsx(
        classes.itemIcon,
        rtlActive ? classes.itemIconRTL : '',
      );

      return (
        <ListItem
          key={key}
          className={clsx(
            route.icon !== undefined ? classes.item : '',
            route.icon === undefined ? classes.collapseItem : '',
          )}
        >
          <NavLink
            to={`${PATHS.Common.Sandbox.MdProTemplate.path.replace(
              PARAMS.Locale.path,
              'en',
            )}/${route.layout}/${route.path}`}
            className={clsx(
              { [navLinkClasses]: route.icon !== undefined },
              { [innerNavLinkClasses]: route.icon === undefined },
            )}
          >
            {route.icon !== undefined ? (
              typeof route.icon === 'string' ? (
                <Icon className={itemIcon}>{route.icon}</Icon>
              ) : (
                <route.icon className={itemIcon} />
              )
            ) : (
              <span className={collapseItemMini}>
                {rtlActive ? route.rtlMini : route.mini}
              </span>
            )}
            <ListItemText
              primary={rtlActive ? route.rtlName : route.name}
              disableTypography={true}
              className={clsx(
                { [itemText]: route.icon !== undefined },
                { [collapseItemText]: route.icon === undefined },
              )}
            />
          </NavLink>
        </ListItem>
      );
    });
  };
  render() {
    const {
      classes,
      logo,
      image,
      logoText,
      routes,
      bgColor,
      rtlActive,
    } = this.props;

    const itemText = clsx(
      classes.itemText,
      this.props.miniActive && this.state.miniActive
        ? classes.itemTextMini
        : '',
      rtlActive && this.props.miniActive && this.state.miniActive
        ? classes.itemTextMiniRTL
        : '',
      rtlActive ? classes.itemTextRTL : '',
    );

    const collapseItemText = clsx(
      classes.collapseItemText,
      this.props.miniActive && this.state.miniActive
        ? classes.collapseItemTextMini
        : '',
      rtlActive && this.props.miniActive && this.state.miniActive
        ? classes.collapseItemTextMiniRTL
        : '',
      rtlActive ? classes.collapseItemTextRTL : '',
    );

    const userWrapperClass = clsx(
      classes.user,
      bgColor === 'white' ? classes.whiteAfter : '',
    );

    const caret = clsx(classes.caret, rtlActive ? classes.caretRTL : '');

    const collapseItemMini = clsx(
      classes.collapseItemMini,
      rtlActive ? classes.collapseItemMiniRTL : '',
    );

    const photo = clsx(classes.photo, rtlActive ? classes.photoRTL : '');

    const user = (
      <div className={userWrapperClass}>
        <div className={photo}>
          <img src={avatar} className={classes.avatarImg} alt="..." />
        </div>
        <List className={classes.list}>
          <ListItem className={classes.item + ' ' + classes.userItem}>
            <NavLink
              to={'#'}
              className={classes.itemLink + ' ' + classes.userCollapseButton}
              onClick={() => this.openCollapse('openAvatar')}
            >
              <ListItemText
                primary={rtlActive ? 'تانيا أندرو' : 'Tania Andrew'}
                secondary={
                  <b
                    className={clsx(
                      caret,
                      classes.userCaret,
                      this.state.openAvatar ? classes.caretActive : '',
                    )}
                  />
                }
                disableTypography={true}
                className={itemText + ' ' + classes.userItemText}
              />
            </NavLink>
            <Collapse in={this.state.openAvatar} unmountOnExit>
              <List className={classes.list + ' ' + classes.collapseList}>
                <ListItem className={classes.collapseItem}>
                  <NavLink
                    to="#"
                    className={
                      classes.itemLink + ' ' + classes.userCollapseLinks
                    }
                  >
                    <span className={collapseItemMini}>
                      {rtlActive ? 'مع' : 'MP'}
                    </span>
                    <ListItemText
                      primary={rtlActive ? 'ملفي' : 'My Profile'}
                      disableTypography={true}
                      className={collapseItemText}
                    />
                  </NavLink>
                </ListItem>
                <ListItem className={classes.collapseItem}>
                  <NavLink
                    to="#"
                    className={clsx(
                      classes.itemLink,
                      classes.userCollapseLinks,
                    )}
                  >
                    <span className={collapseItemMini}>
                      {rtlActive ? 'هوع' : 'EP'}
                    </span>
                    <ListItemText
                      primary={
                        rtlActive ? 'تعديل الملف الشخصي' : 'Edit Profile'
                      }
                      disableTypography={true}
                      className={collapseItemText}
                    />
                  </NavLink>
                </ListItem>
                <ListItem className={classes.collapseItem}>
                  <NavLink
                    to="#"
                    className={
                      classes.itemLink + ' ' + classes.userCollapseLinks
                    }
                  >
                    <span className={collapseItemMini}>
                      {rtlActive ? 'و' : 'S'}
                    </span>
                    <ListItemText
                      primary={rtlActive ? 'إعدادات' : 'Settings'}
                      disableTypography={true}
                      className={collapseItemText}
                    />
                  </NavLink>
                </ListItem>
              </List>
            </Collapse>
          </ListItem>
        </List>
      </div>
    );

    const links = (
      <List className={classes.list}>{this.createLinks(routes)}</List>
    );

    const logoNormal = clsx(
      classes.logoNormal,
      this.props.miniActive && this.state.miniActive
        ? classes.logoNormalSidebarMini
        : '',
      rtlActive && this.props.miniActive && this.state.miniActive
        ? classes.logoNormalSidebarMiniRTL
        : '',
      rtlActive ? classes.logoNormalRTL : '',
    );

    const logoMini = clsx(
      classes.logoMini,
      rtlActive ? classes.logoMiniRTL : '',
    );

    const logoClasses = clsx(
      classes.logo,
      bgColor === 'white' ? classes.whiteAfter : '',
    );

    const brand = (
      <div className={logoClasses}>
        <a
          href="https://www.creative-tim.com?ref=mdpr-sidebar"
          target="_blank"
          className={logoMini}
        >
          <img src={logo} alt="logo" className={classes.img} />
        </a>
        <a
          href="https://www.creative-tim.com?ref=mdpr-sidebar"
          target="_blank"
          className={logoNormal}
        >
          {logoText}
        </a>
      </div>
    );

    const drawerPaper = clsx(
      classes.drawerPaper,
      this.props.miniActive && this.state.miniActive
        ? classes.drawerPaperMini
        : '',
      rtlActive ? classes.drawerPaperRTL : '',
    );

    const sidebarWrapper = clsx(
      classes.sidebarWrapper,
      this.props.miniActive && this.state.miniActive
        ? classes.drawerPaperMini
        : '',
      navigator.platform.indexOf('Win') > -1
        ? classes.sidebarWrapperWithPerfectScrollbar
        : '',
    );

    return (
      <div ref={this.mainPanel}>
        <Hidden mdUp implementation="css">
          <Drawer
            variant="temporary"
            anchor={rtlActive ? 'left' : 'right'}
            open={this.props.open}
            classes={{
              paper: clsx(
                drawerPaper,
                (classes as any)[bgColor + 'Background'],
              ),
            }}
            onClose={this.props.handleDrawerToggle}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            {brand}
            <SidebarWrapper
              className={sidebarWrapper}
              user={user}
              headerLinks={<AdminNavbarLinks rtlActive={rtlActive} />}
              links={links}
            />
            {image !== undefined ? (
              <div
                className={classes.background}
                style={{ backgroundImage: 'url(' + image + ')' }}
              />
            ) : null}
          </Drawer>
        </Hidden>
        <Hidden smDown implementation="css">
          <Drawer
            onMouseOver={() => this.setState({ miniActive: false })}
            onMouseOut={() => this.setState({ miniActive: true })}
            anchor={rtlActive ? 'right' : 'left'}
            variant="permanent"
            open
            classes={{
              paper: clsx(
                drawerPaper,
                (classes as any)[bgColor + 'Background'],
              ),
            }}
          >
            {brand}
            <SidebarWrapper
              className={sidebarWrapper}
              user={user}
              links={links}
            />
            {image !== undefined ? (
              <div
                className={classes.background}
                style={{ backgroundImage: 'url(' + image + ')' }}
              />
            ) : null}
          </Drawer>
        </Hidden>
      </div>
    );
  }
}

export const Sidebar = withStyles(sidebarStyle)(SidebarComponent);
