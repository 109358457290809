import { makeStyles, createStyles, CSSProperties } from 'core/atoms/styles';

import {
  warningCardHeader,
  successCardHeader,
  dangerCardHeader,
  infoCardHeader,
  primaryCardHeader,
  roseCardHeader,
  grayColor,
} from '../../material-dashboard-pro-react';

export interface CardTextStyle {
  cardText: CSSProperties;
  warningCardHeader: CSSProperties;
  successCardHeader: CSSProperties;
  dangerCardHeader: CSSProperties;
  infoCardHeader: CSSProperties;
  primaryCardHeader: CSSProperties;
  roseCardHeader: CSSProperties;
}

export const cardTextStyle: CardTextStyle = {
  cardText: {
    float: 'none',
    display: 'inline-block',
    marginRight: '0',
    borderRadius: '3px',
    backgroundColor: grayColor[0],
    padding: '15px',
    marginTop: '-20px',
  },
  warningCardHeader,
  successCardHeader,
  dangerCardHeader,
  infoCardHeader,
  primaryCardHeader,
  roseCardHeader,
};

export const useStyles = makeStyles(() => createStyles({ ...cardTextStyle }));
