import { makeStyles, createStyles } from 'core/atoms/styles';

import { Select } from 'core/cells/select5';

import { useTranslations } from 'core/dna/translations';

import { useRoomSelector } from './use-room-selector';

const useStyles = makeStyles(() =>
  createStyles({
    select: {
      width: 200,
    },
  }),
);

export const RoomSelector = () => {
  const classes = useStyles();
  const { lang } = useTranslations();

  const {
    rooms,
    selectedRooms,
    setSelectedRooms,
    roomsLoading,
    roomsError,
  } = useRoomSelector();

  if (roomsLoading) return <p>Loading Rooms...</p>;
  if (roomsError) return <p>Rooms Error!</p>;

  return (
    <Select
      multiple
      name="rooms"
      label={lang.common.rooms}
      data={rooms}
      value={selectedRooms}
      className={classes.select}
      minWidth={200}
      onChange={setSelectedRooms}
    />
  );
};
