import { PersonDetailsT2Group } from 'modules/student/dna/types';

export const mapGroupsToString = (groups?: PersonDetailsT2Group[]) => {
  if (!groups?.length) {
    return '';
  }

  const modifiedGroups: string[] = [];

  groups.forEach((group) => {
    if (group.name) {
      modifiedGroups.push(group.name);
    }
  });

  return modifiedGroups.join(', ');
};
