import { useCallback } from 'react';
import clsx from 'clsx';

import { makeStyles, Theme, createStyles } from 'core/atoms/styles';
import { grey } from 'core/atoms/colors';
import { isNumber } from 'core/atoms/functions/type-check';

import { FlexBox, Box } from 'core/cells/box';
import { ChipInput } from 'core/cells/chip-input';
import { Button } from 'core/cells/button';

import { useTranslations } from 'core/dna/translations';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    button: {
      minWidth: theme.spacing(6),
      width: theme.spacing(6),
      height: theme.spacing(6),
      borderRadius: theme.spacing(3),
      marginRight: theme.spacing(0.5),
    },
    firstButton: {
      marginLeft: theme.spacing(0.5),
    },
    lastButton: {
      marginRight: 0,
    },
    chipInputRoot: {
      width: '100%',
      '& label.MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-outlined': {
        top: theme.spacing(0.5),
        '&.MuiInputLabel-marginDense': {
          transform: 'translate(14px, 12px) scale(1)',
        },
        '&.MuiInputLabel-shrink': {
          transform: 'translate(14px, -12px) scale(0.75)',
        },
      },
    },
    editorInputRoot: {
      '&.MuiOutlinedInput-root': {
        // borderTopRightRadius: 0,
        // borderBottomRightRadius: 0,
        paddingLeft: theme.spacing(1),
        paddingTop: theme.spacing(1),
      },
    },
    chip: {
      backgroundColor: grey['400'],
    },
  }),
);

export interface MinutesPerTimeSlotEditorProps {
  value: number[];
  onAdd?: (value: number) => void;
  onDelete?: (value: number, index: number) => void;
  error?: string;
}

export const MinutesPerTimeSlotEditor = (
  props: MinutesPerTimeSlotEditorProps,
) => {
  const classes = useStyles();

  const { lang } = useTranslations();

  const { value, onAdd, onDelete, error } = props;

  const handleBeforeAdd = (chip: any) => {
    const num = +chip.trim();
    return isNumber(num) && num >= 5 && num <= 480;
  };

  const onAdd45 = useCallback(() => {
    onAdd?.(45);
  }, [onAdd]);

  const onAdd60 = useCallback(() => {
    onAdd?.(60);
  }, [onAdd]);

  const onAdd90 = useCallback(() => {
    onAdd?.(90);
  }, [onAdd]);

  return (
    <FlexBox flexWrap="nowrap">
      <Box flexGrow={1} mb={1}>
        <ChipInput
          error={!!error}
          helperText={error}
          allowDuplicates
          label={lang.preparationSpace.schedulesModule.minutesPerTimeSlot}
          variant="outlined"
          classes={{
            root: classes.chipInputRoot,
            inputRoot: classes.editorInputRoot,
            chip: classes.chip,
          }}
          value={value}
          onBeforeAdd={handleBeforeAdd}
          onAdd={onAdd}
          onDelete={onDelete}
          size="small"
          blurBehavior="add"
        />
      </Box>
      <Box justifyContent="flex-start" mb={1} minWidth={156} width={156}>
        <Button
          variant="outlined"
          classes={{ root: clsx(classes.button, classes.firstButton) }}
          onClick={onAdd45}
        >
          45
        </Button>
        <Button
          variant="outlined"
          classes={{ root: classes.button }}
          onClick={onAdd60}
        >
          60
        </Button>
        <Button
          variant="outlined"
          classes={{ root: clsx(classes.button, classes.lastButton) }}
          onClick={onAdd90}
        >
          90
        </Button>
      </Box>
    </FlexBox>
  );
};
