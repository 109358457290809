import { gql } from 'core/dna/types/apollo';

export const GET_SYNC_STAFF_QUERY = gql`
  query GetSyncStaff {
    SyncStaff {
      id
      firstName
      lastName
    }
  }
`;
