import { Theme, createStyles } from 'core/atoms/styles';

export const studentProfileStyles = (theme: Theme) =>
  createStyles({
    box: {
      display: 'flex',
      flexDirection: 'column',
      marginBottom: theme.spacing(4),
    },
    groupTitle: {
      paddingTop: theme.spacing(1),
      marginBottom: theme.spacing(3),
    },
    groupInput: {
      display: 'flex',
      flexDirection: 'column',
    },
    list: {
      padding: 0,
      marginBottom: theme.spacing(3),
    },
    pickerBox: {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
    },
    picker: {
      paddingTop: 4,
      marginBottom: 4,
    },
    input: {
      width: '100%',
      paddingTop: 4,
      marginBottom: 4,
    },
    addBtn: {
      marginTop: theme.spacing(2),
    },
  });
