import { useState, useEffect, useCallback } from 'react';

import { useTranslations } from 'core/dna/translations';

import { extractTimePlanFromSchedule } from 'modules/planner/dna/functions/time-plan';

import { useGetSchedule } from 'modules/planner/memory/apollo/schedules/remote';
import { useSetTimePlanState } from 'modules/planner/memory/apollo/time-plan/local';

import { useWeek } from './use-week';

export interface UseTimePlanProps {
  scheduleId: string;
}

export const useTimePlan = (props: UseTimePlanProps) => {
  const { scheduleId } = props;

  const { data: schedule } = useGetSchedule({ scheduleId, watch: true });
  const { texts } = useTranslations();
  const { getWeekday } = useWeek();
  const { setTimePlanState } = useSetTimePlanState();

  const getConflictDescription = useCallback(
    (key: string) => {
      return (
        (texts.planner.common.aiConflicts as any)[key] ??
        texts.planner.common.aiConflicts.unknown
      );
    },
    [texts.planner.common.aiConflicts],
  );

  const [showClasses, setShowClasses] = useState(true);
  const [showGroups, setShowGroups] = useState(true);
  const [showTeachers, setShowTeachers] = useState(true);
  const [showRooms, setShowRooms] = useState(true);

  useEffect(() => {
    if (schedule) {
      const timePlan = extractTimePlanFromSchedule(schedule, {
        withClasses: showClasses,
        withGroups: showGroups,
        withTeachers: showTeachers,
        withRooms: showRooms,
        getWeekday,
        getConflictDescription,
      });
      setTimePlanState(timePlan);
    }
  }, [
    getConflictDescription,
    getWeekday,
    schedule,
    setTimePlanState,
    showClasses,
    showGroups,
    showRooms,
    showTeachers,
  ]);

  return {
    showClasses,
    setShowClasses,
    showGroups,
    setShowGroups,
    showTeachers,
    setShowTeachers,
    showRooms,
    setShowRooms,
  };
};
