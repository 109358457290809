import { OrganizationLevel, RouteConfig } from 'core/dna/types/local';
import { OasLocales } from 'core/dna/translations/types';
import { OasLanguage } from 'core/dna/routing';

import { PATHS } from 'modules/substitute/paths';

import { Jobs } from 'modules/substitute/pages/jobs';
import { JobDetails } from 'modules/substitute/pages/job-details';
import { EditJob } from 'modules/substitute/pages/edit-job';
import { Teachers } from 'modules/substitute/pages/teachers';
import { Invitation } from 'modules/substitute/pages/invitation';

const jobsId = `substitute-${PATHS.Jobs.path}`;
export const getRoutes = (
  locale: OasLanguage,
  texts: OasLocales,
): RouteConfig[] => [
  {
    id: jobsId,
    sidebar: true,
    exact: true,
    private: true,
    path: PATHS.Jobs.path,
    name: texts.substitute.jobs,
    rtlName: texts.substitute.jobs,
    mini: texts.substitute.jobs.toUpperCase().slice(0, 2),
    rtlMini: texts.substitute.jobs.toUpperCase().slice(0, 2),
    component: Jobs,
    level: OrganizationLevel.Organization,
    permissions: ['substitute'],
    views: [
      {
        id: `substitute-${PATHS.Jobs.Details.path}`,
        parentId: jobsId,
        exact: true,
        private: true,
        path: PATHS.Jobs.Details.path,
        name: texts.substitute.jobDetails,
        rtlName: texts.substitute.jobDetails,
        component: JobDetails,
        level: OrganizationLevel.Organization,
        permissions: ['substitute'],
      },
      {
        id: `substitute-${PATHS.Jobs.Edit.path}`,
        parentId: jobsId,
        exact: true,
        private: true,
        path: PATHS.Jobs.Edit.path,
        name: texts.substitute.editJob,
        rtlName: texts.substitute.editJob,
        component: EditJob,
        level: OrganizationLevel.Organization,
        permissions: ['substitute'],
      },
    ],
  },
  {
    id: `substitute-${PATHS.Teachers.path}`,
    sidebar: true,
    exact: true,
    private: true,
    path: PATHS.Teachers.path,
    name: texts.oasCommon.substitutes.many,
    rtlName: texts.oasCommon.substitutes.many,
    mini: texts.oasCommon.substitutes.many.toUpperCase().slice(0, 2),
    rtlMini: texts.oasCommon.substitutes.many.toUpperCase().slice(0, 2),
    component: Teachers,
    level: OrganizationLevel.Organization,
    permissions: ['substitute'],
  },
  {
    id: `substitute-${PATHS.Invitation.path}`,
    sidebar: false,
    exact: true,
    private: false,
    path: PATHS.Invitation.path,
    name: texts.oasCommon.invitations.one,
    rtlName: texts.oasCommon.invitations.one,
    mini: texts.oasCommon.invitations.one.toUpperCase().slice(0, 2),
    rtlMini: texts.oasCommon.invitations.one.toUpperCase().slice(0, 2),
    component: Invitation,
    level: OrganizationLevel.Unset,
    // "permissions: undefined" means: this route is available for all permissions
    permissions: undefined,
  },
];
