import { useState, useEffect } from 'react';

import { makeStyles, createStyles, Theme } from 'core/atoms/styles';
import { tick } from 'core/atoms/date-time';

import { Box } from 'core/cells/box';
import { PrimaryButton } from 'core/cells/primary-button';
import { ExpandedCircularProgress } from 'core/cells/expanded-circular-progress';

import { useMutation, gql } from 'core/dna/types/apollo';
import { useTranslations } from 'core/dna/translations';

import { useTerm } from 'modules/planner/metabolism/terms';

import { YearlyCalendar } from 'modules/planner/organisms/yearly-calendar';

const updateHolidaysMutation = gql`
  mutation UpdateHolidays($termId: String!, $holidays: [String!]) {
    editTerm(input: { termId: $termId, holidays: $holidays }) {
      id
      name
      startDate {
        formatted
      }
      endDate {
        formatted
      }
      holidays {
        formatted
      }
    }
  }
`;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    },
    calendar: {
      display: 'flex',
      justifyContent: 'center',
    },
    formControl: {
      margin: '10px auto',
      minWidth: 120,
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    progress: {
      height: 'calc(100% + 30px)',
    },
  }),
);

const customCssClasses = {
  holidays: [],
  year: {
    start: '2009-03-21',
    end: '2029-6-20',
  },
  // summer: {
  //   start: '2019-06-21',
  //   end: '2019-09-22',
  // },
  // autumn: {
  //   start: '2019-09-23',
  //   end: '2019-12-21',
  // },
  weekend: 'Sat,Sun',
  // winter: (day: Moment) =>
  //   day.isBefore(moment([2019, 2, 21])) || day.isAfter(moment([2019, 11, 21])),
};

export const YearCalendar = () => {
  const classes = useStyles();
  const [selectedDays, setSelectedDays] = useState<Date[]>([]);
  const { texts } = useTranslations();

  const [updateHolidays] = useMutation(updateHolidaysMutation);

  const { term, loading, error } = useTerm();
  const [updating, setUpdating] = useState(false);

  useEffect(() => {
    if (!loading && !error) {
      const days = (term && term.holidays) || ([] as any[]);
      setSelectedDays(days);
    }
  }, [loading, error, term, setSelectedDays]);

  const dayPicked = (day: any) => {
    const filteredDates = selectedDays.filter(
      (selectedDay) => !day.isSame(selectedDay, 'day'),
    );
    if (filteredDates.length === selectedDays.length) {
      filteredDates.push(day);
    }
    setSelectedDays(filteredDates);
  };

  const renderCalendar = () => {
    const t = term || ({} as any);

    return (
      <YearlyCalendar
        startDay={t.startDate}
        endDay={t.endDate}
        selectedDays={selectedDays}
        showDaysOfWeek={true}
        forceFullWeeks={false}
        showWeekNumbers
        showWeekSeparators={false}
        firstDayOfWeek={1}
        onPickDay={dayPicked}
        customClasses={customCssClasses}
      />
    );
  };

  const saveHolidays = () => {
    if (term) {
      setUpdating(true);
      updateHolidays({
        variables: {
          termId: term.id,
          holidays: selectedDays.map((d) => tick(d).format('YYYY-M-D')),
        },
      })
        .then()
        .finally(() => {
          setUpdating(false);
        });
    }
  };

  return (
    <div className={classes.root}>
      <div className={classes.calendar}>
        {!term ? <div>Waiting for Term...</div> : renderCalendar()}
      </div>
      <Box centeredContent mt={2}>
        <PrimaryButton onClick={saveHolidays} disabled={updating}>
          {texts.planner.common.save}
        </PrimaryButton>
      </Box>
      <ExpandedCircularProgress
        open={updating}
        className={classes.progress}
        invisible
      />
    </div>
  );
};
