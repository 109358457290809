import { useMemo, useCallback, useState } from 'react';

import { RouteConfig } from 'core/dna/types/local';
import { useModuleRoutes } from 'core/dna/routing/module-routes';
import { getRoutes as getFlattenedRoutes } from 'core/dna/routing/functions';

import { useLocationChange } from '../use-location-change';

import { getActiveRoute } from './get-active-route';

export const useActiveRoute = () => {
  const routes = useModuleRoutes();

  const contentRoutes = useMemo<RouteConfig[]>(() => {
    return routes ? getFlattenedRoutes(routes) : [];
  }, [routes]);

  const [activeRoute, setActiveRoute] = useState<RouteConfig>(() =>
    getActiveRoute(contentRoutes),
  );

  const getActiveRouteFn = useCallback(() => getActiveRoute(contentRoutes), [
    contentRoutes,
  ]);

  useLocationChange(() => {
    setActiveRoute(getActiveRoute(contentRoutes));
  });

  return {
    getActiveRoute: getActiveRouteFn,
    activeRoute,
  };
};
