import { gql } from 'core/dna/types/apollo';

import { ORGANIZATION_DATA_FRAGMENT } from 'modules/planner/memory/apollo/_fragments';

export const GET_ORGANIZATION_QUERY = gql`
  query GetOrganization($organizationId: String!) {
    Organization(filter: { id: $organizationId }) {
      ...OrganizationData
      federation {
        id
        name
      }
      terms {
        id
        name
        startDate {
          formatted
        }
        endDate {
          formatted
        }
        holidays {
          formatted
        }
        allocationPlan {
          allocations {
            weekNumber
            published
          }
        }
      }
      features {
        code
        expired
        expiresAt {
          formatted
        }
      }
    }
  }
  ${ORGANIZATION_DATA_FRAGMENT}
`;
