import { useEffect } from 'react';

import { logger, LogType } from 'core/atoms/housekeeping';

import { Grid } from 'core/cells/grid';

import { SleepHoursTable } from './components/sleep-hours-table';

export const SleepHoursListMainContent = () => {
  useEffect(() => {
    logger.debug({
      title: 'did mount',
      type: LogType.Info,
      logger: 'sleep-hours-list-main-content',
    });
  }, []);

  return (
    <Grid container>
      <Grid item xs={12}>
        <SleepHoursTable />
      </Grid>
    </Grid>
  );
};
