import { createContext } from 'react';

import { OrganizationState } from 'core/dna/types/local';

export interface OrganizationsUpdaterContextValue {
  reset: (values: OrganizationState[]) => OrganizationState | undefined;
}

export const OrganizationsUpdaterContext = createContext<OrganizationsUpdaterContextValue>(
  {
    reset: () => {
      throw new Error('not implemented');
    },
  },
);

OrganizationsUpdaterContext.displayName = 'OrganizationsUpdater Context';
