import { useCallback } from 'react';

import { OasMemoryError, OasError } from 'core/atoms/errors';

import { gql, useMutation } from 'core/dna/types/apollo';

import {
  BatchState,
  UpdateStudentEvaluationInput,
} from 'core/dna/types/generated/_globalTypes';
import {
  UpdateStudentEvaluation,
  UpdateStudentEvaluationVariables,
} from 'core/dna/types/generated/UpdateStudentEvaluation';
import { GET_EVALUATION_GROUP_QUERY } from '../evaluation-group';

export const UPDATE_STUDENT_EVALUATION_MUTATION = gql`
  mutation UpdateStudentEvaluation($input: UpdateStudentEvaluationInput) {
    updateStudentEvaluation(input: $input) {
      updatedAt {
        formatted
      }
    }
  }
`;

const TITLE = 'use-update-student-evaluation';

export const useUpdateStudentEvaluation = () => {
  const [updateStudentEvaluation, { loading, error }] = useMutation<
    UpdateStudentEvaluation,
    UpdateStudentEvaluationVariables
  >(UPDATE_STUDENT_EVALUATION_MUTATION);

  const func = useCallback(
    async (input: UpdateStudentEvaluationInput, teacherTuid: string) => {
      try {
        const result = await updateStudentEvaluation({
          variables: {
            input: { ...input },
          },
          refetchQueries: [
            {
              query: GET_EVALUATION_GROUP_QUERY,
              variables: {
                filter: {
                  teacherTuid,
                  batchState: BatchState.open,
                },
              },
            },
          ],
        });

        if (result.errors) {
          throw new OasMemoryError(JSON.stringify(result.errors), {
            title: TITLE,
          });
        }
        return result.data?.updateStudentEvaluation ?? undefined;
      } catch (e: any) {
        if (e instanceof OasError) {
          throw e;
        } else {
          throw OasMemoryError.fromError(e, {
            title: TITLE,
          });
        }
      }
    },
    [updateStudentEvaluation],
  );

  return {
    updateStudentEvaluation: func,
    loading,
    error,
  };
};
