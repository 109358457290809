import { useMemo } from 'react';

import { ShortDataWithAbsences } from 'modules/student/dna/types';
import { mapAbsenceListToAbsenceTotal } from 'modules/reports/dna/functions';
import { StudentWithTotalAbsences } from 'modules/reports/dna/types';

export const useStudentsAbsencesTotal = (
  data: ShortDataWithAbsences[] | undefined,
) => {
  const total = useMemo<StudentWithTotalAbsences[]>(() => {
    if (!data) {
      return [];
    }

    return data.map((student) => ({
      tuid: student.tuid,
      details: student.details,
      total: mapAbsenceListToAbsenceTotal(student.absences),
    }));
  }, [data]);

  return {
    total,
  };
};
