import { useCallback, useMemo } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import SchoolIcon from '@mui/icons-material/School';

import { Skeleton } from 'core/cells/skeleton';

import { useLocale, useHistory } from 'core/dna/routing';
import { useTranslations } from 'core/dna/translations';

import { useGetTermsState } from 'core/memory/apollo/terms/local/get-terms-state';
import { createReactTableStore } from 'core/memory/pure/react-table-store';

import { useReactTableState } from 'core/metabolism/table-state';

import { Table } from 'templates/mdp/components/react-table';
import { Button } from 'templates/mdp/components/custom-buttons';
import { GridContainer, GridItem } from 'templates/mdp/components/grid';
import {
  Card,
  CardBody,
  CardHeader,
  CardIcon,
} from 'templates/mdp/components/card';
import { useStyles } from 'templates/mdp/views/tables/react-tables.styles';

import PlannerUrls from 'modules/planner/routing/urls';

import { useLessonGroupsTableWrite } from './use-lesson-groups-table-write';
import { useLessonGroupsTableRead } from './use-lesson-groups-table-read';

export const lessonGroupsTableStore = createReactTableStore({}, {});

export const LessonGroupsTable = () => {
  const classes = useStyles();
  const { texts } = useTranslations();
  const { push } = useHistory();
  const locale = useLocale();
  const { data: termsState } = useGetTermsState();

  const { columns, title, data, loading } = useLessonGroupsTableRead();
  const { onRowDelete } = useLessonGroupsTableWrite();

  const {
    initialPage,
    pageSize,
    sort,
    filters,
    setSort,
    setFilters,
    setPage,
    setPageSize,
  } = useReactTableState(
    lessonGroupsTableStore,
    termsState?.selected ?? undefined,
  );

  const handleUpdate = useCallback(
    async (lessonItemId: string) => {
      push(
        PlannerUrls.editLessonGroup({ lessonGroupId: lessonItemId, locale }),
      );
    },
    [locale, push],
  );

  const handleDelete = useCallback(
    async (lessonItemId: string) => {
      const tableItem = data!.find((o) => o.id === lessonItemId);
      await onRowDelete(tableItem!);
    },
    [data, onRowDelete],
  );

  const preparedData = useMemo<any[]>(() => {
    if (!data) {
      return [];
    }

    return data.map((lesson) => {
      return {
        ...lesson,
        canBeSplit: lesson.canBeSplit
          ? texts.planner.common.yes
          : texts.planner.common.no,
        actions: (
          <div className="actions-right">
            <Button
              justIcon
              round
              simple
              onClick={() => handleUpdate(lesson.id)}
              color="info"
              className="edit"
            >
              <EditIcon />
            </Button>
            <Button
              justIcon
              round
              simple
              onClick={() => handleDelete(lesson.id)}
              color="danger"
              className="remove"
            >
              <CloseIcon />
            </Button>
          </div>
        ),
      };
    });
  }, [data, handleDelete, handleUpdate, texts]);

  const handleAddLessonGroup = useCallback(() => {
    const url = PlannerUrls.createLessonGroup({ locale });
    return push(url);
  }, [locale, push]);

  return loading ? (
    <Skeleton variant="rect" width="100%" height="80vh" />
  ) : (
    <GridContainer>
      <GridItem xs={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button color="secondary" onClick={handleAddLessonGroup}>
          {texts.planner.preparationSpace.schedulesModule.addLessonGroup}
        </Button>
      </GridItem>
      <GridItem xs={12}>
        <Card>
          <CardHeader color="primary" icon>
            <CardIcon color="primary">
              <SchoolIcon />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>{title}</h4>
          </CardHeader>
          <CardBody>
            <Table
              columns={columns}
              data={preparedData}
              key={termsState?.selected ?? 'unknown_term'}
              onChangeRowsPerPage={setPageSize}
              onChangePage={setPage}
              onFilterChange={setFilters}
              onOrderChange={setSort}
              options={{
                initialPage,
                pageSize,
                sort,
                filters,
              }}
            />
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
};
