import { Student, StudentsTableItem } from 'modules/planner/dna/types/student';

export const mapStudentToStudentsTableItem = (
  student: Student,
): StudentsTableItem => {
  return {
    ...student,
    chaptersCount: student.chapters?.length ?? 0,
  };
};
