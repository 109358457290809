import React from 'react';
import { List } from '@material-ui/icons';

import { Skeleton } from 'core/cells/skeleton';
import { createReactTableStore } from 'core/memory/pure/react-table-store';
import { useGetTermsState } from 'core/memory/apollo/terms/local/get-terms-state';
import { useReactTableState } from 'core/metabolism/table-state';

import {
  Card,
  CardBody,
  CardHeader,
  CardIcon,
} from 'templates/mdp/components/card';
import { Table } from 'templates/mdp/components/react-table';
import { useStyles as reactTablesStyle } from 'templates/mdp/views/tables/react-tables.styles';

import { StudentWithTotalAbsences } from 'modules/reports/dna/types';

import { useStudentsAbsenceTableRead } from './use-students-absence-table-read';

interface StudentsAbsenceTableProps {
  data: StudentWithTotalAbsences[];
  loading: boolean;
}

export const personT2absencesTableStore = createReactTableStore({}, {});

export const StudentsAbsenceTable = ({
  data,
  loading,
}: StudentsAbsenceTableProps) => {
  const reactTablesClasses = reactTablesStyle();

  const { data: termsState } = useGetTermsState();

  const { columns, tableData, title } = useStudentsAbsenceTableRead(data);

  const {
    initialPage,
    pageSize,
    sort,
    filters,
    setSort,
    setFilters,
    setPage,
    setPageSize,
  } = useReactTableState(
    personT2absencesTableStore,
    termsState?.selected ?? undefined,
  );

  return loading || !termsState?.selected ? (
    <Skeleton variant="rect" width="100%" height="80vh" />
  ) : (
    <Card>
      <CardHeader color="primary" icon>
        <CardIcon color="primary">
          <List />
        </CardIcon>
        <h4 className={reactTablesClasses.cardIconTitle}>{title}</h4>
      </CardHeader>
      <CardBody>
        <Table
          noActions
          columns={columns}
          data={tableData ?? []}
          key={termsState.selected}
          onChangeRowsPerPage={setPageSize}
          onChangePage={setPage}
          onFilterChange={setFilters}
          onOrderChange={setSort}
          options={{
            initialPage,
            pageSize,
            sort,
            filters,
          }}
        />
      </CardBody>
    </Card>
  );
};
