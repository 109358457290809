import { useMemo } from 'react';

import { mapTeacherToTeacherTableItem } from 'modules/planner/dna/functions/map-teacher-to-teacher-table-item';

import { useGetTeachers } from 'modules/planner/memory/apollo/teachers/remote';

import { useTranslations } from 'core/dna/translations';

export const useTeachersTableRead = () => {
  const { texts } = useTranslations();
  const { teachers, teachersLoading } = useGetTeachers();

  const columns = useMemo(
    () => [
      {
        Header: texts.planner.common.firstName,
        accessor: 'firstName',
      },
      {
        Header: texts.planner.common.lastName,
        accessor: 'lastName',
      },
      {
        Header: texts.planner.otherAssignments,
        accessor: 'reservedEmploymentPercentage',
      },
      {
        Header: texts.planner.employmentPercentage,
        accessor: 'employmentPercentage',
      },
      {
        Header: texts.oasCommon.type,
        accessor: 'typeText',
      },
      {
        Header: texts.planner.common.availabilityCalendar,
        accessor: 'availabilityDefined',
        disableFilters: true,
      },
      // {
      //   Header: lang.common.nin,
      //   accessor: 'nin',
      // },
      {
        Header: texts.planner.common.actions.many,
        accessor: 'actions',
      },
    ],
    [texts],
  );

  return {
    title: texts.planner.common.teachers,
    columns,
    data:
      teachers
        ?.map((t) => mapTeacherToTeacherTableItem(t, texts))
        .sort((t1, t2) =>
          `${t1.firstName} ${t1.lastName}` > `${t2.firstName} ${t2.lastName}`
            ? -1
            : 1,
        ) ?? [],
    loading: teachersLoading,
  };
};
