import { useCallback, useMemo } from 'react';
import { Close, RecordVoiceOver } from '@material-ui/icons';

import { Skeleton } from 'core/cells/skeleton';

import { useTranslations } from 'core/dna/translations';
import { useHistory, useLocale } from 'core/dna/routing';

import { useGetTermsState } from 'core/memory/apollo/terms/local/get-terms-state';
import { createReactTableStore } from 'core/memory/pure/react-table-store';

import { useReactTableState } from 'core/metabolism/table-state';

import { Table } from 'templates/mdp/components/react-table';
import { Button } from 'templates/mdp/components/custom-buttons';
import { GridContainer, GridItem } from 'templates/mdp/components/grid';
import {
  Card,
  CardBody,
  CardHeader,
  CardIcon,
} from 'templates/mdp/components/card';

import PlannerUrls from 'modules/planner/routing/urls';

import { ColStudentGroups } from './components/col-student-groups';

import { useStudentsTableRead } from './use-students-table-read';
import { useStudentsTableWrite } from './use-students-table-write';

export interface StudentsTableProps {
  onImportStudentsClick?: () => void;
}

const tableStore = createReactTableStore({}, {});

export const StudentsTable = (props: StudentsTableProps) => {
  const { onImportStudentsClick } = props;
  const { texts } = useTranslations();
  const locale = useLocale();
  const { push } = useHistory();
  const { data: termsState } = useGetTermsState();

  const { columns, data, hasStudents, hasGroups } = useStudentsTableRead();
  const { onRowDelete } = useStudentsTableWrite();

  const {
    initialPage,
    pageSize,
    sort,
    filters,
    setSort,
    setFilters,
    setPage,
    setPageSize,
  } = useReactTableState(tableStore, termsState?.selected ?? undefined);

  const getStudentsTableItem = useCallback(
    (id?: string) => data.find((item) => id && item.id === id),
    [data],
  );

  const handleDelete = useCallback(
    async (studentId: string) => {
      await onRowDelete(getStudentsTableItem(studentId)!);
    },
    [getStudentsTableItem, onRowDelete],
  );

  const preparedData = useMemo<any[]>(() => {
    return data.map((prop) => {
      return {
        ...prop,
        chaptersCount: <ColStudentGroups item={prop} />,
        actions: (
          <div className="actions-right">
            <Button
              justIcon
              round
              simple
              onClick={() => handleDelete(prop.id)}
              color="danger"
              className="remove"
            >
              <Close />
            </Button>
          </div>
        ),
      };
    });
  }, [data]);

  const handleOrganizeStudentsClick = useCallback(() => {
    hasStudents && hasGroups && push(PlannerUrls.classesStudentsGroups(locale));
  }, [hasGroups, hasStudents, locale, push]);

  return !termsState?.selected ? (
    <Skeleton variant="rect" width="100%" height="80vh" />
  ) : (
    <GridContainer>
      <GridItem xs={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
        {hasStudents && hasGroups ? (
          <Button color="secondary" onClick={handleOrganizeStudentsClick}>
            {texts.planner.organizeStudents}
          </Button>
        ) : (
          <> </>
        )}
        <Button color="secondary" onClick={onImportStudentsClick}>
          {texts.planner.importStudents}
        </Button>
      </GridItem>
      <GridItem xs={12}>
        <Card>
          <CardHeader color="primary" icon>
            <CardIcon color="primary">
              <RecordVoiceOver />
            </CardIcon>
          </CardHeader>
          <CardBody>
            <Table
              columns={columns}
              data={preparedData}
              key={termsState?.selected}
              name="students-table"
              onChangeRowsPerPage={setPageSize}
              onChangePage={setPage}
              onFilterChange={setFilters}
              onOrderChange={setSort}
              options={{
                initialPage,
                pageSize,
                sort,
                filters,
              }}
            />
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
};
