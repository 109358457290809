import clsx from 'clsx';

import { grey, red } from 'core/atoms/colors';
import { makeStyles, createStyles, useTheme } from 'core/atoms/styles';
import { capitalize } from 'core/atoms/functions';
import { Ref, forwardRef as ReactForwardRef, useMemo } from 'react';

export interface NotchedOutlineProps {
  /** The width of the label. */
  labelWidth?: number;
  labelScale?: number;
  focused?: boolean;
  /**
   * If `true`, the outline is notched to accommodate the label.
   */
  notched?: boolean;
  selected?: boolean;
  className?: string;
  error?: boolean;
}

const defaultProps: Partial<NotchedOutlineProps> = {
  notched: false,
};

const useStyles = makeStyles((theme) =>
  createStyles({
    /* Styles applied to the root element. */
    root: {
      position: 'absolute',
      bottom: 0,
      right: 0,
      top: -5,
      left: 0,
      margin: 0,
      padding: 0,
      pointerEvents: 'none',
      borderRadius: theme.shape.borderRadius,
      borderStyle: (props: NotchedOutlineProps) =>
        props.focused ? 'solid' : 'dashed',
      borderWidth: (props: NotchedOutlineProps) =>
        props.focused || props.selected ? 2 : 1,
      borderColor: (props: NotchedOutlineProps) =>
        props.error
          ? theme.palette.extended?.danger[0] ?? red['500']
          : props.focused
          ? theme.palette.primary.main
          : props.selected
          ? grey['900']
          : theme.palette.form.default.layout.normal,
      // Match the Input Label
      transition: theme.transitions.create(
        [
          `padding-${theme.direction === 'rtl' ? 'right' : 'left'}`,
          'border-color',
          'border-width',
        ],
        {
          duration: theme.transitions.duration.shorter,
          easing: theme.transitions.easing.easeOut,
        },
      ),
    },
    /* Styles applied to the legend element. */
    legend: {
      textAlign: 'left',
      padding: 0,
      lineHeight: '11px',
      transition: theme.transitions.create('width', {
        duration: theme.transitions.duration.shorter,
        easing: theme.transitions.easing.easeOut,
      }),
    },
  }),
);

/**
 * @ignore - internal component.
 */
export const NotchedOutline = ReactForwardRef(
  (props: NotchedOutlineProps, ref: React.Ref<HTMLFieldSetElement>) => {
    const classes = useStyles(props);

    const {
      labelWidth: labelWidthProp = 0,
      labelScale,
      className,
      focused,
      notched = defaultProps.notched,
      selected,
      ...other
    } = props;
    const theme = useTheme();

    const align = theme.direction === 'rtl' ? 'right' : 'left';

    const labelWidth = useMemo(
      () =>
        labelWidthProp > 0 ? labelWidthProp * (labelScale ?? 0.75) + 8 : 0,
      [labelScale, labelWidthProp],
    );

    return (
      <fieldset
        aria-hidden
        style={{
          [`padding${capitalize(align)}`]: 8 + (notched ? 0 : labelWidth / 2),
          // ...style,
        }}
        className={clsx(classes.root, className)}
        ref={ref}
        {...other}
      >
        <legend
          className={classes.legend}
          style={{
            // IE 11: fieldset with legend does not render
            // a border radius. This maintains consistency
            // by always having a legend rendered
            width: notched ? labelWidth : 0.01,
          }}
        >
          {/* Use the nominal use case of the legend, avoid rendering artifacts. */}
          {/* eslint-disable-next-line react/no-danger */}
          <span dangerouslySetInnerHTML={{ __html: '&#8203;' }} />
        </legend>
      </fieldset>
    );
  },
);

NotchedOutline.defaultProps = defaultProps;

function forwardRef(
  arg0: (
    props: NotchedOutlineProps,
    ref: Ref<HTMLFieldSetElement>,
  ) => JSX.Element,
) {
  throw new Error('Function not implemented.');
}
