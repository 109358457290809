import { forwardRef, useMemo, useCallback } from 'react';

import { parseTemplate } from 'core/atoms/functions/string';

import { useHistory, useLocale } from 'core/dna/routing';
import { useTranslations } from 'core/dna/translations';
import { LinkButton } from 'core/cells/link-button';

import { ClassTableItem } from 'modules/planner/dna/types/class';

import { useClasses } from 'modules/planner/metabolism/use-classes';

import PlannerUrls from 'modules/planner/routing/urls';

export interface ColStudentsProps {
  item?: ClassTableItem;
}

interface ContentProps {
  text: string;
  onClick: (e: any) => void;
}

const Content = forwardRef((props: ContentProps, ref) => {
  const { text, onClick, ...other } = props;

  return (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    <LinkButton ref={ref} onClick={onClick} {...other} color="primary">
      {text}
    </LinkButton>
  );
});

export const ColStudents = (props: ColStudentsProps) => {
  const { item } = props;

  const { texts } = useTranslations();
  const history = useHistory();
  const locale = useLocale();

  const { setSelectedClasses } = useClasses();

  const text = useMemo(() => {
    // if (!item?.studentsCount || item.studentsCount < 2) {
    //   return texts.planner.common.manageCount.one;
    //   // return lang.common.definedCountChange.one;
    // }
    return parseTemplate(texts.planner.common.manageCount.many, {
      count: String(item?.studentsCount ?? 0),
    });
  }, [item, texts]);

  const handleClick = useCallback(() => {
    if (item) {
      setSelectedClasses([item.id]);
      history.push(PlannerUrls.classesStudents(locale));
    }
    return false;
  }, [history, item, locale, setSelectedClasses]);

  return item ? <Content text={text} onClick={handleClick} /> : <span> </span>;
};
