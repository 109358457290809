import {
  Theme,
  makeStyles,
  createStyles,
  CSSProperties,
} from 'core/atoms/styles';

import { defaultFont } from 'templates/mdp/material-dashboard-pro-react';

import {
  customDropdownStyle,
  CustomDropdownStyle,
} from 'templates/mdp/components/custom-dropdown/custom-dropdown.styles';

export interface DashboardNavbarLinksStyle extends CustomDropdownStyle {
  linkText: CSSProperties;
  buttonLink: CSSProperties;
  links: CSSProperties;
  wrapperRTL: CSSProperties;
  buttonLinkRTL: CSSProperties;
  labelRTL: CSSProperties;
  linksRTL: CSSProperties;
  managerClasses: CSSProperties;
  headerLinksSvg: CSSProperties;
}

export const dashboardNavbarLinksStyle = (
  theme: Theme,
): DashboardNavbarLinksStyle => ({
  ...customDropdownStyle(theme),
  linkText: {
    zIndex: 4,
    ...defaultFont,
    fontSize: '14px',
    margin: '0!important',
    textTransform: 'none',
  },
  buttonLink: {
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      margin: '5px 15px 0',
      width: 'auto',
      height: 'auto',
      '& svg': {
        width: '30px',
        height: '24px',
        marginRight: '19px',
        marginLeft: '3px',
      },
      '& .fab,& .fas,& .far,& .fal,& .material-icons': {
        width: '30px',
        fontSize: '24px',
        lineHeight: '30px',
        marginRight: '19px',
        marginLeft: '3px',
      },
    },
  },
  links: {
    width: '20px',
    height: '20px',
    zIndex: 4,
    [theme.breakpoints.down('sm')]: {
      display: 'block',
      width: '30px',
      height: '30px',
      color: 'inherit',
      opacity: '0.8',
      marginRight: '16px',
      marginLeft: '-5px',
    },
  },
  wrapperRTL: {
    [theme.breakpoints.up('md')]: {
      paddingLeft: '16px',
    },
  },
  buttonLinkRTL: {
    [theme.breakpoints.down('sm')]: {
      alignItems: 'center',
      justifyContent: 'flex-end',
      width: '-webkit-fill-available',
      margin: '10px 15px 0',
      padding: '10px 15px',
      display: 'block',
      position: 'relative',
    },
  },
  labelRTL: {
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'row-reverse',
      justifyContent: 'initial',
      display: 'flex',
    },
  },
  linksRTL: {
    [theme.breakpoints.down('sm')]: {
      marginRight: '-5px !important',
      marginLeft: '16px !important',
    },
  },
  managerClasses: {
    [theme.breakpoints.up('md')]: {
      display: 'inline-block',
    },
  },
  headerLinksSvg: {
    width: '32px !important',
    height: '32px !important',
  },
});

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({ ...dashboardNavbarLinksStyle(theme) }),
);
