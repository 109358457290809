import { gql } from 'core/dna/types/apollo';

export const CREATE_TERM_MUTATION = gql`
  mutation CreateTerm($input: CreateTermInput) {
    createTerm(input: $input) {
      name
      id
    }
  }
`;
