import { CURRENT_SCHEDULE } from './constants';

const hasCurrentSchedule = () => {
  return !!localStorage.getItem(CURRENT_SCHEDULE);
};

const getCurrentSchedule = (): string | null => {
  return hasCurrentSchedule() ? localStorage.getItem(CURRENT_SCHEDULE) : null;
};

const removeCurrentSchedule = () => {
  hasCurrentSchedule() && localStorage.removeItem(CURRENT_SCHEDULE);
};

const setCurrentSchedule = (schedule: string | null) => {
  if (schedule) {
    localStorage.setItem(CURRENT_SCHEDULE, schedule);
  } else {
    removeCurrentSchedule();
  }
};

export const getCurrentScheduleStorage = () => {
  return {
    key: CURRENT_SCHEDULE,
    hasCurrentSchedule,
    getCurrentSchedule,
    removeCurrentSchedule,
    setCurrentSchedule,
  };
};
