import { INITIAL_PAGE_KEY } from './constants';

const hasInitialPage = () => {
  return !!localStorage.getItem(INITIAL_PAGE_KEY);
};

const removeInitialPage = () => {
  hasInitialPage() && localStorage.removeItem(INITIAL_PAGE_KEY);
};

const getInitialPage = (): {
  fullPath: string;
  path: string;
  search?: string;
} | null => {
  const exists = hasInitialPage();
  if (!exists) {
    return null;
  }
  const item = localStorage.getItem(INITIAL_PAGE_KEY) ?? '';
  const itemArray = item.split('?');

  return {
    fullPath: item,
    path: itemArray[0],
    search: itemArray[1] || undefined,
  };
};

const setInitialPage = (
  initialPage: { path: string; search?: string } | null,
) => {
  if (initialPage) {
    localStorage.setItem(
      INITIAL_PAGE_KEY,
      `${initialPage.path}?${initialPage.search ?? ''}`,
    );
  } else {
    removeInitialPage();
  }
};

export const getInitialPageStorage = () => {
  return {
    key: INITIAL_PAGE_KEY,
    hasInitialPage,
    removeInitialPage,
    getInitialPage,
    setInitialPage,
  };
};
