import {
  StudentBasicData,
  CalendarWeeklyEvent,
} from 'modules/student/dna/types';

export const mapStudentDataToStudentWeeklyEvents = (
  data: StudentBasicData,
): CalendarWeeklyEvent[] | null => {
  const weeklyEvents = data.configuration?.weeklyEvents;

  if (!weeklyEvents || !weeklyEvents.length) {
    return null;
  }

  return weeklyEvents.map((we) => ({
    title: `${data.details.firstName} ${data.details.lastName}`,
    description: we.value,
    startTime: we.startTime,
    endTime: we.endTime,
    daysOfWeek: we.days,
  }));
};

export const mapStudentListToWeeklyEventsList = (
  studentList: StudentBasicData[],
) => {
  const events: CalendarWeeklyEvent[] = [];

  studentList.forEach((student) => {
    const weeklyEvents = mapStudentDataToStudentWeeklyEvents(student);

    if (weeklyEvents) {
      events.push(...weeklyEvents);
    }
  });

  return events;
};
