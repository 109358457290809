import { flattenDeep } from 'core/atoms/functions';
import { RouteConfig } from 'core/dna/types/local';
import { OasRoutingError } from 'core/atoms/errors';

const defaultMapper = (route: RouteConfig) => route as any;

const TITLE = 'getRoutes';

const getRoutesRecursive = <TOut = any>(
  routes: RouteConfig[],
  mapper: (route: RouteConfig) => any,
): TOut[] => {
  return routes.map((route, key) => {
    if ('collapse' in route) {
      if (!route.views) {
        throw new OasRoutingError(
          '"views" property is undefined but should be because of "collapse" is defined',
          { title: TITLE },
        );
      }
      return getRoutesRecursive(route.views, mapper);
    }
    if (route.views) {
      const { views, ...rest } = route;
      return getRoutesRecursive([{ ...rest }, ...route.views], mapper);
    }

    return mapper(route);
  });
};

export const getRoutes = <TOut = any>(
  routes: RouteConfig[],
  mapper = defaultMapper,
): TOut[] => flattenDeep(getRoutesRecursive<TOut>(routes, mapper));
