import { CSSProperties, makeStyles, createStyles } from 'core/atoms/styles';

import {
  cardTitle,
  dangerColor,
  whiteColor,
  grayColor,
} from '../../material-dashboard-pro-react';

import {
  customCheckboxRadioSwitch,
  CustomCheckboxRadioSwitch,
} from '../../custom-checkbox-radio-switch';

export interface ValidationFormsStyle extends CustomCheckboxRadioSwitch {
  cardTitle: CSSProperties;
  cardIconTitle: CSSProperties;
  formCategory: CSSProperties;
  center: CSSProperties;
  justifyContentCenter: CSSProperties;
  registerButton: CSSProperties;
  danger: CSSProperties;
}

export const validationFormsStyle: ValidationFormsStyle = {
  ...customCheckboxRadioSwitch,
  cardTitle: {
    ...cardTitle,
    color: whiteColor,
  },
  cardIconTitle: {
    ...cardTitle,
    marginTop: '15px',
    marginBottom: '0px',
    textAlign: 'left',
  },
  formCategory: {
    marginBottom: '0',
    color: grayColor[0],
    fontSize: '14px',
    padding: '10px 0 10px',
  },
  center: {
    textAlign: 'center',
  },
  justifyContentCenter: {
    justifyContent: 'center',
  },
  registerButton: {
    float: 'right',
  },
  danger: {
    color: `${dangerColor[0]}!important`,
  },
};

export const useStyles = makeStyles(() =>
  createStyles({ ...validationFormsStyle }),
);
