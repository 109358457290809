import { useState, useEffect, useMemo, useCallback } from 'react';

import {
  makeStyles,
  Theme,
  createStyles,
  PropTypeColor,
} from 'core/atoms/styles';

import { Box } from 'core/cells/box';
import { OutlinedArea } from 'core/cells/outlined-area';
import { Chip } from 'core/cells/chip';

import { ChapterSummary } from 'modules/planner/dna/types/chapter';

import { DeleteLessonPropertyFn, LessonProperty } from '../../../../../types';

export interface ChapterListProps {
  data?: LessonProperty[];
  onDelete?: DeleteLessonPropertyFn;
  color?: PropTypeColor;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    margin: {
      margin: theme.spacing(0.5),
    },
    padding: {
      padding: theme.spacing(0.5),
    },
  }),
);

export const ChapterList = (props: ChapterListProps) => {
  const classes = useStyles();

  const { data, onDelete } = props;

  const [classIds, setClassIds] = useState<string[]>();

  useEffect(() => {
    const ids = data
      ? [...new Set(data.map((c) => c.data!.class.id))]
      : undefined;
    setClassIds(ids);
  }, [data]);

  const ChapterItem = (chapterSummary: ChapterSummary) => {
    const handleDeleteChapter = useCallback(() => {
      if (!onDelete) {
        return;
      }
      const chapter = data?.find((c) => c.id === chapterSummary.id);
      chapter && onDelete<ChapterSummary>(chapter);
    }, [chapterSummary.id]);

    return (
      <Chip
        size="small"
        label={chapterSummary.name}
        onDelete={handleDeleteChapter}
        className={classes.margin}
      />
    );
  };

  const ClassItem = ({ classId }: { classId: string }) => {
    const classChapters = useMemo(() => {
      if (!data || !data.length) {
        return undefined;
      }
      const filteredChapters = data.filter((c) => c.data!.class.id === classId);
      return filteredChapters.length ? filteredChapters : undefined;
    }, [classId]);

    const label = classChapters ? classChapters[0].data!.class.name : '  ';

    return (
      <OutlinedArea
        minWidth={10}
        minHeight={10}
        label={label}
        focused
        className={classes.margin}
      >
        <Box fullSize centeredContent className={classes.padding}>
          {classChapters &&
            classChapters.map((c) => <ChapterItem key={c.id} {...c.data!} />)}
        </Box>
      </OutlinedArea>
    );
  };

  return (
    <div className={classes.root}>
      {classIds && classIds.map((cId) => <ClassItem key={cId} classId={cId} />)}
    </div>
  );
};
