import {
  Theme,
  CSSProperties,
  makeStyles,
  createStyles,
} from 'core/atoms/styles';

import transponderWhiteImage from 'core/atoms/pictures/transponder-white.png';

import {
  container,
  cardTitle,
  whiteColor,
  grayColor,
  primaryColor,
  hexToRgb,
  blackColor,
} from 'templates/mdp/material-dashboard-pro-react';

export interface LockPageStyle {
  container: CSSProperties;
  customCardClass: CSSProperties;
  cardTitle: CSSProperties;
  textCenter: CSSProperties;
  justifyContentCenter: CSSProperties;
  customButtonClass: CSSProperties;
  inputAdornment: CSSProperties;
  inputAdornmentIcon: CSSProperties;
  cardHidden: CSSProperties;
  cardHeader: CSSProperties;
  logo: CSSProperties;
  hrText: CSSProperties;
  paddingHorizontal: CSSProperties;
}

export const lockPageStyle = (theme: Theme): LockPageStyle => ({
  container: {
    ...container,
    zIndex: 4,
    [theme.breakpoints.down('sm')]: {
      paddingBottom: '100px',
    },
  },
  customCardClass: {
    width: 240,
    margin: '60px auto 0',
    color: whiteColor,
    display: 'block',
    transform: `translate3d(${hexToRgb(blackColor)})`,
    transition: 'all 300ms linear',
  },
  cardTitle: {
    ...cardTitle,
    color: whiteColor,
  },
  textCenter: {
    textAlign: 'center',
  },
  justifyContentCenter: {
    justifyContent: 'center !important',
  },
  customButtonClass: {
    '&,&:focus,&:hover': {
      color: whiteColor,
    },
    marginLeft: '5px',
    marginRight: '5px',
  },
  inputAdornment: {
    marginRight: '18px',
  },
  inputAdornmentIcon: {
    color: grayColor[6],
  },
  cardHidden: {
    opacity: '0',
    transform: 'translate3d(0, -60px, 0)',
  },
  cardHeader: {
    marginBottom: [[theme.spacing(3)], '!important'] as any,
  },
  logo: {
    backgroundImage: `url("${transponderWhiteImage}")`,
    backgroundPosition: 'top left',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    paddingLeft: theme.spacing(4),
    width: 'fit-content',
    display: 'inline-block',
  },
  hrText: {
    width: '100%',
    textAlign: 'center',
    borderBottom: `1px solid ${primaryColor[1]}`,
    color: primaryColor[0],
    lineHeight: '0.1em',
    margin: '14px 0 0',
    '& span': {
      backgroundColor: '#fff',
      padding: theme.spacing(0, 0.5),
    },
  },
  paddingHorizontal: {
    padding: theme.spacing(0, 5),
  },
});

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({ ...lockPageStyle(theme) }),
);
