import React, { useCallback, useMemo } from 'react';

import { ApolloError } from '@apollo/client';
import { OrganizationFeatureCode } from 'core/dna/types/generated/_globalTypes';
import { PersonT2 } from 'modules/student/dna/types';

import { isFeatureAvailable } from 'core/dna/functions';
import { useTranslations } from 'core/dna/translations';
import { useOrganization } from 'core/metabolism/organizations';

import { StudentAbsence, StudentCarers, StudentRemarks } from '../wizard';
import { StudentProfile } from '../wizard/student-profile';
import { StudentJournals } from '../wizard/student-journals';

interface UseCardContentProps {
  data?: PersonT2;
  error?: ApolloError;
}

export const useCardContent = ({ data, error }: UseCardContentProps) => {
  const { texts } = useTranslations();
  const { organization } = useOrganization();

  const featureTabs = useMemo(
    () => [
      {
        stepName: texts.studentModule.studentCommon.absence.many as string,
        stepComponent: () => (
          <StudentAbsence data={data?.absence} error={error} />
        ),
        stepId: OrganizationFeatureCode.absence,
      },
      {
        stepName: texts.studentModule.studentCommon.remark.many as string,
        stepComponent: () => (
          <StudentRemarks data={data?.remarks} error={error} />
        ),
        stepId: OrganizationFeatureCode.remarks,
      },
      {
        stepName: texts.studentModule.studentCommon.journal.one as string,
        stepComponent: () => (
          <StudentJournals data={data?.journals} error={error} />
        ),
        stepId: OrganizationFeatureCode.journal,
      },
    ],
    [data, error, texts],
  );

  const betaTabs = useMemo(
    () => [
      {
        stepName: texts.studentModule.studentCommon.profile as string,
        stepComponent: () => (
          <StudentProfile
            tuid={data?.tuid}
            data={data?.configuration}
            error={error}
          />
        ),
        stepId: 'profile',
      },
    ],
    [data, error, texts],
  );

  const alwaysVisibleTabs = useMemo(
    () => [
      {
        stepName: texts.studentModule.studentCommon.carer.many as string,
        stepComponent: () => (
          <StudentCarers data={data?.carers} error={error} />
        ),
        stepId: 'carers',
      },
    ],
    [data, error, texts],
  );

  const func = useCallback(() => {
    const availableTabs = featureTabs.filter((tab) =>
      isFeatureAvailable(organization, tab.stepId),
    );

    return isFeatureAvailable(organization, OrganizationFeatureCode.beta)
      ? [...availableTabs, ...alwaysVisibleTabs, ...betaTabs]
      : [...availableTabs, ...alwaysVisibleTabs];
  }, [alwaysVisibleTabs, betaTabs, featureTabs, organization]);

  return { getContent: func };
};
