import { useCallback } from 'react';

import { gql, useMutation } from 'core/dna/types/apollo';
import { StartSchedulingInput } from 'core/dna/types/generated/_globalTypes';
import {
  StartScheduling,
  StartSchedulingVariables,
} from 'core/dna/types/generated/StartScheduling';

const START_SCHEDULING_MUTATION = gql`
  mutation StartScheduling($input: StartSchedulingInput!) {
    startScheduling(input: $input) {
      status
      description
    }
  }
`;

export const useStartScheduling = () => {
  const [startScheduling, { loading, error }] = useMutation<
    StartScheduling,
    StartSchedulingVariables
  >(START_SCHEDULING_MUTATION);

  const func = useCallback(
    (input: StartSchedulingInput) => {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise<any>(async (resolve, reject) => {
        try {
          const executionResult = await startScheduling({
            variables: {
              input: {
                ...input,
              },
            },
          });

          if (executionResult.data && !executionResult.errors) {
            resolve(executionResult.data.startScheduling);
          } else {
            reject(new Error('startScheduling error!'));
          }
        } catch (e: any) {
          reject(e);
        }
      });
    },
    [startScheduling],
  );

  return {
    startScheduling: func,
    loading,
    error,
  };
};
