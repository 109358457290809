import { useMemo } from 'react';
import { useQuery } from 'core/dna/types/apollo';

import { GetSyncStaff } from 'core/dna/types/generated/GetSyncStaff';
import { SyncStaff } from 'modules/planner/dna/types/sync-staff';
import { mapApiSyncStaffListToSyncStaffList } from 'modules/planner/dna/functions/map-ApiSyncStaff-to-SyncStaff';

import { GET_SYNC_STAFF_QUERY } from './get-sync-staff-query';

export const useGetSyncStaff = () => {
  const { data, loading, error } = useQuery<GetSyncStaff>(GET_SYNC_STAFF_QUERY);

  const syncStaff = useMemo<SyncStaff[] | undefined>(() => {
    if (!loading && !error && data) {
      return mapApiSyncStaffListToSyncStaffList(data.SyncStaff);
    }
  }, [data, error, loading]);

  return {
    data: syncStaff,
    loading,
    error,
  };
};
