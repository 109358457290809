import { useCallback } from 'react';

import { OasMemoryError, OasError } from 'core/atoms/errors';

import { gql, useMutation } from 'core/dna/types/apollo';
import { DeleteAllTimeSlotForSchedule } from 'core/dna/types/generated/_globalTypes';
import {
  DeleteAllTimeslotsForScheduleMutation,
  DeleteAllTimeslotsForScheduleMutationVariables,
} from 'core/dna/types/generated/DeleteAllTimeslotsForScheduleMutation';

const DELETE_ALL_TIMESLOTS_FOR_SCHEDULE_MUTATION = gql`
  mutation DeleteAllTimeslotsForScheduleMutation(
    $input: DeleteAllTimeSlotForSchedule!
  ) {
    deleteAllTimeSlotsForSchedule(input: $input) {
      status
      statusCode
      description
    }
  }
`;

const TITLE = 'UseDeleteAllTimeslots';

export const useDeleteAllTimeslots = () => {
  // const { getTermState } = useLazyGetTermsState();

  const [deleteAllTimeslots] = useMutation<
    DeleteAllTimeslotsForScheduleMutation,
    DeleteAllTimeslotsForScheduleMutationVariables
  >(DELETE_ALL_TIMESLOTS_FOR_SCHEDULE_MUTATION);

  const func = useCallback(
    async (input: DeleteAllTimeSlotForSchedule) => {
      try {
        // const termsState = await getTermState(true);

        const result = await deleteAllTimeslots({
          variables: { input },
          // refetchQueries: [
          //   {
          //     query: GET_SCHEDULE_DESCRIPTIONS_QUERY,
          //     variables: { termId: termsState!.selected! },
          //   },
          // ],
        });

        if (result.errors) {
          throw new OasMemoryError(JSON.stringify(result.errors), {
            title: TITLE,
          });
        }
        return result.data?.deleteAllTimeSlotsForSchedule ?? undefined;
      } catch (e: any) {
        if (e instanceof OasError) {
          throw e;
        } else {
          throw OasMemoryError.fromError(e, {
            title: TITLE,
          });
        }
      }
    },
    [deleteAllTimeslots],
  );

  return {
    deleteAllTimeslots: func,
  };
};
