import { useMemo, useEffect, useCallback } from 'react';

import { logger, LogType } from 'core/atoms/housekeeping';

import { useTranslations } from 'core/dna/translations';

import { JournalType } from 'core/dna/types/generated/_globalTypes';
import { JournalEntry } from 'core/dna/types/generated/JournalEntry';
import { ShortData, ShortDataWithJournals } from 'modules/student/dna/types';
import { useGetJournals } from 'modules/student/memory/apollo/students/remote/get-journals';

interface CountingListItem extends ShortData {
  attendance: JournalEntry[];
}

export const useCountingListRead = () => {
  const { texts } = useTranslations();
  const { data, loading } = useGetJournals();

  useEffect(() => {
    logger.debug({
      title: 'did mount',
      type: LogType.Info,
      logger: 'use-counting-list-read',
    });
  }, []);

  useEffect(() => {
    logger.debug({
      title: 'on data',
      type: LogType.Info,
      logger: 'use-counting-list-read',
      value: data?.length ? data : data?.length === 0 ? 'NO DATA' : 'UNDEFINED',
    });
  }, [data]);

  const isToday = (isoString: string) => {
    const inputDate = new Date(isoString);
    const today = new Date();

    return (
      inputDate.getDate() === today.getDate() &&
      inputDate.getMonth() === today.getMonth() &&
      inputDate.getFullYear() === today.getFullYear()
    );
  };

  const mapJournalsToTodayAttendance = useCallback(
    (journals: JournalEntry[] | undefined) => {
      const attendances = journals?.filter(
        (j) => j.type === JournalType.attendance && j.data,
      );

      if (!attendances || !attendances[attendances.length - 1].data.present) {
        return [];
      }

      return attendances.filter(
        (j) => j.signedBy.date?.formatted && isToday(j.signedBy.date.formatted),
      );
    },
    [],
  );

  const listData = useMemo<CountingListItem[]>(() => {
    if (!data) {
      return [];
    }

    const hasAttendance = data.filter((student) =>
      student.journals?.some((j) => j.type === JournalType.attendance),
    );

    const studentWithAttendance: CountingListItem[] = hasAttendance.map(
      (student: ShortDataWithJournals) => ({
        tuid: student.tuid,
        details: student.details,
        attendance: mapJournalsToTodayAttendance(student.journals).slice(-2),
      }),
    );

    return studentWithAttendance.filter((s) => !!s.attendance?.length);
  }, [data, mapJournalsToTodayAttendance]);

  return {
    title: texts.studentModule.counting.countingList,
    data: listData,
    loading,
  };
};
