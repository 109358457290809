import { useCallback } from 'react';
import { gql, useMutation } from 'core/dna/types/apollo';
import { OasError, OasMemoryError } from 'core/atoms/errors';
import { DeleteEvaluationGroupAccessInput } from 'core/dna/types/generated/_globalTypes';
import {
  DeleteEvaluationGroupAccess,
  DeleteEvaluationGroupAccessVariables,
} from 'core/dna/types/generated/DeleteEvaluationGroupAccess';

import { GET_EVALUATION_GROUP_ACCESS_QUERY } from './use-get-evaluation-group-access';

export const DELETE_EVALUATION_GROUP_ACCESS_MUTATION = gql`
  mutation DeleteEvaluationGroupAccess(
    $input: DeleteEvaluationGroupAccessInput
  ) {
    deleteEvaluationGroupAccess(input: $input) {
      ok
    }
  }
`;

const TITLE = 'use-delete-evaluation-group-access';

export const useDeleteEvaluationGroupAccess = () => {
  const [deleteEvaluationGroupAccess, { loading, error }] = useMutation<
    DeleteEvaluationGroupAccess,
    DeleteEvaluationGroupAccessVariables
  >(DELETE_EVALUATION_GROUP_ACCESS_MUTATION);

  const func = useCallback(
    async (input: DeleteEvaluationGroupAccessInput) => {
      try {
        const result = await deleteEvaluationGroupAccess({
          variables: {
            input: { ...input },
          },
          refetchQueries: [
            {
              query: GET_EVALUATION_GROUP_ACCESS_QUERY,
            },
          ],
        });

        if (result.errors) {
          throw new OasMemoryError(JSON.stringify(result.errors), {
            title: TITLE,
          });
        }
        return result.data?.deleteEvaluationGroupAccess ?? undefined;
      } catch (e: any) {
        if (e instanceof OasError) {
          throw e;
        } else {
          throw OasMemoryError.fromError(e, {
            title: TITLE,
          });
        }
      }
    },
    [deleteEvaluationGroupAccess],
  );

  return {
    deleteEvaluationGroupAccess: func,
    loading,
    error,
  };
};
