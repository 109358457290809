import { useCallback, useMemo } from 'react';
import { useReactiveVar } from '@apollo/client';

// import { AnyMap } from 'core/atoms/types';
//
// import { gql, useQuery } from 'core/dna/types/apollo';
// import { GetClassStudentsGroupsMapState } from 'core/dna/types/generated/GetClassStudentsGroupsMapState';

import { StudentsGroupsState } from 'modules/planner/dna/types/local';
import {
  classStudentsGroupsMapStateVar,
  selectedClassStateVar,
} from 'modules/planner/memory/apollo/cache';

const initialState: StudentsGroupsState = {
  unions: { byId: {}, allIds: [] },
  status: 'empty',
  assigning: false,
  changedFromLastAssignment: false,
  assignSuccess: undefined,
  assignError: undefined,
  assignmentsStatus: 'unassigned',
};

// const GET_CLASS_STUDENTS_GROUPS_MAP_STATE_QUERY = gql`
//   query GetClassStudentsGroupsMapState {
//     classStudentsGroupsMapState @client
//     selectedClassState @client
//   }
// `;

export const useGetStudentsGroupsState = () => {
  // const { data, loading, error } = useQuery<GetClassStudentsGroupsMapState>(
  //   GET_CLASS_STUDENTS_GROUPS_MAP_STATE_QUERY,
  // );
  //
  // const studentsGroupsStateMap = useMemo(() => {
  //   if (!loading && !error && data?.classStudentsGroupsMapState) {
  //     return data.classStudentsGroupsMapState as AnyMap<StudentsGroupsState>;
  //   }
  // }, [loading, error, data]);
  //
  // const studentsGroupsState = useMemo(() => {
  //   if (
  //     !loading &&
  //     !error &&
  //     data?.selectedClassState &&
  //     studentsGroupsStateMap
  //   ) {
  //     return studentsGroupsStateMap.byId[data.selectedClassState];
  //   }
  // }, [loading, error, data, studentsGroupsStateMap]);

  const data = useReactiveVar(classStudentsGroupsMapStateVar);
  const selectedClassId = useReactiveVar(selectedClassStateVar);

  const getStudentsGroupsForClass = useCallback(
    (classId?: string) => {
      return classId ? data.byId[classId] ?? initialState : initialState;
    },
    [data],
  );

  const getStudentsGroupsForAllClasses = useCallback(() => data, [data]);

  const studentsGroupsState = useMemo(
    () => getStudentsGroupsForClass(selectedClassId),
    [getStudentsGroupsForClass, selectedClassId],
  );

  return {
    // studentsGroupsStateMap,
    // studentsGroupsState,
    getStudentsGroupsForClass,
    getStudentsGroupsForAllClasses,
    studentsGroupsState,
    data,
  };
};
