import { gql } from 'core/dna/types/apollo';

export const ORGANIZATION_BASE_DATA_FRAGMENT = gql`
  fragment OrganizationBaseData on Organization {
    id
    name
    type
    category
  }
`;

export const ORGANIZATION_OPENING_HOURS_DATA_FRAGMENT = gql`
  fragment OrganizationOpeningHoursData on Organization {
    openingHours {
      id
      startTime {
        formatted
      }
      endTime {
        formatted
      }
      weekday
    }
  }
`;

export const ORGANIZATION_DATA_FRAGMENT = gql`
  fragment OrganizationData on Organization {
    ...OrganizationBaseData
    ...OrganizationOpeningHoursData
  }
  ${ORGANIZATION_BASE_DATA_FRAGMENT}
  ${ORGANIZATION_OPENING_HOURS_DATA_FRAGMENT}
`;

export const AVAILABILITY_PREFERENCE_DATA_FRAGMENT = gql`
  fragment AvailabilityPreferenceData on AvailabilityPreference {
    id
    availabilityLevel
    startTime {
      formatted
    }
    endTime {
      formatted
    }
    weekday
  }
`;

export const CLASS_DATA_FRAGMENT = gql`
  fragment ClassData on Class {
    id
    name
    unions {
      id
      class {
        id
        name
      }
      chapters {
        id
        name
        union {
          id
          class {
            id
            name
          }
        }
      }
    }
    availabilityPreferences {
      id
      availabilityLevel
      startTime {
        formatted
      }
      endTime {
        formatted
      }
      weekday
    }
    students {
      id
      title
      person {
        id
        tuid
        details {
          firstName
          lastName
        }
      }
      chapters {
        id
        name
      }
    }
  }
`;

export const CHAPTER_DATA_FRAGMENT = gql`
  fragment ChapterData on Chapter {
    id
    name
    union {
      id
      class {
        id
        name
      }
    }
  }
`;

export const SUBJECT_DATA_FRAGMENT = gql`
  fragment SubjectData on Subject {
    id
    name
    alias
    code
    bgColor
    annualHours
  }
`;

export const ROOM_DATA_FRAGMENT = gql`
  fragment RoomData on Room {
    id
    name
    alias
    note
    deleted
    term {
      id
      name
    }
    availabilityPreferences {
      id
      availabilityLevel
      startTime {
        formatted
      }
      endTime {
        formatted
      }
      weekday
    }
  }
`;

export const ROLE_DATA_FRAGMENT = gql`
  fragment RoleData on Role {
    id
    title
    person {
      id
      tuid
      details {
        firstName
        lastName
      }
    }
    availabilityPreferences {
      id
      availabilityLevel
      startTime {
        formatted
      }
      endTime {
        formatted
      }
      weekday
    }
    type
    employmentPercentage
    reservedEmploymentPercentage
  }
`;

export const LESSON_GROUP_BASE_DATA_FRAGMENT = gql`
  fragment LessonGroupBaseData on LessonGroup {
    id
    plannedMinutesPerTimeSlot
  }
`;

export const SCHEDULE_DESCRIPTION_DATA_FRAGMENT = gql`
  fragment ScheduleDescriptionData on Schedule {
    id
    name
    status
    dirty
    score
    lessonGroupCount
    timeSlotsUpdatedAt {
      formatted
    }
    shareCode
    shareCodeUpdatedAt {
      formatted
    }
  }
`;

export const SCHEDULE_DATA_FRAGMENT = gql`
  fragment ScheduleData on Schedule {
    ...ScheduleDescriptionData
    lessonGroups {
      id
      plannedMinutesPerTimeSlot
      mustBeNonstop
      lessons {
        id
        subjects {
          id
          name
          alias
          code
          bgColor
          annualHours
        }
        rooms {
          id
          name
          alias
          note
          deleted
          term {
            id
            name
          }
          availabilityPreferences {
            id
            availabilityLevel
            startTime {
              formatted
            }
            endTime {
              formatted
            }
            weekday
          }
        }
        teachers {
          id
          title
          type
          person {
            id
            tuid
            details {
              firstName
              lastName
            }
          }
          availabilityPreferences {
            id
            availabilityLevel
            startTime {
              formatted
            }
            endTime {
              formatted
            }
            weekday
          }
        }
        chapters {
          id
          name
          union {
            id
            class {
              id
              name
            }
          }
        }
        lessonGroup {
          id
          plannedMinutesPerTimeSlot
        }
      }
      timeSlots {
        id
        startTime {
          formatted
        }
        endTime {
          formatted
        }
        weekday
        pinned
        splitId
        aiHardConflicts
        aiSoftConflicts
      }
    }
    term {
      id
    }
  }
  ${SCHEDULE_DESCRIPTION_DATA_FRAGMENT}
`;

export const SCHEDULE_SNAPSHOT_DATA_FRAGMENT = gql`
  fragment ScheduleSnapshotData on ScheduleSnapshotResult {
    score
    status
    timeStamp
    suggestions {
      lessonGroupId
      startTime {
        formatted
      }
      endTime {
        formatted
      }
      weekday
      pinned
      timeSlotId
      splitId
    }
  }
`;
