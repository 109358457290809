/* eslint-disable */
import React from 'react';
import clsx from 'clsx';

import { Badge } from '../badge';

import { useStyles } from './timeline.styles';

export interface TimelineProps {
  stories: any[]; // PropTypes.arrayOf(PropTypes.object).isRequired,
  simple?: boolean; // PropTypes.bool,
}

export const Timeline = (props: TimelineProps) => {
  const classes = useStyles();
  const { stories, simple } = props;
  const timelineClass = clsx(
    classes.timeline,
    simple ? classes.timelineSimple : '',
  );
  return (
    <ul className={timelineClass}>
      {stories.map((prop, key) => {
        const panelClasses = clsx(
          classes.timelinePanel,
          prop.inverted || simple ? classes.timelinePanelInverted : '',
          simple ? classes.timelineSimplePanel : '',
        );

        const timelineBadgeClasses = clsx(
          classes.timelineBadge,
          (classes as any)[prop.badgeColor],
          simple ? classes.timelineSimpleBadge : '',
        );

        return (
          <li className={classes.item} key={key}>
            {prop.badgeIcon ? (
              <div className={timelineBadgeClasses}>
                <prop.badgeIcon className={classes.badgeIcon} />
              </div>
            ) : null}
            <div className={panelClasses}>
              {prop.title ? (
                <div className={classes.timelineHeading}>
                  <Badge color={prop.titleColor}>{prop.title}</Badge>
                </div>
              ) : null}
              <div className={classes.timelineBody}>{prop.body}</div>
              {prop.footerTitle ? (
                <h6 className={classes.footerTitle}>{prop.footerTitle}</h6>
              ) : null}
              {prop.footer ? <hr className={classes.footerLine} /> : null}
              {prop.footer ? (
                <div className={classes.timelineFooter}>{prop.footer}</div>
              ) : null}
            </div>
          </li>
        );
      })}
    </ul>
  );
};
