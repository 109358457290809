import { memo } from 'react';
import clsx from 'clsx';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Hidden from '@material-ui/core/Hidden';

import Menu from '@material-ui/icons/Menu';
import MoreVert from '@material-ui/icons/MoreVert';
import ViewList from '@material-ui/icons/ViewList';

import { FlexBox } from 'core/cells/box';

import { useActiveRoute } from 'core/dna/routing/active-route';

import { Button } from 'templates/mdp/components/custom-buttons';

import { useIntercom } from 'modules/planner/metabolism/intercom';

import { BreadcrumbsNavigator } from 'modules/planner/organisms/breadcrumbs-navigator';
import { IntercomHelpButton } from 'modules/planner/organisms/intercom-help-button';

import { DashboardNavbarLinks } from 'app/organisms/dashboard-navbar-links';

import { useStyles } from './dashboard-navbar.styles';

type AdminNavbarColor = 'primary' | 'info' | 'success' | 'warning' | 'danger';

export interface DashboardNavbarProps {
  color?: AdminNavbarColor; // PropTypes.oneOf([]),
  rtlActive?: boolean; // PropTypes.bool,
  brandText?: string; // PropTypes.string,
  miniActive?: boolean; // PropTypes.bool,
  handleDrawerToggle?: () => void; // PropTypes.func,
  sidebarMinimize?: () => void; // PropTypes.func,
}

const DashboardNavbarComponent = (props: DashboardNavbarProps) => {
  const { color, rtlActive, brandText, miniActive, sidebarMinimize } = props;

  const classes = useStyles();
  const { getActiveRoute } = useActiveRoute();
  const { productTourId } = useIntercom();

  const activeRoute = getActiveRoute();

  const Breadcrumbs = () => (
    <>
      <BreadcrumbsNavigator />
      {productTourId && !brandText && (
        <IntercomHelpButton
          className={classes.intercomAfterBreadcrumbs}
          tourId={productTourId}
        />
      )}
    </>
  );

  return (
    <>
      {activeRoute ? (
        <AppBar className={clsx(classes.appBar, color ? classes[color] : '')}>
          <div>
            <Toolbar className={classes.container}>
              <Hidden smDown implementation="css">
                <div
                  className={clsx(
                    classes.sidebarMinimize,
                    rtlActive ? [classes.sidebarMinimizeRTL] : '',
                  )}
                >
                  {miniActive ? (
                    <Button
                      justIcon
                      round
                      color="white"
                      onClick={sidebarMinimize}
                    >
                      <ViewList className={classes.sidebarMiniIcon} />
                    </Button>
                  ) : (
                    <Button
                      justIcon
                      round
                      color="white"
                      onClick={sidebarMinimize}
                    >
                      <MoreVert className={classes.sidebarMiniIcon} />
                    </Button>
                  )}
                </div>
              </Hidden>
              {!!brandText && (
                <div className={classes.flex}>
                  <Button
                    href="#"
                    className={classes.title}
                    color="transparent"
                  >
                    {brandText}
                  </Button>
                  {!!productTourId && (
                    <IntercomHelpButton
                      className={classes.firstLeftMargin}
                      tourId={productTourId}
                    />
                  )}
                </div>
              )}

              {activeRoute.breadcrumbs?.length ? (
                <>
                  <div
                    className={classes.flex}
                    style={{ flex: 2, marginLeft: 12 }}
                  >
                    <Hidden mdDown>
                      <Breadcrumbs />
                    </Hidden>
                  </div>
                </>
              ) : productTourId && !brandText ? (
                <div className={classes.flex}>
                  <IntercomHelpButton tourId={productTourId} />
                </div>
              ) : (
                <div className={classes.flex} />
              )}
              <Hidden smDown implementation="css">
                <DashboardNavbarLinks rtlActive={rtlActive} />
              </Hidden>
              <Hidden mdUp implementation="css">
                <Button
                  className={classes.appResponsive}
                  color="transparent"
                  justIcon
                  aria-label="open drawer"
                  onClick={props.handleDrawerToggle}
                >
                  <Menu />
                </Button>
              </Hidden>
            </Toolbar>
            <Hidden lgUp>
              <FlexBox justifyContent="center">
                <Breadcrumbs />
              </FlexBox>
            </Hidden>
          </div>
        </AppBar>
      ) : (
        <></>
      )}
    </>
  );
};

export const DashboardNavbar = memo(DashboardNavbarComponent);

DashboardNavbar.displayName = 'Dashboard Navbar';
