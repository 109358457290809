import moment from 'moment';

import { Weekday } from './types';

const mon = new Date('2019-07-08');
const tue = new Date('2019-07-09');
const wed = new Date('2019-07-10');
const thu = new Date('2019-07-11');
const fri = new Date('2019-07-12');
const sat = new Date('2019-07-13');
const sun = new Date('2019-07-14');

export const staticSunday = sun;
export const staticMonday = mon;
export const staticTuesday = tue;
export const staticWednesday = wed;
export const staticThursday = thu;
export const staticFriday = fri;
export const staticSaturday = sat;

export const weekdays = [
  Weekday.Monday,
  Weekday.Tuesday,
  Weekday.Wednesday,
  Weekday.Thursday,
  Weekday.Friday,
  Weekday.Saturday,
  Weekday.Sunday,
];

export const workingDays = weekdays.slice(0, 5);

export const getWeekday = (weekday: string | number): Weekday => {
  const num = Number(weekday);
  if (num < 0 || num > 6) {
    throw new Error(
      `weekday is ${weekday} but should be a number in range from 0 to 6 inclusive`,
    );
  }
  return weekdays[num];
};

export const getWeekdayDate = (weekday: Weekday): Date => {
  switch (weekday) {
    case Weekday.Monday:
      return staticMonday;
    case Weekday.Tuesday:
      return staticTuesday;
    case Weekday.Wednesday:
      return staticWednesday;
    case Weekday.Thursday:
      return staticThursday;
    case Weekday.Friday:
      return staticFriday;
    case Weekday.Saturday:
      return staticSaturday;
    case Weekday.Sunday:
      return staticSunday;
    default:
      throw new Error('Weekday should be in range from 0 to 6.');
  }
};

export const getDateFromStaticDayTime = (
  day: string | number,
  time?: string | number,
) => {
  const date = getWeekdayDate(getWeekday(day));
  if (!time) {
    return date;
  }
  return moment(date).add(moment.duration(time)).toDate();
};
