import {
  useGetSchedulesState,
  useSetSchedulesState,
  useGetSchedulesSelectedState,
} from 'modules/planner/memory/apollo/schedules/local';
import {
  useEditSchedule,
  useShareSchedule,
  useGetScheduleDescriptions,
} from 'modules/planner/memory/apollo/schedules/remote';

export const useSchedules = () => {
  const {
    loading: scheduleDescriptionsLoading,
    error: scheduleDescriptionsError,
    map: scheduleDescriptionMap,
    list: scheduleDescriptions,
    refetch: refetchScheduleDescriptions,
  } = useGetScheduleDescriptions();

  const { data: selectedScheduleIds } = useGetSchedulesSelectedState();

  const { editSchedule } = useEditSchedule();

  const {
    loading: scheduleStateLoading,
    error: scheduleStateError,
  } = useGetSchedulesState();

  const { setSchedulesState } = useSetSchedulesState();

  const { shareSchedule } = useShareSchedule();

  return {
    loading: scheduleDescriptionsLoading || scheduleStateLoading,
    error: scheduleDescriptionsError || scheduleStateError,
    scheduleDescriptions,
    scheduleDescriptionMap,
    refetchScheduleDescriptions,
    editSchedule,
    selectedScheduleIds,
    setSchedulesState,
    shareSchedule,
  };
};
