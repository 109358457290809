import { v4 as uuidv4 } from 'uuid';

import { OasMappingError } from 'core/atoms/errors';

import { GetMyAccessRoles_myAccessRoles as ApiMyAccessRoles } from 'core/dna/types/generated/GetMyAccessRoles';
import { RootAccessRoles } from 'core/dna/types/access-roles';

import { mapNullableListToList } from '../map-nullable-list-to-list';
import { mapNullableStringToString } from '../map-nullable-string-to-string';
import { mapApiAccessRolesListToAccessRolesList } from '../map-ApiAccessRoles-to-AccessRoles';

export const mapApiMyAccessRolesListToRootAccessRolesList = (
  apiMyAccessRolesList: (ApiMyAccessRoles | null)[] | null,
): RootAccessRoles[] | undefined => {
  return mapNullableListToList<ApiMyAccessRoles, RootAccessRoles>(
    apiMyAccessRolesList,
    mapApiMyAccessRolesToRootAccessRoles,
  );
};

export const mapApiMyAccessRolesToRootAccessRoles = (
  apiMyAccessRoles: ApiMyAccessRoles | null,
): RootAccessRoles => {
  if (apiMyAccessRoles === null) {
    throw new OasMappingError('apiMyAccessRoles" should not be null!', {
      title: 'Map ApiMyAccessRoles to RootAccessRoles',
    });
  }

  const {
    id,
    name,
    accessRoles,
    permissions,
    organizations,
  } = apiMyAccessRoles;

  return {
    id: id ?? `no-root-id-${uuidv4()}`,
    name: name ?? undefined,
    accessRoles: mapNullableListToList(accessRoles, mapNullableStringToString),
    permissions: mapNullableListToList(permissions, mapNullableStringToString),
    organizations: mapApiAccessRolesListToAccessRolesList(organizations),
  };
};
