import { GetTeachers_Role_person as ApiGetTeachersPerson } from 'core/dna/types/generated/GetTeachers';
import { GetSchedule_Schedule_lessonGroups_lessons_teachers_person as ApiGetSchedulesPerson } from 'core/dna/types/generated/GetSchedule';
import { Person } from 'core/dna/types/person';
import { mapNullableStringToString } from 'core/dna/functions';

export const mapApiPersonToPerson = (
  apiPerson: ApiGetTeachersPerson | ApiGetSchedulesPerson | null,
): Person | undefined => {
  if (!apiPerson) {
    return;
  }
  const { id, tuid, details } = apiPerson;

  let name = '';
  if (details && details.firstName) {
    name += details.firstName;
  }
  if (details && details.lastName) {
    name += ` ${details.lastName}`;
  }

  return {
    id,
    tuid: tuid || '',
    firstName: details
      ? mapNullableStringToString(details.firstName)
      : undefined,
    lastName: details ? mapNullableStringToString(details.lastName) : undefined,
    name: name.trim() || undefined,
  };
};
