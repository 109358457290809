import { useState, useLayoutEffect } from 'react';

import { authStore } from './auth-store';

export const useAuthState = () => {
  const [state, setState] = useState(authStore.initialState);

  useLayoutEffect(() => {
    const subscription = authStore.subscribe(setState);
    return () => {
      subscription.unsubscribe();
    };
  }, []);

  return {
    authState: state,
  };
};
