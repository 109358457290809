/* eslint-disable */
import React, { ReactNode } from 'react';
import clsx from 'clsx';

import { useStyles } from './card-avatar.styles';

export interface CardAvatarProps {
  children: ReactNode; // PropTypes.node.isRequired,
  className?: string; // PropTypes.string,
  profile?: boolean; // PropTypes.bool,
  plain?: boolean; // PropTypes.bool,
  testimonial?: boolean; // PropTypes.bool,
  testimonialFooter?: boolean; // PropTypes.bool,
}

export const CardAvatar = (props: CardAvatarProps) => {
  const classes = useStyles();

  const {
    children,
    className,
    plain,
    profile,
    testimonial,
    testimonialFooter,
    ...rest
  } = props;

  const cardAvatarClasses = clsx(
    classes.cardAvatar,
    profile ? classes.cardAvatarProfile : '',
    plain ? classes.cardAvatarPlain : '',
    testimonial ? classes.cardAvatarTestimonial : '',
    testimonialFooter ? classes.cardAvatarTestimonialFooter : '',
    className ?? '',
  );

  return (
    <div className={cardAvatarClasses} {...rest}>
      {children}
    </div>
  );
};
