import React from 'react';
import { ApolloError } from '@apollo/client/errors';
import { Typography } from '@material-ui/core';
import { Grid } from 'core/cells/grid';
import { Skeleton } from 'core/cells/skeleton';
import { Absence } from 'modules/student/dna/types';
import { useTranslations } from 'core/dna/translations';
import { AbsenceTable } from './components/absence-table';
import { AbsenceTotalByCategory } from './components/absence-total-by-category';

interface StudentAbsenceProps {
  data: Absence[] | null | undefined;
  error: ApolloError | undefined;
}

export const StudentAbsence = ({ data, error }: StudentAbsenceProps) => {
  const { texts } = useTranslations();
  if (
    error &&
    data === null &&
    error.graphQLErrors.some(
      (e) => e.path && e.path[e.path.length - 1] === 'absences',
    )
  ) {
    return (
      <Typography variant="h5" color="textSecondary" align="center">
        {texts.oasCommon.notAuthForResource}
      </Typography>
    );
  }

  return !data ? (
    <Skeleton variant="rect" width="100%" height="80vh" />
  ) : (
    <Grid container spacing={3} justifyContent="center">
      <Grid item xs={12} lg={4} xl={3}>
        {data && <AbsenceTotalByCategory data={data} />}
      </Grid>
      <Grid item xs={12} lg={8} xl={9}>
        <AbsenceTable data={data} loading={false} />
      </Grid>
    </Grid>
  );
};
