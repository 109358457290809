import { createContext } from 'react';

import { OrganizationLevel, ModuleState } from 'core/dna/types/local';
import { Organization } from 'core/dna/types/organization';

export interface ModulesUpdaterContextValue {
  reset: (level: OrganizationLevel) => ModuleState | null;
  updateSubscriptionsInfo: (organization: Organization) => ModuleState | null;
}

export const ModulesUpdaterContext = createContext<ModulesUpdaterContextValue>({
  reset: () => {
    throw new Error('not implemented');
  },
  updateSubscriptionsInfo: () => {
    throw new Error('not implemented');
  },
});

ModulesUpdaterContext.displayName = 'ModulesUpdater Context';
