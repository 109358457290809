import { ConfigurationType } from 'core/dna/types/generated/_globalTypes';

export interface WeeklyEvent {
  days: number[];
  startTime: string;
  endTime: string;
  value: string;
}

export interface ConfigurationDataType {
  [ConfigurationType.weeklyEvents]?: WeeklyEvent[];
  [ConfigurationType.sleep]?: Array<string[]>;
  [ConfigurationType.allergies]?: string[];
}

export interface ConfigurationData {
  data: ConfigurationDataType;
}

export const emptyWeeklyEvent: WeeklyEvent = {
  days: [],
  startTime: '',
  endTime: '',
  value: '',
};
