import { ReactNode, useState, MouseEvent } from 'react';
import clsx from 'clsx';

import { makeStyles, createStyles } from 'core/atoms/styles';

import { Menu } from 'core/cells/menu';
import { MoreVertIcon } from 'core/cells/oas-icons';
import { IconButton } from 'core/cells/icon-button';

const useStyles = makeStyles(() => {
  return createStyles({
    root: {
      position: 'absolute',
      top: 3,
      right: 3,
    },
    menuButton: {
      padding: 3,
      '&:hover': {
        backgroundColor: 'rgba(255,255,255,0.2)',
      },
    },
    menu: {
      fontSize: '0.8rem',
    },
  });
});

export interface TimeplanEventMenuProps {
  id: string;
  color: string;
  children: ReactNode;
  onOpenChanged?: (open: boolean) => void;
}

export const TimeplanEventMenu = (props: TimeplanEventMenuProps) => {
  const { id, children, color, onOpenChanged } = props;
  const classes = useStyles(props);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    onOpenChanged?.(true);
  };

  const handleClose = () => {
    setAnchorEl(null);
    onOpenChanged?.(false);
  };

  return (
    <div className={clsx(classes.root)}>
      <IconButton
        className={clsx('timeplan-event-menu', classes.menuButton)}
        aria-label="Menu"
        onClick={handleClick}
      >
        <MoreVertIcon className={classes.menu} style={{ color }} />
      </IconButton>

      <Menu
        id={id}
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {children}
        {/* <MenuItem onClick={handlePin}> */}
        {/*  {event.extendedProps?.pinned ? 'Unpin' : 'Pin'} */}
        {/* </MenuItem> */}
      </Menu>
    </div>
  );
};
