import { mapNullableListToList } from 'core/dna/functions/map-nullable-list-to-list';
import { ShortData } from 'modules/student/dna/types/short-data';
import { PersonDetailsT2 } from 'core/dna/types/generated/PersonDetailsT2';
import { mapApiGroupToGroupList } from '../map-api-group-to-group';

interface DetailsWithTuid {
  tuid: string | null;
  details: PersonDetailsT2 | null;
}

export const mapApiPersonT2ShortDataToPersonT2ShortData = (
  apiPersonT2: DetailsWithTuid | null,
): ShortData => {
  if (apiPersonT2 === null) {
    throw new TypeError('"apiPersonT2" should not be null!');
  }

  const { tuid, details } = apiPersonT2;

  return {
    tuid,
    details: {
      firstName: details?.firstName || null,
      lastName: details?.lastName || null,
      groups: !details ? undefined : mapApiGroupToGroupList(details.groups),
    },
  };
};

export const mapApiPersonT2ShortDataToPersonT2ShortDataList = (
  apiPersonT2List: (DetailsWithTuid | null)[] | null,
): ShortData[] | undefined => {
  return mapNullableListToList<DetailsWithTuid, ShortData>(
    apiPersonT2List,
    mapApiPersonT2ShortDataToPersonT2ShortData,
  );
};
