import moment from 'moment';
import { timeLong, timeShort } from 'core/atoms/date-time/formats';
import { OasError } from '../../errors';

export const formatTimeString = (time: string) => {
  let m = moment(time, timeLong);
  if (m) {
    return m.format(timeShort);
  }
  m = moment(time, timeShort);
  if (m) {
    return time;
  }

  throw new OasError(
    'Time string should be in either "HH:mm:ss" or "HH:mm" format',
    { title: 'format-time-string', type: 'OasError' },
  );
};
