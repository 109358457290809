import React, { useMemo } from 'react';

import { dateTimeShortForTableSorting } from 'core/atoms/date-time/formats';
import { useTranslations } from 'core/dna/translations';
import { formatDateTimeString } from 'core/atoms/functions/string/format-date-time-string';
import { SubstituteJobState } from 'core/dna/types/generated/_globalTypes';

import { JobTableItem } from 'modules/substitute/dna/types/job';

import { useGetSubstituteJobs } from 'modules/substitute/memory/apollo/substitute-jobs/remote';
import { DisplayCellInternalTitle } from './display-cell-internal-title';
import { DisplayCellState } from './display-cell-state';

export const useJobsTableRead = () => {
  const { texts } = useTranslations();
  const { data: jobs, loading } = useGetSubstituteJobs();

  const columns = useMemo(
    () => [
      {
        Header: texts.substitute.jobTitle,
        accessor: 'internalTitle',
        Cell: (row: any) => <DisplayCellInternalTitle {...row} />,
        width: '25%',
      },
      {
        Header: texts.planner.common.notes.one,
        accessor: 'description',
        width: '20%',
      },
      {
        Header: texts.oasCommon.responses.many,
        accessor: 'responses',
        width: '13.75%',
      },
      {
        Header: texts.oasCommon.deadline,
        accessor: 'deadline',
        width: '13.75%',
      },
      {
        Header: texts.oasCommon.state,
        accessor: 'state',
        Cell: (row: any) => <DisplayCellState {...row} />,
        width: '13.75%',
      },
      {
        Header: '',
        accessor: 'actions',
        width: 100,
        minWidth: 100,
      },
    ],
    [texts],
  );

  const stateOrder = [
    SubstituteJobState.draft,
    SubstituteJobState.published,
    SubstituteJobState.cancelled,
    SubstituteJobState.closed,
  ];

  const orderedJobs = jobs?.sort(
    (a, b) =>
      stateOrder.indexOf(b.state as SubstituteJobState) -
      stateOrder.indexOf(a.state as SubstituteJobState),
  );

  const data: JobTableItem[] = useMemo(
    () =>
      orderedJobs?.map((j) => {
        const { id, description, internalTitle, deadline, state, tasks } = j;
        return {
          id,
          description,
          internalTitle,
          deadline: formatDateTimeString(
            deadline,
            dateTimeShortForTableSorting,
          ),
          state,
          responses: `${
            tasks?.filter(
              (t) =>
                t.responses &&
                t.responses.filter((r) => r.response === 'yes').length,
            ).length ?? 0
          }/${tasks?.length ?? 0}`,
        };
      }) ?? [],
    [orderedJobs],
  );

  return {
    title: texts.substitute.jobs,
    columns,
    data,
    loading,
  };
};
