export const PATHS = {
  path: '/:locale(no|en|uk)/student',
  part: 'student',
  Cards: {
    path: '/:locale(no|en|uk)/student/list',
    part: 'list',
    params: {
      cardId: {
        pathKey: ':cardId?',
        key: 'cardId',
      },
    },
    Card: {
      path: '/:locale(no|en|uk)/student/:cardId?',
      part: '',
    },
  },
  SleepHours: {
    path: '/:locale(no|en|uk)/student/sleep-hours',
    part: 'sleep-hours',
  },
  Allergies: {
    path: '/:locale(no|en|uk)/student/allergies',
    part: 'allergies',
  },
  Counting: {
    path: '/:locale(no|en|uk)/student/counting',
    part: 'counting',
  },
  WeeklyEvents: {
    path: '/:locale(no|en|uk)/student/weekly-events',
    part: 'weekly-events',
  },
};
