import { CSSProperties, makeStyles, createStyles } from 'core/atoms/styles';

import {
  cardTitle,
  successColor,
  dangerColor,
} from '../../material-dashboard-pro-react';

import {
  customCheckboxRadioSwitch,
  CustomCheckboxRadioSwitch,
} from '../../custom-checkbox-radio-switch';

export interface RegularFormsStyle extends CustomCheckboxRadioSwitch {
  cardIconTitle: CSSProperties;
  staticFormGroup: CSSProperties;
  staticFormControl: CSSProperties;
  inputAdornment: CSSProperties;
  inputAdornmentIconSuccess: CSSProperties;
  inputAdornmentIconError: CSSProperties;
}

export const regularFormsStyle: RegularFormsStyle = {
  ...customCheckboxRadioSwitch,
  cardIconTitle: {
    ...cardTitle,
    marginTop: '15px',
    marginBottom: '0px',
  },
  staticFormGroup: {
    marginLeft: '0',
    marginRight: '0',
    paddingBottom: '10px',
    margin: '8px 0 0 0',
    position: 'relative',
    '&:before,&:after': {
      display: 'table',
      content: '" "',
    },
    '&:after': {
      clear: 'both',
    },
  },
  staticFormControl: {
    marginBottom: '0',
    paddingTop: '8px',
    paddingBottom: '8px',
    minHeight: '34px',
  },
  inputAdornment: {
    marginRight: '8px',
    position: 'relative',
  },
  inputAdornmentIconSuccess: {
    color: `${successColor[0]} !important`,
  },
  inputAdornmentIconError: {
    color: `${dangerColor[0]} !important`,
  },
};

export const useStyles = makeStyles(() =>
  createStyles({ ...regularFormsStyle }),
);
