import { GetRooms_Room_term as ApiRoomTerm } from 'core/dna/types/generated/GetRooms';

import { Term } from 'core/dna/types/term';

export const mapApiRoomTermToTerm = (
  apiRoomTerm: ApiRoomTerm | null,
): Term | undefined => {
  if (!apiRoomTerm) {
    return;
  }
  const { id, name } = apiRoomTerm;

  return {
    id,
    name: name ?? undefined,
  };
};
