import clsx from 'clsx';

import {
  useTermWeeks,
  TermWeek,
} from 'modules/planner/metabolism/use-term-weeks';

import { WeekSmall } from './week-small';

export interface WeeksSelectorProps {
  selected: TermWeek | null;
  className?: string;
  onSelect?: (week: TermWeek) => void;
}

export const WeeksSelector = (props: WeeksSelectorProps) => {
  const { className, onSelect, selected } = props;
  const { termWeeks } = useTermWeeks();

  return (
    <div className={clsx('weeks-selector', className)}>
      {termWeeks.map((tw) => {
        let state: 'assigned' | 'selected' | 'default';

        if (
          tw.weekNumber === selected?.weekNumber &&
          tw.year === selected.year &&
          tw.schedule?.id === selected.schedule?.id
        ) {
          state = 'selected';
        } else if (tw.schedule) {
          state = 'assigned';
        } else {
          state = 'default';
        }

        return (
          <WeekSmall
            text={String(tw.weekNumber)}
            state={state}
            onClick={() => onSelect?.(tw)}
          />
        );
      })}
    </div>
  );
};
