import React, { useCallback, useEffect, useState } from 'react';
import moment from 'moment';

import { makeStyles, createStyles } from 'core/atoms/styles';
import { OasPageError } from 'core/atoms/errors';

import { useParams } from 'core/dna/routing';
import { useTranslations } from 'core/dna/translations';
import {
  SubstituteJobState,
  TaskResponse,
} from 'core/dna/types/generated/_globalTypes';

import { cardTitle } from 'templates/mdp/material-dashboard-pro-react';
import { GridContainer, GridItem } from 'templates/mdp/components/grid';
import { Muted } from 'templates/mdp/components/typography';
import { Button } from 'templates/mdp/components/custom-buttons';
import {
  Card,
  CardBody,
  CardHeader,
  CardIcon,
} from 'templates/mdp/components/card';

import { SubstituteTask } from 'modules/substitute/dna/types/substitute-task';

import { useGetSubstituteInvitationDetails } from 'modules/substitute/memory/apollo/substitute-invitation-details/remote';
import { useUpdateSubstituteInvitationTask } from 'modules/substitute/memory/apollo/substitute-invitation-tasks/remote';
import { formatTimeString } from 'core/atoms/functions/string';
import {
  HowToReg,
  NotificationsActive,
  TransferWithinAStation,
} from '@material-ui/icons';
import { useStyles as useCardStyles } from 'templates/mdp/views/tables/react-tables.styles';
import { dayLongWithDateNo } from 'core/atoms/date-time/formats';
import { FlexBox } from 'core/cells/box';
import { Typography } from 'core/cells/typography';
import { SnackbarContent } from 'templates/mdp/components/snackbar';

import { IcsLink } from './components';

const useStyles = makeStyles(() =>
  createStyles({
    cardBodyText: {
      marginBottom: 10,
    },
    assignedTo: {
      textAlign: 'right',
      marginTop: 15,
    },
    declined: {
      textAlign: 'right',
      marginTop: 15,
    },
    cardHeader: {
      padding: 0,
    },
    cardTitleWhite: {
      ...cardTitle,
      color: '#fff',
      marginTop: 0,
    },
    cardCategoryWhite: {
      margin: 0,
      color: 'rgba(255, 255, 255, 0.8)',
      fontSize: '.875rem',
    },
  }),
);

export const Invitation = () => {
  const { id: idParam } = useParams<{ id: string }>();
  const cardClasses = useCardStyles();
  const classes = useStyles();
  const { texts } = useTranslations();
  const { data, loading, error } = useGetSubstituteInvitationDetails(idParam);
  const {
    updateSubstituteInvitationTask,
  } = useUpdateSubstituteInvitationTask();

  const isDeadlineExpired = useCallback(() => {
    if (!data || !data.job.deadline) return false;

    return moment().isAfter(data.job.deadline);
  }, [data]);

  const [deadlineExpired, setDeadlineExpired] = useState(false);

  useEffect(() => {
    setDeadlineExpired(isDeadlineExpired());
  }, [isDeadlineExpired]);

  const handleAction = useCallback(
    async (response: TaskResponse, task: SubstituteTask) => {
      await updateSubstituteInvitationTask({
        inviteId: idParam,
        taskId: task.id,
        taskResponse: response,
      });
    },
    [idParam, updateSubstituteInvitationTask],
  );

  const getAssignment = useCallback(
    (task: SubstituteTask): 'unassigned' | 'me' | 'other' => {
      if (!data) {
        throw new OasPageError('data should be defined', {
          title: 'modules/substitute/pages/invitation',
        });
      }
      if (!task.assignedTo?.id) {
        return 'unassigned';
      }
      if (task.assignedTo.id === data.to.id) {
        return 'me';
      }
      return 'other';
    },
    [data],
  );

  const getDate = (date: string) => {
    const momentDate = moment(date);
    return momentDate.format(dayLongWithDateNo);
  };

  const jobCanceled = data && data.job.state === SubstituteJobState.cancelled;

  const notifications = () => {
    if (!deadlineExpired && !jobCanceled) return false;

    if (jobCanceled && deadlineExpired) {
      return (
        <ol>
          <li>{texts.substitute.jobExpired}</li>
          <li>{texts.substitute.jobCanceled}</li>
        </ol>
      );
    }

    if (deadlineExpired) return texts.substitute.jobExpired;
    if (jobCanceled) return texts.substitute.jobCanceled;
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error!</p>;

  return (
    !!data && (
      <GridContainer justify="center">
        {notifications() ? (
          <GridItem xs={12} lg={6}>
            <SnackbarContent
              message={notifications()}
              icon={NotificationsActive}
              color="warning"
            />
          </GridItem>
        ) : (
          <></>
        )}
        <GridItem xs={12}>
          <Typography variant="h4" align="center" color="textSecondary">
            {data.job.description}
          </Typography>
        </GridItem>
        <>
          {data.job.tasks?.map((t, index) => {
            const assignment = getAssignment(t);
            const isUnassigned = assignment === 'unassigned';
            // const isAssignedToOther = assignment === 'other';
            const isAssignedToMe = assignment === 'me';
            const isDeclinedByMe = isUnassigned && t.myResponse === 'no';

            return (
              <GridItem xs={12} sm={6} lg={4} xl={3} key={index}>
                <Card>
                  <CardHeader icon>
                    {isUnassigned ? (
                      <CardIcon color="primary">
                        <TransferWithinAStation />
                      </CardIcon>
                    ) : (
                      <CardIcon color="success">
                        <HowToReg />
                      </CardIcon>
                    )}
                    <h4 className={cardClasses.cardIconTitle}>{t.subject}</h4>
                  </CardHeader>
                  <CardBody>
                    <Typography
                      variant="body1"
                      className={classes.cardBodyText}
                    >
                      {getDate(t.date)}
                    </Typography>
                    <Typography
                      variant="body1"
                      className={classes.cardBodyText}
                    >
                      {formatTimeString(t.timeFrom)} -{' '}
                      {formatTimeString(t.timeTo)}
                    </Typography>
                    <Muted>{t.comment}</Muted>
                    {!deadlineExpired && !jobCanceled && (
                      <>
                        {isUnassigned ? (
                          isDeclinedByMe ? (
                            <div style={{ textAlign: 'right' }}>
                              <Typography
                                variant="subtitle1"
                                color="textSecondary"
                                className={classes.declined}
                              >
                                {texts.substitute.declinedTask}
                              </Typography>
                              <Button
                                size="sm"
                                onClick={() =>
                                  handleAction(TaskResponse.yes, t)
                                }
                              >
                                {texts.substitute.changeToYes}
                              </Button>
                            </div>
                          ) : (
                            <FlexBox justifyContent="flex-end">
                              <Button
                                color="danger"
                                onClick={() => handleAction(TaskResponse.no, t)}
                                style={{ marginRight: 15 }}
                              >
                                {texts.planner.common.no}
                              </Button>
                              <Button
                                color="success"
                                onClick={() =>
                                  handleAction(TaskResponse.yes, t)
                                }
                              >
                                {texts.planner.common.yes}
                              </Button>
                            </FlexBox>
                          )
                        ) : (
                          <>
                            <Typography
                              variant="subtitle1"
                              color="textSecondary"
                              className={classes.assignedTo}
                            >
                              {texts.substitute.assignedTo}:{' '}
                              {t.assignedTo?.lastName}
                            </Typography>
                            {isAssignedToMe && (
                              <FlexBox justifyContent="flex-end">
                                <IcsLink task={t} />
                                <Button
                                  size="sm"
                                  onClick={() =>
                                    handleAction(TaskResponse.no, t)
                                  }
                                >
                                  {texts.substitute.changeToNo}
                                </Button>
                              </FlexBox>
                            )}
                          </>
                        )}
                      </>
                    )}
                  </CardBody>
                </Card>
              </GridItem>
            );
          })}
        </>
      </GridContainer>
    )
  );
};
