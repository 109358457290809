import { OasMappingError } from 'core/atoms/errors';

import { GetSubstituteJobs_SubstituteJob_invitations as ApiSubstituteInvitation } from 'core/dna/types/generated/GetSubstituteJobs';
import { mapNullableListToList } from 'core/dna/functions/map-nullable-list-to-list';

import { SubstituteInvitation } from 'modules/substitute/dna/types/substitute-invitation';

export const mapApiSubstituteInvitationListToSubstituteInvitationList = (
  apiSubstituteInvitationList: (ApiSubstituteInvitation | null)[] | null,
): SubstituteInvitation[] | undefined => {
  return mapNullableListToList<ApiSubstituteInvitation, SubstituteInvitation>(
    apiSubstituteInvitationList,
    mapApiSubstituteInvitationToSubstituteInvitation,
  );
};

export const mapApiSubstituteInvitationToSubstituteInvitation = (
  apiSubstituteInvitation: ApiSubstituteInvitation | null,
): SubstituteInvitation => {
  if (apiSubstituteInvitation === null) {
    throw new OasMappingError('apiSubstituteInvitation" should not be null!', {
      title: 'Map ApiSubstituteInvitation to SubstituteInvitation',
    });
  }

  const { inviteId, teacher } = apiSubstituteInvitation;

  return {
    inviteId: inviteId ?? '',
    teacher: {
      id: teacher?.id ?? '',
      firstName: teacher?.firstName ?? '',
      lastName: teacher?.lastName ?? '',
    },
  };
};
