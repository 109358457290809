import { OasMappingError } from 'core/atoms/errors';

import { mapNullableListToList } from 'core/dna/functions';

import { GetOrganizations_Organization_features as ApiFeatures } from 'core/dna/types/generated/GetOrganizations';
import { Features } from 'core/dna/types/organization';
import { ModuleModeEnum } from 'core/dna/types/modules';

export const mapApiFeatureListToFeatureList = (
  apiFeaturesList: (ApiFeatures | null)[] | null,
): Features[] | undefined => {
  return mapNullableListToList<any, Features>(
    apiFeaturesList,
    mapApiFeatureToFeature,
  );
};

export const mapApiFeatureToFeature = (
  apiFeature: ApiFeatures | null,
): Features => {
  if (apiFeature === null) {
    throw new OasMappingError('apiFeature" should not be null!', {
      title: 'Map ApiFeature to Feature',
    });
  }

  const { expiresAt, expired, code } = apiFeature;

  const featureMode = () => {
    if ((!expiresAt || !expiresAt.formatted) && !expired) {
      return ModuleModeEnum.active;
    }

    if (typeof expiresAt?.formatted === 'string' && !expired) {
      return ModuleModeEnum.demo;
    }

    if (typeof expiresAt?.formatted === 'string' && expired) {
      return ModuleModeEnum.expired;
    }

    return ModuleModeEnum.off;
  };

  return {
    code,
    expired,
    expiresAt: expiresAt?.formatted ?? null,
    mode: featureMode(),
  };
};
