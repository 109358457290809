import { TextValue } from 'core/atoms/types';

import { Typography } from 'core/cells/typography';
import { Link } from 'core/cells/link';
import { NavLink } from 'core/cells/nav-link';

export const PageLink = (props: TextValue) => {
  const { text, value } = props;

  return (
    <Link component={NavLink} to={value}>
      <Typography>{text}</Typography>
    </Link>
  );
};
