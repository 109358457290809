import { OrganizationLevel, RouteConfig } from 'core/dna/types/local';
import { PATHS } from 'modules/grades/paths';
import { OrganizationFeatureCode } from 'core/dna/types/generated/_globalTypes';

import { AccessRoleEnum } from 'core/dna/types/access-roles';
import { GetDataRoutesProps } from 'app/routes';

import {
  isFeatureAvailable,
  isOrganizationHasAccessRole,
} from 'core/dna/functions';

import { BatchListMainContent } from './pages/preparation/list';
import { TeachersMainContent } from './pages/preparation/evaluation-groups/teachers';
import { StudentsMainContent } from './pages/preparation/evaluation-groups/students';
import { AssessmentListMainContent } from './pages/assessment/list';
import { EvaluationGroupMainContent } from './pages/assessment/evaluation-group';

const batchId = `batch-${PATHS.Preparation.path}`;
const assessmentId = `assessment-${PATHS.Assessment.path}`;

export const getRoutes = (data: GetDataRoutesProps): RouteConfig[] => {
  const { organization, texts, myAccessRoles } = data;

  const isAdmin = isOrganizationHasAccessRole(
    myAccessRoles || null,
    organization?.id,
    AccessRoleEnum.admin,
  );

  const routes = {
    prepareBatch: {
      id: batchId,
      name: texts.grades.prepareBatch.sidebar,
      mini: texts.grades.prepareBatch.sidebar.toUpperCase().slice(0, 2),
      private: true,
      path: PATHS.Preparation.path,
      component: BatchListMainContent,
      sidebar: isFeatureAvailable(organization, OrganizationFeatureCode.alpha),
      level: OrganizationLevel.Organization,
      exact: true,
      // permissions: ['substitute'],
      views: [
        {
          id: `batch-${PATHS.Preparation.Subjects.path}`,
          parentId: batchId,
          exact: true,
          private: true,
          path: PATHS.Preparation.Subjects.path,
          name: texts.grades.evaluationGroups.sidebar,
          rtlName: texts.grades.evaluationGroups.sidebar,
          component: TeachersMainContent,
          level: OrganizationLevel.Organization,
          // permissions: ['substitute'],
        },
        {
          id: `batch-${PATHS.Preparation.EvaluationGroup.path}`,
          parentId: batchId,
          exact: true,
          private: true,
          path: PATHS.Preparation.EvaluationGroup.path,
          name: texts.grades.evaluationGroups.requestsForStudents,
          rtlName: texts.grades.evaluationGroups.requestsForStudents,
          component: StudentsMainContent,
          level: OrganizationLevel.Organization,
          // permissions: ['substitute'],
        },
      ],
    },
    assessment: {
      id: assessmentId,
      name: texts.grades.assessment.sidebar,
      mini: texts.grades.assessment.sidebar.toUpperCase().slice(0, 2),
      private: true,
      path: PATHS.Assessment.path,
      component: AssessmentListMainContent,
      sidebar: isFeatureAvailable(organization, OrganizationFeatureCode.alpha),
      level: OrganizationLevel.Organization,
      exact: true,
      views: [
        {
          id: `assessment-${PATHS.Assessment.OpenRequestsForGrades.path}`,
          parentId: assessmentId,
          exact: true,
          private: true,
          path: PATHS.Assessment.OpenRequestsForGrades.path,
          name: texts.grades.evaluationGroups.sidebar,
          rtlName: texts.grades.evaluationGroups.sidebar,
          component: EvaluationGroupMainContent,
          level: OrganizationLevel.Organization,
          // permissions: ['substitute'],
        },
      ],
    },
  };

  // order routes is important in routes array !!!
  return isAdmin
    ? [routes.prepareBatch, routes.assessment]
    : [routes.assessment];
};
