import { useCallback, useRef, useEffect } from 'react';

import { OasAuthError } from 'core/atoms/errors';
import { logger, LogType } from 'core/atoms/housekeeping';

import {
  LoginCallback,
  AuthorizationErrorCallback,
  LoginOptions,
} from 'auth/dna/types';

import { getStatusStorage } from 'auth/memory/browser';

import { useAuthContext } from './use-auth-context';

const TITLE = 'use-lazy-login';

export const useLazyLogin = (
  callback: LoginCallback,
  errorCallback?: AuthorizationErrorCallback,
) => {
  const { login, removeAccessToken } = useAuthContext();
  const { getStatus, setStatus } = getStatusStorage();

  const savedCallback = useRef<LoginCallback>();
  const savedErrorCallback = useRef<AuthorizationErrorCallback>();

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    savedErrorCallback.current = errorCallback;
  }, [errorCallback]);

  const func = useCallback(
    (options?: LoginOptions) => {
      if (options?.force) {
        logger.info(
          `[${TITLE}] login() options?.force, IDENTITY STATUS before reset: ${getStatus()}`,
          LogType.Secondary,
        );
        setStatus(null);
      }

      const status = getStatus();
      if (!status || status === 'logged-out') {
        login?.(options?.transient)
          .then((loginUri) => {
            savedCallback.current?.(loginUri);
          })
          .catch((reason) => {
            setStatus(null);
            removeAccessToken();
            savedErrorCallback.current?.(
              new OasAuthError(reason?.message ?? reason, { data: { status } }),
            );
          });
      } else if (options?.fallbackPath) {
        options.navigateTo?.(options?.fallbackPath, {
          from: options?.from || TITLE,
        });
      }
    },
    [getStatus, login, removeAccessToken, setStatus],
  );

  return {
    login: func,
  };
};
