/* eslint-disable */
import React, { ReactNode } from 'react';

import { useStyles } from './typography.styles';
import clsx from 'clsx';

export interface WarningProps {
  children?: ReactNode; // PropTypes.node,
}

export const Warning = (props: WarningProps) => {
  const classes = useStyles();
  const { children } = props;
  return (
    <div className={clsx(classes.defaultFontStyle, classes.warningText)}>
      {children}
    </div>
  );
};
