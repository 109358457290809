import { mapNullableListToList } from 'core/dna/functions/map-nullable-list-to-list';
import { GetStudentDetails_PersonT2_remarks as ApiRemark } from 'core/dna/types/generated/GetStudentDetails';
import { Remark } from 'modules/student/dna/types/remark';
import { mapPersonDetailsToFullName } from '../map-person-details-to-full-name';

export const mapApiRemarkToRemark = (apiRemark: ApiRemark | null): Remark => {
  if (apiRemark === null) {
    throw new TypeError('"apiRemark" should not be null!');
  }

  const { id, occurredAt, note, type, regarding, signedBy } = apiRemark;

  return {
    id,
    note,
    type,
    occurredAt: occurredAt?.formatted || null,
    regarding: regarding?.details
      ? mapPersonDetailsToFullName(regarding.details)
      : null,
    signedBy: signedBy?.details
      ? mapPersonDetailsToFullName(signedBy.details)
      : null,
  };
};

export const mapApiRemarkToRemarkList = (
  apiCarerList: (ApiRemark | null)[] | null,
): Remark[] | undefined => {
  return mapNullableListToList<ApiRemark, Remark>(
    apiCarerList,
    mapApiRemarkToRemark,
  );
};
