import { useCallback } from 'react';
import moment from 'moment';

import { logger, LogType } from 'core/atoms/housekeeping';
import { OasMemoryError, OasError } from 'core/atoms/errors';

import { ApolloClient } from 'core/dna/types/apollo';
import {
  GetOrganization,
  GetOrganizationVariables,
} from 'core/dna/types/generated/GetOrganization';

import { useResetTermsState } from 'core/memory/apollo/terms/local';

import { mapApiOrganizationListToOrganizationList } from 'modules/planner/dna/functions';

import { GET_ORGANIZATION_QUERY } from './get-organization-query';

const FILE_NAME = 'use-load-organization';

export const useLoadOrganization = () => {
  const { resetTermsState } = useResetTermsState();

  const handleCompleted = useCallback(
    (data: GetOrganization) => {
      const terms: { id: string; start?: Date; end?: Date }[] = [];
      const orgs = data.Organization?.filter((o) => o?.terms?.length);
      orgs?.forEach((o) => {
        if (o) {
          o.terms?.forEach((t) => {
            if (t) {
              terms.push({
                id: t.id,
                start: t.startDate
                  ? moment(t.startDate.formatted).toDate()
                  : undefined,
                end: t.endDate
                  ? moment(t.endDate.formatted).toDate()
                  : undefined,
              });
            }
          });
        }
      });
      resetTermsState(terms.length ? terms : undefined);
    },
    [resetTermsState],
  );

  const loadOrganization = useCallback(
    async (client: ApolloClient<any>, organizationId: string) => {
      try {
        const result = await client.query<
          GetOrganization,
          GetOrganizationVariables
        >({
          query: GET_ORGANIZATION_QUERY,
          variables: { organizationId },
        });

        if (result.errors) {
          throw new OasMemoryError(JSON.stringify(result.errors), {
            title: FILE_NAME,
          });
        }

        handleCompleted(result.data);

        const organizationList = mapApiOrganizationListToOrganizationList(
          result.data.Organization,
        );

        return organizationList?.[0];
      } catch (e: any) {
        if (e?.networkError?.type === 'organizationId') {
          logger.debug({
            title: `ApolloClient Handled Error`,
            type: LogType.Default,
            logger: FILE_NAME,
            value: e.message ?? 'no organizationId',
          });
          return;
        }
        if (e instanceof OasError) {
          throw e;
        } else {
          throw OasMemoryError.fromError(e, {
            title: FILE_NAME,
          });
        }
      }
    },
    [handleCompleted],
  );

  return {
    loadOrganization,
  };
};
