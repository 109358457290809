import { useCallback } from 'react';

import { OasMemoryError, OasError } from 'core/atoms/errors';

import { gql, useMutation } from 'core/dna/types/apollo';

import { BatchCreateStudentEvaluationsInput } from 'core/dna/types/generated/_globalTypes';
import { GET_EVALUATION_GROUP_QUERY } from '../evaluation-group';
import {
  BatchCreateStudentEvaluations,
  BatchCreateStudentEvaluationsVariables,
} from 'core/dna/types/generated/BatchCreateStudentEvaluations';
import { EXECUTION_STATUS } from 'modules/substitute/memory/apollo/_fragments/remote';

export const BATCH_CREATE_STUDENT_EVALUATIONS_MUTATION = gql`
  mutation BatchCreateStudentEvaluations(
    $input: BatchCreateStudentEvaluationsInput
  ) {
    batchCreateStudentEvaluations(input: $input) {
      ...ExecutionStatus
    }
  }
  ${EXECUTION_STATUS}
`;

const TITLE = 'use-batch-create-student-evaluations';

export const useBatchCreateStudentEvaluations = () => {
  const [batchCreateStudentEvaluations, { loading, error }] = useMutation<
    BatchCreateStudentEvaluations,
    BatchCreateStudentEvaluationsVariables
  >(BATCH_CREATE_STUDENT_EVALUATIONS_MUTATION);

  const func = useCallback(
    async (input: BatchCreateStudentEvaluationsInput) => {
      try {
        const result = await batchCreateStudentEvaluations({
          variables: {
            input: { ...input },
          },
          refetchQueries: [
            {
              query: GET_EVALUATION_GROUP_QUERY,
              // TODO: use filter by Evaluation group ID when it will be implemented in API side (TRA-1481). Linked to useStudentsTableRead
              variables: {
                filter: {
                  batchId: input.evaluationBatchId,
                },
              },
            },
          ],
        });

        if (result.errors) {
          throw new OasMemoryError(JSON.stringify(result.errors), {
            title: TITLE,
          });
        }
        return result.data?.batchCreateStudentEvaluations ?? undefined;
      } catch (e: any) {
        if (e instanceof OasError) {
          throw e;
        } else {
          throw OasMemoryError.fromError(e, {
            title: TITLE,
          });
        }
      }
    },
    [batchCreateStudentEvaluations],
  );

  return {
    createStudentEvaluation: func,
    loading,
    error,
  };
};
