import { useContext } from 'react';

import { SelectedOrganizationUpdaterContext } from './selected-organization-updater-context';

export const useSelectedOrganizationUpdater = () => {
  const setSelectedOrganization = useContext(
    SelectedOrganizationUpdaterContext,
  );

  if (typeof setSelectedOrganization === 'undefined') {
    throw new Error(
      'useSelectedOrganizationUpdater must be used within a OrganizationsProvider',
    );
  }

  return setSelectedOrganization;
};
