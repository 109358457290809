import { BehaviorSubject, of } from 'rxjs';
import { delay, tap, first } from 'rxjs/operators';
import { produce } from 'immer';

import { logger, LogType } from 'core/atoms/housekeeping';

import { ScheduleEvent } from 'core/dna/types';
import {
  DeleteAllTimeSlotForSchedule,
  ScheduleStatus,
  StartSchedulingInput,
  StopSchedulingInput,
  UpdateTimeSlot,
} from 'core/dna/types/generated/_globalTypes';
import { UpdateTimeslotMutation_updateTimeSlot as ApiUpdateTimeSlot } from 'core/dna/types/generated/UpdateTimeslotMutation';
import { DeleteAllTimeslotsForScheduleMutation_deleteAllTimeSlotsForSchedule as ApiDeleteAllTimeSlots } from 'core/dna/types/generated/DeleteAllTimeslotsForScheduleMutation';

export interface TpcStoreState {
  starting: boolean;
  scheduling: boolean;
  stopping: boolean;
  timeslotUpdating: boolean;
  executing: boolean;
  events: ScheduleEvent[] | undefined;
  locked: boolean;
}

const initialState: TpcStoreState = {
  starting: false,
  scheduling: false,
  executing: false,
  stopping: false,
  timeslotUpdating: false,
  events: undefined,
  locked: false,
};

const subject = new BehaviorSubject(initialState);

let state = initialState;

const emit = (state: TpcStoreState) => {
  subject.next(state);
};

const FILE_NAME = 'planner/organisms/time-plan-calendar/tpc-store';

// region --> Start Scheduling
function startSchedulingStart() {
  state = produce(state, (draft) => {
    draft.starting = true;
    draft.scheduling = false;
    draft.executing = true;
  });

  logger.debug({
    title: 'startSchedulingStart',
    logger: FILE_NAME,
    type: LogType.Info,
  });

  emit(state);
}

function startSchedulingSuccess() {
  state = produce(state, (draft) => {
    draft.starting = false;
    draft.scheduling = true;
    draft.executing = true;
  });

  logger.debug({
    title: 'startSchedulingSuccess',
    logger: FILE_NAME,
    type: LogType.Info,
  });

  emit(state);
}

function startSchedulingError() {
  state = produce(state, (draft) => {
    draft.starting = false;
    draft.scheduling = false;
    draft.executing = state.timeslotUpdating;
  });

  logger.debug({
    title: 'startSchedulingError',
    logger: FILE_NAME,
    type: LogType.Info,
  });

  emit(state);
}
// endregion

// region --> Stop Scheduling
function stopSchedulingStart() {
  state = produce(state, (draft) => {
    draft.stopping = true;
    draft.scheduling = true;
    draft.executing = true;
  });

  logger.debug({
    title: 'stopSchedulingStart',
    logger: FILE_NAME,
    type: LogType.Info,
  });

  emit(state);
}

function stopSchedulingSuccess() {
  state = produce(state, (draft) => {
    draft.stopping = false;
    draft.scheduling = false;
    draft.executing = state.timeslotUpdating;
  });

  logger.debug({
    title: 'stopSchedulingSuccess',
    logger: FILE_NAME,
    type: LogType.Info,
  });

  emit(state);
}

function stopSchedulingError() {
  state = produce(state, (draft) => {
    draft.stopping = false;
    draft.scheduling = false;
    draft.executing = state.timeslotUpdating;
  });

  logger.debug({
    title: 'stopSchedulingError',
    logger: FILE_NAME,
    type: LogType.Info,
  });

  emit(state);
}
// endregion

// region --> Update Time Slot
function updateTimeSlotStart(type?: 'update' | 'delete' | 'deleteAll') {
  state = produce(state, (draft) => {
    draft.timeslotUpdating = true;
    draft.executing = true;
  });

  let title = 'updateTimeSlotStart';
  switch (type) {
    case 'delete':
      title = 'deleteTimeSlotStart';
      break;
    case 'deleteAll':
      title = 'deleteAllTimeSlotsStart';
      break;
    case 'update':
    default:
      break;
  }

  logger.debug({
    title,
    logger: FILE_NAME,
    type: LogType.Info,
  });

  emit(state);
}

function updateTimeSlotSuccess(type?: 'update' | 'delete') {
  state = produce(state, (draft) => {
    draft.timeslotUpdating = false;
    draft.executing = state.scheduling;
  });

  let title = 'updateTimeSlotSuccess';
  switch (type) {
    case 'delete':
      title = 'deleteTimeSlotSuccess';
      break;
    case 'update':
    default:
      break;
  }

  logger.debug({
    title,
    logger: FILE_NAME,
    type: LogType.Info,
  });

  emit(state);
}

function updateTimeSlotError(type?: 'update' | 'delete' | 'deleteAll') {
  state = produce(state, (draft) => {
    draft.timeslotUpdating = false;
    draft.executing = state.scheduling;
  });

  let title = 'updateTimeSlotError';
  switch (type) {
    case 'delete':
      title = 'deleteTimeSlotError';
      break;
    case 'deleteAll':
      title = 'deleteAllTimeSlotsError';
      break;
    case 'update':
    default:
      break;
  }

  logger.debug({
    title,
    logger: FILE_NAME,
    type: LogType.Info,
  });

  emit(state);
}
// endregion

// region --> Delete Time Slot
function deleteTimeSlotStart() {
  updateTimeSlotStart('delete');
}

function deleteTimeSlotSuccess() {
  updateTimeSlotSuccess('delete');
}

function deleteTimeSlotError() {
  updateTimeSlotError('delete');
}
// endregion

// region --> Delete All Time Slots
function deleteAllTimeSlotsStart() {
  updateTimeSlotStart('deleteAll');
}

function deleteAllTimeSlotsSuccess() {
  state = produce(state, (draft) => {
    draft.timeslotUpdating = false;
    draft.executing = state.scheduling;
    draft.events = undefined;
  });

  logger.debug({
    title: 'deleteAllTimeSlotsSuccess',
    logger: FILE_NAME,
    type: LogType.Info,
  });

  emit(state);
}

function deleteAllTimeSlotsError() {
  updateTimeSlotError('deleteAll');
}
// endregion

function setLocked(locked?: boolean) {
  state = produce(state, (draft) => {
    draft.locked = !!locked;
  });

  logger.debug({
    title: 'setLocked',
    logger: FILE_NAME,
    type: LogType.Info,
  });

  emit(state);
}

export const tpcStore = {
  subscribe: (setState: (value: TpcStoreState) => void) =>
    subject.subscribe(setState),
  setLocked,
  startScheduling: (
    func: (input: StartSchedulingInput) => Promise<any>,
    funcInput: StartSchedulingInput,
    triggerStart = false,
  ) => {
    try {
      if (triggerStart) {
        startSchedulingStart();
      }
      func(funcInput)
        .then(() => {
          of(true)
            .pipe(first())
            .subscribe(() => {
              startSchedulingSuccess();
            });
        })
        .catch(() => {
          startSchedulingError();
        });
    } catch (error) {
      startSchedulingError();
    }
  },
  stopScheduling: (
    func: (input: StopSchedulingInput) => Promise<any>,
    funcInput: StopSchedulingInput,
    triggerStart = false,
  ) => {
    try {
      if (triggerStart) {
        stopSchedulingStart();
      }
      func(funcInput)
        .then(() => {
          stopSchedulingSuccess();
        })
        .catch(() => {
          stopSchedulingError();
        });
    } catch (error) {
      stopSchedulingError();
    }
  },
  updateSchedule: (
    events: ScheduleEvent[] | undefined,
    status: ScheduleStatus | undefined,
  ) => {
    state = produce(state, (draft) => {
      switch (status) {
        // case ScheduleStatus.starting:
        //   draft.starting = true;
        //   draft.scheduling = false;
        //   draft.executing = true;
        //   break;
        case ScheduleStatus.running:
          draft.starting = false;
          draft.scheduling = true;
          draft.executing = true;
          break;
        case ScheduleStatus.terminating:
          draft.stopping = true;
          draft.scheduling = true;
          draft.executing = true;
          break;
        case ScheduleStatus.terminated:
        case ScheduleStatus.created:
          draft.starting = false;
          draft.scheduling = false;
          draft.executing = false;
          draft.stopping = false;
          break;
        default:
          break;
      }
      draft.events = events;
    });

    logger.debug({
      title: 'updateSchedule',
      logger: FILE_NAME,
      type: LogType.Info,
    });

    emit(state);
  },
  updateTimeSlot: (
    func: (input: {
      apiInput: UpdateTimeSlot;
      scheduleId: string;
    }) => Promise<ApiUpdateTimeSlot | undefined>,
    funcInput: { apiInput: UpdateTimeSlot; scheduleId: string },
    triggerStart = false,
  ) => {
    try {
      if (triggerStart) {
        updateTimeSlotStart();
      }
      func(funcInput)
        .then(() => {
          updateTimeSlotSuccess();
        })
        .catch(() => {
          updateTimeSlotError();
        });
    } catch (error) {
      updateTimeSlotError();
    }
  },
  deleteTimeSlot: (
    func: (timeSlotId: string) => Promise<string | undefined>,
    timeSlotIds: string[],
  ) => {
    try {
      deleteTimeSlotStart();
      Promise.all(timeSlotIds.map((tsId) => func(tsId)))
        .then(() => {
          deleteTimeSlotSuccess();
        })
        .catch(() => {
          deleteTimeSlotError();
        });
    } catch (error) {
      deleteTimeSlotError();
    }
  },
  deleteAllTimeSlots: (
    func: (
      input: DeleteAllTimeSlotForSchedule,
    ) => Promise<ApiDeleteAllTimeSlots | undefined>,
    funcInput: DeleteAllTimeSlotForSchedule,
    refetch: () => Promise<any>,
    triggerStart = false,
  ) => {
    try {
      if (triggerStart) {
        deleteAllTimeSlotsStart();
      }
      func(funcInput)
        .then(() => {
          refetch().then(() => {
            deleteAllTimeSlotsSuccess();
          });
        })
        .catch(() => {
          deleteAllTimeSlotsError();
        });
    } catch (error) {
      deleteAllTimeSlotsError();
    }
  },
  initialState,
};
