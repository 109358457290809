import { tick } from 'core/atoms/date-time';
import { timeShort } from 'core/atoms/date-time/formats';

import { AvailabilityPreferences as ApiAvailabilityPreference } from 'core/dna/types/generated/_globalTypes';

import { CalendarEvent } from 'core/dna/types';
import { AvailabilityLevel } from 'modules/planner/dna/types/availability-level';

export const mapCalendarEventListToApiAvailabilityPreferenceList = (
  events?: CalendarEvent[],
  resourceId?: string,
  availabilityLevel: AvailabilityLevel = -2,
): ApiAvailabilityPreference[] => {
  if (!events) {
    return [];
  }
  return events
    .filter(
      (e) => !resourceId || (e.resourceIds?.indexOf(resourceId) ?? -1) > -1,
    )
    .map((event) =>
      mapCalendarEventToApiAvailabilityPreference(event, availabilityLevel),
    );
};

export const mapCalendarEventToApiAvailabilityPreference = (
  event: CalendarEvent,
  availabilityLevel: AvailabilityLevel = -2,
): ApiAvailabilityPreference => {
  return {
    availabilityLevel,
    startTime: tick(event.start).format(timeShort),
    endTime: tick(event.end).format(timeShort),
    weekday: tick(event.start).weekDay,
  };
};
