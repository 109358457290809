import { GetSchedule_Schedule_lessonGroups as ApiLessonGroup } from 'core/dna/types/generated/GetSchedule';
import {
  mapNullableListToList,
  mapNullableNumberToNumber,
} from 'core/dna/functions';

import { LessonGroup } from 'modules/planner/dna/types/lesson-group';
import { mapApiLessonListToLessonList } from '../map-ApiLesson-to-Lesson';
import { mapApiTimeSlotListToTimeSlotList } from '../map-ApiTimeSlot-to-TimeSlot';

export const mapApiLessonGroupListToLessonGroupList = (
  apiScheduleList: (ApiLessonGroup | null)[] | null,
): LessonGroup[] | undefined => {
  return mapNullableListToList<ApiLessonGroup, LessonGroup>(
    apiScheduleList,
    mapApiLessonGroupToLessonGroup,
  );
};

export const mapApiLessonGroupToLessonGroup = (
  apiLessonGroup: ApiLessonGroup | null,
): LessonGroup | undefined => {
  if (!apiLessonGroup) {
    return;
  }

  const {
    id,
    plannedMinutesPerTimeSlot,
    mustBeNonstop,
    lessons,
    timeSlots,
  } = apiLessonGroup;

  // TODO: debug this place
  return {
    id,
    plannedMinutesPerTimeSlot: mapNullableListToList(
      plannedMinutesPerTimeSlot,
      mapNullableNumberToNumber,
    ),
    // mustBeNonstop: mustBeNonstop ?? undefined,
    canBeSplit: mustBeNonstop === null ? true : !mustBeNonstop,
    lessons: mapApiLessonListToLessonList(lessons),
    timeSlots: mapApiTimeSlotListToTimeSlotList(timeSlots),
  };
};
