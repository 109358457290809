import { ReactNode } from 'react';
import { IdentityMap, TextItem, Identity } from 'core/atoms/types';
import { OrganizationCategoryType } from 'core/dna/types/generated/_globalTypes';
import { ModuleModeEnum } from 'core/dna/types/modules';

export enum OrganizationLevel {
  Federation = 'federation',
  Organization = 'organization',
  Unset = 'unset',
}

export interface OrganizationState extends Identity {
  name: string;
  accessible: boolean;
  level: OrganizationLevel;
  permissions: string[];
  rootId?: string;
  rootName?: string;
  rootAccessible?: boolean;
  category?: OrganizationCategoryType;
}

export interface OrganizationsState {
  selected: string;
  map?: IdentityMap<OrganizationState>;
}

export interface TermsState {
  selected: string | null;
}

export interface ModuleState extends TextItem {
  level: OrganizationLevel;
  disabled?: boolean;
  expired: boolean | null;
  expiresAt: string | null;
  mode: ModuleModeEnum;
}

export interface ModulesState {
  selected: string;
  values: ModuleState[];
}

export interface RouteConfig extends Identity {
  sidebar?: boolean;
  breadcrumbs?: { component: any; props?: any }[];
  parentId?: string;
  exact?: boolean;
  private?: boolean;
  path?: string | string[];
  name?: string;
  rtlName?: string;
  mini?: string;
  rtlMini?: string;
  collapse?: boolean;
  icon?: ReactNode;
  state?: string;
  component?: any;
  views?: RouteConfig[];
  // layout?: string;
  level?: OrganizationLevel;
  url?: string;
  permissions?: string[];
}

export interface RoutesState {
  module: string;
  selected: string;
  sidebarRoutes: RouteConfig[];
  contentRoutes: RouteConfig[];
  // values: RouteConfig[];
}
