import { ReactNode } from 'react';

import { TextItem } from 'core/atoms/types';

import { useTranslations } from 'core/dna/translations';

import { Tabs } from 'modules/planner/cells/tabs';

import {
  useSchedules,
  useGetCurrentSchedule,
  useSetCurrentSchedule,
} from 'modules/planner/metabolism/schedules';

interface SchedulesTabbedPageProps {
  tabContent: () => ReactNode;
}

export const SchedulesTabbedPage = (props: SchedulesTabbedPageProps) => {
  const { tabContent } = props;

  const { texts } = useTranslations();

  const {
    selectedScheduleIds,
    scheduleDescriptions,
    loading,
    error,
  } = useSchedules();

  // const { selectedScheduleState } = useGetSelectedScheduleState();
  const { currentSchedule } = useGetCurrentSchedule();
  const { setCurrentSchedule } = useSetCurrentSchedule();

  const selectedScheduleItemsList =
    scheduleDescriptions
      ?.map((sd) => ({ id: sd.id, text: sd.name } as TextItem))
      .filter((item) => selectedScheduleIds?.includes(item.id))
      .sort((a, b) => (a.text > b.text ? 1 : -1)) ?? [];

  if (loading) return <p>Loading Classes...</p>;
  if (error) return <p>Schedules Error!</p>;

  if (!selectedScheduleIds?.length) {
    return <p>{texts.planner.plannerSchedules.selectScheduleNotification}</p>;
  }

  if (!currentSchedule) {
    return <p>{texts.planner.plannerSchedules.scheduleNotFound}!</p>;
  }

  if (selectedScheduleIds.length === 1) {
    return <>{tabContent()}</>;
  }
  if (selectedScheduleIds.length > 1 && selectedScheduleIds.length <= 8) {
    return (
      <Tabs
        list={selectedScheduleItemsList}
        tabContent={tabContent}
        value={currentSchedule}
        onChange={setCurrentSchedule}
      />
    );
  }
  if (selectedScheduleIds.length > 8) {
    return <p>{texts.planner.plannerSchedules.tooManySchedulesSelected}</p>;
  }

  return <p>Something wrong!</p>;
};
