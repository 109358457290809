import { useCallback } from 'react';

import { OasMemoryError } from 'core/atoms/errors';
import { OasError } from 'core/atoms/errors/oas-error';

import { useMutation, gql } from 'core/dna/types/apollo';
import { UpdateAvailabilityPreferencesForEntitiesInput } from 'core/dna/types/generated/_globalTypes';

import { useLazyGetTermsState } from 'core/memory/apollo/terms/local';

import { GET_ROOMS_QUERY } from '../graphql';
import { AVAILABILITY_PREFERENCE_DATA_FRAGMENT } from '../../../_fragments';
import {
  UpdateAvailabilityPreferencesForRooms,
  UpdateAvailabilityPreferencesForRoomsVariables,
} from '../../../../../../../core/dna/types/generated/UpdateAvailabilityPreferencesForRooms';

const UPDATE_AVAILABILITY_PREFERENCES_FOR_ROOM_MUTATION = gql`
  mutation UpdateAvailabilityPreferencesForRooms(
    $input: UpdateAvailabilityPreferencesForEntitiesInput
  ) {
    updateAvailabilityPreferencesForEntities(input: $input) {
      id
      preferences {
        ...AvailabilityPreferenceData
      }
    }
  }
  ${AVAILABILITY_PREFERENCE_DATA_FRAGMENT}
`;

const FILE_NAME = 'use-update-availability-preferences-for-rooms';

export const useUpdateAvailabilityPreferencesForRooms = () => {
  const { getTermState } = useLazyGetTermsState();

  const [updateAvailabilityPreferencesForRooms] = useMutation<
    UpdateAvailabilityPreferencesForRooms,
    UpdateAvailabilityPreferencesForRoomsVariables
  >(UPDATE_AVAILABILITY_PREFERENCES_FOR_ROOM_MUTATION);

  const func = useCallback(
    async (input: UpdateAvailabilityPreferencesForEntitiesInput) => {
      try {
        const termsState = await getTermState(true);

        const result = await updateAvailabilityPreferencesForRooms({
          variables: { input },
          refetchQueries: [
            {
              query: GET_ROOMS_QUERY,
              variables: { termId: termsState!.selected! },
            },
          ],
          // update(cache, { data: { updateAvailabilityPreferencesForRooms } }) {
          //   const result = cache.readQuery<GetRooms>({
          //     query: GET_ROOMS_QUERY,
          //   });
          //   if (result) {
          //     const room = result.Room?.find(r => r?.id === input.roomId);
          //     if (room) {
          //       room.availabilityPreferences = [
          //         ...updateAvailabilityPreferencesForRooms,
          //       ];
          //       cache.writeQuery({
          //         query: GET_ROOMS_QUERY,
          //         data: { activeRooms: result.Room },
          //       });
          //     }
          //   }
          // },
        });

        if (result.errors) {
          throw new OasMemoryError(JSON.stringify(result.errors), {
            title: FILE_NAME,
          });
        }
      } catch (e: any) {
        if (e instanceof OasError) {
          throw e;
        } else {
          throw OasMemoryError.fromError(e, { title: FILE_NAME });
        }
      }
    },
    [getTermState, updateAvailabilityPreferencesForRooms],
  );

  return {
    updateAvailabilityPreferencesForRooms: func,
  };
};
