import { useEffect, useMemo, useState } from 'react';
import { Column } from 'react-table';

import { useTranslations } from 'core/dna/translations';
import { AbsenceState } from 'core/dna/types/generated/_globalTypes';

import { GroupWithTotalAbsences } from 'modules/reports/dna/types';

export interface GroupsAbsencesFullTableItem {
  name: string | null;
  hoursMedical: number;
  hoursLeave: number;
  hoursUndocumented: number;
  hoursOpen: number;
  daysMedical: number;
  daysLeave: number;
  daysUndocumented: number;
  daysOpen: number;
  daysTotal: number;
  hoursTotal: number;
}

export const useGroupsAbsenceFullTableRead = (
  data: GroupWithTotalAbsences[],
) => {
  const { texts } = useTranslations();

  const preparedData = useMemo<GroupsAbsencesFullTableItem[]>(() => {
    if (!data) {
      return [];
    }

    return data.map((group) => {
      const { name, total } = group;

      return {
        name,
        daysMedical: total.daysByState[AbsenceState.medical],
        daysLeave: total.daysByState[AbsenceState.leave],
        daysUndocumented: total.daysByState[AbsenceState.undocumented],
        daysOpen: total.daysByState[AbsenceState.open],
        hoursMedical: total.hoursByState[AbsenceState.medical],
        hoursLeave: total.hoursByState[AbsenceState.leave],
        hoursUndocumented: total.hoursByState[AbsenceState.undocumented],
        hoursOpen: total.hoursByState[AbsenceState.open],
        daysTotal: total.days,
        hoursTotal: total.hours,
      };
    });
  }, [data]);

  const [tableData, setTableData] = useState<GroupsAbsencesFullTableItem[]>(
    preparedData,
  );

  useEffect(() => {
    setTableData(preparedData);
  }, [preparedData]);

  const columns = useMemo<Column<GroupsAbsencesFullTableItem>[]>(
    () => [
      {
        Header: texts.planner.common.names.one,
        accessor: 'name',
      },
      {
        Header: texts.reports.absenceTable.daysMedical,
        accessor: 'daysMedical',
      },
      {
        Header: texts.reports.absenceTable.daysLeave,
        accessor: 'daysLeave',
      },
      {
        Header: texts.reports.absenceTable.daysUndocumented,
        accessor: 'daysUndocumented',
      },
      {
        Header: texts.reports.absenceTable.daysOpen,
        accessor: 'daysOpen',
      },
      {
        Header: texts.reports.absenceTable.daysTotal,
        accessor: 'daysTotal',
      },
      {
        Header: texts.reports.absenceTable.hoursMedical,
        accessor: 'hoursMedical',
      },
      {
        Header: texts.reports.absenceTable.hoursLeave,
        accessor: 'hoursLeave',
      },
      {
        Header: texts.reports.absenceTable.hoursUndocumented,
        accessor: 'hoursUndocumented',
      },
      {
        Header: texts.reports.absenceTable.hoursOpen,
        accessor: 'hoursOpen',
      },
      {
        Header: texts.reports.absenceTable.hoursTotal,
        accessor: 'hoursTotal',
      },
    ],
    [texts],
  );

  return {
    title: texts.oasCommon.group.many,
    columns,
    tableData,
  };
};
