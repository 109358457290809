import moment from 'moment';

import {
  isDate as isDateFunc,
  isArray as isArrayFunc,
} from 'core/atoms/functions';
import { Weekday } from 'core/atoms/date-time';
import { getDateFromStaticDayTime } from 'core/atoms/date-time/static-week';
import { OasError } from 'core/atoms/errors';

const isNumberArray = (
  time: Date | string | number | number[] | undefined,
): time is number[] => {
  return isArrayFunc(time);
};

const isDate = (time: Date | string | number | undefined): time is Date => {
  return isDateFunc(time);
};

export const getEventDuration = (input: {
  start: Date | string | number | number[] | undefined;
  end: Date | string | number | number[] | undefined;
}) => {
  if (isNumberArray(input.end) || isNumberArray(input.start)) {
    throw new OasError('Time in format of array of numbers is not supported', {
      title: 'getDayEventDuration',
    });
  }
  const end = isDate(input.end)
    ? input.end
    : getDateFromStaticDayTime(Weekday.Monday, input.end);

  const start = isDate(input.start)
    ? input.start
    : getDateFromStaticDayTime(Weekday.Monday, input.start);

  return moment(end).diff(start, 'minutes');
};
