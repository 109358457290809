import { useCallback } from 'react';

import { OasMemoryError } from 'core/atoms/errors';

import { getTermStorage } from 'core/memory/browser';
import { termsStateVar } from 'core/memory/apollo/cache';

export const useSetTermsSelectedState = () => {
  const { setTerm: setBrowserTerm } = getTermStorage();

  const func = useCallback(
    (input: any) => {
      try {
        termsStateVar({ selected: input.value ?? '' });
        setBrowserTerm(input.value ?? null);
        return true;
      } catch (e: any) {
        throw OasMemoryError.fromError(e, {
          title: 'useSetTermsSelectedState',
        });
      }
    },
    [setBrowserTerm],
  );

  return {
    setTermsSelectedState: func,
  };
};
