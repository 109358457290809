import React from 'react';

import MuiSelect from '@material-ui/core/Select';

import {
  makeStyles,
  createStyles,
  Theme,
  FormPalette,
  getFormPalette,
} from 'core/atoms/styles';
import { TextValue } from 'core/atoms/types';

import { FormControl } from 'core/cells/form-control';
import { MenuItem } from 'core/cells/menu-item';
import { TitleInput } from 'core/cells/title-input';
import { InputBaseProps } from '@material-ui/core/InputBase';
import { Typography } from 'core/cells/typography';
import { Divider } from 'core/cells/divider';
import { ListItemText } from 'core/cells/list-item-text';
import { ListItemIcon } from 'core/cells/list-item-icon';
import { PageTitle } from 'core/cells/page-title';
import { ClearIcon } from 'core/cells/oas-icons';

export interface TitleSelectProps {
  name: string;
  className?: string;
  data: TextValue[];
  value?: any;
  onChange?: (value: any) => void;
  classes?: Record<string, unknown>;
  dense?: boolean;
  palette?: FormPalette;
  disabled?: boolean;
  placeholder?: string;
  emptyText?: string;
}

const defaultProps: Partial<TitleSelectProps> = {
  dense: true,
  palette: 'default',
  placeholder: 'Title',
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    icon: {
      color: (props: TitleSelectProps) =>
        getFormPalette(theme, props.palette).layout.normal,
    },
    title: {
      marginBottom: theme.spacing(0.5),
    },
  }),
);

export const TitleSelect = (props: TitleSelectProps) => {
  const classes = useStyles(props);
  const {
    name,
    data,
    className,
    value,
    onChange,
    classes: selectClasses,
    dense,
    disabled,
    placeholder,
    emptyText,
  } = props;

  const getText = (value: string) => {
    if (!data) {
      return;
    }
    const item = data.find((i) => i.value === value);
    return item && item.text;
  };

  const Title = ({ text }: { text: string }) => (
    <PageTitle className={classes.title}>{text}</PageTitle>
  );

  const Placeholder = () => (
    <Typography variant="subtitle1" color="textSecondary">
      {placeholder}
    </Typography>
  );

  const renderValue = (value: any) => {
    const text = getText(value as string);
    return text ? <Title text={text} /> : <Placeholder />;
  };

  const handleChange = (
    event: React.ChangeEvent<{ name?: string; value: unknown }>,
  ) => {
    onChange && onChange(event.target.value);
  };

  const titleInputProps: InputBaseProps = {
    id: `title-${name}`,
    margin: dense ? 'dense' : undefined,
  };

  return (
    <FormControl
      className={className}
      variant="standard"
      component="div"
      disabled={disabled}
    >
      <MuiSelect
        displayEmpty
        classes={{ icon: classes.icon, ...selectClasses }}
        value={value}
        onChange={handleChange}
        input={<TitleInput {...titleInputProps} />}
        renderValue={renderValue}
      >
        {emptyText && (
          <div>
            <MenuItem value="">
              <ListItemIcon>
                <ClearIcon />
              </ListItemIcon>
              <ListItemText primary={emptyText} />
            </MenuItem>
            <Divider />
          </div>
        )}
        {data.map((item) => (
          <MenuItem key={item.value} value={item.value}>
            {item.text}
          </MenuItem>
        ))}
      </MuiSelect>
    </FormControl>
  );
};

TitleSelect.defaultProps = defaultProps;
