import { Role } from './role';

export type Teacher = Role;

export interface TeacherTableItem extends Teacher {
  availabilityDefined?: boolean;
  nin?: string;
  firstName?: string;
  lastName?: string;
  typeText?: string;
}

export const emptyTeacherItem: Partial<TeacherTableItem> = {
  firstName: '',
  lastName: '',
  nin: '',
};
