import { tick } from 'core/atoms/date-time';

import { EventInput } from 'core/cells/full-calendar';

import { AvailabilityPreference } from 'modules/planner/dna/types/availability-preference';

export const mapAvailabilityPreferenceListToEventInputList = (
  availabilityPreferences: AvailabilityPreference[],
  title: string,
  display?: string,
): EventInput[] => {
  return availabilityPreferences.map((ap) =>
    mapAvailabilityPreferenceToEventInput(ap, title, display),
  );
};

export const mapAvailabilityPreferenceToEventInput = (
  availabilityPreference: AvailabilityPreference,
  title: string,
  display?: string,
): EventInput => {
  const {
    id,
    startTime,
    endTime,
    weekDay,
    resourceId,
  } = availabilityPreference;

  return {
    id,
    title,
    start: tick(weekDay).setTime(startTime).toDate(),
    end: tick(weekDay).setTime(endTime).toDate(),
    display,
    resourceIds: resourceId ? [resourceId] : undefined,
  };
};
