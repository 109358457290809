import { Key } from 'react';
import clsx from 'clsx';

import { makeStyles, createStyles } from 'core/atoms/styles';
import { grey } from 'core/atoms/colors';

import { Box } from 'core/cells/box';

export interface ChapterProps {
  id: string;
  name: string;
  key?: Key | null;
  selected?: boolean;
  classes?: { root?: string; selected?: string };
}

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      margin: theme.spacing(0.25),
      backgroundColor: grey.A100,
      border: '1px solid',
      borderColor: grey.A700,
      borderRadius: theme.spacing(0.25),
    },
    selected: {
      borderColor: grey.A400,
    },
  }),
);

export const Chapter = (props: ChapterProps) => {
  const { key, name, selected, classes: propsClasses } = props;

  const classes = useStyles();

  return (
    <Box
      key={key}
      fullSize
      centeredContent
      className={clsx(
        classes.root,
        propsClasses?.root ?? '',
        selected ? classes.selected : '',
        selected ? propsClasses?.selected ?? '' : '',
      )}
    >
      {name ?? 'noname'}
    </Box>
  );
};
