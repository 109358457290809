import { ReactElement, useState, useEffect } from 'react';
import { useMediaQuery } from '@material-ui/core';
import { ImportContacts } from '@material-ui/icons';

import { makeStyles, createStyles, Theme } from 'core/atoms/styles';

import { Box, FlexBox } from 'core/cells/box';
import { Grid } from 'core/cells/grid';
import { TextFilterField } from 'core/cells/text-filter-field';
import { List } from 'core/cells/list';
import { Drawer } from 'core/cells/drawer';
import { FormControlLabel } from 'core/cells/form-control-label';
import { ListItem } from 'core/cells/list-item';
import { ListSubheader } from 'core/cells/list-subheader';
import { ListItemIcon } from 'core/cells/list-item-icon';
import { ListItemText } from 'core/cells/list-item-text';
import { Checkbox } from 'core/cells/checkbox';
import { ClassIcon, PersonIcon, RoomFilledIcon } from 'core/cells/oas-icons';

import { useTranslations } from 'core/dna/translations';

import { PrintButton } from 'core/organisms/print-helper';
import { WeekCalendar } from 'core/organisms/week-calendar';
import { useTimeplanEvent } from 'core/organisms/week-calendar/components';

import { useIdentity } from 'auth/metabolism';

import { GridContainer, GridItem } from 'templates/mdp/components/grid';
import {
  CardHeader,
  CardIcon,
  CardBody,
  Card,
} from 'templates/mdp/components/card';
import { buttonStyle } from 'templates/mdp/components/custom-buttons/button.styles';

import { useStyles as useReactTableStyles } from 'templates/mdp/views/tables/react-tables.styles';

import { useShareDesktop } from './use-share-desktop';
import { DisplayProps } from './display-props';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    listIcon: {
      minWidth: '26px',
    },
    filteredListCard: {
      height: 815,
      overflow: 'scroll',
      width: 250,
      marginRight: 16,
    },
    filteredListCardBody: {
      padding: theme.spacing(1),
    },
    list: {
      // width: 250,
    },
    subheader: {
      backgroundColor: theme.palette.background.paper,
    },
    fullList: {
      width: 'auto',
    },
    expanded: {
      flexGrow: 1,
    },
    contentRoot: {
      // marginLeft: 250,
      // padding: theme-light.spacing(2),
    },
    icon: {
      fontSize: 14,
    },
    flex: {
      display: 'flex',
      alignItems: 'center',
    },
    ml2: {
      marginLeft: theme.spacing(2),
    },
    printButton: {
      marginTop: 15,
      marginBottom: 0,
    },
    ...buttonStyle,
  }),
);

export const ShareDesktop = () => {
  const classes = useStyles();
  const rtClasses = useReactTableStyles();
  const { lang } = useTranslations();
  const identity = useIdentity();
  const print = useMediaQuery('print');
  const [alert, setAlert] = useState<any>(null);

  useEffect(() => {
    setAlert(null);
  }, [print]);

  const {
    resourceId,
    filter,
    setFilter,
    sharedSchedule,
    scheduleData,
    displayProps,
    setDisplayProps,
    sharedScheduleEvents,
    selectedResource,
    selectResourceTypeAndId,
  } = useShareDesktop();

  const createListFor = (
    listHeader: string,
    resourceType: string,
    icon: ReactElement,
    data: any,
  ) => {
    return (
      <>
        <ListSubheader className={classes.subheader}>
          {listHeader}
        </ListSubheader>

        {data
          ?.map((entry: any) => {
            const { name, firstName, lastName, ...rest } = entry;

            let n: string = entry.name || '';
            if (!name) {
              if (entry.firstName && entry.lastName) {
                n = `${entry.firstName.toLowerCase()} ${entry.lastName.toLowerCase()}`;
              } else if (entry.firstName) {
                n = entry.firstName;
              } else if (entry.lastName) {
                n = entry.lastName;
              }
            }
            return {
              ...rest,
              name: n,
            };
          })
          .filter((entry: any) => {
            if (!entry.name.length) {
              return false;
            }
            return (
              entry.name
                .trim()
                .toLowerCase()
                .indexOf(filter.trim().toLowerCase()) > -1
            );
          })
          .map((entry: any) => (
            <ListItem
              button
              key={entry.id}
              selected={resourceId === entry.id}
              onClick={() => selectResourceTypeAndId(resourceType, entry.id)}
            >
              <ListItemIcon className={classes.listIcon}>{icon}</ListItemIcon>
              <ListItemText primary={entry.name} />
            </ListItem>
          ))}
      </>
    );
  };

  const list = () => (
    <div className={classes.list}>
      <List>
        {createListFor(
          'Klasse',
          'classes',
          <ClassIcon className={classes.icon} />,
          scheduleData.classes,
        )}
        {createListFor(
          'Lærere',
          'persons',
          <PersonIcon className={classes.icon} />,
          scheduleData.persons,
        )}
        {createListFor(
          'Rom',
          'rooms',
          <RoomFilledIcon className={classes.icon} />,
          scheduleData.rooms,
        )}
      </List>
    </div>
  );

  const { eventContent } = useTimeplanEvent({
    showClasses: displayProps.showClasses,
    showGroups: displayProps.showGroups,
    showTeachers: displayProps.showTeachers,
    showRooms: displayProps.showRooms,
    readonly: true,
  });

  const Calendar = () =>
    resourceId ? (
      <WeekCalendar
        freeze
        slotDuration={{ minutes: 15 }}
        slotLabelInterval={{ minutes: 30 }}
        events={sharedScheduleEvents}
        snapDuration="00:05"
        slotMinTime="08:00:00"
        slotMaxTime="16:00:00"
        contentHeight="auto"
        eventContent={eventContent}
      />
    ) : (
      <></>
    );

  const PrintContent = () => (
    <>
      <h4 className={rtClasses.cardIconTitle}>{`${sharedSchedule?.orgName} ${
        selectedResource ? ` - ${selectedResource}` : ''
      }`}</h4>
      <Calendar />
    </>
  );

  const PageContent = () =>
    resourceId ? (
      <Card className={classes.contentRoot}>
        <CardHeader color="primary" icon className={classes.flex}>
          <CardIcon color="primary">
            <ImportContacts />
          </CardIcon>
          <h4 className={rtClasses.cardIconTitle}>{`${
            sharedSchedule?.orgName
          } ${selectedResource ? ` - ${selectedResource}` : ''}`}</h4>
          <Box ml={2}>
            <PrintButton setAlert={setAlert} />
          </Box>
        </CardHeader>
        <CardBody>
          <Grid container>
            <Grid item xs={12} container justify="flex-end">
              {[
                {
                  id: DisplayProps.showClasses,
                  text: lang.common.viewClasses,
                },
                {
                  id: DisplayProps.showGroups,
                  text: lang.common.viewGroups,
                },
                {
                  id: DisplayProps.showTeachers,
                  text: lang.common.viewTeachers,
                },
                {
                  id: DisplayProps.showRooms,
                  text: lang.common.viewRooms,
                },
              ].map((entry) => (
                <FormControlLabel
                  key={entry.id}
                  control={
                    <Checkbox
                      checked={displayProps[entry.id]}
                      onChange={() =>
                        setDisplayProps({
                          ...displayProps,
                          [entry.id]: !displayProps[entry.id],
                        })
                      }
                      name={entry.id}
                      color="primary"
                    />
                  }
                  label={entry.text}
                />
              ))}
            </Grid>
            <Grid item xs={12}>
              <Calendar />
            </Grid>
          </Grid>
        </CardBody>
      </Card>
    ) : (
      <></>
    );

  return (
    <>
      {print ? <></> : alert}
      {!identity ? (
        <>
          {!print && (
            <>
              <Drawer variant="persistent" anchor="left" open>
                <Box pt={1} pl={1} pr={1}>
                  <TextFilterField
                    label={lang.common.filter}
                    value={filter}
                    onChange={(e) => {
                      e.preventDefault();
                      setFilter(e.target.value);
                    }}
                    onFilterClick={() => {
                      setFilter('');
                    }}
                  />
                </Box>
                {list()}
              </Drawer>
              <PageContent />
            </>
          )}
          {print && <PrintContent />}
        </>
      ) : (
        <>
          {!print && (
            <FlexBox>
              <Card className={classes.filteredListCard}>
                <CardBody className={classes.filteredListCardBody}>
                  <Box pt={1} pl={1} pr={1}>
                    <TextFilterField
                      label={lang.common.filter}
                      value={filter}
                      onChange={(e) => {
                        e.preventDefault();
                        setFilter(e.target.value);
                      }}
                      onFilterClick={() => {
                        setFilter('');
                      }}
                    />
                  </Box>
                  {list()}
                </CardBody>
              </Card>
              <PageContent />
            </FlexBox>
          )}
          {print && <PrintContent />}
        </>
      )}
    </>
  );
};

// <GridContainer>
//   <GridItem sm={2}>
//
//   </GridItem>
//   <GridItem sm={10}>
//
//   </GridItem>
// </GridContainer>
