import { useCallback } from 'react';

import { OasMemoryError, OasError } from 'core/atoms/errors';

import { gql, useMutation } from 'core/dna/types/apollo';

import { useLazyGetTermsState } from 'core/memory/apollo/terms/local';

import {
  DeleteAllocatedSchedule,
  DeleteAllocatedScheduleVariables,
} from 'core/dna/types/generated/DeleteAllocatedSchedule';

import { mapWeekToApiWeek } from 'modules/planner/dna/functions';

import { GET_ALLOCATION_PLANS_QUERY } from './_gql';

const DELETE_ALLOCATED_SCHEDULE_MUTATION = gql`
  mutation DeleteAllocatedSchedule($input: DeleteAllocatedScheduleInput!) {
    deleteAllocatedSchedule(input: $input) {
      status
    }
  }
`;

const FILE_NAME = 'use-delete-allocation';

export const useDeleteAllocation = () => {
  const { getTermState } = useLazyGetTermsState();

  const [deleteAllocation] = useMutation<
    DeleteAllocatedSchedule,
    DeleteAllocatedScheduleVariables
  >(DELETE_ALLOCATED_SCHEDULE_MUTATION);

  const func = useCallback(
    async (input: { scheduleId: string; week: number; year: number }) => {
      const { scheduleId, week, year } = input;
      try {
        const termsState = await getTermState(true);

        const result = await deleteAllocation({
          variables: {
            input: {
              scheduleId,
              weekNumber: mapWeekToApiWeek({ week, year }),
              termId: termsState!.selected!,
            },
          },
          refetchQueries: [
            {
              query: GET_ALLOCATION_PLANS_QUERY,
              variables: { termId: termsState!.selected! },
            },
          ],
        });

        if (result.errors) {
          throw new OasMemoryError(JSON.stringify(result.errors), {
            title: FILE_NAME,
          });
        }
        return result.data?.deleteAllocatedSchedule ?? undefined;
      } catch (e: any) {
        if (e instanceof OasError) {
          throw e;
        } else {
          throw OasMemoryError.fromError(e, {
            title: FILE_NAME,
          });
        }
      }
    },
    [deleteAllocation, getTermState],
  );

  return {
    deleteAllocation: func,
  };
};
