import { useCallback } from 'react';

import { OasMemoryError, OasError } from 'core/atoms/errors';

import { gql, useMutation } from 'core/dna/types/apollo';
import {
  RoleType,
  CreatePersonAndRoleInput,
} from 'core/dna/types/generated/_globalTypes';
import {
  CreateTeacher,
  CreateTeacherVariables,
} from 'core/dna/types/generated/CreateTeacher';

import { useLazyGetTermsState } from 'core/memory/apollo/terms/local';

import { GET_TEACHERS_QUERY } from './_gql';

interface OmittedProps {
  termId: any;
  roleType: any;
  title: any;
}

export interface CreateTeacherInput
  extends Omit<CreatePersonAndRoleInput, keyof OmittedProps> {
  type: RoleType;
}

const CREATE_TEACHER_MUTATION = gql`
  mutation CreateTeacher($input: CreatePersonAndRoleInput!) {
    createPersonAndRole(input: $input) {
      personId
      roleId
      tuid
    }
  }
`;

const TITLE = 'use-create-teacher';

export const useCreateTeacher = () => {
  const { getTermState } = useLazyGetTermsState();

  const [createTeacherApi] = useMutation<CreateTeacher, CreateTeacherVariables>(
    CREATE_TEACHER_MUTATION,
  );

  const createTeacher = useCallback(
    async (input: CreateTeacherInput) => {
      try {
        const termsState = await getTermState(true);

        const { type, ...rest } = input;

        const result = await createTeacherApi({
          variables: {
            input: {
              ...rest,
              termId: termsState!.selected!,
              roleType: type,
              title: type.toString(),
            },
          },
          refetchQueries: [
            {
              query: GET_TEACHERS_QUERY,
              variables: { termId: termsState!.selected! },
            },
          ],
        });

        if (result.errors) {
          throw new OasMemoryError(JSON.stringify(result.errors), {
            title: TITLE,
          });
        }
        return result.data?.createPersonAndRole ?? undefined;
      } catch (e: any) {
        if (e instanceof OasError) {
          throw e;
        } else {
          throw OasMemoryError.fromError(e, {
            title: TITLE,
          });
        }
      }
    },
    [createTeacherApi, getTermState],
  );

  return {
    createTeacher,
  };
};
