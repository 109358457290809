import React, { useState } from 'react';
import { Grid } from 'core/cells/grid';
import { Skeleton } from 'core/cells/skeleton';
import { useReactTableExport } from 'core/metabolism/table-state';
import { useTranslations } from 'core/dna/translations';

import { Button } from 'templates/mdp/components/custom-buttons';

import { ShortDataWithAbsences } from 'modules/student/dna/types';

import { StudentsAbsenceTable } from '../students-absence-table';
import { AbsenceStateChartCreator } from '../charts/absence-state-chart-creator';
import { useStudentsAbsencesTotal } from './use-students-absences-total';
import {
  StudentsAbsencesFullTableItem,
  useStudentsAbsenceFullTableRead,
} from '../students-absence-table/use-students-absence-full-table-read';

interface StudentReportProps {
  data: ShortDataWithAbsences[] | undefined;
  loading: boolean;
}

export const StudentsReport = ({ data, loading }: StudentReportProps) => {
  const { texts } = useTranslations();
  const { total } = useStudentsAbsencesTotal(data);

  const studentsToChart = total.map((student) => ({
    ...student.total,
    title: `${student.details.firstName} ${student.details.lastName}`,
  }));

  const {
    columns: excelColumns,
    tableData: excelData,
  } = useStudentsAbsenceFullTableRead(total);

  const { exportToExcel } = useReactTableExport<StudentsAbsencesFullTableItem>(
    excelColumns,
    excelData,
  );

  const [open, setOpen] = useState(false);

  const handleModalOpen = () => {
    setOpen(true);
  };

  if (!total.length) {
    return <Skeleton variant="rect" width="100%" height="80vh" />;
  }

  return (
    <Grid container spacing={3}>
      <Grid
        item
        xs={12}
        style={{ display: 'flex', justifyContent: 'flex-end' }}
      >
        <Button
          color="secondary"
          onClick={handleModalOpen}
          style={{ marginRight: 15 }}
        >
          {texts.reports.reportsCommon.showChart}
        </Button>
        <Button
          color="secondary"
          onClick={() => {
            exportToExcel('Fravær elever');
          }}
        >
          {texts.reports.reportsCommon.excelExportByCategory}
        </Button>
      </Grid>
      <Grid item xs={12} lg={12}>
        <StudentsAbsenceTable data={total} loading={loading} />
      </Grid>
      <AbsenceStateChartCreator
        data={studentsToChart}
        open={open}
        setOpen={setOpen}
      />
    </Grid>
  );
};
