import { makeStyles, createStyles } from 'core/atoms/styles';

import { Select } from 'core/cells/select';

import { useTranslations } from 'core/dna/translations';

export enum Duration {
  M30 = '00:30:00',
  M15 = '00:15:00',
  M05 = '00:05:00',
}

export interface TimeSlotDurationSelectorProps {
  value: Duration;
  onChange: (value: Duration) => void;
}

const useStyles = makeStyles(() =>
  createStyles({
    select: {
      width: 120,
    },
  }),
);

const data = [
  { text: '30 mins.', value: Duration.M30 },
  { text: '15 mins.', value: Duration.M15 },
  { text: '5 mins.', value: Duration.M05 },
];

const defaultProps: Partial<TimeSlotDurationSelectorProps> = {
  value: data[0].value,
};

export const TimeSlotDurationSelector = (
  props: TimeSlotDurationSelectorProps,
) => {
  const classes = useStyles();
  const { lang } = useTranslations();
  const { value, onChange } = props;

  return (
    <Select
      name="timeSlotDuration"
      label={lang.common.zoom}
      data={data}
      value={value}
      className={classes.select}
      minWidth={120}
      onChange={onChange}
    />
  );
};

TimeSlotDurationSelector.defaultProps = defaultProps;
