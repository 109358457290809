import { useCallback } from 'react';

import { isSubstring } from 'core/atoms/functions/string';
import { OasErrorInterface } from 'core/atoms/errors';

import { FullBox, Box } from 'core/cells/box';
import { CircularProgress } from 'core/cells/circular-progress';

import { SyncStaff } from 'modules/planner/dna/types/sync-staff';

import {
  useGetSyncStaff,
  useCreateTeacherFromSyncPerson,
} from 'modules/planner/memory/apollo/sync-staff/remote';

import { ImportList } from 'modules/planner/organisms/import-list';
import { teachersStore, useTeachersState } from '../store';

export interface SyncPeopleListProps {
  onImportSuccess?: (count: number) => void;
  onImportError?: (error: OasErrorInterface) => void;
  width?: number;
}

// const FILE_NAME = 'sync-people-list';

const defaultWidth = 260;

export const SyncPeopleList = (props: SyncPeopleListProps) => {
  const { onImportSuccess, onImportError, width } = props;

  const { loading, error, data } = useGetSyncStaff();
  const { createTeacherFromSyncPerson } = useCreateTeacherFromSyncPerson();

  const {
    teachersState: { checkedForImport },
  } = useTeachersState();

  const importTeachers = useCallback(
    () =>
      Promise.all(
        checkedForImport.map((id) => createTeacherFromSyncPerson(id)),
      ),
    [checkedForImport, createTeacherFromSyncPerson],
  );

  const handleImport = useCallback(async () => {
    const response = await teachersStore.importTeachers(importTeachers);
    switch (response.type) {
      case 'success':
        onImportSuccess?.(checkedForImport.length);
        break;
      case 'error':
        onImportError?.(response.error!);
        break;
      default:
        break;
    }
  }, [checkedForImport, importTeachers, onImportError, onImportSuccess]);

  const filterPredicate = useCallback(
    (item: SyncStaff, filterText: string) =>
      isSubstring(filterText, [item.firstName, item.lastName]),
    [],
  );

  const getText = useCallback(
    (item: SyncStaff) => `${item.firstName} ${item.lastName}`,
    [],
  );

  if (error) return <p>Sync Staff Error!</p>;

  if (loading || !data)
    return (
      <Box width={width ?? defaultWidth} height="100%" m={0} p={0}>
        <FullBox>
          <CircularProgress />
        </FullBox>
      </Box>
    );

  return (
    <ImportList
      checked={checkedForImport}
      onCheck={teachersStore.checkIdsForImport}
      onClear={teachersStore.clearIdsForImport}
      data={data}
      filterPredicate={filterPredicate}
      getText={getText}
      onImport={handleImport}
      width={width ?? defaultWidth}
    />
  );
};
