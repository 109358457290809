import React, { createRef, useEffect, useMemo, useCallback } from 'react';

import { RouterRoute } from 'core/cells/router-route';
import { RouterSwitch } from 'core/cells/router-switch';

import { useTranslations } from 'core/dna/translations';
import { RouteConfig } from 'core/dna/types/local';
import { matchPath, getRoutes as getFlattenedRoutes } from 'core/dna/routing';

import { Footer } from 'core/organisms/footer';

import register from 'templates/mdp/assets/img/register.jpeg';
import login from 'templates/mdp/assets/img/login.jpg';
import lock from 'templates/mdp/assets/img/lock.jpeg';
import error from 'templates/mdp/assets/img/clint-mckoy.jpg';
import pricing from 'templates/mdp/assets/img/bg-pricing.jpeg';

import { AuthNavbar } from './components/auth-navbar';

import { useStyles } from './auth-layout.styles';

export interface AuthLayoutProps {
  routes: any[];
  [p: string]: any;
}

export const AuthLayout = (props: AuthLayoutProps) => {
  const { routes, ...rest } = props;
  // ref for the wrapper div
  const wrapper = createRef<any>();
  // styles
  const classes = useStyles();

  const { lang } = useTranslations();

  useEffect(() => {
    document.body.style.overflow = 'unset';
  });

  const flattenedRoutes = useMemo<RouteConfig[]>(() => {
    return routes ? getFlattenedRoutes(routes) : [];
  }, [routes]);

  const getRoutes = useCallback(
    (): any =>
      flattenedRoutes.map((r) => (
        <RouterRoute exact={!!r.exact} path={r.path} key={r.id}>
          <r.component />
        </RouterRoute>
      )),
    [flattenedRoutes],
  );

  const getBgImage = () => {
    if (window.location.pathname.indexOf('/auth/register-page') !== -1) {
      return register;
    }
    if (window.location.pathname.indexOf('/auth/pricing-page') !== -1) {
      return pricing;
    }
    if (window.location.pathname.indexOf('/auth/lock') !== -1) {
      return lock;
    }
    if (window.location.pathname.indexOf('/auth/error-page') !== -1) {
      return error;
    }
    if (window.location.pathname.indexOf('/auth') !== -1) {
      return login;
    }
  };
  const getActiveRoute = (routes: any[]): any => {
    let activeRoute = lang.common.transponder;
    // eslint-disable-next-line no-restricted-syntax
    for (const route of routes) {
      if (route.collapse) {
        const collapseActiveRoute = getActiveRoute(route.views);
        if (collapseActiveRoute !== activeRoute) {
          return collapseActiveRoute;
        }
      } else if (
        matchPath(window.location.pathname, {
          path: route.path,
          exact: true,
        })
      ) {
        if (route.name) {
          activeRoute = route.name;
        }
        break;
      }
    }
    return activeRoute;
  };
  return (
    <div>
      <AuthNavbar brandText={getActiveRoute(routes)} {...rest} />
      <div className={classes.wrapper} ref={wrapper}>
        <div
          className={classes.fullPage}
          style={{ backgroundImage: `url(${getBgImage()})` }}
        >
          <RouterSwitch>{getRoutes()}</RouterSwitch>
          <Footer white />
        </div>
      </div>
    </div>
  );
};
