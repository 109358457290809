import { useContext } from 'react';

import { SelectedModuleUpdaterContext } from './selected-module-updater-context';

export const useSelectedModuleUpdater = () => {
  const setSelectedModule = useContext(SelectedModuleUpdaterContext);
  if (typeof setSelectedModule === 'undefined') {
    throw new Error(
      'useSelectedModuleUpdater must be used within a ModulesProvider',
    );
  }
  return setSelectedModule;
};
