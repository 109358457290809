import { useCallback, useMemo } from 'react';
import moment from 'moment';
import queryString from 'query-string';

import { OasMemoryError, OasError } from 'core/atoms/errors';
import { logger, LogType } from 'core/atoms/housekeeping';

import { useLocation } from 'core/dna/routing';

import { getTermStorage } from 'core/memory/browser';
import { termsStateVar } from 'core/memory/apollo/cache';

const FILE_NAME = 'use-reset-terms-state';

export const useResetTermsState = () => {
  const location = useLocation();

  const { getTerm: getBrowserTerm, setTerm: setBrowserTerm } = useMemo(
    () => getTermStorage(),
    [],
  );

  const resetTermsState = useCallback(
    (terms: { id: string; start?: Date; end?: Date }[] | undefined) => {
      try {
        logger.debug({
          title: 'resetTermsState()',
          logger: FILE_NAME,
          type: LogType.Info,
          value: [...(terms ?? ['NO TERMS'])],
        });
        let selected = '';
        const bTerm = getBrowserTerm();
        if (terms?.length) {
          const qTerm = queryString.parse(location.search).t;

          if (qTerm && terms.find((t) => t.id === qTerm)) {
            selected = String(qTerm);
          } else if (bTerm && terms.find((t) => t.id === bTerm)) {
            selected = bTerm;
          } else {
            const t = terms.find((t) => {
              if (!t.start || !t.end) {
                return false;
              }
              const now = moment();
              return now.isSameOrAfter(t.start) && now.isBefore(t.end);
            });
            logger.debug({
              title: 'no term in query string',
              logger: FILE_NAME,
              type: LogType.Info,
              value: `start: ${moment(t?.start).format(
                'YYYY-MM-DD',
              )}, end: ${moment(t?.end).format('YYYY-MM-DD')}`,
            });
            selected = t ? t.id : '';
          }
        }

        const result = termsStateVar({ selected });

        if (bTerm !== selected) {
          setBrowserTerm(selected);
        }

        return result;
      } catch (e: any) {
        if (e instanceof OasError) {
          throw e;
        } else {
          throw OasMemoryError.fromError(e, {
            title: FILE_NAME,
          });
        }
      }
    },
    [getBrowserTerm, location.search, setBrowserTerm],
  );

  return {
    resetTermsState,
  };
};
