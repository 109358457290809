import { EventSourceInput } from '@fullcalendar/core';

import { ConfirmationAutoFocus } from 'core/atoms/types';
import { getMoment, tick } from 'core/atoms/date-time';
import { timeShort, dayShort } from 'core/atoms/date-time/formats';

import { ConfirmationDialog } from 'core/cells/confirmation-dialog';

import {
  FullCalendar,
  FullCalendarProps,
  workingHours,
} from 'core/cells/full-calendar';
import {
  timeGridPlugin,
  resourceTimeGridPlugin,
  interactionPlugin,
  momentPlugin,
} from 'core/cells/full-calendar/plugins';

import { useTranslations } from 'core/dna/translations';

import { DefaultEvent } from './components';
import {
  WeekCalendarProps,
  defaultProps,
  assertHasNowWhenPassedNowIndicator,
  assertProperUsageOfResources,
} from './week-calendar.props';
import { useStyles } from './week-calendar.styles';
import { useWeekCalendar } from './use-week-calendar';

const ConfirmationDialogContent = ({ classes, start, end }: any) => (
  <>
    On <span className={classes.bold}>{tick(start).format(dayShort)}</span> from{' '}
    <span className={classes.bold}>{tick(start).format(timeShort)}</span> to{' '}
    <span className={classes.bold}>{tick(end).format(timeShort)}</span>
  </>
);

const FILE_NAME = 'core/organisms/week-calendar';

export const WeekCalendar = (props: WeekCalendarProps) => {
  const {
    freeze,
    type = 'week',
    slotMinTime,
    slotMaxTime,
    events,
    eventTitle,
    eventClass,
    extEvents,
    bgEvents,
    onAddExternal,
    onAdd,
    onSplitDragStart,
    onEdit,
    resources,
    datesAboveResources,
    slotLabelInterval,
    slotDuration,
    snapDuration,
    withConfirmation,
    resizable,
    selectable,
    eventOverlap,
    height,
    contentHeight,
    eventContent,
    nowIndicator,
    now,
    dayCellContent,
  } = props;

  assertProperUsageOfResources(type, resources, FILE_NAME);
  assertHasNowWhenPassedNowIndicator(now, nowIndicator, FILE_NAME);

  const classes = useStyles();

  const { lang } = useTranslations();

  const {
    calendarRef,
    eventConfirmationOptions,
    onEventConfirmationClose,
    handleTimeSelect,
    handleEventResize,
    handleEventDrop,
    handleExternalEventDrop,
    handleEventDragStart,
  } = useWeekCalendar();

  const bgEventSource: EventSourceInput = {
    events: bgEvents,
    id: 'bg-event-source',
    className: classes.bgEvent,
  };

  const eventSource: EventSourceInput = {
    startEditable: !freeze,
    editable: !freeze,
    // overlap: false,
    constraint: {
      startTime: slotMinTime,
      endTime: slotMaxTime,
    },
    events,
    id: 'event-source',
    className: eventClass ?? classes.event,
  };

  const extEventSource: EventSourceInput = {
    startEditable: !freeze,
    editable: !freeze,
    // overlap: false,
    constraint: {
      startTime: slotMinTime,
      endTime: slotMaxTime,
    },
    events: extEvents,
    id: 'ext-event-source',
    className: classes.extEvent,
  };

  const calendarProps: FullCalendarProps = {
    nowIndicator,
    slotMinTime: slotMinTime ?? workingHours.start,
    slotMaxTime: slotMaxTime ?? workingHours.end,
    slotLabelInterval,
    slotDuration,
    snapDuration,
    eventSources: [extEventSource, eventSource, bgEventSource],
    eventStartEditable: !freeze,
    editable: !freeze,
    selectable: !freeze && selectable,
    droppable: !freeze,
    eventOverlap,
    select: !freeze
      ? (info) => {
          handleTimeSelect({
            needConfirmation: false,
            info,
            onAdd,
            eventTitle: eventTitle ?? 'EVENT',
          });
        }
      : undefined,
    eventContent: (arg) => <DefaultEvent {...arg} />,
    // eventRender: (info: any) =>
    //   handleEventRender({ needConfirmation: false, info, onDelete, onPin }),
    eventResize: !freeze
      ? (info) =>
          handleEventResize({ info, onEdit, resizable: resizable ?? true })
      : undefined,
    eventDrop: !freeze
      ? (info) => handleEventDrop({ info, onEdit })
      : undefined,
    drop: !freeze
      ? (info) => handleExternalEventDrop({ info, onAddExternal })
      : undefined,
    eventDragStart: (info) => handleEventDragStart({ info, onSplitDragStart }),
  };

  if (type === 'day') {
    calendarProps.initialView = 'timeGridDay';
    calendarProps.plugins = [timeGridPlugin, interactionPlugin, momentPlugin];
  }
  if (type === 'week') {
    calendarProps.initialView = 'timeGridWeek';
    calendarProps.plugins = [timeGridPlugin, interactionPlugin, momentPlugin];
  } else if (type === 'resource-week') {
    calendarProps.initialView = 'resourceTimeGridWeek';
    calendarProps.plugins = [
      resourceTimeGridPlugin,
      interactionPlugin,
      momentPlugin,
    ];
    calendarProps.resourceOrder = 'title';
    calendarProps.resources = resources;
    calendarProps.datesAboveResources = datesAboveResources ?? true;
  }
  if (height) {
    calendarProps.height = height;
  }
  if (contentHeight) {
    calendarProps.contentHeight = contentHeight;
  }

  if (now || nowIndicator) {
    const date = getMoment(now!)!.toISOString();
    calendarProps.now = date;

    if (nowIndicator) {
      window.setTimeout(() => {
        calendarProps.now = date;
      }, 3600);
    }
  }

  calendarProps.eventContent =
    eventContent ?? ((arg) => <DefaultEvent {...arg} />);

  calendarProps.dayCellContent = dayCellContent;

  return (
    <div className={classes.root}>
      <FullCalendar ref={calendarRef} {...calendarProps} />
      {withConfirmation && (
        <ConfirmationDialog
          id="add-event-confirmation"
          onClose={(isConfirmed) =>
            onEventConfirmationClose({
              newEventTitle: eventTitle ?? 'EVENT',
              confirmed: isConfirmed,
              onAdd,
            })
          }
          open={eventConfirmationOptions.open}
          title={eventConfirmationOptions.title}
          content={
            <ConfirmationDialogContent
              classes={classes}
              start={eventConfirmationOptions.start!}
              end={eventConfirmationOptions.end!}
            />
          }
          okText={lang.common.yes}
          cancelText={lang.common.no}
          autoFocus={ConfirmationAutoFocus.Ok}
        />
      )}
    </div>
  );
};

WeekCalendar.defaultProps = defaultProps;
