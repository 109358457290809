import { SplitDraggingState } from './types';

const storage = sessionStorage;
const PREFIX = 'week-calendar.split-dragging';

const TIME_SLOT_ID_KEY = `${PREFIX}.time-slot-id`;
const DURATION_KEY = `${PREFIX}.duration`;
const RELATIVES_TIME_SLOT_IDS_KEY = `${PREFIX}.relatives-time-slot-ids`;

const hasSplitDraggingState = () => {
  return (
    !!storage.getItem(TIME_SLOT_ID_KEY) &&
    !!storage.getItem(DURATION_KEY) &&
    !!storage.getItem(RELATIVES_TIME_SLOT_IDS_KEY)
  );
};

const getSplitDraggingState = (): SplitDraggingState | null => {
  return hasSplitDraggingState()
    ? {
        timeSlotId: String(storage.getItem(TIME_SLOT_ID_KEY)),
        duration: Number(storage.getItem(DURATION_KEY)),
        relativesTimeSlotIds: JSON.parse(
          String(storage.getItem(RELATIVES_TIME_SLOT_IDS_KEY)),
        ) as string[],
      }
    : null;
};

const removeSplitDraggingState = () => {
  if (hasSplitDraggingState()) {
    storage.removeItem(TIME_SLOT_ID_KEY);
    storage.removeItem(DURATION_KEY);
    storage.removeItem(RELATIVES_TIME_SLOT_IDS_KEY);
  }
};

const setSplitDraggingState = (input: SplitDraggingState | null) => {
  if (input) {
    storage.setItem(TIME_SLOT_ID_KEY, input.timeSlotId);
    storage.setItem(DURATION_KEY, String(input.duration));
    storage.setItem(
      RELATIVES_TIME_SLOT_IDS_KEY,
      JSON.stringify(input.relativesTimeSlotIds),
    );
  } else {
    removeSplitDraggingState();
  }
};

export const getSplitDraggingStorage = () => {
  return {
    hasSplitDraggingState,
    getSplitDraggingState,
    removeSplitDraggingState,
    setSplitDraggingState,
  };
};
