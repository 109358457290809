import { useCallback } from 'react';

import { OasMemoryError, OasError } from 'core/atoms/errors';

import { gql, useMutation } from 'core/dna/types/apollo';

import { CreateEvaluationBatchInput } from 'core/dna/types/generated/_globalTypes';
import {
  CreateEvaluationBatch,
  CreateEvaluationBatchVariables,
} from 'core/dna/types/generated/CreateEvaluationBatch';

import { EVALUATION_BATCH_FRAGMENT } from 'modules/grades/memory/apollo/_fragments';

import { GET_EVALUATION_BATCH_LIST_QUERY } from './use-get-evaluation-batch-list';

export const CREATE_EVALUATION_BATCH_MUTATION = gql`
  mutation CreateEvaluationBatch($input: CreateEvaluationBatchInput) {
    createEvaluationBatch(input: $input) {
      ...EvaluationBatch
    }
  }
  ${EVALUATION_BATCH_FRAGMENT}
`;

const TITLE = 'use-create-evaluation-batch';

export const useCreateEvaluationBatch = () => {
  const [createEvaluationBatch, { loading, error }] = useMutation<
    CreateEvaluationBatch,
    CreateEvaluationBatchVariables
  >(CREATE_EVALUATION_BATCH_MUTATION);

  const func = useCallback(
    async (input: CreateEvaluationBatchInput) => {
      try {
        const result = await createEvaluationBatch({
          variables: {
            input: { ...input },
          },
          refetchQueries: [
            {
              query: GET_EVALUATION_BATCH_LIST_QUERY,
            },
          ],
        });

        if (result.errors) {
          throw new OasMemoryError(JSON.stringify(result.errors), {
            title: TITLE,
          });
        }
        return result.data?.createEvaluationBatch ?? undefined;
      } catch (e: any) {
        if (e instanceof OasError) {
          throw e;
        } else {
          throw OasMemoryError.fromError(e, {
            title: TITLE,
          });
        }
      }
    },
    [createEvaluationBatch],
  );

  return {
    createEvaluationBatch: func,
    loading,
    error,
  };
};
