import { useEffect, useRef } from 'react';
import { logger, LogType } from './logging';

export const useTraceUpdate = (name: string, props: any) => {
  const prev = useRef(props);
  useEffect(() => {
    // logger.debug({
    //   title: 'useTraceUpdate',
    //   type: LogType.Info,
    //   logger: name,
    //   value: 'loaded',
    // });
    const changedProps = Object.entries(props).reduce((ps, [k, v]) => {
      if (prev.current[k] !== v) {
        ps[k] = [prev.current[k], v];
      }
      return ps;
    }, {} as any);
    if (Object.keys(changedProps).length > 0) {
      logger.debug({
        title: name,
        type: LogType.Secondary,
        logger: 'use-trace-update',
        value: Object.keys(changedProps).join(', '),
      });
    }
    prev.current = props;
  });
};
