import { ReactElement, useMemo } from 'react';
import clsx from 'clsx';
import MuiSelect, { SelectChangeEvent } from '@mui/material/Select';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';

import { TextValueData } from 'core/atoms/types';
import { orderBy } from 'core/atoms/functions/array';

export interface SelectProps {
  name: string;
  label: string;
  className?: string;
  minWidth?: number | string;
  data?: TextValueData[];
  value?: any;
  onChange?: (value: any, props?: any) => void;
  onClose?: (event: Record<string, any>) => void;
  multiple?: boolean;
  classes?: any;
  inputClassName?: any;
  size?: 'small' | 'medium';
  disabled?: boolean;
  renderItem?: (
    item: TextValueData,
    options: { last: boolean; selected: boolean },
  ) => any;
  notFoundText?: string;
}

export const Select = (props: SelectProps) => {
  const {
    name,
    label,
    data,
    className,
    value,
    onChange,
    onClose,
    multiple,
    classes: selectClasses,
    inputClassName,
    disabled,
    renderItem,
    notFoundText,
    size = 'small',
  } = props;

  const sortedData = useMemo(() => {
    if (data?.length) {
      return orderBy(data, ['text']);
    }
  }, [data]);

  const handleChange = (event: SelectChangeEvent, child: React.ReactNode) => {
    onChange && onChange(event.target.value, (child as ReactElement)?.props);
  };

  const getText = (value: string) => {
    if (!data) {
      return 'No Data';
    }
    const dataItem = data.find((i) => i.value === value);
    return dataItem ? dataItem.text : notFoundText ?? 'Item Not Found';
  };

  const renderValue = (value: any) => {
    if (Array.isArray(value)) {
      return (value as string[])
        .map(getText)
        .sort((a, b) => (a > b ? 1 : -1))
        .join(', ');
    }
    return getText(value as string);
  };

  return (
    <div className={clsx('Select5', className)}>
      <FormControl fullWidth>
        <InputLabel
          id="label5"
          sx={{
            '&.MuiInputLabel-root': {
              color: 'rgba(0, 0, 0, 0.6)',
              ...(size === 'small' && {
                transform: 'translate(14px, 13.6px) scale(1)',
              }),
            },
          }}
        >
          {label}
        </InputLabel>
        <MuiSelect
          id="select5"
          labelId="label5"
          value={value}
          multiple={multiple}
          onChange={handleChange}
          onClose={onClose}
          input={
            <OutlinedInput
              label={label}
              sx={{
                '& .MuiSelect-select': {
                  ...(size === 'small' && {
                    padding: '10.5px 32px 10.5px 14px',
                  }),
                },
              }}
            />
          }
          renderValue={renderValue}
          size="small"
          // MenuProps={MenuProps}
        >
          {sortedData?.map((item, index, arr) =>
            renderItem ? (
              renderItem(item, {
                last: index === arr.length - 1,
                selected: item.value === value,
              })
            ) : (
              <MenuItem
                key={item.value}
                value={item.value}
                sx={{
                  '&.MuiButtonBase-root': {
                    margin: '2px 0',
                  },
                }}
              >
                {multiple ? (
                  <>
                    <ListItemText
                      primary={item.text}
                      sx={(theme) => ({
                        height: '22px',
                        '& .MuiTypography-root': {
                          fontSize: 'size.md',
                          lineHeight: theme.typography.lineHeight.md,
                        },
                      })}
                    />
                    <Checkbox
                      checked={value ? value.indexOf(item.value) > -1 : false}
                    />
                  </>
                ) : (
                  item.text
                )}
              </MenuItem>
            ),
          )}
        </MuiSelect>
      </FormControl>
    </div>
  );
};
