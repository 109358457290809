import { TERM_KEY } from './constants';

const hasTerm = () => {
  return !!localStorage.getItem(TERM_KEY);
};

const getTerm = (): string | null => {
  return hasTerm() ? localStorage.getItem(TERM_KEY) : null;
};

const removeTerm = () => {
  hasTerm() && localStorage.removeItem(TERM_KEY);
};

const setTerm = (term: string | null) => {
  if (term) {
    localStorage.setItem(TERM_KEY, term);
  } else {
    removeTerm();
  }
};

export const getTermStorage = () => {
  return {
    key: TERM_KEY,
    hasTerm,
    getTerm,
    removeTerm,
    setTerm,
  };
};
