import { useCallback } from 'react';

import { gql, useMutation } from 'core/dna/types/apollo';
import { StopSchedulingInput } from 'core/dna/types/generated/_globalTypes';
import {
  StopScheduling,
  StopSchedulingVariables,
} from 'core/dna/types/generated/StopScheduling';

const STOP_SCHEDULING_MUTATION = gql`
  mutation StopScheduling($input: StopSchedulingInput!) {
    stopScheduling(input: $input) {
      status
      description
    }
  }
`;

export const useStopScheduling = () => {
  const [stopScheduling, { loading, error }] = useMutation<
    StopScheduling,
    StopSchedulingVariables
  >(STOP_SCHEDULING_MUTATION);

  const func = useCallback(
    (input: StopSchedulingInput) => {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise<any>(async (resolve, reject) => {
        try {
          const executionResult = await stopScheduling({
            variables: {
              input: {
                ...input,
              },
            },
          });

          if (executionResult.data && !executionResult.errors) {
            resolve(executionResult);
          } else {
            reject(new Error('stopScheduling error!'));
          }
        } catch (e: any) {
          reject(e);
        }
      });
    },
    [stopScheduling],
  );

  return {
    stopScheduling: func,
    loading,
    error,
  };
};
