import { gql } from 'core/dna/types/apollo';

import { SUBJECT_DATA_FRAGMENT } from 'modules/planner/memory/apollo/_fragments';

export const CREATE_SUBJECT_MUTATION = gql`
  mutation CreateSubject($input: CreateSubjectInput!) {
    createSubject(input: $input) {
      ...SubjectData
    }
  }
  ${SUBJECT_DATA_FRAGMENT}
`;
