import React from 'react';
import clsx from 'clsx';

import MuiTypography, {
  TypographyProps as MuiTypographyProps,
} from '@material-ui/core/Typography';

import { makeStyles, createStyles } from 'core/atoms/styles';

type TypographyVerticalAlign = 'top' | 'bottom' | 'center';

export interface TypographyProps extends MuiTypographyProps {
  verticalAlign?: TypographyVerticalAlign;
}

const useStyles = makeStyles(
  createStyles({
    root: {
      margin: (props: TypographyProps) => {
        switch (props.verticalAlign) {
          case 'top':
            return '0 0 auto 0';
          case 'center':
            return 'auto 0';
          case 'bottom':
            return 'auto 0 0 0';
          default:
            return 'inherit';
        }
      },
    },
  }),
);

export const Typography = (props: TypographyProps) => {
  const classes = useStyles(props);
  const { className, verticalAlign, ...other } = props;

  return <MuiTypography className={clsx(classes.root, className)} {...other} />;
};
