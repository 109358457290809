import { useCallback } from 'react';

import { makeStyles, createStyles } from 'core/atoms/styles';

import { Select } from 'core/cells/select';

import { useTranslations } from 'core/dna/translations';

import { useScheduleSelector } from './use-schedule-selector';

export interface ScheduleSelectorProps {
  singleSelection?: boolean;
  onAfterSelect?: () => void;
}

const useStyles = makeStyles(() =>
  createStyles({
    select: {
      width: 200,
    },
  }),
);

export const ScheduleSelector = (props: ScheduleSelectorProps) => {
  const { singleSelection, onAfterSelect } = props;
  const classes = useStyles();
  const { texts } = useTranslations();

  const {
    loading,
    error,
    schedulesData,
    selectedIds,
    handleChange,
  } = useScheduleSelector();

  const onClose = useCallback(
    (event: Record<string, any>) => {
      onAfterSelect?.();
    },
    [onAfterSelect],
  );

  if (loading) return <p>Loading Schedules...</p>;
  if (error) return <p>Schedules Error!</p>;

  return (
    <Select
      multiple={!singleSelection}
      name="schedules"
      label={texts.planner.common.schedules.many}
      data={schedulesData}
      value={selectedIds ?? []}
      className={classes.select}
      minWidth={200}
      onChange={handleChange}
      onClose={onClose}
      notFoundText={texts.planner.common.selectSchedule}
    />
  );
};
