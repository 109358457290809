import { createContext } from 'react';

import { OrganizationState } from 'core/dna/types/local';

export const SelectedOrganizationUpdaterContext = createContext<
  (id: string) => OrganizationState | null
>(() => {
  throw new Error('not implemented');
});

SelectedOrganizationUpdaterContext.displayName =
  'SelectedOrganizationUpdater Context';
