import { useMemo } from 'react';

import { SubjectTableItem } from 'modules/planner/dna/types/subject';

import { useGetSubjects } from 'modules/planner/memory/apollo/subjects/remote';

import { useTranslations } from 'core/dna/translations';

export const useSubjectsTableRead = () => {
  const { texts } = useTranslations();
  const { subjects, subjectsLoading } = useGetSubjects();

  // const columns: Column<SubjectTableItem>[] = useMemo(
  const columns = useMemo(
    () => [
      {
        Header: texts.planner.common.names.one,
        accessor: 'name',
      },
      {
        Header: texts.planner.annualHours60m,
        accessor: 'annualHours',
      },
      {
        Header: texts.planner.common.shortNames.one,
        accessor: 'alias',
      },
      {
        Header: texts.planner.common.codes.one,
        accessor: 'code',
      },
      {
        Header: texts.planner.common.colors.one,
        accessor: 'bgColor',
        disableFilters: true,
      },
      {
        Header: texts.planner.common.actions.many,
        accessor: 'actions',
      },
    ],
    [texts],
  );

  const data = useMemo<SubjectTableItem[] | undefined>(
    () =>
      subjects?.map((s) => {
        const { id, name, alias, code, bgColor, annualHours } = s;
        return {
          id,
          name,
          alias,
          code,
          bgColor,
          annualHours,
        };
      }),
    [subjects],
  );

  return {
    title: texts.planner.common.subjects,
    columns,
    data: data ?? [],
    loading: subjectsLoading,
  };
};
