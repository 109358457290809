import { OrganizationCategoryType } from 'core/dna/types/generated/_globalTypes';
import {
  Barnehage,
  Grunnskole,
  NotSpecified,
  OasLocales,
  Vgs,
  Sfo,
} from '../types';

export const mapTranslationDueToOrgCategoryType = (
  texts: OasLocales,
  category: OrganizationCategoryType | undefined | null,
  translationKey:
    | keyof NotSpecified
    | keyof Barnehage
    | keyof Grunnskole
    | keyof Vgs
    | keyof Sfo,
): string => {
  if (!category) {
    return 'loading...';
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return texts.oasCommon.orgCategory[category][translationKey];
};
