import { useCallback } from 'react';

import { OasMemoryError, OasError } from 'core/atoms/errors';

import { gql, useMutation } from 'core/dna/types/apollo';

import { CreateStudentEvaluationInput } from 'core/dna/types/generated/_globalTypes';
import {
  CreateStudentEvaluation,
  CreateStudentEvaluationVariables,
} from 'core/dna/types/generated/CreateStudentEvaluation';
import { GET_EVALUATION_GROUP_QUERY } from '../evaluation-group';

export const CREATE_STUDENT_EVALUATION_MUTATION = gql`
  mutation CreateStudentEvaluation($input: CreateStudentEvaluationInput) {
    createStudentEvaluation(input: $input) {
      createdAt {
        formatted
      }
    }
  }
`;

const TITLE = 'use-create-student-evaluation';

export const useCreateStudentEvaluation = () => {
  const [createStudentEvaluation, { loading, error }] = useMutation<
    CreateStudentEvaluation,
    CreateStudentEvaluationVariables
  >(CREATE_STUDENT_EVALUATION_MUTATION);

  const func = useCallback(
    async (input: CreateStudentEvaluationInput) => {
      try {
        const result = await createStudentEvaluation({
          variables: {
            input: { ...input },
          },
          refetchQueries: [
            {
              query: GET_EVALUATION_GROUP_QUERY,
              // TODO: use filter by Evaluation group ID when it will be implemented in API side (TRA-1481). Linked to useStudentsTableRead
              // variables: {
              //   filter: {
              //     teacherTuid,
              //     batchState: BatchState.open,
              //   },
              // },
            },
          ],
        });

        if (result.errors) {
          throw new OasMemoryError(JSON.stringify(result.errors), {
            title: TITLE,
          });
        }
        return result.data?.createStudentEvaluation ?? undefined;
      } catch (e: any) {
        if (e instanceof OasError) {
          throw e;
        } else {
          throw OasMemoryError.fromError(e, {
            title: TITLE,
          });
        }
      }
    },
    [createStudentEvaluation],
  );

  return {
    createStudentEvaluation: func,
    loading,
    error,
  };
};
