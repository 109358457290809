import { Identity } from 'core/atoms/types';

import { TermData } from 'core/dna/types/term';

export interface SubjectInfo extends Identity {
  name: string;
  alias?: string;
  bgColor?: string;
  annualHours?: number;
}

export interface SubjectData extends SubjectInfo {
  code?: string;
}

export interface Subject extends SubjectData {
  term?: TermData;
}

export type SubjectTableItem = SubjectData;

export const emptySubjectItem: Partial<SubjectTableItem> = {
  name: '',
  bgColor: '#cacaca',
};
