import React, { useCallback, useMemo, useState } from 'react';

import { AssignmentInd } from '@material-ui/icons';
import { Skeleton } from 'core/cells/skeleton';
import { WriteModeEnum } from 'core/dna/types/write-mode';
import { useTranslations } from 'core/dna/translations';
import { Button as MaterialButton } from 'core/cells/button';

import { Table } from 'templates/mdp/components/react-table';
import { GridContainer, GridItem } from 'templates/mdp/components/grid';
import {
  Card,
  CardBody,
  CardHeader,
  CardIcon,
} from 'templates/mdp/components/card';
import { useStyles } from 'templates/mdp/views/tables/react-tables.styles';

import { createReactTableStore } from 'core/memory/pure/react-table-store';
import { useGetTermsState } from 'core/memory/apollo/terms/local/get-terms-state';
import { useReactTableState } from 'core/metabolism/table-state';

import { EvaluationGroup } from 'modules/grades/dna/types';
import {
  EvaluationCreator,
  FieldName,
  InitialEvaluation,
  initialEvaluation,
} from 'modules/grades/organisms/evaluation-creator';

import {
  EvaluationsTableItem,
  useEvaluationsTableRead,
} from './use-evaluations-table-read';

export interface EvaluationsTableProps {
  evaluationGroup: EvaluationGroup;
  title: string;
}

export const evaluationsTableStore = createReactTableStore({}, {});

export const EvaluationsTable = ({
  evaluationGroup,
  title,
}: EvaluationsTableProps) => {
  const classes = useStyles();
  const { texts } = useTranslations();

  const { data: termsState } = useGetTermsState();

  const { columns, data } = useEvaluationsTableRead(evaluationGroup);

  const {
    initialPage,
    pageSize,
    sort,
    filters,
    setSort,
    setFilters,
    setPage,
    setPageSize,
  } = useReactTableState(
    evaluationsTableStore,
    termsState?.selected ?? undefined,
  );

  const [showModal, setShowModal] = useState(false);
  const [writeMode, setWriteMode] = useState<WriteModeEnum>(
    WriteModeEnum.create,
  );
  const [
    selectedEvaluation,
    setSelectedEvaluation,
  ] = useState<InitialEvaluation>(initialEvaluation);
  const [selectedField, setSelectedField] = useState<FieldName>(
    FieldName.evaluation,
  );

  const handleEdit = useCallback(
    (evaluation: EvaluationsTableItem, fieldName: FieldName) => {
      setSelectedField(fieldName);
      setWriteMode(WriteModeEnum.update);
      setSelectedEvaluation({
        studentTuid: evaluation.student?.personDetails?.tuid || '',
        code: evaluation.code!,
        evaluationGroupId: evaluationGroup.id!,
        evaluation: evaluation.evaluation,
        note: evaluation.note,
      });
      setShowModal(true);
    },
    [evaluationGroup.id],
  );

  const preparedData = useMemo(
    () =>
      data?.map((evaluation) => ({
        ...evaluation,
        evaluation: (
          <MaterialButton
            style={{ textTransform: 'none' }}
            color="primary"
            onClick={() => handleEdit(evaluation, FieldName.evaluation)}
          >
            {!evaluation.evaluation
              ? texts.planner.common.edit
              : evaluation.evaluation}
          </MaterialButton>
        ),
        note: (
          <MaterialButton
            style={{ textTransform: 'none' }}
            color="primary"
            onClick={() => handleEdit(evaluation, FieldName.note)}
          >
            {!evaluation.note ? texts.planner.common.edit : evaluation.note}
          </MaterialButton>
        ),
      })),
    [data, handleEdit, texts],
  );

  return !termsState?.selected ? (
    <Skeleton variant="rect" width="100%" height="80vh" />
  ) : (
    <GridContainer spacing={3} justifyContent="center">
      <GridItem xs={12}>
        <Card>
          <CardHeader color="primary" icon>
            <CardIcon color="primary">
              <AssignmentInd />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>{title}</h4>
          </CardHeader>
          <CardBody>
            <Table
              noActions
              columns={columns}
              data={preparedData ?? []}
              key={termsState.selected}
              onChangeRowsPerPage={setPageSize}
              onChangePage={setPage}
              onFilterChange={setFilters}
              onOrderChange={setSort}
              options={{
                initialPage,
                pageSize,
                sort,
                filters,
              }}
            />
          </CardBody>
        </Card>
      </GridItem>
      <EvaluationCreator
        fieldName={selectedField}
        writeMode={writeMode}
        data={selectedEvaluation}
        open={showModal}
        setOpen={setShowModal}
      />
    </GridContainer>
  );
};
