import { useCallback, useState } from 'react';

import { logger, LogType } from 'core/atoms/housekeeping';
import {
  OasPageError,
  OasError,
  OasErrorInterface,
  OasMemoryError,
} from 'core/atoms/errors';
import { parseTemplate } from 'core/atoms/functions/string';

import { useSnackbar } from 'core/cells/notistack';

import { useTranslations } from 'core/dna/translations';

import { JobTableItem } from 'modules/substitute/dna/types/job';
import {
  useCreateSubstituteJob,
  useCancelSubstituteJob,
  useDeleteSubstituteJob,
  useUpdateSubstituteJob,
} from 'modules/substitute/memory/apollo/substitute-jobs/remote';

const TITLE = 'use-jobs-table-write';

export const useJobsTableWrite = () => {
  const { texts } = useTranslations();
  const { enqueueSnackbar } = useSnackbar();
  const { createSubstituteJob } = useCreateSubstituteJob();
  const { updateSubstituteJob } = useUpdateSubstituteJob();
  const { cancelSubstituteJob } = useCancelSubstituteJob();
  const { deleteSubstituteJob } = useDeleteSubstituteJob();

  const [writing, setWriting] = useState(false);

  const onRowAdd = useCallback(
    async (item: {
      description: string;
      internalTitle: string;
      deadline: string;
    }) => {
      try {
        setWriting(true);
        const result = await createSubstituteJob(
          item.description,
          item.internalTitle,
          item.deadline,
        );
        setWriting(false);
        // enqueueSnackbar(
        //   parseTemplate(texts.oasCommon.entityCreated, {
        //     entityType: texts.substitute.substituteJobs.one,
        //   }),
        //   {
        //     variant: 'success',
        //   },
        // );
        return result;
      } catch (e) {
        setWriting(false);
        let error: OasErrorInterface;
        if (e instanceof OasError) {
          error = e;
        } else {
          error = OasMemoryError.fromError(e, {
            title: TITLE,
          });
        }
        enqueueSnackbar(error.message, { variant: 'error' });
        throw error;
      }
    },
    [createSubstituteJob, enqueueSnackbar],
  );

  const onRowUpdate = useCallback(
    async (item: {
      substituteJobId: string;
      description: string;
      internalTitle: string;
      deadline: string;
    }) => {
      try {
        setWriting(true);
        const result = await updateSubstituteJob(
          item.substituteJobId,
          item.description,
          item.internalTitle,
          item.deadline,
        );
        setWriting(false);
        enqueueSnackbar(
          parseTemplate(texts.oasCommon.entityUpdated, {
            entityType: texts.substitute.substituteJobs.one,
          }),
          {
            variant: 'success',
          },
        );
        return result;
      } catch (e) {
        setWriting(false);
        let error: OasErrorInterface;
        if (e instanceof OasError) {
          error = e;
        } else {
          error = OasMemoryError.fromError(e, {
            title: TITLE,
          });
        }
        enqueueSnackbar(error.message, { variant: 'error' });
        throw error;
      }
    },
    [enqueueSnackbar, updateSubstituteJob, texts],
  );

  const onRowDelete = useCallback(
    async (oldData: JobTableItem) => {
      try {
        setWriting(true);
        await deleteSubstituteJob(oldData.id);
        setWriting(false);
      } catch (e) {
        setWriting(false);
        const error =
          e instanceof OasError
            ? e
            : OasPageError.fromError(e, { title: TITLE });
        if (error.originalMessage) {
          logger.info(error.originalMessage, LogType.Error);
        }
        enqueueSnackbar(
          parseTemplate(texts.oasCommon.deleteEntitiesOfTypeError.one, {
            entityType: texts.oasCommon.job.one,
            entity: oldData.internalTitle.slice(0, 10),
          }),
          {
            variant: 'error',
          },
        );
        return 1;
      }
    },
    [deleteSubstituteJob, enqueueSnackbar, texts],
  );

  const onRowCancel = useCallback(
    async (oldData: JobTableItem) => {
      try {
        setWriting(true);
        await cancelSubstituteJob(oldData.id);
        setWriting(false);
      } catch (e) {
        setWriting(false);
        const error =
          e instanceof OasError
            ? e
            : OasPageError.fromError(e, { title: TITLE });
        if (error.originalMessage) {
          logger.info(error.originalMessage, LogType.Error);
        }
        enqueueSnackbar(
          parseTemplate(texts.oasCommon.deleteEntitiesOfTypeError.one, {
            entityType: texts.oasCommon.job.one,
            entity: oldData.internalTitle.slice(0, 10),
          }),
          {
            variant: 'error',
          },
        );
        return 1;
      }
    },
    [cancelSubstituteJob, enqueueSnackbar, texts],
  );

  return {
    onRowAdd,
    onRowUpdate,
    onRowCancel,
    onRowDelete,
    writing,
  };
};
