import { useRef, useEffect } from 'react';

import { Location } from 'core/dna/routing/types';
import { useLocation } from 'core/dna/routing/use-location';

const usePrevious = (value: Location) => {
  const ref = useRef<Location>(value);
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

export const useLocationChange = (
  action: (location: Location, prevLocation: Location) => void,
) => {
  const location = useLocation<any>();
  const prevLocation = usePrevious(location);

  useEffect(() => {
    if (location.pathname !== prevLocation?.pathname) {
      action(location, prevLocation);
    }
  }, [action, location, prevLocation]);
};
