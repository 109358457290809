import { useContext } from 'react';

import { AccessRolesStateContext } from './access-roles-state-context';

export const useAccessRolesState = () => {
  const accessRoles = useContext(AccessRolesStateContext);
  if (typeof accessRoles === 'undefined') {
    throw new Error(
      'useAccessRolesState must be used within a AccessRolesProvider',
    );
  }
  return accessRoles;
};
