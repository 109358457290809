import React, { useEffect } from 'react';

import { Typography } from 'core/cells/typography';

import { URLS, useLocale } from 'core/dna/routing';

import { useErrorsSelectors } from 'core/metabolism/errors';

import { useOasHistory } from 'core/metabolism/use-oas-history';

export const Error = () => {
  const { current } = useErrorsSelectors();
  const { replace } = useOasHistory();
  const locale = useLocale();

  useEffect(() => {
    if (!current) {
      replace(URLS.orgWelcome(locale));
    }
  }, [current, locale, replace]);

  return current ? (
    <>
      <Typography variant="h3">{current.error.title}</Typography>
      <Typography variant="h5">{current.error.originalMessage}</Typography>
    </>
  ) : (
    <> </>
  );
};
