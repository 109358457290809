import * as MuiIcons from './material-ui';
import * as Custom from './custom-icons';

// region --> Common icons
export const { PersonIcon } = MuiIcons;
export const RoomFilledIcon = MuiIcons.MeetingRoomIcon;
export const ClassIcon = MuiIcons.SupervisedUserCircleIcon;
export const { GroupIcon } = MuiIcons;
export const PeopleGroupIcon = MuiIcons.GroupTwoToneIcon;
export const PinIcon = MuiIcons.PinDropIcon;
export const ChevronLeftIcon = MuiIcons.ChevronLeftTwoToneIcon;
export const MenuIcon = MuiIcons.MenuTwoToneIcon;
export const { MoreVertIcon } = MuiIcons;
export const HelpIcon = MuiIcons.HelpTwoToneIcon;
// endregion

// region --> Domain icons
export const SourceDataIcon = MuiIcons.StorageTwoToneIcon;
export const PreparationIcon = MuiIcons.ViewDayTwoToneIcon;
export const AllocationIcon = MuiIcons.ViewDayTwoToneIcon;
export const DirectoryIcon = MuiIcons.FolderTwoToneIcon;
export const HomeIcon = MuiIcons.HomeTwoToneIcon;
export const SchoolIcon = MuiIcons.LocationCityTwoToneIcon;
export const TermIcon = MuiIcons.CalendarTodayTwoToneIcon;
export const ClassesIcon = MuiIcons.SupervisedUserCircleTwoToneIcon;
export const RoomsIcon = MuiIcons.MeetingRoomTwoToneIcon;
export const SubjectsIcon = MuiIcons.LibraryBooksTwoToneIcon;
export const TeachersIcon = MuiIcons.RecordVoiceOverTwoToneIcon;
export const AuthIcon = MuiIcons.VpnKeyTwoToneIcon;
export const PeopleIcon = MuiIcons.PeopleAltTwoToneIcon;
// endregion

// region --> Action icons
export const CopyIcon = MuiIcons.FileCopyTwoToneIcon;
export const SyncIcon = MuiIcons.SyncTwoToneIcon;
export const FilterIcon = MuiIcons.FilterListTwoToneIcon;
export const ClearIcon = MuiIcons.ClearTwoToneIcon;
export const ClearCircleIcon = MuiIcons.HighlightOffTwoToneIcon;
export const DeleteIcon = MuiIcons.DeleteTwoToneIcon;
export const { SignInIcon } = Custom;
export const { SignOutIcon } = Custom;
// endregion

// region --> Table icons
export const TableAddIcon = MuiIcons.AddBoxTwoToneIcon;
export const TableCheckIcon = MuiIcons.CheckTwoToneIcon;
export const TableClearIcon = MuiIcons.ClearTwoToneIcon;
export const TableDeleteIcon = MuiIcons.DeleteOutlineTwoToneIcon;
export const TableDetailPanelIcon = MuiIcons.ChevronRightTwoToneIcon;
export const TableEditIcon = MuiIcons.EditTwoToneIcon;
export const TableExportIcon = MuiIcons.SaveAltTwoToneIcon;
export const TableFilterIcon = MuiIcons.FilterListTwoToneIcon;
export const TableFirstPageIcon = MuiIcons.FirstPageTwoToneIcon;
export const TableLastPageIcon = MuiIcons.LastPageTwoToneIcon;
export const TableNextPageIcon = MuiIcons.ChevronRightTwoToneIcon;
export const TablePreviousPageIcon = MuiIcons.ChevronLeftTwoToneIcon;
export const TableResetSearchIcon = MuiIcons.ClearTwoToneIcon;
export const TableResizeIcon = MuiIcons.DragHandleTwoToneIcon;
export const TableSearchIcon = MuiIcons.SearchTwoToneIcon;
export const TableSortArrowIcon = MuiIcons.ArrowDownwardTwoToneIcon;
export const TableThirdStateCheckIcon = MuiIcons.RemoveTwoToneIcon;
export const TableViewColumnIcon = MuiIcons.ViewColumnTwoToneIcon;
export const TableRetryIcon = MuiIcons.ReplayTwoToneIcon;
// endregion
