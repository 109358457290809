import { useMemo } from 'react';

import { useTeachers } from 'modules/planner/metabolism/use-teachers';

export const useTeacherSelector = () => {
  const {
    teachers,
    teachersLoading,
    teachersError,
    selectedTeachers,
    setSelectedTeachers,
  } = useTeachers();

  const teachersData = useMemo(() => {
    return teachers
      ? teachers.map((t, index) => ({
          text: t.person?.name ?? 'noname',
          value: t.id ?? index,
        }))
      : undefined;
  }, [teachers]);

  return {
    teachersLoading,
    teachersError,
    teachers: teachersData,
    selectedTeachers,
    setSelectedTeachers,
  };
};
