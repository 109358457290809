import React from 'react';

import { CircularProgress } from 'core/cells/circular-progress';
import { FullBox } from 'core/cells/box';

import { useOasHistory } from 'core/metabolism/use-oas-history';
import { useLocale } from 'core/dna/routing';

import {
  useLogin,
  defaultLoginCallback,
  defaultAuthErrorCallback,
} from '../metabolism';

export const AuthLogin = () => {
  const { replace } = useOasHistory();
  const locale = useLocale();

  useLogin(defaultLoginCallback, (error: any) =>
    defaultAuthErrorCallback(error, { navigateTo: replace, locale }),
  );

  return (
    <FullBox>
      <CircularProgress size={100} />
    </FullBox>
  );
};
