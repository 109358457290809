import { useState } from 'react';

import { useTraceUpdate } from 'core/atoms/housekeeping';

import {
  AccessRolesStateContext,
  AccessRolesUpdaterContext,
} from 'core/dna/access-roles';
import { RootAccessRoles } from 'core/dna/types/access-roles';

export const AccessRolesProvider = (props: any) => {
  const { children } = props;

  // TODO: remove after performance testing
  useTraceUpdate('AccessRolesProvider', props);

  const [value, setValue] = useState<RootAccessRoles[] | null>(null);

  return (
    <AccessRolesStateContext.Provider value={value}>
      <AccessRolesUpdaterContext.Provider value={setValue}>
        {children}
      </AccessRolesUpdaterContext.Provider>
    </AccessRolesStateContext.Provider>
  );
};
