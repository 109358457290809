import {
  GetClasses_Class as ApiClass,
  GetClasses_Class_students as ApiStudent,
} from 'core/dna/types/generated/GetClasses';
import { Class } from 'modules/planner/dna/types/class';
import { Student } from 'modules/planner/dna/types/student';
import { mapNullableListToList } from 'core/dna/functions/map-nullable-list-to-list';
import { mapGetClassesClassUnionsListToUnionList } from 'modules/planner/dna/functions/map-GetClasses_Class_unions-to-Union';
import { mapApiAvailabilityPreferenceListToAvailabilityPreferenceList } from 'modules/planner/dna/functions/map-ApiAvailabilityPreference-to-AvailabilityPreference';

export const mapApiClassListToClassList = (
  apiClassList: (ApiClass | null)[] | null,
): Class[] | undefined => {
  return mapNullableListToList<ApiClass, Class>(
    apiClassList,
    mapApiClassToClass,
  );
};

export const mapApiClassToClass = (apiClass: ApiClass | null): Class => {
  if (apiClass === null) {
    throw new TypeError('"apiClass" should not be null!');
  }

  const {
    id,
    name,
    unions,
    availabilityPreferences,
    students: apiStudents,
  } = apiClass;

  const students = mapNullableListToList<ApiStudent, Student>(
    apiStudents,
    (apiStudent) => {
      if (!apiStudent) {
        return;
      }
      return {
        id: `${apiStudent.id}_${id}`,
        tuid: apiStudent.person?.tuid ?? '',
        roleId: apiStudent.id,
        classId: id,
        firstName: apiStudent.person?.details?.firstName ?? undefined,
        lastName: apiStudent.person?.details?.lastName ?? undefined,
        chapters: mapNullableListToList(
          apiStudent.chapters,
          (item) => item?.id ?? '',
        ),
      };
    },
  );

  return {
    id,
    name: name ?? 'noname',
    unions: mapGetClassesClassUnionsListToUnionList(unions),
    availabilityPreferences: mapApiAvailabilityPreferenceListToAvailabilityPreferenceList(
      availabilityPreferences,
    ),
    students,
  };
};
