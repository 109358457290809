import { useMemo, useEffect } from 'react';

import { logger, LogType } from 'core/atoms/housekeeping';

import { useTranslations } from 'core/dna/translations';

import { StudentBasicData } from 'modules/student/dna/types';
import { useGetStudents } from 'modules/student/memory/apollo/students/remote/get-students';
import { DisplayCellHours } from './display-cell-hours';

interface SleepHoursTableItem extends StudentBasicData {
  sleep: string[];
}

export const useSleepHoursTableRead = () => {
  const { texts } = useTranslations();
  const { data, loading } = useGetStudents();

  useEffect(() => {
    logger.debug({
      title: 'did mount',
      type: LogType.Info,
      logger: 'use-sleep-hours-table-read',
    });
  }, []);

  useEffect(() => {
    logger.debug({
      title: 'on data',
      type: LogType.Info,
      logger: 'use-students-table-read',
      value: data?.length ? data : data?.length === 0 ? 'NO DATA' : 'UNDEFINED',
    });
  }, [data]);

  const columns = useMemo(
    () => [
      {
        Header: texts.planner.common.firstName,
        accessor: 'details.firstName',
      },
      {
        Header: texts.planner.common.lastName,
        accessor: 'details.lastName',
      },
      {
        Header: texts.planner.common.time,
        accessor: 'sleep',
        Cell: (row: any) => <DisplayCellHours {...row} />,
      },
    ],
    [texts],
  );

  const studentsWithSleepList = data?.filter((s) => s.configuration?.sleep);

  const mapStudentsToSleepEvents = (
    students: StudentBasicData[] | undefined,
  ): SleepHoursTableItem[] => {
    if (!students) {
      return [];
    }

    const sleepEvents: SleepHoursTableItem[] = [];

    students.forEach((student) => {
      const sleep = student.configuration?.sleep;

      if (sleep && sleep.length) {
        sleep.forEach((s, index) => {
          sleepEvents.push({ ...student, sleep: sleep[index] });
        });
      }
    });

    return sleepEvents.sort(
      (a, b) =>
        a.sleep[0].localeCompare(a.sleep[0]) -
        a.sleep[0].localeCompare(b.sleep[0]),
    );
  };

  return {
    title: texts.studentModule.sleepHours,
    columns,
    data: mapStudentsToSleepEvents(studentsWithSleepList),
    loading,
  };
};
