import { makeStyles, createStyles, CSSProperties } from 'core/atoms/styles';

export interface InstructionStyle {
  instruction: CSSProperties;
  picture: CSSProperties;
  image: CSSProperties;
}

export const instructionStyle: InstructionStyle = {
  instruction: {},
  picture: {},
  image: {
    width: '100%',
    height: 'auto',
    borderRadius: '6px',
    display: 'block',
    maxWidth: '100%',
  },
};

export const useStyles = makeStyles(() =>
  createStyles({ ...instructionStyle }),
);
