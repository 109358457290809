import { useContext } from 'react';

import { AccessRolesUpdaterContext } from './access-roles-updater-context';

export const useAccessRolesUpdater = () => {
  const setAccessRoles = useContext(AccessRolesUpdaterContext);

  if (typeof setAccessRoles === 'undefined') {
    throw new Error(
      'useAccessRolesUpdater must be used within a AccessRolesProvider',
    );
  }

  return setAccessRoles;
};
