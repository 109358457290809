import { useCallback, useMemo } from 'react';

import { OasPageError } from 'core/atoms/errors';

import {
  useCreateSubject,
  useGetSubjectInfosFromDefault,
  useGetSubjectInfosFromSync,
  useGetSubjects,
} from 'modules/planner/memory/apollo/subjects/remote';

import {
  ImportSubjectsResponse,
  subjectsStore,
  useSubjectsState,
} from '../../store/subjects';

export const useSubjectsImportList = (
  fileName = 'use-subjects-import-list',
) => {
  const { subjects } = useGetSubjects();
  const { createSubject } = useCreateSubject();

  const {
    subjectsState: { importSource, checkedForImport },
  } = useSubjectsState();

  const {
    loading: syncLoading,
    error: syncError,
    list: syncData,
    map: syncSubjectInfoMap,
  } = useGetSubjectInfosFromSync(importSource === 'sync');

  const {
    loading: defaultLoading,
    error: defaultError,
    list: defaultData,
    map: defaultSubjectInfoMap,
  } = useGetSubjectInfosFromDefault(importSource === 'default');

  const names = useMemo(() => {
    return subjects?.length ? subjects.map((s) => s.name) : undefined;
  }, [subjects]);

  const data = useMemo(() => {
    if (importSource) {
      return (importSource === 'default' ? defaultData : syncData) ?? [];
    }
    return [];
  }, [importSource, defaultData, syncData]);

  const handleCheck = useCallback(
    (checked: string[]) => {
      if (!importSource) {
        throw new OasPageError('Import source should be chosen', {
          title: fileName,
        });
      }
      const map =
        importSource === 'default' ? defaultSubjectInfoMap : syncSubjectInfoMap;

      map && subjectsStore.checkIdsForImport(checked, map);
    },
    [importSource, defaultSubjectInfoMap, syncSubjectInfoMap, fileName],
  );

  const importSubjects = useCallback(
    () =>
      Promise.allSettled(
        checkedForImport.allIds.map((id) => {
          const { name } = checkedForImport.byId[id];
          if (!names || names.indexOf(name) < 0) {
            createSubject(
              name,
              checkedForImport.byId[id].alias,
              undefined,
              checkedForImport.byId[id].bgColor,
            ).then();
          } else {
            return { name, type: 'duplicate' } as ImportSubjectsResponse;
            // throw new OasPageError('DUPLICATE', { data: { name } });
          }
          return { name, type: 'success' } as ImportSubjectsResponse;
        }),
      ),
    [checkedForImport, createSubject, names],
  );

  return {
    loading: defaultLoading || syncLoading || !(defaultData || syncData),
    error: defaultError || syncError,
    data,
    checkedForImport,
    handleCheck,
    importSubjects,
  };
};
