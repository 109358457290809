import { GetTeachers_Role as ApiRole } from 'core/dna/types/generated/GetTeachers';
import { mapNullableListToList } from 'core/dna/functions';

import { Teacher } from 'modules/planner/dna/types/teacher';
import { mapApiPersonToPerson } from '../map-ApiPerson-to-Person';
import { mapApiAvailabilityPreferenceListToAvailabilityPreferenceList } from '../map-ApiAvailabilityPreference-to-AvailabilityPreference';

export const mapApiRoleListToTeacherList = (
  apiOpeningHoursList: (ApiRole | null)[] | null,
): Teacher[] | undefined => {
  return mapNullableListToList<ApiRole, Teacher>(
    apiOpeningHoursList,
    mapApiRoleToTeacher,
  );
};

export const mapApiRoleToTeacher = (
  apiRole: ApiRole | null,
): Teacher | undefined => {
  if (!apiRole) {
    return;
  }
  const {
    id,
    title,
    person,
    availabilityPreferences,
    type,
    employmentPercentage,
    reservedEmploymentPercentage,
  } = apiRole;

  return {
    id,
    title: title ?? undefined,
    person: mapApiPersonToPerson(person),
    availabilityPreferences: mapApiAvailabilityPreferenceListToAvailabilityPreferenceList(
      availabilityPreferences,
    ),
    type: type ?? undefined,
    reservedEmploymentPercentage: reservedEmploymentPercentage ?? 0,
    employmentPercentage: employmentPercentage ?? 100,
  };
};
