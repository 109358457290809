import { gql } from 'core/dna/types/apollo';

import { ROOM_DATA_FRAGMENT } from 'modules/planner/memory/apollo/_fragments';

export const GET_ROOMS_QUERY = gql`
  query GetRooms($termId: String!) {
    Room(filter: { term: { id: $termId } }) {
      ...RoomData
    }
  }
  ${ROOM_DATA_FRAGMENT}
`;
