import { useCallback } from 'react';
import { useMutation } from 'core/dna/types/apollo';

import { EDIT_SUBJECT_MUTATION } from './gql';

export interface EditSubjectInput {
  subjectId: string;
  name: string;
  alias?: string;
  code?: string;
  bgColor?: string;
  annualHours?: number;
}

export const useEditSubject = () => {
  const [editSubject, { loading, error }] = useMutation(EDIT_SUBJECT_MUTATION);

  const func = useCallback(
    (input: EditSubjectInput) => {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise<any>(async (resolve, reject) => {
        try {
          const editSubjectResult = await editSubject({
            variables: { input },
          });

          if (editSubjectResult.data && !editSubjectResult.errors) {
            resolve(editSubjectResult);
          } else {
            reject(new Error('editSubject error!'));
          }
        } catch (e: any) {
          reject(e);
        }
      });
    },
    [editSubject],
  );

  return {
    editSubject: func,
    loading,
    error,
  };
};
