export type SubstitutePathType =
  | '/:locale(no|en|uk)/substitute'
  | '/:locale(no|en|uk)/substitute/jobs'
  | '/:locale(no|en|uk)/substitute/jobs/:id/details'
  | '/:locale(no|en|uk)/substitute/jobs/:id/edit'
  | '/:locale(no|en|uk)/substitute/teachers'
  | '/:locale(no|en|uk)/substitute/invitation/:id';

const SubstitutePath: SubstitutePathType = '/:locale(no|en|uk)/substitute';
const SubstituteJobsPath: SubstitutePathType =
  '/:locale(no|en|uk)/substitute/jobs';
const SubstituteJobDetailsPath: SubstitutePathType =
  '/:locale(no|en|uk)/substitute/jobs/:id/details';
const SubstituteJobsEditPath: SubstitutePathType =
  '/:locale(no|en|uk)/substitute/jobs/:id/edit';
const SubstituteTeachersPath: SubstitutePathType =
  '/:locale(no|en|uk)/substitute/teachers';
const SubstituteInvitationPath: SubstitutePathType =
  '/:locale(no|en|uk)/substitute/invitation/:id';

export const PATHS = {
  path: SubstitutePath,
  part: 'substitute',
  Jobs: {
    path: SubstituteJobsPath,
    part: 'jobs',
    Details: {
      path: SubstituteJobDetailsPath,
      part: 'details',
      params: {
        jobId: {
          pathKey: ':id',
          key: 'id',
        },
      },
    },
    Edit: {
      path: SubstituteJobsEditPath,
      part: 'edit',
      params: {
        jobId: {
          pathKey: ':id',
          key: 'id',
        },
      },
    },
  },
  Teachers: {
    path: SubstituteTeachersPath,
    part: 'teachers',
  },
  Invitation: {
    path: SubstituteInvitationPath,
    part: 'invitation',
    params: {
      invitationId: {
        pathKey: ':id',
        key: 'id',
      },
    },
  },
};
