import { useMemo, useEffect } from 'react';

import { logger, LogType } from 'core/atoms/housekeeping';

import {
  mapTranslationDueToOrgCategoryType,
  useTranslations,
} from 'core/dna/translations';
import { OrganizationCategoryType } from 'core/dna/types/generated/_globalTypes';
import { useOrganization } from 'core/metabolism/organizations';

import { mapGroupsToString } from 'modules/student/dna/functions';

import { useGetStudents } from 'modules/student/memory/apollo/students/remote/get-students';
import { DisplayCellData } from './display-cell-data';

export const useStudentsTableRead = () => {
  const { texts } = useTranslations();
  const { data, loading } = useGetStudents();
  const { organization } = useOrganization();

  useEffect(() => {
    logger.debug({
      title: 'did mount',
      type: LogType.Info,
      logger: 'use-students-table-read',
    });
  }, []);

  useEffect(() => {
    logger.debug({
      title: 'on data',
      type: LogType.Info,
      logger: 'use-students-table-read',
      value: data?.length ? data : data?.length === 0 ? 'NO DATA' : 'UNDEFINED',
    });
  }, [data]);

  const columns = useMemo(() => {
    const cols = {
      firstName: {
        Header: texts.planner.common.firstName,
        accessor: 'details.firstName',
        Cell: (row: any) => <DisplayCellData {...row} />,
      },
      lastName: {
        Header: texts.planner.common.lastName,
        accessor: 'details.lastName',
        Cell: (row: any) => <DisplayCellData {...row} />,
      },
      groups: {
        Header: texts.oasCommon.group.one,
        accessor: 'details.groups',
      },
      actions: {
        width: 30,
        Header: '',
        accessor: 'actions',
        disableFilters: true,
      },
    };

    return organization?.category === OrganizationCategoryType.barnehage
      ? [cols.firstName, cols.lastName, cols.groups, cols.actions]
      : [cols.firstName, cols.lastName, cols.groups];
  }, [organization, texts]);

  const preparedData = useMemo<any[]>(() => {
    if (!data) {
      return [];
    }

    return data.map((student) => {
      return {
        ...student,
        details: {
          ...student.details,
          groups: mapGroupsToString(student.details.groups),
        },
      };
    });
  }, [data]);

  return {
    title: mapTranslationDueToOrgCategoryType(
      texts,
      organization?.category,
      'students',
    ),
    columns,
    data: preparedData,
    loading,
  };
};
