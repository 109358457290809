import moment from 'moment';

import { GetScheduleDescriptions_Schedule as ApiScheduleDescription } from 'core/dna/types/generated/GetScheduleDescriptions';
import { GetSchedule_Schedule as ApiSchedule } from 'core/dna/types/generated/GetSchedule';
import {
  mapNullableListToIdentityMap,
  mapNullableListToList,
} from 'core/dna/functions';

import {
  Schedule,
  ScheduleDescription,
  ScheduleDescriptionMap,
} from 'modules/planner/dna/types/schedule';

import { mapApiLessonGroupListToLessonGroupList } from '../map-ApiLessonGroup-to-LessonGroup';

export const mapApiScheduleDescriptionListToScheduleDescriptionMap = (
  apiScheduleDescriptionList: (ApiScheduleDescription | null)[] | null,
): ScheduleDescriptionMap | undefined => {
  return mapNullableListToIdentityMap<
    ApiScheduleDescription,
    ScheduleDescription
  >(apiScheduleDescriptionList, mapApiScheduleDescriptionToScheduleDescription);
};

export const mapApiScheduleDescriptionListToScheduleDescriptionList = (
  apiScheduleDescriptionList: (ApiScheduleDescription | null)[] | null,
): ScheduleDescription[] | undefined => {
  return mapNullableListToList<ApiScheduleDescription, ScheduleDescription>(
    apiScheduleDescriptionList,
    mapApiScheduleDescriptionToScheduleDescription,
  );
};

export const mapApiScheduleDescriptionToScheduleDescription = (
  apiScheduleDescription: ApiScheduleDescription | ApiSchedule | null,
): ScheduleDescription | undefined => {
  if (!apiScheduleDescription) {
    return;
  }

  const {
    id,
    name,
    status,
    dirty,
    score,
    lessonGroupCount,
    timeSlotsUpdatedAt,
    shareCode,
    shareCodeUpdatedAt,
  } = apiScheduleDescription;

  let timeSlotsUpdatedAtMoment;
  let shareCodeUpdatedAtMoment;
  if (timeSlotsUpdatedAt?.formatted) {
    timeSlotsUpdatedAtMoment = moment
      .utc(timeSlotsUpdatedAt?.formatted.split('[')[0], moment.ISO_8601, false)
      .toDate();
  }
  if (shareCodeUpdatedAt?.formatted) {
    shareCodeUpdatedAtMoment = moment
      .utc(shareCodeUpdatedAt?.formatted.split('[')[0], moment.ISO_8601, false)
      .toDate();
  }

  return {
    id,
    name,
    status: status ?? undefined,
    dirty: dirty ?? undefined,
    score: score ?? undefined,
    lessonGroupCount: lessonGroupCount ?? undefined,
    timeSlotsUpdatedAt: timeSlotsUpdatedAtMoment,
    shareCode: shareCode ?? undefined,
    shareCodeUpdatedAt: shareCodeUpdatedAtMoment,
  };
};

export const mapApiScheduleListToScheduleList = (
  apiScheduleList: (ApiSchedule | null)[] | null,
): Schedule[] | undefined => {
  return mapNullableListToList<ApiSchedule, Schedule>(
    apiScheduleList,
    mapApiScheduleToSchedule,
  );
};

export const mapApiScheduleToSchedule = (
  apiSchedule: ApiSchedule | null,
): Schedule | undefined => {
  if (!apiSchedule) {
    return;
  }

  const { lessonGroups, term } = apiSchedule;

  return {
    ...mapApiScheduleDescriptionToScheduleDescription(apiSchedule!)!,
    lessonGroups: mapApiLessonGroupListToLessonGroupList(lessonGroups),
    termId: term?.id,
  };
};
