import { OasMappingError } from 'core/atoms/errors';

import {
  GetSubstituteInvitationDetails_SubstituteJobInvitation as ApiSubstituteJobInvitationDetails,
  GetSubstituteInvitationDetails_SubstituteJobInvitation_job_tasks as ApiSubstituteJobInvitationDetailsJobTask,
} from 'core/dna/types/generated/GetSubstituteInvitationDetails';
import { mapNullableListToList } from 'core/dna/functions/map-nullable-list-to-list';

import { SubstituteInvitationDetails } from 'modules/substitute/dna/types/substitute-invitation-details';
import { SubstituteTask } from 'modules/substitute/dna/types/substitute-task';

export const mapApiSubstituteJobInvitationDetailsToSubstituteInvitationDetails = (
  apiSubstituteJobInvitation: ApiSubstituteJobInvitationDetails | null,
): SubstituteInvitationDetails => {
  if (apiSubstituteJobInvitation === null) {
    throw new OasMappingError(
      'apiSubstituteJobInvitation" should not be null!',
      {
        title: 'Map ApiSubstituteJobInvitation to SubstituteInvitationDetails',
      },
    );
  }

  const { id, to, job } = apiSubstituteJobInvitation;

  return {
    id,
    to: {
      id: to.id,
      firstName: to.firstName,
      lastName: to.lastName,
    },
    job: {
      deadline: job.deadline.formatted,
      description: job.description,
      state: job.state,
      tasks: mapNullableListToList<
        ApiSubstituteJobInvitationDetailsJobTask,
        SubstituteTask
      >(job.tasks, mapApiSubstituteJobInvitationDetailsJobTaskToSubstituteTask),
    },
  };
};

const mapApiSubstituteJobInvitationDetailsJobTaskToSubstituteTask = (
  apiSubstituteJobInvitationJobTask: ApiSubstituteJobInvitationDetailsJobTask | null,
): SubstituteTask => {
  if (apiSubstituteJobInvitationJobTask === null) {
    throw new OasMappingError(
      'apiSubstituteJobInvitationJobTask" should not be null!',
      {
        title: 'Map ApiSubstituteJobInvitationJobTask to SubstituteTask',
      },
    );
  }

  const {
    id,
    subject,
    comment,
    myResponse,
    assignedTo,
    state,
    date,
    timeFrom,
    timeTo,
  } = apiSubstituteJobInvitationJobTask;

  const result: SubstituteTask = {
    id,
    subject,
    date: date.formatted ?? '',
    timeFrom: timeFrom.formatted ?? '',
    timeTo: timeTo.formatted ?? '',
    comment: comment ?? '',
  };

  if (assignedTo) {
    result.assignedTo = {
      id: assignedTo.id,
      firstName: assignedTo.firstName,
      lastName: assignedTo.lastName,
    };
  }
  if (state) {
    result.state = state;
  }
  if (myResponse) {
    result.myResponse = myResponse.response === 'yes' ? 'yes' : 'no';
  }

  return result;
};
