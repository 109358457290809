import { useCallback } from 'react';

import { OasError, OasPageError } from 'core/atoms/errors';
import { parseTemplate } from 'core/atoms/functions/string';

import { useSnackbar } from 'core/cells/notistack';

import { useTranslations } from 'core/dna/translations';

import { createJournalEntryInput } from 'core/dna/types/generated/_globalTypes';
import { useCreateJournalEntry } from 'modules/student/memory/apollo/students/remote/create-journal-entry';

const TITLE = 'useStudentsTableWrite';

export const useStudentsTableWrite = () => {
  const { texts } = useTranslations();
  const { enqueueSnackbar } = useSnackbar();

  const { createJournalEntry, loading, error } = useCreateJournalEntry();

  const onCreateJournalEntry = useCallback(
    async (input: createJournalEntryInput) => {
      try {
        await createJournalEntry(
          input.regardingTuid,
          input.type,
          input.data,
          input.from,
          input.to,
        );
        enqueueSnackbar(
          parseTemplate(texts.oasCommon.entityCreated, {
            entityType: 'Journal',
          }),
          { variant: 'success' },
        );
      } catch (e: any) {
        if (e instanceof OasError) {
          throw e;
        }
        throw OasPageError.fromError(e, { title: TITLE });
      }
    },
    [createJournalEntry, enqueueSnackbar, texts.oasCommon.entityCreated],
  );

  return {
    onCreateJournalEntry,
    loading,
    error,
  };
};
