import clsx from 'clsx';

import { makeStyles, createStyles } from 'core/atoms/styles';

import { Button } from 'templates/mdp/components/custom-buttons';
import { Zoom } from '@material-ui/core';

export interface WeekProps {
  weekText: string;
  scheduleText?: string;
  assigned?: boolean;
  onClick?: () => void;
}

const useStyles = makeStyles((theme) => {
  const width = theme.spacing(24);
  const height = theme.spacing(8);
  const shiftX = width / 2 - 16;
  const shiftY = height / 2 - 14;
  return createStyles({
    root: {
      margin: theme.spacing(0.5),
      marginTop: theme.spacing(1.5),
      width,
      height,
      overflow: 'hidden',
    },
    weekNumber: {
      position: 'absolute',
      margin: 0,
      transition: 'transform 250ms',
    },
    weekNumberAssigned: {
      '-webkit-transform': `translate(-${shiftX}px, -${shiftY}px) scale(0.9)`,
      transform: `translate(-${shiftX}px, -${shiftY}px) scale(0.9)`,
    },
    scheduleName: {
      position: 'absolute',
      bottom: 4,
      right: 6,
      fontSize: 12,
    },
  });
});

export const Week = (props: WeekProps) => {
  const { weekText, scheduleText, assigned, onClick } = props;
  const classes = useStyles();

  return (
    <Button
      size="sm"
      color={assigned ? 'success' : undefined}
      className={classes.root}
      onClick={onClick}
    >
      <h3
        className={clsx(
          classes.weekNumber,
          assigned ? classes.weekNumberAssigned : '',
        )}
      >
        {weekText}
      </h3>
      <Zoom in={assigned} timeout={250}>
        <h5 className={classes.scheduleName}>{scheduleText}</h5>
      </Zoom>
    </Button>
  );
};
