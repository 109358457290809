import { useMemo } from 'react';

import { gql, useQuery } from 'core/dna/types/apollo';
import { GetSubstituteJobs } from 'core/dna/types/generated/GetSubstituteJobs';

import { Job } from 'modules/substitute/dna/types/job';
import {
  mapApiSubstituteTaskListToSubstituteTaskList,
  mapApiSubstituteInvitationListToSubstituteInvitationList,
} from 'modules/substitute/dna/functions';

import { SUBSTITUTE_TASK_DATA_FRAGMENT } from 'modules/substitute/memory/apollo/_fragments';

export const GET_SUBSTITUTE_JOBS_QUERY = gql`
  query GetSubstituteJobs {
    SubstituteJob {
      id
      internalTitle
      description
      deadline {
        formatted
      }
      state
      tasks {
        ...SubstituteTaskData
      }
      invitations {
        inviteId
        teacher {
          id
          firstName
          lastName
        }
      }
    }
  }
  ${SUBSTITUTE_TASK_DATA_FRAGMENT}
`;

export const useGetSubstituteJobs = () => {
  const { data: apiData, error, loading } = useQuery<GetSubstituteJobs>(
    GET_SUBSTITUTE_JOBS_QUERY,
  );

  const data = useMemo(() => {
    if (!loading && !error && apiData?.SubstituteJob) {
      return apiData.SubstituteJob.map(
        (j) =>
          ({
            id: j?.id,
            description: j?.description,
            internalTitle: j?.internalTitle,
            deadline: j?.deadline.formatted,
            state: j?.state,
            tasks: mapApiSubstituteTaskListToSubstituteTaskList(
              j?.tasks ?? null,
            ),
            invitations: mapApiSubstituteInvitationListToSubstituteInvitationList(
              j?.invitations ?? null,
            ),
          } as Job),
      );
    }
  }, [apiData, error, loading]);

  return {
    data,
    error,
    loading,
  };
};
