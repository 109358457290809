import { useState, useCallback, useMemo, useEffect, useRef } from 'react';

import { useTraceUpdate, logger, LogType } from 'core/atoms/housekeeping';
import { OasPageError } from 'core/atoms/errors';

import { ModuleState, OrganizationLevel } from 'core/dna/types/local';
import { ModuleModeEnum, Modules } from 'core/dna/types/modules';
import { Organization } from 'core/dna/types/organization';
import {
  defaultModules,
  ModulesStateContext,
  ModulesUpdaterContext,
  SelectedModuleStateContext,
  ModulesUpdaterContextValue,
  SelectedModuleUpdaterContext,
} from 'core/dna/modules';
import { OrganizationFeatureCode } from 'core/dna/types/generated/_globalTypes';
import { ModuleSelector } from 'core/dna/translations/types';

import {
  mapTranslationDueToOrgCategoryType,
  useTranslations,
} from 'core/dna/translations';
import { useSelectedOrganizationState } from 'core/dna/organizations';

import { getModuleStorage } from 'core/memory/browser';

import { useAuthContext } from 'auth/metabolism/use-auth-context';

import { useRouteModule } from 'app/routes';

const FILE_NAME = 'modules-provider';

export const ModulesProvider = (props: any) => {
  const { children } = props;

  // TODO: remove after performance testing
  useTraceUpdate('ModulesStateProvider', props);

  const { texts } = useTranslations();
  const { getIdentity } = useAuthContext();
  const identity = getIdentity();

  const [modules, setModules] = useState(defaultModules);
  const [selectedModule, setSelectedModule] = useState<ModuleState | null>(
    null,
  );

  const { setModule: setBrowserModule } = useMemo(() => getModuleStorage(), []);
  const selectedOrganization = useSelectedOrganizationState();
  const prevOrgLevel = useRef<OrganizationLevel | null>(null);
  const prevOrgId = useRef('');
  const { getSelectedModuleFromRoute } = useRouteModule();

  const select = useCallback(
    (id: string) => {
      const newModule = modules.find((m) => m.id === id);
      if (!newModule) {
        return null;
      }
      setBrowserModule(id);
      setSelectedModule(newModule);
      return newModule;
    },
    [modules, setBrowserModule],
  );

  const getSelectedModule = useCallback(
    (modules: ModuleState[]) => {
      const moduleId = getSelectedModuleFromRoute();
      // TODO: To think do we need to filter module by expiration or "on"/"of" status
      const module = modules?.find((m) => m.id === moduleId);
      return module || (modules?.length ? modules[0] : null);
    },
    [getSelectedModuleFromRoute],
  );

  const reset = useCallback(
    (level: OrganizationLevel) => {
      try {
        const values = modules.filter(
          (m) => m.level === level || m.level === OrganizationLevel.Unset,
        );

        const selected = getSelectedModule(values);

        const newData = values ? [...values] : [];
        setBrowserModule(selected?.id ?? null);
        setModules(newData);
        setSelectedModule(selected);
        return selected;
      } catch (e: any) {
        throw OasPageError.fromError(e, {
          title: FILE_NAME,
        });
      }
    },
    [getSelectedModule, modules, setBrowserModule],
  );

  const updateSubscriptionsInfo = useCallback(
    (organization: Organization) => {
      const newModules = modules.map((module) => {
        let text =
          texts.oasCommon.moduleSelector[module.text as keyof ModuleSelector];

        if (module.id === Modules.student) {
          text = mapTranslationDueToOrgCategoryType(
            texts,
            organization?.category,
            'moduleStudent',
          );
        }

        const activeModule: ModuleState = {
          ...module,
          text,
          expired: false,
          expiresAt: null,
          mode: ModuleModeEnum.active,
        };
        const offModule: ModuleState = {
          ...module,
          text,
          expired: null,
          expiresAt: null,
          mode: ModuleModeEnum.off,
        };

        switch (module.id) {
          case Modules.unset:
            return activeModule;
          case Modules.student:
            return organization.features?.some(
              (f) =>
                f.code === OrganizationFeatureCode.absence ||
                f.code === OrganizationFeatureCode.remarks,
            )
              ? activeModule
              : offModule;
          case Modules.reports:
            return organization?.features?.some(
              (f) => f.code === OrganizationFeatureCode.absence,
            )
              ? activeModule
              : offModule;
          case Modules.sms:
            const smsFeature = organization?.features?.find(
              (f) => f.code === OrganizationFeatureCode.canSendSmsNotifications,
            );

            return smsFeature && identity?.provider === 't1'
              ? {
                  ...module,
                  text,
                  expired: smsFeature.expired,
                  expiresAt: smsFeature.expiresAt,
                  mode: smsFeature.mode,
                }
              : offModule;
          case Modules.messageBook:
            const messageBookFeature = organization?.features?.find(
              (f) => f.code === OrganizationFeatureCode.messageBook,
            );

            return messageBookFeature && identity?.provider === 't2'
              ? {
                  ...module,
                  text,
                  expired: messageBookFeature.expired,
                  expiresAt: messageBookFeature.expiresAt,
                  mode: messageBookFeature.mode,
                }
              : offModule;
          default:
            const feature = organization?.features?.find(
              (f) => f.code === module.id,
            );
            const isAvailable =
              module.level === selectedOrganization?.level &&
              selectedOrganization.permissions.indexOf(module.id) > -1;

            return !feature || !isAvailable
              ? offModule
              : {
                  ...module,
                  text,
                  expired: feature.expired,
                  expiresAt: feature.expiresAt,
                  mode: feature.mode,
                };
        }
      });
      const newSelected = getSelectedModule(newModules);
      setModules([...newModules]);
      setSelectedModule(newSelected);
      return newSelected;
    },
    [getSelectedModule, identity, modules, selectedOrganization, texts],
  );

  const modulesUpdater = useMemo<ModulesUpdaterContextValue>(
    () => ({
      reset,
      updateSubscriptionsInfo,
    }),
    [reset, updateSubscriptionsInfo],
  );

  useEffect(() => {
    if (
      selectedOrganization &&
      // prevOrgLevel.current !== selectedOrganization.level
      prevOrgId.current !== selectedOrganization.id
    ) {
      logger.debug({
        title: 'useEffect([reset, selectedOrganization)',
        logger: FILE_NAME,
        type: LogType.Success,
        value:
          'if (selectedOrganization && prevOrgLevel.current !== selectedOrganization.level)',
      });
      prevOrgLevel.current = selectedOrganization.level;
      prevOrgId.current = selectedOrganization.id;
      reset(selectedOrganization.level);
    }
  }, [reset, selectedOrganization]);

  return (
    <ModulesStateContext.Provider value={modules}>
      <SelectedModuleStateContext.Provider value={selectedModule}>
        <ModulesUpdaterContext.Provider value={modulesUpdater}>
          <SelectedModuleUpdaterContext.Provider value={select}>
            {children}
          </SelectedModuleUpdaterContext.Provider>
        </ModulesUpdaterContext.Provider>
      </SelectedModuleStateContext.Provider>
    </ModulesStateContext.Provider>
  );
};
