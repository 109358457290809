import { useRef, useMemo, useCallback } from 'react';

import { OasErrorInterface, OasError, OasPageError } from 'core/atoms/errors';
import { parseTemplate } from 'core/atoms/functions/string';

import { useSnackbar } from 'core/cells/notistack';

import { useTranslations } from 'core/dna/translations';

import {
  useCreateClass,
  useGetClasses,
} from 'modules/planner/memory/apollo/classes/remote';

import { classesStore, useClassesState } from '../../../../store/classes';

const FILE_NAME = 'use-classes-generator';

export const useClassesGenerator = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { texts } = useTranslations();

  const gradesRef = useRef([1, 2, 3, 4, 5, 6, 7, 8, 9, 10]);

  const { classes: classesList } = useGetClasses();
  const { createClass } = useCreateClass();

  const classNames = useMemo(() => {
    return classesList?.length ? classesList.map((c) => c.name) : undefined;
  }, [classesList]);

  const { classesState } = useClassesState();
  const { generatingOptions } = classesState;

  const generateClasses = useCallback(() => {
    return Promise.all(
      generatingOptions.allIds.map((grade) => {
        const { names } = generatingOptions.byId[grade];
        return names.forEach((name) => {
          if (
            !classNames
              ?.map((n) => n.toLowerCase())
              ?.includes(name.toLowerCase())
          ) {
            createClass({ name })
              .then(() => {
                enqueueSnackbar(
                  parseTemplate(
                    texts.planner.plannerClasses.classNamedCreatedSuccessfully,
                    { name },
                  ),
                  {
                    variant: 'success',
                  },
                );
              })
              .catch((error) => {
                let e: OasErrorInterface;
                if (error instanceof OasError) {
                  e = error;
                } else {
                  e = OasPageError.fromError(error, { title: FILE_NAME });
                }
                throw e;
              });
          } else {
            enqueueSnackbar(
              parseTemplate(
                texts.planner.plannerClasses.duplicateNamedClassError,
                { name },
              ),
              {
                variant: 'warning',
              },
            );
          }
        });
      }),
    );
  }, [classNames, createClass, enqueueSnackbar, generatingOptions, texts]);

  const handleGenerate = useCallback(async () => {
    try {
      await classesStore.generateClasses(generateClasses);
    } catch (error: any) {
      let e: OasErrorInterface;
      if (error instanceof OasError) {
        e = error;
      } else {
        e = OasPageError.fromError(error, { title: FILE_NAME });
      }
      enqueueSnackbar(e.message, {
        variant: 'error',
      });
    }
  }, [enqueueSnackbar, generateClasses]);

  return {
    grades: gradesRef.current,
    clearGeneratingOptions: classesStore.clearGeneratingOptions,
    handleGenerate,
  };
};
