import { makeStyles, createStyles } from 'core/atoms/styles';

import { Grid } from 'core/cells/grid';
import { Typography } from 'core/cells/typography';
import { TimeField } from 'core/cells/time-field';

import { useTranslations } from 'core/dna/translations';

const useStyles = makeStyles(() =>
  createStyles({
    hoursTitle: {
      width: 80,
    },
  }),
);

export type WorkTimePart = 'opening' | 'closing';

export interface DayWorkingHoursProps {
  title: string;
  opening?: string;
  closing?: string;
  onChange?: (value: string, part: WorkTimePart) => void;
  disabled?: boolean;
}

const defaultProps: Partial<DayWorkingHoursProps> = {};

export const DayWorkingHours = (props: DayWorkingHoursProps) => {
  const classes = useStyles();
  const { title, opening, closing, onChange, disabled } = props;
  const { texts } = useTranslations();

  return (
    <Grid container spacing={3}>
      <Grid
        item
        className={classes.hoursTitle}
        style={{ display: 'flex', alignItems: 'center' }}
      >
        <Typography variant="h5">{title}</Typography>
      </Grid>
      <Grid item>
        <TimeField
          disabled={disabled}
          label={texts.oasCommon.from}
          value={opening}
          onChange={(event) => onChange?.(event.target.value, 'opening')}
        />
      </Grid>
      <Grid item>
        <TimeField
          disabled={disabled}
          label={texts.oasCommon.to}
          value={closing}
          onChange={(event) => onChange?.(event.target.value, 'closing')}
        />
      </Grid>
    </Grid>
  );
};

DayWorkingHours.defaultProps = defaultProps;
