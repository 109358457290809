import React, { useMemo } from 'react';

import { makeStyles, createStyles, Theme } from 'core/atoms/styles';
import { TextValue } from 'core/atoms/types';

import { Select } from 'core/cells/select';

import { useTranslations } from 'core/dna/translations';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    select: {
      width: 200,
    },
    separator: {
      fontSize: theme.spacing(3),
    },
  }),
);

export interface DayResourceSelectorProps {
  resourceName: string;
  value: string;
  onChange: (newValue: string) => void;
}

export const DayResourceSelector = (props: DayResourceSelectorProps) => {
  const classes = useStyles();
  const { lang } = useTranslations();

  const { value, resourceName, onChange } = props;

  const data = useMemo<TextValue[]>(() => {
    return [
      {
        text: lang.common.viewDays,
        value: 'days',
      },
      {
        text: resourceName,
        value: 'resources',
      },
    ];
  }, [lang, resourceName]);

  return (
    <Select
      name="day-resource"
      label={lang.common.view}
      data={data}
      value={value}
      className={classes.select}
      minWidth={200}
      onChange={onChange}
    />
  );
};
