import { useRef, useState, useLayoutEffect, useMemo } from 'react';
import clsx from 'clsx';

import { grey } from 'core/atoms/colors';
import { makeStyles, createStyles } from 'core/atoms/styles';
import { UiElementSize } from 'core/atoms/types';
import { useComponentSize } from 'core/atoms/functions';

import { InputLabel } from 'core/cells/input-label';
import { FormControl } from 'core/cells/form-control';
import { NotchedOutline } from 'core/cells/notched-outline';

export interface OutlinedAreaProps {
  label?: string;
  focused?: boolean;
  occupied?: boolean;
  selected?: boolean;
  minWidth?: number | string;
  minHeight?: number | string;
  className?: string;
  children?: any;
  classes?: { occupied?: string; label?: string };
  bigLabel?: boolean;
  error?: boolean;
}

const defaultProps: Partial<OutlinedAreaProps> = {
  label: '',
  focused: false,
  occupied: false,
  selected: false,
  minWidth: '100%',
  minHeight: 64,
  className: '',
};

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      minWidth: (props: OutlinedAreaProps) =>
        props.minWidth || defaultProps.minWidth,
      minHeight: (props: OutlinedAreaProps) =>
        props.minHeight || defaultProps.minHeight,
    },
    content: {
      height: '100%',
      minHeight: (props: OutlinedAreaProps) =>
        props.minHeight || defaultProps.minHeight,
    },
    label: {
      color: (props: OutlinedAreaProps) =>
        props.selected ? grey['900'] : 'inherit',
      fontWeight: (props: OutlinedAreaProps) =>
        props.selected ? 'bold' : 'normal',
    },
  }),
);

export const OutlinedArea = (props: OutlinedAreaProps) => {
  const classes = useStyles(props);

  const {
    label,
    focused = defaultProps.focused,
    occupied = defaultProps.occupied,
    selected = defaultProps.selected,
    className,
    children,
    classes: propsClasses,
    bigLabel,
    error,
  } = props;

  const outerBox = useRef<HTMLDivElement>(null);
  const inputLabel = useRef<HTMLLabelElement>(null);
  const notchedOutline = useRef<HTMLFieldSetElement>(null);

  const outerBoxSize = useComponentSize(outerBox);
  const [labelSize, setLabelSize] = useState<UiElementSize>({
    width: 0,
    height: 0,
  });

  useLayoutEffect(() => {
    if (inputLabel.current) {
      const width = inputLabel.current.offsetWidth;
      const height = inputLabel.current.offsetHeight;
      setLabelSize({ width, height });
    }
  }, []);

  const xOffset = useMemo(() => 12, []);
  const yOffset = useMemo(() => (bigLabel ? -12 : -6), [bigLabel]);
  const scale = useMemo(() => (bigLabel ? 1.5 : 0.75), [bigLabel]);

  return (
    <FormControl
      ref={outerBox}
      className={clsx(classes.root, className)}
      variant="outlined"
      component="div"
      error={error}
    >
      <InputLabel
        ref={inputLabel}
        focused={focused}
        shrink={focused || occupied}
        style={{
          transform:
            focused || occupied
              ? `translate(${xOffset}px, ${yOffset}px) scale(${scale})`
              : `translate(${outerBoxSize.width / 2 - labelSize.width / 2}px, ${
                  outerBoxSize.height / 2 - labelSize.height / 2
                }px) scale(1)`,
        }}
        className={clsx(classes.label, propsClasses?.label ?? '')}
      >
        {label}
      </InputLabel>
      <NotchedOutline
        ref={notchedOutline}
        focused={focused!}
        notched={focused || occupied || selected}
        labelWidth={labelSize.width}
        labelScale={scale}
        selected={selected}
        className={clsx(occupied ? props.classes?.occupied ?? '' : '')}
        error={error}
      />
      <div className={classes.content}>{children}</div>
    </FormControl>
  );
};

OutlinedArea.defaultProps = defaultProps;
