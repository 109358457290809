import { useCallback } from 'react';

import { gql, useMutation } from 'core/dna/types/apollo';
import {
  DeleteChapters,
  DeleteChaptersVariables,
} from 'core/dna/types/generated/DeleteChapters';

import { useLazyGetTermsState } from 'core/memory/apollo/terms/local';

import { GET_CLASSES_QUERY } from './_gql';

export interface DeleteChaptersInput {
  classId: string;
  chapterIds: string[];
}

const DELETE_CHAPTERS_MUTATION = gql`
  mutation DeleteChapters($input: DeleteChaptersInput) {
    deleteChapters(input: $input) {
      status
      description
    }
  }
`;

export const useDeleteChapters = () => {
  const { getTermState } = useLazyGetTermsState();

  const [deleteChapters, { loading, error }] = useMutation<
    DeleteChapters,
    DeleteChaptersVariables
  >(DELETE_CHAPTERS_MUTATION);

  const func = useCallback(
    async (input: DeleteChaptersInput) => {
      try {
        const termsState = await getTermState(true);

        const result = await deleteChapters({
          variables: {
            input: {
              chapterIds: input.chapterIds,
            },
          },
          refetchQueries: [
            {
              query: GET_CLASSES_QUERY,
              variables: { termId: termsState!.selected },
            },
          ],
        });
        const { data, errors } = result;

        if (data && !errors) {
          if (data.deleteChapters.status.toLowerCase() === 'ok') {
            return data.deleteChapters;
          }
          // eslint-disable-next-line no-throw-literal
          throw `ERROR: status is ${data.deleteChapters.status.toLowerCase()}`;
        } else {
          return { status: 'FE', description: 'deleteChapters error!' };
        }
      } catch (e: any) {
        return { status: 'FE', description: e.message };
      }
    },
    [deleteChapters, getTermState],
  );

  return {
    deleteChapters: func,
    loading,
    error,
  };
};
