import { useMemo, useCallback } from 'react';

import { logger, LogType } from 'core/atoms/housekeeping';
import { useInterval } from 'core/atoms/functions';
import { generateNumber } from 'core/atoms/functions/math';

import { mapTokensToAuthIdentity } from 'auth/dna/functions';

import {
  getAccessTokenStorage,
  getSessionTokenStorage,
} from 'auth/memory/browser';

import { useUpdateToken } from 'auth/metabolism/use-update-token';

const FILE_NAME = 'use-access-token-expiration-interval-check';

export const useAccessTokenExpirationIntervalCheck = () => {
  const { getAccessToken } = getAccessTokenStorage();
  const { getSessionToken } = getSessionTokenStorage();
  const { updateToken } = useUpdateToken();

  const interval = useMemo(
    () => generateNumber({ min: 20000, max: 40000 }),
    [],
  );

  const callback = useCallback(async () => {
    const accessToken = getAccessToken();
    const sessionToken = getSessionToken();

    if (!accessToken || !sessionToken) {
      logger.debug({
        title: 'callback: NO TOKEN',
        logger: FILE_NAME,
        type: LogType.Info,
        value: [
          {
            interval,
            accessToken,
            sessionToken,
          },
        ],
      });
      return;
    }

    const identity = mapTokensToAuthIdentity(accessToken, sessionToken);

    if (!identity) {
      logger.debug({
        title: 'callback: NO IDENTITY',
        logger: FILE_NAME,
        type: LogType.Info,
        value: [
          {
            interval,
            accessToken,
            sessionToken,
          },
        ],
      });
      return;
    }

    const now = Math.round(new Date().getTime() / 1000);
    let iat: number;
    let ttl: number;
    try {
      iat = identity.issuedAt;
      ttl = identity.expiredAt - iat;
    } catch (e: any) {
      logger.debug({
        title: 'callback: set "iat" and "ttl"',
        logger: FILE_NAME,
        type: LogType.Error,
        value: [
          {
            error: e,
            interval,
            now,
            iat: identity.issuedAt ?? 'UNDEFINED',
            exp: identity.expiredAt ?? 'UNDEFINED',
          },
        ],
      });
      return;
    }

    const threshold = generateNumber({ min: 0.7, max: 0.8 });

    if (now > iat + ttl * threshold) {
      await updateToken();
    }
  }, [getAccessToken, getSessionToken, interval, updateToken]);

  useInterval(callback, interval);
};
