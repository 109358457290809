import { forwardRef, useState, useCallback, ChangeEvent } from 'react';
import clsx from 'clsx';

import { makeStyles, createStyles } from 'core/atoms/styles';
import { parseTemplate } from 'core/atoms/functions/string';

import { Slide } from 'core/cells/slide';
import { Dialog } from 'core/cells/dialog';
import { DialogTitle } from 'core/cells/dialog-title';
import { DialogContent } from 'core/cells/dialog-content';
import { DialogContentText } from 'core/cells/dialog-content-text';
import { DialogActions } from 'core/cells/dialog-actions';

import { useTranslations } from 'core/dna/translations';

import { CustomInput } from 'templates/mdp/components/custom-input';
import { Button } from 'templates/mdp/components/custom-buttons';
import { buttonStyle } from 'templates/mdp/components/custom-buttons/button.styles';

import { useStyles as useNotificationsStyles } from 'templates/mdp/views/components/notifications.styles';

import { useTerm } from 'modules/planner/metabolism/terms';

export interface ScheduleDeleteConfirmationProps {
  id: string;
  name: string;
  open: boolean;
  onClose: () => void;
  deleteSchedule: (id: string) => Promise<void>;
}

const Transition: any = forwardRef(function Transition(props: any, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const useStyles = makeStyles(() =>
  createStyles({
    ...buttonStyle,
    disabled: {},
    marginRight: {
      marginRight: '5px',
    },
  }),
);

export const ScheduleDeleteConfirmation = (
  props: ScheduleDeleteConfirmationProps,
) => {
  const { open, onClose: onCloseProps, id, name, deleteSchedule } = props;
  const notificationsClasses = useNotificationsStyles();
  const classes = useStyles();

  const { texts } = useTranslations();
  const { term } = useTerm();
  const [confirmationText, setConfirmationText] = useState('');

  const handleConfirmationTextChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setConfirmationText(event.target.value);
    },
    [],
  );

  const onConfirm = useCallback(
    (e: any) => {
      e.preventDefault?.();
      if (confirmationText === name) {
        deleteSchedule(id).then(() => {
          setConfirmationText('');
          onCloseProps();
        });
      }
    },
    [confirmationText, deleteSchedule, id, name, onCloseProps],
  );

  const onClose = useCallback(() => {
    setConfirmationText('');
    onCloseProps();
  }, [onCloseProps]);

  return (
    <Dialog
      TransitionComponent={Transition}
      open={open}
      onClose={onClose}
      aria-labelledby="delete-schedule"
      classes={{
        root: `${notificationsClasses.center} ${notificationsClasses.modalRoot}`,
        paper: notificationsClasses.modal,
      }}
    >
      <DialogTitle
        id="delete-schedule"
        className={notificationsClasses.modalHeader}
      >
        {texts.planner.common.delete}
      </DialogTitle>
      <form>
        <DialogContent className={notificationsClasses.modalBody}>
          <DialogContentText>
            {parseTemplate(
              texts.planner.preparationSpace.schedulesModule
                .deleteScheduleMessage,
              { schedule: name, term: term?.name ?? '' },
            )}
          </DialogContentText>
          <CustomInput
            success={false}
            error={false}
            labelText={texts.oasCommon.schedules.one}
            id="delete-schedule-confirmation-text"
            formControlProps={{ fullWidth: true }}
            inputProps={{
              type: 'text',
              autoComplete: 'off',
              autoFocus: true,
              value: confirmationText,
              onChange: handleConfirmationTextChange,
            }}
          />
        </DialogContent>
        <DialogActions className={notificationsClasses.modalFooter}>
          <Button
            color="danger"
            className={clsx(
              classes.button,
              classes.danger,
              classes.marginRight,
            )}
            onClick={onConfirm}
            type="submit"
            disabled={confirmationText !== name}
          >
            {texts.planner.common.delete}
          </Button>
          <Button
            color="primary"
            simple
            className={classes.marginRight}
            onClick={onClose}
          >
            {texts.planner.common.cancel}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
