import { useMemo, useEffect, useCallback } from 'react';
import moment from 'moment';
import produce from 'immer';

import { logger, LogType } from 'core/atoms/housekeeping';

import { useQuery } from 'core/dna/types/apollo';
import {
  GetSchedule,
  GetScheduleVariables,
  GetSchedule_Schedule_lessonGroups_timeSlots as ApiTimeSlot,
} from 'core/dna/types/generated/GetSchedule';
import { ApiScheduleSnapshot } from 'core/dna/types/generated/ApiScheduleSnapshot';
import { mapApiScheduleListToScheduleList } from 'modules/planner/dna/functions/map-ApiSchedule-to-Schedule';

import { getRecentMessageTimeStorage } from 'modules/planner/memory/browser/schedule';

import { GET_SCHEDULE_QUERY, SCHEDULE_SNAPSHOT_SUBSCRIPTION } from './_gql';

export interface UseGetScheduleProps {
  scheduleId?: string;
  watch?: boolean;
  skip?: boolean;
}

const TITLE = 'use-get-schedule';

const {
  getRecentMessageTime,
  setRecentMessageTime,
  removeRecentMessageTime,
} = getRecentMessageTimeStorage();

export const useGetSchedule = (props: UseGetScheduleProps) => {
  const { scheduleId, watch, skip } = props;

  const { loading, error, data: apiData, subscribeToMore, refetch } = useQuery<
    GetSchedule,
    GetScheduleVariables
  >(GET_SCHEDULE_QUERY, {
    skip: skip || !scheduleId,
    variables: {
      scheduleId: scheduleId!,
    },
  });

  const data = useMemo(() => {
    if (!loading && !error && apiData) {
      const result = mapApiScheduleListToScheduleList(apiData.Schedule);
      if (result?.length) {
        return result[0];
      }
    }
  }, [loading, error, apiData]);

  const validateTimeStamp = useCallback((timeStamp?: number | null) => {
    const recentMessageTime = getRecentMessageTime();
    if (recentMessageTime && timeStamp) {
      if (timeStamp > recentMessageTime) {
        setRecentMessageTime(timeStamp);
      } else {
        logger.info(
          `[${TITLE}] subscribeToMore => updateQuery: recentMessageTime >= snapshot.timeStamp`,
          LogType.Secondary,
        );
        return;
      }
    } else if (!recentMessageTime && timeStamp) {
      setRecentMessageTime(timeStamp);
    }
    return true;
  }, []);

  useEffect(() => {
    if (!watch) {
      return;
    }
    let unsubscribe: () => void;
    if (data && subscribeToMore) {
      unsubscribe = subscribeToMore({
        document: SCHEDULE_SNAPSHOT_SUBSCRIPTION,
        variables: {
          scheduleId: data.id,
        },
        updateQuery: (
          prev,
          subscription: { subscriptionData: { data: ApiScheduleSnapshot } },
        ) => {
          logger.info(
            `[${TITLE}] subscribeToMore => updateQuery`,
            LogType.Accent,
          );
          const snapshot = subscription.subscriptionData.data.scheduleSnapshot;

          if (!prev.Schedule?.length) {
            logger.info(
              `[${TITLE}] subscribeToMore => updateQuery: NO DATA`,
              LogType.Secondary,
            );
            return prev;
          }

          if (!validateTimeStamp(snapshot?.timeStamp)) {
            return prev;
          }

          const result = produce(prev, (draft) => {
            const draftSchedule = draft.Schedule?.[0];
            if (draftSchedule) {
              draftSchedule.score = snapshot?.score ?? null;
              draftSchedule.status = snapshot?.status ?? null;
              draftSchedule.timeSlotsUpdatedAt = {
                __typename: '_Neo4jDateTime',
                formatted: moment.utc().toISOString(),
              };

              logger.debug({
                title: 'updateQuery() -> draftSchedule',
                logger: TITLE,
                type: LogType.Info,
                value: [
                  {
                    prev: JSON.stringify(prev),
                    snapshotScore: snapshot?.score ?? '-',
                    snapshotStatus: snapshot?.status ?? '-',
                    snapshotSuggestionsLength:
                      snapshot?.suggestions?.length ?? 'undefined',
                  },
                ],
              });

              draftSchedule.lessonGroups?.forEach((lg) => {
                if (lg) {
                  const suggestions = snapshot?.suggestions?.filter(
                    (s) => s?.lessonGroupId === lg.id,
                  );
                  if (suggestions?.length) {
                    lg.timeSlots = suggestions.map(
                      (s) =>
                        ({
                          __typename: 'TimeSlot',
                          startTime: {
                            __typename: '_Neo4jLocalTime',
                            formatted: s!.startTime?.formatted ?? null,
                          },
                          endTime: {
                            __typename: '_Neo4jLocalTime',
                            formatted: s!.endTime?.formatted ?? null,
                          },
                          weekday: s!.weekday,
                          pinned: s!.pinned ?? null,
                          splitId: s!.splitId ?? null,
                          id: s!.timeSlotId!,
                        } as ApiTimeSlot),
                    );
                  }
                }
              });
            } else {
              logger.debug({
                title: 'updateQuery() -> NO draftSchedule',
                logger: TITLE,
                type: LogType.Error,
                value: [
                  {
                    prev: JSON.stringify(prev),
                    snapshotScore: snapshot?.score ?? '-',
                    snapshotStatus: snapshot?.status ?? '-',
                    snapshotSuggestionsLength:
                      snapshot?.suggestions?.length ?? 'undefined',
                  },
                ],
              });
            }
          });

          const data = result.Schedule?.[0];
          logger.info(
            `[${TITLE}] subscribeToMore => updateQuery: new data -> name: "${data?.name}"`,
            LogType.Secondary,
          );
          logger.info(
            `[${TITLE}] subscribeToMore => updateQuery: new data -> status: "${data?.status}"`,
            LogType.Secondary,
          );
          logger.info(
            `[${TITLE}] subscribeToMore => updateQuery: new data -> lessonGroups: "${JSON.stringify(
              data?.lessonGroups?.map((lg) => ({
                id: lg?.id,
                timeSlots: lg?.timeSlots?.map((ts) => ({
                  startTime: ts?.startTime.formatted,
                  endTime: ts?.startTime.formatted,
                })),
              })),
            )}"`,
            LogType.Secondary,
          );

          return result;
        },
        onError: (e) => {
          logger.info(
            `[${TITLE}] subscribeToMore => onError: "${e.message}", "${e.stack}"`,
            LogType.Error,
          );
        },
      });
    }
    return () => {
      unsubscribe?.();
      removeRecentMessageTime();
    };
  }, [data, subscribeToMore, validateTimeStamp, watch]);

  return {
    loading,
    error,
    data,
    refetch,
  };
};
