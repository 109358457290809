export type PlannerPathType =
  | '/:locale(no|en|uk)/planner'
  | '/:locale(no|en|uk)/planner/welcome'
  | '/:locale(no|en|uk)/planner/error'
  | '/:locale(no|en|uk)/planner/test'
  | '/:locale(no|en|uk)/planner/source-data'
  | '/:locale(no|en|uk)/planner/source-data/organization'
  | '/:locale(no|en|uk)/planner/source-data/year-calendar'
  | '/:locale(no|en|uk)/planner/source-data/term'
  | '/:locale(no|en|uk)/planner/source-data/classes'
  | '/:locale(no|en|uk)/planner/source-data/classes/availability'
  | '/:locale(no|en|uk)/planner/source-data/classes/groups'
  | '/:locale(no|en|uk)/planner/source-data/classes/students'
  | '/:locale(no|en|uk)/planner/source-data/classes/students-groups'
  | '/:locale(no|en|uk)/planner/source-data/rooms'
  | '/:locale(no|en|uk)/planner/source-data/rooms/availability'
  | '/:locale(no|en|uk)/planner/source-data/subjects'
  | '/:locale(no|en|uk)/planner/source-data/teachers'
  | '/:locale(no|en|uk)/planner/source-data/teachers/availability'
  | '/:locale(no|en|uk)/planner/plan'
  | '/:locale(no|en|uk)/planner/plan/schedules'
  | '/:locale(no|en|uk)/planner/plan/schedules/:scheduleId?/lesson-groups'
  | '/:locale(no|en|uk)/planner/plan/schedules/:scheduleId?/lesson-groups/create'
  | '/:locale(no|en|uk)/planner/plan/schedules/:scheduleId?/lesson-groups/:lessonGroupId/edit'
  | '/:locale(no|en|uk)/planner/plan/schedules/:scheduleId?/time-plan'
  | '/:locale(no|en|uk)/planner/plan/schedules/:scheduleId?/teaching-time-report'
  | '/:locale(no|en|uk)/planner/share/:shareId'
  | '/:locale(no|en|uk)/planner/allocation'
  | '/:locale(no|en|uk)/planner/allocation/weeks'
  | '/:locale(no|en|uk)/planner/allocation/year-plan';

const PlannerPath: PlannerPathType = '/:locale(no|en|uk)/planner';
const WelcomePath: PlannerPathType = '/:locale(no|en|uk)/planner/welcome';
const ErrorPath: PlannerPathType = '/:locale(no|en|uk)/planner/error';
const TestPath: PlannerPathType = '/:locale(no|en|uk)/planner/test';
const SourceDataPath: PlannerPathType =
  '/:locale(no|en|uk)/planner/source-data';
const OrganizationPath: PlannerPathType =
  '/:locale(no|en|uk)/planner/source-data/organization';
const YearCalendarPath: PlannerPathType =
  '/:locale(no|en|uk)/planner/source-data/year-calendar';
const TermPath: PlannerPathType = '/:locale(no|en|uk)/planner/source-data/term';
const ClassesPath: PlannerPathType =
  '/:locale(no|en|uk)/planner/source-data/classes';
const ClassesAvailabilityPath: PlannerPathType =
  '/:locale(no|en|uk)/planner/source-data/classes/availability';
const ClassesGroupsPath: PlannerPathType =
  '/:locale(no|en|uk)/planner/source-data/classes/groups';
const ClassesStudentsPath: PlannerPathType =
  '/:locale(no|en|uk)/planner/source-data/classes/students';
const ClassesStudentsGroupsPath: PlannerPathType =
  '/:locale(no|en|uk)/planner/source-data/classes/students-groups';
const RoomsPath: PlannerPathType =
  '/:locale(no|en|uk)/planner/source-data/rooms';
const RoomsAvailabilityPath: PlannerPathType =
  '/:locale(no|en|uk)/planner/source-data/rooms/availability';
const SubjectsPath: PlannerPathType =
  '/:locale(no|en|uk)/planner/source-data/subjects';
const TeachersPath: PlannerPathType =
  '/:locale(no|en|uk)/planner/source-data/teachers';
const TeachersAvailabilityPath: PlannerPathType =
  '/:locale(no|en|uk)/planner/source-data/teachers/availability';
const PlanPath: PlannerPathType = '/:locale(no|en|uk)/planner/plan';
const SchedulesPath: PlannerPathType =
  '/:locale(no|en|uk)/planner/plan/schedules';
const LessonGroupsPath: PlannerPathType =
  '/:locale(no|en|uk)/planner/plan/schedules/:scheduleId?/lesson-groups';
const CreateLessonGroupPath: PlannerPathType =
  '/:locale(no|en|uk)/planner/plan/schedules/:scheduleId?/lesson-groups/create';
const EditLessonGroupPath: PlannerPathType =
  '/:locale(no|en|uk)/planner/plan/schedules/:scheduleId?/lesson-groups/:lessonGroupId/edit';
const TimePlanPath: PlannerPathType =
  '/:locale(no|en|uk)/planner/plan/schedules/:scheduleId?/time-plan';
const TeachingTimeReportPath: PlannerPathType =
  '/:locale(no|en|uk)/planner/plan/schedules/:scheduleId?/teaching-time-report';
const SharePath: PlannerPathType = '/:locale(no|en|uk)/planner/share/:shareId';
const AllocationPath: PlannerPathType = '/:locale(no|en|uk)/planner/allocation';
const AllocationWeeksPath: PlannerPathType =
  '/:locale(no|en|uk)/planner/allocation/weeks';
const YearPlanPath: PlannerPathType =
  '/:locale(no|en|uk)/planner/allocation/year-plan';

export default {
  path: PlannerPath,
  part: 'planner',
  Welcome: {
    path: WelcomePath,
    part: 'welcome',
  },
  Error: {
    path: ErrorPath,
    part: 'error',
  },
  Test: {
    path: TestPath,
    part: 'test',
  },
  SourceData: {
    path: SourceDataPath,
    Organization: {
      path: OrganizationPath,
      part: 'organization',
    },
    YearCalendar: {
      path: YearCalendarPath,
      part: 'year-calendar',
    },
    Term: {
      path: TermPath,
      part: 'term',
    },
    Classes: {
      path: ClassesPath,
      part: 'classes',
      Availability: {
        path: ClassesAvailabilityPath,
        part: 'availability',
      },
      Groups: {
        path: ClassesGroupsPath,
        part: 'groups',
      },
      Students: {
        path: ClassesStudentsPath,
        part: 'students',
      },
      StudentsGroups: {
        path: ClassesStudentsGroupsPath,
        part: 'students-groups',
      },
    },
    Rooms: {
      path: RoomsPath,
      part: 'rooms',
      Availability: {
        path: RoomsAvailabilityPath,
        part: 'availability',
      },
    },
    Subjects: {
      path: SubjectsPath,
      part: 'subjects',
    },
    Teachers: {
      path: TeachersPath,
      part: 'teachers',
      Availability: {
        path: TeachersAvailabilityPath,
        part: 'availability',
      },
    },
  },
  Plan: {
    path: PlanPath,
    part: 'plan',
    Schedules: {
      path: SchedulesPath,
      part: 'schedules',
      params: {
        scheduleId: {
          pathKey: ':scheduleId?',
          key: 'scheduleId',
        },
      },
      LessonGroups: {
        path: LessonGroupsPath,
        part: 'lesson-groups',
        params: {
          lessonGroupId: {
            pathKey: ':lessonGroupId',
            key: 'lessonGroupId',
          },
        },
        Create: {
          path: CreateLessonGroupPath,
          part: 'create',
        },
        Edit: {
          path: EditLessonGroupPath,
          part: 'edit',
        },
      },
      TimePlan: {
        path: TimePlanPath,
        part: 'time-plan',
      },
      TeachingTimeReport: {
        path: TeachingTimeReportPath,
        part: 'teaching-time-report',
      },
    },
  },
  Share: {
    path: SharePath,
    part: 'share',
    params: {
      shareId: {
        pathKey: ':shareId',
        key: 'shareId',
      },
    },
  },
  Allocation: {
    path: AllocationPath,
    part: 'allocation',
    Weeks: {
      path: AllocationWeeksPath,
      part: 'weeks',
    },
    YearPlan: {
      path: YearPlanPath,
      part: 'year-plan',
    },
  },
};
