import { OrganizationLevel, RouteConfig } from 'core/dna/types/local';
import { OasLanguage } from 'core/dna/routing';
import { OasLocales } from 'core/dna/translations/types';
import { PATHS } from './paths';

import { AbsenceMainContent } from './pages/absence';

export const getRoutes = (
  locale: OasLanguage,
  texts: OasLocales,
): RouteConfig[] => [
  {
    id: `reports-${PATHS.Absence.path}`,
    name: texts.studentModule.studentCommon.absence.one,
    mini: 'AR',
    private: true,
    path: PATHS.Absence.path,
    component: AbsenceMainContent,
    sidebar: true,
    level: OrganizationLevel.Organization,
    exact: true,
  },
];
