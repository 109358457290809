export type AuthPathType =
  | '/:locale(no|en|uk)/auth'
  | '/auth/login'
  | '/auth/callback'
  | '/:locale(no|en|uk)/auth/logged-out'
  | '/:locale(no|en|uk)/auth/lock';

const AuthPath: AuthPathType = '/:locale(no|en|uk)/auth';
const AuthLoginPath: AuthPathType = '/auth/login';
const AuthCallbackPath: AuthPathType = '/auth/callback';
const AuthLoggedOutPath: AuthPathType = '/:locale(no|en|uk)/auth/logged-out';
const AuthLockPath: AuthPathType = '/:locale(no|en|uk)/auth/lock';

export default {
  path: AuthPath,
  part: 'auth',
  Login: {
    path: AuthLoginPath,
    part: 'login',
  },
  Callback: {
    path: AuthCallbackPath,
    part: 'callback',
  },
  LoggedOut: {
    path: AuthLoggedOutPath,
    part: 'logged-out',
  },
  Lock: {
    path: AuthLockPath,
    part: 'lock',
  },
};
