import { useCallback } from 'react';

import { OasMemoryError, OasError } from 'core/atoms/errors';

import { gql, useMutation } from 'core/dna/types/apollo';
import {
  DeleteSubstituteJob,
  DeleteSubstituteJobVariables,
} from 'core/dna/types/generated/DeleteSubstituteJob';

import { GET_SUBSTITUTE_JOBS_QUERY } from 'modules/substitute/memory/apollo/substitute-jobs/remote';
import { EXECUTION_STATUS } from 'modules/substitute/memory/apollo/_fragments/remote';

export const DELETE_SUBSTITUTE_JOB_MUTATION = gql`
  mutation DeleteSubstituteJob($input: DeleteSubstituteJobInput) {
    deleteSubstituteJob(input: $input) {
      ...ExecutionStatus
    }
  }
  ${EXECUTION_STATUS}
`;

const TITLE = 'use-delete-substitute-job';

export const useDeleteSubstituteJob = () => {
  const [deleteSubstituteJob] = useMutation<
    DeleteSubstituteJob,
    DeleteSubstituteJobVariables
  >(DELETE_SUBSTITUTE_JOB_MUTATION);

  const func = useCallback(
    async (substituteJobId: OasValidateString) => {
      try {
        const result = await deleteSubstituteJob({
          variables: {
            input: { substituteJobId },
          },
          refetchQueries: [
            {
              query: GET_SUBSTITUTE_JOBS_QUERY,
            },
          ],
        });

        if (result.errors) {
          throw new OasMemoryError(JSON.stringify(result.errors), {
            title: TITLE,
          });
        }
        return result.data?.deleteSubstituteJob ?? undefined;
      } catch (e) {
        if (e instanceof OasError) {
          throw e;
        } else {
          throw OasMemoryError.fromError(e, {
            title: TITLE,
          });
        }
      }
    },
    [deleteSubstituteJob],
  );

  return {
    deleteSubstituteJob: func,
  };
};
