import { useCallback } from 'react';

import { OasMemoryError, OasError } from 'core/atoms/errors';

import { gql, useMutation } from 'core/dna/types/apollo';
import { DeleteSubstituteJobInvites } from 'core/dna/types/generated/_globalTypes';
import {
  DeleteSubstituteInvites,
  DeleteSubstituteInvitesVariables,
} from 'core/dna/types/generated/DeleteSubstituteInvites';

import { GET_SUBSTITUTE_JOBS_QUERY } from 'modules/substitute/memory/apollo/substitute-jobs/remote';

export const DELETE_SUBSTITUTE_INVITES_MUTATION = gql`
  mutation DeleteSubstituteInvites($input: DeleteSubstituteJobInvites!) {
    deleteSubstituteJobInvites(input: $input) {
      id
      invitations {
        inviteId
        teacher {
          firstName
          lastName
        }
      }
    }
  }
`;

const TITLE = 'use-delete-substitute-invites';

export const useDeleteSubstituteInvites = () => {
  const [deleteSubstituteInvites] = useMutation<
    DeleteSubstituteInvites,
    DeleteSubstituteInvitesVariables
  >(DELETE_SUBSTITUTE_INVITES_MUTATION);

  const func = useCallback(
    async (input: DeleteSubstituteJobInvites) => {
      try {
        const result = await deleteSubstituteInvites({
          variables: {
            input,
          },
          refetchQueries: [
            {
              query: GET_SUBSTITUTE_JOBS_QUERY,
            },
          ],
        });

        if (result.errors) {
          throw new OasMemoryError(JSON.stringify(result.errors), {
            title: TITLE,
          });
        }
        return result.data?.deleteSubstituteJobInvites ?? undefined;
      } catch (e) {
        if (e instanceof OasError) {
          throw e;
        } else {
          throw OasMemoryError.fromError(e, {
            title: TITLE,
          });
        }
      }
    },
    [deleteSubstituteInvites],
  );

  return {
    deleteSubstituteInvites: func,
  };
};
