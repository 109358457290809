import { useCallback } from 'react';

import { FlexBox } from 'core/cells/box';
import { Form, Field, FormController } from 'core/cells/forms';
import { FormTextField } from 'core/cells/form-text-field';
import { MenuItem } from 'core/cells/menu-item';

import { useParams } from 'core/dna/routing';

import { useTranslations } from 'core/dna/translations';

import { Button } from 'templates/mdp/components/custom-buttons';

import { WriteModeEnum } from 'core/dna/types/write-mode';

import { WriteTableModal } from 'core/organisms/write-modal';
import { useTeacherTableWrite } from 'modules/grades/metabolism';

import { SubjectCodes } from 'modules/grades/subject-codes';

export interface InitialEvaluationGroup {
  id: string;
  name: string;
  subjectCode: string;
}

export const initialEvaluationGroup = {
  id: '',
  name: '',
  subjectCode: '',
};

interface Values {
  name: string;
  subjectCode: string;
}

interface EvaluationGroupCreatorProps {
  writeMode: WriteModeEnum;
  data: InitialEvaluationGroup;
  open: boolean;
  setOpen: (open: boolean) => void;
  // onSubmit: (id: string) => void;
}

export const EvaluationGroupCreator = (props: EvaluationGroupCreatorProps) => {
  const { open, setOpen, data, writeMode } = props;
  const { id: batchId } = useParams<{ id: string }>();
  const { onRowAdd, onRowUpdate } = useTeacherTableWrite();
  const { texts } = useTranslations();

  const onClose = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  const handleSubmit = async (values: Values) => {
    if (writeMode === WriteModeEnum.update) {
      await onRowUpdate({ ...values, evaluationGroupId: data.id }, batchId);
    } else {
      await onRowAdd({ ...values, evaluationBatchId: data.id }, batchId);
    }
    setOpen(false);
  };

  return (
    <WriteTableModal
      width={420}
      isShow={open ?? false}
      onClose={onClose}
      writeMode={writeMode}
      title={
        writeMode === WriteModeEnum.create
          ? texts.grades.prepareBatch.newRequest
          : texts.planner.common.edit
      }
    >
      <FormController
        initialValues={{
          name: data.name,
          subjectCode: data.subjectCode,
        }}
        validate={(values) => {
          const errors: Partial<Values> = {};
          if (!values.name) {
            errors.name = texts.oasCommon.validation.required;
          }
          if (!values.subjectCode) {
            errors.subjectCode = texts.oasCommon.validation.required;
          }
          return errors;
        }}
        onSubmit={async (values) => {
          await handleSubmit(values);
        }}
      >
        {({ submitForm, isSubmitting }) => (
          <Form>
            <Field
              select
              component={FormTextField}
              name="subjectCode"
              type="text"
              label={texts.grades.gradesCommon.subjectCode}
              disabled={isSubmitting}
              fullWidth
            >
              {SubjectCodes.map((option: string) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </Field>
            <Field
              component={FormTextField}
              name="name"
              type="text"
              label={texts.planner.common.names.one}
              disabled={isSubmitting}
              fullWidth
            />
            <FlexBox justifyContent="flex-end">
              <Button
                color="secondary"
                disabled={isSubmitting}
                onClick={submitForm}
              >
                {writeMode === WriteModeEnum.create
                  ? texts.planner.common.add
                  : texts.planner.common.edit}
              </Button>
              <Button onClick={onClose} color="secondary" simple>
                {texts.planner.common.close}
              </Button>
            </FlexBox>
          </Form>
        )}
      </FormController>
    </WriteTableModal>
  );
};
