import { gql } from 'core/dna/types/apollo';

export const STUDENT_EVALUATION_PERSON_DETAIL_FRAGMENT = gql`
  fragment StudentEvaluationPersonDetail on StudentEvaluationPersonDetail {
    firstName
    lastName
    tuid
  }
`;

export const STUDENT_EVALUATION_PERSON_FRAGMENT = gql`
  fragment StudentEvaluationPerson on StudentEvaluationPerson {
    archiveId
    personDetails {
      ...StudentEvaluationPersonDetail
    }
  }
  ${STUDENT_EVALUATION_PERSON_DETAIL_FRAGMENT}
`;

export const STUDENT_EVALUATION_FRAGMENT = gql`
  fragment StudentEvaluation on StudentEvaluation {
    code
    createdAt {
      formatted
    }
    evaluation
    note
    updatedAt {
      formatted
    }
    student {
      ...StudentEvaluationPerson
    }
    evaluatedBy {
      ...StudentEvaluationPerson
    }
  }
  ${STUDENT_EVALUATION_PERSON_FRAGMENT}
`;

export const EVALUATION_GROUP_FRAGMENT = gql`
  fragment EvaluationGroup on EvaluationGroup {
    createdAt {
      formatted
    }
    id
    name
    subjectCode
    updatedAt {
      formatted
    }
    evaluations {
      ...StudentEvaluation
    }
    evaluationBatch {
      id
      state
      term
      type
      deadlineAt {
        formatted
      }
      createdAt {
        formatted
      }
      updatedAt {
        formatted
      }
    }
  }
  ${STUDENT_EVALUATION_FRAGMENT}
`;

export const EVALUATION_GROUP_ACCESS_FRAGMENT = gql`
  fragment EvaluationGroupAccess on EvaluationGroupAccess {
    evaluator
    evaluationGroup {
      ...EvaluationGroup
    }
    teacher {
      archiveId
      personDetails {
        ...StudentEvaluationPersonDetail
      }
    }
    createdAt {
      formatted
    }
    updatedAt {
      formatted
    }
  }
  ${EVALUATION_GROUP_FRAGMENT}
  ${STUDENT_EVALUATION_PERSON_DETAIL_FRAGMENT}
`;

export const EVALUATION_BATCH_FRAGMENT = gql`
  fragment EvaluationBatch on EvaluationBatch {
    createdAt {
      formatted
    }
    deadlineAt {
      formatted
    }
    updatedAt {
      formatted
    }
    evaluationGroups {
      ...EvaluationGroup
    }
    id
    state
    term
    type
  }
  ${EVALUATION_GROUP_FRAGMENT}
`;
