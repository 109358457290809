import React, { ReactNode } from 'react';
import clsx from 'clsx';

import { useStyles } from './badge.styles';

type BadgeColor =
  | 'primary'
  | 'warning'
  | 'danger'
  | 'success'
  | 'info'
  | 'rose'
  | 'gray';

export interface BadgeProps {
  color?: BadgeColor; // PropTypes.oneOf([]),
  children?: ReactNode; // PropTypes.node,
}

export const Badge = (props: BadgeProps) => {
  const { color, children } = props;
  const classes = useStyles();
  return (
    <span className={clsx(classes.badge, color ? classes[color] : '')}>
      {children}
    </span>
  );
};
