import { TextValue } from 'core/atoms/types';

import {
  SourceDataIcon,
  PreparationIcon,
  AllocationIcon,
} from 'core/cells/oas-icons';

import { OrganizationLevel, RouteConfig } from 'core/dna/types/local';
import { OasLanguage } from 'core/dna/routing';
import { OasLocales } from 'core/dna/translations/types';

import { ClassSelector } from 'modules/planner/organisms/class-selector';
import { RoomSelector } from 'modules/planner/organisms/room-selector';
import { TeacherSelector } from 'modules/planner/organisms/teacher-selector';
import { ScheduleSelector } from 'modules/planner/organisms/schedule-selector';
import {
  PageSelector,
  PageSelectorProps,
} from 'modules/planner/organisms/page-selector';
import { PageLink } from 'modules/planner/organisms/page-link';

import { Share } from 'modules/planner/pages/share';
import { Error } from 'modules/planner/pages/error';
import { Welcome } from 'modules/planner/pages/welcome';
import { Organization } from 'modules/planner/pages/source-data/organization';
import { YearCalendar } from 'modules/planner/pages/source-data/year-calendar';
import { Classes } from 'modules/planner/pages/source-data/classes/pages/index';
import { ClassesAvailability } from 'modules/planner/pages/source-data/classes/pages/availability';
import { ClassesGroups } from 'modules/planner/pages/source-data/classes/pages/groups';
import { ClassesStudents } from 'modules/planner/pages/source-data/classes/pages/students';
import { StudentsGroups } from 'modules/planner/pages/source-data/classes/pages/students-groups';
import { Rooms } from 'modules/planner/pages/source-data/rooms/index';
import { RoomsAvailability } from 'modules/planner/pages/source-data/rooms/availability';
import { Subjects } from 'modules/planner/pages/source-data/subjects';
import { Teachers } from 'modules/planner/pages/source-data/teachers/index';
import { TeachersAvailability } from 'modules/planner/pages/source-data/teachers/availability';
import { Schedules } from 'modules/planner/pages/plan/schedules/pages/index';
import { LessonGroups } from 'modules/planner/pages/plan/schedules/pages/lesson-groups/pages/index';
import { LessonGroupsCreate } from 'modules/planner/pages/plan/schedules/pages/lesson-groups/pages/create';
import { LessonGroupsEdit } from 'modules/planner/pages/plan/schedules/pages/lesson-groups/pages/edit';
import { TimePlan } from 'modules/planner/pages/plan/schedules/pages/time-plan';
import { Test } from 'modules/planner/pages/test';
import { TeachingTimeReport } from 'modules/planner/pages/plan/schedules/pages/teaching-time-report';
import { Weeks } from 'modules/planner/pages/allocation/weeks/index';
import { YearPlan } from 'modules/planner/pages/allocation/year-plan/index';

import PlannerPaths from 'modules/planner/routing/paths';
import PlannerUrls from 'modules/planner/routing/urls';

const classesId = PlannerPaths.SourceData.Classes.path;
const classesAvailabilityId = PlannerPaths.SourceData.Classes.Availability.path;
const classesGroupsId = PlannerPaths.SourceData.Classes.Groups.path;
const classesStudentsId = PlannerPaths.SourceData.Classes.Students.path;
const classesStudentsGroupsId =
  PlannerPaths.SourceData.Classes.StudentsGroups.path;
const roomsId = PlannerPaths.SourceData.Rooms.path;
const roomsAvailabilityId = PlannerPaths.SourceData.Rooms.Availability.path;
const teachersId = PlannerPaths.SourceData.Teachers.path;
const teachersAvailabilityId =
  PlannerPaths.SourceData.Teachers.Availability.path;
const schedulesId = PlannerPaths.Plan.Schedules.path;
const lessonGroupsId = PlannerPaths.Plan.Schedules.LessonGroups.path;
const timePlanId = PlannerPaths.Plan.Schedules.TimePlan.path;
const teachingTimeReportId =
  PlannerPaths.Plan.Schedules.TeachingTimeReport.path;
const weeksId = PlannerPaths.Allocation.Weeks.path;
const yearPlanId = PlannerPaths.Allocation.YearPlan.path;

export const getRoutes = (
  locale: OasLanguage,
  texts: OasLocales,
): RouteConfig[] => {
  const classesHomePage: TextValue = {
    text: texts.planner.common.classes,
    value: PlannerUrls.classes(locale),
  };
  const classesPages: TextValue[] = [
    {
      text: texts.planner.common.availabilityCalendar,
      value: PlannerUrls.classesAvailability(locale),
    },
    {
      text: texts.planner.common.groups.many,
      value: PlannerUrls.classesGroups(locale),
    },
    {
      text: texts.oasCommon.students.many,
      value: PlannerUrls.classesStudents(locale),
    },
  ];
  const roomsHomePage: TextValue = {
    text: texts.planner.common.rooms,
    value: PlannerUrls.rooms(locale),
  };
  const roomsPages: TextValue[] = [
    {
      text: texts.planner.common.availabilityCalendar,
      value: PlannerUrls.roomsAvailability(locale),
    },
  ];
  const teachersHomePage: TextValue = {
    text: texts.planner.common.teachers,
    value: PlannerUrls.teachers(locale),
  };
  const teachersPages: TextValue[] = [
    {
      text: texts.planner.common.availabilityCalendar,
      value: PlannerUrls.teachersAvailability(locale),
    },
  ];
  const schedulesHomePage: TextValue = {
    text: texts.planner.common.schedules.many,
    value: PlannerUrls.schedules(locale),
  };
  const schedulesPages: TextValue[] = [
    {
      text: texts.planner.preparationSpace.schedulesModule.lessonGroups.many,
      value: PlannerUrls.lessonGroups({ locale }),
    },
    {
      text: texts.planner.preparationSpace.schedulesModule.timePlanning,
      value: PlannerUrls.timePlan({ locale }),
    },
    {
      text: texts.oasCommon.teachingTimeReport,
      value: PlannerUrls.teachingTimeReport({ locale }),
    },
  ];
  return [
    {
      id: `planner-${PlannerPaths.Share.path}`,
      path: [
        `${PlannerPaths.Share.path}/:resourceType/:resourceId`,
        `${PlannerPaths.Share.path}/:resourceType`,
        PlannerPaths.Share.path,
      ],
      component: Share,
      level: OrganizationLevel.Organization,
    },
    {
      id: `planner-${PlannerPaths.Error.path}`,
      exact: true,
      path: PlannerPaths.Error.path,
      component: Error,
      level: OrganizationLevel.Organization,
    },
    {
      id: `planner-${PlannerPaths.Test.path}`,
      exact: true,
      path: PlannerPaths.Test.path,
      component: Test,
      level: OrganizationLevel.Organization,
    },
    {
      id: `planner-${PlannerPaths.Welcome.path}`,
      name: texts.planner.common.welcome,
      // mini: 'WE',
      private: true,
      path: PlannerPaths.Welcome.path,
      component: Welcome,
      level: OrganizationLevel.Organization,
    },
    {
      id: 'planner-sourceDataCollapse',
      sidebar: true,
      collapse: true,
      name: texts.planner.common.sourceData,
      rtlName: texts.planner.common.sourceData,
      icon: SourceDataIcon,
      state: 'sourceDataCollapse',
      views: [
        {
          id: `planner-${PlannerPaths.SourceData.Organization.path}`,
          sidebar: true,
          exact: true,
          private: true,
          path: PlannerPaths.SourceData.Organization.path,
          name:
            texts.planner.sourceDataSpace.organizationModule.openingHours.title,
          rtlName:
            texts.planner.sourceDataSpace.organizationModule.openingHours.title,
          mini: texts.planner.sourceDataSpace.organizationModule.openingHours.title
            .toUpperCase()
            .slice(0, 2),
          rtlMini: texts.planner.sourceDataSpace.organizationModule.openingHours.title
            .toUpperCase()
            .slice(0, 2),
          component: Organization,
          level: OrganizationLevel.Organization,
        },
        {
          id: `planner-${PlannerPaths.SourceData.YearCalendar.path}`,
          sidebar: true,
          exact: true,
          private: true,
          path: PlannerPaths.SourceData.YearCalendar.path,
          name: texts.oasCommon.holidays.many,
          rtlName: texts.oasCommon.holidays.many,
          mini: texts.oasCommon.holidays.many.toUpperCase().slice(0, 2),
          rtlMini: texts.oasCommon.holidays.many.toUpperCase().slice(0, 2),
          component: YearCalendar,
          level: OrganizationLevel.Organization,
        },
        {
          id: classesId,
          sidebar: true,
          exact: true,
          private: true,
          path: PlannerPaths.SourceData.Classes.path,
          name: texts.planner.common.classes,
          rtlName: texts.planner.common.classes,
          mini: texts.planner.common.classes.toUpperCase().slice(0, 2),
          rtlMini: texts.planner.common.classes.toUpperCase().slice(0, 2),
          component: Classes,
          level: OrganizationLevel.Organization,
          views: [
            {
              id: classesAvailabilityId,
              parentId: classesId,
              breadcrumbs: [
                { component: PageLink, props: classesHomePage },
                { component: ClassSelector },
                {
                  component: PageSelector,
                  props: {
                    data: classesPages,
                    value: PlannerUrls.classesAvailability(locale),
                  } as PageSelectorProps,
                },
              ],
              private: true,
              path: PlannerPaths.SourceData.Classes.Availability.path,
              component: ClassesAvailability,
              level: OrganizationLevel.Organization,
            },
            {
              id: classesGroupsId,
              parentId: classesId,
              breadcrumbs: [
                { component: PageLink, props: classesHomePage },
                { component: ClassSelector },
                {
                  component: PageSelector,
                  props: {
                    data: classesPages,
                    value: PlannerUrls.classesGroups(locale),
                  } as PageSelectorProps,
                },
              ],
              private: true,
              path: PlannerPaths.SourceData.Classes.Groups.path,
              component: ClassesGroups,
              level: OrganizationLevel.Organization,
            },
            {
              id: classesStudentsId,
              parentId: classesId,
              breadcrumbs: [
                { component: PageLink, props: classesHomePage },
                { component: ClassSelector },
                {
                  component: PageSelector,
                  props: {
                    data: classesPages,
                    value: PlannerUrls.classesStudents(locale),
                  } as PageSelectorProps,
                },
              ],
              private: true,
              path: PlannerPaths.SourceData.Classes.Students.path,
              component: ClassesStudents,
              level: OrganizationLevel.Organization,
            },
            {
              id: classesStudentsGroupsId,
              parentId: classesId,
              private: true,
              path: PlannerPaths.SourceData.Classes.StudentsGroups.path,
              component: StudentsGroups,
              level: OrganizationLevel.Organization,
            },
          ],
        },
        {
          id: roomsId,
          sidebar: true,
          exact: true,
          private: true,
          path: PlannerPaths.SourceData.Rooms.path,
          name: texts.planner.common.rooms,
          rtlName: texts.planner.common.rooms,
          mini: texts.planner.common.rooms.toUpperCase().slice(0, 2),
          rtlMini: texts.planner.common.rooms.toUpperCase().slice(0, 2),
          component: Rooms,
          level: OrganizationLevel.Organization,
          views: [
            {
              id: roomsAvailabilityId,
              parentId: roomsId,
              breadcrumbs: [
                { component: PageLink, props: roomsHomePage },
                { component: RoomSelector },
                {
                  component: PageSelector,
                  props: {
                    data: roomsPages,
                    value: PlannerUrls.roomsAvailability(locale),
                  } as PageSelectorProps,
                },
              ],
              private: true,
              path: PlannerPaths.SourceData.Rooms.Availability.path,
              component: RoomsAvailability,
              level: OrganizationLevel.Organization,
            },
          ],
        },
        {
          id: `planner-${PlannerPaths.SourceData.Subjects.path}`,
          sidebar: true,
          exact: true,
          private: true,
          path: PlannerPaths.SourceData.Subjects.path,
          name: texts.planner.common.subjects,
          rtlName: texts.planner.common.subjects,
          mini: texts.planner.common.subjects.toUpperCase().slice(0, 2),
          rtlMini: texts.planner.common.subjects.toUpperCase().slice(0, 2),
          component: Subjects,
          level: OrganizationLevel.Organization,
        },
        {
          id: teachersId,
          sidebar: true,
          exact: true,
          private: true,
          path: PlannerPaths.SourceData.Teachers.path,
          name: texts.planner.common.teachers,
          rtlName: texts.planner.common.teachers,
          mini: texts.planner.common.teachers.toUpperCase().slice(0, 2),
          rtlMini: texts.planner.common.teachers.toUpperCase().slice(0, 2),
          component: Teachers,
          level: OrganizationLevel.Organization,
          views: [
            {
              id: teachersAvailabilityId,
              parentId: teachersId,
              breadcrumbs: [
                { component: PageLink, props: teachersHomePage },
                { component: TeacherSelector },
                {
                  component: PageSelector,
                  props: {
                    data: teachersPages,
                    value: PlannerUrls.teachersAvailability(locale),
                  } as PageSelectorProps,
                },
              ],
              private: true,
              path: PlannerPaths.SourceData.Teachers.Availability.path,
              component: TeachersAvailability,
              level: OrganizationLevel.Organization,
            },
          ],
        },
      ],
    },
    {
      id: 'planner-preparationCollapse',
      sidebar: true,
      collapse: true,
      name: texts.planner.common.preparation,
      rtlName: texts.planner.common.preparation,
      icon: PreparationIcon,
      state: 'preparationCollapse',
      views: [
        {
          id: schedulesId,
          sidebar: true,
          exact: true,
          private: true,
          path: PlannerPaths.Plan.Schedules.path,
          url: PlannerUrls.schedules(locale),
          name: texts.planner.common.schedules.many,
          rtlName: texts.planner.common.schedules.many,
          mini: texts.planner.common.schedules.many.toUpperCase().slice(0, 2),
          rtlMini: texts.planner.common.schedules.many
            .toUpperCase()
            .slice(0, 2),
          component: Schedules,
          level: OrganizationLevel.Organization,
          views: [
            {
              id: lessonGroupsId,
              parentId: schedulesId,
              breadcrumbs: [
                { component: PageLink, props: schedulesHomePage },
                { component: ScheduleSelector },
                {
                  component: PageSelector,
                  props: {
                    data: schedulesPages,
                    value: PlannerUrls.lessonGroups({ locale }),
                  } as PageSelectorProps,
                },
              ],
              exact: true,
              private: true,
              path: PlannerPaths.Plan.Schedules.LessonGroups.path,
              url: PlannerUrls.lessonGroups({ locale }),
              component: LessonGroups,
              level: OrganizationLevel.Organization,
              views: [
                {
                  id: `planner-${PlannerPaths.Plan.Schedules.LessonGroups.Create.path}`,
                  parentId: lessonGroupsId,
                  private: true,
                  path: PlannerPaths.Plan.Schedules.LessonGroups.Create.path,
                  component: LessonGroupsCreate,
                  level: OrganizationLevel.Organization,
                },
                {
                  id: `planner-${PlannerPaths.Plan.Schedules.LessonGroups.Edit.path}`,
                  parentId: lessonGroupsId,
                  private: true,
                  path: PlannerPaths.Plan.Schedules.LessonGroups.Edit.path,
                  component: LessonGroupsEdit,
                  level: OrganizationLevel.Organization,
                },
              ],
            },
            {
              id: timePlanId,
              parentId: schedulesId,
              breadcrumbs: [
                { component: PageLink, props: schedulesHomePage },
                { component: ScheduleSelector },
                {
                  component: PageSelector,
                  props: {
                    data: schedulesPages,
                    value: PlannerUrls.timePlan({ locale }),
                  } as PageSelectorProps,
                },
              ],
              private: true,
              path: PlannerPaths.Plan.Schedules.TimePlan.path,
              url: PlannerUrls.timePlan({ locale }),
              component: TimePlan,
              level: OrganizationLevel.Organization,
            },
            {
              id: teachingTimeReportId,
              parentId: schedulesId,
              breadcrumbs: [
                { component: PageLink, props: schedulesHomePage },
                { component: ScheduleSelector },
                {
                  component: PageSelector,
                  props: {
                    data: schedulesPages,
                    value: PlannerUrls.teachingTimeReport({ locale }),
                  } as PageSelectorProps,
                },
              ],
              private: true,
              path: PlannerPaths.Plan.Schedules.TeachingTimeReport.path,
              url: PlannerUrls.teachingTimeReport({ locale }),
              component: TeachingTimeReport,
              level: OrganizationLevel.Organization,
            },
          ],
        },
      ],
    },
    {
      id: 'planner-allocationCollapse',
      sidebar: true,
      collapse: true,
      name: texts.planner.allocationStep.allocation,
      rtlName: texts.planner.allocationStep.allocation,
      icon: AllocationIcon,
      state: 'allocationCollapse',
      views: [
        {
          id: weeksId,
          sidebar: true,
          exact: true,
          private: true,
          path: PlannerPaths.Allocation.Weeks.path,
          url: PlannerUrls.weeks(locale),
          name: texts.oasCommon.weeks.many,
          rtlName: texts.oasCommon.weeks.many,
          mini: texts.oasCommon.weeks.many.toUpperCase().slice(0, 2),
          rtlMini: texts.oasCommon.weeks.many.toUpperCase().slice(0, 2),
          component: Weeks,
          level: OrganizationLevel.Organization,
        },
        {
          id: yearPlanId,
          sidebar: true,
          exact: true,
          private: true,
          path: PlannerPaths.Allocation.YearPlan.path,
          url: PlannerUrls.yearPlan(locale),
          name: texts.planner.allocationStep.yearPlan,
          rtlName: texts.planner.allocationStep.yearPlan,
          mini: texts.planner.allocationStep.yearPlan.toUpperCase().slice(0, 2),
          rtlMini: texts.planner.allocationStep.yearPlan
            .toUpperCase()
            .slice(0, 2),
          component: YearPlan,
          level: OrganizationLevel.Organization,
        },
      ],
    },
  ];
};
