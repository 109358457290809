import { PropTypeColor } from 'core/atoms/styles';
import { mdStyled } from 'core/atoms/themes';

import { Avatar } from 'core/cells/avatar';
import { Chip } from 'core/cells/chip';

import { DeleteLessonPropertyFn, LessonProperty } from '../../../../../types';

export interface LessonPropertyListProps {
  data?: LessonProperty[];
  onDelete?: DeleteLessonPropertyFn;
  color?: Exclude<PropTypeColor, 'inherit'>;
  withAvatars?: boolean;
}

const ListRoot = mdStyled('div')({
  display: 'flex',
  flexWrap: 'wrap',
});

export const LessonPropertyList = (props: LessonPropertyListProps) => {
  const { data, onDelete, color, withAvatars } = props;

  return (
    <ListRoot>
      {data?.map((lp) => (
        <Chip
          key={lp.id}
          size="small"
          label={lp.text}
          color={color}
          sx={{ '&.MuiButtonBase-root': { margin: 0.5 } }}
          onDelete={() => onDelete?.(lp)}
          avatar={withAvatars ? <Avatar>{lp.data}</Avatar> : undefined}
        />
      ))}
    </ListRoot>
  );
};
