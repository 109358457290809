import { PersonDetailsT2_groups as ApiGroups } from 'core/dna/types/generated/PersonDetailsT2';
import { mapNullableListToList } from 'core/dna/functions/map-nullable-list-to-list';
import { PersonDetailsT2Group } from 'modules/student/dna/types';

export const mapApiGroupToGroup = (
  apiGroups: ApiGroups | null,
): PersonDetailsT2Group => {
  if (apiGroups === null) {
    throw new TypeError('"PersonDetailsT2_groups" should not be null!');
  }

  return apiGroups;
};

export const mapApiGroupToGroupList = (
  apiGroupList: (ApiGroups | null)[] | null,
): PersonDetailsT2Group[] | undefined => {
  return mapNullableListToList<ApiGroups, PersonDetailsT2Group>(
    apiGroupList,
    mapApiGroupToGroup,
  );
};
