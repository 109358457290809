import { mapNullableListToList } from 'core/dna/functions/map-nullable-list-to-list';
import { GetEvaluationBatch_EvaluationBatch_evaluationGroups as ApiEvaluationGroup } from 'core/dna/types/generated/GetEvaluationBatch';
import { EvaluationGroup } from 'modules/grades/dna/types';
import { mapApiStudentEvaluationToStudentEvaluationList } from '../map-api-student-evaluation-to-student-evaluation';

export const mapApiEvaluationGroupToEvaluationGroup = (
  apiEvaluationGroup: ApiEvaluationGroup | null,
): EvaluationGroup => {
  if (apiEvaluationGroup === null) {
    throw new TypeError('"apiEvaluationGroup" should not be null!');
  }

  const {
    createdAt,
    updatedAt,
    id,
    name,
    subjectCode,
    evaluations,
    evaluationBatch,
  } = apiEvaluationGroup;

  return {
    id,
    name,
    subjectCode,
    createdAt: createdAt?.formatted ?? null,
    updatedAt: updatedAt?.formatted ?? null,
    evaluations: mapApiStudentEvaluationToStudentEvaluationList(evaluations),
    evaluationBatch: {
      deadlineAt: evaluationBatch?.deadlineAt?.formatted || null,
      createdAt: evaluationBatch?.createdAt?.formatted || null,
      updatedAt: evaluationBatch?.updatedAt?.formatted || null,
      state: evaluationBatch?.state || null,
      id: evaluationBatch?.id || null,
      term: evaluationBatch?.term || null,
      type: evaluationBatch?.type || null,
    },
  };
};

export const mapApiEvaluationGroupToEvaluationGroupList = (
  apiEvaluationGroupList: (ApiEvaluationGroup | null)[] | null,
): EvaluationGroup[] | undefined => {
  return mapNullableListToList<ApiEvaluationGroup, EvaluationGroup>(
    apiEvaluationGroupList,
    mapApiEvaluationGroupToEvaluationGroup,
  );
};
