import { useCallback } from 'react';
import { Link } from 'core/cells/link';
import { Typography } from 'core/cells/typography';
import { useLocale } from 'core/dna/routing';
import { useOasHistory } from 'core/metabolism/use-oas-history';
import GradesUrls from 'modules/grades/urls';
import { TeacherAssessmentTableItem } from 'modules/grades/pages/assessment/list/components/assessments-table/use-assessments-table-read';

interface DisplayCellDataProps {
  value: string;
  row: any;
  data: TeacherAssessmentTableItem[];
}

export const DisplayCellData = (props: DisplayCellDataProps) => {
  const { row, value, data } = props;
  const { push } = useOasHistory();
  const locale = useLocale();

  const handleClick = useCallback(() => {
    return push(
      GradesUrls.openRequestsForGradesUrl({
        batchId: data[row.index].batchId,
        subjectCode: data[row.index].subjectCode,
        locale,
      }),
    );
  }, [locale, push, row, data]);

  return (
    <Link onClick={() => handleClick()}>
      <Typography>{value}</Typography>
    </Link>
  );
};
