import { useState, useLayoutEffect } from 'react';

import { errorsStore } from './errors-store';

export const useErrorsState = () => {
  const [state, setState] = useState(errorsStore.initialState);

  useLayoutEffect(() => {
    const subscription = errorsStore.subscribe(setState);
    return () => {
      subscription.unsubscribe();
    };
  }, []);

  return {
    errorsState: state,
  };
};
