import React, { useState } from 'react';

import { Skeleton } from 'core/cells/skeleton';
import { useTranslations } from 'core/dna/translations';
import { useReactTableExport } from 'core/metabolism/table-state';
import { Grid } from 'core/cells/grid';

import { ShortDataWithAbsences } from 'modules/student/dna/types';

import { Button } from 'templates/mdp/components/custom-buttons';

import { GroupsAbsenceTable } from '../groups-absence-table';
import { useGroupsAbsencesTotal } from './use-groups-absences-total';
import {
  GroupsAbsencesFullTableItem,
  useGroupsAbsenceFullTableRead,
} from '../groups-absence-table/use-groups-absence-full-table-read';
import { AbsenceStateChartCreator } from '../charts/absence-state-chart-creator';

interface GroupsReportProps {
  data: ShortDataWithAbsences[] | undefined;
  loading: boolean;
}

export const GroupsReport = ({ data, loading }: GroupsReportProps) => {
  const { texts } = useTranslations();
  const { total } = useGroupsAbsencesTotal(data);

  const groupsToChart = total.map((group) => ({
    ...group.total,
    title: group.name || 'No name :(',
  }));

  const {
    columns: excelColumns,
    tableData: excelData,
  } = useGroupsAbsenceFullTableRead(total);

  const { exportToExcel } = useReactTableExport<GroupsAbsencesFullTableItem>(
    excelColumns,
    excelData,
  );

  const [open, setOpen] = useState(false);

  const handleModalOpen = () => {
    setOpen(true);
  };

  if (!total.length) {
    return <Skeleton variant="rect" width="100%" height="80vh" />;
  }

  return (
    <Grid container spacing={3}>
      <Grid
        item
        xs={12}
        style={{ display: 'flex', justifyContent: 'flex-end' }}
      >
        <Button
          color="secondary"
          onClick={handleModalOpen}
          style={{ marginRight: 15 }}
        >
          {texts.reports.reportsCommon.showChart}
        </Button>
        <Button
          color="secondary"
          onClick={() => {
            exportToExcel('Fravær elever');
          }}
        >
          {texts.reports.reportsCommon.excelExportByCategory}
        </Button>
      </Grid>
      <Grid item xs={12} lg={12}>
        <GroupsAbsenceTable data={total} loading={loading} />
      </Grid>
      <AbsenceStateChartCreator
        data={groupsToChart}
        open={open}
        setOpen={setOpen}
      />
    </Grid>
  );
};
