import { ScheduleDataStateMap } from 'modules/planner/dna/types/local';

export const mapLocalSchedulesDataStateToSchedulesDataState = (
  localSchedulesDataState: OasScheduleDataStateMap | null | undefined,
): ScheduleDataStateMap | undefined => {
  if (!localSchedulesDataState) {
    return;
  }

  const destinationMap: ScheduleDataStateMap = {};
  Object.keys(localSchedulesDataState).forEach((id) => {
    if (localSchedulesDataState[id]) {
      destinationMap[id] = { ...localSchedulesDataState[id] };
    }
  });
  return destinationMap;
};
