import { useCallback } from 'react';

import { OasMemoryError, OasError } from 'core/atoms/errors';

import { gql, useMutation } from 'core/dna/types/apollo';

import { CreateEvaluationGroupInput } from 'core/dna/types/generated/_globalTypes';
import {
  CreateEvaluationGroup,
  CreateEvaluationGroupVariables,
} from 'core/dna/types/generated/CreateEvaluationGroup';

import { EVALUATION_GROUP_FRAGMENT } from 'modules/grades/memory/apollo/_fragments';

import { GET_EVALUATION_GROUP_QUERY } from './use-get-evaluation-group';

export const CREATE_EVALUATION_GROUP_MUTATION = gql`
  mutation CreateEvaluationGroup($input: CreateEvaluationGroupInput) {
    createEvaluationGroup(input: $input) {
      ...EvaluationGroup
    }
  }
  ${EVALUATION_GROUP_FRAGMENT}
`;

const TITLE = 'use-create-evaluation-group';

export const useCreateEvaluationGroup = () => {
  const [createEvaluationGroup, { loading, error }] = useMutation<
    CreateEvaluationGroup,
    CreateEvaluationGroupVariables
  >(CREATE_EVALUATION_GROUP_MUTATION);

  const func = useCallback(
    async (input: CreateEvaluationGroupInput, batchId: string) => {
      try {
        const result = await createEvaluationGroup({
          variables: {
            input: { ...input },
          },
          refetchQueries: [
            {
              query: GET_EVALUATION_GROUP_QUERY,
              variables: { filter: { batchId } },
            },
          ],
        });

        if (result.errors) {
          throw new OasMemoryError(JSON.stringify(result.errors), {
            title: TITLE,
          });
        }
        return result.data?.createEvaluationGroup ?? undefined;
      } catch (e: any) {
        if (e instanceof OasError) {
          throw e;
        } else {
          throw OasMemoryError.fromError(e, {
            title: TITLE,
          });
        }
      }
    },
    [createEvaluationGroup],
  );

  return {
    createEvaluationGroup: func,
    loading,
    error,
  };
};
