import { TextItem } from 'core/atoms/types';

import { Lesson } from 'modules/planner/dna/types/lesson';
import { SubjectData } from 'modules/planner/dna/types/subject';
import { LessonGroup } from 'modules/planner/dna/types/lesson-group';

const getStringsFromLessonTextItems = (
  lessons: Lesson[] | undefined,
  mapper: (l: Lesson) => TextItem[] | undefined,
): string[] =>
  lessons?.map(mapper).reduce((acc: any[], cur: TextItem[] | undefined) => {
    return [...acc, ...(cur?.map((value) => value.text) ?? [])];
  }, []) ?? [];

export const getLectureTitleFromLessonGroup = (lessonGroup: LessonGroup) => {
  const subjects =
    lessonGroup.lessons
      ?.map((l) => l.subjects)
      .reduce((acc: any[], cur: SubjectData[] | undefined) => {
        return [...acc, ...(cur?.map((value) => value.name) ?? [])];
      }, []) ?? [];
  return [...new Set(subjects)].join(', ');
};

export const getClassesTitleFromLessonGroup = (lessonGroup: LessonGroup) => {
  const classes = getStringsFromLessonTextItems(
    lessonGroup.lessons,
    (l) => l.classes,
  );
  return [...new Set(classes)].sort().join(', ');
};

export const getTeachersTitleFromLessonGroup = (lessonGroup: LessonGroup) => {
  const teachers = getStringsFromLessonTextItems(
    lessonGroup.lessons,
    (l) => l.teachers,
  );
  return [...new Set(teachers)].join(', ');
};

export const getRoomsTitleFromLessonGroup = (lessonGroup: LessonGroup) => {
  const rooms = getStringsFromLessonTextItems(
    lessonGroup.lessons,
    (l) => l.rooms,
  );
  return [...new Set(rooms)].join(', ');
};
