import { useCallback } from 'react';

import { Grid } from 'core/cells/grid';
import { Drawer } from 'core/cells/drawer';
import { CircularProgress } from 'core/cells/circular-progress';
import { useSnackbar } from 'core/cells/notistack';

import { SyncPeopleList } from './components/sync-people-list';
import { TeachersTable } from './components/teachers-table';
import { useTeachersState, teachersStore } from './store';

export const Teachers = () => {
  const { enqueueSnackbar } = useSnackbar();

  const {
    teachersState: { importListVisible, importing },
  } = useTeachersState();

  const handleImportSuccess = useCallback(
    (count: number) => {
      enqueueSnackbar(`*${count} teacher(s) w(as/ere) exported successfully!`, {
        variant: 'success',
      });
    },
    [enqueueSnackbar],
  );

  const handleImportError = useCallback(() => {
    enqueueSnackbar(`Error importing teachers`, {
      variant: 'error',
    });
  }, [enqueueSnackbar]);

  return (
    <Grid container>
      <Grid item xs={12}>
        <Drawer
          anchor="left"
          open={importListVisible}
          onClose={teachersStore.hideImportList}
        >
          <SyncPeopleList
            onImportSuccess={handleImportSuccess}
            onImportError={handleImportError}
          />
        </Drawer>
      </Grid>
      <Grid item xs={12}>
        {importing && <CircularProgress size={100} />}
        <TeachersTable onImportTeachersClick={teachersStore.showImportList} />
      </Grid>
    </Grid>
  );
};
