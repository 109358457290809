import React, { ReactNode } from 'react';
import clsx from 'clsx';

import { useStyles } from './typography.styles';

export interface PrimaryProps {
  children?: ReactNode; // PropTypes.node,
}

export const Primary = (props: PrimaryProps) => {
  const classes = useStyles();
  const { children } = props;
  return (
    <div className={clsx(classes.defaultFontStyle, classes.primaryText)}>
      {children}
    </div>
  );
};
