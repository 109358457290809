import { useMemo } from 'react';

import { useTranslations } from 'core/dna/translations';
import { useParams } from 'core/dna/routing';

import { useGetEvaluationGroup } from 'modules/grades/memory/apollo/evaluation/remote';
import { StudentEvaluation } from 'modules/grades/dna/types';

export interface StudentsTableItem extends StudentEvaluation {
  firstName: string;
  lastName: string;
}

export const useStudentsTableRead = () => {
  const { texts } = useTranslations();
  const { id: evaluationGroupId } = useParams<{ id: string }>();
  const { loading, data } = useGetEvaluationGroup();

  const columns = useMemo(() => {
    return [
      {
        Header: texts.planner.common.firstName,
        accessor: 'firstName',
      },
      {
        Header: texts.planner.common.lastName,
        accessor: 'lastName',
      },
      {
        Header: texts.planner.common.actions.many,
        accessor: 'actions',
        disableFilters: true,
      },
    ];
  }, [texts]);

  const preparedData = useMemo<StudentsTableItem[]>(() => {
    // TODO: update EvaluationGroupInput for fetching EvaluationGroup by ID! (TRA-1481). Linked to useCreateStudentEvaluation
    const evaluationGroup = data?.find((evg) => evg.id === evaluationGroupId);

    if (!evaluationGroup || !evaluationGroup.evaluations?.length) {
      return [];
    }

    return evaluationGroup.evaluations.map((evaluation) => ({
      ...evaluation,
      firstName: evaluation.student?.personDetails?.firstName || '',
      lastName: evaluation.student?.personDetails?.lastName || '',
    }));
  }, [data, evaluationGroupId]);

  return {
    title: texts.grades.evaluationGroups.requestsForStudents,
    columns,
    data: preparedData,
    loading,
  };
};
