import { createContext } from 'react';

import { OrganizationState } from 'core/dna/types/local';

export const SelectedOrganizationStateContext = createContext<OrganizationState | null>(
  null,
);

SelectedOrganizationStateContext.displayName =
  'SelectedOrganizationState Context';
