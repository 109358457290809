import { useCallback } from 'react';

import { selectedTeachersStateVar } from 'modules/planner/memory/apollo/cache';

export const useSetSelectedTeachersState = () => {
  const func = useCallback((values: string[]) => {
    try {
      selectedTeachersStateVar([...values]);
      return true;
    } catch (e: any) {
      throw new Error(e);
    }
  }, []);

  return {
    setSelectedTeachersState: func,
  };
};
