import { useCallback } from 'react';

import { OasMemoryError, OasError } from 'core/atoms/errors';

import { gql, useMutation } from 'core/dna/types/apollo';

import { ConfigurationType } from 'core/dna/types/generated/_globalTypes';
import {
  UpsertConfiguration,
  UpsertConfigurationVariables,
} from 'core/dna/types/generated/UpsertConfiguration';
import { ConfigurationDataType } from 'modules/student/dna/types';
import { EXECUTION_STATUS } from 'modules/substitute/memory/apollo/_fragments/remote';
import { GET_STUDENT_DETAILS_QUERY } from '../get-student-details/gql';

export const UPSERT_CONFIGURATION_MUTATION = gql`
  mutation UpsertConfiguration($input: UpsertConfigurationInput!) {
    upsertConfiguration(input: $input) {
      ...ExecutionStatus
    }
  }
  ${EXECUTION_STATUS}
`;

const TITLE = 'use-upsert-configuration';

export const useUpsertConfiguration = () => {
  const [upsertConfiguration, { loading, error }] = useMutation<
    UpsertConfiguration,
    UpsertConfigurationVariables
  >(UPSERT_CONFIGURATION_MUTATION);

  const func = useCallback(
    async (
      regardingTuid: OasValidateString,
      type: ConfigurationType,
      data: ConfigurationDataType,
    ) => {
      try {
        const result = await upsertConfiguration({
          variables: {
            input: {
              regardingTuid,
              type,
              data,
            },
          },
          refetchQueries: [
            {
              query: GET_STUDENT_DETAILS_QUERY,
              variables: { tuid: regardingTuid },
            },
          ],
        });

        if (result.errors) {
          throw new OasMemoryError(JSON.stringify(result.errors), {
            title: TITLE,
          });
        }
        return result.data?.upsertConfiguration ?? undefined;
      } catch (e) {
        if (e instanceof OasError) {
          throw e;
        } else {
          throw OasMemoryError.fromError(e, {
            title: TITLE,
          });
        }
      }
    },
    [upsertConfiguration],
  );

  return {
    upsertConfiguration: func,
    loading,
    error,
  };
};
