import {
  makeStyles,
  createStyles,
  Theme,
  CSSProperties,
} from 'core/atoms/styles';

import { defaultFont, grayColor } from '../../material-dashboard-pro-react';
import { modalStyle, ModalStyle } from '../../modal-style';

export interface NotificationsStyle extends ModalStyle {
  cardTitle: CSSProperties;
  cardHeader: CSSProperties;
  cardSubtitle: CSSProperties;
  center: CSSProperties;
  right: CSSProperties;
  left: CSSProperties;
  marginRight: CSSProperties;
  modalSectionTitle: CSSProperties;
}

export const notificationsStyle = (theme: Theme): NotificationsStyle => ({
  cardTitle: {
    marginTop: '0',
    marginBottom: '3px',
    color: grayColor[2],
    fontSize: '18px',
  },
  cardHeader: {
    zIndex: 3,
  },
  cardSubtitle: {
    ...defaultFont,
    color: grayColor[0],
    fontSize: '14px',
    margin: '0 0 10px',
  },
  center: {
    textAlign: 'center',
  },
  right: {
    textAlign: 'right',
  },
  left: {
    textAlign: 'left',
  },
  marginRight: {
    marginRight: '5px',
  },
  modalSectionTitle: {
    marginTop: '30px',
  },
  ...modalStyle(theme),
});

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({ ...notificationsStyle(theme) }),
);
