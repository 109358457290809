import { useCallback } from 'react';

import { parseTemplate } from 'core/atoms/functions/string';

import { Form, Field, FormController } from 'core/cells/forms';
import { FormTextField } from 'core/cells/form-text-field';
import { FlexBox } from 'core/cells/box';

import { useTranslations } from 'core/dna/translations';

import { Button } from 'templates/mdp/components/custom-buttons';

import { WriteModeEnum } from 'core/dna/types/write-mode';
import { WriteTableModal } from 'core/organisms/write-modal';

import { useRoomsTableWrite } from './use-rooms-table-write';

interface Values {
  name: string;
  alias?: string;
  note?: string;
}

interface RoomFormProps {
  defaultName: string;
  defaultAlias?: string;
  defaultNote?: string;
  id?: string;
  open?: boolean;
  setOpen?: (open: boolean) => void;
  names?: string[];
}

export const RoomEditor = (props: RoomFormProps) => {
  const { open, setOpen, id, defaultName, defaultAlias, defaultNote } = props;

  const { onRowAdd, onRowUpdate } = useRoomsTableWrite();
  const { texts } = useTranslations();

  const onClose = useCallback(() => {
    setOpen?.(false);
  }, [setOpen]);

  return (
    <WriteTableModal
      isShow={open ?? false}
      onClose={onClose}
      writeMode={id ? WriteModeEnum.update : WriteModeEnum.create}
      title={
        id
          ? texts.planner.plannerRooms.editRoom
          : texts.planner.plannerRooms.addRoom
      }
    >
      <FormController
        initialValues={{
          name: defaultName,
          alias: defaultAlias,
          note: defaultNote,
        }}
        validate={(values) => {
          const errors: Partial<Values> = {};
          if (!values.name) {
            errors.name = texts.oasCommon.validation.required;
          }
          if (
            props.names
              ?.map((n) => n.toLowerCase())
              ?.includes(values.name.toLowerCase())
          ) {
            if (
              (defaultAlias?.toLowerCase() ?? '') ===
                (values.alias?.toLowerCase() ?? '') &&
              (defaultNote?.toLowerCase() ?? '') ===
                (values.note?.toLowerCase() ?? '')
            ) {
              errors.name = texts.planner.plannerRooms.duplicateRoomError;
            }
          }
          if (values.alias && values.alias.length > 10) {
            errors.alias = parseTemplate(
              texts.oasCommon.validation.stringLength,
              { length: 10 },
            );
          }
          return errors;
        }}
        onSubmit={async (values) => {
          setOpen?.(false);
          id
            ? await onRowUpdate({
                id,
                name: values.name,
                alias: values.alias,
                note: values.note,
              })
            : await onRowAdd({
                name: values.name,
                alias: values.alias,
                note: values.note,
              });
        }}
      >
        {({ submitForm, isSubmitting }) => (
          <Form>
            <Field
              component={FormTextField}
              name="name"
              type="text"
              label={texts.planner.common.names.one}
              disabled={isSubmitting}
              fullWidth
            />
            <Field
              component={FormTextField}
              name="alias"
              type="text"
              label={texts.planner.common.shortNames.one}
              disabled={isSubmitting}
              fullWidth
            />
            <Field
              component={FormTextField}
              name="note"
              type="text"
              label={texts.planner.common.notes.one}
              disabled={isSubmitting}
              fullWidth
            />
            <FlexBox justifyContent="flex-end">
              <Button
                color="secondary"
                disabled={isSubmitting}
                onClick={submitForm}
              >
                {id ? texts.planner.common.save : texts.planner.common.add}
              </Button>
              <Button onClick={onClose} color="secondary" simple>
                {texts.planner.common.close}
              </Button>
            </FlexBox>
          </Form>
        )}
      </FormController>
    </WriteTableModal>
  );
};
