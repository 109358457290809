import { CSSProperties, forwardRef } from 'react';

import { mdStyled } from 'core/atoms/themes';

import { MDButton, MDButtonProps } from 'core/cells/md-button';

interface OmittedProps {
  variant?: any;
  disableRipple?: any;
}

export interface LinkButtonProps
  extends Omit<MDButtonProps, keyof OmittedProps> {
  focusColor?: CSSProperties['color'];
}

export interface LinkButtonRootProps extends MDButtonProps {
  focusColor?: CSSProperties['color'];
}
const LinkButtonRoot = mdStyled(MDButton)<LinkButtonRootProps>(
  ({ theme, ...styles }) => {
    const { palette } = theme;
    const color =
      !styles.color || styles.color === 'inherit' ? 'primary' : styles.color;
    const focusColor =
      !styles.focusColor || styles.focusColor === 'inherit'
        ? '#89229b'
        : styles.focusColor;

    const paletteColor = palette[color] ?? palette.primary;
    const backgroundColor = palette.transparent.main;

    return {
      '&.MuiButton-root': {
        textTransform: 'none',
        backgroundColor,
        minHeight: 0,
        color: paletteColor.main,
        boxShadow: 'none',
        margin: 0,
        padding: 0,
        fontSize: '1rem',
        fontWeight: 400,
        lineHeight: 1.5,

        '&:hover': {
          backgroundColor,
          color: focusColor,
          boxShadow: 'none',
        },

        '&:focus': {
          boxShadow: 'none',
        },

        '&:focus:not(:hover)': {
          backgroundColor,
          color: focusColor,
        },

        '&:active:not(:hover)': {
          backgroundColor,
          color: focusColor,
        },

        '&:active, &:active:focus, &:active:hover': {
          backgroundColor,
          boxShadow: 'none',
        },

        '&:disabled': {
          boxShadow: 'none',
        },
      },
    };
  },
);

export const LinkButton = forwardRef<any, LinkButtonProps>(
  ({ children, ...rest }, ref) => {
    return (
      <LinkButtonRoot {...rest} ref={ref} variant="link" disableRipple>
        {children}
      </LinkButtonRoot>
    );
  },
);

LinkButton.displayName = 'LinkButton';
