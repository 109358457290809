import { Identity } from 'core/atoms/types';

import { GetClasses_Class_unions_chapters_union as ApiUnionData } from 'core/dna/types/generated/GetClasses';

export const mapGetClassesClassUnionsChaptersUnionToUnionData = (
  apiUnionData: ApiUnionData | null,
): Identity | undefined => {
  if (!apiUnionData) {
    return;
  }
  return {
    id: apiUnionData.id,
  };
};
