import { useState, useLayoutEffect } from 'react';

import { tpcStore } from './tpc-store';

export const useTpcState = () => {
  const [state, setState] = useState(tpcStore.initialState);

  useLayoutEffect(() => {
    const subscription = tpcStore.subscribe(setState);
    return () => {
      subscription.unsubscribe();
    };
  }, []);

  return {
    tpcState: state,
  };
};
