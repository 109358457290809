import React from 'react';

import { makeStyles, createStyles } from 'core/atoms/styles';

import { Grid } from 'core/cells/grid';
import { Typography } from 'core/cells/typography';

import { useTranslations } from 'core/dna/translations';

export interface ShareMobileProps {
  shareId: string;
  href: string;
  image: string;
  alt: string;
}

const useStyles = makeStyles(() =>
  createStyles({
    centeredContent: {
      display: 'flex',
      justifyContent: 'center',
    },
  }),
);

export const ShareMobile = (props: ShareMobileProps) => {
  const { shareId, href, image, alt } = props;
  const classes = useStyles();
  const { lang } = useTranslations();

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} className={classes.centeredContent}>
        <Typography>
          {lang.preparationSpace.schedulesModule.shareMessage}
        </Typography>
      </Grid>
      <Grid item xs={12} className={classes.centeredContent}>
        <a href={href}>
          <img src={image} width={120} alt={alt} />
        </a>
      </Grid>
      <Grid item xs={12} className={classes.centeredContent}>
        <Typography>{shareId}</Typography>
      </Grid>
    </Grid>
  );
};
