import React, { useLayoutEffect } from 'react';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';

import { AbsenceState } from 'core/dna/types/generated/_globalTypes';
import { runtimeEnv } from 'core/atoms/env';
import { useTranslations } from 'core/dna/translations';
import { AbsenceTotalChartData } from 'modules/reports/dna/types';

const licenseKey: string = runtimeEnv().REACT_APP_AM_CHARTS_4_LICENSE_KEY;

am4core.useTheme(am4themes_animated);
am4core.addLicense(licenseKey);

export interface ChartProps {
  data: AbsenceTotalChartData[];
}

export enum ExemptState {
  exempt = 'exempt',
}

export const Chart = ({ data }: ChartProps) => {
  const { texts } = useTranslations();

  const minGridDistance = 40;

  useLayoutEffect(() => {
    // Create chart instance
    const chart = am4core.create('chartdiv', am4charts.XYChart);

    // Add data
    chart.data = data;

    chart.legend = new am4charts.Legend();
    chart.legend.position = 'top';

    // Create axes
    const categoryAxis = chart.yAxes.push(new am4charts.CategoryAxis());
    // categoryAxis.dataFields.category = 'year';
    categoryAxis.dataFields.category = 'title';
    categoryAxis.renderer.grid.template.opacity = 0;

    const valueAxis = chart.xAxes.push(new am4charts.ValueAxis());
    valueAxis.min = 0;
    valueAxis.renderer.grid.template.opacity = 0;
    valueAxis.renderer.ticks.template.strokeOpacity = 0.5;
    valueAxis.renderer.ticks.template.stroke = am4core.color('#495c43');
    valueAxis.renderer.ticks.template.length = 10;
    valueAxis.renderer.line.strokeOpacity = 0.5;
    valueAxis.renderer.baseGrid.disabled = true;
    valueAxis.renderer.minGridDistance = minGridDistance;
    valueAxis.maxPrecision = 0;

    // Create series
    function createSeries(field: AbsenceState | ExemptState, name: string) {
      const series = chart.series.push(new am4charts.ColumnSeries());
      series.dataFields.valueX = field;
      series.dataFields.categoryY = 'title';
      series.stacked = true;
      series.name = name;
      if (field === AbsenceState.open) {
        series.stroke = am4core.color('#f55a4e');
        series.fill = am4core.color('#f55a4e');
      }
      if (field === ExemptState.exempt) {
        series.stroke = am4core.color('#00d27a');
        series.fill = am4core.color('#00d27a');
      }
      const labelBullet = series.bullets.push(new am4charts.LabelBullet());
      labelBullet.locationX = 0.5;
      labelBullet.label.text = '{valueX}';
      labelBullet.label.fill = am4core.color('#fff');
      // Hide labels with 0 value
      labelBullet.label.adapter.add('textOutput', (text, target) => {
        if (target.dataItem && target.dataItem.values.valueX.value === 0) {
          return '';
        }
        return text;
      });
    }

    createSeries(
      AbsenceState.leave,
      texts.studentModule.absence.stateValues![AbsenceState.leave],
    );
    createSeries(
      AbsenceState.medical,
      texts.studentModule.absence.stateValues![AbsenceState.medical],
    );
    createSeries(
      AbsenceState.undocumented,
      texts.studentModule.absence.stateValues![AbsenceState.undocumented],
    );
    createSeries(
      AbsenceState.open,
      texts.studentModule.absence.stateValues![AbsenceState.open],
    );
    createSeries(ExemptState.exempt, texts.studentModule.absence.exempt!);
  }, [texts, data]);

  const calculateHeight = () =>
    !data.length ? 300 : data.length * (minGridDistance + 15);

  return (
    <div
      id="chartdiv"
      style={{ width: '100%', height: calculateHeight(), minHeight: 300 }}
    />
  );
};
