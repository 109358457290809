import { BehaviorSubject } from 'rxjs';
import { produce } from 'immer';

import { logger, LogType } from 'core/atoms/housekeeping';
import { DataItem } from 'core/atoms/types';

export interface StudentsStoreState {
  importListVisible: boolean;
  importing: boolean;
  importSuccess: boolean | undefined;
  importError: boolean | undefined;
}

const initialState: StudentsStoreState = {
  importListVisible: false,
  importing: false,
  importSuccess: undefined,
  importError: undefined,
};

const subject = new BehaviorSubject(initialState);

let state = initialState;

const emit = (state: StudentsStoreState) => {
  subject.next(state);
};

const FILE_NAME = 'planner/source-data/classes/students-store';

const changeImportListVisibility = (visible: boolean) =>
  produce(state, (draft) => {
    draft.importListVisible = visible;
  });

export const studentsStore = {
  subscribe: (setState: (value: StudentsStoreState) => void) =>
    subject.subscribe(setState),
  showImportList: () => {
    state = changeImportListVisibility(true);

    logger.debug({
      title: 'showImportList',
      logger: FILE_NAME,
      type: LogType.Info,
    });

    emit(state);
  },
  hideImportList: () => {
    state = changeImportListVisibility(false);

    logger.debug({
      title: 'hideImportList',
      logger: FILE_NAME,
      type: LogType.Info,
    });

    emit(state);
  },
  importStudents: async (
    importFunc: (
      classId: string,
      students: DataItem<{ tuid: string }>[],
    ) => Promise<void>,
    params: { classId: string; students: DataItem<{ tuid: string }>[] },
  ) => {
    state = produce(state, (draft) => {
      draft.importing = true;
    });
    emit(state);
    try {
      await importFunc(params.classId, params.students);
      state = produce(state, (draft) => {
        draft.importSuccess = true;
        draft.importError = false;
      });
    } catch {
      state = produce(state, (draft) => {
        draft.importSuccess = false;
        draft.importError = true;
      });
    } finally {
      state = produce(state, (draft) => {
        draft.importing = false;
      });
    }
    emit(state);
  },
  initialState,
};
