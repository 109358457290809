import { RouteConfig } from 'core/dna/types/local';
import { matchPath } from 'core/dna/routing/match-path';

export const getActiveRoute = (routes: RouteConfig[]): RouteConfig => {
  let activeRoute: any = {};
  // eslint-disable-next-line no-restricted-syntax
  for (const route of routes) {
    if (route.collapse) {
      const collapseActiveRoute = getActiveRoute(route.views ?? []);
      if (collapseActiveRoute.name !== activeRoute.name) {
        activeRoute = collapseActiveRoute;
        break;
      }
    } else {
      const match = matchPath(window.location.pathname, {
        path: route.path,
        exact: true,
      });
      if (match) {
        activeRoute = route;
        break;
      }
    }
  }
  return activeRoute;
};
