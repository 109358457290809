import { gql } from 'core/dna/types/apollo';
import {
  ABSENCE_DETAILS_FRAGMENT,
  PERSON_T2_DETAILS_FRAGMENT,
} from 'modules/student/memory/apollo/_fragments';

export const GET_ABSENCES_QUERY = gql`
  query GetAbsences(
    $first: Int
    $order: OrderDirection
    $input: PersonT2_DateInputFilter
  ) {
    PersonT2 {
      tuid
      details {
        ...PersonDetailsT2
      }
      absences(first: $first, order: $order, filter: $input) {
        ...Absence
      }
    }
  }
  ${PERSON_T2_DETAILS_FRAGMENT}
  ${ABSENCE_DETAILS_FRAGMENT}
`;
