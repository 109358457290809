import { useMemo } from 'react';

import { useGetSelectedScheduleState } from 'modules/planner/memory/apollo/schedules/local';
import { getCurrentScheduleStorage } from 'modules/planner/memory/browser/schedule';

const { getCurrentSchedule } = getCurrentScheduleStorage();

export const useGetCurrentSchedule = () => {
  const {
    selectedScheduleState,
    loading,
    error,
  } = useGetSelectedScheduleState();

  const currentSchedule = useMemo(() => {
    if (!loading && !error) {
      return selectedScheduleState ?? getCurrentSchedule() ?? undefined;
    }
  }, [error, loading, selectedScheduleState]);

  return {
    currentSchedule,
    loading,
    error,
  };
};
