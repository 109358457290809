import React, { useMemo } from 'react';

import { locale, LocaleContext } from 'core/dna/routing';

export const LocaleProvider = ({ children }: any) => {
  const value = useMemo(() => locale(), []);

  return (
    <LocaleContext.Provider value={value}>{children}</LocaleContext.Provider>
  );
};
