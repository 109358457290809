import enFile from './locales/en.json';
import noFile from './locales/no.json';
import ukFile from './locales/uk.json';

import { OasLocales } from './types';

const langMap: { [key: string]: OasLocales } = {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  no: noFile.no,
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  en: enFile.en,
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  uk: ukFile.uk,
};

export const translations = () => {
  return {
    no: langMap['no'],
    en: langMap['en'],
    uk: langMap['uk'],
  };
};
