import { Grid } from 'core/cells/grid';
import { Drawer } from 'core/cells/drawer';
import { CircularProgress } from 'core/cells/circular-progress';

import { ClassesGenerator } from './components/classes-generator';
import { ClassesTable } from './components/classes-table';

import { useClassesState, classesStore } from '../../store/classes';

export const Classes = () => {
  const {
    classesState: { classesGeneratorVisible, generating },
  } = useClassesState();

  return (
    <Grid container>
      <Grid item xs={12}>
        <Drawer
          anchor="left"
          open={classesGeneratorVisible}
          onClose={classesStore.hideClassesGenerator}
        >
          <ClassesGenerator />
        </Drawer>
      </Grid>
      <Grid item xs={12}>
        {generating && <CircularProgress size={100} />}
        <ClassesTable
          onGenerateClassesClick={classesStore.showClassesGenerator}
        />
      </Grid>
    </Grid>
  );
};
