import { useState, useMemo } from 'react';
import { useTable, useFilters, useSortBy, usePagination } from 'react-table';
import matchSorter from 'match-sorter';
import clsx from 'clsx';

import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import SwapVertIcon from '@mui/icons-material/SwapVert';
import ClearCircleIcon from '@mui/icons-material/HighlightOffTwoTone';
import FilterIcon from '@mui/icons-material/FilterListTwoTone';

import Button from '@mui/material/Button';

import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

import { IconButton } from 'core/cells/icon-button';
import { InputAdornment } from 'core/cells/input-adornment';

import { useTranslations } from 'core/dna/translations';

import {
  ReactTableFilter,
  ReactTableSort,
} from 'modules/planner/dna/types/react-table-state';

import {
  initialPage,
  pageSize,
} from 'modules/planner/dna/constants/table-state-constants';

import { Button as CustomButton } from '../custom-buttons';
import { CustomInput } from '../custom-input';
import { GridContainer, GridItem } from '../grid';

import { useStyles } from './react-table.styles';
import { debuglog } from 'util';

/* eslint-disable */

interface DefaultColumnFilterProps {
  column: {
    filterValue: string;
    preFilteredRows: any[];
    setFilter: any;
    id: string;
  };
  onFilterChange?: (id: string, value: any) => void;
}

// Define a default UI for filtering
function DefaultColumnFilter({
  column: { filterValue, preFilteredRows, setFilter, id },
  onFilterChange,
}: DefaultColumnFilterProps) {
  const count = preFilteredRows.length;

  return (
    <CustomInput
      formControlProps={{
        fullWidth: true,
      }}
      inputProps={{
        value: filterValue || '',
        onChange: (e: any) => {
          setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
          if (onFilterChange) {
            onFilterChange(id, e.target.value);
          }
        },
        placeholder: `Search ${count} records...`,
        endAdornment: (
          <InputAdornment position="end">
            {filterValue ? (
              <IconButton
                edge="end"
                onClick={() => {
                  setFilter?.(undefined);
                  onFilterChange?.(id, undefined);
                }}
              >
                <ClearCircleIcon />
              </IconButton>
            ) : (
              <FilterIcon />
            )}
          </InputAdornment>
        ),
      }}
    />
  );
}

function fuzzyTextFilterFn(rows: any[], id: string, filterValue: string) {
  return matchSorter(rows, filterValue, { keys: [(row) => row.values[id]] });
}

// Let the table remove the filter if the string is empty
fuzzyTextFilterFn.autoRemove = (val: any) => !val;

interface TableProps {
  columns: any[];
  data: any;
  name?: string;
  options?: {
    initialPage: number;
    pageSize: number;
    sort: ReactTableSort | undefined;
    filters: ReactTableFilter | undefined;
  };
  onChangeRowsPerPage?: (pageSize: number) => void;
  onChangePage?: (page: number) => void;
  onOrderChange?: (columnId: string, isSortedDesc: boolean) => void;
  onFilterChange?: (columnId: string, value: any) => void;
  paginationFullWidth?: boolean;
  noActions?: boolean;
  noPagination?: boolean;
  onMouseOverRow?: (rowData: any) => void;
  onMouseLeaveRow?: (rowData: any) => void;
}

// Our table component
export const Table = (props: TableProps) => {
  const {
    columns,
    data,
    options,
    onChangeRowsPerPage,
    onChangePage,
    onOrderChange,
    onFilterChange,
    paginationFullWidth,
    noActions,
    noPagination,
    onMouseOverRow,
    onMouseLeaveRow,
  } = props;

  const { texts } = useTranslations();

  const [numberOfRows, setNumberOfRows] = useState(
    options?.pageSize ?? pageSize,
  );

  const classes = useStyles();

  const filterTypes = useMemo(
    () => ({
      // Add a new fuzzyTextFilterFn filter type.
      fuzzyText: fuzzyTextFilterFn,
      // Or, override the default text filter to use
      // "startWith"
      text: (rows: any[], id: string, filterValue: string) => {
        return rows.filter((row) => {
          const rowValue = row.values[id];
          return rowValue !== undefined
            ? String(rowValue)
                .toLowerCase()
                .startsWith(String(filterValue).toLowerCase())
            : true;
        });
      },
    }),
    [],
  );

  const defaultColumn = useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: (props: any) => (
        <DefaultColumnFilter {...props} onFilterChange={onFilterChange} />
      ),
    }),
    [onFilterChange],
  );

  const initialPageSize = options?.pageSize ?? pageSize;
  const initialPageIndex = options?.initialPage ?? initialPage;
  const initialSortBy = options?.sort ? [options.sort] : [];
  const initialFilters = options?.filters ? [options.filters] : [];

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    state,
    prepareRow,
    nextPage,
    pageOptions,
    previousPage,
    canPreviousPage,
    canNextPage,
    setPageSize,
    gotoPage,
    setAllFilters,
    setSortBy,
  } = useTable(
    {
      columns,
      data: data ?? [],
      defaultColumn, // Be sure to pass the defaultColumn option
      filterTypes: filterTypes as any,
      initialState: {
        pageSize: initialPageSize,
        pageIndex: initialPageIndex,
        sortBy: initialSortBy,
        filters: initialFilters,
      },
    },
    useFilters, // useFilters!
    useSortBy,
    usePagination,
  );

  const hasSomethingToReset = () => {
    function hasFilters() {
      if (!state?.filters?.length) {
        return false;
      }
      if (state.filters.length > 1) {
        return true;
      }
      return state.filters[0].id;
    }

    function hasSorting() {
      if (!state.sortBy.length) {
        return false;
      }
      if (state.sortBy.length > 1) {
        return false;
      }
      return state.sortBy[0].id;
    }

    const hasPageSize = state.pageSize !== initialPageSize;
    const hasPaging = state.pageIndex !== initialPageIndex;

    return hasFilters() || hasPageSize || hasPaging || hasSorting();
  };

  // We don't want to render all of the rows for this example, so cap
  // it for this use case
  // const firstPageRows = rows.slice(0, 10);
  // eslint-disable-next-line prefer-spread
  const pageSelectData = Array.apply(
    null,
    Array(pageOptions.length),
    // eslint-disable-next-line @typescript-eslint/no-empty-function,array-callback-return
  ).map(() => {});
  const numberOfRowsData = [5, 10, 20, 25, 50, 100];
  return (
    <>
      <div className={clsx('ReactTable -striped -highlight', classes.rt)}>
        {noPagination ? null : (
          <div className="pagination-top">
            <div
              className={`-pagination ${paginationFullWidth ? '' : '-small'}`}
            >
              {hasSomethingToReset() && (
                <Button
                  sx={{ '&.MuiButtonBase-root': { marginRight: 2 } }}
                  variant="text"
                  onClick={() => {
                    setAllFilters([]);
                    setPageSize(pageSize);
                    setNumberOfRows(pageSize);
                    gotoPage(initialPage);
                    setSortBy([]);
                  }}
                  size="small"
                >
                  {texts.oasCommon.resetTable}
                </Button>
              )}
              <div className="-previous">
                {paginationFullWidth ? (
                  <button
                    type="button"
                    onClick={() => previousPage()}
                    disabled={!canPreviousPage}
                    className="-btn"
                  >
                    Previous
                  </button>
                ) : (
                  <CustomButton
                    justIcon
                    round
                    simple
                    onClick={() => previousPage()}
                    disabled={!canPreviousPage}
                    color="primary"
                  >
                    <ArrowBackIosIcon />
                  </CustomButton>
                )}
              </div>
              <div className="-center">
                <GridContainer className={classes.gridContainer}>
                  <GridItem xs={12} sm={6} md={paginationFullWidth ? 4 : 6}>
                    <FormControl
                      fullWidth
                      className={
                        classes.selectFormControl +
                        ' ' +
                        classes.formControlMargins
                      }
                    >
                      <Select
                        MenuProps={{
                          className: classes.selectMenu,
                        }}
                        classes={{
                          select: classes.select,
                        }}
                        value={state.pageIndex}
                        onChange={(event) => {
                          if (onChangePage) {
                            onChangePage(Number(event.target.value));
                          }
                          gotoPage(Number(event.target.value));
                        }}
                        inputProps={{
                          name: 'pageSelect',
                          id: 'page-select',
                        }}
                      >
                        {pageSelectData.map((prop, key) => {
                          return (
                            <MenuItem
                              key={key}
                              classes={{
                                root: classes.selectMenuItem,
                                selected: classes.selectMenuItemSelected,
                              }}
                              value={key}
                            >
                              Page {key + 1}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </GridItem>
                  <GridItem xs={12} sm={6} md={paginationFullWidth ? 4 : 6}>
                    <FormControl
                      fullWidth
                      className={
                        classes.selectFormControl +
                        ' ' +
                        classes.formControlMargins
                      }
                    >
                      <Select
                        MenuProps={{
                          className: classes.selectMenu,
                        }}
                        classes={{
                          select: classes.select,
                        }}
                        value={numberOfRows}
                        onChange={(event) => {
                          if (onChangeRowsPerPage) {
                            onChangeRowsPerPage(Number(event.target.value));
                          }
                          setPageSize(Number(event.target.value));
                          setNumberOfRows(Number(event.target.value));
                        }}
                        inputProps={{
                          name: 'numberOfRows',
                          id: 'number-of-rows',
                        }}
                      >
                        {numberOfRowsData.map((prop) => {
                          return (
                            <MenuItem
                              key={prop}
                              classes={{
                                root: classes.selectMenuItem,
                                selected: classes.selectMenuItemSelected,
                              }}
                              value={prop}
                            >
                              {prop} rows
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </GridItem>
                </GridContainer>
              </div>
              <div className="-next">
                {paginationFullWidth ? (
                  <button
                    type="button"
                    onClick={() => nextPage()}
                    disabled={!canNextPage}
                    className="-btn"
                  >
                    Next
                  </button>
                ) : (
                  <CustomButton
                    justIcon
                    round
                    simple
                    onClick={() => nextPage()}
                    disabled={!canNextPage}
                    color="primary"
                  >
                    <ArrowForwardIosIcon />
                  </CustomButton>
                )}
              </div>
            </div>
          </div>
        )}
        <table {...getTableProps()} className="rt-table">
          <thead className="rt-thead -header">
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()} className="rt-tr">
                {headerGroup.headers.map((column, key) => (
                  <th
                    style={{
                      flexBasis: column.width,
                      minWidth: column.minWidth,
                      maxWidth: column.maxWidth,
                    }}
                    key={key}
                    className={clsx('rt-th rt-resizable-header', {
                      '-cursor-pointer':
                        noActions || headerGroup.headers.length - 1 !== key,
                      '-sort-asc': column.isSorted && !column.isSortedDesc,
                      '-sort-desc': column.isSorted && column.isSortedDesc,
                    })}
                  >
                    <div
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                      onClick={() => {
                        column.toggleSortBy(!column.isSortedDesc, false);
                        if (onOrderChange) {
                          onOrderChange(column.id, !column.isSortedDesc!);
                        }
                      }}
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent:
                          noActions || headerGroup.headers.length - 1 !== key
                            ? 'flex-start'
                            : 'flex-end',
                      }}
                    >
                      <span className="rt-resizable-header-content">
                        {column.render('Header')}
                      </span>
                      {!column.isSorted ? (
                        <SwapVertIcon />
                      ) : column.isSorted && !column.isSortedDesc ? (
                        <ArrowUpwardIcon />
                      ) : (
                        <ArrowDownwardIcon />
                      )}
                    </div>
                    {/* Render the columns filter UI */}
                    <div className={classes.filterContainer}>
                      {!noActions && headerGroup.headers.length - 1 === key
                        ? null
                        : column.canFilter
                        ? column.render('Filter', { onFilterChange })
                        : null}
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody
            {...getTableBodyProps()}
            className={clsx('rt-tbody', classes.tableBody)}
          >
            {page.map((row, i) => {
              prepareRow(row);
              return (
                <tr
                  {...row.getRowProps()}
                  onMouseOver={() => {
                    onMouseOverRow?.(row.original);
                  }}
                  onMouseLeave={() => {
                    onMouseLeaveRow?.(row.original);
                  }}
                  className={clsx(
                    'rt-tr',
                    { ' -odd': i % 2 === 0 },
                    { ' -even': i % 2 === 1 },
                  )}
                >
                  {row.cells.map((cell) => {
                    return (
                      <td
                        {...cell.getCellProps()}
                        className="rt-td"
                        style={{
                          flexBasis: cell.column.width,
                          minWidth: cell.column.minWidth,
                          maxWidth: cell.column.maxWidth,
                        }}
                      >
                        {cell.render('Cell')}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
        <div className="pagination-bottom" />
      </div>
    </>
  );
};

// Define a custom filter filter function!
function filterGreaterThan(rows: any[], id: string, filterValue: string) {
  return rows.filter((row) => {
    const rowValue = row.values[id];
    return rowValue >= filterValue;
  });
}

// This is an autoRemove method on the filter function that
// when given the new filter value and returns true, the filter
// will be automatically removed. Normally this is just an undefined
// check, but here, we want to remove the filter if it's not a number
filterGreaterThan.autoRemove = (val: any) => typeof val !== 'number';
