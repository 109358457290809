import { useCallback } from 'react';

import { selectedClassStateVar } from 'modules/planner/memory/apollo/cache';

export const useSetSelectedClassState = () => {
  const func = useCallback((value: string) => {
    try {
      selectedClassStateVar(value);
    } catch (e: any) {
      throw new Error(e);
    }
  }, []);

  return {
    setSelectedClassesState: func,
  };
};
