import { makeStyles, createStyles, CSSProperties } from 'core/atoms/styles';

import { grayColor } from '../../material-dashboard-pro-react';

export interface HeadingStyle {
  heading: CSSProperties;
  rightTextAlign: CSSProperties;
  leftTextAlign: CSSProperties;
  centerTextAlign: CSSProperties;
  title: CSSProperties;
  category: CSSProperties;
}

export const headingStyle: HeadingStyle = {
  heading: {
    marginBottom: '30px',
  },
  rightTextAlign: {
    textAlign: 'right',
  },
  leftTextAlign: {
    textAlign: 'left',
  },
  centerTextAlign: {
    textAlign: 'center',
  },
  title: {
    marginTop: '10px',
    color: grayColor[2],
    textDecoration: 'none',
  },
  category: {
    margin: '0 0 10px',
  },
};

export const useStyles = makeStyles(() => createStyles({ ...headingStyle }));
