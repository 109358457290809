import { Subject } from 'modules/planner/dna/types/subject';
import { GetSchedule_Schedule_lessonGroups_lessons_subjects as ApiGetSchedulesSubject } from 'core/dna/types/generated/GetSchedule';
import { GetSubjects_Subject as ApiGetSubjectsSubject } from 'core/dna/types/generated/GetSubjects';
import { mapNullableListToList } from 'core/dna/functions';

export const mapApiSubjectListToSubjectList = (
  apiSubjectList:
    | (ApiGetSchedulesSubject | ApiGetSubjectsSubject | null)[]
    | null,
): Subject[] | undefined => {
  return mapNullableListToList<
    ApiGetSchedulesSubject | ApiGetSubjectsSubject,
    Subject
  >(apiSubjectList, mapApiSubjectToSubject);
};

export const mapApiSubjectToSubject = (
  apiSubject: ApiGetSchedulesSubject | ApiGetSubjectsSubject | null,
): Subject | undefined => {
  if (!apiSubject) {
    return;
  }

  const { id, name, alias, code, bgColor, annualHours } = apiSubject;

  return {
    id,
    name,
    alias: alias ?? undefined,
    code: code ?? undefined,
    bgColor: bgColor ?? undefined,
    annualHours: annualHours ?? undefined,
    // TODO: map termId somehow
  };
};
