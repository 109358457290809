import { useCallback } from 'react';

import { OasMemoryError, OasError } from 'core/atoms/errors';

import { gql, useMutation } from 'core/dna/types/apollo';
import { DuplicateScheduleInput } from 'core/dna/types/generated/_globalTypes';
import {
  DuplicateSchedule,
  DuplicateScheduleVariables,
} from 'core/dna/types/generated/DuplicateSchedule';

import { useLazyGetTermsState } from 'core/memory/apollo/terms/local';

import { GET_SCHEDULE_DESCRIPTIONS_QUERY } from './_gql';

const DUPLICATE_SCHEDULE_MUTATION = gql`
  mutation DuplicateSchedule($input: DuplicateScheduleInput!) {
    duplicateSchedule(input: $input) {
      id
      name
    }
  }
`;

const TITLE = 'UseDuplicateSchedule';

export const useDuplicateSchedule = () => {
  const { getTermState } = useLazyGetTermsState();

  const [duplicateSchedule] = useMutation<
    DuplicateSchedule,
    DuplicateScheduleVariables
  >(DUPLICATE_SCHEDULE_MUTATION);

  const func = useCallback(
    async (input: DuplicateScheduleInput) => {
      try {
        const termsState = await getTermState(true);

        const result = await duplicateSchedule({
          variables: { input },
          refetchQueries: [
            {
              query: GET_SCHEDULE_DESCRIPTIONS_QUERY,
              variables: { termId: termsState!.selected! },
            },
          ],
        });

        if (result.errors) {
          throw new OasMemoryError(JSON.stringify(result.errors), {
            title: TITLE,
          });
        }
        return result.data?.duplicateSchedule ?? undefined;
      } catch (e: any) {
        if (e instanceof OasError) {
          throw e;
        } else {
          throw OasMemoryError.fromError(e, {
            title: TITLE,
          });
        }
      }
    },
    [duplicateSchedule, getTermState],
  );

  return {
    duplicateSchedule: func,
  };
};
