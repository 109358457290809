import { useEffect, useState, useRef } from 'react';
import moment from 'moment';
import { FetchResult, Observable } from '@apollo/client';

import { runtimeEnv } from 'core/atoms/env';
import {
  useTraceUpdate,
  useRenderCounter,
  logger,
  LogType,
} from 'core/atoms/housekeeping';

import {
  ApolloClient,
  HttpLink,
  ApolloLink,
  split,
  from,
  ApolloProvider,
  WebSocketLink,
  getMainDefinition,
  InMemoryCache,
  NormalizedCacheObject,
} from 'core/dna/types/apollo';
import { useSelectedOrganizationState } from 'core/dna/organizations';
import { useModulesUpdater } from 'core/dna/modules';

import { coreFields } from 'core/memory/apollo/cache';
import { useLoadOrganization } from 'core/memory/apollo/organizations/remote';

import { AuthIdentity } from 'auth/dna/types';

import {
  getAccessTokenStorage,
  getSessionTokenStorage,
} from 'auth/memory/browser';
import { parseJwt } from 'auth/dna/functions';

import { useAuthContext } from 'auth/metabolism/use-auth-context';

import { localTypeDefs } from 'modules/planner/memory/apollo/local';
import { plannerFields } from 'modules/planner/memory/apollo/cache';

import { loggerLink } from './logging/network-logger';

/** https://www.apollographql.com/blog/previewing-the-apollo-client-3-cache-565fadd6a01e/ */
export const memoryCache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        ...coreFields,
        ...plannerFields,
      },
    },
  },
});

const LOGGER = 'data-provider';

const { getAccessToken, removeAccessToken } = getAccessTokenStorage();
const { getSessionToken, removeSessionToken } = getSessionTokenStorage();
const getAuthorization = (place: string) => {
  const accessToken = getAccessToken();
  const sessionToken = getSessionToken();
  const now = moment().unix();

  if (sessionToken) {
    const sessionTokenDecoded = parseJwt(sessionToken);

    if (sessionTokenDecoded.exp <= now) {
      removeSessionToken();
      removeAccessToken();
    }
  }

  if (accessToken) {
    // logger.debug({
    //   title: `getAuthorization() from "${place}"`,
    //   logger: TITLE,
    //   type: LogType.Accent,
    //   value: [
    //     {
    //       token,
    //     },
    //   ],
    // });
    const accessTokenDecoded = parseJwt(accessToken);

    if (accessTokenDecoded.exp <= now) {
      removeAccessToken();
    }

    return `Bearer ${accessToken}`;
  }
  // logger.debug({
  //   title: `getAuthorization() from "${place}"`,
  //   logger: TITLE,
  //   type: LogType.Error,
  //   value: 'NO TOKEN',
  // });
};

const getClient = (identity: AuthIdentity | null, orgId: string | null) => {
  // const getAuthorization = () => {
  //   return identity && `Bearer ${identity.token}`;
  // };

  const getLink = () => {
    const authMiddleware = new ApolloLink((operation, forward) => {
      const headers: any = {};
      const authorization = getAuthorization('authMiddleware');
      if (authorization) {
        headers.authorization = authorization;
      }
      if (orgId) {
        headers.onBehalfOf = orgId;
      }
      operation.setContext({ headers });
      const ops = [
        'IssueAccessToken',
        'ReissueAccessToken',
        'GetMyAccessRoles',
        'GetSubstituteInvitationDetails',
        'UpdateSubstituteInvitationTask',
      ];
      if (!orgId && ops.indexOf(operation.operationName) < 0) {
        return new Observable<FetchResult>((subscriber: any) => {
          subscriber.error({
            message: `No organization id for operation "${operation.operationName}"`,
            operation: operation.operationName,
            type: 'organizationId',
          });
        });
      }
      return forward(operation);
    });

    const httpLink = new HttpLink({
      uri: runtimeEnv().REACT_APP_PLANNER_API_URI,
    });

    const wsLink = new WebSocketLink({
      uri: runtimeEnv().REACT_APP_PLANNER_API_URI_WS,
      options: {
        lazy: true,
        reconnect: true,
        connectionParams: () => {
          return {
            authorization: getAuthorization('wsLink -> connectionParams'),
            onBehalfOf: orgId,
          };
        },
      },
    });

    // using the ability to split links, you can send data to each link
    // depending on what kind of operation is being sent
    return split(
      // split based on operation type
      ({ query }) => {
        const definition = getMainDefinition(query);
        return (
          definition.kind === 'OperationDefinition' &&
          definition.operation === 'subscription'
        );
      },
      wsLink,
      from([loggerLink, authMiddleware, httpLink]),
    );
  };

  // const cache = new LogCache(memoryCache);

  const link = getLink();

  return new ApolloClient<NormalizedCacheObject>({
    typeDefs: localTypeDefs,
    cache: memoryCache,
    link,
    connectToDevTools: process.env.NODE_ENV === 'development',
  });
};

export const DataProvider = (props: any) => {
  // TODO: remove after performance testing
  useTraceUpdate('DataProvider', props);
  const renderCount = useRenderCounter({ name: 'DataProvider' });

  const { children } = props;
  const { getIdentity, ping } = useAuthContext();
  const selected = useSelectedOrganizationState();
  const { loadOrganization } = useLoadOrganization();
  const {
    updateSubscriptionsInfo: updateModulesSubscriptionsInfo,
  } = useModulesUpdater();

  const [
    client,
    setClient,
  ] = useState<ApolloClient<NormalizedCacheObject> | null>(null);
  const prevApolloClient = useRef<ApolloClient<NormalizedCacheObject> | null>(
    null,
  );
  const accessTokenRef = useRef<any>(null);
  const selectedOrgIdRef = useRef<string | null>(null);

  useEffect(() => {
    const identity = getIdentity();
    if (
      accessTokenRef.current !== identity?.token ||
      selectedOrgIdRef.current !== selected?.id
    ) {
      const id = moment().format('YYYY-MM-DD HH:mm:ss.SSS');
      if (accessTokenRef.current !== identity?.token) {
        accessTokenRef.current = identity?.token;
        logger.debug({
          title: `[${id}] TOKEN changed`,
          type: LogType.Info,
          logger: LOGGER,
          value: identity?.token
            ? `${identity.token.slice(0, 6)}...${identity.token.slice(
                identity.token.length - 6,
              )}`
            : 'NONE',
        });
      }
      if (selectedOrgIdRef.current !== selected?.id) {
        selectedOrgIdRef.current = selected?.id ?? null;
        logger.debug({
          title: `[${id}] ORGANIZATION changed`,
          type: LogType.Info,
          logger: LOGGER,
          value: selected?.id ?? 'NONE',
        });
      }
      setClient(getClient(identity, selected?.id ?? null));
    }
  }, [getIdentity, ping, selected]);

  useEffect(() => {
    if (selected?.id && client && prevApolloClient.current !== client) {
      prevApolloClient.current = client;
      const id = moment().format('YYYY-MM-DD HH:mm:ss.SSS');
      logger.debug({
        title: `[${id}] CLIENT changed`,
        type: LogType.Default,
        logger: LOGGER,
        value: `new OrgID: "${selected?.id ?? ' '}"`,
      });
      loadOrganization(client, selected.id).then((organization) => {
        organization && updateModulesSubscriptionsInfo(organization);
      });
    }
  }, [client, loadOrganization, selected, updateModulesSubscriptionsInfo]);

  return (
    <>
      {renderCount}
      {client ? (
        <ApolloProvider client={client}>{children}</ApolloProvider>
      ) : (
        <></>
      )}
    </>
  );
};
