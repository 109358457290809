import { useMemo, useCallback } from 'react';

import { parseTemplate } from 'core/atoms/functions/string';

import { useHistory, useLocale } from 'core/dna/routing';
import { useTranslations } from 'core/dna/translations';

import { ClassTableItem } from 'modules/planner/dna/types/class';

import { useClasses } from 'modules/planner/metabolism/use-classes';

import PlannerUrls from 'modules/planner/routing/urls';

import { ChaptersTooltipButton } from '../../../../../components/chapters-tooltip-button';

export interface ColGroupsProps {
  item?: ClassTableItem;
}

export const ColGroups = (props: ColGroupsProps) => {
  const { item } = props;

  const { texts } = useTranslations();
  const history = useHistory();
  const locale = useLocale();

  const { setSelectedClasses } = useClasses();

  const text = useMemo(() => {
    if (!item?.chaptersCount || item.chaptersCount < 2) {
      return texts.planner.common.create;
    }
    // if (item.chaptersCount === 1) {
    //   return texts.planner.common.definedCountChange.one;
    // }
    return parseTemplate(texts.planner.common.definedCountChange.many, {
      count: item.chaptersCount - 1,
    });
  }, [item, texts]);

  const handleClick = useCallback(
    (itemId: string) => {
      setSelectedClasses([itemId]);
      history.push(PlannerUrls.classesGroups(locale));
    },
    [history, locale, setSelectedClasses],
  );

  return item ? (
    <ChaptersTooltipButton
      itemId={item.id}
      unions={item.unions}
      text={text}
      onClick={handleClick}
    />
  ) : (
    <span> </span>
  );
};
