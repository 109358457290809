import { useMemo, useCallback } from 'react';

import { useApolloClient } from 'core/dna/types/apollo';
import { GetRole, GetRoleVariables } from 'core/dna/types/generated/GetRole';

import { mapApiRoleToRole } from 'modules/planner/dna/functions/map-ApiRole-to-Role';

import { GET_ROLE_QUERY } from './_gql';
import { GetMyAccessRoles } from '../../../../../../core/dna/types/generated/GetMyAccessRoles';
import { OasMemoryError, OasError } from '../../../../../../core/atoms/errors';
import { mapApiMyAccessRolesListToRootAccessRolesList } from '../../../../../../core/dna/functions';

const FILE_NAME = 'use-lazy-get-role';

export const useLazyGetRole = () => {
  const client = useApolloClient();

  const getRole = useCallback(
    async (id: string) => {
      try {
        const result = await client.query<GetRole, GetRoleVariables>({
          query: GET_ROLE_QUERY,
          variables: { id },
        });
        if (result.errors) {
          throw new OasMemoryError(JSON.stringify(result.errors), {
            title: FILE_NAME,
          });
        }
        return mapApiRoleToRole(result.data.Role?.[0] ?? null);
      } catch (e: any) {
        if (e instanceof OasError) {
          throw e;
        } else {
          throw OasMemoryError.fromError(e, {
            title: FILE_NAME,
          });
        }
      }
    },
    [client],
  );

  return {
    getRole,
  };
};
