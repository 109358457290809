import { gql } from 'core/dna/types/apollo';

export const GET_SUBJECT_INFOS_FROM_DEFAULT_QUERY = gql`
  query GetSubjectInfosFromDefault {
    DefaultSubject {
      name
      short
    }
  }
`;
