import clsx from 'clsx';

import { Weekday } from 'core/atoms/date-time';

import { useWeek } from 'modules/planner/metabolism/use-week';

import { range } from '../utils';

export interface HeaderWeekDaysProps {
  firstDayOfWeek?: number;
  forceFullWeeks?: boolean;
  showWeekSeparators?: boolean;
  showWeekNumbers?: boolean;
}

const defaultProps: Partial<HeaderWeekDaysProps> = {
  forceFullWeeks: false,
  showWeekSeparators: true,
  firstDayOfWeek: 1,
};

const EmptyHeaderCell = () => (
  <th aria-label="Empty Header Cell" style={{ cursor: 'default' }}>
    &nbsp;
  </th>
);

export const HeaderRow = (props: HeaderWeekDaysProps) => {
  const {
    firstDayOfWeek = defaultProps.firstDayOfWeek || 0,
    forceFullWeeks,
    showWeekSeparators,
    showWeekNumbers,
  } = props;
  const { getWeekday } = useWeek();

  const totalDays = forceFullWeeks ? 42 : 37;

  const days: any[] = [];
  range(firstDayOfWeek, totalDays + firstDayOfWeek, null).forEach((i) => {
    // const dayLetter = moment().weekday(i).format('dd').charAt(0);

    if (i % 7 === firstDayOfWeek && days.length) {
      if (showWeekSeparators) {
        // push week separator
        days.push(
          <th
            aria-label="Header Row Separator"
            className="week-separator"
            key={`separator-${i}`}
          />,
        );
      }
      if (showWeekNumbers) {
        days.push(<EmptyHeaderCell />);
      }
    }
    days.push(
      <th
        key={`weekday-${i}`}
        className={clsx(
          'weekday',
          i % 7 === Weekday.Saturday || i % 7 === Weekday.Sunday
            ? 'bolder'
            : '',
        )}
        style={{ cursor: 'default' }}
      >
        {getWeekday(i % 7).letter}
      </th>,
    );
  });

  return (
    <tr>
      <EmptyHeaderCell />
      {showWeekNumbers && <EmptyHeaderCell />}
      {days}
    </tr>
  );
};

HeaderRow.defaultProps = defaultProps;
