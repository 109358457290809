/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum AbsenceState {
  leave = "leave",
  medical = "medical",
  open = "open",
  undocumented = "undocumented",
}

export enum BatchState {
  closed = "closed",
  draft = "draft",
  locked = "locked",
  open = "open",
  published = "published",
}

export enum BatchType {
  exam = "exam",
  final = "final",
  fullYear = "fullYear",
  halfYear = "halfYear",
}

export enum ClassRole {
  contactTeacher = "contactTeacher",
  student = "student",
}

export enum ConfigurationType {
  allergies = "allergies",
  sleep = "sleep",
  weeklyEvents = "weeklyEvents",
}

export enum EvaluationCode {
  B = "B",
  BM = "BM",
  D = "D",
  F = "F",
  GK = "GK",
  I = "I",
  IM = "IM",
  IV = "IV",
  K1 = "K1",
  K2 = "K2",
  K3 = "K3",
  K4 = "K4",
  K5 = "K5",
  K6 = "K6",
}

export enum JournalType {
  attendance = "attendance",
  diaper = "diaper",
  note = "note",
  restock = "restock",
  sleep = "sleep",
}

export enum OrderDirection {
  asc = "asc",
  desc = "desc",
}

export enum OrganizationCategoryType {
  barnehage = "barnehage",
  grunnskole = "grunnskole",
  notSpecified = "notSpecified",
  sfo = "sfo",
  vgs = "vgs",
}

export enum OrganizationFeatureCode {
  absence = "absence",
  absenceSyncIst = "absenceSyncIst",
  accessToOas = "accessToOas",
  alpha = "alpha",
  beta = "beta",
  calendar = "calendar",
  canAddLocalUsers = "canAddLocalUsers",
  canSendSmsNotifications = "canSendSmsNotifications",
  grades = "grades",
  journal = "journal",
  messageBook = "messageBook",
  planner = "planner",
  remarks = "remarks",
  substitute = "substitute",
}

export enum OrganizationType {
  privateKindergarten = "privateKindergarten",
  privateSchool = "privateSchool",
  publicKindergarten = "publicKindergarten",
  publicSchool = "publicSchool",
}

export enum RemarkState {
  conduct = "conduct",
  late = "late",
  order = "order",
}

export enum RoleType {
  assistant = "assistant",
  carer = "carer",
  staff = "staff",
  student = "student",
  teacher = "teacher",
}

export enum ScheduleStatus {
  created = "created",
  running = "running",
  starting = "starting",
  terminated = "terminated",
  terminatedWithError = "terminatedWithError",
  terminating = "terminating",
}

export enum SubstituteJobInvitationPersonJobState {
  assigned = "assigned",
  unassigned = "unassigned",
}

export enum SubstituteJobState {
  cancelled = "cancelled",
  closed = "closed",
  draft = "draft",
  published = "published",
}

export enum SubstituteTeacherState {
  active = "active",
  inactive = "inactive",
}

export enum TaskResponse {
  no = "no",
  yes = "yes",
}

export interface AddMembersToChapterInput {
  chapterId: OasValidateString;
  roleIds: string[];
}

export interface AllocateScheduleAllocationDataInput {
  published: boolean;
  weekNumber: OasValidateNumber;
}

export interface AllocateScheduleInput {
  allocationData?: AllocateScheduleAllocationDataInput[] | null;
  scheduleId: OasValidateString;
  termId: OasValidateString;
}

export interface AvailabilityPreferences {
  availabilityLevel: OasValidateNumber;
  endTime: OasValidateString;
  startTime: OasValidateString;
  weekday: OasValidateNumber;
}

export interface BatchCreateStudentEvaluationsInput {
  classIds: (string | null)[];
  evaluationBatchId: OasValidateString;
  scheduleId: OasValidateString;
  subjectIds: (string | null)[];
}

export interface CancelSubstituteJobInput {
  substituteJobId: OasValidateString;
}

export interface CreateClassInput {
  name: OasValidateString;
  termId: string;
}

export interface CreateEvaluationBatchInput {
  deadline: OasValidateString;
  state: BatchState;
  term: string;
  type: BatchType;
}

export interface CreateEvaluationGroupAccessInput {
  evaluationGroupId?: OasValidateString | null;
  evaluator?: boolean | null;
  tuid?: OasValidateString | null;
}

export interface CreateEvaluationGroupInput {
  evaluationBatchId?: OasValidateString | null;
  name: OasValidateString;
  subjectCode: OasValidateString;
}

export interface CreateLessonGroupInput {
  lessons: CreateLessonGroupLessonsInput[];
  mustBeNonstop: boolean;
  plannedMinutesPerTimeSlot: number[];
  scheduleId: OasValidateString;
}

export interface CreateLessonGroupLessonsInput {
  chapters?: CreateLessonGroupResourcePartInput[] | null;
  rooms?: (CreateLessonGroupResourcePartInput | null)[] | null;
  subjects?: CreateLessonGroupResourcePartInput[] | null;
  teachers?: (CreateLessonGroupResourcePartInput | null)[] | null;
}

export interface CreateLessonGroupResourcePartInput {
  applicableWeeks?: (number | null)[] | null;
  id: OasValidateString;
}

export interface CreatePersonAndRoleFromSyncPersonInput {
  roleType: RoleType;
  syncPersonId: string;
  termId: OasValidateString;
  title: string;
}

export interface CreatePersonAndRoleInput {
  firstName: string;
  lastName: string;
  nin: OasValidateString;
  roleType: RoleType;
  staffDetails?: StaffDetails | null;
  termId?: OasValidateString | null;
  title?: string | null;
}

export interface CreateRoomInput {
  alias?: OasValidateString | null;
  name: OasValidateString;
  note?: OasValidateString | null;
  termId: OasValidateString;
}

export interface CreateScheduleInput {
  name: OasValidateString;
  termId: OasValidateString;
}

export interface CreateStudentEvaluationInput {
  code: EvaluationCode;
  evaluation?: string | null;
  evaluationGroupId: OasValidateString;
  note?: string | null;
  studentTuid: OasValidateString;
}

export interface CreateSubjectInput {
  alias?: OasValidateString | null;
  annualHours?: OasValidateNumber | null;
  bgColor?: OasValidateString | null;
  code?: OasValidateString | null;
  name: OasValidateString;
  termId: OasValidateString;
}

export interface CreateSubstituteJobInput {
  deadline: OasValidateString;
  description: OasValidateString;
  internalTitle: OasValidateString;
}

export interface CreateSubstituteJobInvites {
  substituteJobId: OasValidateString;
  substituteTeacherIds: string[];
}

export interface CreateSubstituteTaskInput {
  comment?: OasValidateString | null;
  date: OasValidateString;
  subject: OasValidateString;
  substituteJobId: OasValidateString;
  timeFrom: OasValidateString;
  timeTo: OasValidateString;
}

export interface CreateSubstituteTeacherInput {
  firstName: OasValidateString;
  lastName: OasValidateString;
  mail?: OasValidateString | null;
  msisdn?: OasValidateString | null;
  tags: (string | null)[];
}

export interface CreateTermInput {
  endDate: string;
  name: OasValidateString;
  startDate: string;
}

export interface CreateUnionInput {
  chapters?: string[] | null;
  classId: string;
}

export interface DeleteAllTimeSlotForSchedule {
  scheduleId: string;
}

export interface DeleteAllocatedScheduleInput {
  scheduleId: OasValidateString;
  termId: OasValidateString;
  weekNumber: OasValidateNumber;
}

export interface DeleteChaptersInput {
  chapterIds: (string | null)[];
}

export interface DeleteClassInput {
  classId: OasValidateString;
  force?: boolean | null;
}

export interface DeleteClassMembersInput {
  classId: OasValidateString;
  roleIds: string[];
}

export interface DeleteConfigurationInput {
  tuid: OasValidateString;
  type: ConfigurationType;
}

export interface DeleteEvaluationGroupAccessInput {
  evaluationGroupId?: OasValidateString | null;
  tuid?: OasValidateString | null;
}

export interface DeleteLessonGroupInput {
  lessonGroupId: OasValidateString;
}

export interface DeleteRoomInput {
  force?: boolean | null;
  roomId: OasValidateString;
}

export interface DeleteScheduleInput {
  scheduleId: OasValidateString;
}

export interface DeleteSubjectInput {
  force?: boolean | null;
  subjectId: OasValidateString;
}

export interface DeleteSubstituteJobInput {
  substituteJobId: OasValidateString;
}

export interface DeleteSubstituteJobInvites {
  substituteJobId: OasValidateString;
  substituteTeacherIds: string[];
}

export interface DeleteSubstituteTaskInput {
  substituteTaskId: OasValidateString;
}

export interface DeleteTimeSlot {
  timeSlotId: string;
}

export interface DuplicateScheduleInput {
  name: OasValidateString;
  sourceScheduleId: OasValidateString;
}

export interface EditClassInput {
  classId: OasValidateString;
  name: OasValidateString;
}

export interface EditLessonGroupInput {
  lessonGroupId: OasValidateString;
  lessons: CreateLessonGroupLessonsInput[];
  mustBeNonstop: boolean;
  plannedMinutesPerTimeSlot: number[];
}

export interface EditRoomInput {
  alias?: OasValidateString | null;
  name?: OasValidateString | null;
  note?: OasValidateString | null;
  roomId: OasValidateString;
}

export interface EditScheduleInput {
  name?: OasValidateString | null;
  scheduleId: OasValidateString;
}

export interface EditSubjectInput {
  alias?: OasValidateString | null;
  annualHours?: OasValidateNumber | null;
  bgColor?: OasValidateString | null;
  code?: OasValidateString | null;
  name: OasValidateString;
  subjectId: OasValidateString;
}

export interface EvaluationGroupInput {
  batchId?: OasValidateString | null;
  batchState?: string | null;
  subjectCode?: string | null;
  teacherTuid?: OasValidateString | null;
}

export interface OpeningHoursInput {
  endTime: string;
  startTime: string;
  weekday: OasValidateNumber;
}

export interface PersonT2_DateInputFilter {
  from?: OasValidateString | null;
  to?: OasValidateString | null;
}

export interface PublishSubstituteJobInvites {
  substituteJobId: OasValidateString;
}

export interface ShareScheduleInput {
  scheduleId: OasValidateString;
}

export interface StaffDetails {
  employmentPercentage?: OasValidateNumber | null;
  reservedEmploymentPercentage?: OasValidateNumber | null;
}

export interface StartSchedulingInput {
  scheduleId: OasValidateString;
}

export interface StopSchedulingInput {
  scheduleId: OasValidateString;
}

export interface SubstituteJobInvitationInput {
  inviteId: OasValidateString;
}

export interface SynchroniseAllocationSchedulesInput {
  termId: OasValidateString;
}

export interface UpdateAvailabilityPreferencesForEntitiesInput {
  preferences?: UpdateAvailabilityPreferencesForEntityInput[] | null;
}

export interface UpdateAvailabilityPreferencesForEntityInput {
  availabilityPreferences?: AvailabilityPreferences[] | null;
  entityId: OasValidateString;
  entityType: string;
}

export interface UpdateClassMembersInput {
  classId: OasValidateString;
  members?: (UpdateClassMembersMemberInput | null)[] | null;
}

export interface UpdateClassMembersMemberInput {
  classRole?: ClassRole | null;
  roleId?: string | null;
}

export interface UpdateEvaluationBatchInput {
  deadline: OasValidateString;
  evaluationBatchId: OasValidateString;
  state: BatchState;
  term: string;
  type: BatchType;
}

export interface UpdateEvaluationGroupInput {
  evaluationGroupId: OasValidateString;
  name: OasValidateString;
  subjectCode: OasValidateString;
}

export interface UpdateOpeningHoursInput {
  openingHours?: (OpeningHoursInput | null)[] | null;
}

export interface UpdatePersonDetails {
  firstName?: string | null;
  lastName?: string | null;
  mail?: string | null;
  personId: string;
}

export interface UpdateRoleInput {
  employmentPercentage?: OasValidateNumber | null;
  reservedEmploymentPercentage?: OasValidateNumber | null;
  roleId: OasValidateString;
  title?: OasValidateString | null;
  type?: RoleType | null;
}

export interface UpdateStudentEvaluationInput {
  code: EvaluationCode;
  evaluation?: string | null;
  evaluationGroupId: OasValidateString;
  note?: string | null;
  studentTuid: OasValidateString;
}

export interface UpdateSubstituteJobInput {
  deadline?: OasValidateString | null;
  description?: OasValidateString | null;
  internalTitle?: OasValidateString | null;
  substituteJobId: OasValidateString;
}

export interface UpdateSubstituteJobInvitationTaskInput {
  inviteId: OasValidateString;
  taskId: string;
  taskResponse?: TaskResponse | null;
}

export interface UpdateSubstituteTaskInput {
  comment?: OasValidateString | null;
  date?: OasValidateString | null;
  subject?: OasValidateString | null;
  substituteTaskId: OasValidateString;
  timeFrom?: OasValidateString | null;
  timeTo?: OasValidateString | null;
}

export interface UpdateSubstituteTeacherInput {
  firstName?: OasValidateString | null;
  lastName?: OasValidateString | null;
  mail?: OasValidateString | null;
  msisdn?: OasValidateString | null;
  state?: SubstituteTeacherState | null;
  substituteTeacherId: OasValidateString;
  tags?: (string | null)[] | null;
}

export interface UpdateTimeSlot {
  endTime: OasValidateString;
  mergeTimeSlotsWithSameSplitId?: boolean | null;
  pinned: boolean;
  startTime: OasValidateString;
  timeSlotId: OasValidateString;
  weekday: OasValidateNumber;
}

export interface UpsertConfigurationInput {
  data: OasJSONObject;
  regardingTuid: OasValidateString;
  type: ConfigurationType;
}

export interface createJournalEntryInput {
  data: OasJSONObject;
  from?: OasValidateString | null;
  regardingTuid: OasValidateString;
  to?: OasValidateString | null;
  type: JournalType;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
