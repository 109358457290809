import clsx from 'clsx';

import { makeStyles, createStyles } from 'core/atoms/styles';
import { orderBy } from 'core/atoms/functions/array';

import { Union } from 'modules/planner/dna/types/union';

import { Chapter } from './chapter';

export interface ChaptersProps {
  unions?: Union[];
  selectedChapters?: string[];
  classes?: { chapter?: string; selectedChapter?: string };
  className?: string;
}

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      minWidth: 160,
    },
    union: {
      display: 'flex',
      justifyContent: 'space-between',
    },
  }),
);

export const Chapters = (props: ChaptersProps) => {
  const classes = useStyles(props);

  const { unions, className, classes: propsClasses, selectedChapters } = props;

  return (
    <div className={clsx(classes.root, className)}>
      {unions?.length
        ? unions.map((u) => (
            <div className={classes.union} key={u.id}>
              {u.chapters
                ? orderBy(u.chapters, ['name']).map((ch) => (
                    <Chapter
                      key={ch.id}
                      id={ch.id}
                      name={ch.name ?? 'noname'}
                      classes={{
                        root: propsClasses?.chapter ?? '',
                        selected: propsClasses?.selectedChapter ?? '',
                      }}
                      selected={(selectedChapters?.indexOf(ch.id) ?? -1) > -1}
                    />
                  ))
                : 'No groups defined'}
            </div>
          ))
        : 'No groups defined'}
    </div>
  );
};
