/**
=========================================================
* Material Dashboard 2 PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Material Dashboard 2 PRO React base styles
import colors from '../../base/colors';

// Material Dashboard 2 PRO React helper functions
import pxToRem from '../../functions/pxToRem';

const { transparent } = colors;

export default {
  styleOverrides: {
    select: {
      minHeight: 'auto',
    },
    //   display: 'grid',
    //   alignItems: 'center',
    //   padding: `0 ${pxToRem(12)}`,
    //
    //   '& .Mui-selected': {
    //     backgroundColor: transparent.main,
    //   },
    // },
    //
    // selectMenu: {
    //   background: 'none',
    //   height: 'none',
    //   minHeight: 'none',
    //   overflow: 'unset',
    // },
    //
    // icon: {
    //   display: 'none',
    // },
  },
};
