import { AbsenceState } from 'core/dna/types/generated/_globalTypes';

import { Absence } from 'modules/student/dna/types';
import {
  absenceCalculateTotalDays,
  absenceCalculateTotalHours,
} from 'modules/student/dna/functions/absence-calculate-total';

import { AbsenceTotal } from 'modules/reports/dna/types';
import {
  calculateTotalDaysByAbsenceState,
  calculateTotalHoursByAbsenceState,
} from 'modules/reports/dna/functions';

export const mapAbsenceListToAbsenceTotal = (
  absences: Absence[] | undefined,
): AbsenceTotal => {
  const emptyState = {
    [AbsenceState.undocumented]: 0,
    [AbsenceState.medical]: 0,
    [AbsenceState.leave]: 0,
    [AbsenceState.open]: 0,
    exempt: 0,
  };

  if (!absences?.length) {
    return {
      days: 0,
      hours: 0,
      daysByState: emptyState,
      hoursByState: emptyState,
    };
  }

  const exemptAbsences = absences.filter((a) => !!a.exempt);
  const absencesWithoutExempt = absences.filter((a) => !a.exempt);

  return {
    days: absenceCalculateTotalDays(absences),
    hours: absenceCalculateTotalHours(absences),
    daysByState: {
      [AbsenceState.undocumented]: calculateTotalDaysByAbsenceState(
        absencesWithoutExempt,
        AbsenceState.undocumented,
      ),
      [AbsenceState.medical]: calculateTotalDaysByAbsenceState(
        absencesWithoutExempt,
        AbsenceState.medical,
      ),
      [AbsenceState.leave]: calculateTotalDaysByAbsenceState(
        absencesWithoutExempt,
        AbsenceState.leave,
      ),
      [AbsenceState.open]: calculateTotalDaysByAbsenceState(
        absencesWithoutExempt,
        AbsenceState.open,
      ),
      exempt: exemptAbsences.filter((a) => !!a.wholeDay).length,
    },
    hoursByState: {
      [AbsenceState.undocumented]: calculateTotalHoursByAbsenceState(
        absences,
        AbsenceState.undocumented,
      ),
      [AbsenceState.medical]: calculateTotalHoursByAbsenceState(
        absences,
        AbsenceState.medical,
      ),
      [AbsenceState.leave]: calculateTotalHoursByAbsenceState(
        absences,
        AbsenceState.leave,
      ),
      [AbsenceState.open]: calculateTotalHoursByAbsenceState(
        absences,
        AbsenceState.open,
      ),
      exempt: exemptAbsences.filter((a) => !a.wholeDay).length,
    },
  };
};
