import { useMemo, useCallback } from 'react';
import EditIcon from '@mui/icons-material/Edit';
import ListIcon from '@mui/icons-material/List';

import { FlexBox } from 'core/cells/box';
import { Paper } from 'core/cells/paper';
import { Typography } from 'core/cells/typography';

import { useTranslations } from 'core/dna/translations';
import { useHistory, useLocale } from 'core/dna/routing';

import { createReactTableStore } from 'core/memory/pure/react-table-store';

import { useReactTableState } from 'core/metabolism/table-state';

import { Table } from 'templates/mdp/components/react-table';
import {
  Card,
  CardBody,
  CardIcon,
  CardHeader,
} from 'templates/mdp/components/card';
import { Button } from 'templates/mdp/components/custom-buttons';

import { useStyles } from 'templates/mdp/views/tables/react-tables.styles';

import PlannerUrls from 'modules/planner/routing/urls';

import { selectConflict } from 'modules/planner/memory/rx/conflicts';

import { useWorklistRead } from './use-worklist-read';

export interface ConflictsTableProps {
  scheduleId: string;
}

const conflictsTableStore = createReactTableStore({}, {});

export const Worklist = (props: ConflictsTableProps) => {
  const classes = useStyles();
  const { scheduleId } = props;
  const { columns, title, data } = useWorklistRead(scheduleId);
  const { texts } = useTranslations();
  const { push } = useHistory();
  const locale = useLocale();

  const {
    initialPage,
    pageSize,
    sort,
    filters,
    setSort,
    setFilters,
    setPage,
    setPageSize,
  } = useReactTableState(conflictsTableStore);

  const handleUpdate = useCallback(
    (lessonGroupId: string) => {
      push(PlannerUrls.editLessonGroup({ lessonGroupId, locale }));
    },
    [locale, push],
  );

  const preparedData = useMemo<any[]>(() => {
    return (
      data?.map((item) => {
        return {
          ...item,
          canBeSplit: item.canBeSplit
            ? texts.planner.common.yes
            : texts.planner.common.no,
          actions: (
            <div className="actions-right">
              <Button
                justIcon
                round
                simple
                onClick={() => handleUpdate(item.lessonGroupId)}
                color="info"
                className="edit"
              >
                <EditIcon />
              </Button>
            </div>
          ),
        };
      }) ?? []
    );
  }, [data, handleUpdate, texts]);

  const onMouseOverRow = (rowData: any) => {
    const tsId = rowData.timeSlotId;
    if (tsId) {
      selectConflict(tsId);
    }
  };

  const onMouseLeaveRow = () => {
    selectConflict(null);
  };

  return data?.length ? (
    <Card>
      <CardHeader color="warning" icon>
        <CardIcon color="warning">
          <ListIcon />
        </CardIcon>
        <h4 className={classes.cardIconTitle}>{title}</h4>
      </CardHeader>
      <CardBody>
        <Table
          key={scheduleId}
          columns={columns}
          data={preparedData}
          onChangeRowsPerPage={setPageSize}
          onChangePage={setPage}
          onFilterChange={setFilters}
          onOrderChange={setSort}
          onMouseOverRow={onMouseOverRow}
          onMouseLeaveRow={onMouseLeaveRow}
          options={{
            initialPage,
            pageSize,
            sort,
            filters,
          }}
        />
      </CardBody>
    </Card>
  ) : (
    <Paper>
      <FlexBox noSpacing={false} centeredContent p={1}>
        <Typography>{texts.planner.common.aiConflicts.noConflicts}</Typography>
      </FlexBox>
    </Paper>
  );
};
