import { useMemo, useCallback } from 'react';

import { useQuery } from 'core/dna/types/apollo';
import { ScheduleStatus } from 'core/dna/types/generated/_globalTypes';
import {
  GetScheduleDescriptions,
  GetScheduleDescriptionsVariables,
} from 'core/dna/types/generated/GetScheduleDescriptions';
import { mapNullableListToList } from 'core/dna/functions';
import { useSelectedOrganizationState } from 'core/dna/organizations';

import { useGetTermsState } from 'core/memory/apollo/terms/local';

import {
  SetSchedulesStateInput,
  ScheduleStateInput,
} from 'modules/planner/dna/types/local';
import {
  ScheduleDescriptionMap,
  ScheduleDescription,
} from 'modules/planner/dna/types/schedule';
import {
  mapApiScheduleDescriptionListToScheduleDescriptionMap,
  mapApiScheduleDescriptionListToScheduleDescriptionList,
  mapScheduleStatusToScheduleStateStatus,
} from 'modules/planner/dna/functions';

import {
  useSetSchedulesState,
  useSetSelectedScheduleState,
} from 'modules/planner/memory/apollo/schedules/local';

import { updateScheduleStorages } from 'modules/planner/metabolism/schedules';

import { GET_SCHEDULE_DESCRIPTIONS_QUERY } from './_gql';

export const useGetScheduleDescriptions = (runCondition = true) => {
  const selectedOrg = useSelectedOrganizationState();
  const { data: termsState } = useGetTermsState();
  const { setSchedulesState } = useSetSchedulesState();
  const { setSelectedScheduleState } = useSetSelectedScheduleState();

  const onCompleted = useCallback(
    (data: GetScheduleDescriptions) => {
      const input: SetSchedulesStateInput = {};
      if (data.Schedule?.length) {
        data.Schedule.forEach((s) => {
          if (
            s?.status === ScheduleStatus.starting ||
            s?.status === ScheduleStatus.running
          ) {
            input.subscribed = [
              ...new Set([...(input.subscribed ?? []), s.id]),
            ];

            const scheduleStateInput: ScheduleStateInput = {
              id: s.id,
              name: s.name,
              status: mapScheduleStatusToScheduleStateStatus(s.status),
            };
            if (
              !input.values ||
              !input.values.find((v) => v.id === scheduleStateInput.id)
            ) {
              input.values = [...(input.values ?? []), scheduleStateInput];
            }
          }
        });

        const {
          selectedSchedules: selectedSchedulesFromStorage,
          currentSchedule: currentScheduleFromStorage,
        } = updateScheduleStorages(
          mapNullableListToList(data.Schedule, (s) =>
            s === null ? undefined : s.id,
          ) ?? [],
          true,
        );

        input.selected = selectedSchedulesFromStorage;

        const currentSchedule =
          data.Schedule.find((s) =>
            s && currentScheduleFromStorage
              ? s.id === currentScheduleFromStorage
              : false,
          ) ?? undefined;

        const firstScheduleStateInput: ScheduleStateInput = {
          id: currentSchedule?.id ?? '',
          name: currentSchedule?.name ?? '',
          status: mapScheduleStatusToScheduleStateStatus(
            currentSchedule?.status ?? undefined,
          ),
        };

        if (
          !input.values ||
          !input.values.find((v) => v.id === firstScheduleStateInput.id)
        ) {
          input.values = [...(input.values ?? []), firstScheduleStateInput];
        }

        setSchedulesState(input);
        setSelectedScheduleState(currentScheduleFromStorage);
      }
    },
    [setSchedulesState, setSelectedScheduleState],
  );

  const { loading, error, data: apiData, refetch } = useQuery<
    GetScheduleDescriptions,
    GetScheduleDescriptionsVariables
  >(GET_SCHEDULE_DESCRIPTIONS_QUERY, {
    skip: !runCondition || !selectedOrg?.id || !termsState?.selected,
    variables: { termId: termsState!.selected! },
    onCompleted,
  });

  const data = useMemo<{
    list: ScheduleDescription[] | undefined;
    map: ScheduleDescriptionMap | undefined;
  }>(() => {
    if (!loading && !error && apiData) {
      return {
        list: mapApiScheduleDescriptionListToScheduleDescriptionList(
          apiData.Schedule,
        ),
        map: mapApiScheduleDescriptionListToScheduleDescriptionMap(
          apiData.Schedule,
        ),
      };
    }
    return { list: undefined, map: undefined };
  }, [apiData, error, loading]);

  return {
    loading,
    error,
    list: data.list,
    map: data.map,
    refetch,
  };
};
