import { useMemo } from 'react';

import { useGetSubstituteTeachers } from 'modules/substitute/memory/apollo/teachers/remote';

import { useTranslations } from 'core/dna/translations';
import { longNorPhoneRegex, shortNorPhoneRegex } from 'core/atoms/reg-ex';

import { TeacherTableItem } from 'modules/substitute/dna/types/teacher';

export interface UseTeachersTableReadProps {
  withActions: boolean;
}

interface Column {
  Header: string;
  accessor: string;
  disableFilters?: boolean;
  disableSortBy?: boolean;
}

export const useTeachersTableRead = (props: UseTeachersTableReadProps) => {
  const { withActions } = props;

  const { texts } = useTranslations();
  const { data: teachers, loading } = useGetSubstituteTeachers();

  const columns = useMemo(() => {
    const result: Column[] = [
      {
        Header: texts.planner.common.firstName,
        accessor: 'firstName',
      },
      {
        Header: texts.planner.common.lastName,
        accessor: 'lastName',
      },
      {
        Header: texts.oasCommon.phone,
        accessor: 'phone',
      },
      {
        Header: texts.oasCommon.email,
        accessor: 'email',
      },
      {
        Header: texts.oasCommon.tags,
        accessor: 'tags',
      },
    ];

    if (withActions) {
      result.push({
        Header: texts.planner.common.actions.many,
        accessor: 'actions',
        disableFilters: true,
      });
    }
    return result;
  }, [withActions, texts]);

  const toShortFormat = (phone: string) => {
    if (longNorPhoneRegex.test(phone)) {
      return phone.slice(2);
    }

    if (shortNorPhoneRegex.test(phone)) {
      return phone;
    }

    return '';
  };

  const data: TeacherTableItem[] = useMemo(
    () =>
      teachers?.map((t) => {
        const { id, firstName, lastName, phone, email, tags, state } = t;

        return {
          id,
          firstName,
          lastName,
          phone: phone ? toShortFormat(phone) : '',
          email,
          tags,
          state,
        };
      }) ?? [],
    [teachers],
  );

  return {
    title: texts.oasCommon.substitutes.many,
    columns,
    data,
    loading,
  };
};
