import { AUTH_HAS_PIN_CODE_KEY } from './constants';

const hasHasPinCode = () => {
  return !!localStorage.getItem(AUTH_HAS_PIN_CODE_KEY);
};

const getHasPinCode = (): boolean | null => {
  return hasHasPinCode()
    ? Boolean(localStorage.getItem(AUTH_HAS_PIN_CODE_KEY))
    : null;
};

const removeHasPinCode = () => {
  hasHasPinCode() && localStorage.removeItem(AUTH_HAS_PIN_CODE_KEY);
};

const setHasPinCode = (hasPinCode: boolean | null) => {
  if (hasPinCode) {
    localStorage.setItem(AUTH_HAS_PIN_CODE_KEY, String(hasPinCode));
  } else {
    removeHasPinCode();
  }
};

export const getHasPinCodeStorage = () => {
  return {
    key: AUTH_HAS_PIN_CODE_KEY,
    hasHasPinCode,
    getHasPinCode,
    removeHasPinCode,
    setHasPinCode,
  };
};
