import { useCallback } from 'react';
import clsx from 'clsx';
import { grey } from '@material-ui/core/colors';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';

import { makeStyles, createStyles, Theme } from 'core/atoms/styles';
import { DropFn, DraggableTypes } from 'core/atoms/types';

import { Box } from 'core/cells/box';
import { Grid } from 'core/cells/grid';
import { Typography } from 'core/cells/typography';
import { Paper } from 'core/cells/paper';

import { useTranslations } from 'core/dna/translations';
import { RoleType } from 'core/dna/types/generated/_globalTypes';

import { Button } from 'templates/mdp/components/custom-buttons';

import { ChapterSummary } from 'modules/planner/dna/types/chapter';

import {
  LessonProperty,
  DeleteLessonPropertyFn,
  LessonItem,
} from '../../../../types';
import { useLgcState } from '../../../../store';

import { LessonDroppableSection } from './lesson-droppable-section';
import { LessonPropertyList } from './lesson-property-list';
import { ChapterList } from './chapter-list';

export interface LessonProps {
  lesson: LessonItem;
  onDelete?: DeleteLessonPropertyFn;
  teachers?: LessonProperty<{ type: RoleType }>[];
  onTeacherDropped?: DropFn;
  onTeacherDelete?: DeleteLessonPropertyFn;
  rooms?: LessonProperty[];
  onRoomDropped?: DropFn;
  onRoomDelete?: DeleteLessonPropertyFn;
  chapters?: LessonProperty[];
  onChapterDropped?: DropFn;
  onChapterDelete?: DeleteLessonPropertyFn;
  className?: string;
  onSelect?: (lessonId: string) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: 400,
      border: '1px solid #cacaca',
      padding: theme.spacing(2),
      display: 'flex',
      flexDirection: 'column',
    },
    chaptersContainer: {
      display: 'flex',
    },
    chip: {
      margin: theme.spacing(0.5),
    },
    selected: {
      borderColor: grey['900'],
      borderWidth: 2,
      borderStyle: 'dashed',
    },
  }),
);

export const Lesson = (props: LessonProps) => {
  const classes = useStyles();

  const { texts } = useTranslations();

  const {
    lgcState: { selectedLessonId },
  } = useLgcState();

  const {
    lesson,
    onDelete,
    className,
    teachers,
    onTeacherDropped,
    onTeacherDelete,
    rooms,
    onRoomDropped,
    onRoomDelete,
    chapters,
    onChapterDropped,
    onChapterDelete,
    onSelect,
  } = props;

  const mixLessonId = (item: LessonProperty<any>) => ({
    ...item,
    lessonId: lesson.id,
  });

  const dropTeacher = (item: LessonProperty<{ type?: RoleType }>) => {
    onTeacherDropped && onTeacherDropped(mixLessonId(item));
  };

  const dropRoom = (item: LessonProperty<any>) => {
    onRoomDropped && onRoomDropped(mixLessonId(item));
  };

  const dropChapter = (item: LessonProperty<ChapterSummary>) => {
    onChapterDropped && onChapterDropped(mixLessonId(item));
  };

  const handleDeleteLesson = useCallback(() => {
    onDelete?.(lesson);
  }, [lesson, onDelete]);

  return (
    <Paper
      className={clsx(
        classes.root,
        className,
        selectedLessonId === lesson.id ? classes.selected : '',
      )}
      onClick={() => onSelect?.(lesson.id)}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} className="lesson-header">
          <Box display="flex" alignItems="center">
            <Typography variant="h5">{lesson.text}</Typography>
            <Button
              justIcon
              simple
              round
              color="github"
              onClick={handleDeleteLesson}
            >
              <HighlightOffIcon />
            </Button>
          </Box>
        </Grid>
        <Grid item xs={12} className="lesson-body">
          <Grid container spacing={2}>
            <Grid item xs={7}>
              <LessonDroppableSection
                label={
                  texts.planner.preparationSpace.schedulesModule.dropTeacherHere
                }
                type={DraggableTypes.Teacher}
                onDrop={dropTeacher}
              >
                {teachers && teachers.length && (
                  <LessonPropertyList
                    withAvatars
                    data={teachers}
                    onDelete={onTeacherDelete}
                    color="secondary"
                  />
                )}
              </LessonDroppableSection>
            </Grid>
            <Grid item xs={5}>
              <LessonDroppableSection
                label={
                  texts.planner.preparationSpace.schedulesModule.dropRoomHere
                }
                type={DraggableTypes.Room}
                onDrop={dropRoom}
              >
                {rooms && rooms.length && (
                  <LessonPropertyList
                    data={rooms}
                    onDelete={onRoomDelete}
                    color="primary"
                  />
                )}
              </LessonDroppableSection>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} className="lesson-footer">
          <Box className={classes.chaptersContainer}>
            <LessonDroppableSection
              label={
                texts.planner.preparationSpace.schedulesModule.dropChapterHere
              }
              type={DraggableTypes.Chapter}
              onDrop={dropChapter}
            >
              {chapters && chapters.length && (
                <ChapterList
                  data={chapters}
                  onDelete={onChapterDelete}
                  color="primary"
                />
              )}
            </LessonDroppableSection>
          </Box>
        </Grid>
      </Grid>
    </Paper>
  );
};
