import clsx from 'clsx';
import MuiTextField, {
  StandardTextFieldProps as MuiTextFieldProps,
} from '@material-ui/core/TextField';

import {
  makeStyles,
  Theme,
  createStyles,
  defaultFont,
} from 'core/atoms/styles';

interface OmittedProps {
  variant?: any;
}

export interface TextFieldProps
  extends Omit<MuiTextFieldProps, keyof OmittedProps> {
  error?: boolean;
  success?: boolean;
  white?: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      margin: '0 0 17px 0',
      // paddingTop: '27px',
      position: 'relative',
      verticalAlign: 'unset',
      '& svg,& .fab,& .far,& .fal,& .fas,& .material-icons': {
        color: theme.palette.extended?.grey[14],
      },
    },
    disabled: {
      '&:before': {
        borderColor: 'transparent !important',
      },
    },
    label: {
      ...defaultFont,
      color: (props: TextFieldProps) =>
        props.success
          ? `${theme.palette.extended?.success[0]} !important`
          : theme.palette.extended?.grey[1],
      fontWeight: 500,
      fontSize: '14px',
      lineHeight: '1.42857',
      top: '10px',
      letterSpacing: 'unset',
      '& + $underline': {
        marginTop: '0px',
      },
      '&.MuiInputLabel-shrink': {
        transform: 'translate(0, 1.5px) scale(0.85)',
      },
    },
    labelError: {
      color: theme.palette.extended?.danger[0],
    },
    input: {
      color: theme.palette.extended?.grey[14],
      height: 'unset',
      '&,&::placeholder': {
        fontSize: '14px',
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        fontWeight: '400',
        lineHeight: '1.42857',
        opacity: '1',
      },
      '&::placeholder': {
        color: theme.palette.extended?.grey[3],
      },
    },
    whiteInput: {
      '&,&::placeholder': {
        color: '#fff',
        opacity: '1',
      },
    },
    underline: {
      '&:hover:not($disabled):before,&:before': {
        borderColor: `${theme.palette.extended?.grey[4]} !important`,
        borderWidth: '1px !important',
      },
      '&:after': {
        borderColor: (props: TextFieldProps) =>
          props.error
            ? theme.palette.extended?.danger[0]
            : props.success
            ? theme.palette.extended?.success[0]
            : props.white
            ? '#fff'
            : theme.palette.primary.main,
      },
      '& + p': {
        fontWeight: '300',
      },
    },
  }),
);

export const TextField = (props: TextFieldProps) => {
  const classes = useStyles(props);

  const textFieldProps = { ...props };

  textFieldProps.className = clsx('text-field', textFieldProps.className ?? '');
  textFieldProps.classes = {
    root: clsx(classes.root, textFieldProps.classes?.root ?? ''),
  };

  const inputClasses = clsx({
    [classes.input]: true,
    [classes.whiteInput]: props.white,
  });

  return (
    <MuiTextField
      variant="standard"
      InputLabelProps={{
        classes: {
          root: classes.label,
          error: classes.labelError,
        },
      }}
      InputProps={{
        classes: {
          root: inputClasses,
          underline: classes.underline,
          disabled: classes.disabled,
        },
      }}
      {...textFieldProps}
    />
  );
};
