import { useMemo } from 'react';

import { useQuery, gql } from 'core/dna/types/apollo';
import { GetTestError } from '../../../../../dna/types/generated/GetTestError';

const GET_TEST_ERROR_QUERY = gql`
  query GetTestError {
    testError {
      forbiddenError
      validString
    }
  }
`;

export const useGetTestError = () => {
  const { loading, error, data: apiData } = useQuery<GetTestError>(
    GET_TEST_ERROR_QUERY,
    { errorPolicy: 'all' },
  );

  // const data = useMemo(() => {
  //   if (!loading && !error && apiData) {
  //     return apiData.testError;
  //   }
  // }, [apiData, error, loading]);

  return {
    loading,
    error,
    data: apiData?.testError,
  };
};
