import { useCallback } from 'react';

import { TextValue } from 'core/atoms/types';

import { TitleSelect } from 'core/cells/title-select';

import { useHistory } from 'core/dna/routing';
import { useTranslations } from 'core/dna/translations';

export interface PageSelectorProps {
  data: TextValue[];
  value: string;
}

export const PageSelector = (props: PageSelectorProps) => {
  const { data, value } = props;
  const { lang } = useTranslations();
  const { push } = useHistory();

  const handleChange = useCallback(
    (value: string) => {
      push(value);
    },
    [push],
  );

  return (
    <TitleSelect
      name="page-selector"
      placeholder={lang.common.page}
      data={data}
      value={value}
      onChange={handleChange}
    />
  );
};
