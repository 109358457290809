import { useState } from 'react';
import clsx from 'clsx';

import {
  makeStyles,
  Theme,
  createStyles,
  PropTypeColor,
} from 'core/atoms/styles';
import { DraggableType, DraggableItem, DataItem } from 'core/atoms/types';

import { Paper } from 'core/cells/paper';
import { Typography } from 'core/cells/typography';
import { Chip } from 'core/cells/chip';
import { DraggableChip } from 'core/cells/draggable-chip';
import { TextFilterField } from 'core/cells/text-filter-field';
import { Avatar } from 'core/cells/avatar';

export interface FilteredChipsProps {
  label?: string;
  withAvatars?: boolean;
  data?: DataItem<string>[];
  draggableType?: DraggableType;
  color?: Exclude<PropTypeColor, 'inherit'>;
  className?: string;
  children?: any;
  onDoubleClick?: (item: DraggableItem) => void;
}

const defaultProps: Partial<FilteredChipsProps> = {};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(2),
    },
    list: {
      display: 'flex',
      flexWrap: 'wrap',
      marginTop: theme.spacing(2),
    },
    chip: {
      margin: theme.spacing(0.5),
    },
  }),
);

export const FilteredChips = (props: FilteredChipsProps) => {
  const classes = useStyles();

  const {
    label,
    data,
    draggableType,
    color,
    className,
    onDoubleClick,
    withAvatars,
  } = props;

  const [filter, setFilter] = useState<string>('');

  if (!data || !data.length) {
    return <Typography variant="overline">No items</Typography>;
  }

  return (
    <Paper className={clsx(classes.root, className)}>
      <TextFilterField
        label={label}
        value={filter}
        onChange={(e) => {
          setFilter && setFilter(e.target.value);
        }}
        onFilterClick={() => {
          setFilter && setFilter('');
        }}
      />
      <div className={classes.list}>
        {data &&
          data
            .filter((item) =>
              filter
                ? item.text.toLowerCase().indexOf(filter.toLowerCase()) > -1
                : true,
            )
            .map((item) => {
              const chipProps: any = {
                key: item.id,
              };
              if (withAvatars) {
                chipProps.avatar = <Avatar>{item.data}</Avatar>;
              }
              return draggableType ? (
                <DraggableChip
                  {...chipProps}
                  type={draggableType!}
                  id={item.id}
                  text={item.text}
                  data={item.data}
                  color={color}
                  onDoubleClick={onDoubleClick}
                />
              ) : (
                <Chip
                  {...chipProps}
                  size="small"
                  label={item.text}
                  className={classes.chip}
                  color={color}
                />
              );
            })}
      </div>
    </Paper>
  );
};

FilteredChips.defaultProps = defaultProps;
