import React from 'react';
import moment from 'moment';

import { HowToReg } from '@material-ui/icons';
import { Skeleton } from 'core/cells/skeleton';
import { List } from '@material-ui/core';
import { ListItem } from 'core/cells/list-item';
import { ListItemText } from 'core/cells/list-item-text';
import { Divider } from 'core/cells/divider';
import { formatDateTimeString } from 'core/atoms/functions/string';
import { NoData } from 'core/organisms/no-data';

import { useTranslations } from 'core/dna/translations';

import { GridContainer, GridItem } from 'templates/mdp/components/grid';
import {
  Card,
  CardBody,
  CardHeader,
  CardIcon,
} from 'templates/mdp/components/card';
import { useStyles } from 'templates/mdp/views/tables/react-tables.styles';

import { useCountingListRead } from './use-counting-list-read';

export const CountingList = () => {
  const { texts } = useTranslations();
  const classes = useStyles();
  const { data, loading, title } = useCountingListRead();

  return loading ? (
    <Skeleton variant="rect" width="100%" height="80vh" />
  ) : (
    <GridContainer spacing={3} justify="center">
      <GridItem xs={12} lg={8} xl={6}>
        <Card>
          <CardHeader color="primary" icon>
            <CardIcon color="primary">
              <HowToReg />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>{title}</h4>
          </CardHeader>
          <CardBody>
            {!data.length ? (
              <NoData />
            ) : (
              <List>
                {data.map((student) => (
                  <React.Fragment key={student.tuid}>
                    <ListItem>
                      <div style={{ width: '100%' }}>
                        <ListItemText
                          primary={`${student.details.firstName} ${student.details.lastName}`}
                        />
                        {student.attendance.map((j, index) => {
                          const date = j.signedBy.date?.formatted;
                          return !date ? null : (
                            <ListItemText
                              key={`date-${index}`}
                              secondary={
                                j.data.present
                                  ? `${
                                      texts.oasCommon.in
                                    }: ${formatDateTimeString(
                                      moment(date).format(),
                                    )}`
                                  : `${
                                      texts.oasCommon.out
                                    }: ${formatDateTimeString(
                                      moment(date).format(),
                                    )}`
                              }
                            />
                          );
                        })}
                      </div>
                    </ListItem>
                    <Divider component="li" />
                  </React.Fragment>
                ))}
              </List>
            )}
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
};
