import { createRef, useState, useCallback } from 'react';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';

import { uniqBy } from 'core/atoms/functions/array/uniq-by';

import {
  CalendarAction,
  FullCalendarType,
  SelectionInfo,
  EventInput,
} from 'core/cells/full-calendar';

import {
  mapSelectionInfoToCalendarEvent,
  mapEventApiToCalendarEvent,
} from 'modules/planner/dna/functions';

import { createSplitDraggingStore } from './store/split-dragging';

import {
  EventConfirmationOptions,
  EventResizeInput,
  EventDropInput,
  SelectInput,
  DropInput,
  EventConfirmationCloseInput,
  EventDragStartInput,
} from './week-calendar.types';

export const splitDraggingStore = createSplitDraggingStore();

export const useWeekCalendar = () => {
  const [
    eventConfirmationOptions,
    setEventConfirmationOptions,
  ] = useState<EventConfirmationOptions>({ open: false });

  const calendarRef = createRef<FullCalendarType>();

  const addEvent = useCallback(
    (
      onAdd?: (event: EventInput) => void,
      selectionInfo?: SelectionInfo,
      title?: string,
    ) => {
      if (onAdd && selectionInfo) {
        onAdd(
          mapSelectionInfoToCalendarEvent({
            selectionInfo,
            title: title ?? 'NONAME',
          }) as EventInput,
        );
      }
    },
    [],
  );

  // const pinEvent = useCallback(
  //   (onPin?: (event: CalendarEvent) => void, event?: EventApi) => {
  //     onPin && onPin(mapEventApiToCalendarEvent(event!));
  //     // onPin && onPin(event!);
  //   },
  //   [],
  // );

  const handleTimeSelect = ({
    info: selectionInfo,
    needConfirmation,
    eventTitle,
    onAdd,
  }: SelectInput) => {
    if (needConfirmation) {
      const { start, end } = selectionInfo;
      setEventConfirmationOptions({
        open: true,
        title: 'Would you like to set "Not Available"?',
        start,
        end,
        action: CalendarAction.AddEvent,
        selectionInfo,
      });
    } else {
      addEvent(onAdd, selectionInfo, eventTitle);
    }
  };

  // const handleEventPin = ({ event, onPin }: EventPinInput) => {
  //   pinEvent(onPin, event);
  // };

  // const handleEventRender = ({ info, onPin }: EventRenderInput) => {
  //   const event = info.el;
  //   const content = event.querySelector('.fc-content');
  //   const time = content?.querySelector('.fc-time');
  //   if (!content || !time) {
  //     return;
  //     // throw new Error('No element with "fc-content" class name available');
  //   }
  //
  //   if (info?.event.extendedProps?.locked) {
  //     const lockedBlock = document.createElement('div');
  //     lockedBlock.className = 'fc-locked';
  //
  //     const locked = (
  //       <Icon
  //         name="lock-alt"
  //         prefix="fad"
  //         className={classes.lockIcon}
  //         style={{ color: info.event.textColor }}
  //         aria-label="Lock"
  //       />
  //     );
  //     ReactDOM.render(locked, lockedBlock);
  //     time.before(lockedBlock);
  //   }
  //
  //   if (withPinning) {
  //     const pinnedBlock = document.createElement('div');
  //     pinnedBlock.className = 'fc-pinned';
  //
  //     event.className = clsx(
  //       event.className,
  //       info?.event.extendedProps?.pinned ? classes.pinned : classes.notPinned,
  //     );
  //
  //     const pinned = (
  //       <IconButton
  //         name="thumbtack"
  //         prefix="fas"
  //         iconClassName={classes.pinIcon}
  //         style={{ color: info.event.textColor }}
  //         aria-label="Pin"
  //         onClick={() => {
  //           handleEventPin({
  //             event: info.event,
  //             onPin,
  //           });
  //         }}
  //       />
  //     );
  //     ReactDOM.render(pinned, pinnedBlock);
  //     content.appendChild(pinnedBlock);
  //   }
  //
  //   if (info?.event.extendedProps?.rooms) {
  //     const roomBlock = document.createElement('div');
  //     roomBlock.className = 'fc-room';
  //
  //     const roomContent = (
  //       <>
  //         <Icon className={classes.roomEventText} name="map-marker-alt" />
  //         <span className={classes.roomEventText}>
  //           {info?.event.extendedProps?.rooms}
  //         </span>
  //
  //         {/*<Avatar variant="rounded" className={classes.personAvatar}>*/}
  //         {/*  {info?.event.extendedProps?.rooms}*/}
  //         {/*</Avatar>*/}
  //       </>
  //     );
  //     ReactDOM.render(roomContent, roomBlock);
  //     content.appendChild(roomBlock);
  //   }
  //
  //   if (info?.event.extendedProps?.teachers) {
  //     const teacherBlock = document.createElement('div');
  //     teacherBlock.className = 'fc-teacher';
  //
  //     const teacherContent = (
  //       <>
  //         <Icon className={classes.roomEventText} name="user" />
  //         <span className={classes.roomEventText}>
  //           {info?.event.extendedProps?.teachers}
  //         </span>
  //
  //         {/*<Avatar className={classes.personAvatar}>*/}
  //         {/*  {info?.event.extendedProps?.teachers.charAt(0)}C*/}
  //         {/*</Avatar>*/}
  //       </>
  //     );
  //     ReactDOM.render(teacherContent, teacherBlock);
  //     content.appendChild(teacherBlock);
  //   }
  // };

  const handleEventResize = ({ info, onEdit, resizable }: EventResizeInput) => {
    resizable &&
      onEdit &&
      onEdit(mapEventApiToCalendarEvent(info.event) as EventInput);
  };

  const handleEventDrop = ({ info, onEdit }: EventDropInput) => {
    const state = splitDraggingStore.getState();
    if (state?.timeSlotId === info.event.extendedProps.timeSlotId) {
      info.event.setExtendedProp('splitDragObject', {
        duration: state?.duration,
        relativesTimeSlotIds: [...(state?.relativesTimeSlotIds ?? [])],
      });
    }
    splitDraggingStore.clear();
    onEdit && onEdit(mapEventApiToCalendarEvent(info.event));
  };

  const handleEventDragStart = ({
    info,
    onSplitDragStart,
  }: EventDragStartInput) => {
    const { event, view } = info;
    const { splitId, timeSlotId } = event.extendedProps;
    if (!splitId) {
      return;
    }
    const relatives = view.calendar
      .getEvents()
      .filter((e) => e.extendedProps.splitId === splitId);

    const evt = relatives.find(
      (e) =>
        e.extendedProps.timeSlotId === timeSlotId && e.title === event.title,
    );
    if (!evt) {
      return;
    }

    const uniqTimeSlotEvents = uniqBy(relatives, 'extendedProps.timeSlotId');

    relatives.forEach((e) => {
      if (
        !(e.extendedProps.timeSlotId === timeSlotId && e.title === event.title)
      ) {
        e.remove();
      }
    });

    const relativesTimeSlotIds = uniqTimeSlotEvents
      .map((e) => String(e.extendedProps.timeSlotId))
      .filter((id) => id !== timeSlotId);

    const duration = uniqTimeSlotEvents
      .map((ts) => moment(ts.end).diff(ts.start, 'minutes'))
      .reduce((prev, cur) => prev + cur, 0);

    // evt.setExtendedProp('splitDragObject', { duration, relativesTimeSlotIds });

    splitDraggingStore.startDragging({
      timeSlotId,
      duration,
      relativesTimeSlotIds,
    });

    // onSplitDragStart?.(
    //   mapEventApiToCalendarEvent(event),
    //   relatives,
    //   duration,
    //   relativesTimeSlotIds,
    // );
  };

  // Called only if eventData has property "create: false"
  const handleExternalEventDrop = ({ info, onAddExternal }: DropInput) => {
    const title = info.draggedEl.getAttribute('title') || '';
    const durationStr = info.draggedEl.getAttribute('data-duration') || '';
    const minutes = moment.duration(durationStr).as('minutes');
    const newEvent: EventInput = {
      id: uuidv4(),
      title,
      start: info.date,
      end: moment(info.date).add(minutes, 'm').toDate(),
    };
    onAddExternal && onAddExternal(newEvent);
  };

  const onEventConfirmationClose = ({
    newEventTitle,
    confirmed,
    onAdd,
  }: EventConfirmationCloseInput) => {
    const { action, /* event, */ selectionInfo } = eventConfirmationOptions;

    setEventConfirmationOptions({ open: false });

    if (!confirmed || !action) {
      return;
    }

    switch (action) {
      case CalendarAction.AddEvent: {
        addEvent(onAdd, selectionInfo, newEventTitle);
        break;
      }
      default:
        break;
    }
  };

  return {
    calendarRef,
    eventConfirmationOptions,
    onEventConfirmationClose,
    handleTimeSelect,
    // handleEventRender,
    handleEventResize,
    handleEventDrop,
    handleExternalEventDrop,
    handleEventDragStart,
  };
};
