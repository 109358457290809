import { mapNullableListToList } from 'core/dna/functions/map-nullable-list-to-list';
import { GetEvaluationBatch_EvaluationBatch as ApiEvaluationBatch } from 'core/dna/types/generated/GetEvaluationBatch';
import { EvaluationBatch } from 'modules/grades/dna/types';
import { mapApiEvaluationGroupToEvaluationGroupList } from 'modules/grades/dna/functions';

export const mapApiEvaluationBatchToEvaluationBatch = (
  apiEvaluationBatch: ApiEvaluationBatch | null,
): EvaluationBatch => {
  if (apiEvaluationBatch === null) {
    throw new TypeError('"apiEvaluationBatch" should not be null!');
  }

  const {
    createdAt,
    deadlineAt,
    id,
    state,
    term,
    type,
    evaluationGroups,
    updatedAt,
  } = apiEvaluationBatch;

  return {
    id,
    type,
    term,
    createdAt: createdAt?.formatted ?? null,
    updatedAt: updatedAt?.formatted ?? null,
    deadlineAt: deadlineAt?.formatted ?? null,
    state,
    evaluationGroups: mapApiEvaluationGroupToEvaluationGroupList(
      evaluationGroups,
    ),
  };
};

export const mapApiEvaluationBatchToEvaluationBatchList = (
  apiEvaluationBatchList: (ApiEvaluationBatch | null)[] | null,
): EvaluationBatch[] | undefined => {
  return mapNullableListToList<ApiEvaluationBatch, EvaluationBatch>(
    apiEvaluationBatchList,
    mapApiEvaluationBatchToEvaluationBatch,
  );
};
