import moment, { Moment } from 'moment';
import { isMoment as isMomentType } from '../functions/type-check';

function isMoment(date: Date | Moment | string): date is Moment {
  return isMomentType(date);
}

export function getMoment(date: Date | Moment | string, throwError?: boolean) {
  const d = isMoment(date) ? date : moment(date);
  if (!d.isValid()) {
    if (throwError) {
      throw new Error(`Date ${d.toISOString()} is not valid!`);
    } else {
      return null;
    }
  }
  return d;
}

export const getWeek = (date: Date | Moment, throwError?: boolean) => {
  const m = getMoment(date, throwError);
  return m ? m.isoWeek() : null;
};

export const getWeeksInYear = (date: Date | Moment) => {
  const m = getMoment(date, true);
  return m!.isoWeeksInYear();
};

export const getYear = (date: Date | Moment) => {
  const d = getMoment(date, true);
  return d!.isoWeekYear();
};

export const getMondayOfWeek = (week: number, year: number) =>
  moment()
    .day('Monday')
    .isoWeekYear(year)
    .isoWeek(week)
    .hours(0)
    .minutes(0)
    .seconds(0)
    .milliseconds(0)
    .toDate();
