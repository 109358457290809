import { logger, LogType } from 'core/atoms/housekeeping';
import { AnyMap } from 'core/atoms/types';

import { TreeDataItem, TreeNode } from 'core/cells/tree-view';
import { SyncStudent } from 'modules/planner/dna/types/sync-student';
import { SyncGroup } from 'modules/planner/dna/types/sync-group';

const FILE_NAME = 'map-sync-groups-and-students-to-tree-data-item-map';

export const getStudentId = (groupId: string, studentId: string) =>
  `${groupId}_student_${studentId}`;

export const mapSyncGroupsAndStudentsToTreeDataItemMap = (
  groups: SyncGroup[],
  students: SyncStudent[],
):
  | AnyMap<TreeDataItem<{ groupId: string; studentId?: string; tuid?: string }>>
  | undefined => {
  const map: AnyMap<
    TreeDataItem<{ groupId: string; studentId?: string; tuid?: string }>
  > = {
    byId: {},
    allIds: [],
  };

  logger.debug({
    title: 'Groups mapping -> start',
    logger: FILE_NAME,
    type: LogType.Info,
  });

  function filterGroups(group: SyncGroup) {
    return (
      group.name.trim().length < 5 ||
      /^([1-9]|10)((\s|.)|.\s)(klasse|trinn)$/.test(group.name.toLowerCase()) ||
      /^(klasse|trinn)\s?([1-9]|10)((\s|.)|.\s)$/.test(group.name.toLowerCase())
    ); // RegExp for all grades from 1 to 10, add these variations: “1 Klasse”, “1. Klasse“, “1.Klasse“, “1 Trinn“, “1. Trinn“, ”1.Trinn”
  }

  const filteredGroups = groups.filter(filterGroups);
  filteredGroups.forEach((g) => {
    if (!map.byId[g.id]) {
      map.byId[g.id] = {
        id: g.id,
        text: g.name,
        data: { groupId: g.id },
        parent: g.parentId,
        state: 'unchecked',
        children: [],
      };
      if (map.allIds.indexOf(g.id) < 0) {
        map.allIds.push(g.id);
      }
    }
  });

  logger.debug({
    title: 'Groups mapping -> set parent-child relations',
    logger: FILE_NAME,
    type: LogType.Info,
  });

  map.allIds.forEach((gId) => {
    const parentId = map.byId[gId].parent;
    if (parentId && map.byId[parentId]) {
      map.byId[parentId].children?.push(gId);
    }
  });

  logger.debug({
    title: 'Students mapping -> start',
    logger: FILE_NAME,
    type: LogType.Info,
  });

  students.forEach((s) => {
    s.groups.forEach((sg) => {
      const id = getStudentId(sg.id, s.id);
      const parent = map.byId[sg.id];
      if (parent && !map.byId[id]) {
        map.byId[id] = {
          id,
          text: `${s.firstName} ${s.lastName}`,
          data: { groupId: parent.id, studentId: s.id, tuid: s.tuid },
          parent: parent.id,
          state: 'unchecked',
        };
        if (map.allIds.indexOf(id) < 0) {
          map.allIds.push(id);
        }
        parent.children?.push(id);
      }
    });
  });

  logger.debug({
    title: 'Students mapping -> end',
    logger: FILE_NAME,
    type: LogType.Info,
  });

  return map;
};

const mapSyncGroupToTreeNode = (
  group: SyncGroup,
): TreeNode<{ groupId: string; studentId?: string; tuid?: string }> => ({
  id: group.id,
  text: group.name,
  data: { groupId: group.id },
  children: [],
});

const mapSyncStudentToTreeNode = (
  student: SyncStudent,
  id: string,
  parentId: string,
): TreeNode<{ groupId: string; studentId?: string; tuid?: string }> => ({
  id,
  text: `${student.firstName} ${student.lastName}`,
  data: { groupId: parentId, studentId: student.id, tuid: student.tuid },
  parent: parentId,
});

export const mapSyncGroupsAndStudentsToTreeNodes = (
  groups: SyncGroup[],
  students: SyncStudent[],
):
  | TreeNode<{ groupId: string; studentId?: string; tuid?: string }>[]
  | undefined => {
  function filterGroups(group: SyncGroup) {
    return (
      group.name.trim().length < 5 ||
      /^([1-9]|10)((\s|.)|.\s)(klasse|trinn)$/.test(group.name.toLowerCase()) ||
      /^(klasse|trinn)\s?([1-9]|10)((\s|.)|.\s)$/.test(group.name.toLowerCase())
    ); // RegExp for all grades from 1 to 10, add these variations: “1 Klasse”, “1. Klasse“, “1.Klasse“, “1 Trinn“, “1. Trinn“, ”1.Trinn”
  }

  const classNodes = groups.filter(filterGroups).map(mapSyncGroupToTreeNode);

  students.forEach((s) => {
    s.groups.forEach((sg) => {
      const id = getStudentId(sg.id, s.id);
      const parent = classNodes.find((cn) => cn.id === sg.id);
      if (parent) {
        parent.children?.push(mapSyncStudentToTreeNode(s, id, parent.id));
      }
    });
  });
  return classNodes;
};
