import React from 'react';

import { Grid } from 'core/cells/grid';
import { useParams } from 'core/dna/routing';
import { Skeleton } from 'core/cells/skeleton';

import { WizardNoValidation } from 'modules/student/organisms/wizard-no-validation';
import { useGetStudentDetails } from 'modules/student/memory/apollo/students/remote/get-student-details';
import { PersonT2 } from 'modules/student/dna/types';

import { useCardContent } from './components/use-card-content';

export const StudentCardMainContent = () => {
  const { cardId } = useParams<{ cardId: string }>();

  const { data, loading, error } = useGetStudentDetails({
    tuid: cardId,
  });

  const { getContent } = useCardContent({ data, error });

  const getTitle = (data: PersonT2 | undefined) =>
    !data ? '' : `${data.details.firstName} ${data.details.lastName}`;

  if (loading || !data) {
    return <Skeleton variant="rect" width="100%" height="80vh" />;
  }

  return (
    <Grid container justifyContent="center">
      <Grid item xs={12}>
        <WizardNoValidation steps={getContent()} title={getTitle(data)} />
      </Grid>
    </Grid>
  );
};
