import { useCallback } from 'react';

import { FlexBox } from 'core/cells/box';
import { Field, Form, FormController } from 'core/cells/forms';
import { FormTextField } from 'core/cells/form-text-field';
import { MenuItem } from 'core/cells/menu-item';

import { useTranslations } from 'core/dna/translations';
import {
  BatchState,
  BatchType,
  UpdateEvaluationBatchInput,
} from 'core/dna/types/generated/_globalTypes';

import { Button } from 'templates/mdp/components/custom-buttons';

import { WriteModeEnum } from 'core/dna/types/write-mode';

import { WriteTableModal } from 'core/organisms/write-modal';

import { useBatchTableWrite } from 'modules/grades/metabolism';

export interface BatchCreatorData extends UpdateEvaluationBatchInput {
  deadline: string;
  state: BatchState;
  term: string;
  type: BatchType;
  evaluationBatchId: string;
}

interface BatchCreatorProps {
  writeMode: WriteModeEnum;
  data: BatchCreatorData;
  open: boolean;
  setOpen: (open: boolean) => void;
  // onSubmit?: (id: string) => void;
}

interface Values {
  deadline: string;
  term: string;
  type: string;
  state: string;
}

export const BatchCreator = (props: BatchCreatorProps) => {
  const { data, open, setOpen, writeMode } = props;
  const { onRowAdd, onRowUpdate } = useBatchTableWrite();
  const { texts } = useTranslations();

  const batchTypes = [
    {
      label: texts.grades.gradesCommon.batchType.halfYear,
      value: BatchType.halfYear,
    },
    {
      label: texts.grades.gradesCommon.batchType.fullYear,
      value: BatchType.fullYear,
    },
    {
      label: texts.grades.gradesCommon.batchType.exam,
      value: BatchType.exam,
    },
    {
      label: texts.grades.gradesCommon.batchType.final,
      value: BatchType.final,
    },
  ];

  const batchStates = [
    {
      label: texts.grades.gradesCommon.bathchState.draft,
      value: BatchState.draft,
    },
    {
      label: texts.grades.gradesCommon.bathchState.open,
      value: BatchState.open,
    },
    {
      label: texts.grades.gradesCommon.bathchState.closed,
      value: BatchState.closed,
    },
    {
      label: texts.grades.gradesCommon.bathchState.published,
      value: BatchState.published,
    },
    {
      label: texts.grades.gradesCommon.bathchState.locked,
      value: BatchState.locked,
    },
  ];

  const onClose = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  const handleSubmit = async (values: Values) => {
    if (writeMode === WriteModeEnum.update) {
      await onRowUpdate({
        evaluationBatchId: data.evaluationBatchId,
        state: values.state as BatchState,
        deadline: values.deadline,
        term: values.term,
        type: values.type as BatchType,
      });
      setOpen(false);
    } else {
      await onRowAdd({
        state: data.state,
        deadline: values.deadline,
        term: values.term,
        type: values.type as BatchType,
      });
      setOpen(false);
    }
  };

  return (
    <WriteTableModal
      width={420}
      isShow={open ?? false}
      onClose={onClose}
      writeMode={writeMode}
      title={
        writeMode === WriteModeEnum.create
          ? texts.grades.prepareBatch.newRequest
          : texts.planner.common.edit
      }
    >
      <FormController
        initialValues={{
          deadline: data.deadline,
          term: data.term,
          type: data.type,
          state: data.state,
        }}
        validate={(values) => {
          const errors: Partial<Values> = {};
          if (!values.term) {
            errors.term = texts.oasCommon.validation.required;
          }
          if (!values.deadline) {
            errors.deadline = texts.oasCommon.validation.required;
          }
          if (writeMode === WriteModeEnum.update && !values.state) {
            errors.state = texts.oasCommon.validation.required;
          }
          return errors;
        }}
        onSubmit={async (values) => {
          await handleSubmit(values);
        }}
      >
        {({ submitForm, isSubmitting }) => (
          <Form>
            <Field
              select
              component={FormTextField}
              name="type"
              type="text"
              label={texts.oasCommon.type}
              disabled={isSubmitting}
              fullWidth
            >
              {batchTypes.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Field>
            {writeMode === WriteModeEnum.update && (
              <Field
                select
                component={FormTextField}
                name="state"
                type="text"
                label="State"
                disabled={isSubmitting}
                fullWidth
              >
                {batchStates.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </Field>
            )}
            <Field
              component={FormTextField}
              name="term"
              type="text"
              label={texts.grades.gradesCommon.subTerm}
              disabled={isSubmitting}
              fullWidth
            />
            <Field
              component={FormTextField}
              name="deadline"
              type="datetime-local"
              label={texts.oasCommon.deadline}
              disabled={isSubmitting}
              fullWidth
              inputProps={{
                step: 900,
              }}
            />
            <FlexBox justifyContent="flex-end">
              <Button
                color="secondary"
                disabled={isSubmitting}
                onClick={submitForm}
              >
                {texts.planner.common.add}
              </Button>
              <Button onClick={onClose} color="secondary" simple>
                {texts.planner.common.close}
              </Button>
            </FlexBox>
          </Form>
        )}
      </FormController>
    </WriteTableModal>
  );
};
