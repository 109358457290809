import { useCallback, useMemo } from 'react';

import { ReactTableStore } from 'core/memory/pure/react-table-store';

export const useReactTableState = (
  tableStore: ReactTableStore,
  id?: string,
) => {
  const initialPage = useMemo(
    () => (id ? tableStore.getPage(id) : tableStore.initialPage),
    [id, tableStore],
  );

  const pageSize = useMemo(
    () => (id ? tableStore.getPageSize(id) : tableStore.initialPageSize),
    [id, tableStore],
  );

  const sort = useMemo(() => (id ? tableStore.getSort(id) : undefined), [
    id,
    tableStore,
  ]);

  const filters = useMemo(() => (id ? tableStore.getFilters(id) : undefined), [
    id,
    tableStore,
  ]);

  const setSort = useCallback(
    (columnId: string, isSortedDesc: boolean) => {
      id && tableStore.setSort(id, { id: columnId, desc: isSortedDesc });
    },
    [id, tableStore],
  );

  const setFilters = useCallback(
    (columnId: string, value: string) => {
      id && tableStore.setFilters(id, { id: columnId, value });
    },
    [id, tableStore],
  );

  const setPageSize = useCallback(
    (pageSize: number) => {
      id && tableStore.setPageSize(id, pageSize);
    },
    [id, tableStore],
  );

  const setPage = useCallback(
    (page: number) => {
      id && tableStore.setPage(id, page);
    },
    [id, tableStore],
  );

  return {
    initialPage,
    pageSize,
    sort,
    filters,
    setPage,
    setSort,
    setFilters,
    setPageSize,
  };
};
