import { useCallback } from 'react';

import { OasError } from 'core/atoms/errors/oas-error';
import { OasMemoryError } from 'core/atoms/errors';

import { ApolloError, useMutation, gql } from 'core/dna/types/apollo';
import {
  CreateTeacherFromSyncPerson,
  CreateTeacherFromSyncPersonVariables,
} from 'core/dna/types/generated/CreateTeacherFromSyncPerson';
import { RoleType } from 'core/dna/types/generated/_globalTypes';

import { useLazyGetTermsState } from 'core/memory/apollo/terms/local';

import { GET_TEACHERS_QUERY } from 'modules/planner/memory/apollo/teachers/remote';

export interface CreateTeachersFromSyncPersonsResult {
  createTeacherFromSyncPerson: (id: string) => Promise<any>;
  loading: boolean;
  error?: ApolloError;
}

const CREATE_TEACHER_FROM_SYNC_PERSON_MUTATION = gql`
  mutation CreateTeacherFromSyncPerson(
    $input: CreatePersonAndRoleFromSyncPersonInput
  ) {
    createPersonAndRoleFromSyncPerson(input: $input) {
      personId
      roleId
    }
  }
`;

const TITLE = 'use-create-teacher-from-sync-person';

export const useCreateTeacherFromSyncPerson = (): CreateTeachersFromSyncPersonsResult => {
  const { getTermState } = useLazyGetTermsState();

  const [create, { loading, error }] = useMutation<
    CreateTeacherFromSyncPerson,
    CreateTeacherFromSyncPersonVariables
  >(CREATE_TEACHER_FROM_SYNC_PERSON_MUTATION);

  const func = useCallback(
    async (id: string) => {
      try {
        const termsState = await getTermState(true);

        const result = await create({
          variables: {
            input: {
              syncPersonId: id,
              termId: termsState!.selected!,
              roleType: RoleType.teacher,
              title: 'Teacher',
            },
          },
          refetchQueries: [
            {
              query: GET_TEACHERS_QUERY,
              variables: { termId: termsState!.selected! },
            },
          ],
        });

        if (result.errors) {
          throw new OasMemoryError(JSON.stringify(result.errors), {
            title: TITLE,
          });
        }
        return result.data?.createPersonAndRoleFromSyncPerson ?? undefined;
      } catch (e: any) {
        if (e instanceof OasError) {
          throw e;
        } else {
          throw OasMemoryError.fromError(e, {
            title: TITLE,
          });
        }
      }
    },
    [create, getTermState],
  );

  return {
    createTeacherFromSyncPerson: func,
    loading,
    error,
  };
};
