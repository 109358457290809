import { gql } from 'core/dna/types/apollo';

export const PERSON_T2_DETAILS_FRAGMENT = gql`
  fragment PersonDetailsT2 on PersonDetailsT2 {
    firstName
    lastName
    groups {
      id
      name
    }
  }
`;

export const PERSON_CONFIGURATION_FRAGMENT = gql`
  fragment Configuration on Configuration {
    data
    type
  }
`;

export const PERSON_T2_CARER_DETAILS_FRAGMENT = gql`
  fragment PersonT2CarerDetail on PersonT2CarerDetail {
    firstName
    lastName
    mail
    mobile
  }
`;

export const PERSON_T2_CARERS_FRAGMENT = gql`
  fragment PersonT2Carer on PersonT2Carer {
    tuid
    details {
      ...PersonT2CarerDetail
    }
  }
  ${PERSON_T2_CARER_DETAILS_FRAGMENT}
`;

export const ABSENCE_PERSON_DETAIL_FRAGMENT = gql`
  fragment AbsencePersonDetail on AbsencePersonDetail {
    firstName
    lastName
  }
`;

export const ABSENCE_PERSON_FRAGMENT = gql`
  fragment AbsencePerson on AbsencePerson {
    tuid
    details {
      ...AbsencePersonDetail
    }
  }
  ${ABSENCE_PERSON_DETAIL_FRAGMENT}
`;

export const ABSENCE_ORIGIN_FRAGMENT = gql`
  fragment AbsenceOrigin on AbsenceOrigin {
    allDay
    collectionId
    comment
    from {
      formatted
    }
    to {
      formatted
    }
    id
    tags
    type
  }
`;

export const ABSENCE_DETAILS_FRAGMENT = gql`
  fragment Absence on Absence {
    id
    from {
      formatted
    }
    to {
      formatted
    }
    wholeDay
    locked
    exempt
    state
    regarding {
      ...AbsencePerson
    }
    signedBy {
      ...AbsencePerson
    }
    origin {
      ...AbsenceOrigin
    }
  }
  ${ABSENCE_PERSON_FRAGMENT}
  ${ABSENCE_ORIGIN_FRAGMENT}
`;

export const REMARK_PERSON_DETAIL_FRAGMENT = gql`
  fragment RemarkPersonDetail on RemarkPersonDetail {
    firstName
    lastName
  }
`;

export const REMARK_PERSON_FRAGMENT = gql`
  fragment RemarkPerson on RemarkPerson {
    tuid
    details {
      ...RemarkPersonDetail
    }
  }
  ${REMARK_PERSON_DETAIL_FRAGMENT}
`;

export const REMARK_DETAILS_FRAGMENT = gql`
  fragment Remark on Remark {
    id
    occurredAt {
      formatted
    }
    note
    type
    regarding {
      ...RemarkPerson
    }
    signedBy {
      ...RemarkPerson
    }
  }
  ${REMARK_PERSON_FRAGMENT}
`;

export const JOURNAL_PERSON_FRAGMENT = gql`
  fragment JournalPerson on JournalPerson {
    date {
      formatted
    }
    firstName
    lastName
    tuid
  }
`;

export const JOURNAL_ENTRY_FRAGMENT = gql`
  fragment JournalEntry on JournalEntry {
    id
    data
    type
    signedBy {
      ...JournalPerson
    }
    from {
      formatted
    }
    to {
      formatted
    }
  }
  ${JOURNAL_PERSON_FRAGMENT}
`;
