import { mapStringListToString } from 'core/dna/functions';
import { OasLocales } from 'core/dna/translations/types';

import {
  LessonGroup,
  LessonGroupTableItem,
} from 'modules/planner/dna/types/lesson-group';
import { getEmployeeTypedName } from '../get-employee-typed-name';
import { extractChaptersStringFromLessonGroup } from '../extract-chapters-string-from-lesson-group';

export const mapLessonGroupToLessonGroupTableItem = (
  lessonGroup: LessonGroup,
  texts: OasLocales,
): LessonGroupTableItem => {
  const { id, plannedMinutesPerTimeSlot, canBeSplit, lessons } = lessonGroup;

  return {
    id,
    plannedMinutesPerTimeSlot: `[${plannedMinutesPerTimeSlot?.join(', ')}]`,
    canBeSplit,
    subjects: mapStringListToString(
      lessons
        ?.map((l) => l.subjects || [])
        .reduce((prev, cur) => {
          return [...prev, ...cur];
        }, [])
        .map((sd) => sd.name),
    ),
    teachers: mapStringListToString(
      lessons
        ?.map(
          (l) =>
            l.teachers?.map((t) => {
              return getEmployeeTypedName({
                name: t.text,
                type: t.data!.type,
                texts,
              });
            }) || [],
        )
        .reduce((prev, cur) => {
          return [...prev, ...cur];
        }, []),
    ),
    rooms: mapStringListToString(
      lessons
        ?.map((l) => l.rooms?.map((r) => r.text) || [])
        .reduce((prev, cur) => {
          return [...prev, ...cur];
        }, []),
    ),
    chapters: extractChaptersStringFromLessonGroup(lessonGroup),
  };
};
