import { useState, useEffect, useRef, useCallback } from 'react';

import { runtimeEnv } from 'core/atoms/env';

import { RouteConfig, OrganizationLevel } from 'core/dna/types/local';
import {
  ModuleGoHomeContext,
  ModuleRoutesContext,
  useLocale,
} from 'core/dna/routing';
import { useTranslations } from 'core/dna/translations';
import { useSelectedModuleState } from 'core/dna/modules';

import { useOrganization } from 'core/metabolism/organizations';

import { getDataRoutes } from 'app/routes';
import { useAuthContext } from '../../../auth/metabolism/use-auth-context';
import { Modules } from '../../../core/dna/types/modules';
import PlannerUrls from '../../../modules/planner/routing/urls';
import StudentUrls from '../../../modules/student/urls';
import SubstituteUrls from '../../../modules/substitute/urls';
import UnsetUrls from '../../../modules/unset/urls';
import ReportsUrls from '../../../modules/reports/urls';
import GradesUrls from '../../../modules/grades/urls';
import { useOasHistory } from '../../../core/metabolism/use-oas-history';
import { useRouteModule } from '../../routes';
import { useAccessRolesState } from '../../../core/dna/access-roles';

const env = runtimeEnv();

const t1Uri = String(env.REACT_APP_T1_URI);
const t2Uri = String(env.REACT_APP_T2_URI);

export const ModuleRoutesProvider = (props: any) => {
  const { children } = props;

  const locale = useLocale();
  const { texts } = useTranslations();
  const { replace } = useOasHistory();

  const selected = useSelectedModuleState();
  const { organization } = useOrganization();
  const myAccessRoles = useAccessRolesState();

  const [routes, setRoutes] = useState<RouteConfig[]>([]);
  const prevSelectedModuleId = useRef('');
  const prevSelectedOrgId = useRef('');
  const { getIdentity } = useAuthContext();
  const { getSelectedModuleFromRoute } = useRouteModule();

  const goHome = useCallback(
    (id?: string, level?: OrganizationLevel) => {
      const module = id ?? selected?.id ?? '';
      switch (module) {
        case Modules.planner:
          replace(PlannerUrls.home(locale));
          break;
        case Modules.student:
          replace(StudentUrls.home(locale));
          break;
        case Modules.substitute:
          replace(SubstituteUrls.home(locale));
          break;
        case Modules.unset:
          replace(UnsetUrls.home(locale));
          break;
        case Modules.reports:
          replace(ReportsUrls.home(locale));
          break;
        case Modules.grades:
          replace(GradesUrls.home(locale));
          break;
        case Modules.sms:
          window.location.replace(t1Uri);
          break;
        case Modules.messageBook:
          window.location.replace(t2Uri);
          break;
        default:
          break;
      }
    },
    [locale, replace, selected],
  );

  useEffect(() => {
    const identity = getIdentity();
    if (!identity) {
      const mId = getSelectedModuleFromRoute();
      if (mId && mId !== prevSelectedModuleId.current) {
        prevSelectedModuleId.current = mId;
        setRoutes(getDataRoutes({ module: mId, locale, texts }));
      }
    } else if (
      selected?.id &&
      organization?.id &&
      (selected.id !== prevSelectedModuleId.current ||
        organization.id !== prevSelectedOrgId.current)
    ) {
      prevSelectedModuleId.current = selected.id;
      prevSelectedOrgId.current = organization.id;
      setRoutes(
        getDataRoutes({
          module: selected.id,
          locale,
          texts,
          organization,
          myAccessRoles,
        }),
      );
    }
  }, [
    getIdentity,
    getSelectedModuleFromRoute,
    locale,
    organization,
    selected,
    texts,
    myAccessRoles,
  ]);

  return (
    <ModuleRoutesContext.Provider value={routes}>
      <ModuleGoHomeContext.Provider value={goHome}>
        {children}
      </ModuleGoHomeContext.Provider>
    </ModuleRoutesContext.Provider>
  );
};
