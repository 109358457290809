import { gql } from 'core/dna/types/apollo';

import { ROLE_DATA_FRAGMENT } from 'modules/planner/memory/apollo/_fragments';

export const GET_TEACHERS_QUERY = gql`
  query GetTeachers($termId: String!) {
    Role(
      filter: { terms: { id: $termId }, type_in: [teacher, assistant, staff] }
    ) {
      ...RoleData
    }
  }
  ${ROLE_DATA_FRAGMENT}
`;
