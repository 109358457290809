import { ReactNode } from 'react';
import clsx from 'clsx';

import Dialog from '@material-ui/core/Dialog';

import { PriorityHigh } from '@material-ui/icons';

import { ConfirmationAutoFocus } from 'core/atoms/types';

import { FlexBox } from 'core/cells/box';

import { Button } from 'templates/mdp/components/custom-buttons';
import { useStyles as useTablesStyles } from 'templates/mdp/views/tables/react-tables.styles';
import { useStyles } from 'templates/mdp/views/components/notifications.styles';
import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  CardIcon,
} from 'templates/mdp/components/card';

export interface ConfirmationDialogProps {
  id: string;
  open: boolean;
  onClose: (isConfirmed: boolean) => void;
  title?: ReactNode;
  content: ReactNode;
  okText?: string;
  cancelText?: string;
  autoFocus?: ConfirmationAutoFocus;
}

const defaultProps: Partial<ConfirmationDialogProps> = {
  okText: 'OK',
  cancelText: 'Cancel',
  autoFocus: ConfirmationAutoFocus.None,
};

export const ConfirmationDialog = (props: ConfirmationDialogProps) => {
  const {
    onClose,
    open,
    title,
    content,
    id,
    okText,
    cancelText,
    autoFocus,
  } = props;

  const classesTable = useTablesStyles();
  const classes = useStyles();

  const cancelAutoFocus = autoFocus === ConfirmationAutoFocus.Cancel;
  const okAutoFocus = autoFocus === ConfirmationAutoFocus.Ok;

  function handleCancel() {
    onClose(false);
  }

  function handleOk() {
    onClose(true);
  }

  return (
    <Dialog
      classes={{
        root: classes.modalRoot,
        paper: classes.modal,
      }}
      id={id}
      disableBackdropClick
      hideBackdrop
      open={open}
      onEscapeKeyDown={handleCancel}
      keepMounted
      aria-labelledby={`${id}-title`}
      aria-describedby={`${id}-description`}
      PaperComponent={() => (
        <div style={{ width: 400 }}>
          <Card>
            <CardHeader color="primary" icon>
              <CardIcon color="primary">
                <PriorityHigh />
              </CardIcon>
              {title && <h4 className={classesTable.cardIconTitle}>{title}</h4>}
            </CardHeader>
            <CardBody className={clsx(classes.modalBody, classes.center)}>
              {content}
            </CardBody>
            <CardFooter>
              <FlexBox justifyContent="flex-end">
                <Button
                  color="danger"
                  onClick={handleCancel}
                  autoFocus={cancelAutoFocus}
                  style={{ marginRight: 15 }}
                >
                  {cancelText}
                </Button>
                <Button
                  color="success"
                  onClick={handleOk}
                  autoFocus={okAutoFocus}
                >
                  {okText}
                </Button>
              </FlexBox>
            </CardFooter>
          </Card>
        </div>
      )}
    />
  );
};

ConfirmationDialog.defaultProps = defaultProps;
