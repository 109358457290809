import { useState, useEffect } from 'react';

import { useTranslations } from 'core/dna/translations';

import { Schedule } from 'modules/planner/dna/types/schedule';

import { LessonItemsData } from '../../types';

import { LessonGroupCreator } from '../lesson-group-creator';

import { getLessonItems } from './get-lesson-items';

export interface LessonGroupEditorProps {
  schedule?: Schedule;
  lessonGroupId?: string;
}

export const LessonGroupEditor = (props: LessonGroupEditorProps) => {
  const { schedule, lessonGroupId } = props;
  const [data, setData] = useState<LessonItemsData | undefined>();
  const { texts } = useTranslations();

  useEffect(() => {
    const lessonGroup = schedule?.lessonGroups?.find(
      (lg) => lg.id === lessonGroupId,
    );
    if (lessonGroup) {
      const items = getLessonItems(lessonGroup, texts);
      setData(items);
    }
  }, [lessonGroupId, schedule, texts]);

  return <LessonGroupCreator data={data} />;
};
