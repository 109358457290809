import { useCallback } from 'react';

import { OasError } from 'core/atoms/errors/oas-error';
import { OasMemoryError } from 'core/atoms/errors';

import {
  ApolloError,
  useMutation,
  useApolloClient,
} from 'core/dna/types/apollo';
import { GetTermsState } from 'core/dna/types/generated/GetTermsState';

import { GET_TERMS_STATE_QUERY } from 'core/memory/apollo/terms/local/graphql';

import { GET_ROOMS_QUERY } from '../graphql';
import { CREATE_ROOM_MUTATION } from './create-room-mutation';

export interface CreateRoomInput {
  name: string;
  alias?: string;
  note?: string;
}

export interface CreateRoomResult {
  createRoom: (input: CreateRoomInput) => Promise<any>;
  loading: boolean;
  error?: ApolloError;
}

const TITLE = 'Create Room';

export const useCreateRoom = (): CreateRoomResult => {
  const [createRoom, { loading, error }] = useMutation(CREATE_ROOM_MUTATION);
  const client = useApolloClient();

  const func = useCallback(
    (input: CreateRoomInput) => {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise<any>(async (resolve, reject) => {
        try {
          const getTermStateResult = await client.query<GetTermsState>({
            query: GET_TERMS_STATE_QUERY,
          });

          const termId = getTermStateResult.data?.termsState?.selected;

          if (!termId) {
            throw new OasMemoryError('Term is not set!', { title: TITLE });
          }

          const createRoomResult = await createRoom({
            variables: {
              input: {
                ...input,
                termId: termId!,
              },
            },
            refetchQueries: [
              {
                query: GET_ROOMS_QUERY,
                variables: { termId: termId! },
              },
            ],
          });

          if (createRoomResult.data && !createRoomResult.errors) {
            resolve(createRoomResult);
          } else {
            reject(new OasMemoryError('Create room error!', { title: TITLE }));
          }
        } catch (e: any) {
          if (e instanceof OasError) {
            reject(e);
          } else {
            reject(OasMemoryError.fromError(e, { title: TITLE }));
          }
        }
      });
    },
    [client, createRoom],
  );

  return {
    createRoom: func,
    loading,
    error,
  };
};
