import React from 'react';

import { Typography } from '@material-ui/core';
import { Grid } from 'core/cells/grid';
import { Skeleton } from 'core/cells/skeleton';
import { BatchState, BatchType } from 'core/dna/types/generated/_globalTypes';
import { NoData } from 'core/organisms/no-data';
import { useParams } from 'core/dna/routing';
import { formatDateTimeString } from 'core/atoms/functions/string';
import { dateTimeShortForTableSorting } from 'core/atoms/date-time/formats';
import { useTranslations } from 'core/dna/translations';

import { useIdentity } from 'auth/metabolism';

import { useGetEvaluationGroup } from 'modules/grades/memory/apollo/evaluation/remote';

import { EvaluationsTable } from './components/evaluations-table';

export const EvaluationGroupMainContent = () => {
  const { texts } = useTranslations();
  const identity = useIdentity();
  const { batchId, subjectCode } = useParams<{
    batchId: string;
    subjectCode: string;
  }>();

  const { loading, data } = useGetEvaluationGroup({
    batchState: BatchState.open,
    teacherTuid: identity?.sub,
    subjectCode,
    batchId,
  });

  const createTitle = () => {
    if (!data?.length) return '';

    const { type, deadlineAt } = data[0].evaluationBatch;

    return `Oversikt for ${
      texts.grades.gradesCommon.batchType[type as BatchType]
    } i ${subjectCode} med svarfrist ${formatDateTimeString(
      deadlineAt || '',
      dateTimeShortForTableSorting,
    )}`;
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        {loading && <Skeleton variant="rect" width="100%" height="80vh" />}
        {!loading && !data?.length ? (
          <NoData text={texts.grades.assessment.noData} />
        ) : (
          <div>
            <Typography variant="h5" color="textSecondary" align="center">
              {createTitle()}
            </Typography>
            {data?.map((evaluationGroup) => (
              <EvaluationsTable
                title={evaluationGroup.name || ''}
                evaluationGroup={evaluationGroup}
                key={evaluationGroup.id}
              />
            ))}
          </div>
        )}
      </Grid>
    </Grid>
  );
};
