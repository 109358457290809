/* eslint-disable */
import React, { ReactNode } from 'react';
import clsx from 'clsx';

import Snack from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';

// @material-ui/icons
import Close from '@material-ui/icons/Close';

import { useStyles } from './snackbar-content.styles';

type SnackbarColor =
  | 'info'
  | 'success'
  | 'warning'
  | 'danger'
  | 'primary'
  | 'rose';

export interface SnackbarProps {
  message: ReactNode; // PropTypes.node.isRequired,
  color?: SnackbarColor; // PropTypes.oneOf([]),
  close?: boolean; // PropTypes.bool,
  icon?: any; // PropTypes.object,
  place?: 'tl' | 'tr' | 'tc' | 'br' | 'bl' | 'bc'; // PropTypes.oneOf([]),
  open?: boolean; // PropTypes.bool;
  closeNotification?: () => void; // PropTypes.func;
}

export const Snackbar = (props: SnackbarProps) => {
  const classes = useStyles();
  const { message, color, close, icon, place, open } = props;
  let action: any[] = [];
  const messageClasses = clsx({
    [classes.iconMessage]: icon !== undefined,
  });
  if (close !== undefined) {
    action = [
      <IconButton
        className={classes.iconButton}
        key="close"
        aria-label="Close"
        color="inherit"
        onClick={() => props.closeNotification?.()}
      >
        <Close className={classes.close} />
      </IconButton>,
    ];
  }
  const iconClasses = clsx({
    [classes.icon]: classes.icon,
    [classes.infoIcon]: color === 'info',
    [classes.successIcon]: color === 'success',
    [classes.warningIcon]: color === 'warning',
    [classes.dangerIcon]: color === 'danger',
    [classes.primaryIcon]: color === 'primary',
    [classes.roseIcon]: color === 'rose',
  });
  return (
    <Snack
      classes={{
        anchorOriginTopCenter: classes.top20,
        anchorOriginTopRight: classes.top40,
        anchorOriginTopLeft: classes.top40,
      }}
      anchorOrigin={{
        vertical: place?.indexOf('t') === -1 ? 'bottom' : 'top',
        horizontal:
          place?.indexOf('l') !== -1
            ? 'left'
            : place?.indexOf('c') !== -1
            ? 'center'
            : 'right',
      }}
      open={open}
      message={
        <div>
          {icon !== undefined ? <props.icon className={iconClasses} /> : null}
          {typeof message === 'string' ? (
            <span className={messageClasses}>{message}</span>
          ) : (
            message
          )}
        </div>
      }
      action={action}
      ContentProps={{
        classes: {
          root: classes.root + ' ' + (color ? classes[color] : ''),
          message: classes.message,
        },
      }}
    />
  );
};

Snackbar.defaultProps = {
  color: 'info',
};
