import { useMemo } from 'react';

import { TextValue } from 'core/atoms/types';

import { useRooms } from 'modules/planner/metabolism/use-rooms';

export const useRoomSelector = () => {
  const {
    rooms,
    selectedRooms,
    setSelectedRooms,
    roomsLoading,
    roomsError,
  } = useRooms();

  const roomsData: TextValue[] = useMemo(() => {
    return rooms.map((r) => ({
      text: r.name || 'noname',
      value: r.id,
    }));
  }, [rooms]);

  return {
    rooms: roomsData,
    selectedRooms,
    setSelectedRooms,
    roomsLoading,
    roomsError,
  };
};
