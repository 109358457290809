import React from 'react';

import notFound from 'core/atoms/pictures/not-found.png';
import { createStyles, makeStyles } from 'core/atoms/styles';

import { Box } from 'core/cells/box';
import { Typography } from 'core/cells/typography';

const useStyles = makeStyles(
  createStyles({
    root: {
      position: 'absolute',
      top: 0,
      left: 0,
    },
  }),
);

export const AccessDenied = () => {
  const classes = useStyles();

  return (
    <Box
      fullSize
      centeredContent
      bgcolor="white"
      flexDirection="column"
      className={classes.root}
    >
      <Typography variant="h1">Permission denied :(</Typography>
      <br />
      <img alt="coming-soon" src={notFound} height="400px" />
    </Box>
  );
};
