import { useMemo } from 'react';

import { useQuery } from 'core/dna/types/apollo';
import {
  GetClasses,
  GetClassesVariables,
} from 'core/dna/types/generated/GetClasses';

import { useGetTermsState } from 'core/memory/apollo/terms/local';

import { Class, ClassMap } from 'modules/planner/dna/types/class';
import { mapClassesToClassMap } from 'modules/planner/dna/functions/map-classes-to-class-map';
import { mapApiClassListToClassList } from 'modules/planner/dna/functions/map-ApiClass-to-Class';

import { GET_CLASSES_QUERY } from './queries/get-classes-query';

export const useGetClasses = (condition = true) => {
  const { data: termsState } = useGetTermsState();

  const {
    data: classesData,
    error: classesError,
    loading: classesLoading,
  } = useQuery<GetClasses, GetClassesVariables>(GET_CLASSES_QUERY, {
    skip: !condition || !termsState?.selected,
    variables: { termId: termsState!.selected! },
  });

  const classes = useMemo<Class[] | undefined>(() => {
    if (!classesLoading && !classesError && classesData?.Class?.length) {
      return mapApiClassListToClassList(classesData.Class);
    }
  }, [classesData, classesError, classesLoading]);

  const classMap = useMemo<ClassMap | undefined>(() => {
    return classes?.length ? mapClassesToClassMap(classes) : undefined;
  }, [classes]);

  return {
    classes,
    classMap,
    classesError,
    classesLoading,
  };
};
