import { useEffect, useMemo } from 'react';
import { useLazyQuery } from 'core/dna/types/apollo';
import { OrderDirection } from 'core/dna/types/generated/_globalTypes';
import {
  GetAbsences,
  GetAbsencesVariables,
} from 'core/dna/types/generated/GetAbsences';
import { TermData } from 'core/dna/types/term';

import { mapApiPersonT2AbsencesToPersonT2AbsencesList } from 'modules/student/dna/functions/';
import { ShortDataWithAbsences } from 'modules/student/dna/types';
import { GET_ABSENCES_QUERY } from './gql';

interface UseGetAbsencesProps extends GetAbsencesVariables {
  term: TermData | undefined;
}

export const useGetAbsences = (props: UseGetAbsencesProps) => {
  const { first, order = OrderDirection.desc, input, term } = props;

  const [getAbsences, { data, error, loading }] = useLazyQuery<
    GetAbsences,
    GetAbsencesVariables
  >(GET_ABSENCES_QUERY, {
    errorPolicy: 'all',
    fetchPolicy: 'no-cache',
  });

  useEffect(() => {
    if (term?.startDate) {
      getAbsences({
        variables: {
          first,
          order,
          input,
        },
      });
    }
  }, [first, getAbsences, input, order, term]);

  const absences = useMemo<ShortDataWithAbsences[] | undefined>(() => {
    if (loading || !data) {
      return;
    }

    return mapApiPersonT2AbsencesToPersonT2AbsencesList(data.PersonT2);
  }, [loading, data]);

  return {
    loading,
    error,
    data: absences,
  };
};
