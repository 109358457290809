import { useCallback, useMemo } from 'react';

import { parseTemplate } from 'core/atoms/functions/string';

import { Grid } from 'core/cells/grid';
import { Typography } from 'core/cells/typography';
import { Slider } from 'core/cells/slider';

import { useTranslations } from 'core/dna/translations';

import { useClassesState, classesStore } from '../../../../../store/classes';

const CustomThumb = (props: any) => {
  return (
    <span {...props}>
      <span className="bar" />
    </span>
  );
};

export interface GradeClassesSelectorProps {
  grade: number;
}

export const GradeClassesSelector = (props: GradeClassesSelectorProps) => {
  const { grade } = props;
  const { texts } = useTranslations();

  const { classesState } = useClassesState();
  const { generatingOptions } = classesState;

  const value = useMemo(() => {
    if (generatingOptions?.byId?.[grade]?.count) {
      return generatingOptions.byId[grade].count;
    }
    return 0;
  }, [generatingOptions.byId, grade]);

  const getNames = useCallback((grade: number, count: number) => {
    if (count === 0) {
      return [];
    }
    const classes = [];
    for (let i = 65; i < 65 + count; i++) {
      classes.push(grade + String.fromCharCode(i));
    }
    return classes;
  }, []);

  const handleSliderChange = useCallback(
    (event: any, newValue: any) => {
      const names = getNames(grade, newValue);
      classesStore.setGradeClasses(grade, names);
    },
    [getNames, grade],
  );

  const printClasses = useCallback(
    (grade: number) => {
      const names = getNames(grade, value);
      if (!names.length) {
        return ' ';
      }
      return names.join(', ');
    },
    [getNames, value],
  );

  return (
    <div>
      <Grid
        container
        justify="space-between" // Add it here :)
      >
        <Grid item>
          <Typography id="discrete-slider-small-steps" gutterBottom>
            {parseTemplate(
              texts.planner.sourceDataSpace.classesModule.numberedClass,
              {
                number: grade,
              },
            )}
          </Typography>
        </Grid>
        <Grid item>
          <Typography id="discrete-slider-small-steps" gutterBottom>
            {printClasses(grade)}
          </Typography>
        </Grid>
      </Grid>

      <Slider
        ThumbComponent={CustomThumb}
        value={value}
        step={1}
        marks
        onChange={handleSliderChange}
        min={0}
        max={5}
        valueLabelDisplay="auto"
      />
    </div>
  );
};
