/* eslint-disable */
import React, { ReactElement } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid, { GridProps } from '@material-ui/core/Grid';

import { createStyles } from 'core/atoms/styles';
import clsx from 'clsx';

export interface GridContainerProps extends GridProps {
  className?: string; // PropTypes.string,
  children?: ReactElement | ReactElement[]; // PropTypes.node,
}

const useStyles = makeStyles(() =>
  createStyles({
    grid: {
      margin: '0 -15px',
      width: 'calc(100% + 30px)',
      // '&:before,&:after':{
      //   display: 'table',
      //   content: '" "',
      // },
      // '&:after':{
      //   clear: 'both',
      // }
    },
  }),
);

export const GridContainer = (props: GridContainerProps) => {
  const classes = useStyles();
  const { children, className, ...rest } = props;
  return (
    <Grid container {...rest} className={clsx(classes.grid, className ?? '')}>
      {children}
    </Grid>
  );
};
