import { useState, useEffect, useLayoutEffect } from 'react';
import clsx from 'clsx';

import { logger, LogType } from 'core/atoms/housekeeping';
import { parseTemplate } from 'core/atoms/functions/string';

import { Form, Field, FormController } from 'core/cells/forms';
import { FormTextField } from 'core/cells/form-text-field';

import { useTranslations } from 'core/dna/translations';
import { useLocale } from 'core/dna/routing';

import { useOasHistory } from 'core/metabolism/use-oas-history';

import { GridContainer, GridItem } from 'templates/mdp/components/grid';
import { Card, CardBody, CardFooter } from 'templates/mdp/components/card';
import { Button } from 'templates/mdp/components/custom-buttons';
import { Warning } from 'templates/mdp/components/typography';

import { isIssueAccessTokenPinValid } from 'auth/dna/types';

import { useIssueAccessToken } from 'auth/memory/apollo/access-token/remote';
import { getSessionTokenStorage, getStatusStorage } from 'auth/memory/browser';

import { useAuthContext } from 'auth/metabolism/use-auth-context';

import AuthUrls from 'auth/urls';

import { useStyles } from './lock-page.styles';

interface Values {
  pinCode: string;
}

const LOGGER = 'lock-page';

export const LockPage = () => {
  const classes = useStyles();
  const maxAttempts = 3;

  const { texts } = useTranslations();

  const [cardAnimation, setCardAnimation] = useState('cardHidden');
  const [attempts, setAttempts] = useState(1);

  const locale = useLocale();
  const { replace } = useOasHistory();
  const { getHasPinCode, setTokens, removeTokens, reload } = useAuthContext();
  const { getStatus, setStatus } = getStatusStorage();

  const { getSessionToken } = getSessionTokenStorage();
  const [sessionTokenLocally, setSessionTokenLocally] = useState(
    getSessionToken(),
  );

  useEffect(() => {
    const sessionToken = getSessionToken();

    if (!sessionToken) {
      const hasPinCode = getHasPinCode();
      if (!hasPinCode) {
        replace(AuthUrls.loggedOut(locale));
      }
    }

    setSessionTokenLocally(sessionToken);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useLayoutEffect(() => {
    setCardAnimation('');
  }, []);

  const { issueAccessToken } = useIssueAccessToken();

  const [warning, setWarning] = useState({ message: '', attemptsLeft: '' });

  const handleSubmit = async (values: Values) => {
    const result = await issueAccessToken(values.pinCode);

    if (isIssueAccessTokenPinValid(result)) {
      logger.debug({
        title: 'SUBMIT: Pin Valid',
        logger: LOGGER,
        type: LogType.Success,
        value: [
          {
            accessToken: result.accessToken,
          },
        ],
      });
      if (result.accessToken) {
        if (getStatus() !== 'identity') {
          setStatus('identity');
        }
        setTokens({
          accessToken: result.accessToken,
          // sessionToken: getIdentity()?.session.token!,
          sessionToken: sessionTokenLocally!,
        });
      }
      reload();
    } else {
      logger.debug({
        title: 'SUBMIT: Pin Invalid',
        logger: LOGGER,
        type: LogType.Error,
      });
      setAttempts(attempts + 1);

      if (attempts && maxAttempts) {
        if (attempts >= maxAttempts) {
          removeTokens();
          replace(AuthUrls.loggedOut(locale));
          return;
        }

        const attemptsLeft = maxAttempts - attempts;
        const attemptsLeftMessage =
          attemptsLeft > 1
            ? parseTemplate(texts.auth.attemptsLeft.many, {
                attemptsLeft,
              })
            : texts.auth.attemptsLeft.one;

        setWarning({
          // message: (texts.auth.errors as any)[code],
          message: texts.auth.errors['PIN_ERROR_PIN_INVALID'],
          attemptsLeft: attemptsLeftMessage,
        });
      }
    }
  };

  return (
    <div className={classes.container}>
      <GridContainer justifyContent="center">
        <GridItem xs={12} sm={6} md={4}>
          <Card
            profile
            className={clsx(
              classes.customCardClass,
              (classes as any)[cardAnimation],
            )}
          >
            <FormController
              initialValues={{
                pinCode: '',
              }}
              validate={(values) => {
                const errors: Partial<Values> = {};
                if (!values.pinCode) {
                  errors.pinCode = texts.oasCommon.validation.required;
                }
                return errors;
              }}
              onSubmit={(values) => handleSubmit(values)}
            >
              {({ submitForm, isSubmitting }) => (
                <Form>
                  <CardBody profile>
                    <Field
                      component={FormTextField}
                      name="pinCode"
                      type="text"
                      label={texts.auth.enterPinCode}
                      disabled={isSubmitting}
                      fullWidth
                      inputProps={{
                        type: 'password',
                        autoComplete: 'off',
                        autoFocus: true,
                      }}
                    />
                    {!!warning.message && <Warning>{warning.message}</Warning>}
                    {!!warning.attemptsLeft && (
                      <Warning>{warning.attemptsLeft}</Warning>
                    )}
                  </CardBody>
                  <CardFooter className={classes.justifyContentCenter}>
                    <Button
                      color="rose"
                      round
                      disabled={isSubmitting}
                      onClick={submitForm}
                    >
                      {texts.auth.unlock}
                    </Button>
                  </CardFooter>
                </Form>
              )}
            </FormController>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
};
