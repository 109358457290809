import { useCallback } from 'react';

import { OasMemoryError, OasError } from 'core/atoms/errors';

import { gql, useMutation } from 'core/dna/types/apollo';
import { EditScheduleInput } from 'core/dna/types/generated/_globalTypes';
import {
  EditSchedule,
  EditScheduleVariables,
} from 'core/dna/types/generated/EditSchedule';

const EDIT_SCHEDULE_MUTATION = gql`
  mutation EditSchedule($input: EditScheduleInput!) {
    editSchedule(input: $input) {
      id
      name
    }
  }
`;

const FILE_NAME = 'use-edit-schedule';

export const useEditSchedule = () => {
  const [editSchedule] = useMutation<EditSchedule, EditScheduleVariables>(
    EDIT_SCHEDULE_MUTATION,
  );

  const func = useCallback(
    async (input: EditScheduleInput) => {
      try {
        const result = await editSchedule({
          variables: { input },
        });

        if (result.errors) {
          throw new OasMemoryError(JSON.stringify(result.errors), {
            title: FILE_NAME,
          });
        }
        return result.data?.editSchedule ?? undefined;
      } catch (e: any) {
        if (e instanceof OasError) {
          throw e;
        } else {
          throw OasMemoryError.fromError(e, {
            title: FILE_NAME,
          });
        }
      }
    },
    [editSchedule],
  );

  return {
    editSchedule: func,
  };
};
