import {
  OasError,
  OasErrorConstructor,
  getOasErrorFromError,
  OasErrorOptions,
  OasErrorInterface,
} from './oas-error';

export const OasOrganismError: OasErrorConstructor = class OasOrganismError extends OasError {
  static fromError(e: unknown, options?: OasErrorOptions): OasErrorInterface {
    return getOasErrorFromError(OasOrganismError, e, options);
  }

  constructor(message?: string, options?: OasErrorOptions) {
    super(message, { ...options, type: 'OasOrganismError' });
  }
};
