import { useContext } from 'react';

import { OasDnaError } from 'core/atoms/errors';

import { ModulesStateContext } from './modules-state-context';

export const useModulesState = () => {
  const modulesState = useContext(ModulesStateContext);
  if (typeof modulesState === 'undefined') {
    throw new OasDnaError(
      'useModulesState from the ModulesStateContext must be used within a ModulesProvider',
      { title: 'use-module-state' },
    );
  }
  return modulesState;
};
