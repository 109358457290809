import { Class, ClassMap } from 'modules/planner/dna/types/class';

export const mapClassesToClassMap = (classes: Class[]): ClassMap => {
  return {
    byId: classes.reduce(
      (prev, curr) => ({ ...prev, [curr.id]: { ...curr } }),
      {},
    ),
  };
};
