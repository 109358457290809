import { BehaviorSubject } from 'rxjs';
import { produce } from 'immer';

import { logger, LogType } from 'core/atoms/housekeeping';
import { IdentityMap } from 'core/atoms/types';

export interface ClassesStoreState {
  generatingOptions: IdentityMap<{
    id: string;
    names: string[];
    count: number;
  }>;
  classesGeneratorVisible: boolean;
  generating: boolean;
}

const defaultIdentityMap: IdentityMap<any> = { allIds: [], byId: {} };

const initialState: ClassesStoreState = {
  generatingOptions: defaultIdentityMap,
  classesGeneratorVisible: false,
  generating: false,
};

const subject = new BehaviorSubject(initialState);

let state = initialState;

const emit = (state: ClassesStoreState) => {
  subject.next(state);
};

const FILE_NAME = 'planner/source-data/classes/classes-store';

const changeGeneratorVisibility = (visible: boolean) =>
  produce(state, (draft) => {
    draft.classesGeneratorVisible = visible;
  });

export const classesStore = {
  subscribe: (setState: (value: ClassesStoreState) => void) =>
    subject.subscribe(setState),
  showClassesGenerator: () => {
    state = changeGeneratorVisibility(true);

    logger.debug({
      title: 'showClassesGenerator',
      logger: FILE_NAME,
      type: LogType.Info,
    });

    emit(state);
  },
  setGradeClasses: (grade: number, names: string[]) => {
    state = produce(state, (draft) => {
      draft.generatingOptions.allIds = [
        ...new Set([...draft.generatingOptions.allIds, String(grade)]),
      ];
      draft.generatingOptions.byId = {
        ...draft.generatingOptions.byId,
        [grade]: {
          id: String(grade),
          names,
          count: names.length,
        },
      };
    });

    logger.debug({
      title: 'setGradeClasses',
      logger: FILE_NAME,
      type: LogType.Info,
    });

    emit(state);
  },
  hideClassesGenerator: () => {
    state = changeGeneratorVisibility(false);

    logger.debug({
      title: 'hideClassesGenerator',
      logger: FILE_NAME,
      type: LogType.Info,
    });

    emit(state);
  },
  clearGeneratingOptions: () => {
    state = produce(state, (draft) => {
      draft.generatingOptions = defaultIdentityMap;
    });

    logger.debug({
      title: 'hideClassesGenerator',
      logger: FILE_NAME,
      type: LogType.Info,
    });

    emit(state);
  },
  generateClasses: async (generateFunc: () => Promise<void[]>) => {
    state = produce(state, (draft) => {
      draft.classesGeneratorVisible = false;
      draft.generating = true;
    });
    emit(state);
    try {
      await generateFunc();
      state = produce(state, (draft) => {
        // draft.generating = false;
        draft.generatingOptions = defaultIdentityMap;
      });
    } catch {
      // state = produce(state, (draft) => {
      //   draft.generating = false;
      // });
    } finally {
      state = produce(state, (draft) => {
        draft.generating = false;
      });
    }
    emit(state);
  },
  initialState,
};
