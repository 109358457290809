import { DraggableTypes } from 'core/atoms/types';

import { OasLocales } from 'core/dna/translations/types';
import { RoleType } from 'core/dna/types/generated/_globalTypes';

import { LessonGroup } from 'modules/planner/dna/types/lesson-group';
import { getEmployeeTypeString } from 'modules/planner/dna/functions/get-employee-type-string';

import { LessonItemsData, LessonItem } from '../../types';

export const getLessonItems = (
  lessonGroup: LessonGroup,
  texts: OasLocales,
): LessonItemsData => {
  const lessons: LessonItem[] = [];
  const subjects: LessonItem[] = [];
  const teachers: LessonItem[] = [];
  const rooms: LessonItem[] = [];
  const chapters: LessonItem[] = [];

  lessonGroup.lessons?.forEach((l) => {
    lessons.push({
      id: l.id,
      text: l.title ?? 'LESSON',
      type: DraggableTypes.Subject,
    });

    l.subjects?.forEach((s) => {
      subjects.push({
        id: s.id,
        text: s.name,
        lessonId: l.id,
        type: DraggableTypes.Subject,
      });
    });

    l.teachers?.forEach((t) => {
      teachers.push({
        id: t.id,
        text: t.text,
        lessonId: l.id,
        type: DraggableTypes.Teacher,
        data: getEmployeeTypeString({
          type: t.data?.type ?? RoleType.teacher,
          texts,
        }),
      });
    });

    l.rooms?.forEach((r) => {
      rooms.push({
        id: r.id,
        text: r.text,
        lessonId: l.id,
        type: DraggableTypes.Room,
      });
    });

    l.chapters?.forEach((ch) => {
      chapters.push({
        id: ch.id,
        text: ch.name ?? ch.class,
        lessonId: l.id,
        type: DraggableTypes.Chapter,
        data: {
          id: ch.id,
          name: ch.name,
          class: { id: ch.classId, name: ch.class },
        },
      });
    });
  });

  const plannedMinutesPerTimeSlot: number[] =
    lessonGroup.plannedMinutesPerTimeSlot ?? [];

  // const mustBeNonstop = lessonGroup.mustBeNonstop ?? false;

  return {
    lessonGroupId: lessonGroup.id,
    lessons,
    subjects,
    teachers,
    rooms,
    chapters,
    plannedMinutesPerTimeSlot,
    canBeSplit: lessonGroup.canBeSplit,
    // mustBeNonstop,
  };
};
