import invert from 'invert-color';

import { tick } from 'core/atoms/date-time';
import { grey } from 'core/atoms/colors';

import { mapStringListToString } from 'core/dna/functions';
import { ScheduleEvent } from 'core/dna/types';

import { LessonGroup } from 'modules/planner/dna/types/lesson-group';
import { logger, LogType } from '../../../../../core/atoms/housekeeping';

export const mapLessonGroupListToScheduleEventList = (
  lessonGroupList: LessonGroup[] | undefined,
  options?: {
    withClasses?: boolean;
    withGroups?: boolean;
    withTeachers?: boolean;
    withRooms?: boolean;
  },
): ScheduleEvent[] | undefined => {
  if (!lessonGroupList?.length) {
    return;
  }
  let scheduleEvents: ScheduleEvent[] = [];

  lessonGroupList.forEach((lg) => {
    const seList = mapLessonGroupToScheduleEventList(lg, options);
    if (seList?.length) {
      scheduleEvents = [...scheduleEvents, ...seList];
    }
  });

  return scheduleEvents;
};

export const mapLessonGroupToScheduleEventList = (
  lessonGroup: LessonGroup,
  options?: {
    withClasses?: boolean;
    withGroups?: boolean;
    withTeachers?: boolean;
    withRooms?: boolean;
  },
): ScheduleEvent[] | undefined => {
  const { lessons, timeSlots } = lessonGroup;

  if (!lessons?.length || !timeSlots?.length) {
    return;
  }

  const scheduleEvents: ScheduleEvent[] = [];

  timeSlots.forEach((ts) => {
    const start = tick(ts.weekday).setTime(ts.startTime!).toDate();
    const end = tick(ts.weekday).setTime(ts.endTime!).toDate();
    lessons.forEach((l) => {
      const {
        id,
        title,
        chapters,
        bgColor,
        classes,
        groups,
        teachers,
        rooms,
      } = l;

      const invertedColor = invert(bgColor!, {
        black: '#3a3a3a',
        white: '#fafafa',
      });

      scheduleEvents.push({
        id,
        timeSlotId: ts.id,
        title: title ?? 'E',
        resourceIds: chapters?.map((ch) => ch.classId),
        backgroundColor: bgColor,
        borderColor: grey['600'],
        textColor: invertedColor,
        start,
        end,
        pinned: ts.pinned,
        durationEditable: false,
        locked: false, // !!ts.splitId,
        startEditable: true, // !ts.splitId,
        classes: options?.withClasses
          ? mapStringListToString(classes?.map((t) => t.text))
          : undefined,
        groups: options?.withGroups
          ? mapStringListToString(groups?.map((t) => t.text))
          : undefined,
        teachers: options?.withTeachers
          ? mapStringListToString(teachers?.map((t) => t.text))
          : undefined,
        rooms: options?.withRooms
          ? mapStringListToString(rooms?.map((r) => r.text))
          : undefined,
        aiHardConflicts: ts.aiHardConflicts,
        aiSoftConflicts: ts.aiSoftConflicts,
      });
    });
  });

  return scheduleEvents;
};
