import { ChangeEvent, useState } from 'react';
import { Field, Form, FormController } from 'core/cells/forms';
import { FormTextField } from 'core/cells/form-text-field';
import { FlexBox } from 'core/cells/box';

import { RadioGroup } from 'core/cells/radio-group';
import { Radio, FormControlLabel } from '@material-ui/core';

import { Button } from 'templates/mdp/components/custom-buttons';
import { RestockTypes } from 'modules/student/dna/types';
import { JournalType } from 'core/dna/types/generated/_globalTypes';
import { useTranslations } from 'core/dna/translations';
import { useStudentsTableWrite } from '../use-students-table-write';

interface Values {
  comment: string;
  selected: string;
}

interface JournalRestockProps {
  tuid: string | null;
  setOpen: (open: boolean) => void;
  resetJournalType: (selectedType: JournalType | null) => void;
}

export const JournalRestock = ({
  tuid,
  setOpen,
  resetJournalType,
}: JournalRestockProps) => {
  const { texts } = useTranslations();
  const { onCreateJournalEntry } = useStudentsTableWrite();

  const restockTypes = [
    {
      text: texts.studentModule.journal.restockTypes!.diapers,
      value: RestockTypes.diapers,
    },
    {
      text: texts.studentModule.journal.restockTypes!.indoor,
      value: RestockTypes.indoor,
    },
    {
      text: texts.studentModule.journal.restockTypes!.outdoor,
      value: RestockTypes.outdoor,
    },
    {
      text: texts.studentModule.journal.restockTypes!.others,
      value: RestockTypes.other,
    },
  ];

  const [restockType, setRestockType] = useState<RestockTypes>(
    RestockTypes.diapers,
  );

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setRestockType((event.target as HTMLInputElement).value as RestockTypes);
  };

  return (
    <FormController
      initialValues={{
        comment: '',
        selected: '',
      }}
      validate={(values) => {
        const errors: Partial<Values> = {};
        if (restockType === RestockTypes.other && !values.comment) {
          errors.comment = texts.oasCommon.validation.required;
        }
        return errors;
      }}
      onSubmit={async (values) => {
        if (!tuid) return;
        await onCreateJournalEntry({
          regardingTuid: tuid,
          type: JournalType.restock,
          data: {
            [JournalType.restock]: { restockType, comment: values.comment },
          },
        });
        setOpen(false);
        resetJournalType(null);
      }}
    >
      {({ submitForm, isSubmitting }) => (
        <Form>
          <RadioGroup
            aria-label="restock-type"
            name="restock-type1"
            value={restockType}
            onChange={handleChange}
          >
            {restockTypes.map((t) => (
              <FormControlLabel
                value={t.value}
                control={<Radio />}
                label={t.text}
              />
            ))}
          </RadioGroup>
          {restockType === RestockTypes.other && (
            <Field
              component={FormTextField}
              name="comment"
              type="text"
              label={texts.oasCommon.comments.one}
              disabled={isSubmitting}
              fullWidth
            />
          )}
          <FlexBox justifyContent="flex-end">
            <Button
              color="secondary"
              disabled={isSubmitting}
              onClick={submitForm}
            >
              {texts.planner.common.save}
            </Button>
            <Button
              color="secondary"
              simple
              onClick={() => {
                setOpen(false);
              }}
            >
              {texts.planner.common.close}
            </Button>
          </FlexBox>
        </Form>
      )}
    </FormController>
  );
};
