import { useState } from 'react';

import { makeStyles, Theme, createStyles } from 'core/atoms/styles';
import { deepOrange, amber, grey } from 'core/atoms/colors';

import { Popover } from 'core/cells/popover';
import { List } from 'core/cells/list';
import { ListItem } from 'core/cells/list-item';
import { ListItemText } from 'core/cells/list-item-text';

import { useTranslations } from 'core/dna/translations';

export interface WithConflictsProps {
  hardConflicts?: string[];
  softConflicts?: string[];
  children?: React.ReactElement;
}

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    popover: {
      pointerEvents: 'none',
    },
    paper: {
      padding: theme.spacing(1),
      backgroundColor: (props: WithConflictsProps) =>
        props.hardConflicts?.length ? deepOrange.A400 : amber.A400,
      borderColor: grey['800'],
      borderWidth: 1,
      borderStyle: 'solid',
    },
    listRoot: {
      width: '100%',
      maxWidth: 360,
      backgroundColor: theme.palette.background.paper,
      position: 'relative',
      overflow: 'auto',
      maxHeight: 300,
    },
    listSection: {
      backgroundColor: 'inherit',
    },
    ul: {
      backgroundColor: 'inherit',
      padding: 0,
    },
  }),
);

export const WithConflicts = (props: WithConflictsProps) => {
  const classes = useStyles(props);
  const { hardConflicts, softConflicts, children } = props;

  const { lang } = useTranslations();

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handlePopoverOpen = (
    event: React.MouseEvent<HTMLElement, MouseEvent>,
  ) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return hardConflicts?.length || softConflicts?.length ? (
    <>
      <span
        aria-owns={open ? 'mouse-over-popover' : undefined}
        aria-haspopup="true"
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
      >
        {children}
      </span>
      <Popover
        id="mouse-over-popover"
        className={classes.popover}
        classes={{
          paper: classes.paper,
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <List dense>
          {hardConflicts?.map((hConflict, index) => (
            <ListItem key={`${hConflict}-${index}`}>
              <ListItemText
                primary={
                  (lang.common.aiConflicts as any)[hConflict] ??
                  lang.common.aiConflicts.unknown
                }
              />
            </ListItem>
          ))}
          {softConflicts?.map((sConflict, index) => (
            <ListItem key={`${sConflict}-${index}`}>
              <ListItemText
                primary={
                  (lang.common.aiConflicts as any)[sConflict] ??
                  lang.common.aiConflicts.unknown
                }
              />
            </ListItem>
          ))}
        </List>
      </Popover>
    </>
  ) : (
    <>{children}</>
  );
};
