import { useCallback } from 'react';
import produce from 'immer';

import { OasMemoryError } from 'core/atoms/errors';

import { SchedulesState } from 'modules/planner/dna/types/local';

import { schedulesStateVar } from 'modules/planner/memory/apollo/cache';

const FILE_NAME = 'use-set-schedules-selected-state';

export const useSetSchedulesSelectedState = () => {
  // const setSchedulesSelectedState = useCallback(
  //   (input: { schedules: ScheduleStateInput[] | null }) => {
  //     const { schedules: schedulesInput } = input;
  //     try {
  //       const origData = schedulesStateVar();
  //       const data = produce<SchedulesState, SchedulesState>(
  //         origData,
  //         (draft) => {
  //           draft.selected = [];
  //           const newValues: { [id: string]: ScheduleDataState } = {};
  //           schedulesInput?.forEach((s) => {
  //             draft.selected!.push(s.id);
  //             newValues[s.id] = {
  //               id: s.id,
  //               name: s.name ?? null,
  //               status: s.status ?? null,
  //             };
  //           });
  //           draft.byId = { ...origData.byId, ...newValues };
  //           draft.subscribed?.forEach((id) => {
  //             const oldValue = draft.byId.hasOwnProperty(id)
  //               ? draft.byId[id]
  //               : null;
  //             if (!oldValue) {
  //               logger.debug({
  //                 title: 'SchedulesState: values in "byId"',
  //                 logger: FILE_NAME,
  //                 type: LogType.Info,
  //                 value: [
  //                   {
  //                     originalById: Object.keys(origData.byId).join(', '),
  //                     selectedById: Object.keys(newValues.byId).join(', '),
  //                   },
  //                 ],
  //               });
  //               throw new OasMemoryError(
  //                 `SchedulesState inconsistent: no value corresponding to "subscribed" ID: ${id}`,
  //                 { title: FILE_NAME },
  //               );
  //             }
  //           });
  //         },
  //       );
  //
  //       schedulesStateVar(data);
  //       const existingSchedule = selectedScheduleStateVar();
  //       updateScheduleStorages(data.selected);
  //       const currentSchedule = getCurrentSchedule() ?? undefined;
  //       if (existingSchedule !== currentSchedule) {
  //         selectedScheduleStateVar(currentSchedule ?? undefined);
  //       }
  //
  //       return true;
  //     } catch (e: any) {
  //       throw OasMemoryError.fromError(e, { title: FILE_NAME });
  //     }
  //   },
  //   [],
  // );

  const setSchedulesSelectedState = useCallback(
    (selectedSchedules: string[] | null) => {
      try {
        const origData = schedulesStateVar();
        const data = produce<SchedulesState, SchedulesState>(
          origData,
          (draft) => {
            draft.selected = selectedSchedules ?? [];
          },
        );
        schedulesStateVar(data);
        return true;
      } catch (e: any) {
        throw OasMemoryError.fromError(e, { title: FILE_NAME });
      }
    },
    [],
  );

  return {
    setSchedulesSelectedState,
  };
};
