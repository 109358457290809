import { useMemo } from 'react';

import { useQuery } from 'core/dna/types/apollo';
import { GetSubjectInfosFromSync } from 'core/dna/types/generated/GetSubjectInfosFromSync';
import { mapNullableListToIdentityMap } from 'core/dna/functions';

import { SubjectInfo } from 'modules/planner/dna/types/subject';
import { mapApiSubjectInfoListToSubjectInfoList } from 'modules/planner/dna/functions';

import { GET_SUBJECT_INFOS_FROM_SYNC_QUERY } from './gql';

export const useGetSubjectInfosFromSync = (runCondition = true) => {
  const { data: apiData, error, loading } = useQuery<GetSubjectInfosFromSync>(
    GET_SUBJECT_INFOS_FROM_SYNC_QUERY,
    {
      skip: !runCondition,
    },
  );

  const list = useMemo(() => {
    if (!loading && !error && apiData) {
      return mapApiSubjectInfoListToSubjectInfoList(apiData.SyncSubject);
    }
    return [];
  }, [apiData, error, loading]);

  const map = useMemo(() => {
    return list?.length
      ? mapNullableListToIdentityMap<SubjectInfo, SubjectInfo>(
          list,
          (si) => si ?? undefined,
        )
      : undefined;
  }, [list]);

  return {
    list,
    map,
    loading,
    error,
  };
};
