import { Organization } from 'core/dna/types/organization';
import { OrganizationFeatureCode } from 'core/dna/types/generated/_globalTypes';

export const isFeatureAvailable = (
  org: Organization | undefined,
  featureName: OrganizationFeatureCode,
) => {
  if (!org || !org.category || !org.features) {
    return false;
  }

  const feature = org.features.find((f) => f.code === featureName);

  return !!feature && !feature.expired;
};
