import React, { useMemo, useState } from 'react';

import { Table } from 'templates/mdp/components/react-table';
import { Group, Delete } from '@material-ui/icons';
import { Skeleton } from 'core/cells/skeleton';
import { GridContainer, GridItem } from 'templates/mdp/components/grid';
import {
  Card,
  CardBody,
  CardHeader,
  CardIcon,
} from 'templates/mdp/components/card';
import { useStyles } from 'templates/mdp/views/tables/react-tables.styles';
import { Button } from 'templates/mdp/components/custom-buttons';
import { useTranslations } from 'core/dna/translations';
import { useParams, useHistory } from 'core/dna/routing';

import { WriteModeEnum } from 'core/dna/types/write-mode';

import { createReactTableStore } from 'core/memory/pure/react-table-store';
import { useGetTermsState } from 'core/memory/apollo/terms/local/get-terms-state';
import { useReactTableState } from 'core/metabolism/table-state';

import {
  EvaluationCreator,
  initialEvaluation,
} from 'modules/grades/organisms/evaluation-creator';
import { useStudentsTableRead } from './use-students-table-read';

export const manageStudentsTableStore = createReactTableStore({}, {});

export const StudentsTable = () => {
  const classes = useStyles();
  const { texts } = useTranslations();
  const { goBack } = useHistory();

  const { data: termsState } = useGetTermsState();
  const { id: evaluationGroupId } = useParams<{ id: string }>();

  const { columns, data, loading, title } = useStudentsTableRead();

  const selectedStudents = data.map(
    (item) => item.student?.personDetails?.tuid || '',
  );

  const {
    initialPage,
    pageSize,
    sort,
    filters,
    setSort,
    setFilters,
    setPage,
    setPageSize,
  } = useReactTableState(
    manageStudentsTableStore,
    termsState?.selected ?? undefined,
  );

  const [showModal, setShowModal] = useState(false);

  const preparedData = useMemo(
    () =>
      data?.map((subjects) => ({
        ...subjects,
        actions: (
          <div className="actions-right">
            <Button
              justIcon
              round
              simple
              size="sm"
              color="danger"
              title={texts.planner.common.delete}
              aria-label={texts.planner.common.delete}
              disabled={true}
              onClick={() => {
                console.log('remove is not implemented');
              }}
            >
              <Delete />
            </Button>
          </div>
        ),
      })),
    [data, texts],
  );

  const handleAdd = () => {
    setShowModal(true);
  };

  const handleBack = () => {
    goBack();
  };

  return loading || !termsState?.selected ? (
    <Skeleton variant="rect" width="100%" height="80vh" />
  ) : (
    <GridContainer spacing={3} justifyContent="center">
      <GridItem
        xs={12}
        style={{ display: 'flex', justifyContent: 'space-between' }}
      >
        <Button onClick={handleBack}>{texts.oasCommon.back}</Button>
        <Button color="secondary" onClick={handleAdd}>
          {texts.grades.evaluationGroups.addStudents}
        </Button>
      </GridItem>
      <GridItem xs={12}>
        <Card>
          <CardHeader color="primary" icon>
            <CardIcon color="primary">
              <Group />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>{title}</h4>
          </CardHeader>
          <CardBody>
            <Table
              columns={columns}
              data={preparedData ?? []}
              key={termsState.selected}
              onChangeRowsPerPage={setPageSize}
              onChangePage={setPage}
              onFilterChange={setFilters}
              onOrderChange={setSort}
              options={{
                initialPage,
                pageSize,
                sort,
                filters,
              }}
            />
          </CardBody>
        </Card>
      </GridItem>
      <EvaluationCreator
        writeMode={WriteModeEnum.create}
        selectedStudentsIds={selectedStudents}
        data={{
          ...initialEvaluation,
          evaluationGroupId,
        }}
        open={showModal}
        setOpen={setShowModal}
      />
    </GridContainer>
  );
};
