/* eslint-disable */
import { ReactNode } from 'react';
import clsx from 'clsx';

import { makeStyles, Theme, createStyles } from 'core/atoms/styles';
import { deepOrange } from 'core/atoms/colors';

import { useStyles } from './typography.styles';

export interface DeepWarningProps {
  children?: ReactNode; // PropTypes.node,
}

const useLocalStyles = makeStyles(() =>
  createStyles({
    deepWarningText: {
      color: deepOrange.A700,
      fontWeight: 500,
    },
  }),
);

export const DeepWarning = (props: DeepWarningProps) => {
  const classes = useStyles();
  const localClasses = useLocalStyles();
  const { children } = props;
  return (
    <div
      className={clsx(classes.defaultFontStyle, localClasses.deepWarningText)}
    >
      {children}
    </div>
  );
};
