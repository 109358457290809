import { ReactNode, useMemo } from 'react';

import { TextItem } from 'core/atoms/types';

import { NavPills } from 'core/cells/nav-pills';

interface TabsProps {
  list: TextItem[];
  tabContent: () => ReactNode;
  value?: string;
  onChange?: (value: string) => void;
}

export const Tabs = (props: TabsProps) => {
  const { list, tabContent, value, onChange } = props;

  const tabs = useMemo(
    () =>
      list.map((item, tabIndex) => {
        return {
          id: item.id,
          tabIndex,
          tabButton: item.text,
          tabContent: tabContent(),
        };
      }),
    [list, tabContent],
  );

  return (
    <NavPills
      alignCenter
      notRounded
      tabs={tabs}
      value={value}
      onChange={onChange}
    />
  );
};
