import { AUTH_SESSION_TOKEN_KEY } from './constants';

const hasSessionToken = () => {
  return !!localStorage.getItem(AUTH_SESSION_TOKEN_KEY);
};

const getSessionToken = (): string | null => {
  return hasSessionToken()
    ? String(localStorage.getItem(AUTH_SESSION_TOKEN_KEY))
    : null;
};

const removeSessionToken = () => {
  hasSessionToken() && localStorage.removeItem(AUTH_SESSION_TOKEN_KEY);
};

const setSessionToken = (token: string | null) => {
  if (token) {
    localStorage.setItem(AUTH_SESSION_TOKEN_KEY, token);
  } else {
    removeSessionToken();
  }
};

export const getSessionTokenStorage = () => {
  return {
    key: AUTH_SESSION_TOKEN_KEY,
    hasSessionToken,
    getSessionToken,
    removeSessionToken,
    setSessionToken,
  };
};
