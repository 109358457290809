import { createContext } from 'react';

import { RootAccessRoles } from 'core/dna/types/access-roles';

export const AccessRolesUpdaterContext = createContext<
  (roles: RootAccessRoles[] | null) => void
>(() => {
  throw new Error('not implemented!');
});

AccessRolesUpdaterContext.displayName = 'AccessRolesUpdater Context';
