import { makeStyles, createStyles } from 'core/atoms/styles';

import { Button } from 'templates/mdp/components/custom-buttons';

export interface WeekSmallProps {
  text: string;
  state?: 'assigned' | 'selected' | 'default';
  onClick?: () => void;
}

const useStyles = makeStyles((theme) => {
  const width = theme.spacing(3);
  const height = theme.spacing(3);
  return createStyles({
    root: {
      margin: theme.spacing(0.3),
      padding: 0,
      width,
      height,
      overflow: 'hidden',
    },
    weekNumber: {
      position: 'absolute',
      margin: 0,
      fontWeight: (props: WeekSmallProps) =>
        props.state === 'selected' ? 800 : 400,
    },
  });
});

export const WeekSmall = (props: WeekSmallProps) => {
  const { text, state, onClick } = props;
  const classes = useStyles(props);

  return (
    <Button
      size="sm"
      color={
        state === 'selected'
          ? 'primary'
          : state === 'assigned'
          ? 'success'
          : undefined
      }
      className={classes.root}
      onClick={onClick}
    >
      <h5 className={classes.weekNumber}>{text}</h5>
    </Button>
  );
};
