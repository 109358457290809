import { useCallback } from 'react';

import { OasError, OasPageError } from 'core/atoms/errors';
import { parseTemplate } from 'core/atoms/functions/string';
import { useTranslations } from 'core/dna/translations';

import { useSnackbar } from 'core/cells/notistack';

import { SubjectTableItem } from 'modules/planner/dna/types/subject';

import {
  useCreateSubject,
  useEditSubject,
} from 'modules/planner/memory/apollo/subjects/remote';
import { useDeleteSubject } from 'modules/planner/memory/apollo/subjects/remote/delete-subject';

const TITLE = 'Use Subjects Main Content Write';

export const useSubjectsTableWrite = () => {
  const { texts } = useTranslations();
  const { createSubject } = useCreateSubject();
  const { editSubject } = useEditSubject();
  const { deleteSubject } = useDeleteSubject();
  const { enqueueSnackbar } = useSnackbar();

  const onRowAdd = (data: {
    name: string;
    alias?: string;
    code?: string;
    bgColor?: string;
    annualHours?: number;
  }) =>
    new Promise<any>((resolve, reject) => {
      createSubject(
        data.name,
        data.alias,
        data.code,
        data.bgColor,
        data.annualHours,
      )
        .then((result) => {
          enqueueSnackbar(
            parseTemplate(texts.oasCommon.entityCreated, {
              entityType: texts.oasCommon.subjects.one,
            }),
            {
              variant: 'success',
            },
          );
          resolve(result);
        })
        .catch((error) => {
          enqueueSnackbar(error.message, { variant: 'error' });
          reject(error);
        });
    });

  const onRowUpdate = (data: {
    id: string;
    name: string;
    alias?: string;
    code?: string;
    bgColor?: string;
    annualHours?: number;
  }) =>
    new Promise<any>((resolve, reject) => {
      editSubject({
        subjectId: data.id,
        name: data.name,
        alias: data.alias,
        code: data.code ?? '',
        bgColor: data.bgColor,
        annualHours: data.annualHours,
      })
        .then((result) => {
          resolve(result);
        })
        .catch((e: any) => {
          reject(e);
        });
    });

  const onRowDelete = useCallback(
    async (oldData: SubjectTableItem) => {
      try {
        const result = await deleteSubject({ subjectId: oldData.id });

        if (result?.statusCode !== 'OK') {
          throw new OasPageError(
            result?.description ??
              parseTemplate(texts.oasCommon.deleteEntitiesOfTypeError.one, {
                entityType: texts.oasCommon.subjects.one,
                entity: oldData.name,
              }),
            { title: TITLE },
          );
        }
        enqueueSnackbar(
          parseTemplate(texts.oasCommon.entitiesOfTypeWereDeleted.one, {
            entityType: texts.oasCommon.subjects.one,
            entity: oldData.name,
          }),
          {
            variant: 'success',
          },
        );
        return 0;
      } catch (e: any) {
        const error =
          e instanceof OasError
            ? e
            : OasPageError.fromError(e, { title: TITLE });
        enqueueSnackbar(
          error.originalMessage ??
            parseTemplate(texts.oasCommon.deleteEntitiesOfTypeError.one, {
              entityType: texts.oasCommon.subjects.one,
              entity: oldData.name,
            }),
          {
            variant: 'error',
          },
        );
        return 1;
      }
    },
    [
      deleteSubject,
      enqueueSnackbar,
      texts.oasCommon.deleteEntitiesOfTypeError.one,
      texts.oasCommon.entitiesOfTypeWereDeleted.one,
      texts.oasCommon.subjects.one,
    ],
  );

  return {
    onRowAdd,
    onRowUpdate,
    onRowDelete,
  };
};
