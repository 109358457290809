import { useMemo } from 'react';

import { gql, useQuery } from 'core/dna/types/apollo';
import { GetSyncStudents } from 'core/dna/types/generated/GetSyncStudents';

import { mapApiSyncStudentListToSyncStudentList } from 'modules/planner/dna/functions/map-ApiSyncStudents-to-SyncStudents';
import { mapApiSyncGroupListToSyncGroupList } from 'modules/planner/dna/functions/map-ApiSyncGroup-to-SyncGroup';
import {
  mapSyncGroupsAndStudentsToTreeDataItemMap,
  mapSyncGroupsAndStudentsToTreeNodes,
} from 'modules/planner/dna/functions/map-sync-groups-and-students-to-tree-data-item-map';

const GET_SYNC_STUDENTS_QUERY = gql`
  query GetSyncStudents {
    SyncStudent {
      id
      tuid
      lastName
      firstName
      groups {
        id
        name
        description
        short
      }
    }
    SyncGroup {
      id
      name
      description
      parentId
      type
      short
    }
  }
`;

export const useGetSyncStudents = () => {
  const { data, loading, error } = useQuery<GetSyncStudents>(
    GET_SYNC_STUDENTS_QUERY,
  );

  const syncStudents = useMemo(() => {
    if (!loading && !error && data?.SyncStudent) {
      return mapApiSyncStudentListToSyncStudentList(data.SyncStudent);
    }
  }, [data, error, loading]);

  const syncGroups = useMemo(() => {
    if (!loading && !error && data?.SyncGroup) {
      return mapApiSyncGroupListToSyncGroupList(data.SyncGroup);
    }
  }, [data, error, loading]);

  const syncStudentGroupMap = useMemo(() => {
    if (!loading && !error && syncStudents && syncGroups) {
      return mapSyncGroupsAndStudentsToTreeDataItemMap(
        syncGroups,
        syncStudents,
      );
    }
  }, [error, loading, syncGroups, syncStudents]);

  const syncClasses = useMemo(() => {
    if (!loading && !error && syncStudents && syncGroups) {
      return mapSyncGroupsAndStudentsToTreeNodes(syncGroups, syncStudents);
    }
  }, [error, loading, syncGroups, syncStudents]);

  return {
    syncStudents,
    syncGroups,
    syncStudentGroupMap,
    syncClasses,
    loading,
    error,
  };
};
