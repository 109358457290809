import { useMemo, useState, useCallback } from 'react';

import { createStyles, makeStyles } from 'core/atoms/styles';

import { useSnackbar } from 'core/cells/notistack';
import { Box, FlexBox } from 'core/cells/box';
import { Typography } from 'core/cells/typography';
import { Dialog } from 'core/cells/dialog';
import { AppBar } from 'core/cells/app-bar';
import { Toolbar } from 'core/cells/toolbar';
import { ExpandedCircularProgress } from 'core/cells/expanded-circular-progress';
import { WhiteButton } from 'core/cells/white-button';

import { useTranslations } from 'core/dna/translations';

import { SlideUpTransition } from 'modules/planner/cells/slide-up-transition';

import { useGetSchedule } from 'modules/planner/memory/apollo/schedules/remote';

import { useIntercom } from 'modules/planner/metabolism/intercom';

import { IntercomHelpButton } from 'modules/planner/organisms/intercom-help-button';

import { LessonGroupCreator } from './components/lesson-group-creator';
import { LessonGroupEditor } from './components/lesson-group-editor';

import { useLessonGroupConstructor } from './use-lesson-group-constructor';

export interface LessonGroupConstructorProps {
  scheduleId: string;
  lessonGroupId?: string;
}

const useStyles = makeStyles((theme) =>
  createStyles({
    appBar: {
      position: 'relative',
    },
    title: {
      flex: 1,
    },
    dialogContent: {
      padding: theme.spacing(2),
      backgroundColor: theme.palette.background.default,
      // backgroundColor: blue['100'],
      height: `calc(100% - ${theme.spacing(6)}px)`,
    },
  }),
);

export const LessonGroupConstructor = (props: LessonGroupConstructorProps) => {
  const { scheduleId, lessonGroupId } = props;

  const classes = useStyles();
  const { texts } = useTranslations();
  const { enqueueSnackbar } = useSnackbar();
  const { productTourId } = useIntercom();
  const [saving, setSaving] = useState(false);

  const { data: schedule } = useGetSchedule({ scheduleId });

  const { saveLessonGroup, clearData, close } = useLessonGroupConstructor({
    scheduleId,
    lessonGroupId,
  });

  const title = useMemo(() => {
    if (lessonGroupId) {
      return `${texts.planner.preparationSpace.schedulesModule.lessonGroupEditing} (${schedule?.name})`;
    }
    return `${texts.planner.preparationSpace.schedulesModule.lessonGroupCreation} (${schedule?.name})`;
  }, [texts, lessonGroupId, schedule]);

  const save = useCallback(
    ({ andClose }: { andClose?: boolean }) => {
      setSaving(true);
      saveLessonGroup()
        .then(({ action }) => {
          enqueueSnackbar(
            texts.planner.preparationSpace.schedulesModule
              .lessonGroupSavedSuccessfully,
            {
              variant: 'success',
            },
          );
          if (action === 'create') {
            andClose ? close() : clearData();
          } else if (action === 'edit') {
            close();
          }
        })
        .catch((reason: any) => {
          if (reason === 'scheduleId') {
            enqueueSnackbar(
              '*Please select schedule to be able to create a lesson group!',
              { variant: 'warning' },
            );
          } else if (reason.message) {
            enqueueSnackbar(reason.message, { variant: 'error' });
          } else {
            enqueueSnackbar(reason, { variant: 'error' });
          }
        })
        .finally(() => {
          setSaving(false);
        });
    },
    [clearData, close, enqueueSnackbar, saveLessonGroup, texts],
  );

  const saveAndClose = () => {
    save({ andClose: true });
  };

  const saveAndContinue = () => {
    save({ andClose: false });
  };

  const onClose = useCallback(() => {
    close();
  }, [close]);

  return (
    <Dialog
      data-name="DIALOG"
      fullScreen
      open={true}
      onClose={onClose}
      scroll="body"
      TransitionComponent={SlideUpTransition}
    >
      <ExpandedCircularProgress open={saving} />
      <AppBar className={classes.appBar}>
        <Toolbar variant="dense">
          <FlexBox className={classes.title}>
            <Typography variant="h6">{title}</Typography>
            {productTourId ? (
              <IntercomHelpButton color="white" tourId={productTourId} />
            ) : (
              <> </>
            )}
          </FlexBox>
          <Box mr={2}>
            <WhiteButton variant="outlined" size="small" onClick={onClose}>
              {texts.planner.common.close}
            </WhiteButton>
          </Box>
          <Box mr={1}>
            <WhiteButton variant="contained" onClick={saveAndClose}>
              {texts.planner.common.saveAndClose}
            </WhiteButton>
          </Box>
          {!lessonGroupId && (
            <WhiteButton variant="contained" onClick={saveAndContinue}>
              {texts.planner.common.save}
            </WhiteButton>
          )}
        </Toolbar>
      </AppBar>
      <div className={classes.dialogContent}>
        {lessonGroupId ? (
          <LessonGroupEditor
            schedule={schedule}
            lessonGroupId={lessonGroupId}
          />
        ) : (
          <LessonGroupCreator />
        )}
      </div>
    </Dialog>
  );
};
