import { useCallback, useMemo, useState } from 'react';
import { Close, Edit, ImportContacts } from '@material-ui/icons';

import { CopyIcon } from 'core/cells/oas-icons';
import { Skeleton } from 'core/cells/skeleton';
import { Typography } from 'core/cells/typography';
import { FlexBox } from 'core/cells/box';

import { useTranslations } from 'core/dna/translations';

import { useLocale } from 'core/dna/routing';
import { useGetTermsState } from 'core/memory/apollo/terms/local';

import { createReactTableStore } from 'core/memory/pure/react-table-store';

import { useReactTableState } from 'core/metabolism/table-state';

import { Table } from 'templates/mdp/components/react-table';
import { GridContainer, GridItem } from 'templates/mdp/components/grid';
import { Button } from 'templates/mdp/components/custom-buttons';
import {
  Card,
  CardBody,
  CardHeader,
  CardIcon,
} from 'templates/mdp/components/card';

import { ScheduleTableItem } from 'modules/planner/dna/types/schedule';
import PlannerPaths from 'modules/planner/routing/paths';
import PlannerUrls from 'modules/planner/routing/urls';

import { ColLessonGroups } from './components/col-lesson-groups';
import { ColPageLink } from './components/col-page-link';

import { ColSharedSchedule } from './components/col-shared-schedule';
import { useSchedulesTableRead } from './use-schedules-table-read';
import { useSchedulesTableWrite } from './use-schedules-table-write';
import { ScheduleEditor } from './schedule-editor';
import { ScheduleDeleteConfirmation } from './components/schedule-delete-confirmation';

export interface SchedulesTableProps {
  onDuplicateClick?: (scheduleData: ScheduleTableItem) => void;
}

export const schedulesTableStore = createReactTableStore({}, {});

export const SchedulesTable = (props: SchedulesTableProps) => {
  const { onDuplicateClick } = props;
  const locale = useLocale();
  const { texts } = useTranslations();
  const { data: termsState } = useGetTermsState();

  const { columns, data, loading } = useSchedulesTableRead();
  const { onRowDelete } = useSchedulesTableWrite();

  const {
    initialPage,
    pageSize,
    sort,
    filters,
    setSort,
    setFilters,
    setPage,
    setPageSize,
  } = useReactTableState(
    schedulesTableStore,
    termsState?.selected ?? undefined,
  );

  const [showModal, setShowModal] = useState(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);

  const [selectedScheduleTableItem, setSelectedScheduleTableItem] = useState<
    Partial<ScheduleTableItem>
  >({ name: '' });

  const handleAdd = () => {
    setSelectedScheduleTableItem({ name: '' });
    setShowModal(true);
  };

  const handleCopy = useCallback(
    async (scheduleTableItemId: string) => {
      const schedule = data!.find(
        (schedule) => schedule.id === scheduleTableItemId,
      );
      if (schedule) {
        onDuplicateClick?.(schedule);
      }
    },
    [data, onDuplicateClick],
  );

  const handleUpdate = useCallback(
    async (scheduleTableItemId: string) => {
      const schedule = data!.find(
        (schedule) => schedule.id === scheduleTableItemId,
      );
      if (schedule) {
        setSelectedScheduleTableItem(schedule);
        setShowModal(true);
      }
    },
    [data],
  );

  const handleDelete = useCallback(
    async (scheduleId: string) => {
      const tableItem = data!.find((schedule) => schedule.id === scheduleId);
      if (tableItem) {
        await onRowDelete(tableItem);
      }
    },
    [data, onRowDelete],
  );

  const onDeleteSchedule = useCallback(
    (schedule: ScheduleTableItem) => {
      if (schedule.lessonGroupCount) {
        setSelectedScheduleTableItem(schedule);
        setShowDeleteConfirmation(true);
      } else {
        handleDelete(schedule.id).then();
      }
    },
    [handleDelete],
  );

  const cleanupAfterDeleteSchedule = useCallback(() => {
    setShowDeleteConfirmation(false);
    setSelectedScheduleTableItem({ name: '' });
  }, []);

  const preparedData = useMemo<any[]>(() => {
    if (!data) {
      return [];
    }

    return data.map((schedule) => {
      return {
        ...schedule,
        [PlannerPaths.Plan.Schedules.LessonGroups.part]: (
          <ColLessonGroups item={schedule} />
        ),
        [PlannerPaths.Plan.Schedules.TimePlan.part]: (
          <ColPageLink
            item={schedule}
            pageUrl={PlannerUrls.timePlan({ locale })}
            text={texts.planner.preparationSpace.schedulesModule.plan}
          />
        ),
        [PlannerPaths.Share.part]: <ColSharedSchedule item={schedule} />,
        appShareCode: (
          <Typography variant="caption">{schedule?.shareCode}</Typography>
        ),
        actions: (
          <div className="actions-right">
            <Button
              justIcon
              round
              simple
              onClick={() => handleCopy(schedule.id)}
              color="info"
              className="copy"
            >
              <CopyIcon />
            </Button>{' '}
            <Button
              justIcon
              round
              simple
              onClick={() => handleUpdate(schedule.id)}
              color="info"
              className="edit"
            >
              <Edit />
            </Button>{' '}
            <Button
              justIcon
              round
              simple
              onClick={() => onDeleteSchedule(schedule)}
              color="danger"
              className="remove"
            >
              <Close />
            </Button>{' '}
          </div>
        ),
      };
    });
  }, [data, handleCopy, handleUpdate, texts, locale, onDeleteSchedule]);

  const goToCourse = useCallback(() => {
    window.open('https://transponder.no/timeplan-kurs', '_blank');
  }, []);

  return loading || !termsState?.selected ? (
    <Skeleton variant="rect" width="100%" height="80vh" />
  ) : (
    <GridContainer>
      <GridItem xs={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button color="secondary" onClick={handleAdd}>
          {texts.planner.preparationSpace.schedulesModule.addSchedule}
        </Button>
      </GridItem>
      <GridItem xs={12}>
        <Card>
          <CardHeader color="primary" icon>
            <CardIcon color="primary">
              <ImportContacts />
            </CardIcon>
            {/* <h4 className={classes.cardIconTitle}>{title}</h4> */}
          </CardHeader>
          <CardBody>
            {!preparedData || !preparedData.length ? (
              <FlexBox justifyContent="center">
                <Button variant="outlined" color="primary" onClick={goToCourse}>
                  Gå til videokurs
                </Button>
              </FlexBox>
            ) : (
              <>
                <ScheduleDeleteConfirmation
                  id={selectedScheduleTableItem.id ?? ''}
                  name={selectedScheduleTableItem.name ?? ''}
                  open={showDeleteConfirmation}
                  onClose={cleanupAfterDeleteSchedule}
                  deleteSchedule={handleDelete}
                />
                <Table
                  columns={columns}
                  data={preparedData}
                  key={termsState?.selected ?? 'unknown_term'}
                  onChangeRowsPerPage={setPageSize}
                  onChangePage={setPage}
                  onFilterChange={setFilters}
                  onOrderChange={setSort}
                  options={{
                    initialPage,
                    pageSize,
                    sort,
                    filters,
                  }}
                />
              </>
            )}
          </CardBody>
        </Card>
      </GridItem>
      <ScheduleEditor
        defaultName={selectedScheduleTableItem.name ?? ''}
        id={selectedScheduleTableItem.id}
        open={showModal}
        setOpen={setShowModal}
      />
    </GridContainer>
  );
};
