import { useCallback } from 'react';

import { OasMemoryError, OasError } from 'core/atoms/errors';

import { gql, useMutation } from 'core/dna/types/apollo';
import { UpdateTimeSlot } from 'core/dna/types/generated/_globalTypes';
import {
  UpdateTimeslotMutation,
  UpdateTimeslotMutationVariables,
} from 'core/dna/types/generated/UpdateTimeslotMutation';

import { GET_SCHEDULE_QUERY } from './_gql';

const UPDATE_TIMESLOT_MUTATION = gql`
  mutation UpdateTimeslotMutation($input: UpdateTimeSlot!) {
    updateTimeSlot(input: $input) {
      id
      pinned
      startTime {
        formatted
      }
      endTime {
        formatted
      }
      weekday
      aiHardConflicts
      aiSoftConflicts
    }
  }
`;

const TITLE = 'use-update-timeslot';

export const useUpdateTimeslot = () => {
  const [updateTimeSlot] = useMutation<
    UpdateTimeslotMutation,
    UpdateTimeslotMutationVariables
  >(UPDATE_TIMESLOT_MUTATION);

  const func = useCallback(
    async (input: { apiInput: UpdateTimeSlot; scheduleId: string }) => {
      try {
        const result = await updateTimeSlot({
          variables: { input: input.apiInput },
          refetchQueries: [
            {
              query: GET_SCHEDULE_QUERY,
              variables: { scheduleId: input.scheduleId },
            },
          ],
        });

        if (result.errors) {
          throw new OasMemoryError(JSON.stringify(result.errors), {
            title: TITLE,
          });
        }
        return result.data?.updateTimeSlot ?? undefined;
      } catch (e: any) {
        if (e instanceof OasError) {
          throw e;
        } else {
          throw OasMemoryError.fromError(e, {
            title: TITLE,
          });
        }
      }
    },
    [updateTimeSlot],
  );

  return {
    updateTimeSlot: func,
  };
};
