import { useMemo, useState, useCallback } from 'react';
import { RecordVoiceOver, Edit } from '@material-ui/icons';

import { Skeleton } from 'core/cells/skeleton';

import { useTranslations } from 'core/dna/translations';
import { RoleType } from 'core/dna/types/generated/_globalTypes';

import { useGetTermsState } from 'core/memory/apollo/terms/local/get-terms-state';
import { createReactTableStore } from 'core/memory/pure/react-table-store';

import { useReactTableState } from 'core/metabolism/table-state';

import { Table } from 'templates/mdp/components/react-table';
import { Button } from 'templates/mdp/components/custom-buttons';
import { GridContainer, GridItem } from 'templates/mdp/components/grid';
import {
  Card,
  CardBody,
  CardHeader,
  CardIcon,
} from 'templates/mdp/components/card';

import {
  TeacherTableItem,
  emptyTeacherItem,
} from 'modules/planner/dna/types/teacher';

import { TeacherEditor } from './teacher-editor';
import { ColAvailability } from './components/col-availability';
import { useTeachersTableRead } from './use-teachers-table-read';

export interface TeachersTableProps {
  onImportTeachersClick?: () => void;
}

export const teachersTableStore = createReactTableStore({}, {});

export const TeachersTable = (props: TeachersTableProps) => {
  const { onImportTeachersClick } = props;
  const { texts } = useTranslations();
  const { data: termsState } = useGetTermsState();

  const { columns, data, loading } = useTeachersTableRead();

  const {
    initialPage,
    pageSize,
    sort,
    filters,
    setSort,
    setFilters,
    setPage,
    setPageSize,
  } = useReactTableState(teachersTableStore, termsState?.selected ?? undefined);

  const [showModal, setShowModal] = useState(false);
  const [selectedTeachersTableItem, setSelectedTeachersTableItem] = useState<
    Partial<TeacherTableItem>
  >(emptyTeacherItem);

  const handleAdd = () => {
    setSelectedTeachersTableItem(emptyTeacherItem);
    setShowModal(true);
  };

  const handleUpdate = useCallback(
    async (teacherTableItemId: string) => {
      const teacher = data.find((t) => t.id === teacherTableItemId);
      if (teacher) {
        setSelectedTeachersTableItem(teacher);
        setShowModal(true);
      }
    },
    [data],
  );

  const preparedData = useMemo<any[]>(() => {
    return data.map((teacher) => {
      return {
        ...teacher,
        availabilityDefined: <ColAvailability item={teacher} />,
        actions: (
          <div className="actions-right">
            <Button
              justIcon
              round
              simple
              onClick={() => handleUpdate(teacher.id)}
              color="info"
              className="edit"
            >
              <Edit />
            </Button>
          </div>
        ),
      };
    });
  }, [data, handleUpdate]);

  return loading || !termsState?.selected ? (
    <Skeleton variant="rect" width="100%" height="80vh" />
  ) : (
    <GridContainer>
      <GridItem xs={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button color="secondary" onClick={handleAdd}>
          {texts.planner.plannerTeachers.addTeacher}
        </Button>
        <Button color="secondary" onClick={onImportTeachersClick}>
          {texts.planner.plannerTeachers.importTeachers}
        </Button>
      </GridItem>
      <GridItem xs={12}>
        <Card>
          <CardHeader color="primary" icon>
            <CardIcon color="primary">
              <RecordVoiceOver />
            </CardIcon>
            {/* <h4 className={classes.cardIconTitle}>{title}</h4> */}
          </CardHeader>
          <CardBody>
            <Table
              columns={columns}
              data={preparedData}
              key={termsState?.selected}
              name="teachers-table"
              onChangeRowsPerPage={setPageSize}
              onChangePage={setPage}
              onFilterChange={setFilters}
              onOrderChange={setSort}
              options={{
                initialPage,
                pageSize,
                sort,
                filters,
              }}
            />
          </CardBody>
        </Card>
      </GridItem>
      <TeacherEditor
        defaultFirstName={selectedTeachersTableItem.firstName ?? ''}
        defaultLastName={selectedTeachersTableItem.lastName ?? ''}
        defaultType={selectedTeachersTableItem.type ?? RoleType.teacher}
        defaultNin={selectedTeachersTableItem.nin}
        defaultReservedEmploymentPercentage={
          selectedTeachersTableItem.reservedEmploymentPercentage
        }
        defaultEmploymentPercentage={
          selectedTeachersTableItem.employmentPercentage
        }
        open={showModal}
        setOpen={setShowModal}
        id={selectedTeachersTableItem.id}
      />
    </GridContainer>
  );
};
