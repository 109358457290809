import React, { FC, ReactNode } from 'react';
import { Button } from 'core/cells/button';

export interface PrimaryButtonProps {
  className?: string;
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  autoFocus?: boolean;
  disabled?: boolean;
  children?: ReactNode;
}

const defaultProps: Partial<PrimaryButtonProps> = {
  autoFocus: false,
  disabled: false,
};

export const PrimaryButton = React.forwardRef(
  (props: PrimaryButtonProps, ref) => {
    const { className, onClick, autoFocus, disabled } = props;

    return (
      <Button
        className={className}
        variant="outlined"
        color="primary"
        onClick={onClick}
        disabled={disabled}
        autoFocus={autoFocus}
        ref={ref}
      >
        {props.children}
      </Button>
    );
  },
);

PrimaryButton.defaultProps = defaultProps;
PrimaryButton.displayName = 'PrimaryButton';
