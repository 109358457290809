import { useMemo } from 'react';
import moment from 'moment';
import { Column } from 'react-table';

import { useTranslations } from 'core/dna/translations';
import { Remark } from 'modules/student/dna/types/remark';
import { timeShort, dateDot } from 'core/atoms/date-time/formats';
import { TypeValues } from 'core/dna/translations/types';

export interface RemarkTableItem extends Remark {
  time: string | null;
}

export const useRemarkTableRead = (remarks: Remark[] | undefined) => {
  const { texts } = useTranslations();

  const columns = useMemo<Column<RemarkTableItem>[]>(
    () => [
      {
        Header: texts.studentModule.remarks.occurredAt,
        accessor: 'occurredAt',
        disableFilters: true,
      },
      {
        Header: texts.studentModule.remarks.type,
        accessor: 'type',
        disableFilters: true,
      },
      {
        Header: texts.planner.common.time,
        accessor: 'time',
        disableFilters: true,
      },
      {
        Header: texts.studentModule.remarks.note,
        accessor: 'note',
        disableFilters: true,
      },
      {
        Header: texts.studentModule.remarks.signedBy,
        accessor: 'signedBy',
        disableFilters: true,
      },
    ],
    [texts],
  );

  const tableData = useMemo<RemarkTableItem[]>(() => {
    if (!remarks) {
      return [];
    }

    return remarks.map((remark) => ({
      ...remark,
      type:
        remark.type &&
        texts.studentModule.remarks.typeValues[remark.type as keyof TypeValues],
      occurredAt:
        remark.occurredAt && moment(remark.occurredAt).utc().format(dateDot),
      time:
        remark.occurredAt && moment(remark.occurredAt).utc().format(timeShort),
    }));
  }, [remarks, texts]);

  return { columns, tableData };
};
