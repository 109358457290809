import { Grid } from 'core/cells/grid';

import { TeachersTable } from './components/teachers-table';

export const TeachersMainContent = () => {
  return (
    <Grid container>
      <Grid item xs={12}>
        <TeachersTable />
      </Grid>
    </Grid>
  );
};
