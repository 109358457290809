import { useCallback } from 'react';

import { OasMemoryError, OasError } from 'core/atoms/errors';

import { gql, useMutation } from 'core/dna/types/apollo';
import {
  EditClassVariables,
  EditClass,
} from 'core/dna/types/generated/EditClass';

import { CLASS_DATA_FRAGMENT } from 'modules/planner/memory/apollo/_fragments';

export interface EditClassInput {
  classId: string;
  name: string;
}

const EDIT_CLASS_MUTATION = gql`
  mutation EditClass($input: EditClassInput) {
    editClass(input: $input) {
      ...ClassData
    }
  }
  ${CLASS_DATA_FRAGMENT}
`;

const TITLE = 'use-edit-class';

export const useEditClass = () => {
  const [editClass, { loading, error }] = useMutation<
    EditClass,
    EditClassVariables
  >(EDIT_CLASS_MUTATION);

  const func = useCallback(
    async (input: EditClassInput) => {
      try {
        const result = await editClass({
          variables: { input },
        });

        if (result.errors) {
          throw new OasMemoryError(JSON.stringify(result.errors), {
            title: TITLE,
          });
        }
        return result.data?.editClass ?? undefined;
      } catch (e: any) {
        if (e instanceof OasError) {
          throw e;
        } else {
          throw OasMemoryError.fromError(e, {
            title: TITLE,
          });
        }
      }
    },
    [editClass],
  );

  return {
    editClass: func,
    loading,
    error,
  };
};
