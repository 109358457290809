import { useCallback } from 'react';

import { OasMemoryError, OasError } from 'core/atoms/errors';

import { gql, useMutation } from 'core/dna/types/apollo';
import { UpdateSubstituteJobInvitationTaskInput } from 'core/dna/types/generated/_globalTypes';

import {
  UpdateSubstituteInvitationTask,
  UpdateSubstituteInvitationTaskVariables,
} from 'core/dna/types/generated/UpdateSubstituteInvitationTask';

// import { GET_SUBSTITUTE_JOBS_QUERY } from 'modules/substitute/memory/apollo/substitute-jobs/remote';
import { GET_SUBSTITUTE_INVITATION_DETAILS_QUERY } from 'modules/substitute/memory/apollo/substitute-invitation-details/remote';

import { useSnackbar } from 'core/cells/notistack';
import { useTranslations } from 'core/dna/translations';

export const UPDATE_SUBSTITUTE_INVITATION_TASK_MUTATION = gql`
  mutation UpdateSubstituteInvitationTask(
    $input: UpdateSubstituteJobInvitationTaskInput!
  ) {
    updateSubstituteJobInvitationTask(input: $input) {
      description
      status
      statusCode
    }
  }
`;

const TITLE = 'use-update-substitute-invitation-task';

export const useUpdateSubstituteInvitationTask = () => {
  const [updateSubstituteInvitationTask] = useMutation<
    UpdateSubstituteInvitationTask,
    UpdateSubstituteInvitationTaskVariables
  >(UPDATE_SUBSTITUTE_INVITATION_TASK_MUTATION);

  const { texts } = useTranslations();
  const { enqueueSnackbar } = useSnackbar();

  const func = useCallback(
    async (input: UpdateSubstituteJobInvitationTaskInput) => {
      try {
        const result = await updateSubstituteInvitationTask({
          variables: {
            input,
          },
          refetchQueries: [
            // {
            //   query: GET_SUBSTITUTE_JOBS_QUERY,
            // },
            {
              query: GET_SUBSTITUTE_INVITATION_DETAILS_QUERY,
              variables: { input: { inviteId: input.inviteId } },
            },
          ],
        });

        if (result.errors) {
          throw new OasMemoryError(JSON.stringify(result.errors), {
            title: TITLE,
          });
        }

        if (
          result?.data &&
          result?.data.updateSubstituteJobInvitationTask &&
          result?.data.updateSubstituteJobInvitationTask.statusCode ===
            'SUBSTITUTE_INVITE_1'
        ) {
          enqueueSnackbar(texts.substitute.jobAlreadyAssigned, {
            variant: 'warning',
          });
        }

        return result.data?.updateSubstituteJobInvitationTask ?? undefined;
      } catch (e) {
        if (e instanceof OasError) {
          throw e;
        } else {
          throw OasMemoryError.fromError(e, {
            title: TITLE,
          });
        }
      }
    },
    [enqueueSnackbar, texts, updateSubstituteInvitationTask],
  );

  return {
    updateSubstituteInvitationTask: func,
  };
};
