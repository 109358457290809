import React, { useCallback, useEffect, useState } from 'react';

import { FlexBox } from 'core/cells/box';

import { Button } from 'templates/mdp/components/custom-buttons';

import { useTranslations } from 'core/dna/translations';
import { JournalType } from 'core/dna/types/generated/_globalTypes';
import { WriteModeEnum } from 'core/dna/types/write-mode';
import { WriteTableModal } from 'core/organisms/write-modal';

import {
  JournalNote,
  JournalSleep,
  JournalDiaper,
  JournalRestock,
} from './journal-create-form';
import { useGetJournalActions } from './journal-manage-actions';

interface JournalCreatorProps {
  tuid: string | null;
  open: boolean;
  setOpen: (open: boolean) => void;
}

export const JournalCreator = (props: JournalCreatorProps) => {
  const { open, setOpen, tuid } = props;

  const { texts } = useTranslations();

  const { journalActionsByOrgCategory } = useGetJournalActions();

  const [
    journalTypeSelected,
    setJournalTypeSelected,
  ] = useState<JournalType | null>(null);

  useEffect(() => {
    if (!open) {
      setJournalTypeSelected(null);
    }
  }, [open]);

  const onClose = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  const manageJournalCreateForm = () => {
    switch (journalTypeSelected) {
      case JournalType.note:
        return (
          <JournalNote
            tuid={tuid}
            setOpen={setOpen}
            resetJournalType={setJournalTypeSelected}
          />
        );
      case JournalType.sleep:
        return (
          <JournalSleep
            tuid={tuid}
            setOpen={setOpen}
            resetJournalType={setJournalTypeSelected}
          />
        );
      case JournalType.diaper:
        return (
          <JournalDiaper
            tuid={tuid}
            setOpen={setOpen}
            resetJournalType={setJournalTypeSelected}
          />
        );
      case JournalType.restock:
        return (
          <JournalRestock
            tuid={tuid}
            setOpen={setOpen}
            resetJournalType={setJournalTypeSelected}
          />
        );
      default:
        return <span>No data</span>;
    }
  };

  return (
    <WriteTableModal
      isShow={open ?? false}
      onClose={onClose}
      writeMode={WriteModeEnum.create}
      title={texts.studentModule.studentCommon.addEvent}
    >
      {!journalTypeSelected ? (
        <FlexBox flexDirection="column">
          {journalActionsByOrgCategory.map((type, index) => (
            <Button
              key={`btn-${index}`}
              fullWidth
              color="info"
              onClick={() => {
                setJournalTypeSelected(type.value);
              }}
            >
              {type.text}
            </Button>
          ))}
        </FlexBox>
      ) : (
        manageJournalCreateForm()
      )}
    </WriteTableModal>
  );
};
