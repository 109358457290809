import { useCallback } from 'react';

import { OasMemoryError, OasError } from 'core/atoms/errors';

import { UpdateOpeningHoursInput } from 'core/dna/types/generated/_globalTypes';
import { useMutation } from 'core/dna/types/apollo';
import { UpdateOpeningHours } from 'core/dna/types/generated/UpdateOpeningHours';
import { useSelectedOrganizationState } from 'core/dna/organizations';

import { GET_ORGANIZATION_QUERY } from 'core/memory/apollo/organizations/remote/graphql';

import { UPDATE_OPENING_HOURS_MUTATION } from './update-opening-hours-mutation';

const FILE_NAME = 'use-update-opening-hours';

export const useUpdateOpeningHours = () => {
  const selected = useSelectedOrganizationState();

  const [
    updateOpeningHours,
    { loading, error },
  ] = useMutation<UpdateOpeningHours>(UPDATE_OPENING_HOURS_MUTATION);

  const func = useCallback(
    async (input: UpdateOpeningHoursInput) => {
      try {
        const result = await updateOpeningHours({
          variables: { input },
          refetchQueries: [
            {
              query: GET_ORGANIZATION_QUERY,
              variables: { organizationId: selected!.id },
            },
          ],
        });

        if (result.errors) {
          throw new OasMemoryError(JSON.stringify(result.errors), {
            title: FILE_NAME,
          });
        }
        return result.data?.updateOpeningsHours ?? undefined;
      } catch (e: any) {
        if (e instanceof OasError) {
          throw e;
        } else {
          throw OasMemoryError.fromError(e, {
            title: FILE_NAME,
          });
        }
      }
    },
    [selected, updateOpeningHours],
  );

  return {
    updateOpeningHours: func,
    loading,
    error,
  };
};
