import { useMemo, useCallback } from 'react';

import {
  Weekday,
  WeekdayObject,
  WeekdayType,
  WeekObject,
} from 'core/atoms/date-time';

import { useTranslations } from 'core/dna/translations';

export const useWeek = () => {
  const { texts } = useTranslations();

  const week: WeekObject = useMemo(
    () => ({
      [Weekday.Monday]: {
        title: texts.planner.common.weekdays.monday.short,
        letter: texts.planner.common.weekdays.monday.short
          .toUpperCase()
          .slice(0, 1),
        day: Weekday.Monday,
      },
      [Weekday.Tuesday]: {
        title: texts.planner.common.weekdays.tuesday.short,
        letter: texts.planner.common.weekdays.tuesday.short
          .toUpperCase()
          .slice(0, 1),
        day: Weekday.Tuesday,
      },
      [Weekday.Wednesday]: {
        title: texts.planner.common.weekdays.wednesday.short,
        letter: texts.planner.common.weekdays.wednesday.short
          .toUpperCase()
          .slice(0, 1),
        day: Weekday.Wednesday,
      },
      [Weekday.Thursday]: {
        title: texts.planner.common.weekdays.thursday.short,
        letter: texts.planner.common.weekdays.thursday.short
          .toUpperCase()
          .slice(0, 1),
        day: Weekday.Thursday,
      },
      [Weekday.Friday]: {
        title: texts.planner.common.weekdays.friday.short,
        letter: texts.planner.common.weekdays.friday.short
          .toUpperCase()
          .slice(0, 1),
        day: Weekday.Friday,
      },
      [Weekday.Saturday]: {
        title: texts.planner.common.weekdays.saturday.short,
        letter: texts.planner.common.weekdays.saturday.short
          .toUpperCase()
          .slice(0, 1),
        day: Weekday.Saturday,
      },
      [Weekday.Sunday]: {
        title: texts.planner.common.weekdays.sunday.short,
        letter: texts.planner.common.weekdays.sunday.short
          .toUpperCase()
          .slice(0, 1),
        day: Weekday.Sunday,
      },
    }),
    [texts],
  );

  const weekArr: WeekdayObject[] = Object.values(week);

  const getWeekday = useCallback(
    (day: Weekday | WeekdayType) => {
      return week[day];
    },
    [week],
  );

  return {
    week,
    weekArr,
    getWeekday,
  };
};
