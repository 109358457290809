import React, { useCallback } from 'react';
import moment from 'moment';
import clsx from 'clsx';
import produce from 'immer';

import Check from '@material-ui/icons/Check';
import Close from '@material-ui/icons/Close';

import { createStyles, makeStyles, Theme } from 'core/atoms/styles';
import { OasOrganismError } from 'core/atoms/errors';
import { Field, Form, FormController } from 'core/cells/forms';
import { FormTextField } from 'core/cells/form-text-field';

import {
  mapTranslationDueToOrgCategoryType,
  useTranslations,
} from 'core/dna/translations';
import { useOrganization } from 'core/metabolism/organizations';

import { Button } from 'templates/mdp/components/custom-buttons';

import { Mode } from 'modules/substitute/organisms/tasks';
import {
  useCreateSubstituteTask,
  useUpdateSubstituteTask,
} from 'modules/substitute/memory/apollo/substitute-jobs/remote';

export interface AppointmentFieldValue {
  id: string;
  subject: string;
  comment: string;
  date: string;
  timeFrom: string;
  timeTo: string;
}

export interface AppointmentFieldProps {
  value: AppointmentFieldValue;
  mode: Mode;
  substituteJobId: string;
  onClose: (mode: Mode) => void;
}

export const emptyAppointmentValue: AppointmentFieldValue = {
  id: '',
  date: '',
  timeFrom: '',
  timeTo: '',
  subject: '',
  comment: '',
};

export const getNewAppointmentFieldValue = () => {
  return produce(emptyAppointmentValue, (draft: AppointmentFieldValue) => {
    draft.date = '';
    draft.timeFrom = '';
    draft.timeTo = '';
  });
};

function formatTime(time: string) {
  let t = moment(time, 'HH:mm:ss');
  if (!t) {
    t = moment(time, 'HH:mm');
  }
  if (t) {
    return t.format('HH:mm');
  }

  throw new OasOrganismError(
    'time should be in either "HH:mm" or "HH:mm:ss" format',
    { title: 'modules/substitute/organisms/tasks' },
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    fullWidth: {
      width: '100%',
    },
    dateTimeField: {
      width: '33.3%',
      '& .MuiInputBase-input': {
        color: 'transparent',
      },
      '& .Mui-focused .MuiInputBase-input': {
        color: 'currentColor',
      },
    },
    dateTimeFieldFilled: {
      '& .MuiInputBase-input': {
        color: 'currentColor',
      },
    },
    pt: {
      paddingTop: '27px',
    },
    pl: {
      paddingLeft: theme.spacing(1),
    },
    pr: {
      paddingRight: theme.spacing(1),
    },
  }),
);

export const AppointmentField = (props: AppointmentFieldProps) => {
  const { value, mode, substituteJobId, onClose } = props;

  const classes = useStyles();
  const { texts } = useTranslations();
  const { organization } = useOrganization();

  const { createSubstituteTask } = useCreateSubstituteTask();
  const { updateSubstituteTask } = useUpdateSubstituteTask();

  const onAcceptEditing = useCallback(
    async (val: AppointmentFieldValue) => {
      if (mode === 'add') {
        await createSubstituteTask({
          date: val.date,
          timeFrom: formatTime(val.timeFrom),
          timeTo: formatTime(val.timeTo),
          subject: val.subject,
          comment: !val.comment ? null : val.comment,
          substituteJobId,
        });
      } else if (mode === 'edit') {
        await updateSubstituteTask({
          substituteTaskId: val.id,
          date: val.date,
          timeFrom: formatTime(val.timeFrom),
          timeTo: formatTime(val.timeTo),
          subject: val.subject,
          comment: !val.comment ? null : val.comment,
        });
      }
      onClose('list');
    },
    [
      createSubstituteTask,
      mode,
      substituteJobId,
      updateSubstituteTask,
      onClose,
    ],
  );

  const onCancelEditing = useCallback(() => {
    onClose('list');
  }, [onClose]);

  return (
    <FormController
      initialValues={value}
      validate={(values) => {
        const errors: Partial<AppointmentFieldValue> = {};
        if (!values.date) {
          errors.date = texts.oasCommon.validation.required;
        }
        if (!values.timeFrom) {
          errors.timeFrom = texts.oasCommon.validation.required;
        }
        if (!values.timeTo) {
          errors.timeTo = texts.oasCommon.validation.required;
        }
        if (!values.subject) {
          errors.subject = texts.oasCommon.validation.required;
        }
        return errors;
      }}
      onSubmit={async (values) => {
        await onAcceptEditing(values);
      }}
    >
      {({ submitForm, isSubmitting, values }) => (
        <Form className={classes.container}>
          <Field
            component={FormTextField}
            name="date"
            type="date"
            label={texts.oasCommon.dates.one}
            disabled={isSubmitting}
            className={clsx(
              classes.dateTimeField,
              classes.pr,
              classes.pt,
              values.date ? classes.dateTimeFieldFilled : '',
            )}
          />
          <Field
            component={FormTextField}
            name="timeFrom"
            type="time"
            label={texts.oasCommon.from}
            disabled={isSubmitting}
            inputProps={{
              step: 300,
            }}
            className={clsx(
              classes.dateTimeField,
              classes.pr,
              classes.pt,
              values.timeFrom ? classes.dateTimeFieldFilled : '',
            )}
          />
          <Field
            component={FormTextField}
            name="timeTo"
            type="time"
            label={texts.oasCommon.to}
            disabled={isSubmitting}
            inputProps={{
              step: 300,
            }}
            className={clsx(
              classes.dateTimeField,
              classes.pr,
              classes.pt,
              values.timeTo ? classes.dateTimeFieldFilled : '',
            )}
          />

          <Field
            component={FormTextField}
            name="subject"
            type="text"
            label={mapTranslationDueToOrgCategoryType(
              texts,
              organization?.category,
              'subject',
            )}
            disabled={isSubmitting}
            fullWidth
          />
          <Field
            component={FormTextField}
            name="comment"
            type="text"
            label={texts.substitute.informationToSubstitute}
            disabled={isSubmitting}
            fullWidth
          />

          <div>
            <Button
              color="success"
              size="sm"
              disabled={isSubmitting}
              onClick={submitForm}
            >
              <Check />{' '}
              {mode === 'add'
                ? texts.planner.common.add
                : mode === 'edit'
                ? texts.planner.common.save
                : ''}
            </Button>
            <Button size="sm" onClick={onCancelEditing}>
              <Close /> {texts.planner.common.close}
            </Button>
          </div>
        </Form>
      )}
    </FormController>
  );
};
