import { gql } from 'core/dna/types/apollo';

// noinspection GraphQLIllegalName
export const localTypeDefs = gql`
  directive @client on FIELD

  extend type Query {
    organizationsState: OrganizationsState
    termsState: TermsState
    modulesState: ModulesState
    routesState: RoutesState
    classStudentsGroupsMapState: ClassStudentsGroupsMap
    selectedClassesState: [String!]
    selectedClassState: String
    selectedRoomsState: [String!]
    selectedSubjectsState: [String!]
    selectedTeachersState: [String!]
    schedulesState: SchedulesState
    selectedScheduleState: String
    timePlanState: TimePlanState
  }

  # COMMON -->

  type StringMapTuple {
    key: String!
    value: String
  }

  type StateTextItem {
    __typename: String!
    id: String!
    text: String
  }

  input TextItemInput {
    id: String!
    text: String!
  }

  input SetSelectedStateInput {
    value: String
  }
  #_________________/ COMMON /_________________#

  # Organizations -->

  type OrganizationsValueState {
    __typename: String!
    id: String!
    name: String!
    accessible: Boolean!
    rootId: String!
    rootName: String!
    rootAccessible: Boolean!
  }

  type OrganizationsState {
    selected: String!
    map: OrganizationsDataStateMap
  }
  #_________________/ Organizations /_________________#

  # Terms -->

  type TermsState {
    selected: String!
  }
  #_________________/ Terms /_________________#

  # Modules -->

  type ModulesValueState {
    __typename: String!
    id: String!
    text: String!
    level: String!
  }

  type ModulesState {
    selected: String!
    values: [ModulesValueState!]!
  }
  #_________________/ Modules /_________________#

  # Modules -->

  type RoutesState {
    module: String!
    selected: String!
    sidebarRoutes: RouteConfigs
    contentRoutes: RouteConfigs
  }
  #_________________/ Modules /_________________#

  # Schedules -->

  enum ScheduleStateStatus {
    Idle
    Running
    Starting
    Stopping
    NotStarted
    NotStopped
  }

  type ScheduleState {
    __typename: String!
    id: String!
    name: String
    status: ScheduleStateStatus
  }

  type SchedulesState {
    selected: [String!]
    subscribed: [String!]
    byId: ScheduleDataStateMap
  }
  #_________________/ Schedules /_________________#

  # TimePlan -->
  type TimePlanState {
    __typename: String!
    scheduleId: String!
    calendarEvents: [CalendarEvent!]
    worklist: WorkList
  }
  #_________________/ TimePlan /_________________#

  scalar ClassStudentsGroupsMap
  scalar OrganizationsDataStateMap
  scalar RouteConfigs
  scalar ScheduleDataStateMap
  scalar CalendarEvent
  scalar WorkList
`;
