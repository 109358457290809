import { useState, ChangeEvent, useCallback, forwardRef } from 'react';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import Dialog from '@material-ui/core/Dialog';

import { parseTemplate } from 'core/atoms/functions/string';

import { useSnackbar } from 'core/cells/notistack';
import { DialogContentText } from 'core/cells/dialog-content-text';

import { useTranslations } from 'core/dna/translations';

import { useStyles } from 'templates/mdp/views/components/notifications.styles';
import { Button } from 'templates/mdp/components/custom-buttons';
import { CustomInput } from 'templates/mdp/components/custom-input';
import { DeepWarning } from 'templates/mdp/components/typography/deep-warning';

import { ScheduleTableItem } from 'modules/planner/dna/types/schedule';

import { useSchedulesTableWrite } from './components/schedules-table/use-schedules-table-write';
import { SchedulesTable } from './components/schedules-table';

const Transition: any = forwardRef(function Transition(props: any, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

/**  /plan/schedules  */
export const Schedules = () => {
  const classes = useStyles();
  const { texts } = useTranslations();
  const { enqueueSnackbar } = useSnackbar();
  const { onRowDuplicate } = useSchedulesTableWrite();

  const [dialogOpened, setDialogOpened] = useState(false);
  const [sourceSchedule, setSourceSchedule] = useState<{
    id: string;
    name: string;
  }>();
  const [duplicateName, setDuplicateName] = useState<string>('');

  const onDuplicateClick = useCallback((scheduleData: ScheduleTableItem) => {
    setSourceSchedule({ id: scheduleData.id, name: scheduleData.name });
    setDialogOpened(true);
  }, []);

  const handleDuplicateNameChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setDuplicateName(event.target.value);
    },
    [],
  );

  const cleanup = useCallback(() => {
    setSourceSchedule(undefined);
    setDuplicateName('');
    setDialogOpened(false);
  }, []);

  const handleDialogClose = useCallback(() => {
    cleanup();
  }, [cleanup]);

  const handleDialogDuplicate = useCallback(() => {
    cleanup();
    onRowDuplicate(duplicateName, sourceSchedule!.id)
      .then(() => {
        enqueueSnackbar(
          `"${duplicateName}" schedule was created as a duplicate of the "${sourceSchedule?.name}"`,
          { variant: 'success' },
        );
      })
      .catch((e: any) => {
        enqueueSnackbar(e.message, { variant: 'error' });
      });
  }, [cleanup, duplicateName, enqueueSnackbar, onRowDuplicate, sourceSchedule]);

  return (
    <>
      <SchedulesTable onDuplicateClick={onDuplicateClick} />
      <Dialog
        TransitionComponent={Transition}
        open={dialogOpened}
        onClose={handleDialogClose}
        aria-labelledby="duplicate-schedule"
        classes={{
          root: `${classes.center} ${classes.modalRoot}`,
          paper: classes.modal,
        }}
      >
        <DialogTitle id="duplicate-schedule" className={classes.modalHeader}>
          {texts.planner.common.copy}
        </DialogTitle>
        <DialogContent className={classes.modalBody}>
          <DeepWarning>
            {texts.planner.preparationSpace.schedulesModule.addScheduleWarning}
          </DeepWarning>
          <DialogContentText>
            {parseTemplate(
              texts.planner.preparationSpace.schedulesModule
                .duplicateScheduleDescription,
              {
                scheduleName: sourceSchedule?.name ?? '',
              },
            )}
          </DialogContentText>
          <CustomInput
            success={false}
            error={false}
            labelText={
              texts.planner.preparationSpace.schedulesModule.newScheduleName
            }
            id="schedule-duplicate-name"
            formControlProps={{ fullWidth: true }}
            inputProps={{
              onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                handleDuplicateNameChange(e);
              },
              type: 'text',
              value: duplicateName,
              autoFocus: true,
            }}
          />
        </DialogContent>
        <DialogActions className={classes.modalFooter}>
          <Button onClick={handleDialogDuplicate} color="transparent">
            {texts.planner.common.copy}
          </Button>
          <Button onClick={handleDialogClose} color="danger" simple>
            {texts.planner.common.cancel}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
