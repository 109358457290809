import { useMemo, useState, Fragment } from 'react';
import clsx from 'clsx';

import { createStyles, makeStyles } from 'core/atoms/styles';
import { deepPurple, green } from 'core/atoms/colors';

import { Box, FlexBox } from 'core/cells/box';
import { Typography } from 'core/cells/typography';

import { Dialog } from 'core/cells/dialog';
import { AppBar } from 'core/cells/app-bar';
import { Toolbar } from 'core/cells/toolbar';
import { ExpandedCircularProgress } from 'core/cells/expanded-circular-progress';
import { TextFilterField } from 'core/cells/text-filter-field';
import { LinkButton } from 'core/cells/link-button';
import { WhiteButton } from 'core/cells/white-button';

import { useTranslations } from 'core/dna/translations';

import { SlideUpTransition } from 'modules/planner/cells/slide-up-transition';

import { useIntercom } from 'modules/planner/metabolism/intercom';
import { useSelectedClass } from 'modules/planner/metabolism/use-selected-class';

import { IntercomHelpButton } from 'modules/planner/organisms/intercom-help-button';

import { Union } from './components/union';

import { useStudentsGroups } from './use-students-groups';

const useStyles = makeStyles((theme) =>
  createStyles({
    appBar: {
      position: 'relative',
    },
    title: {
      flex: 1,
    },
    dialogContent: {
      padding: theme.spacing(2),
      backgroundColor: theme.palette.background.default,
      // backgroundColor: blue['100'],
      height: `calc(100% - ${theme.spacing(6)}px)`,
    },
    m2: {
      margin: theme.spacing(2),
    },
    unionUnassigned: {
      backgroundColor: theme.palette.background.default,
    },
    unionInProgress: {
      backgroundColor: deepPurple['100'],
    },
    unionAllSet: {
      backgroundColor: green['100'],
    },
  }),
);

export const StudentsGroups = () => {
  const classes = useStyles();
  const { texts } = useTranslations();
  const { productTourId } = useIntercom();
  const [filter, setFilter] = useState('');
  const { selectedClass, loading, error } = useSelectedClass();

  const {
    close,
    studentsGroupsState,
    saveAndClose,
    saving,
  } = useStudentsGroups({ selectedClass });

  const title = useMemo(() => {
    return selectedClass
      ? `${selectedClass.name}: Students - Groups editor`
      : 'Students - Groups editor';
  }, [selectedClass]);

  return (
    <Dialog
      data-name="DIALOG"
      fullScreen
      open={true}
      onClose={close}
      scroll="body"
      TransitionComponent={SlideUpTransition}
    >
      <ExpandedCircularProgress open={saving} />
      <AppBar className={classes.appBar}>
        <Toolbar variant="dense">
          <FlexBox className={classes.title}>
            <Typography variant="h6">{title}</Typography>
            {productTourId ? (
              <IntercomHelpButton color="white" tourId={productTourId} />
            ) : (
              <> </>
            )}
          </FlexBox>
          <Box mr={2}>
            <WhiteButton variant="outlined" size="small" onClick={close}>
              {texts.planner.common.close}
            </WhiteButton>
          </Box>
          {!!selectedClass && (
            <Box mr={1}>
              <WhiteButton
                variant="contained"
                onClick={saveAndClose}
                disabled={
                  studentsGroupsState?.assigning ||
                  !studentsGroupsState?.changedFromLastAssignment
                }
              >
                {texts.planner.common.saveAndClose}
              </WhiteButton>
            </Box>
          )}
        </Toolbar>
      </AppBar>
      <Box className={classes.dialogContent}>
        {error && <p>Classes Error!</p>}
        {loading && <p>Loading Classes...</p>}
        {!selectedClass && (
          <p>
            <Typography variant="body2">Class is not found!</Typography>
            <LinkButton onClick={close}>Go back to classes</LinkButton>
          </p>
        )}
        {selectedClass && (
          <>
            <Box ml={2}>
              <TextFilterField
                label={texts.planner.filterStudents}
                value={filter}
                onChange={(e) => {
                  setFilter(e.target.value);
                }}
                onFilterClick={() => {
                  setFilter('');
                }}
              />
            </Box>
            <Box>
              {selectedClass.unions
                ?.filter((u) => (u.chapters?.length ?? 0) > 1)
                .map((u) => {
                  const union = studentsGroupsState?.unions.byId[u.id];
                  return (
                    <Fragment key={`union-${u.id}`}>
                      <Union
                        classId={selectedClass.id}
                        union={u}
                        students={
                          union?.students.filter((item) =>
                            filter
                              ? item.text
                                  .toLowerCase()
                                  .indexOf(filter.toLowerCase()) > -1
                              : true,
                          ) ?? []
                        }
                        classes={{
                          root: clsx(classes.m2, [
                            union?.state === 'unassigned' &&
                              classes.unionUnassigned,
                            union?.state === 'in progress' &&
                              classes.unionInProgress,
                            union?.state === 'all set' && classes.unionAllSet,
                          ]),
                        }}
                      />
                    </Fragment>
                  );
                })}
            </Box>
          </>
        )}
      </Box>
    </Dialog>
  );
};
