import React, { ReactNode } from 'react';
import clsx from 'clsx';

import { useStyles } from './typography.styles';

export interface QuoteProps {
  text?: ReactNode; // PropTypes.node,
  author?: ReactNode; // PropTypes.node,
}

export const Quote = (props: QuoteProps) => {
  const classes = useStyles();
  const { text, author } = props;
  return (
    <blockquote className={clsx(classes.defaultFontStyle, classes.quote)}>
      <p className={classes.quoteText}>{text}</p>
      <small className={classes.quoteAuthor}>{author}</small>
    </blockquote>
  );
};
