import { useCallback, useMemo, useState } from 'react';

import { useHistory, useLocale } from 'core/dna/routing';

import { Class } from 'modules/planner/dna/types/class';

import { useAddMembersToChapter } from 'modules/planner/memory/apollo/classes/remote';
import {
  useGetStudentsGroupsState,
  useSetStudentsGroupsState,
} from 'modules/planner/memory/apollo/classes/local/students-groups';

import PlannerUrls from 'modules/planner/routing/urls';

// import { useStudentsGroupsState } from '../../store/students-groups';

interface UseStudentsGroupsOptions {
  selectedClass?: Class;
}
export const useStudentsGroups = (options: UseStudentsGroupsOptions) => {
  const { selectedClass } = options;
  const history = useHistory();
  const locale = useLocale();
  // const { studentsGroupsState } = useStudentsGroupsState();
  const { addMembersToChapter } = useAddMembersToChapter();
  const [saving, setSaving] = useState(false);
  const { assign: assignStudentsToGroups } = useSetStudentsGroupsState();
  const { studentsGroupsState } = useGetStudentsGroupsState();

  // useEffect(() => {
  //   if (selectedClass?.unions?.length && selectedClass?.students?.length) {
  //     studentsGroupsStore.init(selectedClass.unions, selectedClass.students);
  //   }
  // }, [selectedClass?.students, selectedClass?.unions]);

  const close = useCallback(() => {
    if (selectedClass) {
      history.goBack();
    } else {
      history.replace(PlannerUrls.classes(locale));
    }
  }, [history, locale, selectedClass]);

  const save = useCallback(async () => {
    if (!selectedClass) {
      return;
    }
    setSaving(true);
    await assignStudentsToGroups({
      classId: selectedClass.id,
      assignFunc: addMembersToChapter,
    });
    // await studentsGroupsStore.assign(addMembersToChapter);
    setSaving(false);
  }, [addMembersToChapter, assignStudentsToGroups, selectedClass]);

  const saveAndClose = useCallback(async () => {
    setSaving(true);
    await save();
    setSaving(false);
    close();
  }, [close, save]);

  return {
    close,
    save,
    saveAndClose,
    saving,
    studentsGroupsState,
  };
};
