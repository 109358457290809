import {
  useState,
  useEffect,
  useRef,
  useCallback,
  useMemo,
  createRef,
} from 'react';
import clsx from 'clsx';

import { blue } from 'core/atoms/colors';

import { FormControl } from 'core/cells/form-control';
import { FormHelperText } from 'core/cells/form-helper-text';
import { Input } from 'core/cells/input';
import { InputLabel } from 'core/cells/input-label';
import { FilledInput } from 'core/cells/filled-input';
import { OutlinedInput } from 'core/cells/outlined-input';
import { Chip } from 'core/cells/chip';

import { ChipInputProps } from './chip-input.props';
import { useStyles } from './chip-input.styles';

const variantComponent = {
  standard: Input,
  filled: FilledInput,
  outlined: OutlinedInput,
};

const keyCodes = {
  BACKSPACE: 8,
  DELETE: 46,
  LEFT_ARROW: 37,
  RIGHT_ARROW: 39,
};

export const DefaultChipRenderer = (
  {
    text,
    isFocused,
    isDisabled,
    isReadOnly,
    handleClick,
    handleDelete,
    className,
  }: any,
  key: any,
) => (
  <Chip
    key={key}
    className={className}
    style={{
      pointerEvents: isDisabled || isReadOnly ? 'none' : undefined,
      backgroundColor: isFocused ? blue[300] : undefined,
    }}
    onClick={handleClick}
    onDelete={handleDelete}
    label={text}
  />
);

export const ChipInput = (props: ChipInputProps) => {
  const {
    allowDuplicates,
    alwaysShowPlaceholder,
    chipRenderer = DefaultChipRenderer,
    className,
    clearInputValueOnChange,
    dataSource,
    dataSourceConfig,
    defaultValue,
    delayBeforeAdd,
    disabled,
    disableUnderline,
    error,
    FormHelperTextProps,
    fullWidth,
    fullWidthInput,
    helperText,
    id,
    InputProps = {},
    inputRef: propsInputRef,
    InputLabelProps = {},
    inputValue: propsInputValue,
    label,
    newChipKeyCodes,
    newChipKeys,
    onBeforeAdd,
    onAdd,
    onBlur,
    onDelete,
    onChange,
    onFocus,
    onKeyDown,
    onKeyPress,
    onKeyUp,
    onUpdateInput,
    placeholder,
    readOnly,
    required,
    rootRef,
    value,
    variant = 'standard',
    blurBehavior = 'clear',
    classes: propsClasses,
    ...other
  } = props;

  const classes = useStyles();

  const [chips, setChips] = useState<any[]>([]);
  const [focusedChip, setFocusedChip] = useState<any | null>(null);
  const [inputValue, setInputValue] = useState('');
  const [isFocused, setIsFocused] = useState(false);
  const [chipsUpdated, setChipsUpdated] = useState(false);
  const [prevPropsValue, setPrevPropsValue] = useState<any[]>([]);

  const initialLoad = useRef<boolean>(true);
  const labelRef = useRef<HTMLLabelElement>(null);

  const inputRef = useMemo(
    () => propsInputRef ?? createRef<HTMLInputElement>(),
    [propsInputRef],
  );

  const keyPressedRef = useRef(false);
  const preventChipCreationRef = useRef(false);

  useEffect(() => {
    if (initialLoad.current && defaultValue) {
      initialLoad.current = false;
      setChips(defaultValue);
    }
  }, [defaultValue]);

  const InputComponent = variantComponent[variant];

  useEffect(() => {
    if (props.value && props.value.length !== prevPropsValue.length) {
      setPrevPropsValue(props.value);
      if (props.clearInputValueOnChange) {
        setInputValue('');
      }
    }

    // if change detection is only needed for clearInputValueOnChange
    if (
      props.clearInputValueOnChange &&
      props.value &&
      props.value.length !== prevPropsValue.length
    ) {
      setInputValue('');
      setPrevPropsValue(props.value);
    }

    if (props.disabled) {
      setFocusedChip(null);
    }

    if (!chipsUpdated && props.defaultValue) {
      setChips(props.defaultValue);
    }
  }, [
    chipsUpdated,
    prevPropsValue.length,
    props.clearInputValueOnChange,
    props.defaultValue,
    props.disabled,
    props.value,
  ]);

  /** Focuses this component. */
  const focus = useCallback(() => {
    inputRef.current?.focus();
    if (focusedChip != null) {
      setFocusedChip(null);
    }
  }, [focusedChip, inputRef]);

  const handleInputBlur = (event: any) => {
    if (props.onBlur) {
      props.onBlur(event);
    }
    setIsFocused(false);
    if (focusedChip != null) {
      setFocusedChip(null);
    }
    const { value } = event.target;
    let addChipOptions: any;

    function processAddCase() {
      if (props.delayBeforeAdd) {
        // Lets assume that we only want to add the existing content as chip, when
        // another event has not added a chip within 200ms .
        // e.g. onSelection Callback in Autocomplete case
        const numChipsBefore = (props.value || chips).length;
        const inputBlurTimeout = setTimeout(() => {
          const numChipsAfter = (props.value || chips).length;
          if (numChipsBefore === numChipsAfter) {
            handleAddChip(value, addChipOptions);
          } else {
            clearInput();
          }
        }, 150);
      } else {
        handleAddChip(value, addChipOptions);
      }
    }
    switch (blurBehavior) {
      case 'add-or-clear':
        addChipOptions = { clearInputOnFail: true };
        processAddCase();
        break;
      case 'add':
        processAddCase();
        break;
      case 'clear':
        clearInput();
        break;
      default:
        break;
    }
  };

  const handleInputFocus = (event: any) => {
    setIsFocused(true);
    props.onFocus?.(event);
  };

  const handleKeyDown = (event: any) => {
    keyPressedRef.current = false;
    preventChipCreationRef.current = false;
    if (props.onKeyDown) {
      // Needed for arrow controls on menu in autocomplete scenario
      props.onKeyDown(event);
      // Check if the callback marked the event as isDefaultPrevented() and skip further actions
      // enter key for example should not always add the current value of the inputField
      if (event.isDefaultPrevented()) {
        return;
      }
    }
    const chipsLocal = props.value || chips;
    if (
      (props.newChipKeyCodes &&
        event.keyCode &&
        props.newChipKeyCodes.indexOf(event.keyCode) >= 0) ||
      (props.newChipKeys &&
        event.key &&
        props.newChipKeys.indexOf(event.key) >= 0)
    ) {
      const result = handleAddChip(event.target.value);
      if (result !== false) {
        event.preventDefault();
      }
      return;
    }

    switch (event.keyCode) {
      case keyCodes.BACKSPACE:
        if (event.target.value === '') {
          if (focusedChip != null) {
            handleDeleteChip(chipsLocal[focusedChip], focusedChip);
            if (focusedChip > 0) {
              setFocusedChip(focusedChip - 1);
            }
          } else {
            setFocusedChip(chipsLocal.length - 1);
          }
        }
        break;
      case keyCodes.DELETE:
        if (event.target.value === '' && focusedChip != null) {
          handleDeleteChip(chipsLocal[focusedChip], focusedChip);
          if (focusedChip <= chipsLocal.length - 1) {
            setFocusedChip(focusedChip);
          }
        }
        break;
      case keyCodes.LEFT_ARROW:
        if (
          focusedChip == null &&
          event.target.value === '' &&
          chipsLocal.length
        ) {
          setFocusedChip(chipsLocal.length - 1);
        } else if (focusedChip != null && focusedChip > 0) {
          setFocusedChip(focusedChip - 1);
        }
        break;
      case keyCodes.RIGHT_ARROW:
        if (focusedChip != null && focusedChip < chipsLocal.length - 1) {
          setFocusedChip(focusedChip + 1);
        } else {
          setFocusedChip(null);
        }
        break;
      default:
        setFocusedChip(null);
        break;
    }
  };

  const handleKeyUp = (event: any) => {
    if (
      !preventChipCreationRef.current &&
      ((newChipKeyCodes &&
        event.keyCode &&
        newChipKeyCodes.indexOf(event.keyCode) >= 0) ||
        (newChipKeys && event.key && newChipKeys.indexOf(event.key) >= 0)) &&
      keyPressedRef.current
    ) {
      clearInput();
    } else {
      updateInput(event.target.value);
    }
    props.onKeyUp?.(event);
  };

  const handleKeyPress = (event: any) => {
    keyPressedRef.current = true;
    props.onKeyPress?.(event);
  };

  const handleUpdateInput = (e: any) => {
    if (props.inputValue == null) {
      updateInput(e.target.value);
    }
    props.onUpdateInput?.(e);
  };

  /**
   * Handles adding a chip.
   * @param {string|object} chip Value of the chip, either a string or an object (if dataSourceConfig is set)
   * @param {object=} options Additional options
   * @param {boolean=} options.clearInputOnFail If `true`, and `onBeforeAdd` returns `false`, clear the input
   * @returns True if the chip was added (or at least `onAdd` was called), false if adding the chip was prevented
   */
  function handleAddChip(chip: any, options?: any) {
    if (props.onBeforeAdd && !props.onBeforeAdd(chip)) {
      preventChipCreationRef.current = true;
      if (options?.clearInputOnFail) {
        clearInput();
      }
      return false;
    }
    clearInput();
    const chipsLocal = props.value || chips;

    if (props.dataSourceConfig) {
      if (typeof chip === 'string') {
        chip = {
          [props.dataSourceConfig.text]: chip,
          [props.dataSourceConfig.value]: chip,
        };
      }

      if (
        props.dataSourceConfig &&
        (props.allowDuplicates ||
          !chipsLocal.some(
            (c) =>
              c[props.dataSourceConfig!.value] ===
              chip[props.dataSourceConfig!.value],
          ))
      ) {
        if (props.value && props.onAdd) {
          props.onAdd(chip);
        } else {
          updateChips([...chips, chip]);
        }
      }
      return true;
    }

    if (chip.trim().length > 0) {
      if (props.allowDuplicates || chipsLocal.indexOf(chip) === -1) {
        if (props.value && props.onAdd) {
          props.onAdd(chip);
        } else {
          updateChips([...chips, chip]);
        }
      }
      return true;
    }
    return false;
  }

  function handleDeleteChip(chip: any, i: number) {
    if (!props.value) {
      const chipsLocal = chips.slice();
      const changed = chips.splice(i, 1); // remove the chip at index i
      if (changed) {
        let focusedChipLocal = focusedChip;
        if (focusedChip === i) {
          focusedChipLocal = null;
        } else if (focusedChip > i) {
          focusedChipLocal = focusedChip - 1;
        }
        updateChips(chipsLocal, { focusedChip: focusedChipLocal });
      }
    } else if (onDelete) {
      onDelete(chip, i);
    }
  }

  function updateChips(chips: any, additionalUpdates = {}) {
    // this.setState({ chips, chipsUpdated: true, ...additionalUpdates });
    setChips(chips);
    setChipsUpdated(true);
    onChange?.(chips);
  }

  /**
   * Clears the text field for adding new chips.
   * This only works in uncontrolled input mode, i.e. if the inputValue prop is not used.
   */
  function clearInput() {
    updateInput('');
  }

  function updateInput(value: string) {
    setInputValue(value);
  }

  const chipsLocal = value || chips;
  const actualInputValue =
    propsInputValue != null ? propsInputValue : inputValue;

  const hasInput =
    (props.value || actualInputValue).length > 0 || actualInputValue.length > 0;
  const shrinkFloatingLabel =
    InputLabelProps.shrink != null
      ? InputLabelProps.shrink
      : label != null && (hasInput || isFocused || chipsLocal.length > 0);

  const chipComponents = chipsLocal.map((chip, i) => {
    const value = dataSourceConfig ? chip[dataSourceConfig.value] : chip;
    return chipRenderer(
      {
        value,
        text: dataSourceConfig ? chip[dataSourceConfig.text] : chip,
        chip,
        isDisabled: !!disabled,
        isReadOnly: readOnly,
        isFocused: focusedChip === i,
        handleClick: () => setFocusedChip(i),
        handleDelete: () => handleDeleteChip(chip, i),
        className: classes.chip,
      },
      i,
    );
  });

  const InputMore: any = {};
  if (variant === 'outlined') {
    InputMore.notched = shrinkFloatingLabel;
    InputMore.labelWidth =
      (shrinkFloatingLabel && labelRef.current?.offsetWidth) || 0;
  }

  if (variant !== 'standard') {
    InputMore.startAdornment = <>{chipComponents}</>;
  } else {
    InputProps.disableUnderline = true;
  }

  return (
    <FormControl
      ref={rootRef}
      fullWidth={fullWidth}
      className={clsx(
        className,
        classes.root,
        {
          [classes.marginDense]: other.margin === 'dense',
        },
        propsClasses?.root,
      )}
      error={error}
      required={chips.length > 0 ? undefined : required}
      onClick={focus}
      disabled={disabled}
      variant={variant}
      // classes={{ root: propsClasses?.root }}
      {...other}
    >
      {label && (
        <InputLabel
          htmlFor={id}
          classes={{
            root: clsx(classes[variant], classes.label),
            shrink: classes.labelShrink,
          }}
          shrink={shrinkFloatingLabel}
          focused={isFocused}
          variant={variant}
          ref={labelRef}
          required={required}
          {...InputLabelProps}
        >
          {label}
        </InputLabel>
      )}
      <div
        className={clsx(classes[variant], classes.chipContainer, {
          [classes.focused]: isFocused,
          [classes.underline]: !disableUnderline && variant === 'standard',
          [classes.disabled]: disabled,
          [classes.labeled]: label != null,
          [classes.error]: error,
        })}
      >
        {variant === 'standard' && chipComponents}
        <InputComponent
          classes={{
            input: clsx(classes.input, classes[variant], propsClasses?.input),
            root: clsx(
              classes.inputRoot,
              classes[variant],
              propsClasses?.inputRoot,
            ),
          }}
          id={id}
          value={actualInputValue}
          onChange={handleUpdateInput}
          onKeyDown={handleKeyDown}
          onKeyPress={handleKeyPress}
          onKeyUp={handleKeyUp}
          onFocus={handleInputFocus}
          onBlur={handleInputBlur}
          inputRef={inputRef}
          disabled={disabled}
          fullWidth={fullWidthInput}
          placeholder={
            (!hasInput && (shrinkFloatingLabel || label == null)) ||
            alwaysShowPlaceholder
              ? placeholder
              : null
          }
          readOnly={readOnly}
          {...InputProps}
          {...InputMore}
        />
      </div>
      {helperText && (
        <FormHelperText
          {...FormHelperTextProps}
          className={
            FormHelperTextProps
              ? clsx(FormHelperTextProps.className, classes.helperText)
              : classes.helperText
          }
        >
          {helperText}
        </FormHelperText>
      )}
    </FormControl>
  );
};

ChipInput.defaultProps = {
  allowDuplicates: false,
  blurBehavior: 'clear',
  clearInputValueOnChange: false,
  delayBeforeAdd: false,
  disableUnderline: false,
  newChipKeyCodes: [13],
  newChipKeys: ['Enter'],
  variant: 'standard',
};
