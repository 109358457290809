import { ReactNode } from 'react';
import { useDrop } from 'react-dnd';
import clsx from 'clsx';

import { makeStyles, createStyles, Theme } from 'core/atoms/styles';
import { DraggableType, DraggableItem, DropFn } from 'core/atoms/types';

export interface DroppableProps {
  type: DraggableType;
  onDrop?: DropFn;
  onOver?: (isOver: boolean) => void;
  width?: string | number;
  height?: string | number;
  minHeight?: string | number;
  centeredContent?: boolean;
  children?: ReactNode;
}

const defaultProps: Partial<DroppableProps> = {
  width: '100%',
  height: '100%',
  centeredContent: false,
  minHeight: 0,
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: (props: DroppableProps) => props.width || defaultProps.width,
      height: (props: DroppableProps) => props.height || defaultProps.height,
      minHeight: (props: DroppableProps) =>
        props.minHeight || defaultProps.minHeight,
      padding: theme.spacing(2),
    },
    centeredContent: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },
  }),
);

export const Droppable = (props: DroppableProps) => {
  const classes = useStyles(props);

  const { type, onDrop, children, onOver, centeredContent } = props;

  const [, drop] = useDrop({
    accept: type,
    drop: (item: DraggableItem) => {
      onDrop && onDrop(item);
    },
    collect: (monitor) => {
      const isOver = monitor.isOver();
      onOver && onOver(isOver);
      return {};
    },
  });

  return (
    <div
      ref={drop}
      className={clsx(
        'droppable',
        classes.root,
        centeredContent ? classes.centeredContent : '',
      )}
    >
      {children}
    </div>
  );
};

Droppable.defaultProps = defaultProps;
