/**
=========================================================
* Material Dashboard 2 PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { CSSProperties } from 'react';

// Material Dashboard 2 PRO React base styles
import boxShadows from '../../base/boxShadows';
import typography from '../../base/typography';
import colors from '../../base/colors';
import borders from '../../base/borders';

// Material Dashboard 2 PRO React helper functions
import pxToRem from '../../functions/pxToRem';

const { lg } = boxShadows;
const { size } = typography;
const { text, white } = colors;
const { borderRadius } = borders;

export default {
  defaultProps: {
    disableAutoFocusItem: true,
  },

  styleOverrides: {
    paper: {
      '&.MuiPaper-root': {
        minWidth: pxToRem(160),
        boxShadow: lg,
        padding: `0 ${pxToRem(8)}`,
        fontSize: size.sm,
        color: text.main,
        textAlign: 'left' as CSSProperties['textAlign'],
        backgroundColor: `${white.main}`,
        borderRadius: borderRadius.md,
      },
    },
  },
};
