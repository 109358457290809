/* eslint-disable */
import React, { ReactNode } from 'react';
import clsx from 'clsx';

import { useStyles } from './card-header.styles';

type CardHeaderColor =
  | 'warning'
  | 'success'
  | 'danger'
  | 'info'
  | 'primary'
  | 'rose';

export interface CardHeaderProps {
  className?: string; // PropTypes.string,
  color?: CardHeaderColor; // PropTypes.oneOf([]),
  plain?: boolean; // PropTypes.bool;
  image?: boolean; // PropTypes.bool;
  contact?: boolean; // PropTypes.bool;
  signup?: boolean; // PropTypes.bool;
  stats?: boolean; // PropTypes.bool;
  icon?: boolean; // PropTypes.bool;
  text?: boolean; // PropTypes.bool;
  children?: ReactNode; // PropTypes.node;
}

export const CardHeader = (props: CardHeaderProps) => {
  const classes = useStyles();
  const {
    className,
    children,
    color,
    plain,
    image,
    contact,
    signup,
    stats,
    icon,
    text,
    ...rest
  } = props;

  const cardHeaderClasses = clsx(
    classes.cardHeader,
    color ? (classes as any)[color + 'CardHeader'] : '',
    plain ? classes.cardHeaderPlain : '',
    image ? classes.cardHeaderImage : '',
    contact ? classes.cardHeaderContact : '',
    signup ? classes.cardHeaderSignup : '',
    stats ? classes.cardHeaderStats : '',
    icon ? classes.cardHeaderIcon : '',
    text ? classes.cardHeaderText : '',
    className !== undefined ? className : '',
  );

  return (
    <div className={cardHeaderClasses} {...rest}>
      {children}
    </div>
  );
};
