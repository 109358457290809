import { gql, useQuery } from 'core/dna/types/apollo';
import { GetSchedulesSelectedState } from 'core/dna/types/generated/GetSchedulesSelectedState';
import { useMemo } from 'react';

export const GET_SCHEDULES_SELECTED_STATE_QUERY = gql`
  query GetSchedulesSelectedState {
    schedulesState @client {
      selected
    }
  }
`;

export const useGetSchedulesSelectedState = () => {
  const {
    data: localApiData,
    loading,
    error,
  } = useQuery<GetSchedulesSelectedState>(GET_SCHEDULES_SELECTED_STATE_QUERY);

  const data = useMemo(() => {
    if (localApiData?.schedulesState?.selected?.length && !loading && !error) {
      return localApiData.schedulesState.selected;
    }
  }, [error, loading, localApiData?.schedulesState?.selected]);

  return {
    loading,
    error,
    data,
  };
};
