import { gql } from 'core/dna/types/apollo';
import {
  PERSON_T2_DETAILS_FRAGMENT,
  ABSENCE_DETAILS_FRAGMENT,
  REMARK_DETAILS_FRAGMENT,
  PERSON_T2_CARERS_FRAGMENT,
  PERSON_CONFIGURATION_FRAGMENT,
  JOURNAL_ENTRY_FRAGMENT,
} from 'modules/student/memory/apollo/_fragments';

export const GET_STUDENT_DETAILS_QUERY = gql`
  query GetStudentDetails(
    $tuid: UUID
    $first: Int
    $order: OrderDirection
    $input: PersonT2_DateInputFilter
  ) {
    PersonT2(filter: { tuid: $tuid }) {
      tuid
      details {
        ...PersonDetailsT2
      }
      carers {
        ...PersonT2Carer
      }
      absences(first: $first, order: $order, filter: $input) {
        ...Absence
      }
      remarks(first: $first, order: $order, filter: $input) {
        ...Remark
      }
      configurations {
        ...Configuration
      }
      journals {
        ...JournalEntry
      }
    }
  }
  ${PERSON_T2_DETAILS_FRAGMENT}
  ${PERSON_T2_CARERS_FRAGMENT}
  ${ABSENCE_DETAILS_FRAGMENT}
  ${REMARK_DETAILS_FRAGMENT}
  ${PERSON_CONFIGURATION_FRAGMENT}
  ${JOURNAL_ENTRY_FRAGMENT}
`;
