import { useState, useCallback } from 'react';

export const useDayResourceSelector = () => {
  const [dayResourceSelectorValue, setDayResourceSelectorValue] = useState(
    'days',
  );

  const handleViewChange = useCallback((newValue: string) => {
    setDayResourceSelectorValue(newValue);
  }, []);

  return {
    dayResourceSelectorValue,
    handleViewChange,
  };
};
