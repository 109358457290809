import { Moment } from 'moment';

import { getWeek } from 'core/atoms/date-time';
import { makeStyles, createStyles } from 'core/atoms/styles';
import { grey } from 'core/atoms/colors';
import clsx from 'clsx';

export interface WeekNumberProps {
  date: Date | Moment;
}

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      fontSize: 9,
      width: theme.spacing(4),
      position: 'relative',
      cursor: 'default',
    },
    number: {
      position: 'absolute',
      top: 3,
      right: 3,
      color: grey['600'],
      cursor: 'default',
    },
  }),
);

export const WeekNumber = (props: WeekNumberProps) => {
  const { date } = props;
  const classes = useStyles();

  return (
    <td aria-label="Week Number" className={clsx(classes.root, 'week-number')}>
      <span className={classes.number}>{getWeek(date) ?? ''}</span>
    </td>
  );
};
