import { useCallback } from 'react';

import { longNorPhoneRegex, shortNorPhoneRegex } from 'core/atoms/reg-ex';
import { OasError, OasMemoryError, OasErrorInterface } from 'core/atoms/errors';
import { parseTemplate } from 'core/atoms/functions/string';

import { useSnackbar } from 'core/cells/notistack';

import { useTranslations } from 'core/dna/translations';

import {
  useCreateSubstituteTeacher,
  useEditSubstituteTeacher,
  useInactivateSubstituteTeacher,
  useActivateSubstituteTeacher,
} from 'modules/substitute/memory/apollo/teachers/remote';

const TITLE = 'use-teachers-table-write';

export const useTeachersTableWrite = () => {
  const { texts } = useTranslations();
  const { enqueueSnackbar } = useSnackbar();
  const { createSubstituteTeacher } = useCreateSubstituteTeacher();
  const { editSubstituteTeacher } = useEditSubstituteTeacher();
  const { inactivateSubstituteTeacher } = useInactivateSubstituteTeacher();
  const { activateSubstituteTeacher } = useActivateSubstituteTeacher();

  const toLongFormat = (phone: string) => {
    if (longNorPhoneRegex.test(phone)) {
      return phone;
    }

    if (shortNorPhoneRegex.test(phone)) {
      return `47${phone}`;
    }
  };

  const onRowAdd = useCallback(
    async (item: {
      firstName: string;
      lastName: string;
      phone?: string;
      email?: string;
      tags?: string;
    }) => {
      try {
        const result = await createSubstituteTeacher(
          item.firstName,
          item.lastName,
          item.phone && toLongFormat(item.phone),
          item.email,
          item.tags,
        );
        enqueueSnackbar(
          parseTemplate(texts.oasCommon.entityCreated, {
            entityType: texts.oasCommon.employees.one,
          }),
          {
            variant: 'success',
          },
        );
        return result;
      } catch (e) {
        let error: OasErrorInterface;
        if (e instanceof OasError) {
          error = e;
        } else {
          error = OasMemoryError.fromError(e, {
            title: TITLE,
          });
        }
        enqueueSnackbar(error.message, { variant: 'error' });
        throw error;
      }
    },
    [createSubstituteTeacher, enqueueSnackbar, texts],
  );

  const onRowUpdate = useCallback(
    async (item: {
      id: string;
      firstName: string;
      lastName: string;
      phone?: string;
      email?: string;
      tags?: string;
    }) => {
      try {
        const result = await editSubstituteTeacher(
          item.id,
          item.firstName,
          item.lastName,
          item.phone && toLongFormat(item.phone),
          item.email,
          item.tags,
        );
        enqueueSnackbar(texts.oasCommon.success, {
          variant: 'success',
        });
        return result;
      } catch (e) {
        let error: OasErrorInterface;
        if (e instanceof OasError) {
          error = e;
        } else {
          error = OasMemoryError.fromError(e, {
            title: TITLE,
          });
        }
        enqueueSnackbar(error.message, { variant: 'error' });
        throw error;
      }
    },
    [editSubstituteTeacher, enqueueSnackbar, texts],
  );

  const onRowInactivate = useCallback(
    async (item: {
      id: string;
      firstName: string;
      lastName: string;
      phone?: string;
      email?: string;
      tags?: string;
    }) => {
      try {
        const result = await inactivateSubstituteTeacher(
          item.id,
          item.firstName,
          item.lastName,
          item.phone && toLongFormat(item.phone),
          item.email,
          item.tags,
        );
        enqueueSnackbar(
          parseTemplate(texts.oasCommon.entitiesOfTypeWereDeleted.one, {
            entityType: texts.oasCommon.employees.one,
            entity: `${item.firstName} ${item.lastName}`,
          }),
          {
            variant: 'success',
          },
        );
        return result;
      } catch (e) {
        let error: OasErrorInterface;
        if (e instanceof OasError) {
          error = e;
        } else {
          error = OasMemoryError.fromError(e, {
            title: TITLE,
          });
        }
        enqueueSnackbar(error.message, { variant: 'error' });
        throw error;
      }
    },
    [inactivateSubstituteTeacher, enqueueSnackbar, texts],
  );

  const onRowActivate = useCallback(
    async (item: {
      id: string;
      firstName: string;
      lastName: string;
      phone?: string;
      email?: string;
      tags?: string;
    }) => {
      try {
        const result = await activateSubstituteTeacher(
          item.id,
          item.firstName,
          item.lastName,
          item.phone && toLongFormat(item.phone),
          item.email,
          item.tags,
        );
        enqueueSnackbar(texts.oasCommon.success, {
          variant: 'success',
        });
        return result;
      } catch (e) {
        let error: OasErrorInterface;
        if (e instanceof OasError) {
          error = e;
        } else {
          error = OasMemoryError.fromError(e, {
            title: TITLE,
          });
        }
        enqueueSnackbar(error.message, { variant: 'error' });
        throw error;
      }
    },
    [activateSubstituteTeacher, enqueueSnackbar, texts],
  );

  return {
    onRowAdd,
    onRowUpdate,
    onRowInactivate,
    onRowActivate,
  };
};
