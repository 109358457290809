import { useCallback } from 'react';

import { OasMemoryError, OasError } from 'core/atoms/errors';

import {
  gql,
  ApolloClient,
  NormalizedCacheObject,
} from 'core/dna/types/apollo';
import { GetMyAccessRoles } from 'core/dna/types/generated/GetMyAccessRoles';
import { mapApiMyAccessRolesListToRootAccessRolesList } from 'core/dna/functions';

const GET_MY_ACCESS_ROLES_QUERY = gql`
  query GetMyAccessRoles {
    myAccessRoles {
      id
      name
      accessRoles
      permissions
      organizations {
        id
        name
        accessRoles
        permissions
        category
      }
    }
  }
`;

const FILE_NAME = 'use-load-my-access-roles';

export interface UseLoadMyAccessRolesOptions {
  client: ApolloClient<any>;
}

export const useLoadMyAccessRoles = (options: UseLoadMyAccessRolesOptions) => {
  const { client } = options;
  const loadMyAccessRoles = useCallback(async () => {
    try {
      const result = await client.query<GetMyAccessRoles>({
        query: GET_MY_ACCESS_ROLES_QUERY,
      });

      if (result.errors) {
        throw new OasMemoryError(JSON.stringify(result.errors), {
          title: FILE_NAME,
        });
      }

      const accessRoles = mapApiMyAccessRolesListToRootAccessRolesList(
        result.data.myAccessRoles,
      );

      return accessRoles;
    } catch (e: any) {
      if (e instanceof OasError) {
        throw e;
      } else {
        throw OasMemoryError.fromError(e, {
          title: FILE_NAME,
        });
      }
    }
  }, [client]);

  return {
    loadMyAccessRoles,
  };
};
