import { AvailabilityPreference } from 'modules/planner/dna/types/availability-preference';
import { Resource } from 'modules/planner/dna/types/resource';

export const mapResourceListToAvailabilityPreferenceList = (
  resources: Resource[],
): AvailabilityPreference[] => {
  return resources
    .map(mapResourceToAvailabilityPreferenceList)
    .reduce(
      (previousValue, currentValue) => [...previousValue, ...currentValue],
      [],
    );
};

export const mapResourceToAvailabilityPreferenceList = (
  resource: Resource,
): AvailabilityPreference[] => {
  if (!resource.availabilityPreferences?.length) {
    return [];
  }
  return resource.availabilityPreferences.map(
    (ap) =>
      ({
        resourceId: resource.id,
        ...ap,
      } as AvailabilityPreference),
  );
};
