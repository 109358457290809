import { OasError, OasMemoryError, OasErrorInterface } from 'core/atoms/errors';
import { parseTemplate } from 'core/atoms/functions/string';

import { useSnackbar } from 'core/cells/notistack';

import { useTranslations } from 'core/dna/translations';

import {
  CreateTeacherInput,
  UpdateTeacherInput,
  useCreateTeacher,
  useUpdateTeacher,
} from 'modules/planner/memory/apollo/teachers/remote';

const TITLE = 'use-teachers-table-write';

export const useTeachersTableWrite = () => {
  const { texts } = useTranslations();
  const { enqueueSnackbar } = useSnackbar();
  const { createTeacher } = useCreateTeacher();
  const { updateTeacher } = useUpdateTeacher();

  const onRowAdd = async (input: CreateTeacherInput) => {
    try {
      const result = await createTeacher(input);
      enqueueSnackbar(
        parseTemplate(texts.oasCommon.entityCreated, {
          entityType: texts.oasCommon.employees.one,
        }),
        {
          variant: 'success',
        },
      );
      return result;
    } catch (e: any) {
      let error: OasErrorInterface;
      if (e instanceof OasError) {
        error = e;
      } else {
        error = OasMemoryError.fromError(e, {
          title: TITLE,
        });
      }
      enqueueSnackbar(error.message, { variant: 'error' });
      throw error;
    }
  };

  const onRowUpdate = async (input: UpdateTeacherInput) => {
    try {
      const result = await updateTeacher(input);
      enqueueSnackbar('Teacher updated', { variant: 'success' });
      return result;
    } catch (e: any) {
      let error: OasErrorInterface;
      if (e instanceof OasError) {
        error = e;
      } else {
        error = OasMemoryError.fromError(e, {
          title: TITLE,
        });
      }
      enqueueSnackbar(error.message, { variant: 'error' });
      throw error;
    }
  };

  return {
    onRowAdd,
    onRowUpdate,
  };
};
