import { useEffect, useState, memo } from 'react';
import clsx from 'clsx';

import {
  makeStyles,
  createStyles,
  Theme,
  CSSProperties,
} from 'core/atoms/styles';
import { TextValueData } from 'core/atoms/types';
// import { logger, LogType } from 'core/atoms/housekeeping';

import { Skeleton } from 'core/cells/skeleton';
import { Select } from 'core/cells/select';
import { MenuItem } from 'core/cells/menu-item';

import { useTranslations } from 'core/dna/translations';

// import { useAuthContext } from 'auth/metabolism/use-auth-context';

import { useOrganizationSelector } from './use-organization-selector';
import { OrganizationItem } from './types';

const useStyles = makeStyles((theme: Theme) => {
  const lineMixin: CSSProperties = {
    content: '',
    position: 'absolute',
    backgroundSize: '5px 5px',
    left: 20,
  };
  const getLineBgImage = (angle: number, inverted?: boolean) => {
    const color = inverted ? '#fff' : theme.palette.grey.A100;
    return `linear-gradient(${angle}deg, ${color}, ${color} 75%, transparent 75%, transparent 100%)`;
  };
  return createStyles({
    root: {
      padding: 10,
    },
    formControl: {
      minWidth: 180,
    },
    orgGutters: {
      paddingLeft: theme.spacing(4),
    },
    vLine: {
      ...lineMixin,
      top: 0,
      bottom: 0,
      width: 1,
      backgroundImage: getLineBgImage(0),
    },
    vLineLast: {
      ...lineMixin,
      top: 0,
      bottom: '50%',
      width: 1,
      backgroundImage: getLineBgImage(0),
    },
    vLineSelected: {
      backgroundImage: getLineBgImage(0, true),
    },
    hLine: {
      ...lineMixin,
      top: '50%',
      height: 1,
      width: theme.spacing(1),
      backgroundImage: getLineBgImage(90),
    },
    hLineSelected: {
      backgroundImage: getLineBgImage(90, true),
    },
  });
});

const OrganizationSelectorComponent = () => {
  const classes = useStyles();

  const { lang } = useTranslations();
  // const retriedRef = useRef(false);
  // const { reload } = useAuthContext();

  const {
    onChange,
    selected,
    // loading,
    // error,
    formattedData,
  } = useOrganizationSelector();

  // const [value, setValue] = useState<string>(selected ?? '');
  const [data, setData] = useState<TextValueData<OrganizationItem>[]>(
    formattedData ?? [],
  );

  // useEffect(() => {
  //   setValue(selected ?? '');
  // }, [selected]);
  useEffect(() => {
    setData(formattedData ?? []);
  }, [formattedData]);

  // if (error) {
  //   if (!retriedRef.current) {
  //     logger.debug({
  //       title: 'error -> retrying...',
  //       logger: 'organization-selector',
  //       type: LogType.Accent,
  //       value: [{ ...error }],
  //     });
  //     retriedRef.current = true;
  //     reload();
  //     return <></>;
  //   }
  //   return <p>Organizations Error!</p>;
  // }

  return (
    <div className={classes.root}>
      {!data ? (
        <Skeleton variant="rect" width={180} height={40} />
      ) : (
        <Select
          // palette="light"
          name="organization"
          label={lang.common.organization}
          className={classes.formControl}
          data={data}
          unsorted
          renderItem={(
            item: TextValueData<OrganizationItem>,
            { last, selected },
          ) =>
            item.data?.root ? (
              <MenuItem
                key={item.value}
                value={item.value}
                data-root
                disabled={!item.data!.accessible}
                onClick={() => onChange(item.value, true)}
              >
                {item.text}
              </MenuItem>
            ) : (
              <MenuItem
                key={item.value}
                value={item.value}
                data-root={false}
                disabled={!item.data!.accessible}
                classes={{ gutters: classes.orgGutters }}
                onClick={() => onChange(item.value)}
              >
                <div
                  className={clsx(
                    last ? classes.vLineLast : classes.vLine,
                    selected && classes.vLineSelected,
                  )}
                />
                <div
                  className={clsx(
                    classes.hLine,
                    selected && classes.hLineSelected,
                  )}
                />
                {item.text}
              </MenuItem>
            )
          }
          value={selected?.id ?? ''}
        />
      )}
    </div>
  );
};

OrganizationSelectorComponent.displayName = 'Organization Selector';

export const OrganizationSelector = memo(OrganizationSelectorComponent);
