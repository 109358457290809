import { useCallback, useRef, useEffect } from 'react';

import { OasAuthError } from 'core/atoms/errors';

import { AuthorizationErrorCallback, LogoutCallback } from 'auth/dna/types';

import { getStatusStorage } from 'auth/memory/browser';

import { useAuthContext } from './use-auth-context';

export const useLazyLogout = (
  callback: LogoutCallback,
  errorCallback?: AuthorizationErrorCallback,
) => {
  const { logout, getIdentity, removeAccessToken } = useAuthContext();
  const { getStatus, setStatus } = getStatusStorage();

  const savedCallback = useRef<LogoutCallback>();
  const savedErrorCallback = useRef<AuthorizationErrorCallback>();

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    savedErrorCallback.current = errorCallback;
  }, [errorCallback]);

  const func = useCallback(() => {
    const status = getStatus();
    const identity = getIdentity();
    if (identity) {
      logout?.()
        .then(() => {
          savedCallback.current?.();
        })
        .catch((reason) => {
          setStatus(null);
          removeAccessToken();
          savedErrorCallback.current?.(
            new OasAuthError(reason?.message ?? reason, { data: { status } }),
          );
        });
    } else if ((status ?? null) !== null) {
      setStatus(null);
    }
  }, [getIdentity, getStatus, logout, removeAccessToken, setStatus]);

  return {
    logout: func,
  };
};
