import { useRef, useEffect } from 'react';
import queryString from 'query-string';

import {
  OasAuthError,
  OasError,
  OasMemoryError,
  OasErrorInterface,
} from 'core/atoms/errors';

import { useLocation } from 'core/dna/routing';
import { useTranslations } from 'core/dna/translations';

import { errorsStore } from 'core/metabolism/errors';

import {
  AuthorizationCallback,
  AuthorizationErrorCallback,
  AuthIdentity,
} from 'auth/dna/types';

import { getStatusStorage } from 'auth/memory/browser';

import { useAuthContext } from './use-auth-context';

export const useAuthorize = (
  callback: AuthorizationCallback,
  errorCallback?: AuthorizationErrorCallback,
) => {
  const { authorize, removeAccessToken } = useAuthContext();
  const { getStatus, setStatus } = getStatusStorage();

  const { lang } = useTranslations();

  const location = useLocation();
  const qData = queryString.parse(location.search);

  const savedCallback = useRef<AuthorizationCallback>();
  const savedErrorCallback = useRef<AuthorizationErrorCallback>();

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    savedErrorCallback.current = errorCallback;
  }, [errorCallback]);

  useEffect(() => {
    try {
      const status = getStatus();
      if (status === 'logging-in') {
        if (qData.error) {
          const { error, error_description: errorDescription } = qData;
          throw new OasAuthError(
            errorDescription ? String(errorDescription) : undefined,
            {
              title: String(error),
              data: { status },
            },
          );
        }

        if (qData?.tgt) {
          authorize?.(qData.tgt as string)
            .then((newIdentity: AuthIdentity | null) => {
              savedCallback.current?.(newIdentity);
            })
            .catch((reason) => {
              setStatus(null);
              removeAccessToken();
              const request = reason?.response?.request;
              if (request?.status === 400) {
                errorsStore.setError(
                  new OasAuthError(lang.common.error404.description, {
                    data: { status },
                    title: lang.common.error404.title,
                  }),
                );
              } else {
                errorsStore.setError(
                  new OasAuthError(reason?.message ?? reason, {
                    data: { status },
                  }),
                );
              }
            });
        }
      }
    } catch (e: any) {
      let error: OasErrorInterface;
      if (e instanceof OasError) {
        error = e;
      } else {
        error = OasMemoryError.fromError(e, {
          title: 'useAuthorize => useEffect',
        });
      }
      errorsStore.setError(error);
    }
  }, [authorize, getStatus, lang, qData, setStatus, removeAccessToken]);
};
