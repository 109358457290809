import { useEffect, useMemo, useState } from 'react';
import { useTranslations } from 'core/dna/translations';
import { GroupWithTotalAbsences } from 'modules/reports/dna/types';

interface GroupAbsencesTableItem extends GroupWithTotalAbsences {
  include: boolean;
}

export const useGroupsAbsenceTableRead = (data: GroupWithTotalAbsences[]) => {
  const { texts } = useTranslations();

  const preparedData = useMemo<GroupAbsencesTableItem[]>(() => {
    if (!data) {
      return [];
    }

    return data.map((group) => {
      return {
        ...group,
        include: true,
      };
    });
  }, [data]);

  const [tableData, setTableData] = useState<GroupAbsencesTableItem[]>(
    preparedData,
  );

  useEffect(() => {
    setTableData(preparedData);
  }, [preparedData]);

  // const onIncludeToggle = useCallback(
  //   (id: string) => {
  //     const index = tableData.findIndex((el) => el.id === id);
  //     const group = tableData.find((group) => group.id === id);
  //
  //     if (!group || index === undefined) {
  //       return;
  //     }
  //
  //     group.include = !group.include;
  //     tableData.splice(index, 1, group);
  //
  //     setTableData([...tableData]);
  //   },
  //   [tableData],
  // );

  const columns = useMemo(
    () => [
      // {
      //   Header: 'Include',
      //   accessor: 'include',
      //   Cell: (include: any) => (
      //     <CheckboxCell {...include} onToggle={onIncludeToggle} />
      //   ),
      //   width: 60,
      //   disableFilters: true,
      // },
      {
        Header: texts.oasCommon.group.one,
        accessor: 'name',
      },
      {
        Header: texts.planner.common.hours.many,
        accessor: 'total.hours',
        disableFilters: true,
        // minWidth: 80,
        // width: '12.5%',
      },
      {
        Header: texts.planner.common.days.many,
        accessor: 'total.days',
        disableFilters: true,
        // minWidth: 80,
        // width: '12.5%',
      },
    ],
    [texts],
  );

  return {
    title: texts.oasCommon.group.many,
    columns,
    tableData,
  };
};
