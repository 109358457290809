import { useCallback } from 'react';
import { IntercomAPI } from 'react-intercom';
import clsx from 'clsx';

import { logger } from 'core/atoms/housekeeping';
import { makeStyles, createStyles } from 'core/atoms/styles';
import { grey } from 'core/atoms/colors';

import { IconButton } from 'core/cells/icon-button';
import { HelpIcon } from 'core/cells/oas-icons';

export interface IntercomHelpButtonProps {
  className?: string;
  tourId?: number;
  color?: 'inherit' | 'primary' | 'secondary' | 'default' | 'white';
}

const useStyles = makeStyles(() =>
  createStyles({
    white: {
      color: grey['50'],
    },
  }),
);

const TITLE = 'Intercom Help Button';

export const IntercomHelpButton = (props: IntercomHelpButtonProps) => {
  const classes = useStyles();

  const handleClick = useCallback(() => {
    logger.info(`[${TITLE}] onClick => tourId: "${props.tourId}"`);
    IntercomAPI('startTour', props.tourId);
  }, [props.tourId]);

  const ibProps: any = {
    'data-intercom-target': TITLE,
    className: props.className ?? '',
    onClick: handleClick,
  };

  if (props.color === 'white') {
    ibProps.className = clsx(ibProps.className, classes.white);
  } else {
    ibProps.color = props.color ?? 'default';
  }

  return (
    <IconButton {...ibProps}>
      <HelpIcon color="inherit" />
    </IconButton>
  );
};
