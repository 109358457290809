interface DisplayCellStateProps {
  value: Array<string[]> | undefined;
  // row: any;
  // data: string[] | undefined;
}

export const DisplayCellHours = (props: DisplayCellStateProps) => {
  const { value } = props;

  if (!value) return <span />;

  return <div>{`${value[0]} - ${value[1]}`}</div>;
};
