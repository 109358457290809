import { useCallback } from 'react';

import { useRouteMatch } from 'core/dna/routing/use-route-match';

import { PATHS as UnsetPaths } from 'modules/unset/paths';
import PlannerPaths from 'modules/planner/routing/paths';
import { PATHS as StudentPaths } from 'modules/student/paths';
import { PATHS as SubstitutePaths } from 'modules/substitute/paths';
import { PATHS as ReportsPaths } from 'modules/reports/paths';
import { PATHS as GradesPaths } from 'modules/grades/paths';

export const useRouteModule = () => {
  const matchHome = useRouteMatch(UnsetPaths.path);
  const matchPlanner = useRouteMatch(PlannerPaths.path);
  const matchStudent = useRouteMatch(StudentPaths.path);
  const matchSubstitute = useRouteMatch(SubstitutePaths.path);
  const matchReports = useRouteMatch(ReportsPaths.path);
  const matchGrades = useRouteMatch(GradesPaths.path);

  const getSelectedModuleFromRoute = useCallback(() => {
    let moduleId = '';
    if (matchPlanner) {
      moduleId = PlannerPaths.part;
    } else if (matchStudent) {
      moduleId = StudentPaths.part;
    } else if (matchSubstitute) {
      moduleId = SubstitutePaths.part;
    } else if (matchReports) {
      moduleId = ReportsPaths.part;
    } else if (matchGrades) {
      moduleId = GradesPaths.part;
    } else if (matchHome) {
      moduleId = UnsetPaths.part;
    }
    return moduleId;
  }, [
    matchHome,
    matchPlanner,
    matchReports,
    matchStudent,
    matchSubstitute,
    matchGrades,
  ]);

  return {
    getSelectedModuleFromRoute,
  };
};
