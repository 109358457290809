import { SELECTED_SCHEDULES } from './constants';

const hasSelectedSchedules = () => {
  return !!localStorage.getItem(SELECTED_SCHEDULES);
};

const getSelectedSchedules = (): string[] | null => {
  return hasSelectedSchedules()
    ? (JSON.parse(localStorage.getItem(SELECTED_SCHEDULES) ?? '[]') as string[])
    : null;
};

const removeSelectedSchedule = (id: string) => {
  if (!hasSelectedSchedules()) {
    return;
  }
  const filtered = getSelectedSchedules()?.filter((ss) => ss !== id) ?? null;
  setSelectedSchedules(filtered);
};

const removeSelectedSchedules = () => {
  hasSelectedSchedules() && localStorage.removeItem(SELECTED_SCHEDULES);
};

const setSelectedSchedules = (schedules: string[] | null) => {
  if (schedules?.length) {
    localStorage.setItem(SELECTED_SCHEDULES, JSON.stringify(schedules));
  } else {
    removeSelectedSchedules();
  }
};

export const getSelectedSchedulesStorage = () => {
  return {
    key: SELECTED_SCHEDULES,
    hasSelectedSchedules,
    getSelectedSchedules,
    removeSelectedSchedule,
    removeSelectedSchedules,
    setSelectedSchedules,
  };
};
