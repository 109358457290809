import React from 'react';
import moment from 'moment';
import ical from 'ical-generator';

import { useTranslations } from 'core/dna/translations';
import { SubstituteTask } from 'modules/substitute/dna/types/substitute-task';
import { Button } from 'templates/mdp/components/custom-buttons';

interface IcsLinkProps {
  task: SubstituteTask;
}

export const IcsLink = (props: IcsLinkProps) => {
  const { task } = props;

  const { texts } = useTranslations();

  const format = 'YYYY-MM-DD HH:mm:ss';
  const from = `${task.date} ${task.timeFrom}`;
  const to = `${task.date} ${task.timeTo}`;

  const cal = ical({
    domain: 'transponder.no',
    prodId: { company: 'transponder.no', product: 'ical-generator' },
    name: task.subject,
    timezone: 'CET',
  });

  cal.createEvent({
    start: moment(from, format),
    end: moment(to, format),
    summary: task.subject,
    description: `Kommentar: ${
      task.comment || 'Ingen kommentar'
    }. Invitasjonskoblingen din: ${window.location.href}`,
    url: window.location.href,
  });

  const str = cal.toString();

  const downloadBlob = (fileName: string, data: string) => {
    const link = document.createElement('a');
    link.href = `data:text/calendar,${encodeURIComponent(data)}`;
    link.target = '_blank';
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
  };

  return (
    <Button
      style={{ marginRight: 15 }}
      color="success"
      size="sm"
      onClick={() => downloadBlob(`${task.subject}.ics`, str)}
    >
      {texts.substitute.addToCalendar}
    </Button>
  );
};
