const get = (path: string, obj: any, fb = `$\{${path}}`) => {
  return path.split('.').reduce((res, key) => res[key] || fb, obj);
};

export const parseTemplate = (
  template: string,
  map: { [key: string]: any },
  fallback?: string,
) => {
  return template.replace(/\${.+?}/g, (match) => {
    const path = match.substr(2, match.length - 3).trim();
    return get(path, map, fallback);
  });
};
