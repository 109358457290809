import { useMemo } from 'react';

import { useGetClasses } from 'modules/planner/memory/apollo/classes/remote';
import { useGetSelectedClassState } from 'modules/planner/memory/apollo/classes/local';

export const useSelectedClass = () => {
  const { classes, classesLoading, classesError } = useGetClasses();
  const {
    selectedClassState: selectedClassId,
    loading,
    error,
  } = useGetSelectedClassState();

  const selectedClass = useMemo(() => {
    if (!classesError && !classesLoading && classes?.length) {
      return classes.find((c) => c.id === selectedClassId);
    }
  }, [classes, classesError, classesLoading, selectedClassId]);

  return {
    selectedClass,
    loading: classesLoading || loading,
    error: classesError || error,
  };
};
