import { useContext } from 'react';

import { ModulesUpdaterContext } from './modules-updater-context';

export const useModulesUpdater = () => {
  const setModules = useContext(ModulesUpdaterContext);
  if (typeof setModules === 'undefined') {
    throw new Error('useModulesUpdater must be used within a ModulesProvider');
  }
  return setModules;
};
