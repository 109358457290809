import { tick } from 'core/atoms/date-time';
import { checkType, Types } from 'core/atoms/functions/type-check';

export const formatGroupMessage = (
  entity: any,
  title: string,
  titleStyle: string,
  loggerName = 'console',
) => {
  let entityType: string;
  switch (checkType(entity)) {
    case Types.Array:
      entityType = `Array of ${entity.length} items`;
      break;
    case Types.Object:
      entityType = 'Object';
      break;
    case Types.String:
      entityType = 'String';
      break;
    default:
      entityType = '';
  }

  const headerCss = [
    'color: inherit;', // tick().format('YYYY-MM-DD HH:mm:ss.SSS')
    'color: gray; font-weight: lighter;', // loggerName
    titleStyle, // title
    entityType === 'String'
      ? 'color: orange; font-weight: lighter;'
      : 'color: gray; font-weight: lighter;', // entityType
  ];

  const parts = [
    `%c ${tick().format('YYYY-MM-DD HH:mm:ss.SSS')}`,
    `%c ${loggerName}`,
    `%c ${title}`,
    `%c ${
      entityType ? (entityType === 'String' ? entity : `(${entityType})`) : ''
    }`,
  ];

  return [parts.join(' '), ...headerCss];
};
