import { RoleType } from 'core/dna/types/generated/_globalTypes';
import { GetSchedule_Schedule_lessonGroups_lessons as ApiGetSchedulesLesson } from 'core/dna/types/generated/GetSchedule';
import { GetLessons_Lesson as ApiGetLessonsLesson } from 'core/dna/types/generated/GetLessons';
import {
  mapNullableListToList,
  mapNullableNumberToNumber,
} from 'core/dna/functions';

import { Lesson } from 'modules/planner/dna/types/lesson';

import { mapApiSubjectListToSubjectList } from '../map-ApiSubject-to-Subject';
import { mapApiRoomListToRoomList } from '../map-ApiRoom-to-Room';
import { mapApiChapterListToLessonChapterList } from '../map-ApiChapter-to-LessonChapter';
import { mapApiTeacherListToTeacherList } from '../map-ApiTeacher-to-Teacher';
import { mapApiClassDataListToClassDataList } from '../map-ApiClassData-to-ClassData';
import { mapApiChapterListToChapterDataList } from '../map-ApiChapter-to-ChapterData';

export const mapApiLessonListToLessonList = (
  apiLessonList: (ApiGetSchedulesLesson | ApiGetLessonsLesson | null)[] | null,
): Lesson[] | undefined => {
  return mapNullableListToList<
    ApiGetSchedulesLesson | ApiGetLessonsLesson,
    Lesson
  >(apiLessonList, mapApiLessonToLesson);
};

export const mapApiLessonToLesson = (
  apiLesson: ApiGetSchedulesLesson | ApiGetLessonsLesson | null,
): Lesson | undefined => {
  if (!apiLesson) {
    return;
  }

  const { id, subjects, rooms, teachers, chapters, lessonGroup } = apiLesson;

  return {
    id,
    title: subjects?.[0]?.name,
    bgColor: subjects?.[0]?.bgColor || '#cecece',
    lessonGroupId: lessonGroup?.id,
    plannedMinutesPerTimeSlot: mapNullableListToList(
      lessonGroup?.plannedMinutesPerTimeSlot ?? null,
      mapNullableNumberToNumber,
    ),
    subjects: mapApiSubjectListToSubjectList(subjects),
    classes: mapApiClassDataListToClassDataList(
      chapters?.map((ch) => ch?.union?.class ?? null) ?? null,
    )?.map((c) => ({ id: c.id, text: c.name })),
    groups: mapApiChapterListToChapterDataList(chapters)?.map((c) => ({
      id: c.id,
      text: c.name || 'GROUP',
    })),
    teachers: mapApiTeacherListToTeacherList(teachers)?.map((t) => {
      return {
        id: t.id,
        text: t.person?.name || t.title || 'TEACHER',
        data: { type: t.type ?? RoleType.teacher },
      };
    }),
    rooms: mapApiRoomListToRoomList(rooms)?.map((r) => ({
      id: r.id,
      text: r.name,
    })),
    chapters: mapApiChapterListToLessonChapterList(chapters),
  };
};
