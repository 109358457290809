import { GetSchedule_Schedule_lessonGroups_lessons_chapters_union_class as ApiClassData } from 'core/dna/types/generated/GetSchedule';
import { mapNullableListToList } from 'core/dna/functions/map-nullable-list-to-list';

import { ClassData } from 'modules/planner/dna/types/class-data';

export const mapApiClassDataListToClassDataList = (
  apiClassDataList: (ApiClassData | null)[] | null,
): ClassData[] | undefined => {
  return mapNullableListToList<ApiClassData, ClassData>(
    apiClassDataList,
    mapApiClassDataToClassData,
  );
};

export const mapApiClassDataToClassData = (
  apiClassData: ApiClassData | null,
): ClassData => {
  if (apiClassData === null) {
    throw new TypeError('"apiClassData" should not be null!');
  }

  const { id, name } = apiClassData;
  return {
    id,
    name: name ?? 'noname',
  };
};
