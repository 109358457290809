/* eslint-disable */
import React, { ReactNode, forwardRef } from 'react';
import clsx from 'clsx';
// @material-ui/core components
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Input from '@material-ui/core/Input';

import { useStyles } from './custom-input.styles';

export interface CustomInputProps {
  labelText?: ReactNode; // PropTypes.node,
  labelProps?: any; // PropTypes.object,
  id?: string; // PropTypes.string,
  inputProps?: any; // PropTypes.object,
  formControlProps?: any; // PropTypes.object,
  inputRootCustomClasses?: string; // PropTypes.string;
  error?: boolean; // PropTypes.bool;
  success?: boolean; // PropTypes.bool;
  white?: boolean; // PropTypes.bool;
  helperText?: ReactNode; // PropTypes.node;
}

export const CustomInput = forwardRef((props: CustomInputProps, ref: any) => {
  const classes = useStyles();
  const {
    formControlProps,
    labelText,
    id,
    labelProps,
    inputProps: rawInputProps,
    error,
    white,
    inputRootCustomClasses,
    success,
    helperText,
  } = props;

  const { inputRef, ...inputProps } = rawInputProps;

  const labelClasses = clsx({
    [classes.labelRootError]: error,
    [classes.labelRootSuccess]: success && !error,
  });
  const underlineClasses = clsx({
    [classes.underlineError]: error,
    [classes.underlineSuccess]: success && !error,
    [classes.underline]: true,
    [classes.whiteUnderline]: white,
  });
  const marginTop = clsx(
    inputRootCustomClasses ? { [inputRootCustomClasses]: true } : {},
  );
  const inputClasses = clsx({
    [classes.input]: true,
    [classes.whiteInput]: white,
  });
  let formControlClasses;
  if (formControlProps !== undefined) {
    formControlClasses = clsx(formControlProps.className, classes.formControl);
  } else {
    formControlClasses = classes.formControl;
  }
  const helpTextClasses = clsx({
    [classes.labelRootError]: error,
    [classes.labelRootSuccess]: success && !error,
  });
  const newInputProps = {
    maxLength:
      inputProps && inputProps.maxLength ? inputProps.maxLength : undefined,
    minLength:
      inputProps && inputProps.minLength ? inputProps.minLength : undefined,
  };
  return (
    <FormControl {...formControlProps} className={formControlClasses}>
      {labelText !== undefined ? (
        <InputLabel
          className={clsx(classes.labelRoot, labelClasses)}
          htmlFor={id}
          {...labelProps}
        >
          {labelText}
        </InputLabel>
      ) : null}
      <Input
        classes={{
          input: inputClasses,
          root: marginTop,
          disabled: classes.disabled,
          underline: underlineClasses,
        }}
        id={id}
        inputRef={ref}
        {...inputProps}
        inputProps={newInputProps}
      />
      {helperText !== undefined ? (
        <FormHelperText id={id + '-text'} className={helpTextClasses}>
          {helperText}
        </FormHelperText>
      ) : null}
    </FormControl>
  );
});
