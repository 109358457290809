/* eslint-disable */
import React, { ReactNode } from 'react';
import clsx from 'clsx';

import { useStyles } from './card-body.styles';

export interface CardBodyProps {
  className?: string; // PropTypes.string;
  background?: boolean; // PropTypes.bool;
  plain?: boolean; // PropTypes.bool;
  formHorizontal?: boolean; // PropTypes.bool;
  pricing?: boolean; // PropTypes.bool;
  signup?: boolean; // PropTypes.bool;
  color?: boolean; // PropTypes.bool;
  profile?: boolean; // PropTypes.bool;
  calendar?: boolean; // PropTypes.bool;
  children?: ReactNode; // PropTypes.node;
  style?: React.CSSProperties;
}

export const CardBody = (props: CardBodyProps) => {
  const classes = useStyles();
  const {
    className,
    children,
    background,
    plain,
    formHorizontal,
    pricing,
    signup,
    color,
    profile,
    calendar,
    ...rest
  } = props;

  const cardBodyClasses = clsx(
    classes.cardBody,
    background ? classes.cardBodyBackground : '',
    plain ? classes.cardBodyPlain : '',
    formHorizontal ? classes.cardBodyFormHorizontal : '',
    pricing ? classes.cardPricing : '',
    signup ? classes.cardSignup : '',
    color ? classes.cardBodyColor : '',
    profile ? classes.cardBodyProfile : '',
    calendar ? classes.cardBodyCalendar : '',
    className !== undefined ? className : '',
  );

  return (
    <div className={cardBodyClasses} {...rest}>
      {children}
    </div>
  );
};
