import { useContext } from 'react';

import { OrganizationsStateContext } from './organizations-state-context';

export const useOrganizationsState = () => {
  const organizationsState = useContext(OrganizationsStateContext);
  if (typeof organizationsState === 'undefined') {
    throw new Error(
      'useOrganizationsState must be used within a OrganizationsProvider',
    );
  }
  return organizationsState;
};
