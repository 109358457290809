import { GetSchedule_Schedule_lessonGroups_lessons_teachers as ApiGetSchedulesTeacher } from 'core/dna/types/generated/GetSchedule';
import { GetLessons_Lesson_teachers as ApiGetLessonsTeacher } from 'core/dna/types/generated/GetLessons';
import { mapNullableListToList } from 'core/dna/functions';

import { Teacher } from 'modules/planner/dna/types/teacher';
import { mapApiPersonToPerson } from '../map-ApiPerson-to-Person';
import { mapApiAvailabilityPreferenceListToAvailabilityPreferenceList } from '../map-ApiAvailabilityPreference-to-AvailabilityPreference';

export const mapApiTeacherListToTeacherList = (
  apiTeacherList:
    | (ApiGetSchedulesTeacher | ApiGetLessonsTeacher | null)[]
    | null,
): Teacher[] | undefined => {
  return mapNullableListToList<
    ApiGetSchedulesTeacher | ApiGetLessonsTeacher,
    Teacher
  >(apiTeacherList, mapApiTeacherToTeacher);
};

export const mapApiTeacherToTeacher = (
  apiTeacher: ApiGetSchedulesTeacher | ApiGetLessonsTeacher | null,
): Teacher | undefined => {
  if (!apiTeacher) {
    return;
  }

  const { id, title, person, availabilityPreferences, type } = apiTeacher;

  return {
    id,
    title: title ?? undefined,
    person: mapApiPersonToPerson(person),
    availabilityPreferences: mapApiAvailabilityPreferenceListToAvailabilityPreferenceList(
      availabilityPreferences,
    ),
    type: type ?? undefined,
  };
};
