import { MODULE_KEY } from './constants';

const hasModule = () => {
  return !!localStorage.getItem(MODULE_KEY);
};

const getModule = (): string | null => {
  return hasModule() ? localStorage.getItem(MODULE_KEY) : null;
};

const removeModule = () => {
  hasModule() && localStorage.removeItem(MODULE_KEY);
};

const setModule = (term: string | null) => {
  if (term) {
    localStorage.setItem(MODULE_KEY, term);
  } else {
    removeModule();
  }
};

export const getModuleStorage = () => {
  return {
    key: MODULE_KEY,
    hasModule,
    getModule,
    removeModule,
    setModule,
  };
};
