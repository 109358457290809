import { useMemo } from 'react';

import { useQuery } from 'core/dna/types/apollo';

import { Subject } from 'modules/planner/dna/types/subject';
import {
  GetSubjects,
  GetSubjectsVariables,
} from 'core/dna/types/generated/GetSubjects';
import { mapApiSubjectListToSubjectList } from 'modules/planner/dna/functions/map-ApiSubject-to-Subject';

import { useGetTermsState } from 'core/memory/apollo/terms/local';

import { GET_SUBJECTS_QUERY } from './get-subjects-query';

export const useGetSubjects = (runCondition = true) => {
  const { data: termsState } = useGetTermsState();

  const {
    data: subjectsData,
    error: subjectsError,
    loading: subjectsLoading,
  } = useQuery<GetSubjects, GetSubjectsVariables>(GET_SUBJECTS_QUERY, {
    skip: !runCondition || !termsState?.selected,
    variables: { termId: termsState!.selected! },
  });

  const subjects = useMemo<Subject[] | undefined>(() => {
    if (!subjectsLoading && !subjectsError && subjectsData) {
      return mapApiSubjectListToSubjectList(subjectsData.Subject);
    }
    return [];
  }, [subjectsLoading, subjectsError, subjectsData]);

  return {
    subjects,
    subjectsError,
    subjectsLoading,
  };
};
