import { CircularProgress } from 'core/cells/circular-progress';
import { Grid } from 'core/cells/grid';
import { Drawer } from 'core/cells/drawer';

import { SubjectsImportList } from './components/subjects-import-list/subjects-import-list';
import { SubjectsTable } from './components/subjects-table';
import { useSubjectsState, subjectsStore } from './store/subjects';

export const Subjects = () => {
  const { subjectsState } = useSubjectsState();
  const { importListVisible, importing } = subjectsState;

  return (
    <Grid container>
      <Grid item xs={12}>
        <Drawer
          anchor="left"
          open={importListVisible}
          onClose={subjectsStore.hideImportList}
        >
          <SubjectsImportList />
        </Drawer>
      </Grid>
      <Grid item xs={12}>
        {importing && <CircularProgress size={100} />}
        <SubjectsTable
          onImportSubjectsFromOasClick={subjectsStore.showImportListFromSync}
          onImportSubjectsFromDefaultClick={
            subjectsStore.showImportListFromDefault
          }
        />
      </Grid>
    </Grid>
  );
};
