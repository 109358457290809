import { useMemo } from 'react';
import { useQuery } from 'core/dna/types/apollo';

import { Room } from 'modules/planner/dna/types/room';
import { mapApiRoomListToRoomList } from 'modules/planner/dna/functions/map-ApiRoom-to-Room';
import { GetRooms, GetRoomsVariables } from 'core/dna/types/generated/GetRooms';

import { useGetTermsState } from 'core/memory/apollo/terms/local';

import { GET_ROOMS_QUERY } from './get-rooms-query';

export const useGetRooms = (runCondition = true) => {
  const { data: termsState } = useGetTermsState();

  const {
    data: roomsData,
    error: roomsError,
    loading: roomsLoading,
  } = useQuery<GetRooms, GetRoomsVariables>(GET_ROOMS_QUERY, {
    skip: !runCondition || !termsState?.selected,
    variables: { termId: termsState!.selected! },
  });

  const rooms = useMemo<Room[] | undefined>(() => {
    if (!roomsLoading && !roomsError && roomsData) {
      return mapApiRoomListToRoomList(roomsData.Room);
    }
  }, [roomsLoading, roomsError, roomsData]);

  return {
    rooms: rooms ?? [],
    roomsError,
    roomsLoading,
  };
};
