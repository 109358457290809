import { useMemo } from 'react';

import { useTranslations } from 'core/dna/translations';
import { useParams } from 'core/dna/routing';

import { EvaluationGroup } from 'modules/grades/dna/types';
import { useGetEvaluationGroup } from 'modules/grades/memory/apollo/evaluation/remote';
import { useGetEvaluationGroupAccess } from 'modules/grades/memory/apollo/evaluation/remote/evaluation-group';
import { GetEvaluationGroupAccess_EvaluationGroupAccess_teacher } from 'core/dna/types/generated/GetEvaluationGroupAccess';

import { DisplayCellData } from './display-cell-data';
import { StudentsLinkCell } from './students-link-cell';

export interface AccessList {
  teacher: GetEvaluationGroupAccess_EvaluationGroupAccess_teacher | null;
  evaluator: boolean | null;
}

export interface TeachersTableItem extends EvaluationGroup {
  accessList: AccessList[];
  // students: string;
}

export const useTeachersTableRead = () => {
  const { texts } = useTranslations();
  const { id: batchId } = useParams<{ id: string }>();
  const { loading, data } = useGetEvaluationGroup({ batchId });
  const {
    loading: groupAccessLoading,
    data: groupsAccesses,
  } = useGetEvaluationGroupAccess();

  const columns = useMemo(() => {
    return [
      {
        Header: texts.grades.gradesCommon.subjectCode,
        accessor: 'subjectCode',
      },
      {
        Header: texts.planner.common.names.one,
        accessor: 'name',
      },
      {
        Header: texts.grades.evaluationGroups.teachersForSubjects,
        accessor: 'accessList',
        disableFilters: true,
        Cell: (row: any) => <DisplayCellData {...row} />,
      },
      {
        Header: texts.grades.evaluationGroups.studentsForSubjects,
        accessor: 'students',
        disableFilters: true,
        Cell: (row: any) => <StudentsLinkCell {...row} />,
      },
      {
        Header: texts.planner.common.actions.many,
        accessor: 'actions',
        disableFilters: true,
        width: 120,
        minWidth: 120,
      },
    ];
  }, [texts]);

  const preparedData = useMemo<TeachersTableItem[]>(() => {
    if (!data || !data.length) {
      return [];
    }

    return data.map((eg) => {
      const accessListForGroup = groupsAccesses?.filter(
        (access) => access.evaluationGroup?.id === eg.id,
      );

      return {
        ...eg,
        // students: '',
        accessList: !accessListForGroup?.length
          ? []
          : accessListForGroup?.map((ac) => ({
              teacher: ac.teacher,
              evaluator: ac.evaluator,
            })),
      };
    });
  }, [data, groupsAccesses]);

  return {
    title: texts.grades.evaluationGroups.title,
    columns,
    data: preparedData,
    batchId,
    loading: loading || groupAccessLoading,
  };
};
