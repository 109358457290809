/**
=========================================================
* Material Dashboard 2 PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/**
  The boxShadow() function helps you to create a box shadow for an element
 */

// Material Dashboard 2 PRO React helper functions
import rgba from './rgba';
import pxToRem from './pxToRem';

function boxShadow(
  // eslint-disable-next-line @typescript-eslint/default-param-last
  offset: any[] = [],
  // eslint-disable-next-line @typescript-eslint/default-param-last
  radius: any[] = [],
  color: any,
  opacity: any,
  inset = '',
) {
  const [x, y] = offset;
  const [blur, spread] = radius;

  return `${inset} ${pxToRem(x)} ${pxToRem(y)} ${pxToRem(blur)} ${pxToRem(
    spread,
  )} ${rgba(color, opacity)}`;
}

export default boxShadow;
