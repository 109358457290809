import { useCallback } from 'react';

import { OasMemoryError, OasError } from 'core/atoms/errors';

import { gql, useMutation } from 'core/dna/types/apollo';
import {
  ResetTimeSlot,
  ResetTimeSlotVariables,
} from 'core/dna/types/generated/ResetTimeSlot';

import { GET_SCHEDULE_QUERY } from 'modules/planner/memory/apollo/schedules/remote';

import { useGetSchedulesSelectedState } from '../local';

const RESET_TIMESLOT_FOR_SCHEDULE_MUTATION = gql`
  mutation ResetTimeSlot($input: DeleteTimeSlot!) {
    deleteTimeSlot(input: $input) {
      status
      statusCode
      description
    }
  }
`;

const TITLE = 'use-delete-timeslot';

export const useDeleteTimeslot = () => {
  const [deleteTimeslot] = useMutation<ResetTimeSlot, ResetTimeSlotVariables>(
    RESET_TIMESLOT_FOR_SCHEDULE_MUTATION,
  );

  const { data: selectedScheduleIds } = useGetSchedulesSelectedState();

  const func = useCallback(
    async (timeSlotId: string) => {
      try {
        const result = await deleteTimeslot({
          variables: { input: { timeSlotId } },
          refetchQueries: [
            {
              query: GET_SCHEDULE_QUERY,
              variables: { scheduleId: selectedScheduleIds![0] },
            },
          ],
        });

        if (result.errors) {
          throw new OasMemoryError(JSON.stringify(result.errors), {
            title: TITLE,
          });
        }
        return result.data?.deleteTimeSlot.description ?? undefined;
      } catch (e: any) {
        if (e instanceof OasError) {
          throw e;
        } else {
          throw OasMemoryError.fromError(e, { title: TITLE });
        }
      }
    },
    [deleteTimeslot, selectedScheduleIds],
  );

  return {
    deleteTimeslot: func,
  };
};
