import { TextValue } from 'core/atoms/types';
import { OasMappingError } from 'core/atoms/errors';

import { Term } from 'core/dna/types/term';

export const mapTermListToTextValueList = (
  termList?: Term[],
): TextValue[] | undefined => {
  return termList?.map((t) => mapTermToTextValue(t));
};

export const mapTermToTextValue = (term: Term | null): TextValue => {
  if (term === null) {
    throw new OasMappingError('term" should not be null!', {
      title: 'Map Term to TextValue',
    });
  }

  const { id, name } = term;

  return {
    text: name ?? 'TERM',
    value: id,
  };
};
