import { gql } from 'core/dna/types/apollo';

import { ROOM_DATA_FRAGMENT } from 'modules/planner/memory/apollo/_fragments';

export const CREATE_ROOM_MUTATION = gql`
  mutation CreateRoom($input: CreateRoomInput) {
    createRoom(input: $input) {
      ...RoomData
    }
  }
  ${ROOM_DATA_FRAGMENT}
`;
