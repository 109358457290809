/* eslint-disable */
import React, { ReactElement } from 'react';
import clsx from 'clsx';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';

import { Card } from 'templates/mdp/components/card';

import {
  WizardStyleClasses,
  wizardStyle,
} from 'templates/mdp/components/wizard/wizard.styles';

interface WizardStep {
  stepName: string;
  stepComponent: any;
  stepId: string;
}

type WizardColor =
  | 'primary'
  | 'warning'
  | 'danger'
  | 'success'
  | 'info'
  | 'rose';

export interface WizardNoValidationProps {
  classes: WizardStyleClasses;
  steps: WizardStep[];
  color?: WizardColor;
  title?: string;
  subtitle?: string;
  headElement?: ReactElement;
}

class WizardNoValidationComponent extends React.Component<
  WizardNoValidationProps,
  any
> {
  static defaultProps = {
    color: 'primary' as WizardColor,
    title: 'Here should go your title',
  };

  constructor(props: WizardNoValidationProps) {
    super(props);

    let width;
    if (this.props.steps.length === 1) {
      width = '100%';
    } else {
      if (window.innerWidth < 600) {
        if (this.props.steps.length !== 3) {
          width = '50%';
        } else {
          width = 100 / 3 + '%';
        }
      } else {
        if (this.props.steps.length === 2) {
          width = '50%';
        } else {
          width = 100 / 3 + '%';
        }
      }
    }
    this.state = {
      currentStep: 0,
      color: this.props.color,
      width: width,
      movingTabStyle: {
        transition: 'transform 0s',
      },
      allStates: {},
    };
    this.navigationStepChange = this.navigationStepChange.bind(this);
    this.refreshAnimation = this.refreshAnimation.bind(this);
    this.updateWidth = this.updateWidth.bind(this);
  }
  wizard = React.createRef<any>();
  componentDidMount() {
    this.refreshAnimation(0);
    window.addEventListener('resize', this.updateWidth);
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWidth);
  }
  updateWidth() {
    this.refreshAnimation(this.state.currentStep);
  }
  navigationStepChange(key: number) {
    if (!this.props.steps || !this.props.steps.length) {
      return;
    }

    this.setState({
      currentStep: key,
      nextButton: this.props.steps.length > key + 1,
      previousButton: key > 0,
      finishButton: this.props.steps.length === key + 1,
    });
    this.refreshAnimation(key);
  }
  refreshAnimation(index: any) {
    const total = this.props.steps.length;
    let liWidth = 100 / total;
    const totalSteps = this.props.steps.length;
    let moveDistance = this.wizard.current.children[0].offsetWidth / totalSteps;
    let indexTemp = index;
    let verticalLevel = 0;

    const mobileDevice = window.innerWidth < 600 && total > 3;

    if (mobileDevice) {
      moveDistance = this.wizard.current.children[0].offsetWidth / 2;
      indexTemp = index % 2;
      liWidth = 50;
    }

    this.setState({ width: liWidth + '%' });

    const stepWidth = moveDistance;
    moveDistance = moveDistance * indexTemp;

    const current = index + 1;

    if (current === 1 || (mobileDevice && index % 2 === 0)) {
      moveDistance -= 8;
    } else if (current === totalSteps || (mobileDevice && index % 2 === 1)) {
      moveDistance += 8;
    }

    if (mobileDevice) {
      verticalLevel = parseInt(String(index / 2), 10);
      verticalLevel = verticalLevel * 38;
    }
    const movingTabStyle = {
      width: stepWidth,
      transform:
        'translate3d(' + moveDistance + 'px, ' + verticalLevel + 'px, 0)',
      transition: 'all 0.5s cubic-bezier(0.29, 1.42, 0.79, 1)',
    };
    this.setState({ movingTabStyle: movingTabStyle });
  }

  render() {
    const { classes, title, subtitle, color, steps, headElement } = this.props;
    return (
      <div className={classes.wizardContainer} ref={this.wizard}>
        <Card className={classes.card}>
          <div className={classes.wizardHeader}>
            {headElement && headElement}
            <h3 className={classes.title}>{title}</h3>
            {subtitle ? <h5 className={classes.subtitle}>{subtitle}</h5> : null}
          </div>
          <div className={classes.wizardNavigation}>
            <ul className={classes.nav}>
              {steps.map((prop, key) => {
                return (
                  <li
                    className={classes.steps}
                    key={key}
                    style={{ width: this.state.width }}
                  >
                    <div
                      className={classes.stepsAnchor}
                      onClick={(e) => {
                        e.preventDefault();
                        this.navigationStepChange(key);
                      }}
                    >
                      {prop.stepName}
                    </div>
                  </li>
                );
              })}
            </ul>
            <div
              className={clsx(classes.movingTab, color ? classes[color] : '')}
              style={this.state.movingTabStyle}
            >
              {steps[this.state.currentStep].stepName}
            </div>
          </div>
          <div className={classes.content}>
            {steps.map((prop, key) => {
              const stepContentClasses = clsx({
                [classes.stepContentActive]: this.state.currentStep === key,
                [classes.stepContent]: this.state.currentStep !== key,
              });

              return (
                <div className={stepContentClasses} key={key}>
                  <prop.stepComponent
                    innerRef={(node: any) =>
                      ((this as any)[prop.stepId] = node)
                    }
                    allStates={this.state.allStates}
                  />
                </div>
              );
            })}
          </div>
        </Card>
      </div>
    );
  }
}

export const WizardNoValidation = withStyles(wizardStyle)(
  WizardNoValidationComponent,
);
