import { createStyles, withStyles } from 'core/atoms/styles';

import { InputBase } from 'core/cells/input-base';

export const TitleInput = withStyles(() =>
  createStyles({
    input: {
      // fontSize: 32,
      // color: theme-light.palette.primary.main,
      padding: '2px 22px 2px 0',
    },
  }),
)(InputBase);
