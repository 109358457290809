import { useCallback } from 'react';
import axios from 'axios';

import { logger, LogType } from 'core/atoms/housekeeping';
import { runtimeEnv } from 'core/atoms/env';

import { getStatusStorage } from 'auth/memory/browser';
import { mapTokensToAuthIdentity } from 'auth/dna/functions';

import { useAuthContext } from './use-auth-context';

const FILE_NAME = 'use-get-token-by-transient';

const env = runtimeEnv();

export const useGetTokenByTransient = () => {
  const { getStatus, setStatus } = getStatusStorage();
  const { setTokens, logout } = useAuthContext();

  const getTokenByTransient = useCallback(
    async (transientToken: string) => {
      try {
        const url = `${env.REACT_APP_CORE_URI}/v1/transientTokens/${transientToken}/sessions`;
        const response = await axios.post(url, { clientId: 'oas' });

        if (response.status !== 200) {
          console.log('Something happened when used transient token');
        }

        const { accessToken, sessionToken } = response.data;

        if (!accessToken || !sessionToken) {
          logger.debug({
            title: 'getTokenByTransient: NO TOKEN',
            logger: FILE_NAME,
            type: LogType.Info,
            value: [
              {
                accessToken,
                sessionToken,
              },
            ],
          });
          return;
        }

        const identity = mapTokensToAuthIdentity(accessToken, sessionToken);
        if (!identity) {
          logger.debug({
            title: 'getTokenByTransient: NO IDENTITY',
            logger: FILE_NAME,
            type: LogType.Info,
            value: [
              {
                accessToken,
                sessionToken,
              },
            ],
          });
          return;
        }

        if (getStatus() !== 'identity') {
          setStatus('identity');
        }
        const tokens = {
          accessToken: accessToken as string,
          sessionToken: sessionToken as string,
        };
        setTokens(tokens);
        return tokens;
      } catch (e: any) {
        logger.debug({
          title: 'Error to get tokens',
          logger: FILE_NAME,
          type: LogType.Error,
          value: [
            {
              error: e,
            },
          ],
        });

        await logout?.();
      }
    },
    [logout, getStatus, setStatus, setTokens],
  );

  return {
    getTokenByTransient,
  };
};
