import queryString, { StringifyOptions } from 'query-string';
import { TextValueData } from 'core/atoms/types';
import { intersection, orderBy } from 'core/atoms/functions/array';

import { RootAccessRoles, AccessRoleEnum } from 'core/dna/types/access-roles';

import { OrganizationItem } from './types';

export const stringifyQs = (
  qData: { [key: string]: any },
  options?: StringifyOptions,
) => {
  return queryString.stringify(qData, { skipNull: true, ...options });
};

export const mapRootAccessRolesToOrgsFlatList = (
  rootAccessRoles: RootAccessRoles[] | undefined,
): TextValueData<OrganizationItem>[] => {
  const result: TextValueData<OrganizationItem>[] = [];

  if (!rootAccessRoles) {
    return result;
  }

  orderBy(rootAccessRoles, ['name']).forEach((ro) => {
    result.push({
      text: ro.name ?? 'NONAME',
      value: ro.id,
      data: {
        root: true,
        accessible: false,
        // (ro.accessRoles?.indexOf(AccessRoleEnum.rootAdmin) ?? -1) > -1,
      },
    });
    if (ro.organizations) {
      orderBy(ro.organizations, ['name']).forEach((o) => {
        result.push({
          text: o.name ?? 'NONAME',
          value: o.id,
          data: {
            root: false,
            accessible:
              intersection(o.accessRoles, [
                AccessRoleEnum.admin,
                AccessRoleEnum.staff,
              ]).length !== 0,
            parent: {
              id: ro.id,
              name: ro.name ?? 'NONAME',
            },
          },
        });
      });
    }
  });

  return result;
};
