import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

import CssBaseline from '@mui/material/CssBaseline';
import NoSsr from '@mui/material/NoSsr';
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles';

import { themes } from 'core/atoms/themes';

export const Mui5Provider = ({ children }: any) => {
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={themes.light!}>
        <NoSsr>
          <CssBaseline />
          {children}
        </NoSsr>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};
