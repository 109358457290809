import { Fragment } from 'react';
import { grey } from 'core/atoms/colors';
import { Typography } from 'core/cells/typography';
import { DraggableChip } from 'core/cells/draggable-chip';

import { useTranslations } from 'core/dna/translations';

import { StudentItem } from 'modules/planner/dna/types/local';

export interface ChapterContentProps {
  students: StudentItem[];
  dragDropType: string;
  onDelete: (item: StudentItem) => void;
}

export const ChapterContent = (props: ChapterContentProps) => {
  const { students, dragDropType, onDelete } = props;
  const { texts } = useTranslations();

  return students.length ? (
    <>
      {students.map((item) => {
        return (
          <Fragment key={item.id}>
            <DraggableChip
              type={dragDropType}
              id={item.id}
              text={item.text}
              data={item.chapter}
              color="secondary"
              onDelete={onDelete}
            />
          </Fragment>
        );
      })}
    </>
  ) : (
    <Typography
      variant="body2"
      gutterBottom={false}
      style={{ color: grey['600'] }}
    >
      {texts.planner.sourceDataSpace.classesModule.dndStudentsIntoGroups}
    </Typography>
  );
};
