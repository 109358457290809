import React, { useCallback, useMemo } from 'react';
import { RemarkState } from 'core/dna/types/generated/_globalTypes';
import { Card, CardBody } from 'templates/mdp/components/card';
import { useTranslations } from 'core/dna/translations';
import { ColDef, DataGrid } from 'core/cells/data-grid';
import { Remark } from 'modules/student/dna/types/remark';
import { createStyles, makeStyles } from 'core/atoms/styles';
import { Box } from 'core/cells/box';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      border: 'none',
      '& .MuiDataGrid-columnSeparator': {
        display: 'none',
        color: 'transparent !important',
      },
    },
  }),
);

interface RemarkTotalByProps {
  data: Remark[];
}

export const RemarkTotalByCategory = (props: RemarkTotalByProps) => {
  const { data } = props;
  const { texts } = useTranslations();

  const classes = useStyles();

  const getAmount = useCallback(
    (key: keyof Remark, searchedValue: RemarkState) => {
      if (!data) return 0;

      return data.filter((remark) => remark[key] === searchedValue).length;
    },
    [data],
  );

  const columns: ColDef[] = useMemo(
    () => [
      {
        headerName: texts.oasCommon.category.one,
        field: 'category',
        flex: 3,
      },
      {
        headerName: texts.planner.common.amount,
        field: 'amount',
        flex: 2,
        headerAlign: 'center',
        align: 'center',
      },
    ],
    [texts],
  );

  const rows = useMemo(
    () => [
      {
        id: 0,
        category: texts.studentModule.remarks.typeValues![RemarkState.conduct],
        amount: getAmount('type', RemarkState.conduct),
      },
      // {
      //   id: 1,
      //   category: texts.studentModule.remarks.typeValues![RemarkState.late],
      //   amount: getAmount('type', RemarkState.late),
      // },
      {
        id: 2,
        category: texts.studentModule.remarks.typeValues![RemarkState.order],
        amount: getAmount('type', RemarkState.order),
      },
    ],
    [getAmount, texts],
  );

  return (
    <>
      <Card>
        <CardBody>
          <div style={{ height: 165 }}>
            <DataGrid
              hideFooter
              autoHeight
              columns={columns}
              rows={rows}
              className={classes.root}
            />
          </div>
        </CardBody>
      </Card>
      <Box p={2} display="flex">
        <div>
          {getAmount('type', RemarkState.late)}{' '}
          {texts.studentModule.remarks.timeLate}
        </div>
      </Box>
    </>
  );
};
