import { OasLanguage, URLS } from 'core/dna/routing';

import { PATHS } from './paths';

const { getOasUrlWithoutParams } = URLS;

const getDefaultUrl = (locale?: OasLanguage) => {
  return getOasUrlWithoutParams({
    pathname: PATHS.Absence.path,
    locale,
  });
};

export default {
  home: getDefaultUrl,
};
