import { useMemo, useCallback } from 'react';

import { Link } from 'core/cells/link';
import { Typography } from 'core/cells/typography';

import { useTranslations } from 'core/dna/translations';
import { useHistory, useLocale } from 'core/dna/routing';

import { TeacherTableItem } from 'modules/planner/dna/types/teacher';

import { useRooms } from 'modules/planner/metabolism/use-rooms';

import PlannerUrls from 'modules/planner/routing/urls';

export interface ColAvailabilityProps {
  item?: TeacherTableItem;
}

export const ColAvailability = (props: ColAvailabilityProps) => {
  const { item } = props;

  const { texts } = useTranslations();
  const history = useHistory();
  const locale = useLocale();
  const { setSelectedRooms } = useRooms();

  const text = useMemo(() => {
    if (!item?.availabilityDefined) {
      return texts.planner.common.create;
    }
    return texts.planner.common.definedCountChange.one;
  }, [item, texts.planner]);

  const handleClick = useCallback(() => {
    if (item) {
      setSelectedRooms([item.id]);
      history.push(PlannerUrls.roomsAvailability(locale));
    }
    return false;
  }, [history, item, locale, setSelectedRooms]);

  return item ? (
    <Link onClick={handleClick}>
      <Typography>{text}</Typography>
    </Link>
  ) : (
    <span> </span>
  );
};
