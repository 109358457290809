import { useCallback } from 'react';

import { OasMemoryError, OasError } from 'core/atoms/errors';

import { gql, useMutation } from 'core/dna/types/apollo';
import {
  CancelSubstituteJob,
  CancelSubstituteJobVariables,
} from 'core/dna/types/generated/CancelSubstituteJob';

import { GET_SUBSTITUTE_JOBS_QUERY } from 'modules/substitute/memory/apollo/substitute-jobs/remote';
import { EXECUTION_STATUS } from 'modules/substitute/memory/apollo/_fragments/remote';

export const CANCEL_SUBSTITUTE_JOB_MUTATION = gql`
  mutation CancelSubstituteJob($input: CancelSubstituteJobInput) {
    cancelSubstituteJob(input: $input) {
      ...ExecutionStatus
    }
  }
  ${EXECUTION_STATUS}
`;

const TITLE = 'use-cancel-substitute-job';

export const useCancelSubstituteJob = () => {
  const [cancelSubstituteJob] = useMutation<
    CancelSubstituteJob,
    CancelSubstituteJobVariables
  >(CANCEL_SUBSTITUTE_JOB_MUTATION);

  const func = useCallback(
    async (substituteJobId: OasValidateString) => {
      try {
        const result = await cancelSubstituteJob({
          variables: {
            input: { substituteJobId },
          },
          refetchQueries: [
            {
              query: GET_SUBSTITUTE_JOBS_QUERY,
            },
          ],
        });

        if (result.errors) {
          throw new OasMemoryError(JSON.stringify(result.errors), {
            title: TITLE,
          });
        }
        return result.data?.cancelSubstituteJob ?? undefined;
      } catch (e) {
        if (e instanceof OasError) {
          throw e;
        } else {
          throw OasMemoryError.fromError(e, {
            title: TITLE,
          });
        }
      }
    },
    [cancelSubstituteJob],
  );

  return {
    cancelSubstituteJob: func,
  };
};
