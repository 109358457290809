import { useState } from 'react';
import { Event } from '@material-ui/icons';
import { Grid } from 'core/cells/grid';

import {
  Card,
  CardBody,
  CardHeader,
  CardIcon,
} from 'templates/mdp/components/card';

import { EventContentArg } from 'core/cells/full-calendar';

import { WeekCalendar } from 'core/organisms/week-calendar';
import {
  Duration,
  TimeSlotDurationSelector,
} from 'modules/planner/organisms/time-slot-duration-selector';
import { DescriptionEvent } from 'core/organisms/week-calendar/components';

import { useWeeklyEvents } from './components/use-weekly-events';

export const WeeklyEvents = () => {
  const { startTime, endTime, events } = useWeeklyEvents();

  const [timeSlotDurationValue, setTimeSlotDurationValue] = useState(
    Duration.M05,
  );

  const eventContent = (arg: EventContentArg) => <DescriptionEvent {...arg} />;

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Card>
          <CardHeader color="primary" icon>
            <CardIcon color="primary">
              <Event />
            </CardIcon>
          </CardHeader>
          <CardBody>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <TimeSlotDurationSelector
                  value={timeSlotDurationValue}
                  onChange={setTimeSlotDurationValue}
                />
              </Grid>
              <Grid item xs={12}>
                <div>
                  <WeekCalendar
                    type="day"
                    slotLabelInterval="00:05"
                    slotDuration={timeSlotDurationValue}
                    snapDuration="00:05"
                    slotMinTime={startTime}
                    slotMaxTime={endTime}
                    events={events}
                    contentHeight="auto"
                    eventContent={eventContent}
                    nowIndicator
                    now={new Date().toISOString()}
                    freeze
                  />
                </div>
              </Grid>
            </Grid>
          </CardBody>
        </Card>
      </Grid>
    </Grid>
  );
};
