import { useCallback } from 'react';

import { OasError, OasMemoryError } from 'core/atoms/errors';

import { gql, useMutation } from 'core/dna/types/apollo';
import {
  DeleteClassMembers,
  DeleteClassMembersVariables,
} from 'core/dna/types/generated/DeleteClassMembers';

import { useLazyGetTermsState } from 'core/memory/apollo/terms/local';

import { GET_CLASSES_QUERY } from './_gql';

export const DELETE_CLASS_MEMBERS_MUTATION = gql`
  mutation DeleteClassMembers($input: DeleteClassMembersInput!) {
    deleteClassMembers(input: $input) {
      description
      status
      statusCode
    }
  }
`;

const FILE_NAME = 'use-delete-class-members';

export const useDeleteClassMembers = () => {
  const { getTermState } = useLazyGetTermsState();

  const [deleteClassMembers, { loading, error }] = useMutation<
    DeleteClassMembers,
    DeleteClassMembersVariables
  >(DELETE_CLASS_MEMBERS_MUTATION);

  const func = useCallback(
    async (input: { classId: string; roleIds: string[] }) => {
      try {
        const termsState = await getTermState(true);

        const result = await deleteClassMembers({
          variables: { input },
          refetchQueries: [
            {
              query: GET_CLASSES_QUERY,
              variables: { termId: termsState!.selected },
            },
          ],
        });

        if (result.errors) {
          throw new OasMemoryError(JSON.stringify(result.errors), {
            title: FILE_NAME,
          });
        }
        return result.data?.deleteClassMembers ?? undefined;
      } catch (e: any) {
        if (e instanceof OasError) {
          throw e;
        } else {
          throw OasMemoryError.fromError(e, {
            title: FILE_NAME,
          });
        }
      }
    },
    [getTermState, deleteClassMembers],
  );

  return {
    deleteClassMembers: func,
    loading,
    error,
  };
};
