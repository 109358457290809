import { makeStyles, Theme, createStyles } from 'core/atoms/styles';

import {
  PersonIcon,
  ClassIcon,
  GroupIcon,
  RoomFilledIcon,
} from 'core/cells/oas-icons';

import { TimeplanEventProps } from '../timeplan-event.types';

import { IconTextContent } from './icon-text-content';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: '100%',
      display: 'flex',
      flexWrap: 'nowrap',
    },
    time: {
      alignSelf: 'flex-start',
      width: theme.spacing(9),
      minWidth: theme.spacing(9),
    },
    data: {
      flexGrow: 1,
      alignSelf: 'center',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    title: {
      fontWeight: 'bold',
    },
    ml: {
      marginLeft: theme.spacing(1),
    },
    icon: {
      fontSize: theme.spacing(2),
    },
  }),
);

export const OneLineEventContent = (props: TimeplanEventProps) => {
  const classes = useStyles();
  const {
    timeText,
    event,
    showClasses,
    showGroups,
    showTeachers,
    showRooms,
  } = props;

  return event.display === 'background' ? (
    <></>
  ) : (
    <div className={classes.root}>
      <span className={classes.time}>{timeText}</span>
      <div className={classes.data}>
        <span className={classes.title}>{event.title ?? ''}</span>
        {!!showTeachers && (
          <IconTextContent
            className={classes.ml}
            text={event.extendedProps?.teachers ?? ''}
          >
            <PersonIcon className={classes.icon} />
          </IconTextContent>
        )}
        {!!showRooms && (
          <IconTextContent
            className={classes.ml}
            text={event.extendedProps?.rooms ?? ''}
          >
            <RoomFilledIcon className={classes.icon} />
          </IconTextContent>
        )}
        {!!showClasses && (
          <IconTextContent
            className={classes.ml}
            text={event.extendedProps?.classes ?? ''}
          >
            <ClassIcon className={classes.icon} />
          </IconTextContent>
        )}
        {!!showGroups && (
          <IconTextContent
            className={classes.ml}
            text={event.extendedProps?.groups ?? ''}
          >
            <GroupIcon className={classes.icon} />
          </IconTextContent>
        )}
      </div>
    </div>
  );
};
