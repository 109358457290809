import { useState, useEffect } from 'react';
import { useMediaQuery } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import ViewListIcon from '@material-ui/icons/ViewList';

import { round } from 'core/atoms/functions/math';
import { grey, blue, red } from 'core/atoms/colors';

import { RoleType } from 'core/dna/types/generated/_globalTypes';
import { useTranslations } from 'core/dna/translations';

import { PrintButton } from 'core/organisms/print-helper';

import {
  CardHeader,
  CardIcon,
  CardBody,
  Card,
} from 'templates/mdp/components/card';
import { Button } from 'templates/mdp/components/custom-buttons';
import { cardTitle } from 'templates/mdp/material-dashboard-pro-react';

import { useEmployeeTypedName } from 'modules/planner/metabolism/use-employee-typed-name';

import {
  TeachingTimeTableItem,
  useTeachingTimeTable,
} from './use-teaching-time-table';

const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
  cardIconTitle: {
    ...cardTitle,
    marginTop: '15px',
    marginBottom: '0px',
  },
  f0t90: {
    color: grey['900'],
  },
  f90t100: {
    color: blue['500'],
  },
  f100: {
    color: red['500'],
  },
  hidden: {
    visibility: 'hidden',
  },
});

const round2 = (value: number | null | undefined) =>
  !value ? 0 : round(value, 2);

const Row = (props: { row: TeachingTimeTableItem; allOpen: boolean }) => {
  const { row } = props;
  const [open, setOpen] = useState(true);
  const { texts } = useTranslations();
  const classes = useRowStyles();
  const { getName } = useEmployeeTypedName();

  useEffect(() => {
    setOpen(props.allOpen);
  }, [props.allOpen]);

  const TotalUtilizationRate = () => {
    const { type, utilizationRate } = row.teacher;
    const value = round2(utilizationRate);
    const className =
      value <= 90
        ? classes.f0t90
        : value > 90 && value <= 100
        ? classes.f90t100
        : classes.f100;
    return type === RoleType.teacher && utilizationRate ? (
      <h5 className={className}>{value}%</h5>
    ) : (
      <></>
    );
  };

  return (
    <>
      <TableRow className={classes.root}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          <h5>{getName({ name: row.teacher.name, type: row.teacher.type })}</h5>
        </TableCell>
        <TableCell align="right">
          <h5>{row.teacher.sumWeekHours}</h5>
        </TableCell>
        <TableCell align="right">
          <TotalUtilizationRate />
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ padding: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box ml={10} mr={2} mb={2}>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell>{texts.oasCommon.subjects.one}</TableCell>
                    <TableCell align="right">
                      {texts.planner.sumWeekHours60m}
                    </TableCell>
                    <TableCell
                      align="right"
                      className={
                        row.teacher.type === RoleType.teacher
                          ? ''
                          : classes.hidden
                      }
                    >
                      {texts.planner.utilizationRateOfEmployee}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.subjects.map((subject) => (
                    <TableRow key={subject.id}>
                      <TableCell component="th" scope="row">
                        {subject.name}
                      </TableCell>
                      <TableCell align="right">
                        {subject.sumWeekHours === 0 ? '' : subject.sumWeekHours}
                      </TableCell>
                      <TableCell align="right">
                        {row.teacher.type === RoleType.teacher &&
                        subject.utilizationRate
                          ? `${round2(subject.utilizationRate)}%`
                          : ''}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

export interface TeachingTimeTableProps {
  scheduleId: string;
}

export const TeachingTimeTable = (props: TeachingTimeTableProps) => {
  const { scheduleId } = props;
  const { texts } = useTranslations();
  const { data } = useTeachingTimeTable(scheduleId);
  const [allOpen, setAllOpen] = useState(false);
  const [printAlert, setPrintAlert] = useState<any>(null);
  const print = useMediaQuery('print');

  useEffect(() => {
    setPrintAlert(null);
    //   setAllOpen(print);
  }, [print]);

  const Report = () => {
    return (
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow>
            <TableCell>
              {print ? (
                <></>
              ) : (
                <Button
                  simple
                  size="sm"
                  color="primary"
                  onClick={() => setAllOpen(!allOpen)}
                >
                  {allOpen ? 'Collapse all' : 'Expand all'}
                </Button>
              )}
            </TableCell>
            <TableCell>{texts.oasCommon.employees.one}</TableCell>
            <TableCell align="right">{texts.planner.sumWeekHours60m}</TableCell>
            <TableCell align="right">
              {texts.planner.utilizationRateOfEmployee}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((item) => (
            <Row key={item.id} row={item} allOpen={allOpen} />
          ))}
        </TableBody>
      </Table>
    );
  };

  return !data ? (
    <></>
  ) : !print ? (
    <>
      {printAlert}
      <Card>
        <CardHeader color="primary" icon>
          <CardIcon color="primary">
            <ViewListIcon />
          </CardIcon>
          <PrintButton setAlert={setPrintAlert} />
        </CardHeader>
        <CardBody>
          <Report />
        </CardBody>
      </Card>
    </>
  ) : (
    <Report />
  );
};
