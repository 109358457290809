export const localeEn = {
  code: 'en',
  week: {
    dow: 1, // Monday is the first day of the week.
    doy: 7,
  },
  weekLabel: 'W',
  noEventsMessage: 'No events to show',
};

export const localeNo = {
  code: 'nb',
  week: {
    dow: 1, // Monday is the first day of the week.
    doy: 7,
  },
  weekLabel: 'W',
  noEventsMessage: 'No events to show',
};

export const workingHours = {
  start: '08:00:00',
  end: '16:00:00',
};
