import { useCallback, useMemo, useRef, useState } from 'react';
import moment from 'moment';

import { OasError, OasOrganismError } from 'core/atoms/errors';
import { TextValue } from 'core/atoms/types';
import { max } from 'core/atoms/functions/array';

import { useSnackbar } from 'core/cells/notistack';

import { useTranslations } from 'core/dna/translations';
import { useAccessRolesState } from 'core/dna/access-roles';
import { AccessRoleEnum } from 'core/dna/types/access-roles';
import { TermData } from 'core/dna/types/term';
import { isOrganizationHasAccessRole } from 'core/dna/functions';

import {
  useGetTermsState,
  useSetTermsSelectedState,
} from 'core/memory/apollo/terms/local';
import { useCreateTerm } from 'core/memory/apollo/terms/remote/create-term';

import { useOrganization } from 'core/metabolism/organizations';

import { mapTermListToTextValueList } from 'modules/planner/dna/functions';

import { useResetTermStateDependencies } from 'modules/planner/memory/apollo/term/local';

export const useTermSelector = () => {
  const { texts } = useTranslations();
  const { organization, loading, error } = useOrganization();
  const [termCreating, setTermCreating] = useState(false);
  const { data: termsState } = useGetTermsState();
  const { setTermsSelectedState } = useSetTermsSelectedState();
  const { createTerm } = useCreateTerm();
  const { enqueueSnackbar } = useSnackbar();

  const myAccessRoles = useAccessRolesState();

  const { resetTermStateDependencies } = useResetTermStateDependencies();

  const previousTermRef = useRef(termsState?.selected ?? '');

  const newTerm = useMemo<TextValue>(
    () => ({
      text: texts.oasCommon.addTerm,
      value: 'newTerm',
    }),
    [texts.oasCommon.addTerm],
  );

  const getRecentStartYear = useCallback((terms?: TermData[]) => {
    const startYears = terms?.length
      ? terms.map((t) => t.startDate?.getFullYear())
      : null;
    return startYears ? max(startYears) : null;
  }, []);

  const handleCreateNewTerm = useCallback(
    async (terms?: TermData[]) => {
      setTermCreating(true);
      const recentStartYear = getRecentStartYear(terms);

      const startYear = recentStartYear
        ? moment().year(recentStartYear).add(1, 'year')
        : moment();

      const startYearText = startYear.format('YYYY');
      const endYearText = startYear.add(1, 'year').format('YYYY');

      const name = `${startYearText}/${endYearText}`;
      const res = await createTerm({
        name,
        startDate: `${startYearText}-08-01`,
        endDate: `${endYearText}-06-30`,
      });
      try {
        setTermsSelectedState({ value: res?.id });
        resetTermStateDependencies();
        enqueueSnackbar(texts.planner.plannerTerms.newTermCreatedAndActive, {
          variant: 'success',
        });
      } catch (e: any) {
        if (e instanceof OasError) {
          throw e;
        } else {
          throw OasOrganismError.fromError(e, { title: 'use-term-selector' });
        }
      } finally {
        setTermCreating(false);
      }
    },
    [
      createTerm,
      enqueueSnackbar,
      resetTermStateDependencies,
      setTermsSelectedState,
      texts,
      getRecentStartYear,
    ],
  );

  const isAllowCreateTerm = useCallback(
    (/*terms: TermData[]*/) => {
      // const currentYear = new Date().getFullYear();
      // const startYears = terms.map((t) => t.startDate?.getFullYear());

      return (
        // !startYears.includes(currentYear) &&
        isOrganizationHasAccessRole(
          myAccessRoles,
          organization?.id,
          AccessRoleEnum.admin,
        )
      );
    },
    [myAccessRoles, organization?.id],
  );

  const handleChange = useCallback(
    (newValue: string) => {
      if (newValue !== 'newTerm' && previousTermRef?.current === newValue) {
        return;
      }
      previousTermRef.current = newValue;

      if (newValue === newTerm.value) {
        handleCreateNewTerm(organization?.terms);
        return;
      }

      try {
        setTermsSelectedState({ value: newValue });
        resetTermStateDependencies();
      } catch (e: any) {
        if (e instanceof OasError) {
          throw e;
        } else {
          throw OasOrganismError.fromError(e, { title: 'use-term-selector' });
        }
      }
    },
    [
      handleCreateNewTerm,
      newTerm.value,
      resetTermStateDependencies,
      setTermsSelectedState,
      organization?.terms,
    ],
  );

  // useEffect(() => {
  //   if (previousTermRef.current !== termsState?.selected) {
  //     previousTermRef.current = termsState?.selected ?? '';
  //   }
  // }, [termsState]);

  const termsData = useMemo<TextValue[]>(() => {
    if (!organization || !organization.terms) {
      return [];
    }

    const terms = mapTermListToTextValueList(organization.terms) ?? [];

    if (isAllowCreateTerm(/*organization.terms*/)) {
      terms.push(newTerm);
    }

    return terms;
  }, [isAllowCreateTerm, newTerm, organization]);

  return {
    termsLoading: loading,
    termsError: error,
    termsData,
    onChange: handleChange,
    value: termsState?.selected ?? '',
    termsDisabled: !organization,
    termCreating,
  };
};
