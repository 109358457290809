import { PersonDetailsT2Group, ShortData } from 'modules/student/dna/types';

export const mapStudentListToGroupList = (studentList: ShortData[]) => {
  const groups: PersonDetailsT2Group[] = [];

  studentList.forEach((student) => {
    if (!student.details.groups?.length) {
      return [];
    }

    student.details.groups.forEach((group) => {
      if (!groups.length) {
        groups.push(group);
      }

      if (groups.length && groups.every((g) => g.id !== group.id)) {
        groups.push(group);
      }
    });
  });

  return groups;
};
