import { getDiffInHours } from 'core/atoms/date-time';
import { Absence } from 'modules/student/dna/types';

export const absenceCalculateTotalHours = (absences: Absence[]) => {
  if (!absences.length) return 0;

  const notWholeDayAbsences = absences.filter(
    (absence) => absence.wholeDay === false,
  );

  let hours = 0;

  notWholeDayAbsences.forEach((absence) => {
    if (absence.to && absence.from) {
      hours += getDiffInHours(absence.from, absence.to);
    }
  });

  return parseFloat(hours.toFixed(2));
};
