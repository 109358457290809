import { useCallback } from 'react';

import { OasMemoryError, OasError } from 'core/atoms/errors';

import { gql, useMutation } from 'core/dna/types/apollo';

import { PublishSubstituteJobInvites } from 'core/dna/types/generated/_globalTypes';
import {
  PublishSubstituteJob,
  PublishSubstituteJobVariables,
} from 'core/dna/types/generated/PublishSubstituteJob';

import { GET_SUBSTITUTE_JOBS_QUERY } from 'modules/substitute/memory/apollo/substitute-jobs/remote';

export const PUBLISH_SUBSTITUTE_JOB_MUTATION = gql`
  mutation PublishSubstituteJob($input: PublishSubstituteJobInvites) {
    publishSubstituteJob(input: $input) {
      id
      description
      internalTitle
      deadline {
        formatted
      }
      state
    }
  }
`;

const TITLE = 'use-publish-substitute-job';

export const usePublishSubstituteJob = () => {
  const [publishSubstituteJob] = useMutation<
    PublishSubstituteJob,
    PublishSubstituteJobVariables
  >(PUBLISH_SUBSTITUTE_JOB_MUTATION);

  const func = useCallback(
    async (input: PublishSubstituteJobInvites) => {
      try {
        const result = await publishSubstituteJob({
          variables: {
            input: {
              substituteJobId: input.substituteJobId,
            },
          },
          refetchQueries: [
            {
              query: GET_SUBSTITUTE_JOBS_QUERY,
            },
          ],
        });

        if (result.errors) {
          throw new OasMemoryError(JSON.stringify(result.errors), {
            title: TITLE,
          });
        }
        return result.data?.publishSubstituteJob ?? undefined;
      } catch (e) {
        if (e instanceof OasError) {
          throw e;
        } else {
          throw OasMemoryError.fromError(e, {
            title: TITLE,
          });
        }
      }
    },
    [publishSubstituteJob],
  );

  return {
    publishSubstituteJob: func,
  };
};
