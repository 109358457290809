import { useMemo, useCallback, useEffect, useState } from 'react';

import { Resource, EventInput } from 'core/cells/full-calendar';
import { useSnackbar } from 'core/cells/notistack';

import {
  CalendarEvent,
  AvailabilityPreferencesEntityType,
} from 'core/dna/types';
import { Teacher } from 'modules/planner/dna/types/teacher';
import {
  mapResourceListToAvailabilityPreferenceList,
  mapAvailabilityPreferenceListToEventInputList,
  mapCalendarEventListToApiAvailabilityPreferenceList,
} from 'modules/planner/dna/functions';

import { UpdateAvailabilityPreferencesForEntityInput } from 'core/dna/types/generated/_globalTypes';
import { useTranslations } from 'core/dna/translations';

import { useTeachers } from 'modules/planner/metabolism/use-teachers';
import { useOrganizationCalendar } from 'modules/planner/metabolism/use-organization-calendar';

import { useRemovableEvent } from 'core/organisms/week-calendar/components';

export const useTeachersAvailability = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { texts } = useTranslations();
  const { startTime, endTime, getBackgroundEvents } = useOrganizationCalendar();

  const {
    teachersLoading,
    teachersError,
    teachers,
    selectedTeachers: selectedTeacherIds,
    updateAvailabilityPreferencesForTeachers,
  } = useTeachers();

  const [events, setEvents] = useState<EventInput[]>([]);
  const [dirty, setDirty] = useState<boolean>(false);
  const [saving, setSaving] = useState<boolean>(false);

  const selectedTeachers = useMemo<Teacher[] | undefined>(
    () =>
      teachers?.filter(
        (t) => selectedTeacherIds && selectedTeacherIds.indexOf(t.id) >= 0,
      ),
    [teachers, selectedTeacherIds],
  );

  const selectedTeachersCount = selectedTeachers?.length;

  const resources = useMemo<Resource[]>(() => {
    const result = selectedTeachers?.map((t) => ({
      id: t.id,
      title: t.person?.name || 'noname',
    }));
    return result?.length ? result : [{ id: '', title: '' }];
  }, [selectedTeachers]);

  useEffect(() => {
    if (!teachersLoading && !teachersError && selectedTeachersCount) {
      const newEvents = mapAvailabilityPreferenceListToEventInputList(
        mapResourceListToAvailabilityPreferenceList(selectedTeachers!),
        'Not Available',
      );
      setEvents(newEvents);
    }
  }, [selectedTeachers, selectedTeachersCount, teachersError, teachersLoading]);

  const updateEvents = useCallback((newEvents: EventInput[]) => {
    setDirty(true);
    setEvents(newEvents);
  }, []);

  const addPreference = useCallback(
    (event: EventInput) => {
      if (!events) {
        throw new Error("It's not possible to add new event undefined!");
      }
      updateEvents([...events, event]);
    },
    [events, updateEvents],
  );

  const editPreference = useCallback(
    (event: EventInput) => {
      if (!events) {
        throw new Error("It's not possible to edit not existing events!");
      }
      const existingEvent = events.find((e) => e.id === event.id);
      if (!existingEvent) {
        throw new Error('Event to edit have to match data');
      }

      existingEvent.start = event.start;
      existingEvent.end = event.end;

      updateEvents([...events]);
    },
    [events, updateEvents],
  );

  const deletePreference = useCallback(
    (eventId: string) => {
      if (!events) {
        throw new Error("It's not possible to edit not existing events!");
      }
      const updatedEvents = events.filter((e) => e.id !== eventId);
      if (updatedEvents.length === events.length) {
        throw new Error('Item to delete is not found in the data source!');
      }
      updateEvents(updatedEvents);
    },
    [events, updateEvents],
  );

  const { eventContent } = useRemovableEvent({ onDelete: deletePreference });

  const save = useCallback(() => {
    setSaving(true);
    const entityType: AvailabilityPreferencesEntityType = 'teacher';
    const preferences = resources.map((r) => {
      return {
        entityId: r.id,
        entityType,
        availabilityPreferences: mapCalendarEventListToApiAvailabilityPreferenceList(
          events as CalendarEvent[],
          r.id,
          -2,
        ),
      } as UpdateAvailabilityPreferencesForEntityInput;
    });
    updateAvailabilityPreferencesForTeachers({ preferences })
      .then(() => {
        setDirty(false);
        enqueueSnackbar(
          texts.planner.common.availabilityPreferencesUpdateSuccess,
          {
            variant: 'success',
          },
        );
      })
      .catch(() => {
        enqueueSnackbar(
          texts.planner.common.availabilityPreferencesUpdateError,
          {
            variant: 'error',
          },
        );
      })
      .finally(() => {
        setSaving(false);
      });
  }, [
    enqueueSnackbar,
    events,
    texts,
    resources,
    updateAvailabilityPreferencesForTeachers,
  ]);

  return {
    startTime,
    endTime,
    resources,
    bgEvents: getBackgroundEvents(resources?.map((r) => r.id)),
    events,
    add: addPreference,
    edit: editPreference,
    save,
    dirty,
    saving,
    loading: teachersLoading,
    error: teachersError,
    eventContent,
  };
};
