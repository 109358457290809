import React, { useState, useCallback, Fragment } from 'react';
import Close from '@material-ui/icons/Close';
import Edit from '@material-ui/icons/Edit';

import { makeStyles, Theme, createStyles } from 'core/atoms/styles';

import { OutlinedArea } from 'core/cells/outlined-area';
import { List } from 'core/cells/list';
import { ListItem } from 'core/cells/list-item';
import { ListItemText } from 'core/cells/list-item-text';
import { ListItemSecondaryAction } from 'core/cells/list-item-secondary-action';
import { Divider } from 'core/cells/divider';
import { Typography } from 'core/cells/typography';

import {
  mapTranslationDueToOrgCategoryType,
  useTranslations,
} from 'core/dna/translations';
import { useOrganization } from 'core/metabolism/organizations';

import { Button } from 'templates/mdp/components/custom-buttons';

import {
  AppointmentField,
  getNewAppointmentFieldValue,
} from 'modules/substitute/cells/appointment-field';

import { SubstituteTask } from 'modules/substitute/dna/types/substitute-task';

import { useDeleteSubstituteTask } from 'modules/substitute/memory/apollo/substitute-jobs/remote/use-delete-substitute-task';

export interface TasksProps {
  substituteJobId: string;
  data?: SubstituteTask[];
}

export type Mode = 'add' | 'edit' | 'list';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    addAppointmentOutline: {
      padding: theme.spacing(1),
    },
    occupied: {
      borderStyle: 'solid',
    },
  }),
);

export const Tasks = (props: TasksProps) => {
  const { substituteJobId, data } = props;
  const classes = useStyles();

  const { texts } = useTranslations();
  const { organization } = useOrganization();

  const [mode, setMode] = useState<Mode>('list');
  const [selectedTask, setSelectedTask] = useState(() =>
    getNewAppointmentFieldValue(),
  );
  const { deleteSubstituteTask } = useDeleteSubstituteTask();

  const handleDeleteTask = useCallback(
    async (task: SubstituteTask) => {
      await deleteSubstituteTask({ substituteTaskId: task.id });
      // setMode('list');
    },
    [deleteSubstituteTask],
  );

  const handleOpenEditor = useCallback((mode: Mode, task?: SubstituteTask) => {
    setSelectedTask(task ?? getNewAppointmentFieldValue());
    setMode(mode);
  }, []);

  const handleCloseEditor = (mode: Mode) => {
    setMode(mode);
  };

  return (
    <>
      {mode === 'add' || mode === 'edit' ? (
        <OutlinedArea
          label={mapTranslationDueToOrgCategoryType(
            texts,
            organization?.category,
            'addLesson',
          )}
          occupied
          className={classes.addAppointmentOutline}
          classes={{ occupied: classes.occupied }}
        >
          <AppointmentField
            value={selectedTask}
            mode={mode}
            substituteJobId={substituteJobId}
            onClose={handleCloseEditor}
          />
        </OutlinedArea>
      ) : (
        <div>
          {!data?.length && (
            <Typography color="error">
              {texts.substitute.taskListDesc}
            </Typography>
          )}
          <Button
            size="sm"
            color="info"
            onClick={() => handleOpenEditor('add')}
          >
            {mapTranslationDueToOrgCategoryType(
              texts,
              organization?.category,
              'addLesson',
            )}
          </Button>
        </div>
      )}
      {!!data?.length && (
        <List dense>
          {data.map((task, index) => (
            <Fragment key={`${task.id || 'new'}-${index}`}>
              <ListItem>
                <ListItemText
                  primary={
                    task.comment
                      ? `${task.subject} (${task.comment})`
                      : task.subject
                  }
                  secondary={`${task.date} from ${task.timeFrom} to ${task.timeTo}`}
                />
                <ListItemSecondaryAction>
                  <Button
                    justIcon
                    round
                    simple
                    size="sm"
                    onClick={() => handleOpenEditor('edit', task)}
                    color="info"
                    className="edit"
                  >
                    <Edit />
                  </Button>{' '}
                  <Button
                    justIcon
                    round
                    simple
                    size="sm"
                    onClick={() => handleDeleteTask(task)}
                    color="danger"
                    className="remove"
                  >
                    <Close />
                  </Button>
                </ListItemSecondaryAction>
              </ListItem>
              <Divider variant="middle" component="li" />
            </Fragment>
          ))}
        </List>
      )}
    </>
  );
};
