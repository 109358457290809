import { AnyMap } from 'core/atoms/types';

import { makeVar } from 'core/dna/types/apollo';
import { ApolloCacheFields } from 'core/dna/types/apollo-cache-fields';

import {
  SchedulesState,
  TimePlanState,
  StudentsGroupsState,
} from 'modules/planner/dna/types/local';

export const plannerFields: ApolloCacheFields = {
  classStudentsGroupsMapState: {
    read: () => classStudentsGroupsMapStateVar(),
  },
  selectedClassesState: {
    read: () => selectedClassesStateVar(),
  },
  selectedClassState: {
    read: () => selectedClassStateVar(),
  },
  selectedRoomsState: {
    read: () => selectedRoomsStateVar(),
  },
  selectedSubjectsState: {
    read: () => selectedSubjectsStateVar(),
  },
  selectedTeachersState: {
    read: () => selectedTeachersStateVar(),
  },
  schedulesState: {
    read: () => schedulesStateVar(),
  },
  selectedScheduleState: {
    read: () => selectedScheduleStateVar(),
  },
  timePlanState: {
    read: () => timePlanStateVar(),
  },
};

export const defaults = {
  classStudentsGroupsMapState: {
    byId: {},
    allIds: [],
  } as AnyMap<StudentsGroupsState>,
  selectedClassesState: [],
  selectedClassState: '',
  selectedRoomsState: [],
  selectedSubjectsState: [],
  selectedTeachersState: [],
  schedulesState: {
    selected: [],
    subscribed: [],
    byId: {},
  },
  selectedScheduleState: '',
  timePlanState: {
    scheduleId: '',
    calendarEvents: [],
    worklist: [],
  },
};

export const classStudentsGroupsMapStateVar = makeVar<
  AnyMap<StudentsGroupsState>
>(defaults.classStudentsGroupsMapState);

export const selectedClassesStateVar = makeVar<string[]>(
  defaults.selectedClassesState,
);
export const selectedClassStateVar = makeVar<string>(
  defaults.selectedClassState,
);
export const selectedRoomsStateVar = makeVar<string[]>(
  defaults.selectedRoomsState,
);
export const selectedSubjectsStateVar = makeVar<string[]>(
  defaults.selectedSubjectsState,
);
export const selectedTeachersStateVar = makeVar<string[]>(
  defaults.selectedTeachersState,
);
export const schedulesStateVar = makeVar<SchedulesState>(
  defaults.schedulesState,
);
export const selectedScheduleStateVar = makeVar<string>(
  defaults.selectedScheduleState,
);

export const timePlanStateVar = makeVar<TimePlanState>(defaults.timePlanState);

export const resetAll = () => {
  selectedClassesStateVar(defaults.selectedClassesState);
  selectedRoomsStateVar(defaults.selectedRoomsState);
  selectedSubjectsStateVar(defaults.selectedSubjectsState);
  selectedTeachersStateVar(defaults.selectedTeachersState);
  schedulesStateVar(defaults.schedulesState);
  timePlanStateVar(defaults.timePlanState);
};
