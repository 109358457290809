import {
  useGetTeachers,
  useUpdateAvailabilityPreferencesForTeachers,
} from 'modules/planner/memory/apollo/teachers/remote';
import {
  useGetSelectedTeachersState,
  useSetSelectedTeachersState,
} from 'modules/planner/memory/apollo/teachers/local';

export const useTeachers = () => {
  const { teachers, teachersLoading, teachersError } = useGetTeachers();
  const {
    selectedTeachersState,
    loading: teacherStateLoading,
    error: teacherStateError,
  } = useGetSelectedTeachersState();

  const { setSelectedTeachersState } = useSetSelectedTeachersState();

  const {
    updateAvailabilityPreferencesForTeachers,
  } = useUpdateAvailabilityPreferencesForTeachers();

  return {
    teachersLoading: teachersLoading || teacherStateLoading,
    selectedTeachers: selectedTeachersState,
    setSelectedTeachers: setSelectedTeachersState,
    updateAvailabilityPreferencesForTeachers,
    teachers,
    teachersError: teachersError || teacherStateError,
  };
};
