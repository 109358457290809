import {
  OasError,
  OasErrorConstructor,
  getOasErrorFromError,
  OasErrorOptions,
  OasErrorInterface,
} from './oas-error';

export const OasMappingError: OasErrorConstructor = class OasMappingError extends OasError {
  static fromError(e: unknown, options?: OasErrorOptions): OasErrorInterface {
    return getOasErrorFromError(OasMappingError, e, options);
  }

  constructor(message?: string, options?: OasErrorOptions) {
    super(message, { ...options, type: 'OasMappingError' });
  }
};
