import { useState, useLayoutEffect } from 'react';

import { studentsStore } from './students-store';

export const useStudentsState = () => {
  const [state, setState] = useState(studentsStore.initialState);

  useLayoutEffect(() => {
    const subscription = studentsStore.subscribe(setState);
    return () => {
      subscription.unsubscribe();
    };
  }, []);

  return {
    studentsState: state,
  };
};
