import { useMemo, useCallback, useState, forwardRef } from 'react';
import clsx from 'clsx';
import { Moment } from 'moment';

import { makeStyles, createStyles, Theme } from 'core/atoms/styles';
import { deepOrange } from 'core/atoms/colors';
import { staticMonday } from 'core/atoms/date-time';

import { Paper } from 'core/cells/paper';
import { Grid } from 'core/cells/grid';
import { Box } from 'core/cells/box';
import { Button } from 'core/cells/button';
import {
  Resource,
  EventInput,
  DayCellContentType,
} from 'core/cells/full-calendar';
import { Checkbox } from 'core/cells/checkbox';
import { FormControlLabel } from 'core/cells/form-control-label';
import { DialogContent } from 'core/cells/dialog-content';
import { DialogContentText } from 'core/cells/dialog-content-text';
import { DialogActions } from 'core/cells/dialog-actions';
import { Dialog } from 'core/cells/dialog';
import { Tooltip } from 'core/cells/tooltip';

import { useTranslations } from 'core/dna/translations';

import { WeekCalendar } from 'core/organisms/week-calendar';

import { useClasses } from 'modules/planner/metabolism/use-classes';

import { useOrganizationCalendar } from 'modules/planner/metabolism/use-organization-calendar';
import { useClassesCalendarEvents } from 'modules/planner/metabolism/use-classes-calendar-events';

import { ClassSelector } from 'modules/planner/organisms/class-selector';
import {
  TimeSlotDurationSelector,
  Duration,
} from 'modules/planner/organisms/time-slot-duration-selector';

import { WaitingBackdrop } from './components/waiting-backdrop';

import { useTimePlanCalendar } from './use-time-plan-calendar';

export interface TimePlanCalendarProps {
  scheduleId: string;
  showClasses: boolean;
  showGroups: boolean;
  setShowGroups: (value: boolean) => void;
  showTeachers: boolean;
  setShowTeachers: (value: boolean) => void;
  showRooms: boolean;
  setShowRooms: (value: boolean) => void;
  className?: string;
  readonly?: boolean;
  dayCellContent?: DayCellContentType;
  now?: Date | string | Moment;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(2),
    },
    tableContainer: {
      position: 'relative',
    },
    resourcesRow: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: theme.spacing(2),
    },
    expanded: {
      flexGrow: 1,
    },
    runButton: {
      marginRight: theme.spacing(0.5),
    },
    clearButton: {
      marginLeft: theme.spacing(0.5),
    },
    event: {
      '&.fc-event': {
        cursor: 'default',
        padding: 0,
        border: 'none',
      },
      '& .fc-event-main': {
        cursor: 'default',
        padding: 0,
      },
    },
    notification: {
      color: deepOrange.A700,
    },
  }),
);

/**  /plan/schedules/:scheduleId?/time-plan  */
export const TimePlanCalendar = (props: TimePlanCalendarProps) => {
  const classes = useStyles();
  const {
    scheduleId,
    className,
    showClasses,
    showGroups,
    setShowGroups,
    showTeachers,
    setShowTeachers,
    showRooms,
    setShowRooms,
    readonly,
    dayCellContent,
    now = staticMonday,
  } = props;

  const [dialogOpened, setDialogOpened] = useState(false);

  const { texts } = useTranslations();
  const { startTime, endTime, getBackgroundEvents } = useOrganizationCalendar();

  const {
    classData,
    selectedClasses,
    classesLoading,
    classesError,
  } = useClasses();

  const {
    startScheduling,
    stopScheduling,
    clean,
    events,
    starting,
    scheduling,
    executing,
    stopping,
    locked,
    eventContent,
    onSplitDragStart,
    onDrop,
    noLessons,
  } = useTimePlanCalendar({
    scheduleId,
    showClasses,
    showGroups,
    showTeachers,
    showRooms,
  });

  const resources: Resource[] = useMemo(() => {
    const result = classData
      .filter((c) => selectedClasses && selectedClasses.indexOf(c.id) > -1)
      .map((cls) => ({
        id: cls.id,
        title: cls.name || 'noname',
      }));
    return result && result.length ? result : [{ id: '', title: '' }];
  }, [classData, selectedClasses]);

  const { breaks } = useClassesCalendarEvents({ ids: selectedClasses });

  const [timeSlotDurationValue, setTimeSlotDurationValue] = useState(
    Duration.M30,
  );

  const changeShowGroups = useCallback(
    (_: any, checked: boolean) => {
      setShowGroups(checked);
    },
    [setShowGroups],
  );

  const changeShowTeachers = useCallback(
    (_: any, checked: boolean) => {
      setShowTeachers(checked);
    },
    [setShowTeachers],
  );

  const changeShowRooms = useCallback(
    (_: any, checked: boolean) => {
      setShowRooms(checked);
    },
    [setShowRooms],
  );

  const handleDialogClose = useCallback(() => {
    setDialogOpened(false);
  }, []);

  const handleCleanApproved = useCallback(() => {
    setDialogOpened(false);
    clean();
  }, [clean]);

  const handleCleanClick = useCallback(() => {
    setDialogOpened(true);
  }, []);

  if (classesError) return <p>Classes Error!</p>;
  if (classesLoading) return <p>Loading Classes...</p>;

  const getBgEvents = () => {
    let result: EventInput[] | undefined;
    const openingHoursEvents = getBackgroundEvents(resources?.map((r) => r.id));
    if (openingHoursEvents?.length) {
      result = [...openingHoursEvents];
    }
    if (breaks) {
      result = result ? [...result, ...breaks] : [...breaks];
    }
    return result;
  };

  const StartButton = forwardRef((props, ref: any) => (
    <div {...props} ref={ref}>
      <Button
        variant="outlined"
        color="primary"
        className={classes.runButton}
        onClick={startScheduling}
        disabled={starting || locked || noLessons}
      >
        {texts.planner.common.start}
      </Button>
    </div>
  ));

  return (
    <>
      <Paper className={clsx(classes.root, className)}>
        <Grid container>
          <Grid item xs={12} className={classes.resourcesRow}>
            <ClassSelector />
            {selectedClasses.length ? (
              <Box ml={1}>
                <TimeSlotDurationSelector
                  value={timeSlotDurationValue}
                  onChange={setTimeSlotDurationValue}
                />
              </Box>
            ) : (
              <> </>
            )}
            <div className={classes.expanded} />
            <FormControlLabel
              control={
                <Checkbox
                  checked={showGroups}
                  onChange={changeShowGroups}
                  name="show-groups"
                  color="primary"
                />
              }
              label={texts.planner.common.viewGroups}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={showTeachers}
                  onChange={changeShowTeachers}
                  name="show-teachers"
                  color="primary"
                />
              }
              label={texts.planner.common.viewTeachers}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={showRooms}
                  onChange={changeShowRooms}
                  name="show-rooms"
                  color="primary"
                />
              }
              label={texts.planner.common.viewRooms}
            />
          </Grid>
          {selectedClasses.length ? (
            <>
              <Grid item xs={12}>
                <div className={classes.tableContainer}>
                  <WeekCalendar
                    // freeze
                    withPinning
                    resizable={false}
                    selectable={false}
                    eventOverlap={true}
                    type="resource-week"
                    resources={resources}
                    events={events ?? undefined}
                    bgEvents={getBgEvents()}
                    slotMinTime={startTime}
                    slotMaxTime={endTime}
                    slotLabelInterval="00:30:00"
                    slotDuration={timeSlotDurationValue}
                    snapDuration="00:05:00"
                    // onPin={onPin}
                    onEdit={onDrop}
                    onSplitDragStart={onSplitDragStart}
                    contentHeight="auto"
                    eventContent={eventContent}
                    eventClass={classes.event}
                    dayCellContent={dayCellContent}
                    now={now}
                  />
                  {executing && (
                    <WaitingBackdrop show={executing} showText={executing} />
                  )}
                </div>
              </Grid>
              {!readonly && (
                <Grid item xs={12}>
                  <Box centeredContent m={2}>
                    {scheduling ? (
                      <Button
                        variant="contained"
                        color="primary"
                        className={classes.runButton}
                        onClick={stopScheduling}
                        disabled={stopping || locked}
                      >
                        {texts.planner.common.stop}
                      </Button>
                    ) : noLessons ? (
                      <Tooltip
                        title={
                          texts.planner.preparationSpace.schedulesModule
                            .needToDefineLessons
                        }
                        arrow
                      >
                        <StartButton />
                      </Tooltip>
                    ) : (
                      <StartButton />
                    )}
                    {events?.length ? (
                      <Button
                        className={classes.clearButton}
                        variant="outlined"
                        color="secondary"
                        onClick={handleCleanClick}
                        disabled={executing}
                      >
                        {texts.planner.common.clear}
                      </Button>
                    ) : (
                      <> </>
                    )}
                  </Box>
                </Grid>
              )}
            </>
          ) : (
            <>
              <Grid item xs={12}>
                <h1 className={classes.notification}>
                  {
                    texts.planner.sourceDataSpace.classesModule
                      .selectClassNotification
                  }
                </h1>
              </Grid>
            </>
          )}
        </Grid>
      </Paper>
      {!readonly && (
        <Dialog
          open={dialogOpened}
          onClose={handleDialogClose}
          aria-labelledby="clear-timeplan"
        >
          <DialogContent>
            <DialogContentText>
              {
                texts.planner.preparationSpace.schedulesModule
                  .cleanTimePlanDescription
              }
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDialogClose} color="primary">
              {texts.planner.common.no}
            </Button>
            <Button onClick={handleCleanApproved} color="primary">
              {texts.planner.common.yes}
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};
