export const PATHS = {
  path: '/:locale(no|en|uk)/grades',
  part: 'grades',
  Preparation: {
    path: '/:locale(no|en|uk)/grades/batch',
    part: 'batch',
    Subjects: {
      path: '/:locale(no|en|uk)/grades/batch/:id/subjects',
      part: 'subjects',
      params: {
        batchId: {
          pathKey: ':id',
          key: 'id',
        },
      },
    },
    EvaluationGroup: {
      path: '/:locale(no|en|uk)/grades/batch/:id/evaluation-group',
      part: 'evaluation-group',
      params: {
        evaluationGroupId: {
          pathKey: ':id',
          key: 'id',
        },
      },
    },
  },
  Assessment: {
    path: '/:locale(no|en|uk)/grades/assessment',
    part: 'assessment',
    OpenRequestsForGrades: {
      path: '/:locale(no|en|uk)/grades/assessment/:batchId/:subjectCode',
      part: 'open-requests',
      params: {
        batchId: {
          pathKey: ':batchId',
          key: 'batchId',
        },
        subjectCode: {
          pathKey: ':subjectCode',
          key: 'subjectCode',
        },
      },
    },
  },
};
