import {
  useState,
  createRef,
  useEffect,
  useMemo,
  useCallback,
  memo,
} from 'react';
import clsx from 'clsx';
import PerfectScrollbar from 'perfect-scrollbar';
import 'perfect-scrollbar/css/perfect-scrollbar.css';
import { Switch } from 'react-router-dom';
import { useMediaQuery } from '@material-ui/core';

import { useRenderCounter } from 'core/atoms/housekeeping';
import logoWhiteImage from 'core/atoms/pictures/transponder-white.png';

import { RouterRoute } from 'core/cells/router-route';

import { useTranslations } from 'core/dna/translations';
import { useLocale } from 'core/dna/routing';
import { getRoutes as getFlattenedRoutes } from 'core/dna/routing/functions';
import { RouteConfig } from 'core/dna/types/local';
import { useModuleRoutes } from 'core/dna/routing/module-routes';
import { useActiveRoute } from 'core/dna/routing/active-route';
import { RouterRedirect } from 'core/cells/router-redirect';
import { isRouteAvailable } from 'core/dna/functions/is-route-available';
import { useSelectedOrganizationState } from 'core/dna/organizations';

import { useAuthContext } from 'auth/metabolism/use-auth-context';

import { PATHS } from 'modules/unset/paths';

import { PrivateRoute } from 'app/auth/private-route';

import { Sidebar } from 'app/organisms/sidebar/sidebar';

import { DashboardNavbar } from './components/dashboard-navbar';
import { DemoBanner } from './components/demo-banner';

import { useStyles } from './dashboard-layout.styles';

let ps: any;

const DashboardLayoutComponent = () => {
  // TODO: remove after performance testing
  const renderCount = useRenderCounter({ name: 'DashboardLayout' });

  const classes = useStyles();
  const print = useMediaQuery('print');

  const routes = useModuleRoutes();
  const { getActiveRoute } = useActiveRoute();
  const { getIdentity } = useAuthContext();
  // const { selected: selectedOrgState } = useOrganizationsState();
  const selectedOrgState = useSelectedOrganizationState();

  const contentRoutes = useMemo<RouteConfig[]>(() => {
    return routes ? getFlattenedRoutes(routes) : [];
  }, [routes]);

  const locale = useLocale();
  const { lang } = useTranslations();

  const [mobileOpen, setMobileOpen] = useState(false);
  const [miniActive, setMiniActive] = useState(false);
  // const [color, setColor] = useState<any>('white');
  // const [bgColor, setBgColor] = useState<any>('black');

  const mainPanelClasses = clsx(classes.mainPanel, {
    [classes.mainPanelSidebarMini]: miniActive,
    [classes.mainPanelWithPerfectScrollbar]:
      navigator.platform.indexOf('Win') > -1,
    [classes.mainPanelFullWidth]: print,
  });

  const mainPanel = createRef<any>();

  const resizeFunction = () => {
    if (window.innerWidth >= 960) {
      setMobileOpen(false);
    }
  };

  useEffect(() => {
    if (navigator.platform.indexOf('Win') > -1) {
      ps = new PerfectScrollbar(mainPanel.current, {
        suppressScrollX: true,
        suppressScrollY: false,
      });
      document.body.style.overflow = 'hidden';
    }
    window.addEventListener('resize', resizeFunction);

    return () => {
      if (navigator.platform.indexOf('Win') > -1) {
        ps.destroy();
      }
      window.removeEventListener('resize', resizeFunction);
    };
  });

  const handleDrawerToggle = useCallback(() => {
    setMobileOpen(!mobileOpen);
  }, [mobileOpen]);

  const getRoute = () => {
    return window.location.pathname !== '/admin/full-screen-maps';
  };

  const getRoutes = useCallback(
    (): any =>
      contentRoutes.map((r) => {
        if (!r.private) {
          return (
            <RouterRoute exact={!!r.exact} path={r.path} key={r.id}>
              <r.component />
            </RouterRoute>
          );
        }

        if (!selectedOrgState) {
          return null;
        }

        return isRouteAvailable(r, selectedOrgState) ? (
          <PrivateRoute exact={!!r.exact} path={r.path} key={r.id}>
            <r.component />
          </PrivateRoute>
        ) : (
          <RouterRedirect to={PATHS.AccessDenied.path} />
        );
      }),
    [selectedOrgState, contentRoutes],
  );

  const sidebarMinimize = useCallback(() => {
    setMiniActive(!miniActive);
  }, [miniActive]);

  const identity = getIdentity();
  return (
    <div className={classes.wrapper}>
      {renderCount}
      {!!identity && !print && (
        <Sidebar
          routes={routes}
          locale={locale}
          logoText={lang.common.transponder}
          logo={logoWhiteImage}
          image={undefined}
          handleDrawerToggle={handleDrawerToggle}
          open={mobileOpen}
          color="white"
          bgColor="black"
          miniActive={miniActive}
          setMiniActive={setMiniActive}
        />
      )}
      <div className={mainPanelClasses} ref={mainPanel}>
        {!print && <DemoBanner />}
        {!!identity && !print && (
          <DashboardNavbar
            sidebarMinimize={sidebarMinimize}
            miniActive={miniActive}
            brandText={getActiveRoute()?.name ?? ''} // lang.common.transponder}
            handleDrawerToggle={handleDrawerToggle}
          />
        )}
        {/* On the /maps/full-screen-maps route we want the map to be on full screen - this is not possible if the content and conatiner classes are present because they have some paddings which would make the map smaller */}
        {getRoute() ? (
          <div
            className={clsx(
              !print ? classes.content : '',
              !identity ? classes.topContent : '',
            )}
          >
            <div className={classes.container}>
              <Switch>
                {getRoutes()}
                {/* <Redirect from="/admin" to="/admin/dashboard" /> */}
              </Switch>
            </div>
          </div>
        ) : (
          <div className={classes.map}>
            <Switch>
              {getRoutes()}
              {/* <Redirect from="/admin" to="/admin/dashboard" /> */}
            </Switch>
          </div>
        )}
      </div>
    </div>
  );
};

export const DashboardLayout = memo(DashboardLayoutComponent);

DashboardLayout.displayName = 'Dashboard Layout';
