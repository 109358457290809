import React, { useMemo } from 'react';

import { translations, TranslationsContext } from 'core/dna/translations';

export const TranslationsProvider = ({ children }: any) => {
  const value = useMemo(() => translations(), []);

  return (
    <TranslationsContext.Provider value={value}>
      {children}
    </TranslationsContext.Provider>
  );
};
