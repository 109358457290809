import { memo } from 'react';

import { TextValueData } from 'core/atoms/types';
import { makeStyles, createStyles } from 'core/atoms/styles';
import { grey } from 'core/atoms/colors';

import { Select } from 'core/cells/select';
import { MenuItem } from 'core/cells/menu-item';

import { useTranslations } from 'core/dna/translations';

import { useModuleSelector } from './use-module-selector';
import { ModuleItem } from './types';

const useStyles = makeStyles(
  createStyles({
    root: {
      paddingTop: 10,
      width: 'calc(100% - 30px)',
      marginLeft: 15,
      marginRight: 15,
    },
    formControl: {
      width: '100%',
    },
    skeletonRoot: {
      backgroundColor: grey['800'],
    },
  }),
);

const ModuleSelectorComponent = () => {
  const classes = useStyles();

  const { onChange, data, value } = useModuleSelector();

  const { texts } = useTranslations();

  return (
    <div className={classes.root}>
      {!data ? (
        <> </>
      ) : (
        <Select
          palette="light"
          name="module"
          label={texts.oasCommon.menu}
          className={classes.formControl}
          data={data}
          value={value}
          renderItem={(item: TextValueData<ModuleItem>) => (
            <MenuItem
              key={item.value}
              value={item.value}
              disabled={!(item.data?.accessible ?? true)}
              onClick={() => onChange(item.value)}
            >
              {item.text}
            </MenuItem>
          )}
          notFoundText={texts.oasCommon.menu}
        />
      )}
    </div>
  );
};

export const ModuleSelector = memo(ModuleSelectorComponent);

ModuleSelector.displayName = 'Module Selector';
