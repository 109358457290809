import { difference, groupBy } from 'core/atoms/functions/array';

import { ScheduleWorklistItem } from 'core/dna/types';

import { LessonGroup } from 'modules/planner/dna/types/lesson-group';
import { TimeSlot } from 'modules/planner/dna/types/time-slot';
import { extractChaptersStringFromLessonGroup } from 'modules/planner/dna/functions/extract-chapters-string-from-lesson-group';

import { getEventDuration } from './get-event-duration';
import {
  getLectureTitleFromLessonGroup,
  getClassesTitleFromLessonGroup,
  getTeachersTitleFromLessonGroup,
  getRoomsTitleFromLessonGroup,
} from './get-titles-from-lesson-group';

interface TimeSlotWithDuration extends TimeSlot {
  duration: number;
}

// const TITLE = 'get-unassigned-lectures-from-lesson-group';

export const getUnassignedLecturesFromLessonGroup = (
  lessonGroup: LessonGroup,
) => {
  const { id: lgId, timeSlots, plannedMinutesPerTimeSlot } = lessonGroup;

  const timeSlotsWithDuration = timeSlots?.map(
    (ts) =>
      ({
        ...ts,
        duration: getEventDuration({
          start: ts.startTime,
          end: ts.endTime,
        }),
      } as TimeSlotWithDuration),
  );
  let assignedDurations: number[] = [];

  if (timeSlotsWithDuration?.length) {
    const timeSlotsWithDurationMap = groupBy(timeSlotsWithDuration, 'splitId');
    const timeSlotsWithDurationReduced = Object.values(
      timeSlotsWithDurationMap,
    ).map((tsd) =>
      tsd.reduce(
        (acc, cur) => {
          return {
            ...acc,
            duration: acc.duration + cur.duration,
            id: acc.id ? `${acc.id}__${cur.id}` : cur.id,
          };
        },
        { duration: 0 } as TimeSlotWithDuration,
      ),
    );
    assignedDurations = timeSlotsWithDurationReduced.map((ts) => ts.duration);
  }

  const unassignedDurations = difference(
    plannedMinutesPerTimeSlot,
    assignedDurations,
  );

  const unassigned: ScheduleWorklistItem[] = [];

  unassignedDurations.forEach((duration) => {
    unassigned.push({
      id: `${lgId}_${duration}`,
      lessonGroupId: lgId,
      lesson: getLectureTitleFromLessonGroup(lessonGroup),
      classes: getClassesTitleFromLessonGroup(lessonGroup),
      groups: extractChaptersStringFromLessonGroup(lessonGroup) ?? '',
      teachers: getTeachersTitleFromLessonGroup(lessonGroup),
      rooms: getRoomsTitleFromLessonGroup(lessonGroup),
      duration: `${duration}`,
      canBeSplit: lessonGroup.canBeSplit,
    });
  });

  return unassigned;
};
