import { tick, TickFormats } from 'core/atoms/date-time';

import { ScheduleWorklistItem } from 'core/dna/types';

import { LessonGroup } from 'modules/planner/dna/types/lesson-group';

import { ExtractTimePlanOptions } from 'modules/planner/dna/types/extract-time-plan-options';
import { extractChaptersStringFromLessonGroup } from 'modules/planner/dna/functions/extract-chapters-string-from-lesson-group';

import { getEventDuration } from './get-event-duration';

import {
  getLectureTitleFromLessonGroup,
  getClassesTitleFromLessonGroup,
  getTeachersTitleFromLessonGroup,
  getRoomsTitleFromLessonGroup,
} from './get-titles-from-lesson-group';

export const getConflictsFromLessonGroup = (
  lessonGroup: LessonGroup,
  options: ExtractTimePlanOptions,
) => {
  const { id: lgId, timeSlots } = lessonGroup;
  const { getWeekday, getConflictDescription } = options;

  const conflicts: ScheduleWorklistItem[] = [];
  let duration: number;
  timeSlots?.forEach((ts) => {
    duration = getEventDuration({
      start: ts.startTime,
      end: ts.endTime,
    });
    if (ts.aiHardConflicts?.length) {
      conflicts.push({
        id: `${lgId}_${duration}_${ts.id}`,
        lessonGroupId: lgId,
        lesson: getLectureTitleFromLessonGroup(lessonGroup),
        classes: getClassesTitleFromLessonGroup(lessonGroup),
        groups: extractChaptersStringFromLessonGroup(lessonGroup) ?? '',
        teachers: getTeachersTitleFromLessonGroup(lessonGroup),
        rooms: getRoomsTitleFromLessonGroup(lessonGroup),
        duration: `${duration}`,
        timeSlotId: ts.id,
        dayTime: `${getWeekday(tick(ts.weekday).weekDay).title} ${tick(
          ts.weekday,
        )
          .setTime(ts.startTime ?? '')
          .format(TickFormats.timeShort)}-${tick(ts.weekday)
          .setTime(ts.endTime ?? '')
          .format(TickFormats.timeShort)}`,
        conflicts:
          ts.aiHardConflicts
            ?.map((hc) => getConflictDescription(hc))
            ?.join(',') ?? '',
        canBeSplit: lessonGroup.canBeSplit,
      });
    }
  });

  return conflicts;
};
