import { ThemeOptions } from 'core/atoms/styles';

// https://material-ui.com/customization/color/#color-palette
export const oasThemeOptions: ThemeOptions = {
  palette: {
    primary: {
      light: '#5eb8ff',
      main: '#0288d1', // lightBlue['700']
      dark: '#005b9f',
      contrastText: '#fff',
    },
    secondary: {
      light: '#5df2d6',
      main: '#00bfa5', // teal.A700
      dark: '#008e76',
      contrastText: '#fff',
    },
    form: {
      light: {
        layout: {
          normal: '#f5f5f5', // grey['100'],
          selected: '#fafafa', // grey['50'],
          hover: '#fafafa', // grey['50'],
          focused: '#fafafa', // grey['50'],
          disabled: '#9e9e9e', // grey['500'],
          error: undefined,
        },
        text: {
          normal: '#fafafa', // grey['50'],
          selected: '#fafafa', // grey['50'],
          hover: '#fafafa', // grey['50'],
          focused: '#fafafa', // grey['50'],
          disabled: '#bdbdbd', // grey['400'],
          error: undefined,
        },
      },
      dark: {
        layout: {
          normal: '#616161', // grey.A700,
        },
        text: {
          normal: '#616161', // grey.A700,
        },
      },
      default: {
        layout: {
          normal: 'rgba(0,0,0,0.23)',
          focused: '#0288d1',
        },
        text: {
          normal: 'rgba(0,0,0,0.87)',
        },
      },
    },
    extended: {
      danger: [
        '#f44336',
        '#ef5350',
        '#e53935',
        '#f55a4e',
        '#d32f2f',
        '#ebcccc',
        '#f2dede',
      ],
      success: [
        '#4caf50',
        '#66bb6a',
        '#43a047',
        '#5cb860',
        '#388e3c',
        '#d0e9c6',
        '#dff0d8',
      ],
      brown: {
        light: '#bca283',
        main: '#8b7356',
        dark: '#5d482d',
        contrastText: '#fff',
      },
      grey: [
        '#999',
        '#777',
        '#3C4858',
        '#AAAAAA',
        '#D2D2D2',
        '#DDD',
        '#555555',
        '#333',
        '#eee',
        '#ccc',
        '#e4e4e4',
        '#E5E5E5',
        '#f9f9f9',
        '#f5f5f5',
        '#495057',
        '#e7e7e7',
        '#212121',
        '#c8c8c8',
        '#505050',
      ],
    },
  },
};
