/* eslint-disable */
import React, { ReactNode } from 'react';
import clsx from 'clsx';

import { useStyles } from './card.styles';

type CardColor = 'primary' | 'info' | 'success' | 'warning' | 'danger' | 'rose';

export interface CardProps {
  className?: string; // PropTypes.string,
  plain?: boolean; // PropTypes.bool,
  profile?: boolean; // PropTypes.bool,
  blog?: boolean; // PropTypes.bool,
  raised?: boolean; // PropTypes.bool,
  background?: boolean; // PropTypes.bool,
  pricing?: boolean; // PropTypes.bool,
  testimonial?: boolean; // PropTypes.bool,
  color?: CardColor; // PropTypes.oneOf([]),
  product?: boolean; // PropTypes.bool;
  chart?: boolean; // PropTypes.bool;
  login?: boolean; // PropTypes.bool;
  children?: ReactNode; // PropTypes.node;
}

export const Card = (props: CardProps) => {
  const classes = useStyles();
  const {
    className,
    children,
    plain,
    profile,
    blog,
    raised,
    background,
    pricing,
    color,
    product,
    testimonial,
    chart,
    login,
    ...rest
  } = props;

  const cardClasses = clsx(
    classes.card,
    plain ? classes.cardPlain : '',
    profile || testimonial ? classes.cardProfile : '',
    blog ? classes.cardBlog : '',
    raised ? classes.cardRaised : '',
    background ? classes.cardBackground : '',
    (pricing && color !== undefined) || (pricing && background !== undefined)
      ? classes.cardPricingColor
      : '',
    color ? classes[color] : '',
    pricing ? classes.cardPricing : '',
    product ? classes.cardProduct : '',
    chart ? classes.cardChart : '',
    login ? classes.cardLogin : '',
    className !== undefined ? className : '',
  );

  return (
    <div className={cardClasses} {...rest}>
      {children}
    </div>
  );
};
