import { useMemo } from 'react';

import { useTranslations } from 'core/dna/translations';
import { ScheduleWorklistItem } from 'core/dna/types/schedule-worklist-item';

import { useGetTimePlanState } from 'modules/planner/memory/apollo/time-plan/local';

export const useWorklistRead = (scheduleId: string) => {
  const { texts } = useTranslations();

  const columns = useMemo(
    () => [
      {
        Header: texts.oasCommon.description,
        accessor: 'conflicts',
      },
      {
        Header: texts.planner.common.time,
        accessor: 'dayTime',
      },
      {
        Header: texts.planner.common.lessons.one,
        accessor: 'lesson',
      },
      {
        Header: texts.oasCommon.employees.one,
        accessor: 'teachers',
      },
      {
        Header: texts.planner.common.rooms,
        accessor: 'rooms',
      },
      {
        Header: texts.planner.common.groups.many,
        accessor: 'groups',
      },
      {
        Header: texts.oasCommon.duration,
        accessor: 'duration',
      },
      {
        Header: texts.planner.preparationSpace.schedulesModule.canBeSplit,
        accessor: 'canBeSplit',
      },
      {
        Header: texts.planner.common.actions.many,
        accessor: 'actions',
      },
    ],
    [texts],
  );

  const { data: timePlanState, error } = useGetTimePlanState();

  const data = useMemo<ScheduleWorklistItem[] | undefined>(() => {
    if (timePlanState && !error) {
      return timePlanState.scheduleId === scheduleId
        ? timePlanState.worklist
        : undefined;
    }
  }, [error, scheduleId, timePlanState]);

  return {
    title: texts.planner.worklist,
    columns,
    data,
  };
};
