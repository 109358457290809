import { GetAbsences_PersonT2 as ApiPersonT2Absences } from 'core/dna/types/generated/GetAbsences';
import { mapNullableListToList } from 'core/dna/functions/map-nullable-list-to-list';
import { ShortDataWithAbsences } from 'modules/student/dna/types/short-data';
import { mapApiPersonT2ShortDataToPersonT2ShortData } from '../map-api-person-t2-short-data-to-person-t2-short-data';
import { mapApiAbsenceToAbsenceList } from '../map-api-absence-to-absence';

export const mapApiPersonT2AbsencesToPersonT2Absences = (
  apiPersonT2Absences: ApiPersonT2Absences | null,
): ShortDataWithAbsences => {
  if (apiPersonT2Absences === null) {
    throw new TypeError('"apiPersonT2Absences" should not be null!');
  }

  return {
    ...mapApiPersonT2ShortDataToPersonT2ShortData(apiPersonT2Absences),
    absences: mapApiAbsenceToAbsenceList(apiPersonT2Absences.absences),
  };
};

export const mapApiPersonT2AbsencesToPersonT2AbsencesList = (
  apiPersonT2List: (ApiPersonT2Absences | null)[] | null,
): ShortDataWithAbsences[] | undefined => {
  return mapNullableListToList<ApiPersonT2Absences, ShortDataWithAbsences>(
    apiPersonT2List,
    mapApiPersonT2AbsencesToPersonT2Absences,
  );
};
