import {
  SnackbarProvider,
  VariantType as NsVariantType,
  useSnackbar,
} from 'notistack';

export { SnackbarProvider as NsSnackbarProvider, useSnackbar };

export interface VariantTypeProps {
  Default: NsVariantType;
  Error: NsVariantType;
  Success: NsVariantType;
  Warning: NsVariantType;
  Info: NsVariantType;
}

export const VariantType: VariantTypeProps = {
  Default: 'default',
  Error: 'error',
  Success: 'success',
  Warning: 'warning',
  Info: 'info',
};
