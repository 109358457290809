import { gql } from 'core/dna/types/apollo';

import { SUBJECT_DATA_FRAGMENT } from 'modules/planner/memory/apollo/_fragments';

export const GET_SUBJECTS_QUERY = gql`
  query GetSubjects($termId: String!) {
    Subject(filter: { term: { id: $termId } }) {
      ...SubjectData
    }
  }
  ${SUBJECT_DATA_FRAGMENT}
`;
