import { useContext } from 'react';

import { ModuleGoHomeContext } from './module-go-home-context';

export const useModuleGoHome = () => {
  const moduleGoHome = useContext(ModuleGoHomeContext);
  if (typeof moduleGoHome === 'undefined') {
    throw new Error(
      'useModuleGoHome must be used within a ModuleGoHomeProvider',
    );
  }
  return moduleGoHome;
};
