import { useCallback, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';

import { DraggableItem } from 'core/atoms/types';

import { LessonProperty, LessonItem, LessonItemsData } from '../../types';
import { useLgcState, lgcStore } from '../../store';

import { onDrop, onDelete, deleteChildren } from './functions';

export interface UseLessonGroupCreatorProps {
  data?: LessonItemsData;
}

export const useLessonGroupCreator = (props: UseLessonGroupCreatorProps) => {
  const { data } = props;

  const {
    lgcState: {
      lessons,
      subjects,
      teachers,
      rooms,
      chapters,
      plannedMinutesPerTimeSlot,
      // mustBeNonstop,
      canBeSplit,
      errorSubjects,
      errorPlannedMinutesPerTimeSlot,
      selectedLessonId,
    },
  } = useLgcState();

  useEffect(() => {
    if (data?.lessons.length) {
      lgcStore.setLessons(data.lessons);
      lgcStore.selectLesson(data.lessons[data.lessons.length - 1].id);
    }
    if (data?.subjects) {
      lgcStore.setSubjects(data.subjects);
    }
    if (data?.teachers) {
      lgcStore.setTeachers(data.teachers);
    }
    if (data?.rooms) {
      lgcStore.setRooms(data.rooms);
    }
    if (data?.chapters) {
      lgcStore.setChapters(data.chapters);
    }
    if (data?.plannedMinutesPerTimeSlot) {
      lgcStore.setPlannedMinutesPerTimeSlot(data.plannedMinutesPerTimeSlot);
    }
    // if (data?.mustBeNonstop) {
    //   lgcStore.setMustBeNonStop(data.mustBeNonstop);
    // }

    if (data) {
      lgcStore.setCanBeSplit(data.canBeSplit);
    }
  }, [data]);

  const addMinutesPerTimeSlot = useCallback(
    (value: number) => {
      lgcStore.setPlannedMinutesPerTimeSlot([
        ...plannedMinutesPerTimeSlot,
        Number(value),
      ]);
      lgcStore.setErrorPlannedMinutesPerTimeSlot(null);
    },
    [plannedMinutesPerTimeSlot],
  );

  const deleteMinutesPerTimeSlot = useCallback(
    (value: number, index: number) => {
      if (plannedMinutesPerTimeSlot[index] === value) {
        const copy = [...plannedMinutesPerTimeSlot];
        copy.splice(index, 1);
        lgcStore.setPlannedMinutesPerTimeSlot(copy);
        lgcStore.setErrorPlannedMinutesPerTimeSlot(null);
      }
    },
    [plannedMinutesPerTimeSlot],
  );

  const onLessonSelect = useCallback((lessonId) => {
    lgcStore.selectLesson(lessonId);
  }, []);

  const dropNewSubjectFunc = useCallback(
    (subject: LessonProperty) => {
      const { id, lessonId: lid, ...rest } = subject;
      const lessonId = uuidv4();
      onDrop(lgcStore.setLessons, lessons, true)({ id: lessonId, ...rest });
      onDrop(lgcStore.setSubjects, subjects, true)({ id, lessonId, ...rest });
      onLessonSelect(lessonId);
      lgcStore.setErrorSubjects(null);
    },
    [lessons, onLessonSelect, subjects],
  );

  const deleteLessonFunc = useCallback(
    (lesson: LessonItem) => {
      deleteChildren(lgcStore.setSubjects, subjects)(lesson);
      deleteChildren(lgcStore.setTeachers, teachers)(lesson);
      deleteChildren(lgcStore.setRooms, rooms)(lesson);
      deleteChildren(lgcStore.setChapters, chapters)(lesson);
      onDelete(lgcStore.setLessons, lessons)(lesson);
    },
    [chapters, lessons, rooms, subjects, teachers],
  );

  // const changeMustBeNonstop = useCallback((_: any, checked: boolean) => {
  //   lgcStore.setMustBeNonStop(checked);
  // }, []);

  const changeCanBeSplit = useCallback((_: any, checked: boolean) => {
    lgcStore.setCanBeSplit(checked);
  }, []);

  const onTeacherDoubleClick = useCallback(
    (item: LessonProperty) => {
      onDrop(
        lgcStore.setTeachers,
        teachers,
      )({ ...item, lessonId: selectedLessonId });
    },
    [selectedLessonId, teachers],
  );

  const onRoomDoubleClick = useCallback(
    (item: DraggableItem) => {
      onDrop(
        lgcStore.setRooms,
        rooms,
        true,
      )({ ...item, lessonId: selectedLessonId });
    },
    [rooms, selectedLessonId],
  );

  const onChapterDoubleClick = useCallback(
    (item: DraggableItem) => {
      onDrop(
        lgcStore.setChapters,
        chapters,
      )({ ...item, lessonId: selectedLessonId });
    },
    [chapters, selectedLessonId],
  );

  return {
    lessons,
    onLessonSelect,
    onNewSubjectDropped: dropNewSubjectFunc,
    onLessonDelete: deleteLessonFunc,
    subjects,
    onSubjectDelete: onDelete(lgcStore.setSubjects, subjects),
    teachers,
    onTeacherDropped: onDrop(lgcStore.setTeachers, teachers),
    onTeacherDelete: onDelete(lgcStore.setTeachers, teachers),
    rooms,
    onRoomDropped: onDrop(lgcStore.setRooms, rooms, true),
    onRoomDelete: onDelete(lgcStore.setRooms, rooms),
    chapters,
    onChapterDropped: onDrop(lgcStore.setChapters, chapters),
    onChapterDelete: onDelete(lgcStore.setChapters, chapters),
    plannedMinutesPerTimeSlot,
    errorSubjects,
    errorPlannedMinutesPerTimeSlot,
    addMinutesPerTimeSlot,
    deleteMinutesPerTimeSlot,
    canBeSplit,
    // mustBeNonstop: mustBeNonstop ?? false,
    changeCanBeSplit,
    // changeMustBeNonstop,
    onTeacherDoubleClick,
    onRoomDoubleClick,
    onChapterDoubleClick,
  };
};
