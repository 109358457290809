import { useMemo } from 'react';

import { useQuery, gql } from 'core/dna/types/apollo';
import { GetSelectedTeachersState } from 'core/dna/types/generated/GetSelectedTeachersState';
import {
  mapNullableListToList,
  mapNullableStringToString,
} from 'core/dna/functions';

const GET_SELECTED_TEACHERS_STATE_QUERY = gql`
  query GetSelectedTeachersState {
    selectedTeachersState @client
  }
`;

export const useGetSelectedTeachersState = () => {
  const { data, loading, error } = useQuery<GetSelectedTeachersState>(
    GET_SELECTED_TEACHERS_STATE_QUERY,
  );

  const selectedTeachersState = useMemo(() => {
    if (!loading && !error && data) {
      return (
        mapNullableListToList(
          data.selectedTeachersState,
          mapNullableStringToString,
        ) ?? undefined
      );
    }
  }, [loading, error, data]);

  return {
    selectedTeachersState,
    loading,
    error,
  };
};
