import { useState, useLayoutEffect } from 'react';

import { subjectsStore } from './subjects-store';

export const useSubjectsState = () => {
  const [state, setState] = useState(subjectsStore.initialState);

  useLayoutEffect(() => {
    const subscription = subjectsStore.subscribe(setState);
    return () => {
      subscription.unsubscribe();
    };
  }, []);

  return {
    subjectsState: state,
  };
};
