import React, { useEffect, useMemo, useState } from 'react';
import moment from 'moment';

import { logger, LogType } from 'core/atoms/housekeeping';

import { Table } from 'templates/mdp/components/react-table';
import { Delete, Edit, MenuBook, Settings } from '@material-ui/icons';
import { Skeleton } from 'core/cells/skeleton';
import { GridContainer, GridItem } from 'templates/mdp/components/grid';
import {
  Card,
  CardBody,
  CardHeader,
  CardIcon,
} from 'templates/mdp/components/card';
import { useStyles } from 'templates/mdp/views/tables/react-tables.styles';
import { Button } from 'templates/mdp/components/custom-buttons';

import { useTranslations } from 'core/dna/translations';
import { createReactTableStore } from 'core/memory/pure/react-table-store';
import { useGetTermsState } from 'core/memory/apollo/terms/local/get-terms-state';
import { useReactTableState } from 'core/metabolism/table-state';
import { useOasHistory } from 'core/metabolism/use-oas-history';
import { useLocale } from 'core/dna/routing';
import {
  BatchState,
  BatchType,
  UpdateEvaluationBatchInput,
} from 'core/dna/types/generated/_globalTypes';
import { WriteModeEnum } from 'core/dna/types/write-mode';
import { dateTimeInputFormat } from 'core/atoms/date-time/formats';

import URLS from 'modules/grades/urls';
import { EvaluationBatch } from 'modules/grades/dna/types';
import { mapEvaluationBatchToUpdateEvaluationBatchInput } from 'modules/grades/dna/functions';

import { useBatchTableRead } from './use-batch-table-read';
import { BatchCreator } from './batch-creator';

export const batchTableStore = createReactTableStore({}, {});

const emptyBatch: UpdateEvaluationBatchInput = {
  type: BatchType.halfYear,
  term: '',
  deadline: moment().add(1, 'month').format(dateTimeInputFormat),
  state: BatchState.draft,
  evaluationBatchId: '',
};

export const BatchTable = () => {
  const classes = useStyles();
  const { texts } = useTranslations();
  const { push } = useOasHistory();
  const locale = useLocale();

  const { data: termsState } = useGetTermsState();

  const { columns, data, loading, title } = useBatchTableRead();

  const {
    initialPage,
    pageSize,
    sort,
    filters,
    setSort,
    setFilters,
    setPage,
    setPageSize,
  } = useReactTableState(batchTableStore, termsState?.selected ?? undefined);

  useEffect(() => {
    logger.debug({
      title: 'did mount',
      type: LogType.Info,
      logger: 'batch-table',
    });
  }, []);

  const [showModal, setShowModal] = useState(false);
  const [
    selectedBatch,
    setSelectedBatch,
  ] = useState<UpdateEvaluationBatchInput>(emptyBatch);
  const [writeMode, setWriteMode] = useState<WriteModeEnum>(
    WriteModeEnum.create,
  );

  const preparedData = useMemo(
    () =>
      data?.map((batch) => ({
        ...batch,
        type: !batch.type
          ? ''
          : texts.grades.gradesCommon.batchType[batch.type],
        state: !batch.state
          ? ''
          : texts.grades.gradesCommon.bathchState[batch.state],
        actions:
          batch.state === BatchState.draft ||
          batch.state === BatchState.open ||
          batch.state === BatchState.closed ? (
            <div className="actions-right">
              <Button
                justIcon
                round
                simple
                size="sm"
                color="info"
                title="Set responsible persons for setting grades"
                aria-label="some title"
                onClick={() => {
                  if (batch.id) {
                    push(URLS.batchSubjects({ batchId: batch.id, locale }));
                  }
                }}
              >
                <Settings />
              </Button>
              <Button
                justIcon
                round
                simple
                size="sm"
                color="info"
                title={texts.planner.common.edit}
                aria-label={texts.planner.common.edit}
                onClick={() => {
                  handleEdit(batch);
                }}
              >
                <Edit />
              </Button>
              <Button
                justIcon
                round
                simple
                size="sm"
                color="danger"
                title={texts.planner.common.delete}
                aria-label={texts.planner.common.delete}
                disabled={true}
                onClick={() => {
                  console.log('remove is not implemented');
                }}
              >
                <Delete />
              </Button>
            </div>
          ) : null,
      })),
    [data, locale, push, texts],
  );

  const handleAdd = () => {
    setWriteMode(WriteModeEnum.create);
    setSelectedBatch(emptyBatch);
    setShowModal(true);
  };

  const handleEdit = (batch: EvaluationBatch) => {
    setWriteMode(WriteModeEnum.update);
    const mappedBatch = mapEvaluationBatchToUpdateEvaluationBatchInput(batch);
    setSelectedBatch(mappedBatch);
    setShowModal(true);
  };

  return loading || !termsState?.selected ? (
    <Skeleton variant="rect" width="100%" height="80vh" />
  ) : (
    <GridContainer spacing={3} justifyContent="center">
      <GridItem xs={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button color="secondary" onClick={handleAdd}>
          {texts.grades.prepareBatch.addBatch}
        </Button>
      </GridItem>
      <GridItem xs={12}>
        <Card>
          <CardHeader color="primary" icon>
            <CardIcon color="primary">
              <MenuBook />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>{title}</h4>
          </CardHeader>
          <CardBody>
            <Table
              columns={columns}
              data={preparedData ?? []}
              key={termsState.selected}
              onChangeRowsPerPage={setPageSize}
              onChangePage={setPage}
              onFilterChange={setFilters}
              onOrderChange={setSort}
              options={{
                initialPage,
                pageSize,
                sort,
                filters,
              }}
            />
          </CardBody>
        </Card>
      </GridItem>
      <BatchCreator
        writeMode={writeMode}
        data={selectedBatch}
        open={showModal}
        setOpen={setShowModal}
      />
    </GridContainer>
  );
};
