import { useMemo } from 'react';

import { useQuery } from 'core/dna/types/apollo';
import { TermsState } from 'core/dna/types/local';
import { GetTermsState } from 'core/dna/types/generated/GetTermsState';

import { mapLocalApiTermsStateToTermsState } from 'modules/planner/dna/functions/map-LocalApiTermsState-to-TermsState';

import { GET_TERMS_STATE_QUERY } from './get-terms-state-query';

export const useGetTermsState = () => {
  const { data: localApiData, error, loading } = useQuery<GetTermsState>(
    GET_TERMS_STATE_QUERY,
  );

  const data = useMemo<TermsState | undefined>(() => {
    if (!loading && !error && localApiData) {
      return mapLocalApiTermsStateToTermsState(localApiData.termsState);
    }
  }, [loading, error, localApiData]);

  return {
    loading,
    error,
    data,
  };
};
