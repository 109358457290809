import { PaletteMode } from '@mui/material';
import { Theme } from '@mui/material/styles';

/**
 * Customized Material UI themes for "light" and "dark" modes.
 *
 * @see https://next.material-ui.com/customization/default-theme/
 */
// import themeDark from './theme-dark';
// import themeLight from './theme-light';
import themeTransponder from './theme-transponder';

export const themes: { [key in PaletteMode]?: Theme } = {
  light: themeTransponder,
  // dark: themeDark,
};

export * from './md-styled';
