import { useCallback } from 'react';
import queryString from 'query-string';

import { useHistory, useLocation } from 'core/dna/routing';

export interface OasLocationInput {
  path: string;
  state?: any;
  params?: { [key: string]: string };
}

export interface UseOasHistoryResult {
  push: (path: string, state?: any) => void;
  pushWithParams: (input: OasLocationInput) => void;
  replace: (path: string, state?: any) => void;
  getAugmentedPath: (path: string) => string;
  organization?: string;
}

export const useOasHistory = (): UseOasHistoryResult => {
  const history = useHistory();
  const location = useLocation();

  const getSearchObject = useCallback(() => {
    const parsed = queryString.parse(location.search);

    return {
      // o: parsed.o || getBrowserOrganization() || undefined,
      // t: parsed.t || getBrowserTerm() || undefined,
      idp: parsed.idp ?? undefined,
    };
  }, [location.search]);

  // const organization = useMemo(() => {
  //   const org = queryString.parse(location.search)?.o;
  //   return org ? (org as string) : undefined;
  // }, [location.search]);

  const getAugmentedPath = useCallback(
    (path: string) => {
      const parsedUrl = queryString.parseUrl(path);
      const queryObj: any = {
        ...getSearchObject(),
        ...parsedUrl.query,
      };
      return `${parsedUrl.url}?${queryString.stringify(queryObj)}`;
    },
    [getSearchObject],
  );

  const push = useCallback(
    (path: string, state?: any) => {
      history.push(getAugmentedPath(path), state);
    },
    [getAugmentedPath, history],
  );

  const pushWithParams = useCallback(
    (input: OasLocationInput) => {
      const paramKeys = Object.keys(input.params ?? {});
      let newPath = input.path;
      if (paramKeys.length) {
        newPath = paramKeys.reduce((prev, cur) => {
          return prev.replace(cur, input.params![cur]);
        }, newPath);
      }
      history.push(getAugmentedPath(newPath), input.state);
    },
    [getAugmentedPath, history],
  );

  const replace = useCallback(
    (path: string, state?: any) => {
      history.replace(getAugmentedPath(path), state);
    },
    [getAugmentedPath, history],
  );

  return {
    push,
    pushWithParams,
    replace,
    getAugmentedPath,
  };
};
