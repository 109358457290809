import { CustomError } from 'ts-custom-error';
import { getErrorMessage, getErrorStack } from './common-errors';

export type OasErrorType =
  | 'OasError'
  | 'OasCellError'
  | 'OasDnaError'
  | 'OasMemoryError'
  | 'OasMetabolismError'
  | 'OasOrganismError'
  | 'OasPageError'
  | 'OasAuthError'
  | 'OasMappingError'
  | 'OasRoutingError';

export interface OasErrorOptions<TData extends Record<string, unknown> = any> {
  title?: string;
  type?: OasErrorType;
  data?: TData;
}

export interface OasErrorConstructor<
  TData extends Record<string, unknown> = any
> {
  new (
    message?: string,
    options?: OasErrorOptions<TData>,
  ): OasErrorInterface<TData>;
  fromError(
    e: unknown,
    options?: OasErrorOptions<TData>,
  ): OasErrorInterface<TData>;
}

export interface OasErrorInterface<
  TData extends Record<string, unknown> = any
> {
  type?: OasErrorType;
  title?: string;
  message?: string;
  originalMessage?: string;
  stack?: string;
  isOf: (type: OasErrorType) => boolean;
  data?: TData;
}

export const getOasErrorFromError = <
  TData extends Record<string, unknown> = any
>(
  Ctor: OasErrorConstructor<TData>,
  e: unknown,
  options?: OasErrorOptions<TData>,
): OasErrorInterface<TData> => {
  const error = new Ctor(getErrorMessage(e), options);
  error.stack = getErrorStack(e);
  return error;
};

export class OasError<TData extends Record<string, unknown> = any>
  extends CustomError
  implements OasErrorInterface<TData> {
  title?: string;

  type?: OasErrorType;

  originalMessage?: string;

  data?: TData;

  constructor(message?: string, options?: OasErrorOptions<TData>) {
    super(message);
    this.title = options?.title;
    this.type = options?.type;
    this.originalMessage = message;
    this.message = `${this.title ?? 'ERROR'}: ${message}`;
    this.data = options?.data;
  }

  isOf(type: OasErrorType) {
    return this.type === type;
  }
}
