import { useMemo } from 'react';

import { useQuery, gql } from 'core/dna/types/apollo';
import { GetSyncGroups } from 'core/dna/types/generated/GetSyncGroups';

import { mapApiSyncGroupListToSyncGroupList } from 'modules/planner/dna/functions/map-ApiSyncGroup-to-SyncGroup';

const GET_SYNC_GROUPS_QUERY = gql`
  query GetSyncGroups {
    SyncGroup {
      id
      name
      description
      parentId
      type
      short
    }
  }
`;

export const useGetSyncGroups = () => {
  const { data, loading, error } = useQuery<GetSyncGroups>(
    GET_SYNC_GROUPS_QUERY,
  );

  const syncGroups = useMemo(() => {
    if (!loading && !error && data) {
      return mapApiSyncGroupListToSyncGroupList(data.SyncGroup);
    }
  }, [data, error, loading]);

  return {
    data: syncGroups,
    loading,
    error,
  };
};
