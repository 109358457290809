import { useMemo, useState, useCallback } from 'react';
import { RecordVoiceOver, Edit, Close, Check } from '@material-ui/icons';

import { OasOrganismError } from 'core/atoms/errors';

import { Skeleton } from 'core/cells/skeleton';
import { Checkbox } from 'core/cells/checkbox';
import { FlexBox } from 'core/cells/box';

import { SubstituteTeacherState } from 'core/dna/types/generated/_globalTypes';

import {
  mapTranslationDueToOrgCategoryType,
  useTranslations,
} from 'core/dna/translations';

import { useGetTermsState } from 'core/memory/apollo/terms/local/get-terms-state';
import { ReactTableStore } from 'core/memory/pure/react-table-store';

import { useReactTableState } from 'core/metabolism/table-state';
import { useOrganization } from 'core/metabolism/organizations';

import { useStyles as useRegularFormStyles } from 'templates/mdp/views/forms/regular-forms.styles';
import { Table } from 'templates/mdp/components/react-table';
import { Button } from 'templates/mdp/components/custom-buttons';
import { GridContainer, GridItem } from 'templates/mdp/components/grid';
import {
  Card,
  CardBody,
  CardHeader,
  CardIcon,
} from 'templates/mdp/components/card';
import { useStyles } from 'templates/mdp/views/tables/react-tables.styles';

import {
  TeacherTableItem,
  emptyTeacherItem,
} from 'modules/substitute/dna/types/teacher';
import { SubstituteInvitation } from 'modules/substitute/dna/types/substitute-invitation';

import { TeacherEditor } from './teacher-editor';
import { useTeachersTableRead } from './use-teachers-table-read';
import { useTeachersTableWrite } from './use-teachers-table-write';

export interface TeachersTableProps {
  tableStore: ReactTableStore;
  editable?: boolean;
  selectable?: boolean;
  onSelect?: (item: TeacherTableItem) => void;
  selected?: SubstituteInvitation[];
}

const defaultProps: Partial<TeachersTableProps> = {
  editable: true,
  selectable: false,
};

export const TeachersTable = (props: TeachersTableProps) => {
  const { tableStore, editable, selectable, onSelect, selected } = props;

  const classes = useStyles();
  const regularFormsClasses = useRegularFormStyles();

  const { organization } = useOrganization();
  const { texts } = useTranslations();
  const { data: termsState } = useGetTermsState();

  const { columns, data, loading, title } = useTeachersTableRead({
    withActions: !!editable || !!selectable,
  });
  const { onRowInactivate, onRowActivate } = useTeachersTableWrite();

  const {
    initialPage,
    pageSize,
    sort,
    filters,
    setSort,
    setFilters,
    setPage,
    setPageSize,
  } = useReactTableState(tableStore, termsState?.selected ?? undefined);

  const [inactiveEnabled, setInactiveEnabled] = useState<boolean>(false);

  const [showModal, setShowModal] = useState(false);
  const [selectedTeachersTableItem, setSelectedTeachersTableItem] = useState<
    Partial<TeacherTableItem>
  >(emptyTeacherItem);

  const handleAdd = () => {
    setSelectedTeachersTableItem(emptyTeacherItem);
    setShowModal(true);
  };

  const handleUpdate = useCallback(
    async (id: string) => {
      const teacher = data?.find((item) => item.id === id);
      if (teacher) {
        setSelectedTeachersTableItem(teacher);
        setShowModal(true);
      }
    },
    [data],
  );

  const handleInactivate = useCallback(
    async (id: string) => {
      const teacherToDelete = data?.find((item) => item.id === id);
      if (teacherToDelete) {
        await onRowInactivate(teacherToDelete);
      }
    },
    [data, onRowInactivate],
  );

  const handleActivate = useCallback(
    async (id: string) => {
      const teacherToActivate = data?.find((item) => item.id === id);
      if (teacherToActivate) {
        await onRowActivate(teacherToActivate);
      }
    },
    [data, onRowActivate],
  );

  const changeInactiveEnabled = useCallback((value: boolean) => {
    setInactiveEnabled(value);
  }, []);

  const isSelected = useCallback(
    (id: string) => Boolean(selected?.find((item) => item.teacher.id === id)),
    [selected],
  );

  const preparedData = useMemo<any[]>(() => {
    if (editable && selectable) {
      throw new OasOrganismError(
        'editable and selectable are mutually exclusive',
        { title: 'modules/substitute/organisms/teachers-table' },
      );
    }
    if (editable) {
      return data.map((item) => {
        if (item.state === SubstituteTeacherState.inactive) {
          return {
            ...item,
            actions: (
              <div className="actions-right">
                <Button
                  style={{ minWidth: 100 }}
                  size="sm"
                  color="success"
                  onClick={() => handleActivate(item.id)}
                  disabled={isSelected(item.id)}
                >
                  {texts.oasCommon.activate}
                </Button>
              </div>
            ),
          };
        }
        return {
          ...item,
          actions: (
            <div className="actions-right">
              <Button
                justIcon
                round
                simple
                onClick={() => handleUpdate(item.id)}
                color="info"
                className="edit"
              >
                <Edit />
              </Button>
              <Button
                justIcon
                round
                simple
                onClick={() => handleInactivate(item.id)}
                color="danger"
                className="remove"
              >
                <Close />
              </Button>
            </div>
          ),
        };
      });
    }
    if (selectable) {
      return data.map((item) => {
        return {
          ...item,
          actions: (
            <div className="actions-right">
              <Button
                style={{ minWidth: 100 }}
                size="sm"
                color="info"
                onClick={() => onSelect?.(item)}
                disabled={isSelected(item.id)}
              >
                {isSelected(item.id)
                  ? texts.substitute.invited
                  : texts.oasCommon.invite}
              </Button>
            </div>
          ),
        };
      });
    }

    return data;
  }, [
    data,
    editable,
    handleActivate,
    handleInactivate,
    handleUpdate,
    isSelected,
    onSelect,
    selectable,
    texts,
  ]);

  const filteredByStateData = useCallback(() => {
    return inactiveEnabled
      ? preparedData
      : preparedData.filter((t) => t.state === SubstituteTeacherState.active);
  }, [inactiveEnabled, preparedData]);

  return loading || !termsState?.selected ? (
    <Skeleton variant="rect" width="100%" height="80vh" />
  ) : (
    <GridContainer>
      {editable ? (
        <GridItem
          xs={12}
          style={{ display: 'flex', justifyContent: 'flex-end' }}
        >
          <FlexBox style={{ display: 'inline-flex', width: 'auto' }}>
            {mapTranslationDueToOrgCategoryType(
              texts,
              organization?.category,
              'showInactiveTeachers',
            )}
            <Checkbox
              checked={inactiveEnabled}
              onChange={(event) => changeInactiveEnabled(event.target.checked)}
              tabIndex={-1}
              checkedIcon={
                <Check className={regularFormsClasses.checkedIcon} />
              }
              icon={<Check className={regularFormsClasses.uncheckedIcon} />}
              classes={{
                checked: regularFormsClasses.checked,
                root: regularFormsClasses.checkRoot,
              }}
            />
          </FlexBox>
          <Button color="secondary" onClick={handleAdd}>
            {texts.substitute.addTeacher}
          </Button>
        </GridItem>
      ) : (
        <> </>
      )}
      <GridItem xs={12}>
        <Card>
          <CardHeader color="primary" icon>
            <CardIcon color="primary">
              <RecordVoiceOver />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>{title}</h4>
          </CardHeader>
          <CardBody>
            <Table
              key={termsState?.selected ?? 'unknown_term'}
              noActions={!(selectable || editable)}
              columns={columns}
              data={filteredByStateData()}
              onChangeRowsPerPage={setPageSize}
              onChangePage={setPage}
              onFilterChange={setFilters}
              onOrderChange={setSort}
              options={{
                initialPage,
                pageSize,
                sort,
                filters,
              }}
            />
          </CardBody>
        </Card>
      </GridItem>
      <TeacherEditor
        initial={{
          id: selectedTeachersTableItem.id,
          firstName: selectedTeachersTableItem.firstName,
          lastName: selectedTeachersTableItem.lastName,
          phone: selectedTeachersTableItem.phone,
          email: selectedTeachersTableItem.email,
          tags: selectedTeachersTableItem.tags,
        }}
        open={showModal}
        setOpen={setShowModal}
      />
    </GridContainer>
  );
};

TeachersTable.defaultProps = defaultProps;
