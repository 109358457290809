import { CreateLessonGroupResourcePartInput } from 'core/dna/types/generated/_globalTypes';

import { LessonProperty } from '../../../types';

export const onDrop = <T extends LessonProperty<any>>(
  setList?: (list?: T[]) => void,
  list?: T[],
  allowDuplicates?: boolean,
) => (lp: T) => {
  if (!list || !list.length) {
    setList?.([lp]);
  } else {
    const existingIdentity = list.find(
      (item) => item.id === lp.id && item.lessonId === lp.lessonId,
    );
    if (!existingIdentity || allowDuplicates) {
      const existingIdentityInLesson = list.find(
        (item) => item.id === lp.id && item.lessonId === lp.lessonId,
      );
      if (!existingIdentityInLesson) {
        setList?.([...list, lp]);
      }
    }
  }
};

export const onDelete = <T extends LessonProperty<any>>(
  setList: (list?: T[]) => void,
  list?: T[],
) => (lp: T) => {
  if (list && list.length) {
    const newList = list.filter(
      (i) => !(i.id === lp.id && i.lessonId === lp.lessonId),
    );
    setList(newList.length ? newList : undefined);
  }
};

export const deleteChildren = <T extends LessonProperty<any>>(
  setList: (list?: T[]) => void,
  list?: T[],
) => (lesson: T) => {
  if (list && list.length) {
    const newList = list.filter((i) => i.lessonId !== lesson.id);
    setList(newList.length ? newList : undefined);
  }
};

export const mapSubjectToSubjectInput = (
  subject: LessonProperty,
): CreateLessonGroupResourcePartInput => {
  return {
    id: subject.id,
  };
};

export const mapTeacherToTeacherInput = (
  teacher: LessonProperty,
): CreateLessonGroupResourcePartInput => {
  return {
    id: teacher.id,
  };
};

export const mapRoomToRoomInput = (
  room: LessonProperty,
): CreateLessonGroupResourcePartInput => {
  return {
    id: room.id,
  };
};

export const mapChapterToChapterInput = (
  chapter: LessonProperty,
): CreateLessonGroupResourcePartInput => {
  return {
    id: chapter.id,
  };
};
