import { useCallback } from 'react';
import clsx from 'clsx';
import { Select } from 'core/cells/select';

import {
  makeStyles,
  Theme,
  createStyles,
  defaultFont,
} from 'core/atoms/styles';

import { fieldToSelect, SelectProps } from 'core/cells/forms';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      margin: '17px 0 17px 0',
      // paddingTop: '27px',
      position: 'relative',
      verticalAlign: 'unset',
      '& svg,& .fab,& .far,& .fal,& .fas,& .material-icons': {
        color: theme.palette.extended?.grey[14],
      },
    },
    disabled: {
      '&:before': {
        borderColor: 'transparent !important',
      },
    },
    label: {
      ...defaultFont,
      color: (props: FormSelectProps) =>
        props.success
          ? `${theme.palette.extended?.success[0]} !important`
          : theme.palette.extended?.grey[1],
      fontWeight: 500,
      fontSize: '14px',
      lineHeight: '1.42857',
      top: '10px',
      letterSpacing: 'unset',
      '& + $underline': {
        marginTop: '0px',
      },
      '&.MuiInputLabel-shrink': {
        transform: 'translate(0, 1.5px) scale(0.85)',
      },
    },
    labelError: {
      color: theme.palette.extended?.danger[0],
    },
    input: {
      color: theme.palette.extended?.grey[14],
      height: 'unset',
      '&,&::placeholder': {
        fontSize: '14px',
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        fontWeight: '400',
        lineHeight: '1.42857',
        opacity: '1',
      },
      '&::placeholder': {
        color: theme.palette.extended?.grey[3],
      },
    },
    whiteInput: {
      '&,&::placeholder': {
        color: '#fff',
        opacity: '1',
      },
    },
    underline: {
      '&:hover:not($disabled):before,&:before': {
        borderColor: `${theme.palette.extended?.grey[4]} !important`,
        borderWidth: '1px !important',
      },
      '&:after': {
        borderColor: (props: FormSelectProps) =>
          props.error
            ? theme.palette.extended?.danger[0]
            : props.success
            ? theme.palette.extended?.success[0]
            : props.white
            ? '#fff'
            : theme.palette.primary.main,
      },
      '& + p': {
        fontWeight: '300',
      },
    },
    error: {
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: theme.palette.extended?.danger[0],
      },
    },
  }),
);

interface OmittedProps {
  variant?: any;
}

export interface FormSelectProps extends Omit<SelectProps, keyof OmittedProps> {
  label: string;
  error?: boolean;
  success?: boolean;
  white?: boolean;
}

export const FormSelect = (props: FormSelectProps) => {
  const classes = useStyles(props);
  const {
    form: { setFieldValue },
    field: { name },
  } = props;
  const onChange = useCallback(
    (value) => {
      setFieldValue(name, value || '');
    },
    [setFieldValue, name],
  );

  const { label, ...rest } = props;
  const selectProps = fieldToSelect(rest);
  selectProps.className = clsx(
    'form-select',
    classes.root,
    selectProps.className ?? '',
  );

  const inputClasses = clsx({
    [classes.input]: true,
    [classes.whiteInput]: props.white,
    [classes.error]: props.error,
  });

  return (
    <Select
      name={name}
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      label={label}
      {...selectProps}
      onChange={onChange}
      inputClassName={inputClasses}
    />
  );

  /*
  return (
    <MuiTextField
      variant="standard"
      onChange={onChange}
      InputLabelProps={{
        classes: {
          root: classes.label,
          error: classes.labelError,
        },
      }}
      InputProps={{
        classes: {
          root: inputClasses,
          underline: classes.underline,
          disabled: classes.disabled,
        },
      }}
      {...textFieldProps}
    />
  );

   */
};
