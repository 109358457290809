import { makeStyles, createStyles, CSSProperties } from 'core/atoms/styles';

import {
  grayColor,
  primaryColor,
  infoColor,
  successColor,
  warningColor,
  dangerColor,
  whiteColor,
  hexToRgb,
} from '../../material-dashboard-pro-react';

export interface PaginationStyle {
  pagination: CSSProperties;
  paginationItem: CSSProperties;
  paginationLink: CSSProperties;
  primary: CSSProperties;
  info: CSSProperties;
  success: CSSProperties;
  warning: CSSProperties;
  danger: CSSProperties;
  disabled: CSSProperties;
}

export const paginationStyle: PaginationStyle = {
  pagination: {
    display: 'inline-block',
    paddingLeft: '0',
    margin: '20px 0',
    borderRadius: '4px',
  },
  paginationItem: {
    display: 'inline',
  },
  paginationLink: {
    ':first-of-type': {
      marginLeft: '0',
    },
    letterSpacing: 'unset',
    border: '0',
    borderRadius: '30px !important',
    transition: 'all .3s',
    padding: '0px 11px',
    margin: '0 3px',
    minWidth: '30px',
    height: '30px',
    minHeight: 'auto',
    lineHeight: '30px',
    fontWeight: 400,
    fontSize: '12px',
    textTransform: 'uppercase',
    background: 'transparent',
    position: 'relative',
    float: 'left',
    textDecoration: 'none',
    boxSizing: 'border-box',
    '&,&:hover,&:focus': {
      color: grayColor[0],
    },
    '&:hover,&:focus': {
      zIndex: 3,
      backgroundColor: grayColor[8],
      borderColor: grayColor[5],
    },
    '&:hover': {
      cursor: 'pointer',
    },
  },
  primary: {
    '&,&:hover,&:focus': {
      backgroundColor: primaryColor[0],
      borderColor: primaryColor[0],
      color: whiteColor,
      boxShadow: `0 4px 5px 0 rgba(${hexToRgb(
        primaryColor[0],
      )}, 0.14), 0 1px 10px 0 rgba(${hexToRgb(
        primaryColor[0],
      )}, 0.12), 0 2px 4px -1px rgba(${hexToRgb(primaryColor[0])}, 0.2)`,
    },
    '&:hover,&:focus': {
      zIndex: 2,
      cursor: 'default',
    },
  },
  info: {
    '&,&:hover,&:focus': {
      backgroundColor: infoColor[0],
      borderColor: infoColor[0],
      color: whiteColor,
      boxShadow: `0 4px 5px 0 rgba(${hexToRgb(
        infoColor[0],
      )}, 0.14), 0 1px 10px 0 rgba(${hexToRgb(
        infoColor[0],
      )}, 0.12), 0 2px 4px -1px rgba(${hexToRgb(infoColor[0])}, 0.2)`,
    },
    '&:hover,&:focus': {
      zIndex: 2,
      cursor: 'default',
    },
  },
  success: {
    '&,&:hover,&:focus': {
      backgroundColor: successColor[0],
      borderColor: successColor[0],
      color: whiteColor,
      boxShadow: `0 4px 5px 0 rgba(${hexToRgb(
        successColor[0],
      )}, 0.14), 0 1px 10px 0 rgba(${hexToRgb(
        successColor[0],
      )}, 0.12), 0 2px 4px -1px rgba(${hexToRgb(successColor[0])}, 0.2)`,
    },
    '&:hover,&:focus': {
      zIndex: 2,
      cursor: 'default',
    },
  },
  warning: {
    '&,&:hover,&:focus': {
      backgroundColor: warningColor[0],
      borderColor: warningColor[0],
      color: whiteColor,
      boxShadow: `0 4px 5px 0 rgba(${hexToRgb(
        warningColor[0],
      )}, 0.14), 0 1px 10px 0 rgba(${hexToRgb(
        warningColor[0],
      )}, 0.12), 0 2px 4px -1px rgba(${hexToRgb(warningColor[0])}, 0.2)`,
    },
    '&:hover,&:focus': {
      zIndex: 2,
      cursor: 'default',
    },
  },
  danger: {
    '&,&:hover,&:focus': {
      backgroundColor: dangerColor[0],
      borderColor: dangerColor[0],
      color: whiteColor,
      boxShadow: `0 4px 5px 0 rgba(${hexToRgb(
        dangerColor[0],
      )}, 0.14), 0 1px 10px 0 rgba(${hexToRgb(
        dangerColor[0],
      )}, 0.12), 0 2px 4px -1px rgba(${hexToRgb(dangerColor[0])}, 0.2)`,
    },
    '&:hover,&:focus': {
      zIndex: 2,
      cursor: 'default',
    },
  },
  disabled: {
    '&,&:hover,&:focus': {
      color: grayColor[1],
      cursor: 'not-allowed',
      backgroundColor: whiteColor,
      borderColor: grayColor[5],
    },
  },
};

export const useStyles = makeStyles(() => createStyles({ ...paginationStyle }));
