import React, { useEffect, useMemo, useState } from 'react';

import { logger, LogType } from 'core/atoms/housekeeping';

import { Table } from 'templates/mdp/components/react-table';
import { Add, Person } from '@material-ui/icons';
import { Skeleton } from 'core/cells/skeleton';
import { GridContainer, GridItem } from 'templates/mdp/components/grid';
import {
  Card,
  CardBody,
  CardHeader,
  CardIcon,
} from 'templates/mdp/components/card';
import { useStyles } from 'templates/mdp/views/tables/react-tables.styles';
import { Button } from 'templates/mdp/components/custom-buttons';

import {
  OrganizationCategoryType,
  OrganizationFeatureCode,
} from 'core/dna/types/generated/_globalTypes';

import { useTranslations } from 'core/dna/translations';
import { isFeatureAvailable } from 'core/dna/functions';
import { createReactTableStore } from 'core/memory/pure/react-table-store';
import { useGetTermsState } from 'core/memory/apollo/terms/local/get-terms-state';
import { useReactTableState } from 'core/metabolism/table-state';
import { useOrganization } from 'core/metabolism/organizations';

import { useStudentsTableRead } from './use-students-table-read';
import { JournalCreator } from './journal-creator';

export const studentsTableStore = createReactTableStore({}, {});

export const StudentsTable = () => {
  const classes = useStyles();
  const { texts } = useTranslations();

  const { data: termsState } = useGetTermsState();
  const { organization } = useOrganization();

  const { columns, data, loading, title } = useStudentsTableRead();

  const {
    initialPage,
    pageSize,
    sort,
    filters,
    setSort,
    setFilters,
    setPage,
    setPageSize,
  } = useReactTableState(studentsTableStore, termsState?.selected ?? undefined);

  useEffect(() => {
    logger.debug({
      title: 'did mount',
      type: LogType.Info,
      logger: 'students-table',
    });
  }, []);

  const [showModal, setShowModal] = useState(false);
  const [selectedTuid, setSelectedTuid] = useState<string | null>(null);

  const preparedData = useMemo(() => {
    if (isFeatureAvailable(organization, OrganizationFeatureCode.journal)) {
      return data?.map((student) => {
        return {
          ...student,
          actions: (
            <div className="actions-right">
              <Button
                color="success"
                justIcon
                title={texts.studentModule.studentCommon.addEvent}
                aria-label={texts.studentModule.studentCommon.addEvent}
                onClick={() => {
                  setSelectedTuid(student.tuid);
                  setShowModal(true);
                }}
              >
                <Add />
              </Button>
            </div>
          ),
        };
      });
    }

    return data;
  }, [data, organization, texts.studentModule.studentCommon.addEvent]);

  return loading || !termsState?.selected ? (
    <Skeleton variant="rect" width="100%" height="80vh" />
  ) : (
    <GridContainer spacing={3} justifyContent="center">
      <GridItem xs={12} lg={8} xl={6}>
        <Card>
          <CardHeader color="primary" icon>
            <CardIcon color="primary">
              <Person />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>{title}</h4>
          </CardHeader>
          <CardBody>
            <Table
              noActions={
                organization?.category !== OrganizationCategoryType.barnehage
              }
              columns={columns}
              data={preparedData ?? []}
              key={termsState.selected}
              onChangeRowsPerPage={setPageSize}
              onChangePage={setPage}
              onFilterChange={setFilters}
              onOrderChange={setSort}
              options={{
                initialPage,
                pageSize,
                sort,
                filters,
              }}
            />
          </CardBody>
        </Card>
      </GridItem>
      <JournalCreator
        tuid={selectedTuid}
        open={showModal}
        setOpen={setShowModal}
      />
    </GridContainer>
  );
};
