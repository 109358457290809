import { useMemo, useCallback } from 'react';

import {
  YearObject,
  Month,
  MonthObject,
  MonthType,
} from 'core/atoms/date-time';

import { useTranslations } from 'core/dna/translations';

export const useYear = () => {
  const { texts } = useTranslations();

  const year: YearObject = useMemo(
    () => ({
      [Month.January]: {
        long: texts.oasCommon.months.january,
        short: texts.oasCommon.months.january.slice(0, 3),
        num: Month.January,
      },
      [Month.February]: {
        long: texts.oasCommon.months.february,
        short: texts.oasCommon.months.february.slice(0, 3),
        num: Month.February,
      },
      [Month.March]: {
        long: texts.oasCommon.months.march,
        short: texts.oasCommon.months.march.slice(0, 3),
        num: Month.March,
      },
      [Month.April]: {
        long: texts.oasCommon.months.april,
        short: texts.oasCommon.months.april.slice(0, 3),
        num: Month.April,
      },
      [Month.May]: {
        long: texts.oasCommon.months.may,
        short: texts.oasCommon.months.may.slice(0, 3),
        num: Month.May,
      },
      [Month.June]: {
        long: texts.oasCommon.months.june,
        short: texts.oasCommon.months.june.slice(0, 3),
        num: Month.June,
      },
      [Month.July]: {
        long: texts.oasCommon.months.july,
        short: texts.oasCommon.months.july.slice(0, 3),
        num: Month.July,
      },
      [Month.August]: {
        long: texts.oasCommon.months.august,
        short: texts.oasCommon.months.august.slice(0, 3),
        num: Month.August,
      },
      [Month.September]: {
        long: texts.oasCommon.months.september,
        short: texts.oasCommon.months.september.slice(0, 3),
        num: Month.September,
      },
      [Month.October]: {
        long: texts.oasCommon.months.october,
        short: texts.oasCommon.months.october.slice(0, 3),
        num: Month.October,
      },
      [Month.November]: {
        long: texts.oasCommon.months.november,
        short: texts.oasCommon.months.november.slice(0, 3),
        num: Month.November,
      },
      [Month.December]: {
        long: texts.oasCommon.months.december,
        short: texts.oasCommon.months.december.slice(0, 3),
        num: Month.December,
      },
    }),
    [texts],
  );

  const yearArr: MonthObject[] = Object.values(year);

  const getMonth = useCallback(
    (month: Month | MonthType) => {
      return year[month];
    },
    [year],
  );

  return {
    year,
    yearArr,
    getMonth,
  };
};
