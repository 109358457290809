import { ApolloLink } from 'core/dna/types/apollo';
import { getMainDefinition } from 'apollo-utilities';

import { logging } from './logging';

import formatMessage from './format-message';

export const loggerLink = new ApolloLink((operation, forward) => {
  const startTime = new Date().getTime();

  return forward(operation).map((result) => {
    const definition = getMainDefinition(operation.query);
    let operationType;
    if (definition.kind === 'OperationDefinition') {
      operationType = definition.operation;
    } else {
      operationType = definition.name.value;
    }

    // const operationType = operation.query.definitions[0].operation;
    const elapsed = new Date().getTime() - startTime;

    const group = formatMessage(operationType, operation, elapsed);

    logging.groupCollapsed(...group);

    logging.log('INIT', operation);
    logging.log('RESULT', result);

    logging.groupEnd(...group);
    return result;
  });
});
