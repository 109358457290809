import { useState, useEffect, useCallback, useMemo, useRef } from 'react';
import queryString from 'query-string';
import clsx from 'clsx';

import { useLocale, useLocation } from 'core/dna/routing';
import { useTranslations } from 'core/dna/translations';

import { useOasHistory } from 'core/metabolism/use-oas-history';

import { LoginOptions } from 'auth/dna/types';

import {
  useIdentity,
  useLazyLogin,
  defaultLoginCallback,
  defaultAuthErrorCallback,
} from 'auth/metabolism';

import AuthPaths from 'auth/paths';
import AuthUrls from 'auth/urls';

import { GridContainer, GridItem } from 'templates/mdp/components/grid';
import { Card, CardHeader, CardBody } from 'templates/mdp/components/card';
import { Button } from 'templates/mdp/components/custom-buttons';

import { useStyles } from './login-page.styles';

export const LoginPage = () => {
  const classes = useStyles();

  const { lang } = useTranslations();
  const { replace } = useOasHistory();
  const location = useLocation();
  const identity = useIdentity();
  const locale = useLocale();

  const [cardAnimation, setCardAnimation] = useState('cardHidden');

  useEffect(() => {
    const id = setTimeout(() => {
      setCardAnimation('');
    }, 700);
    return () => {
      window.clearTimeout(id);
    };
  });

  // const [autoLogin, setAutoLogin] = useState<boolean>(false);
  const autoLoginRef = useRef(false);

  const from = useMemo(
    () =>
      (location?.state as any)?.from ?? {
        pathname: AuthPaths.path,
      },
    [location?.state],
  );

  const { login } = useLazyLogin(defaultLoginCallback, (error: any) =>
    defaultAuthErrorCallback(error, {
      navigateTo: replace,
      from,
    }),
  );

  const loginWithT2 = useCallback(() => {
    login({ force: true });
    return false;
  }, [login]);

  useEffect(() => {
    // if (!identity && !autoLogin) {
    if (!identity && !autoLoginRef.current) {
      const qTransient = queryString.parse(location.search).transient;
      if (qTransient) {
        const options: LoginOptions = {
          navigateTo: replace,
          fallbackPath: AuthUrls.home(locale),
          from,
          locale,
          transient: String(qTransient),
        };
        // setAutoLogin(true);
        autoLoginRef.current = true;
        login(options);
      }
    } /* else if (identity) {
     replace(URLS.home(locale));
     } */
    // }, [autoLogin, from, identity, locale, location.search, login, replace]);
  }, [from, identity, locale, location.search, login, replace]);

  // return autoLogin ? (
  return autoLoginRef.current ? (
    <> </>
  ) : (
    <div className={classes.container}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={6} md={4}>
          <form>
            <Card login className={(classes as any)[cardAnimation]}>
              <CardHeader
                className={clsx(classes.cardHeader, classes.textCenter)}
                color="primary"
              >
                <h4 className={clsx(classes.cardTitle, classes.logo)}>
                  {lang.common.transponder}
                </h4>
              </CardHeader>
              <CardBody>
                <GridContainer className={classes.paddingHorizontal}>
                  <GridItem xs={12} flex>
                    <Button color="secondary" block onClick={loginWithT2}>
                      {lang.login.primaryButton}
                    </Button>
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
          </form>
        </GridItem>
      </GridContainer>
    </div>
  );
};
