import { ScheduleStateStatus } from 'modules/planner/dna/types/local';
import { ScheduleStatus } from 'core/dna/types/generated/_globalTypes';

export const mapScheduleStatusToScheduleStateStatus = (
  scheduleStatus?: ScheduleStatus,
): ScheduleStateStatus => {
  if (!scheduleStatus) {
    return ScheduleStateStatus.Idle;
  }
  if (
    scheduleStatus === ScheduleStatus.starting ||
    scheduleStatus === ScheduleStatus.running
  ) {
    return ScheduleStateStatus.Running;
  }
  return ScheduleStateStatus.Idle;
};
